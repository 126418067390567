import i18next from "i18next";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../PageUtils";
import React from "react";
import { TValue } from "../../global.types";
import BindingContext, { IEntity } from "../../odata/BindingContext";
import { TCellValue } from "@components/table";
import { FieldType, GroupedField, IconSize, LabelStatus, Sort, Status, TextAlign, ValidatorType } from "../../enums";
import { StatusLockedIcon, StatusPartlyLockedIcon, StatusUnlockedIcon } from "@components/icon";
import { isDefined } from "@utils/general";
import { TFormatterFn } from "@components/smart/smartTable/SmartTable.utils";
import { IFormatOptions } from "@odata/OData.utils";
import {
    DefaultPeriodsPath,
    FiscalYearDefaultPeriod,
    getDefaultFiscalYearDateStart,
    getFiscalYearNumbers,
    updatePeriodNumbers
} from "./FiscalYear.utils";
import { ISummaryItem, ISummaryItemEditProps } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import { IFormDef } from "../../views/formView/Form";
import FiscalYearFormView from "./FiscalYearFormView";
import { AuditTrailFieldMode, IGetValueArgs } from "@components/smart/FieldInfo";
import ConfirmationButtons from "../../views/table/ConfirmationButtons";
import Dialog from "../../components/dialog/Dialog";
import SmartField, { ISmartFieldChange } from "../../components/smart/smartField/SmartField";
import { ISelectItem } from "@components/inputs/select/BasicSelect";
import { ColoredText } from "../../global.style";
import { DefaultTheme } from "styled-components";
import { FilterBarGroup, getDefaultFilterGroupDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { FormStorage } from "../../views/formView/FormStorage";
import {
    getCommonFilterDefs,
    getCommonTableColumnsDefs,
    withDisplayName
} from "@components/smart/GeneralFieldDefinition";
import {
    EntitySetName,
    EntityTypeName,
    FiscalPeriodEntity,
    FiscalYearEntity,
    IFiscalYearStatusEntity
} from "@odata/GeneratedEntityTypes";
import * as yup from "yup";
import { ValidationMessage } from "../../model/Validator.types";
import { formatDateToDateString } from "@components/inputs/date/utils";
import { setDefByEntityType } from "../getDefByEntityType";
import { IFilterQuery } from "../../model/TableStorage";
import { FiscalYearStatusCode } from "@odata/GeneratedEnums";
import { IChangedFilter, ISplitPageTableDef } from "../../views/table/TableView.utils";
import { Model } from "../../model/Model";
import { getUtcDateBy, getUtcDayjs } from "../../types/Date";

const yearFilterPath = BindingContext.localContext("year");

const getStatusColor = (StatusCode: string): keyof DefaultTheme => {
    let color: keyof DefaultTheme;

    switch (StatusCode) {
        case FiscalYearStatusCode.Active:
            color = "C_SEM_text_good";
            break;
        case FiscalYearStatusCode.NotUsed:
            color = "C_SEM_text_warning";
            break;
        default:
            color = "C_TEXT_primary";
            break;
    }

    return color;
};

const statusFormatter: TFormatterFn = (val: TValue, args?: IFormatOptions): TCellValue => {
    const title = val as string;
    const color: keyof DefaultTheme = getStatusColor(args.entity.Status?.Code);

    const colorWrapper = <ColoredText color={color}> {val} </ColoredText>;

    return { tooltip: title, value: colorWrapper };
};

export const getFiscalYearStatus = (status: FiscalYearStatusCode): Status => {
    switch (status) {
        case FiscalYearStatusCode.Active:
            return Status.Success;
        case FiscalYearStatusCode.NotUsed:
            return Status.Warning;
    }
    return null;
};

const lockFormatter = (val: TValue, values: IEntity, property: string): TCellValue => {
    let anyUnlocked = false;
    let anyLocked = false;

    if (isDefined(values[property])) { // current entity is one of the nested fiscal periods
        anyUnlocked = !values[property];
        anyLocked = values[property];
    } else { // current entity is fiscal year that averages the values from all the periods
        for (const period of values.Periods) {
            anyUnlocked = anyUnlocked || !period[property];
            anyLocked = anyLocked || period[property];

            if (anyUnlocked && anyLocked) {
                break;
            }
        }
    }

    let Icon;
    let title;

    if (anyUnlocked && !anyLocked) {
        Icon = StatusUnlockedIcon;
        title = i18next.t("FiscalYear:Table.Unlocked");
    } else if (anyLocked && anyUnlocked) {
        Icon = StatusPartlyLockedIcon;
        title = i18next.t("FiscalYear:Table.PartiallyUnlocked");
    } else {
        Icon = StatusLockedIcon;
        title = i18next.t("FiscalYear:Table.Locked");
    }

    return { tooltip: title, value: <Icon title={title} width={IconSize.S} height={IconSize.S}/> };
};

const lockedApFormatter = (val: TValue, args?: IFormatOptions): TCellValue => {
    return lockFormatter(val, args.entity, "IsLockedForAP");
};

const lockedArFormatter = (val: TValue, args?: IFormatOptions): TCellValue => {
    return lockFormatter(val, args.entity, "IsLockedForAR");
};

const periodNameFormatter = (val: TValue, args?: IFormatOptions): TCellValue => {
    return args.entity.Name;
};

const yearFormatter = (val: TValue, args?: IFormatOptions): string => {
    const startYear = args.entity.DateStart.getFullYear();
    const endYear = args.entity.DateEnd.getFullYear();

    return startYear === endYear ? startYear.toString() : `${startYear}/${endYear}`;
};

// field is editable only for unused FiscalYear
const isReadOnlyStrict = (args: IGetValueArgs) => {
    return args.storage.data.entity?.StatusCode !== FiscalYearStatusCode.NotUsed;
};

// field is editable even in active FiscalYear
const isReadOnlyLoose = (args: IGetValueArgs) => {
    return args.storage.data.entity?.StatusCode === FiscalYearStatusCode.Closed;
};

const fiscalPeriodYearPath = BindingContext.localContext("Year");

function getFiscalYearNumberItems(storage: FormStorage): ISelectItem[] {
    const { entity, bindingContext, additionalResults } = storage.data;
    const fiscalYearId = bindingContext.getKey();
    const otherFiscalYears = (additionalResults[1]?.value ?? [])
        .filter((fiscalYear: IEntity) => bindingContext.isNew() || fiscalYear.Id !== fiscalYearId);

    const possibleNumbers = getFiscalYearNumbers(entity.DateStart, entity.DateEnd, otherFiscalYears);

    return possibleNumbers.map(number => ({ id: number, label: number }));
}

const getLockItems = () => (
    [
        {
            label: i18next.t("FiscalYear:Table.Unlocked"),
            id: false
        },
        {
            label: i18next.t("FiscalYear:Table.Locked"),
            id: true
        }
    ]
);

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const table: ISplitPageTableDef = {
        filterBarDef: [{
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            defaultFilters: [
                "Number",
                "Periods/Name",
                yearFilterPath,
                "Status",
                "Periods/IsLockedForAP",
                "Periods/IsLockedForAR"
            ],
            filterDefinition: {
                Number: {
                    id: "Number", isValueHelp: false, filterName: ["", `/Periods/Number`],
                    // get around Number MaxLength 4 (Periods/Number doesn't have this restriction)
                    validator: {
                        type: ValidatorType.Custom,
                        settings: {
                            customSchema: (args: IGetValueArgs) => {
                                return yup.string()
                                        .test("required", ValidationMessage.Required,
                                                function (this: yup.TestContext, value: TValue) {
                                                    return true;
                                                }).nullable();
                            }
                        }
                    }
                },
                "Periods/Name": {
                    id: "Periods/Name", isValueHelp: false, description: false
                },
                DateStart: {
                    id: "DateStart", isValueHelp: false, filterName: ["", `/Periods/DateStart`]
                },
                DateEnd: {
                    id: "DateEnd", isValueHelp: false, filterName: ["", `/Periods/DateEnd`]
                },
                [yearFilterPath]: {
                    id: yearFilterPath,
                    label: i18next.t("FiscalYear:Columns.Year"),
                    isValueHelp: false,
                    filter: {
                        buildFilter: (item: IChangedFilter): IFilterQuery | string => {
                            const year = item.value as number;

                            if (!year) {
                                return "";
                            }

                            const yearDate = getUtcDayjs(getUtcDateBy(year));
                            const yearStart = formatDateToDateString(yearDate.startOf("year"));
                            const yearEnd = formatDateToDateString(yearDate.endOf("year"));

                            const mainQuery = `DateStart ge ${yearStart} AND DateEnd le ${yearEnd}`;
                            const nestedQuery = `(${mainQuery} OR Periods/any(x: x/DateStart ge ${yearStart} AND x/DateEnd le ${yearEnd}))`;

                            return {
                                query: nestedQuery,
                                collectionQueries: {
                                    Periods: { query: mainQuery }
                                }
                            };
                        }
                    },
                    validator: {
                        type: ValidatorType.Custom,
                        settings: {
                            customSchema: () => yup.number().typeError(ValidationMessage.NotAYear).nullable().positive(ValidationMessage.NotAYear).integer(ValidationMessage.NotAYear)
                        }
                    }
                },
                ...withDisplayName("Status"),
                "Periods/IsLockedForAP": {
                    id: "Periods/IsLockedForAP",
                    description: false,
                    isValueHelp: false,
                    type: FieldType.ComboBox,
                    fieldSettings: { items: getLockItems(), noRecordText: i18next.t("Common:Select.All") }
                },
                "Periods/IsLockedForAR": {
                    id: "Periods/IsLockedForAR",
                    description: false,
                    isValueHelp: false,
                    type: FieldType.ComboBox,
                    fieldSettings: { items: getLockItems(), noRecordText: i18next.t("Common:Select.All") }
                },
                ...getCommonFilterDefs()
            },

            isValueHelp: true
        }],
        id: "fiscalYears",
        initialSortBy: [{ id: "Number", sort: Sort.Desc }],
        columns: [
            "Number",
            "Periods/Name",
            "DateStart",
            "DateEnd",
            "Status",
            // virtual column without representation in metadata
            fiscalPeriodYearPath,
            "Periods/IsLockedForAP",
            "Periods/IsLockedForAR"
        ],
        columnDefinition: {
            Number: { id: "Number", isDisabled: true, isRequired: true },
            DateStart: { id: "DateStart" },
            DateEnd: { id: "DateEnd" },
            Status: { id: "Status", fieldSettings: { displayName: "Name" }, formatter: statusFormatter },
            ...getCommonTableColumnsDefs(),
            [fiscalPeriodYearPath]: {
                label: i18next.t("FiscalYear:Columns.Year"),
                formatter: yearFormatter,
                fieldSettings: { disableSort: true },
                additionalProperties: [{ id: "/DateStart" }, { id: "/DateEnd" }]

            },
            "Periods/Name": {
                id: "Periods/Name",
                formatter: periodNameFormatter,
                // so far, SmartTable and export don't support sort on children
                fieldSettings: {
                    disableSort: true
                }
            },
            "Periods/IsLockedForAP": {
                id: "Periods/IsLockedForAP", textAlign: TextAlign.Center, formatter: lockedApFormatter,
                // we have to add additionalProperties to some of the columns to load all the needed properties of Periods
                additionalProperties: [{
                    id: "/Periods/Name"
                }, {
                    id: "/Periods/Number"
                }, {
                    id: "/Periods/DateStart"
                }, {
                    id: "/Periods/DateEnd"
                }],
                fieldSettings: { disableSort: true }
            },
            "Periods/IsLockedForAR": {
                id: "Periods/IsLockedForAR",
                textAlign: TextAlign.Center,
                formatter: lockedArFormatter,
                fieldSettings: { disableSort: true }
            }
        },
        title: i18next.t("FiscalYear:Title"),
        hierarchy: "Periods"
    };

    const summary: ISummaryItem[] = [
        {
            id: "Number",
            isReadOnly: (args: IGetValueArgs) => {
                if (args.data.StatusCode === FiscalYearStatusCode.NotUsed) {
                    const items = getFiscalYearNumberItems(args.storage as FormStorage);
                    return items.length === 1;
                }
                return true;
            },
            renderEdit: (args: ISummaryItemEditProps) => {
                const onChange = (event: ISmartFieldChange) => {
                    args.storage.handleTemporalChange(event);
                    args.storage.refreshFields();
                };

                const onClose = () => {
                    args.storage.cancelFields([args.bindingContext]);
                    args.onClose();
                };

                const onConfirm = async () => {
                    if (!args.storage.getValue(args.bindingContext)) {
                        args.storage.cancelFields([args.bindingContext]);
                        args.onClose();
                        return;
                    }

                    await args.storage.confirmFields([args.bindingContext]);

                    const number = args.storage.getValue(args.bindingContext);
                    updatePeriodNumbers(args.storage, number);

                    args.storage.refreshFields();
                    args.onClose();
                };

                const items = getFiscalYearNumberItems(args.storage);

                return (
                    <Dialog title={args.storage.t("FiscalYear:Form.ChangeNumber")}
                            onClose={onClose}
                            onConfirm={onConfirm}
                            footer={<ConfirmationButtons onConfirm={onConfirm} onCancel={onClose} useWrapper={false}/>}>
                        <SmartField bindingContext={args.bindingContext}
                                    storage={args.storage}
                                    onTemporalChange={onChange}
                                    fieldDef={{
                                        isConfirmable: true,
                                        type: FieldType.ComboBox,
                                        fieldSettings: {
                                            items
                                        }
                                    }}/>
                    </Dialog>
                );
            }
        },
        {
            id: "Status", label: i18next.t("FiscalYear:Columns.Status"),
            formatter: (value: TValue, args: IFormatOptions) => {
                return (value as IFiscalYearStatusEntity)?.Name;
            },
            colorFormatter: (value: TValue, args: IFormatOptions) => {
                return getStatusColor((value as IFiscalYearStatusEntity)?.Code);
            }
        }
    ];

    const form: IFormDef = {
        id: "fiscalYearForm",
        translationFiles: getDefinitions.translationFiles,
        summary,
        isDeletable: true,
        getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t("FiscalYear:NewPeriod"),
                        storage.data.entity?.Name ?? storage.data.entity?.Number),
        formControl: FiscalYearFormView,
        isReadOnly: isReadOnlyLoose,

        // we need to load ChartOfAccounts and Company to perform check for activate button
        additionalProperties: [{ id: "ChartOfAccounts" }, { id: "Company" }, { id: "StatusCode" }, { id: "Status/Name" }],
        fieldDefinition: {
            StatusCode: {
                customizationData: {
                    useForCustomization: false
                },
                defaultValue: FiscalYearStatusCode.NotUsed,
                // present only for saving purposes, not supposed to be edited
                isVisible: false,
                clearIfInvisible: false
            },
            DateStart: {
                type: FieldType.MonthYear,
                defaultValue: () => {
                    return getDefaultFiscalYearDateStart();
                },
                groupedField: GroupedField.NoWrapStart,
                isReadOnly: isReadOnlyStrict
            },
            DateEnd: {
                type: FieldType.MonthYear,
                groupedField: GroupedField.NoWrapEnd,
                isReadOnly: isReadOnlyStrict
            },
            [DefaultPeriodsPath]: {
                type: FieldType.SegmentedButton,
                isRequired: true,
                isDisabled: isReadOnlyStrict,
                label: i18next.t("FiscalYear:Form.Period"),
                labelStatus: LabelStatus.Removed,
                auditTrailMode: AuditTrailFieldMode.Hidden,
                fieldSettings: {
                    items: [
                        {
                            id: FiscalYearDefaultPeriod.Monthly,
                            label: i18next.t("FiscalYear:DefaultPeriods.Monthly")
                        },
                        {
                            id: FiscalYearDefaultPeriod.Quarterly,
                            label: i18next.t("FiscalYear:DefaultPeriods.Quarterly")
                        },
                        {
                            id: FiscalYearDefaultPeriod.Custom,
                            label: i18next.t("FiscalYear:DefaultPeriods.Custom")
                        }
                    ]
                }
            },
            "Periods/Number": {
                type: FieldType.Text,
                auditTrailMode: AuditTrailFieldMode.DisplayedButIgnored
            },
            "Periods/Name": {
                isReadOnly: isReadOnlyLoose
            },
            "Periods/DateStart": {
                type: FieldType.MonthYear,
                groupedField: GroupedField.NoWrapStart,
                isReadOnly: isReadOnlyStrict
            },
            "Periods/DateEnd": {
                type: FieldType.MonthYear,
                groupedField: GroupedField.NoWrapEnd,
                isReadOnly: isReadOnlyStrict
            },
            "Periods/IsLockedForAP": {
                type: FieldType.Checkbox,
                fieldSettings: {
                    checkBoxFieldLabel: i18next.t("FiscalYear:Form.Lock")
                },
                isRequired: false,
                isReadOnly: isReadOnlyLoose,
                width: "fit-content",
                groupedField: GroupedField.MultiStart
            },
            "Periods/IsLockedForAR": {
                type: FieldType.Checkbox,
                labelStatus: LabelStatus.Hidden,
                isRequired: false,
                isReadOnly: isReadOnlyLoose,
                width: "fit-content",
                groupedField: GroupedField.MultiEnd
            }
        },
        groups: [
            {
                id: "basic",
                rows: [
                    [{ id: "DateStart" }, { id: "DateEnd" }], [{ id: DefaultPeriodsPath }]
                ]
            },
            {
                id: "Periods",
                collection: FiscalYearEntity.Periods,
                collectionOrder: FiscalPeriodEntity.DateStart,
                isRemovable: (args) => {
                    return args.storage.data.entity?.StatusCode === FiscalYearStatusCode.NotUsed;
                },
                // use common title because of customization
                title: i18next.t("FiscalYear:Form.Period"),
                collectionItemTitle: (index: number) => {
                    return `${index + 1}. ${i18next.t("FiscalYear:Form.Period")}`;
                },
                rows: [[{ id: "Number" }],
                    [{ id: "Name" }, { id: "DateStart" }, { id: "DateEnd" }],
                    [{ id: "IsLockedForAP" }, { id: "IsLockedForAR" }]
                ]
            }
        ]
    };

    return {
        entitySet: EntitySetName.FiscalYears,
        table,
        form
    };
};

getDefinitions.translationFiles = ["FiscalYear"];
setDefByEntityType(EntityTypeName.FiscalYear, getDefinitions);