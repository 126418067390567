import { ISelectItem } from "@components/inputs/select/BasicSelect";
import { SwitchType } from "@components/inputs/switch/Switch";
import { ifAll, IFieldDef, IGetValueArgs } from "@components/smart/FieldInfo";
import {
    getAddressFields,
    getCommonFilterDefs,
    getCommonTableColumnsDefs,
    getSavedAccountsDef,
    PhoneNumberDef,
    SingleBusinessPartnerDef,
    SymbolConstantDef,
    withDisplayName
} from "@components/smart/GeneralFieldDefinition";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    TFilterDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import { TCellValue } from "@components/table";
import { createPath, IEntity } from "@odata/BindingContext";
import {
    BusinessPartnerBankAccountEntity,
    BusinessPartnerContactEntity,
    BusinessPartnerEntity,
    BusinessPartnerIssuedDocumentDefaultEntity,
    EntitySetName,
    EntityTypeName,
    IBusinessPartnerBankAccountEntity,
    IBusinessPartnerEntity,
    ICompanyBankAccountEntity,
    VatClassificationEntity
} from "@odata/GeneratedEntityTypes";
import { CountryCode, PayableReceivableTypeCode, PaymentMethodCode, VatStatusCode } from "@odata/GeneratedEnums";
import { ICreateFilterStringSettings } from "@odata/OData.utils";
import { getSimpleAccountAssignmentFieldDef } from "@pages/accountAssignment/AccountAssignment.utils";
import { createBankAccountsItems } from "@utils/BankUtils";
import { isAccountAssignmentCompany } from "@utils/CompanyUtils";
import { forEachKey, isNotDefined } from "@utils/general";
import i18next from "i18next";
import React from "react";

import { IAppContext } from "../../contexts/appContext/AppContext.types";
import {
    BankAccountType,
    BasicInputSizes,
    CacheStrategy,
    FieldType,
    GroupedField,
    LabelStatus,
    QueryParam,
    Sort,
    ValidatorType
} from "../../enums";
import { ColoredText } from "../../global.style";
import { TValue } from "../../global.types";
import { Model } from "../../model/Model";
import { TableStorage } from "../../model/TableStorage";
import { IFormDef } from "../../views/formView/Form";
import { FormStorage } from "../../views/formView/FormStorage";
import { IChangedFilter, ISplitPageTableDef } from "../../views/table/TableView.utils";
import {
    buildComplexBankAccountFilterFromBankAccountString,
    getBankAccountsFieldBaseDef,
    removeInactiveAccounts
} from "../banks/bankAccounts/BankAccounts.utils";
import { companyIsSecondaryAddressPath } from "../companies/Company.shared.utils";
import { isSecondaryAddressVisible } from "../companies/CompanyDef";
import { DefaultDueDays } from "../documents/Document.utils";
import { getActiveChartOfAccountsId } from "../fiscalYear/FiscalYear.utils";
import { setDefByEntityType } from "../getDefByEntityType";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition, TFieldsDefinition } from "../PageUtils";
import {
    IBusinessPartnerCustomData,
    ReceivedBankAccountDefaultsPath,
    ShowIssuedDefaultsPath,
    ShowReceivedDefaultsPath
} from "./BusinessPartner.utils";
import BusinessPartnerFormView from "./BusinessPartnerFormView";

const isReceivedDefaultsVisible = (args: IGetValueArgs): boolean => {
    return !!args.storage.getValueByPath(ShowReceivedDefaultsPath);
};

const isIssuedDefaultsVisible = (args: IGetValueArgs): boolean => {
    return !!args.storage.getValueByPath(ShowIssuedDefaultsPath);
};

const PartnerSingleBusinessPartnerDef = {
    ...SingleBusinessPartnerDef,
    columns: [{
        id: "Name"
    }, {
        id: "LegalNumber"
    }]
};

const isAutomaticallyUpdatedBusinessPartner = (args: IGetValueArgs) => {
    return !!args.data?.IsSynchronizedWithAres;
};

export const partnerAresFields = [
    {
        from: "Name"
    },
    {
        from: "LegalNumber"
    },
    {
        from: "BillingAddress/Street"
    },
    {
        from: "BillingAddress/City"
    },
    {
        from: "BillingAddress/Country"
    },
    {
        from: "BillingAddress/PostalCode"
    },
    {
        from: "VatStatus",
        to: "VatStatus"
    },
    {
        from: "TaxNumber",
        to: "TaxNumber"
    }];


const getDefaultBankAccountAffectedFields = (prefix: string) => {
    return [{
        id: `${prefix}/SymbolVariable`
    }, {
        id: `${prefix}/SymbolSpecific`
    }, {
        id: `${prefix}/SymbolConstant`
    }, {
        id: `${prefix}/RemittanceInformation`
    }];
};

const getDocumentDefaults = (prefix: BusinessPartnerEntity.ReceivedDocumentDefault | BusinessPartnerEntity.IssuedDocumentDefault): TFieldsDefinition => {
    const isReceivedType = prefix === BusinessPartnerEntity.ReceivedDocumentDefault;
    const isVisibleDefaultCallback = isReceivedType ? isReceivedDefaultsVisible : isIssuedDefaultsVisible;
    const _isVisible = (args: IGetValueArgs) => {
        return args.storage.data?.entity?.[prefix]?.DefaultPaymentMethod?.Code === PaymentMethodCode.WireTransfer;
    };

    const getCountry = (args: IGetValueArgs) => {
        if (prefix === BusinessPartnerEntity.ReceivedDocumentDefault) {
            const account = (args.storage.data.entity.BankAccounts || []).find((ba: IBusinessPartnerBankAccountEntity) => ba.IsDefaultForReceivedDocuments);
            return account?.Country?.Code;
        }

        if (prefix === BusinessPartnerEntity.IssuedDocumentDefault) {
            const id = args.storage.data.entity?.IssuedDocumentDefault?.CompanyBankAccount?.Id;
            const account = args.storage.context.getCompanyBankAccounts()?.find((acc: ICompanyBankAccountEntity) => acc.Id === id);
            return account?.CountryCode;
        }

        return "";
    };

    const _isSpecialVisible = (args: IGetValueArgs) => {
        return _isVisible(args) && getCountry(args) === CountryCode.CzechRepublic;
    };

    const _isRemittanceVisible = (args: IGetValueArgs) => {
        return _isVisible(args);
    };

    return {
        [`${prefix}/DefaultPaymentMethod`]: {
            type: FieldType.ComboBox,
            defaultValue: PaymentMethodCode.WireTransfer,
            fieldSettings: {
                displayName: "Name"
            },
            isVisible: isVisibleDefaultCallback,
            affectedFields: [{
                id: prefix === "ReceivedDocumentDefault" ? ReceivedBankAccountDefaultsPath : "IssuedDocumentDefault/CompanyBankAccount"
            }, ...getDefaultBankAccountAffectedFields(prefix)]
        },
        [`${prefix}/SymbolVariable`]: {
            isVisible: ifAll(_isSpecialVisible, isVisibleDefaultCallback),
            validator: {
                type: ValidatorType.Number
            }
        },
        [`${prefix}/SymbolSpecific`]: {
            isVisible: ifAll(_isSpecialVisible, isVisibleDefaultCallback),
            validator: {
                type: ValidatorType.Number
            }
        },
        [`${prefix}/SymbolConstant`]: {
            ...SymbolConstantDef,
            isVisible: ifAll(_isSpecialVisible, isVisibleDefaultCallback)
        },
        [`${prefix}/RemittanceInformation`]: {
            isVisible: ifAll(_isRemittanceVisible, isVisibleDefaultCallback),
            width: BasicInputSizes.XL
        },
        [`${prefix}/VatClassification`]: {
            width: BasicInputSizes.XL,
            type: FieldType.ComboBox,
            isVisible: isVisibleDefaultCallback,
            fieldSettings: {
                displayName: "Name",
                preloadItems: true,
                noRecordText: i18next.t("Common:Select.NoRecord"),
                transformFetchedItems: (items, args) => {
                    const isVatRegistered = (args.storage.data.entity as IBusinessPartnerEntity).VatStatusCode === VatStatusCode.VATRegistered;
                    const type = isReceivedType ? PayableReceivableTypeCode.Payable : PayableReceivableTypeCode.Receivable;
                    return items?.filter(item => {
                        return item.additionalData.IsVatRegistered === isVatRegistered && item.additionalData.PayableReceivableTypeCode === type;
                    });
                },
                additionalProperties: [
                    { id: VatClassificationEntity.IsVatRegistered },
                    { id: VatClassificationEntity.PayableReceivableTypeCode }
                ]
            }
        },
        [`${prefix}/AccountAssignment`]: {
            ...getSimpleAccountAssignmentFieldDef(),
            isVisible: isVisibleDefaultCallback,
            fieldSettings: {
                ...getSimpleAccountAssignmentFieldDef().fieldSettings,
                queryParams: (args: IGetValueArgs): Record<string, string> => {
                    // special parameter "charted" will only retrieve account assignments
                    // related to the given CoA
                    return {
                        charted: getActiveChartOfAccountsId(args.context)?.toString(),
                        [QueryParam.CompanyId]: args.context.getCompanyId()?.toString()
                    };
                }
            },
            customizationData: {
                useForCustomization: (args: IGetValueArgs) => isAccountAssignmentCompany(args.context)
            }
        }
    };
};

const getDocumentDefaultsForTableDef = (prefix: BusinessPartnerEntity.ReceivedDocumentDefault | BusinessPartnerEntity.IssuedDocumentDefault, isFilter?: boolean): TFieldsDefinition => {
    let defs: TFieldsDefinition = {
        ...withDisplayName(`${prefix}/${BusinessPartnerIssuedDocumentDefaultEntity.DefaultPaymentMethod}`),
        [`${prefix}/${BusinessPartnerIssuedDocumentDefaultEntity.RemittanceInformation}`]: {},
        [`${prefix}/${BusinessPartnerIssuedDocumentDefaultEntity.SymbolConstant}`]: {},
        [`${prefix}/${BusinessPartnerIssuedDocumentDefaultEntity.SymbolSpecific}`]: {},
        [`${prefix}/${BusinessPartnerIssuedDocumentDefaultEntity.SymbolVariable}`]: {},
        [`${prefix}/${BusinessPartnerIssuedDocumentDefaultEntity.DaysDue}`]: {},
        ...withDisplayName(`${prefix}/${BusinessPartnerIssuedDocumentDefaultEntity.VatClassification}`),
        ...withDisplayName(`${prefix}/${BusinessPartnerIssuedDocumentDefaultEntity.AccountAssignment}`)
    };

    if (isFilter) {
        (defs[`${prefix}/${BusinessPartnerIssuedDocumentDefaultEntity.RemittanceInformation}`] as TFilterDef).isValueHelp = false;
    }

    if (prefix === BusinessPartnerEntity.IssuedDocumentDefault) {
        defs = {
            ...defs,
            ...withDisplayName(`${BusinessPartnerEntity.IssuedDocumentDefault}/${BusinessPartnerIssuedDocumentDefaultEntity.CompanyBankAccount}`)
        };
    }

    return defs;
};

const bankAccountAffectedFields = [{
    id: ReceivedBankAccountDefaultsPath
}];

export const partnerFormatter = (val: TValue, values: IEntity): TCellValue => {
    if (!val) {
        return undefined as TCellValue;
    }
    const title = val as string;
    const instance = (
        <b>{val}</b>
    );

    return { tooltip: title, value: instance };
};

export const vatPayerFormatter = (val: TValue, values: IEntity): TCellValue => {
    if (values.entity.VatStatus?.Code === VatStatusCode.NotVATRegistered) {
        return undefined as TCellValue;
    }
    const title = val ? i18next.t("BusinessPartner:NotReliable") : i18next.t("BusinessPartner:Reliable");
    const instance = <ColoredText color={val ? "C_SEM_text_bad" : "C_SEM_text_good"}>{title}</ColoredText>;

    return { tooltip: title, value: instance };
};

export const getDefinitions: IGetDefinition = (context: IAppContext): IDefinition => {
    const table: ISplitPageTableDef = {
        filterBarDef: [{
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            isValueHelp: true,
            defaultFilters: [
                "Name", "LegalNumber", "TaxNumber", "VatStatus",
                createPath(BusinessPartnerEntity.BankAccounts, BusinessPartnerBankAccountEntity.AccountNumber),
                createPath(BusinessPartnerEntity.Contacts, BusinessPartnerContactEntity.LastName),
                createPath(BusinessPartnerEntity.Contacts, BusinessPartnerContactEntity.Email)
            ],
            filterDefinition: {
                Name: {},
                LegalNumber: {},
                TaxNumber: {},
                ...withDisplayName("VatStatus"),
                IsUnreliableVatPayer: {
                    label: i18next.t("BusinessPartner:ReliabilityCheck")
                },
                Note: { isValueHelp: false },
                "BillingAddress/City": {},
                ...withDisplayName("BillingAddress/Country"),
                "BillingAddress/PostalCode": {},
                "BillingAddress/Street": {},
                "CorrespondenceAddress/City": {},
                ...withDisplayName("CorrespondenceAddress/Country"),
                "CorrespondenceAddress/PostalCode": {},
                "CorrespondenceAddress/Street": {},
                ...getDocumentDefaultsForTableDef(BusinessPartnerEntity.ReceivedDocumentDefault, true),
                ...getDocumentDefaultsForTableDef(BusinessPartnerEntity.IssuedDocumentDefault, true),
                [createPath(BusinessPartnerEntity.BankAccounts, BusinessPartnerBankAccountEntity.AccountNumber)]: {
                    description: i18next.t("BusinessPartner:Form.BankAccounts"),
                    isValueHelp: false,
                    filter: {
                        buildFilter: (item: IChangedFilter, settings: ICreateFilterStringSettings, storage: TableStorage) => {
                            return buildComplexBankAccountFilterFromBankAccountString(item, storage, "x");
                        }
                    }
                },
                [createPath(BusinessPartnerEntity.Contacts, BusinessPartnerContactEntity.FirstName)]: { isValueHelp: false },
                [createPath(BusinessPartnerEntity.Contacts, BusinessPartnerContactEntity.LastName)]: { isValueHelp: false },
                [createPath(BusinessPartnerEntity.Contacts, BusinessPartnerContactEntity.Email)]: { isValueHelp: false },


                ...getCommonFilterDefs()
            }
        }],
        id: "businessPartners",
        columns: [
            "Name", "LegalNumber", "TaxNumber", "VatStatus"
        ],
        columnDefinition: {
            Name: {
                formatter: partnerFormatter
            },
            LegalNumber: {},
            TaxNumber: {},
            VatStatus: {
                fieldSettings: {
                    displayName: "Name"
                }
            },
            IsUnreliableVatPayer: {
                formatter: vatPayerFormatter,
                label: i18next.t("BusinessPartner:ReliabilityCheck")
            },
            Note: {},
            ...getCommonTableColumnsDefs(),
            "BillingAddress/City": {},
            ...withDisplayName("BillingAddress/Country"),
            "BillingAddress/PostalCode": {},
            "BillingAddress/Street": {},
            "CorrespondenceAddress/City": {},
            ...withDisplayName("CorrespondenceAddress/Country"),
            "CorrespondenceAddress/PostalCode": {},
            "CorrespondenceAddress/Street": {},
            ...getDocumentDefaultsForTableDef(BusinessPartnerEntity.ReceivedDocumentDefault),
            ...getDocumentDefaultsForTableDef(BusinessPartnerEntity.IssuedDocumentDefault)

        },
        title: i18next.t("BusinessPartner:Title"),
        initialSortBy: [{
            id: "Name", sort: Sort.Asc
        }]
    };

    const summary: ISummaryItem[] = [
        { id: "Name" },
        {
            id: "IsUnreliableVatPayer",
            label: i18next.t("BusinessPartner:ReliabilityCheck"),
            formatter: (val: TValue, data?: IEntity) => {
                return i18next.t(`BusinessPartner:${isNotDefined(val) ? "Unknown" : (val ? "NotReliable" : "Reliable")}`).toString();
            },
            colorFormatter: (val: TValue, data?: IEntity) => {
                return isNotDefined(val) ? "C_SEM_text_warning" : (val ? "C_SEM_text_bad" : "C_SEM_el_good");
            },
            isVisible: (args) => {
                const BusinessPartner: IBusinessPartnerEntity = args.data;
                return [VatStatusCode.VATRegistered, VatStatusCode.IdentifiedPerson].includes(BusinessPartner?.VatStatus?.Code as VatStatusCode);
            }
        }];

    const _savedAcc = getSavedAccountsDef();

    const dateDueDefaultDef = {
        fieldSettings: {
            showSteppers: true,
            min: 0
        },
        validator: {
            type: ValidatorType.Integer,
            settings: {
                min: 0
            }
        },
        defaultValue: DefaultDueDays,
        type: FieldType.NumberInput,
        width: "125px" // TODO according to zeplin this should be BasicInputSizes.S, but czech translation is 2px longer https://app.zeplin.io/project/62eba22ff62347721cec129f/screen/5fc7acdfb17cc20f11239d72
    };

    const BaseBankFieldDefs = getBankAccountsFieldBaseDef();

    const defaultValuesReceivedRows: (IFieldDef[])[] = [[
        { id: "ReceivedDocumentDefault/DefaultPaymentMethod" },
        { id: "ReceivedDocumentDefault/DaysDue" }
    ], [
        { id: ReceivedBankAccountDefaultsPath },
        { id: "ReceivedDocumentDefault/SymbolVariable" },
        { id: "ReceivedDocumentDefault/SymbolSpecific" },
        { id: "ReceivedDocumentDefault/SymbolConstant" },
        { id: "ReceivedDocumentDefault/RemittanceInformation" }
    ], [
        { id: "ReceivedDocumentDefault/VatClassification" }
    ]];

    const defaultValuesIssuedRows: (IFieldDef[])[] = [[
        { id: "IssuedDocumentDefault/DefaultPaymentMethod" },
        { id: "IssuedDocumentDefault/DaysDue" }
    ], [
        { id: "IssuedDocumentDefault/CompanyBankAccount" },
        { id: "IssuedDocumentDefault/SymbolVariable" },
        { id: "IssuedDocumentDefault/SymbolSpecific" },
        { id: "IssuedDocumentDefault/SymbolConstant" },
        { id: "IssuedDocumentDefault/RemittanceInformation" }
    ], [
        { id: "IssuedDocumentDefault/VatClassification" }
    ]];

    if (isAccountAssignmentCompany(context)) {
        defaultValuesReceivedRows[2].push(
            { id: "ReceivedDocumentDefault/AccountAssignment" }
        );

        defaultValuesIssuedRows[2].push(
            { id: "IssuedDocumentDefault/AccountAssignment" }
        );
    }

    const form: IFormDef = {
        id: "businessPartnerForm",
        getItemBreadCrumbText: (storage: Model) =>
            getItemBreadCrumbsText(storage, i18next.t("BusinessPartner:New"), storage.data.entity?.Name),
        summary,
        isDeletable: true,
        formControl: BusinessPartnerFormView,
        translationFiles: getDefinitions.translationFiles,
        additionalProperties: [{ id: "Company" }, { id: "IsSynchronizedWithAres" }],
        groups: [
            {
                id: "Note",
                title: i18next.t("Common:General.Note"),
                rows: [[{ id: "Note" }]]
            },
            {
                id: "basic",
                title: i18next.t("BusinessPartner:Form.Basic"),
                rows: [
                    [{ id: "Name" }, { id: "LegalNumber" }, { id: "IsSynchronizedWithAres" }, { id: "VatStatus" }, { id: "TaxNumber" }]
                ]
            },
            {
                id: "Address",
                title: i18next.t("BusinessPartner:Form.Address"),
                rows: [[{ id: "BillingAddress/Street" }, { id: "BillingAddress/City" }, { id: "BillingAddress/PostalCode" }, { id: "BillingAddress/Country" }],
                    [{ id: companyIsSecondaryAddressPath }],
                    [{ id: "CorrespondenceAddress/Street" }, { id: "CorrespondenceAddress/City" }, { id: "CorrespondenceAddress/PostalCode" }, { id: "CorrespondenceAddress/Country" }]
                ]
            },
            {
                id: "BankAccounts", // to be compatible with FormView.load
                title: i18next.t("BusinessPartner:Form.BankAccounts"),
                lineItems: {
                    useLabelWrapping: false,
                    // disable virtualization to prevent problems caused by the fact
                    // that every row can have different size and number of columns
                    disableVirtualization: true,
                    collection: "BankAccounts",
                    isGroupToggleable: true,
                    showLineNumbers: true,
                    order: "Order",
                    columns: [
                        { id: "Country" },
                        { id: "IsDefaultForReceivedDocuments" },
                        { id: "AbaNumber" },
                        { id: "AccountNumber" },
                        { id: "Bank" },
                        { id: "IBAN" },
                        { id: "SWIFT" }
                    ]
                }
            },
            {
                id: "Contacts",
                title: i18next.t("BusinessPartner:Form.Contacts"),
                lineItems: {
                    // primary contact doesn't seem to be in zeplin anymore => we don't need disableVirtualization
                    // containsPrimary: true,
                    // disable virtualization to prevent problems caused by containsPrimary (todo, probably could be solved)
                    // disableVirtualization: true,
                    // isCollapsible: true, // TODO: what is this? do we need such functionality on Fast Entry List?
                    collection: "Contacts",
                    isGroupToggleable: true,
                    showLineNumbers: true,
                    order: "Order",
                    columns: [{ id: "FirstName" }, { id: "LastName" }, { id: "Email" }, { id: "PhoneNumber" }, { id: "Role" }]
                }
            },
            {
                id: "DefaultValuesReceived",
                title: i18next.t("BusinessPartner:Form.DefaultValuesReceived"),
                togglePropPath: ShowReceivedDefaultsPath,
                rows: defaultValuesReceivedRows,
                customizationData: {
                    isLocked: true
                }
            },
            {
                id: "DefaultValuesIssued",
                title: i18next.t("BusinessPartner:Form.DefaultValuesIssued"),
                togglePropPath: ShowIssuedDefaultsPath,
                rows: defaultValuesIssuedRows,
                customizationData: {
                    isLocked: true
                }
            }
        ],
        fieldDefinition: {
            IsSynchronizedWithAres: {
                type: FieldType.Switch,
                defaultValue: false,
                fieldSettings: {
                    type: SwitchType.YesNo
                },
                label: i18next.t("BusinessPartner:Form.SyncWithAres"),
                isVisible: (args: IGetValueArgs) => {
                    const storage = args.storage as FormStorage<IBusinessPartnerEntity, IBusinessPartnerCustomData>;
                    const value = storage.getEntity().IsSynchronizedWithAres;

                    return value || !!storage.getCustomData().isBusinessPartnerFromAres;
                }
            },
            Note: {
                isConfirmable: true,
                type: FieldType.EditableText,
                labelStatus: LabelStatus.Removed
            },
            Name: {
                ...PartnerSingleBusinessPartnerDef,
                groupedField: GroupedField.MultiStart,
                width: BasicInputSizes.XL
            },
            LegalNumber: {
                ...PartnerSingleBusinessPartnerDef,
                groupedField: GroupedField.MultiEnd,
                width: BasicInputSizes.S
            },
            VatStatus: {
                type: FieldType.ComboBox,
                cacheStrategy: CacheStrategy.EndOfTime,
                fieldSettings: {
                    displayName: "Name"
                },
                defaultValue: () => ({ Code: VatStatusCode.VATRegistered }),
                isDisabled: isAutomaticallyUpdatedBusinessPartner
            },
            TaxNumber: {
                isDisabled: isAutomaticallyUpdatedBusinessPartner
            },
            "BankAccounts/IsDefaultForReceivedDocuments": {
                isVisible: false,
                clearIfInvisible: false,
                customizationData: {
                    useForCustomization: false
                }
            },
            "Contacts/Email": {
                width: BasicInputSizes.L,
                validator: {
                    type: ValidatorType.Email
                }
            },
            "Contacts/PhoneNumber": {
                width: BasicInputSizes.M,
                ...PhoneNumberDef
            },
            "Contacts/Role": {
                width: BasicInputSizes.S
            },
            "Contacts/FirstName": {},
            "Contacts/LastName": {},
            [ReceivedBankAccountDefaultsPath]: {
                ..._savedAcc,
                fieldSettings: {
                    ..._savedAcc.fieldSettings,
                    noRecordText: i18next.t("Common:Select.NoRecord"),
                    itemsForRender: (items, args) => {
                        return createBankAccountsItems({ bankAccounts: args.data?.BankAccounts });
                    }
                },
                width: BasicInputSizes.XL,
                affectedFields: getDefaultBankAccountAffectedFields("ReceivedDocumentDefault"),
                label: i18next.t("BusinessPartner:Form.Account"),
                isVisible: (args: IGetValueArgs) => {
                    return args.storage.data?.entity?.ReceivedDocumentDefault?.DefaultPaymentMethod?.Code === PaymentMethodCode.WireTransfer && isReceivedDefaultsVisible(args);
                }
            },
            [companyIsSecondaryAddressPath]: {
                label: i18next.t("BusinessPartner:Form.AddCorrespondenceAddress"),
                type: FieldType.Switch,
                fieldSettings: { type: SwitchType.YesNo },
                affectedFields: [
                    { id: "CorrespondenceAddress/Street" }, { id: "CorrespondenceAddress/City" },
                    { id: "CorrespondenceAddress/PostalCode" }, { id: "CorrespondenceAddress/Country" }
                ]
            },
            ...getDocumentDefaults(BusinessPartnerEntity.ReceivedDocumentDefault),
            ...getDocumentDefaults(BusinessPartnerEntity.IssuedDocumentDefault),

            ...getAddressFields("BillingAddress", { isDisabled: isAutomaticallyUpdatedBusinessPartner }),
            ...getAddressFields("CorrespondenceAddress", { isVisible: isSecondaryAddressVisible }),

            "ReceivedDocumentDefault/DaysDue": {
                ...dateDueDefaultDef,
                isVisible: isReceivedDefaultsVisible
            },
            "IssuedDocumentDefault/DaysDue": {
                ...dateDueDefaultDef,
                isVisible: isIssuedDefaultsVisible
            },
            "IssuedDocumentDefault/CompanyBankAccount": {
                width: BasicInputSizes.XL,
                type: FieldType.ComboBox,
                ..._savedAcc,
                fieldSettings: {
                    ..._savedAcc.fieldSettings,
                    noRecordText: i18next.t("Common:Select.NoRecord"),
                    // items are set in _savedAcc.fieldSetting, but then itemsFactory is not called at all
                    items: null,
                    itemsFactory: async (args: IGetValueArgs): Promise<ISelectItem[]> => {
                        const accounts = args.storage.context.getCompanyBankAccounts();
                        const allowedTypes = [BankAccountType.Account, BankAccountType.IBANOnly, BankAccountType.ABA];
                        const currentId = (args.storage.data.entity as IBusinessPartnerEntity).IssuedDocumentDefault?.CompanyBankAccount?.Id;
                        return removeInactiveAccounts(createBankAccountsItems({
                            bankAccounts: accounts,
                            hasBusinessPartnerCol: false,
                            allowedTypes
                        }), currentId);
                    },
                    shouldDisplayAdditionalColumns: true
                },
                columns: [{ id: "Label" }, { id: "Name" }],
                affectedFields: getDefaultBankAccountAffectedFields("IssuedDocumentDefault"),
                isVisible: (args: IGetValueArgs) => {
                    return args.storage.data?.entity?.IssuedDocumentDefault?.DefaultPaymentMethod?.Code === PaymentMethodCode.WireTransfer && isIssuedDefaultsVisible(args);
                }
            },
            [ShowReceivedDefaultsPath]: {
                type: FieldType.Switch,
                fieldSettings: {
                    type: SwitchType.YesNo
                },
                affectedFields: defaultValuesReceivedRows.flatMap(row => row),
                customizationData: {
                    useForCustomization: false // group switch
                }
            },
            [ShowIssuedDefaultsPath]: {
                type: FieldType.Switch,
                fieldSettings: {
                    type: SwitchType.YesNo
                },
                affectedFields: defaultValuesIssuedRows.flatMap(row => row),
                customizationData: {
                    useForCustomization: false // group switch
                }
            }
        }

    };

    forEachKey(BaseBankFieldDefs, (key) => {
        form.fieldDefinition[`BankAccounts/${key}`] = {
            ...BaseBankFieldDefs[key],
            affectedFields: bankAccountAffectedFields,
            customizationData: {
                ...(BaseBankFieldDefs[key].customizationData ?? {}),
                useForCustomization: true
            }
        };
    });

    return {
        entitySet: EntitySetName.BusinessPartners,
        table,
        form
    };
};

getDefinitions.translationFiles = ["BusinessPartner", "AccountAssignment", "Common", "Banks"];
setDefByEntityType(EntityTypeName.BusinessPartner, getDefinitions);