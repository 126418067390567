import styled, { css } from "styled-components/macro";
import { StyledToken } from "./Token.styles";
import { TOKEN_MARGIN_RIGHT } from "./Tokenizer.utils";

interface ITokenizerStyleProps {
    _maxWidth?: string;
    isWrappable?: boolean;
    availableWidthLimit?: number;
}

export const StyledTokenizerOuterWrapper = styled.div<ITokenizerStyleProps>`
    display: flex;
    flex: 0 1 auto;
    align-items: center;

    margin-top: -1px;
    margin-right: ${`${TOKEN_MARGIN_RIGHT}px;`}

    position: relative;

    max-width: ${props => `calc(100% - ${TOKEN_MARGIN_RIGHT}px - ${props.availableWidthLimit ? props.availableWidthLimit : 0}px)`};
`;

export const StyledTokenizer = styled.div<ITokenizerStyleProps>`
    display: flex;
    //width: fit-content;
    position: relative;
    max-width: ${props => props._maxWidth};

    flex-wrap: ${props => props.isWrappable ? "wrap" : "nowrap"};

    ${StyledToken}:not(:last-child) {
        margin-right: ${`${TOKEN_MARGIN_RIGHT}px;`}
    }

    ${StyledToken} {
        // prevent initial flickering by hiding all tokens completely by default
        visibility: ${props => props.isWrappable ? "visible" : "hidden"};
    }

    ${props => props.isWrappable && css`
        flex-wrap: wrap;
        // compensate for the token margin
        margin-bottom: -4px;

        ${StyledToken} {
            display: block;
            margin-bottom: 4px;
            width: fit-content;
        }
    `}
`;

export const AfterTokensText = styled.span`
    position: absolute;
`;