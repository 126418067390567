import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import BusyIndicator from "../components/busyIndicator/BusyIndicator";
import { ROUTE_LOGIN } from "../routes";
import store from "./state/store";
import { logout } from "../contexts/authContext/Auth.utils";

interface IProps {
}

const Logout: React.FC<IProps> = () => {
    const [hasSession, setHasSession] = useState<boolean>(!!store.getState().session);

    useEffect(() => {
        logout();

        // however specific listener for store changes needs to be created
        // return it from useEffect to unsubscribe on unmount
        return store.subscribe(() => {
            setHasSession(!store.getState().session);
        });
    }, []);

    if (!hasSession) {
        return <Redirect to={ROUTE_LOGIN}/>;
    } else {
        return <BusyIndicator/>;
    }
};

export default Logout;