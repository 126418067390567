import {
    Condition,
    createFilterRow,
    IComplexFilter,
    isComplexFilter
} from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import { IncomingIcon, OutgoingIcon } from "@components/icon";
import { TValidatorFn } from "@components/smart/FieldInfo";
import { TSmartODataTableStorage } from "@components/smart/smartTable/SmartODataTableBase";
import { ICellValueObject, IRow, TId } from "@components/table";
import { TEntityKey } from "@evala/odata-metadata/src";
import { getNestedValue, setNestedValue } from "@odata/Data.utils";
import {
    BankTransactionEntity,
    IAccountEntity,
    IBankTransactionEntity,
    ICashReceiptEntity,
    IDocumentCbaCategoryEntity,
    IDocumentItemCbaCategoryEntity,
    IPayingDocumentEntity,
    IPaymentDocumentItemAccountAssignmentEntity,
    IPaymentDocumentItemEntity,
    PaymentDocumentItemEntity
} from "@odata/GeneratedEntityTypes";
import { BankTransactionTypeCode, DocumentTypeCode, PostedStatusCode, SelectionCode } from "@odata/GeneratedEnums";
import { createFilterValue, transformToODataString } from "@odata/OData.utils";
import { getCompanyCurrency, isAccountAssignmentCompany, isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { forEachKey, isNotDefined, roundToDecimalPlaces } from "@utils/general";
import { hasPairedChanged, savePairChanges } from "@utils/PairTableUtils";
import i18next from "i18next";
import React, { ReactElement } from "react";
import { ValidationError } from "yup";

import { Button } from "../../../components/button";
import Dialog from "../../../components/dialog";
import { ISelectItem } from "../../../components/inputs/select/BasicSelect";
import { ISmartFieldChange } from "../../../components/smart/smartField/SmartField";
import { TFilterDef } from "../../../components/smart/smartFilterBar/SmartFilterBar.types";
import { getRow } from "../../../components/smart/smartTable/SmartTable.utils";
import { createSelectItem, fetchValueHelperData } from "../../../components/smart/smartValueHelper/ValueHelper.utils";
import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { IconSize, PageViewMode, ValueType } from "../../../enums";
import { TRecordAny, TRecordType, TValue } from "../../../global.types";
import { TableStorage } from "../../../model/TableStorage";
import { ValidationMessage } from "../../../model/Validator.types";
import BindingContext, { IEntity } from "../../../odata/BindingContext";
import { IFieldInfo } from "../../../odata/FieldInfo.utils";
import DateType from "../../../types/Date";
import { FormStorage, IFormStorageDefaultCustomData } from "../../../views/formView/FormStorage";
import { ITableViewBaseProps } from "../../../views/table/TableView";
import {
    getCurrentCoAId,
    handleItemAccAssignmentDialog,
    handleItemDateChange,
    IAccAssDialogCustomData,
    ItemCreateCustomAssignmentBc,
    prepareAccountAssignmentsForSave
} from "../../accountAssignment/AccountAssignment.utils";
import { PAIR_ACTION_ID } from "../../asset/PairingWithAssetTableView";
import { handleItemCbaCategoryChange } from "../../cashBasisAccounting/CashBasisAccounting.utils";
import DialogPage from "../../DialogPage";
import {
    getFiscalDataCorrespondingToDateAccountingTransaction,
    refreshExchangeRateWithoutDefault,
    setNotPostedStatusFilter
} from "../../documents/Document.utils";
import {
    getActiveFiscalYears,
    getFiscalYearByDate,
    getFiscalYears,
    isFiscalYearClosed,
    isInFYorPeriod
} from "../../fiscalYear/FiscalYear.utils";
import { replaceWildcardsAfterDateChange, setMatchingNumberRange } from "../../numberRange/NumberRange.utils";
import AccountSplitDialog from "../AccountSplitDialog";
import {
    getFixedAccountAssignmentAccountNumberForPaymentEntity,
    handleBankAssignmentChange,
    isPaymentOrOther,
    itemHasAssignment
} from "../Pair.utils";
import { IPaymentDocumentItemEntityExpanded } from "../PairTableView";
import { getDefinitions } from "./BankTransactionDocumentPairDef";

export type TPairedDocumentCategoriesMap = Record<number, (IDocumentCbaCategoryEntity | IDocumentItemCbaCategoryEntity)[]>;

// todo not sure about this, maybe it should be moved (and renamed) somewhere to pairing, not BankTransactions
// it is used heavily across all bank related files, but i'm not sure if i managed to apply it properly
export interface IBankCustomData extends IFormStorageDefaultCustomData {
    oldAssSelId?: SelectionCode;
    pairedDialogOpened?: boolean;
    type?: DocumentTypeCode;

    promptDialogOpened?: boolean;
    rootStorage?: FormStorage<unknown, IBankCustomData>;
    pairedStorage?: TSmartODataTableStorage<IBankCustomData>;
    pairedDocuments?: Record<TEntityKey, IPaymentDocumentItemEntityExpanded>;
    pairRedirectUrl?: string;
    showSaveButton?: boolean;
    statementPairedDialogOpened?: boolean;
    documentsExchangeRates?: Map<number, number>;

    amountDialogRow?: IRow;
    defaultExchangeRate?: number;
    pairedDocumentCategoriesMap?: TPairedDocumentCategoriesMap;
    dialogCurrentLineItem?: TRecordAny;
    isCbaCategoryDialogOpen?: boolean;
}

export interface IBankStatementsCustomData extends IFormStorageDefaultCustomData {
    hideTransactions?: boolean;
}

export const getCorrent4Digit = (n: number): string => {
    return String(n).padStart(4, "0");
};

export const setNumberOurs = (numberOurs: string, count: number, storage: FormStorage): void => {
    if (!numberOurs) {
        storage.data.entity.NumberOurs = "";
    } else {
        const transCount = (count || 0) + 1;
        storage.data.entity.NumberOurs = `${numberOurs}-${getCorrent4Digit(transCount)}`;
    }
};

export const createDefaultPairFilter = (storage: TSmartODataTableStorage, transactionType: BankTransactionTypeCode): void => {
    const path = transactionType === BankTransactionTypeCode.OutgoingPayment ? "TransactionAmountToPay" : "TransactionAmountToReceive";

    storage.setFilterValueByPath(path, [createFilterRow({
        value: 0,
        condition: Condition.GreaterThan
    })]);

    setNotPostedStatusFilter(storage);
};

export const getTranBalance = (storage: FormStorage): number => {
    const data = storage.data.entity;
    const tranAmount = Math.abs(data.TransactionAmount) || 0;

    const sum = (storage.data.entity.Items || []).reduce((accumulator: number, link: IPaymentDocumentItemEntity) => {
        const add = isPaymentOrOther(link.PaymentDocumentItemTypeCode) ? link.TransactionAmount || 0 : 0;
        return accumulator + add;
    }, 0);

    return roundToDecimalPlaces(2, tranAmount - sum);
};

export const bankTransactionTypeFormatter = (val: TValue): ICellValueObject => {
    if (val === BankTransactionTypeCode.OutgoingPayment) {
        const title = i18next.t("Banks:Transactions.Out");

        return {
            tooltip: title,
            value: <OutgoingIcon title={title} width={IconSize.M}/>
        };
    }

    const title = i18next.t("Banks:Transactions.In");

    return {
        tooltip: title,
        value: <IncomingIcon title={title} width={IconSize.M}/>
    };
};

export const formatNumberCurrency = (val: number): number => {
    if (isNaN(val)) {
        return val;
    }
    return roundToDecimalPlaces(2, val);
};


export interface ICalculateExchangeRate {
    docCurrency: string;
    tranCurrency: string;
    tranExRate: number;
    docExchangeRate: number;
    docType: DocumentTypeCode;
    currentAmount: number;
    currentExRate?: number;
    ratedAmount?: number;
    type: BankTransactionTypeCode;
    context: IAppContext;
}

export const hasExchangeRate = (docCurrency: string, tranCurrency: string, context: IAppContext): boolean => {
    const companyCurrency = getCompanyCurrency(context);
    return docCurrency !== companyCurrency || tranCurrency !== companyCurrency;
};

export const hasExchangeGain = (docCurrency: string, docType: DocumentTypeCode, context: IAppContext): boolean => {
    const isProforma = docType === DocumentTypeCode.ProformaInvoiceReceived || docType === DocumentTypeCode.ProformaInvoiceIssued;
    return docCurrency !== getCompanyCurrency(context) && !isProforma;
};

export const hasAdditionalExRate = (docCurrency: string, tranCurrency: string, context: IAppContext): boolean => {
    // Every pair with different currencies
    return docCurrency !== tranCurrency && docCurrency !== getCompanyCurrency(context);
};

function getUniqueAbsAmount(items: IEntity[], prefix?: string): IEntity[] {
    const { abs } = Math;
    const uniqSet = new Set<string>();
    const ret: IEntity[] = [];
    const propName = `${prefix}Amount`;
    const currencyPropName = `${prefix}CurrencyCode`;
    items.forEach(item => {
        const val = abs(item[propName]);
        const key = `${item[currencyPropName]}${val}`;
        if (!uniqSet.has(key)) {
            uniqSet.add(key);
            ret.push({
                ...item,
                [propName]: val
            });
        }
    });
    return ret;
}

const getAmountFilterCustomization = (prefix?: string): Partial<TFilterDef> => ({
    fieldSettings: {
        itemsFactory: async ({ bindingContext, storage, info, fetchFn }): Promise<ISelectItem[]> => {
            const opts = {
                bindingContext, fieldInfo: info as IFieldInfo, storage: storage as TableStorage, isEnum: false
            };
            const data = await fetchValueHelperData(opts, fetchFn);
            return getUniqueAbsAmount(data, prefix).map(entity => createSelectItem(entity, opts));
        }
    },
    filter: {
        oDataFn: "abs"
    }
});

export const getAmountFilterDefs = (): Record<string, TFilterDef> => ({
    TransactionAmount: {
        label: i18next.t("Banks:Transactions.Converted"),
        ...getAmountFilterCustomization("Transaction")
    },
    Amount: {
        label: i18next.t("Banks:Transactions.Amount"),
        ...getAmountFilterCustomization()
    },
});

export const getTransactionTypeFromEntity = (storage: FormStorage): BankTransactionTypeCode => {
    if (storage.data.entity.BankTransactionType?.Code) {
        return storage.data.entity.BankTransactionType?.Code;
    }

    const type = storage.getCustomData<IBankCustomData>().type;

    return type === DocumentTypeCode.CashReceiptReceived ? BankTransactionTypeCode.IncomingPayment : BankTransactionTypeCode.OutgoingPayment;
};

export const calculateExchangeRate = (args: ICalculateExchangeRate) => {
    // current ex rate is by default loaded from document ex rate (with fiscal year stuff) so it should be sufficient to compare these two.
    const _hasAdditionalExRate = hasAdditionalExRate(args.docCurrency, args.tranCurrency, args.context);
    const isDiffExRate = _hasAdditionalExRate ? args.currentExRate !== args.docExchangeRate : args.docExchangeRate !== args.tranExRate;
    const _hasExpenseGain = hasExchangeGain(args.docCurrency, args.docType, args.context) && isDiffExRate;

    let exchangeRateDiff = 0;

    if (_hasExpenseGain) {
        if (_hasAdditionalExRate) {
            // we have to round every calculation as it may lead to missing values in some small rounding
            if (isNotDefined(args.ratedAmount)) {
                exchangeRateDiff = NaN;
            } else {
                exchangeRateDiff = formatNumberCurrency(args.currentAmount * (args.tranExRate ?? 1)) -
                    formatNumberCurrency(args.ratedAmount * args.docExchangeRate);
            }
        } else {
            const transactionExchangeRate = args.tranExRate;
            exchangeRateDiff = formatNumberCurrency(args.currentAmount * transactionExchangeRate) -
                formatNumberCurrency(args.currentAmount * args.docExchangeRate);
        }

        const isOutgoing = args.type === BankTransactionTypeCode.OutgoingPayment;
        if (isOutgoing) {
            exchangeRateDiff *= -1;
        }
    }

    return _hasExpenseGain && !isNaN(exchangeRateDiff) ? formatNumberCurrency(exchangeRateDiff) : undefined;
};

export const handleBeforeSave = (storage: FormStorage, entity: IBankTransactionEntity | ICashReceiptEntity): IBankTransactionEntity | ICashReceiptEntity => {
    const tranRate = (entity as IBankTransactionEntity).BankTransactionType?.Code === BankTransactionTypeCode.OutgoingPayment ? -1 : 1;
    entity.TransactionAmount = Math.abs(entity.TransactionAmount || 0) * tranRate;

    const decimalPlaces = storage.data.entity.TransactionCurrency?.MinorUnit ?? 2;
    const rate = storage.data.entity?.ExchangeRatePerUnit ?? 1;

    entity.Amount = roundToDecimalPlaces(decimalPlaces, entity.TransactionAmount * rate);

    const isNew = storage.data.bindingContext.isNew();
    if (isNew) {
        entity.CurrencyCode = getCompanyCurrency(storage.context);
        entity.Company = { Id: storage.context.getCompany().Id };
    }

    if (storage.data.entity?.TransactionCurrency?.Code === getCompanyCurrency(storage.context)) {
        entity.ExchangeRatePerUnit = 1;
    }

    if (isAccountAssignmentCompany(storage.context)) {
        if (entity.Items) {
            entity.Items.forEach((item: IPaymentDocumentItemEntity) => {
                if (isPaymentOrOther(item.PaymentDocumentItemTypeCode)) {
                    prepareAccountAssignmentsForSave(storage, item, {
                        collectionName: "Items",
                        transactionDate: item.DateAccountingTransaction
                    });
                } else {
                    item.TransactionAmount = 0;
                    const coaId = getCurrentCoAId({
                        storage: storage
                    }, { transactionDate: item.DateAccountingTransaction });

                    setNestedValue(coaId, "AccountAssignmentSelection/AccountAssignment/ChartOfAccounts/Id", item);
                    setNestedValue(SelectionCode.Own, "AccountAssignmentSelection/Selection/Code", item);

                    const daNumber = getNestedValue("AccountAssignmentSelection/AccountAssignment/DebitAccount/Number", item);
                    const caNumber = getNestedValue("AccountAssignmentSelection/AccountAssignment/CreditAccount/Number", item);
                    const shortName = `${daNumber}/${caNumber}`;

                    setNestedValue(shortName, "AccountAssignmentSelection/AccountAssignment/Name", item);
                    setNestedValue(shortName, "AccountAssignmentSelection/AccountAssignment/ShortName", item);
                }
            });

            // filter all gains for payments that have no account assignment
            entity.Items = entity.Items.filter((item: IPaymentDocumentItemEntity) => {
                if (isPaymentOrOther(item.PaymentDocumentItemTypeCode)) {
                    return true;
                }

                return itemHasAssignment(item, entity.Items);
            });
        }

        // @see https://solitea-cz.atlassian.net/browse/DEV-17687
        for (const item of (entity.Items ?? [])) {
            if ((item.AccountAssignmentSelection?.AccountAssignment as IEntity)?.[ItemCreateCustomAssignmentBc]) {
                const properties: (keyof IPaymentDocumentItemAccountAssignmentEntity)[] = ["DebitAccount", "CreditAccount"];

                for (const account of properties) {
                    const currentAccount = item.AccountAssignmentSelection.AccountAssignment[account] as IAccountEntity;
                    const {
                        accountPrefix,
                        accountSuffix
                    } = getFixedAccountAssignmentAccountNumberForPaymentEntity(storage.data.bindingContext, entity);

                    if (!currentAccount.Number.startsWith(accountPrefix)) {
                        continue;
                    }

                    const bc = storage.data.bindingContext.navigate("Items").addKey(item).navigate(`AccountAssignmentSelection/AccountAssignment/${account}`);
                    // expect items to be loaded, otherwise ItemCreateCustomAssignment wouldn't be set to true
                    const accounts = storage.getInfo(bc)?.fieldSettings?.items;
                    // the correct, analytical account number
                    // different for BankTransactions and CashReceipts
                    const correctAccountNumber = `${accountPrefix}${accountSuffix}`;
                    const correctAccountItem = accounts?.find(accountItem => (accountItem.additionalData as IAccountEntity).Number === correctAccountNumber);

                    if (correctAccountItem) {
                        (item.AccountAssignmentSelection.AccountAssignment[account] as IAccountEntity) = correctAccountItem.additionalData;
                    }
                }
            }
        }

    }

    return entity;
};


export const refreshFormAfterDateChange = async (storage: FormStorage, bc: BindingContext, newValue: Date): Promise<void> => {
    const oldValue = storage.getValue(bc);
    const fiscalData = getFiscalDataCorrespondingToDateAccountingTransaction(storage, newValue);
    const oldFiscalData = getFiscalDataCorrespondingToDateAccountingTransaction(storage, oldValue);

    const hasChanged = oldFiscalData?.fiscalYear?.Id !== fiscalData?.fiscalYear?.Id;
    if (fiscalData && hasChanged) {
        const changed = await setMatchingNumberRange(storage);
        if (changed) {
            replaceWildcardsAfterDateChange(storage);
        }

        storage.refresh();
    }
};


const finishClosingPrompt = (storage: FormStorage<unknown, IBankCustomData>) => {
    const url = storage.getCustomData().pairRedirectUrl;
    if (url) {
        storage.history.push(url);
    } else {
        storage.refresh();
    }
};

const cancelChanges = (storage: FormStorage<unknown, IBankCustomData>) => {
    storage.setCustomData({
        promptDialogOpened: false,
        pairedDialogOpened: false
    });
    finishClosingPrompt(storage);
};


const cancelPrompt = (storage: FormStorage<unknown, IBankCustomData>) => {
    storage.setCustomData({ promptDialogOpened: false });

    storage.refresh();
};

const savePairChangesAction = async (storage: FormStorage<unknown, IBankCustomData>, type: BankTransactionTypeCode, saveDraftFn?: () => void) => {
    const pairedStorage = storage.getCustomData().pairedStorage;
    const pairedDocuments = pairedStorage.getCustomData().pairedDocuments || {};

    await savePairChanges(storage, pairedDocuments, type);
    saveDraftFn?.();
    storage.setCustomData({
        promptDialogOpened: false,
        pairedDialogOpened: false
    });
    finishClosingPrompt(storage);
};

const displayPromptOnChange = (storage: FormStorage<unknown, IBankCustomData>, route = "") => {
    const pairedStorage = storage.getCustomData().pairedStorage;
    const pairedDocuments = pairedStorage.getCustomData().pairedDocuments || {};

    if (pairedStorage.tableAction === PAIR_ACTION_ID) {
        const hasChanges = hasPairedChanged(storage, pairedDocuments);
        if (hasChanges) {
            storage.setCustomData({
                promptDialogOpened: true,
                pairRedirectUrl: route,
                showSaveButton: !route
            });

            storage.refresh();
            return true;
        }
    }

    return false;
};

const handleClosePairing = (storage: FormStorage<unknown, IBankCustomData>) => {
    if (!displayPromptOnChange(storage)) {
        storage.setCustomData({ pairedDialogOpened: false });
        storage.refresh();
    }
};

export const getBankTransactionType = (storage: FormStorage): BankTransactionTypeCode => {
    return storage.data.entity.BankTransactionType?.Code;
};

export const getCashTransactionType = (storage: FormStorage<unknown, IBankCustomData>): BankTransactionTypeCode => {
    const type = storage.getCustomData().type;
    return type === DocumentTypeCode.CashReceiptReceived ? BankTransactionTypeCode.IncomingPayment : BankTransactionTypeCode.OutgoingPayment;
};


export const renderAssignmentSplitDialog = (storage: FormStorage<unknown, IAccAssDialogCustomData>): ReactElement => {
    const isOpened = storage.getCustomData().isSplitDialogOpened;

    if (!isOpened) {
        return null;
    }

    const dialogBc = storage.getCustomData().AccountAssignmentDialogBc;
    const bc = dialogBc.getParent().getParent().navigate("SplitAccountAssignments");

    return <AccountSplitDialog storage={storage} bindingContext={bc}/>;
};

interface IRenderPairingDialogArgs {
    view: React.ComponentType<ITableViewBaseProps>;
    type: BankTransactionTypeCode;
    saveDraftFn: () => void;
}

export const renderPairingDialog = (storage: FormStorage<unknown, IBankCustomData>, args: IRenderPairingDialogArgs): ReactElement => {
    const showSave = storage.getCustomData().showSaveButton;
    return (
        <>
            {storage.getCustomData().promptDialogOpened &&
                <>
                    <Dialog
                        onConfirm={showSave ? savePairChangesAction.bind(null, storage, args.type, args.saveDraftFn) : null}
                        onClose={cancelPrompt.bind(null, storage)}
                        isConfirmation
                        footer={<>
                            <Button isTransparent onClick={cancelPrompt.bind(null, storage)}>
                                {i18next.t("Common:General.Cancel")}
                            </Button>
                            <Button isTransparent onClick={cancelChanges.bind(null, storage)}>
                                {i18next.t("Common:Form.DiscardChanges")}
                            </Button>

                            {showSave &&
                                <Button
                                    onClick={savePairChangesAction.bind(null, storage, args.type, args.saveDraftFn)}>
                                    {i18next.t("Document:Form.SubmitChanges")}
                                </Button>
                            }
                        </>}>
                        {i18next.t("Common:General.UnsavedChanges")}
                    </Dialog>
                </>
            }
            {storage.getCustomData().pairedDialogOpened &&
                <Dialog
                    isEditableWindow={true}
                    onConfirm={null}
                    onClose={handleClosePairing.bind(null, storage)}>
                    <DialogPage
                        formProps={{
                            isSimple: true,
                            shouldHideVariant: false
                        }}
                        tableViewProps={{
                            saveDraftFn: args.saveDraftFn
                        }}
                        pageViewMode={PageViewMode.FormReadOnly}
                        rootStorage={storage}
                        tableView={args.view}
                        getDef={context => getDefinitions(context, args.type)}/>
                </Dialog>
            }
        </>
    );
};

/**
 * Default filters to be used selecting bank statement on Bank Transaction form
 *  Note: if changed, you may want to edit also isRowWithoutAction on BankTransactionStatementPairTableView
 * @param context
 */
function getDefaultStatementFilters(context: IAppContext): TRecordType<boolean[] | IComplexFilter[]> {
    const filters: TRecordType<boolean[] | IComplexFilter[]> = {
        "BankAccount/IsActive": [true]
    };

    // const oldestOpenFiscalYear = getOpenFiscalYears(context)?.[0];
    // if (oldestOpenFiscalYear) {
    //     filters["DateFrom"] = [createFilterRow({
    //         value: oldestOpenFiscalYear.DateStart,
    //         condition: Condition.IsAfterOrEqualsTo
    //     })];
    // }

    return filters;
}

/**
 * Creates default filter values in storage, filters should be defined in Def, so user can work with them.
 * @param storage
 */
export function createDefaultStatementPairFilter(storage: TSmartODataTableStorage): void {
    const filters = getDefaultStatementFilters(storage.context);

    forEachKey(filters, (key) => {
        storage.setFilterValueByPath(key, filters[key]);
    });
}

export const createStatementFilterQuery = (context: IAppContext, baseBc: BindingContext): string => {
    const filters = getDefaultStatementFilters(context);
    const queries: string[] = [];

    forEachKey(filters, (path) => {
        const value = filters[path];

        value.forEach(val => {
            const query = isComplexFilter(val)
                ? createFilterValue(baseBc.navigate(path), path, val)
                : `${path} eq ${transformToODataString(val, ValueType.Boolean)}`;
            queries.push(query);
        });
    });

    return queries.join(" AND ");
};

export const refreshExRateAndRefreshItems = async (storage: FormStorage, currencyCode: string, date: Date): Promise<void> => {
    await refreshExchangeRateWithoutDefault(storage, currencyCode, date);
    storage.addActiveField(storage.data.bindingContext.navigate(BankTransactionEntity.Items));
    storage.refreshFields();
};

export const isPairingRowReadOnly = (pairItem: IPaymentDocumentItemEntity, storage: TableStorage, rootStorage: FormStorage): boolean => {
    // filled ID === item is already accounted
    if (pairItem?.Id) {
        const date = pairItem.DateAccountingTransaction;
        const fy = getFiscalYearByDate(storage.context, date);

        const pairedDocBc = rootStorage.data.bindingContext.navigate("Items").addKey(pairItem?.Id);
        const isFieldLockedByBe = !!rootStorage.getBackendDisabledFieldMetadata(pairedDocBc);

        return isFieldLockedByBe || isFiscalYearClosed(fy); // TODO: maybe BE message is enought, check
    }

    return false;
};

export const handleTranAmountChange = (storage: FormStorage, args: ISmartFieldChange): void => {
    if (args.bindingContext.getPath() === BankTransactionEntity.TransactionAmount) {
        const amountBc = args.bindingContext.getParent().navigate(BankTransactionEntity.Amount);
        const tranExRate = storage.getEntity().ExchangeRatePerUnit ?? 1;
        const valN = Number(args.value);
        if (!isNaN(valN)) {
            const val = roundToDecimalPlaces(2, valN * tranExRate);
            storage.setValue(amountBc, val);
        }
    }
};

export const handleDateChange = async (storage: FormStorage, args: ISmartFieldChange): Promise<void> => {
    if (args.bindingContext.getPath() === PaymentDocumentItemEntity.DateAccountingTransaction && args.triggerAdditionalTasks) {
        await handleItemDateChange({
            storage,
            bindingContext: args.bindingContext,
            value: args.value as Date
        });

        storage.refreshFields();
    }
};


export const handleLineItemsChange = async (storage: FormStorage, args: ISmartFieldChange): Promise<void> => {
    //BEFORE CHANGE
    if (handleItemAccAssignmentDialog({ storage, e: args })) {
        // Account assignment dialog is shown -> do not process the change further as the dialog might be canceled
        // -> we don't want to keep "Selection.Own" in the select then.
        return;
    }
    handleTranAmountChange(storage, args);

    storage.handleLineItemsChange(args);

    // AFTER CHANGE
    handleDateChange(storage, args);
    handleItemCbaCategoryChange(args, storage);
    if (args.triggerAdditionalTasks) {
        await handleBankAssignmentChange(args, storage);
    }
};

export const paymentDocDateValidator: TValidatorFn = (value: TValue, { storage, bindingContext }) => {
    if (!DateType.isValid(value as Date)) {
        return new ValidationError(ValidationMessage.NotADate, false, bindingContext.getNavigationPath(true));
    }
    if (isCashBasisAccountingCompany(storage.context)) {
        const date = value as Date;
        if (!getActiveFiscalYears(storage.context).some(fy => isInFYorPeriod(fy, date))) {
            const isInactive = getFiscalYears(storage.context).some(fy => isInFYorPeriod(fy, date));
            const translationPath = isInactive ? "ClosedCalendarYear" : "WrongCalendarYear";
            return new ValidationError(i18next.t(`Document:Form.${translationPath}`), false, bindingContext.getNavigationPath(false));
        }
    }
    return true;
};

interface ICanRowBePairedArgs {
    storage: TSmartODataTableStorage;
    origPairedDocuments: Record<TEntityKey, IPaymentDocumentItemEntityExpanded>;
    rowId: TId;
    paymentType: BankTransactionTypeCode;
    row?: IRow;
}

export const canRowBePaired = (args: ICanRowBePairedArgs) => {
    const row = args.row ?? getRow(args.storage.tableAPI.getState().rows, args.rowId);

    if (row?.customData) {
        const key = (args.rowId as BindingContext)?.getKey?.();
        const isPairedSaved = !!args.origPairedDocuments?.[key];
        const path = args.paymentType === BankTransactionTypeCode.OutgoingPayment ? "TransactionAmountToPay" : "TransactionAmountToReceive";

        const amount = row.customData.entity[path];
        const isNotPosted = (row.customData.entity as IPayingDocumentEntity).PostedStatusCode === PostedStatusCode.NotPosted;

        return (isPairedSaved || amount > 0) && !isNotPosted;
    }

    return true;
};

export const isPairingActive = (tableStorage: TableStorage) => {
    return (tableStorage as TableStorage).tableAction === PAIR_ACTION_ID;
};