import React from "react";
import { ItemDescription, ItemTitle, StyledProductTypeItem } from "./PurchaseWizard.styles";
import { KeyName } from "../../keyName";
import TestIds from "../../testIds";

interface IProps {
    id?: string;
    title: string;
    description?: string;
    isCentered?: boolean;
    isDisabled?: boolean;
    isSelected?: boolean;
    isWithoutDescription?: boolean;
    onClick?: (id: string) => void;
}

export default class PurchaseWizardTile extends React.PureComponent<IProps> {
    handleClick = (): void => {
        this.props.onClick?.(this.props.id);
    };

    handleKeyDown = (event: React.KeyboardEvent): void => {
        if (event.key === KeyName.Enter) {
            this.handleClick();
        }
    };

    render() {
        return (
            <StyledProductTypeItem $isSelected={this.props.isSelected}
                                   $isDisabled={this.props.isDisabled}
                                   tabIndex={0}
                                   $isCentered={this.props.isCentered}
                                   onClick={this.handleClick}
                                   onKeyDown={this.handleKeyDown}
                                   data-testid={TestIds.ProductTypeItem}>
                <ItemTitle data-testid={TestIds.Title}>
                    {this.props.title}
                </ItemTitle>
                {!this.props.isWithoutDescription &&
                    <ItemDescription>{this.props.description}</ItemDescription>}
                {this.props.children}
            </StyledProductTypeItem>
        );
    }
}

export type { IProps as IProductTypeItemProps };