import { FieldType, ValueType } from "../../../enums";
import BindingContext from "../../../odata/BindingContext";
import { Model } from "../../../model/Model";
import { isNotDefined } from "@utils/general";
import {
    fixWhiteSpaceChar,
    startOfWordRegExp,
    startsWith,
    startsWithAccentsInsensitive,
    testAccentsInsensitiveWithRegex,
    testWithRegex
} from "@utils/string";
import { Direction, ISelectItem, ITEM_PAGE_SIZE, SelectGroups, TSelectItemId } from "./BasicSelect";
import { KeyName } from "../../../keyName";
import { getItemsForRenderCallbackArgs, HIERARCHICAL_PARENT_PROP_NAME } from "../../smart/smartSelect/SmartSelectAPI";
import { IValidationError } from "../../../model/Validator.types";
import { EMPTY_VALUE } from "../../../constants";
import { IFieldInfo } from "@odata/FieldInfo.utils";

export interface IRaiseErrorArgs {
    error: IValidationError;
    shouldFire: boolean;
}

export const isSelectBasedComponent = (type: string): boolean => {
    switch (type) {
        case FieldType.Autocomplete:
        case FieldType.BusinessPartnerSelect:
        case FieldType.ComboBox:
        case FieldType.HierarchyComboBox:
        case FieldType.HierarchyMultiComboBox:
        case FieldType.LabelSelect:
        case FieldType.MultiSelect:
        // case FieldType.Select:
        case FieldType.ValueHelper:
            return true;

        default:
            return false;
    }
};

interface IGetBoundCurrentValue {
    storage: Model<any>;
    info: IFieldInfo;
    fieldBindingContext: BindingContext;
    processMultiValue?: boolean;
}

export const isDirectionKey = (keyName: string): boolean => {
    return [KeyName.ArrowUp, KeyName.PageUp, KeyName.ArrowDown, KeyName.PageDown].includes(keyName as KeyName);
};

export const isSystemKey = (keyName: string): boolean => {
    return isDirectionKey(keyName) || [KeyName.Escape, KeyName.Enter, KeyName.Space, KeyName.Backspace, KeyName.Delete,
        KeyName.Tab, KeyName.Shift, KeyName.Control].includes(keyName as KeyName);
};

export const getSelectDisplayValue = (args: IGetBoundCurrentValue): string => {
    let displayValue;
    if (args.info?.fieldSettings?.items) {
        const { value } = args.storage.getFieldValues(args.fieldBindingContext, args.info);
        if (Array.isArray(value) && args.processMultiValue) {
            const displayValues = (value as string[]).map(val => getSelectDisplayValueForOneItem(val, args));
            // filter out undefined values and join
            displayValue = displayValues.filter(item => item).join(", ");
        } else {
            displayValue = getSelectDisplayValueForOneItem(value, args);
        }
    }

    return displayValue;
};

/**
 * Display value for just one select item, so we can work with it further...
 * @param value
 * @param args
 */
export const getSelectDisplayValueForOneItem = (value: unknown, args: IGetBoundCurrentValue): string => {
    const _getValue = (value: string) => {
        const { fieldSettings } = args.info;
        let items = fieldSettings?.items ?? [];
        if (fieldSettings?.itemsForRender) {
            items = fieldSettings?.itemsForRender(items, getItemsForRenderCallbackArgs(args.storage, args.info, args.fieldBindingContext));
        }
        const item = [
            ...items,
            ...(fieldSettings?.additionalItems ?? []),
            ...(fieldSettings?.initialItems ?? [])
        ].find(item => item.id === value);

        if (item) {
            return item.label || item.id?.toString();
        }

        return "";
    };

    const displayValue = _getValue(value as string);

    if (!displayValue && args.info?.type === FieldType.LabelSelect) {
        // in reports, LabelSelect items are not fetched immediately,
        // but the value already represents LabelSelects label
        return value === EMPTY_VALUE ? args.storage.t("Common:General.Empty") : (value || args.storage.t("Reporting:Common.NoLabel")) as string;
    }

    return displayValue;
};

export const buildTreeFromAllItems = (allItems: ISelectItem[], keyName = "Id", parentKeyName?: string): ISelectItem[] => {
    const itemsById: Record<string, ISelectItem> = {};
    const rootItems: ISelectItem[] = [];

    // we have to handle cases where there are items with parent NOT present in this collection (due to the filter condition)
    // first we create hashmap and then check whether item has EXISTING PARENT
    allItems.forEach((item: ISelectItem) => {
        itemsById[item.id.toString()] = item;
    });

    allItems.forEach((item: ISelectItem) => {
        let parent = item.additionalData?.[HIERARCHICAL_PARENT_PROP_NAME];
        let isRoot = !parent;

        if (parent) {
            if (parent instanceof Array) {
                parent = parent[0];
            }

            // use parentKeyName in case we use different prop as key (id) on the parents and on the children items
            const code = typeof parent === "string" ? parent : parent[parentKeyName ?? keyName];
            if (!itemsById[code]) {
                isRoot = true;
            }
        }

        if (isRoot) {
            rootItems.push(item);
        }
    });


    const items: ISelectItem[] = [];
    let index = 0;
    const _add = (item: ISelectItem, indent: number, parent?: ISelectItem) => {
        item.indent = indent;
        item.index = index;
        item.parent = parent;
        item.children = [];
        items.push(item);
        index++;
        for (const childObj of item.additionalData?.Children || []) {
            const _item = itemsById[childObj[keyName]];
            if (_item) {
                // item might be filtered out by oData query -> skip it
                // (the item and all its children won't be visible in the tree)
                _add(_item, indent + 1, item);
                item.children.push(_item);
            }
        }
    };

    for (const root of rootItems) {
        _add(root, 0);
    }

    return items;
};

export interface IGetFirstMatchingIndexReturnType {
    anyWordFit: number;
    startsWith: number;
}

// tries to find first item which matching condition of startsWith (with deburr)
// if there is no such item we return starts with any word
// the reason we return both is, on handleChange method we can "autocomplete" only items which fits "startswith" condition
export const getFirstMatchingItemIndex = (items: ISelectItem[], value: string, searchType: ValueType): IGetFirstMatchingIndexReturnType => {
    let startsWithIdx, anyWordFitIdx;
    let startsWithInsensitiveIdx, anyWordFitInsensitiveIdx;

    if (value) {
        const valueRegEx = startOfWordRegExp(value, searchType);
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (!item.isDisabled && item.isSearchable !== false) {
                if (startsWith(item.label, value)) {
                    startsWithIdx = i;
                    break;
                }
                // noinspection JSUnusedAssignment
                if (isNotDefined(startsWithInsensitiveIdx) &&
                    startsWithAccentsInsensitive(item.label, value)) {
                    startsWithInsensitiveIdx = i;
                }
                // noinspection JSUnusedAssignment
                if (isNotDefined(anyWordFitIdx) &&
                    testWithRegex(item.label, valueRegEx)) {
                    anyWordFitIdx = i;
                }
                // noinspection JSUnusedAssignment
                if (isNotDefined(anyWordFitInsensitiveIdx) &&
                    testAccentsInsensitiveWithRegex(item.label, valueRegEx)) {
                    anyWordFitInsensitiveIdx = i;
                }
            }
        }
    }

    return {
        anyWordFit: startsWithIdx ?? startsWithInsensitiveIdx ?? anyWordFitIdx ?? anyWordFitInsensitiveIdx,
        startsWith: startsWithIdx ?? startsWithInsensitiveIdx
    };
};

export interface IFilterItemsArgs {
    value: string;
    items: ISelectItem[];
    searchType: ValueType;
    skipFilter: boolean;
}

export const filterItems = (args: IFilterItemsArgs): ISelectItem[] => {
    const getItemsWithCorrectIndex = (filteredItems: ISelectItem[]) => {
        for (let i = 0; i < (filteredItems || []).length; i++) {
            filteredItems[i].index = i;
        }

        return filteredItems;
    };

    if (!args.value || args.skipFilter) {
        return getItemsWithCorrectIndex(args.items);
    }

    const valueRegEx = startOfWordRegExp(args.value, args.searchType);
    let items: ISelectItem[] = [];

    let i = 0;
    let filteredIndex = 0;

    const _add = (children: ISelectItem[]) => {
        const currentIndex = filteredIndex;
        const item = args.items[i];
        i++;
        for (const child of (item.children || [])) {
            _add(children);
        }

        const isFit = item.isNotFilterable
            || testAccentsInsensitiveWithRegex(item.label, valueRegEx)
            || (item.tabularData?.length && item.tabularData.find(colValue => testAccentsInsensitiveWithRegex(colValue, valueRegEx)));
        // fulfill condition or has children who fulfill condition
        if (isFit || currentIndex !== filteredIndex) {
            if (!item.indent) {
                items.push(item);
                if (children.length > 0) {
                    items = [...items, ...children];
                }
            } else {
                if (children[children.length - 1]?.indent > item?.indent) {
                    children.unshift(item);
                } else {
                    children.push(item);
                }

            }

            filteredIndex++;
        }
    };

    for (i = 0; i < args.items.length;) {
        _add([]);
    }

    return getItemsWithCorrectIndex(items);
};

export function getHighlightedIndex(currentIndex: number, items: ISelectItem[], value: TSelectItemId, currentValue: string): number {
    // if there is value find in among item
    // or there is non existent item and we doesnt select anything
    currentIndex = value ? items.findIndex(item => item.id === value) : -1;

    // in case there is some value but we were not able to find it among items by id we may try to find it with current value
    if (currentIndex === -1 && currentValue) {
        currentIndex = items.findIndex(item => fixWhiteSpaceChar(item.label) === currentValue);
    }

    return currentIndex;
}

export function findHighlightableItem(items: ISelectItem[], oldPos: number, newPos: number, direction: Direction): number {
    const _isHighlightable = (i: number) => {
        const item = items[i];
        return item && !item.isNotHighlightable && !item.isDisabled;
    };

    const count = items.length;

    for (let i = newPos; direction === Direction.Up ? i >= 0 : i < count; direction === Direction.Up ? i-- : i++) {
        if (_isHighlightable(i)) {
            return i;
        }
    }

    // if we are out of bounds find (last (or first based on the direction) highlightable item
    const limit = direction === Direction.Down ? count - 1 : 0;
    for (let i = limit; i !== oldPos && i >= 0 && i < count; direction === Direction.Down ? i-- : i++) {
        if (_isHighlightable(i)) {
            return i;
        }
    }

    return oldPos;
}

export function handleNavigationKey(items: ISelectItem[], highlightedIndex: number, key: KeyName): { highlightedIndex: number, scrollDirection: Direction } {
    let scrollDirection;

    switch (key) {
        case KeyName.ArrowUp:
            highlightedIndex = findHighlightableItem(items, highlightedIndex, highlightedIndex - 1, Direction.Up);
            scrollDirection = Direction.Up;
            break;

        case KeyName.PageUp:
            highlightedIndex = findHighlightableItem(items, highlightedIndex, highlightedIndex - ITEM_PAGE_SIZE, Direction.Up);
            scrollDirection = Direction.Up;
            break;

        case KeyName.ArrowDown:
            highlightedIndex = findHighlightableItem(items, highlightedIndex, highlightedIndex + 1, Direction.Down);
            scrollDirection = Direction.Down;
            break;

        case KeyName.PageDown:
            highlightedIndex = findHighlightableItem(items, highlightedIndex, highlightedIndex + ITEM_PAGE_SIZE, Direction.Down);
            scrollDirection = Direction.Down;
            break;
        default:
            return null;
    }

    return { highlightedIndex, scrollDirection };
}

const setIsNotFilterableMark = (items: ISelectItem[]): ISelectItem[] => {
    return (items || []).map(item => {
        item.isNotFilterable = true;
        return item;
    });
};

interface IGetAllItemsArgs {
    items: ISelectItem[];
    additionalItems?: ISelectItem[];
    noRecordText?: string;
}

export const getAllItems = (args: IGetAllItemsArgs): ISelectItem[] => {
    // mark additional items as not filterable (so user doesn't need to do it himself)
    // put action items to the end and the rest to the beginning so it matches the order of display
    const actionItems = [];
    const additionalItems = [];
    if (args.additionalItems?.length > 0) {
        const originAdditionalItems = setIsNotFilterableMark(args.additionalItems);

        for (const item of originAdditionalItems) {
            item.groupId === SelectGroups.Action ? actionItems.push(item) : additionalItems.push(item);
        }
    }

    const items = additionalItems.concat(args.items).concat(actionItems);

    // No record === not selected item
    if (args.noRecordText) {
        const noRecordText = args.noRecordText;

        items.unshift({
            label: noRecordText,
            groupId: SelectGroups.Default,
            isNotFilterable: true,
            id: null,
            additionalData: {
                isNoRecord: true
            }
        });
    }

    return items;
};