import React from "react";
import { CaretIcon } from "../../icon";
import { BasicSelect, IProps, ISelectionChangeArgs, ISelectItem } from "./BasicSelect";
import { IconSize } from "../../../enums";
import { getAllItems } from "./SelectAPI";

const icon = <CaretIcon width={IconSize.M}/>;

interface IState {
    currentValue: string;
}

export class Select extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            currentValue: this.getCurrentValue(props)
        };
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (prevProps.value !== this.props.value || this.props.items?.length !== prevProps.items?.length) {
            const currentValue = this.getCurrentValue();
            if (currentValue !== this.state.currentValue) {
                this.setState({ currentValue });
            }
        }
    }

    getCurrentValue(props = this.props): string {
        const allItems = props.items?.concat(props.additionalItems || []);
        return allItems?.find(item => item.id === props.value)?.label
            ?? undefined; // use undefined instead of "", otherwise, BasicSelect won't use noRecordText;
    }

    getAllItems = (): ISelectItem[] => {
        return getAllItems({
            items: this.props.items,
            additionalItems: this.props.additionalItems,
            noRecordText: this.props.noRecordText
        });
    };

    handleChange = (args: ISelectionChangeArgs): void => {
        const item = this.getAllItems().find(item => item.id === args.value);

        this.setState({ currentValue: item?.label });
        this.props.onChange(args);
    };

    renderContent = (): React.ReactElement => {
        const sharedProps = {
            closeOnSelection: true,
            openOnClick: true,
            inputIsReadOnly: false
        };

        return <BasicSelect
            {...this.props}
            onChange={this.handleChange}
            inputIcon={icon}
            {...sharedProps}
            isMultiLine={false}/>;
    };

    render() {
        return this.renderContent();
    }
}
