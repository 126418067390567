import { getEnumDisplayValue, getEnumName, ILoadEnumsResult, isEnum, loadEnums } from "@odata/GeneratedEnums.utils";
import { formatValue, getFilterToken, IFormatOptions } from "@odata/OData.utils";
import { isDefined, isNotDefined } from "@utils/general";
import i18next from "i18next";

import { TValue } from "../../global.types";
import { IEntity } from "../../odata/BindingContext";
import { getBoundValue } from "../../odata/Data.utils";
import BooleanType from "../../types/Boolean";
import {
    IComplexFilter,
    isComplexFilterArr,
    TFilterValue
} from "../conditionalFilterDialog/ConditionalFilterDialog.utils";
import { getSelectDisplayValueForOneItem, isSelectBasedComponent } from "../inputs/select/SelectAPI";
import { getInfoValue } from "../smart/FieldInfo";
import { getValueHelpFilterGroupByProps, parseCompositeFilterId } from "../smart/smartValueHelper/ValueHelper.utils";
import { IFilterBarReadOnlyItem } from "./FilterBar";

const MAX_VISIBLE_ITEMS = 5;

export const getFilterBarItemRenderValue = (item: IFilterBarReadOnlyItem, isExpandedFn?: (itemId: string) => boolean) => {
    let hiddenValues = 0;
    let possibleHiddenValues = 0;
    let id = item.label;
    let loadEnumsResult: ILoadEnumsResult;
    let value: TValue;
    const info = item.bindingContext && item.storage?.getInfo(item.bindingContext);

    const _reduceArrayToMaxLength = (values: any[]) => {
        // we reduce values of the conditional filter
        possibleHiddenValues = values.length - MAX_VISIBLE_ITEMS;

        if (isExpandedFn && !isExpandedFn(id)) {
            hiddenValues = possibleHiddenValues;
            // if not expanded, reduce hidden values to max
            return values.slice(0, MAX_VISIBLE_ITEMS);
        }
        return values;
    };

    const _getDisplayValue = (value: TFilterValue) => {
        /* when there is itemsForRender callback, items are customized -> transformed, so we can't use original enum values */
        if (isEnum(info) && !info?.fieldSettings?.itemsForRender) {
            // todo: this is most likely on wrong place, can we move it to formatValue function?
            const enumName = getEnumName(info, item.storage?.oData);

            loadEnumsResult = loadEnums(enumName);

            return getEnumDisplayValue(enumName, value as string);
        }

        if (isSelectBasedComponent(info.type)) {
            return getSelectDisplayValueForOneItem(value, {
                storage: item.storage,
                info,
                fieldBindingContext: info.bindingContext
            });
        }

        let entity: IEntity = {};

        if (getValueHelpFilterGroupByProps(info.bindingContext, info)?.length > 0) {
            // if groupByProps exists, value (id) is composed of multiple different values
            // => we have to parse it and replace the "value" with just the main value id
            entity = parseCompositeFilterId(value as string);

            if (entity) {
                value = getBoundValue({
                    bindingContext: item.bindingContext,
                    data: entity,
                    dataBindingContext: item.bindingContext.getRootParent()
                });
            }
        }

        return formatValue(value, info, {
            entity,
            readonly: true,
            placeholder: i18next.t("Common:General.Empty"),
            storage: item.storage
        });
    };

    if (info) {
        // items with binding context uses it as ID
        id = item.bindingContext.toString();
        let parsedValue: TValue;

        if (info.filter?.customReadOnlyParsedValue) {
            parsedValue = getInfoValue(info.filter, "customReadOnlyParsedValue", {
                storage: item.storage,
                info: info,
                bindingContext: item.bindingContext,
                item,
                context: item.storage.context
            });
        } else {
            parsedValue = item.storage.getAdditionalFieldData(item.bindingContext, "parsedValue");
        }

        if (item.storage.hasError(info.bindingContext)) {
            value = i18next.t("Common:Validation.InvalidValue") as string;
        } else if (isComplexFilterArr(parsedValue)) {
            const parsedComplexFilters: IComplexFilter[] = [];

            for (const filter of parsedValue as IComplexFilter[]) {
                if (!Array.isArray(filter.value) || isEnum(info)) {
                    parsedComplexFilters.push(filter);
                } else {
                    // for cases where value is array (OR filters) => create one filter for all values to correctly work with hidden values
                    // so far, used in report drilldowns - in BalanceSheet to send multiple "startsWith" filters, because BE is unable to properly provide all account children
                    for (const val of filter.value) {
                        parsedComplexFilters.push({
                            ...filter,
                            value: val as string
                        });
                    }
                }
            }

            const valueHelperItems = _reduceArrayToMaxLength(parsedComplexFilters);

            const options: IFormatOptions = {
                entity: item.storage.data.entity,
                readonly: true,
                placeholder: i18next.t("Common:General.Empty")
            };

            if (isEnum(info)) {
                value = valueHelperItems.flatMap((complexFilter: IComplexFilter) => (complexFilter.value as string[])
                    .map(val => {
                        const translatedVal = info.formatter ? val : _getDisplayValue(val);

                        return getFilterToken({ ...complexFilter, value: translatedVal }, info, options);
                    }));
            } else {
                value = valueHelperItems.map((val) => getFilterToken(val as IComplexFilter, info, options));
            }
        } else if (Array.isArray(parsedValue)) {
            value = _reduceArrayToMaxLength(parsedValue)
                .map(_getDisplayValue);
        } else if (isDefined(parsedValue)) {
            // plain value, try to format it, also select values, but don't format null values == empty filter
            value = _getDisplayValue(parsedValue);
        }

    } else if (Array.isArray(item.value)) {
        value = _reduceArrayToMaxLength(item.value);
    } else {
        value = item.value;
    }

    // When value is multiselect and items are not loaded yet, we need to check this and don't display "Label: ,,,"
    const _isArrayWithoutValue = (arr: any) => Array.isArray(arr) && !(arr as any[]).find(item => item);

    if (isNotDefined(value) || value === "" || _isArrayWithoutValue(value)) {
        value = null;
    }

    if (typeof value === "boolean") {
        value = BooleanType.format(value);
    }

    return {
        mayHideValues: possibleHiddenValues > 0,
        hiddenValues: Math.max(hiddenValues, 0),
        loadEnumsResult,
        value, id
    };
};