import React, { Component } from "react";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { WithTranslation, withTranslation } from "react-i18next";
import FilterBar, { IFilterBarItem, IFilterBarTab } from "../../filterBar/FilterBar";
import { ISmartFieldBlur, ISmartFieldChange } from "../smartField/SmartField";
import { TableStorage } from "../../../model/TableStorage";
import { isDefined, isObjectEmpty } from "@utils/general";
import { ICheckboxGroupChange, ICheckboxGroupItem, ICheckboxSubgroup } from "../../inputs/checkboxGroup/CheckboxGroup";
import { FilterBarGroup, IFieldData, IFilterGroupDef, TFilterDef } from "./SmartFilterBar.types";
import { ISelectItem } from "../../inputs/select/BasicSelect";
import CustomizationDialog from "./CustomizationDialog";
import { isVisible } from "../FieldInfo";
import { compareString } from "@utils/string";
import { FieldType } from "../../../enums";
import Tabs from "../../tabs";
import memoizeOne from "../../../utils/memoizeOne";
import SmartFilterField from "./SmartFilterField";
import { getFieldInfo, IFieldInfo } from "@odata/FieldInfo.utils";
import memoize from "../../../utils/memoize";

export interface IProps extends WithTranslation {
    tableId: string;
    className?: string;
    storage: TableStorage;
    isDisabled?: boolean;
    shouldAutoSort?: boolean;

    onClearFilter: () => void;
    onFilterChange: (args?: ISmartFieldChange) => void;
    onFilterBlur?: (args?: ISmartFieldBlur) => void;
    /** Called when CustomizationDialog is confirmed -> user changed filter bar fields */
    onFilterCustomizationChanged?: () => void;
    onExpandFinish?: () => void;
    onCustomButtonClick?: (tabId: string, buttonId: string) => void;
}

interface IState {
    isExpanded: boolean;
    isDialogOpen: boolean;
    visibleFilters: string[];
    availableFilters: ICheckboxGroupItem[];
    detailTabFilters?: ICheckboxGroupItem[];
    detailTabGroups?: ICheckboxSubgroup[];
    selectedTab?: string;
}

const DEFAULT_TAB_ID = "defaultTab";
const DETAIL_TAB_ID = "detailTab";

class SmartFilterBar extends Component<IProps, IState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    constructor(props: IProps) {
        super(props);

        let isExpanded = false;
        if (this.props.storage.data?.definition) {
            const expandedGroup = (this.props.storage.data.definition.filterBarDef || []).find(group => group.isExpanded);
            if (expandedGroup) {
                isExpanded = true;
                props.storage.data.expandedGroupKey = expandedGroup.id;
            }
        }

        this.state = {
            isExpanded,
            isDialogOpen: false,
            visibleFilters: [],
            availableFilters: [],
            detailTabFilters: [],
            detailTabGroups: [],
            selectedTab: DEFAULT_TAB_ID
        };
    }

    handleExpand = (id: string) => {
        const isCurrentClicked = this.props.storage.data.expandedGroupKey === id;
        this.props.storage.store({
            expandedGroupKey: id as FilterBarGroup
        });

        this.setState((state) => {
            const isOpen = state.isExpanded;
            let shouldChange = false;

            if ((isOpen && isCurrentClicked) || !isOpen) {
                shouldChange = true;
            }
            return {
                isExpanded: shouldChange ? !state.isExpanded : state.isExpanded
            };
        });
    };

    _getCurrentGroupVisibleFilters = () => {
        return this.props.storage.data.visibleFilters[this.props.storage.data.expandedGroupKey];
    };

    get filterBarDef() {
        return this.props.storage.data.definition.filterBarDef.find(filterBarDef => filterBarDef.id === this.props.storage.data.expandedGroupKey);
    }

    handleEditFiltersClick = async (id: string) => {
        await this.prepareAvailableFilters();

        this.setState({
            isDialogOpen: true,
            selectedTab: DEFAULT_TAB_ID,
            visibleFilters: [...this._getCurrentGroupVisibleFilters()]
        });
    };

    handleClearFilter = () => {
        this.props.onClearFilter();
    };

    handleCloseDialog = () => {
        this.setState({
            isDialogOpen: false
        });
    };

    prepareAvailableFilters = async (): Promise<void> => {
        const filterBarDef = this.filterBarDef;
        const { storage } = this.props;

        const preparedDefs: IFieldInfo[] = [];

        const _add = (def: TFilterDef, id: string) => {
            return getFieldInfo({
                bindingContext: storage.data.bindingContext.navigate(id),
                context: this.context,
                fieldDef: {
                    id,
                    ...def
                }
            }).then(info => preparedDefs.push(info));
        };

        const defPromises: Promise<any>[] = Object.keys(filterBarDef.filterDefinition)
            .map((id) => {
                const filterDefinition = filterBarDef.filterDefinition[id];
                if (typeof filterDefinition?.factory === "function") {
                    return filterDefinition.factory(storage)
                        .then((defs) => Promise.all(defs.map(def => _add(def, def.id))));
                }
                return _add(filterDefinition, id);
            });

        await Promise.all(defPromises);

        const groupStatus = storage.data.visibleFilters[storage.data.expandedGroupKey];
        if (!groupStatus) {
            return null;
        }

        const availableFilters: ICheckboxGroupItem[] = [];
        const detailTabFilters: ICheckboxGroupItem[] = [];
        const detailTabGroups: ICheckboxSubgroup[] = [];

        for (const field of preparedDefs) {
            if (storage.predefinedFilters?.[field.id]) {
                // predefined filters are not customizable
                continue;
            }
            // we use fullpath with keys when using factory filter (more filters are created from one definition)
            const withoutKey = !field.factory;
            const path = field.bindingContext.getFullPath(withoutKey);
            const item: ICheckboxGroupItem = {
                id: path,
                label: field.label
            };
            const parentBc = field.bindingContext.getParent();
            const isDefaultTabFilter = !field.description;

            if (isDefaultTabFilter) {
                availableFilters.push(item);
            } else {
                const groupId = field.descriptionPath ?? parentBc.getPath(true);

                if (!detailTabGroups.find(group => group.id === groupId)) {
                    detailTabGroups.push({
                        id: groupId,
                        label: field.description as string
                    });
                }
                item.groupId = groupId;
                detailTabFilters.push(item);
            }
        }

        availableFilters.sort((a: ISelectItem, b: ISelectItem) => compareString(a.label, b.label));
        detailTabFilters.sort((a: ISelectItem, b: ISelectItem) => compareString(a.label, b.label));
        detailTabGroups.sort((a: ISelectItem, b: ISelectItem) => compareString(a.label, b.label));

        this.setState({
            availableFilters,
            detailTabFilters,
            detailTabGroups
        });
    };

    handleConfirmDialog = async () => {
        const { storage } = this.props;
        const id = storage.data.expandedGroupKey;
        const currentVisibleFilters = this._getCurrentGroupVisibleFilters();
        const visibleFilters = [...this.state.visibleFilters];

        // TODO predefinedFilters vs presetDefaultFilters =_=
        // https://solitea-cz.atlassian.net/browse/DEV-23571
        if (storage.predefinedFilters) {
            Object.keys(storage.predefinedFilters).forEach(predefinedFilterId => {
                const predefinedFilterBc = storage.data.bindingContext.navigate(predefinedFilterId);
                visibleFilters.push(predefinedFilterBc.getFullPath(true));
            });
        }

        // in case that filter that had previously value was removed, filter query has to be refreshed
        // otherwise the items would still be filtered by the old filter value and the filter couldn't be changed by the user anymore
        const removedFilters = currentVisibleFilters.filter((filterName) => {
            return !visibleFilters.includes(filterName);
        });

        for (const removedFilter of removedFilters) {
            // force empty string into the data for the removed filters
            storage.handleChange({
                value: null,
                bindingContext: storage.data.fieldsInfo[removedFilter].bindingContext
            });
        }

        // trigger filter query refresh
        this.props.onFilterChange?.();

        await storage.updateVisibleFilters(id, visibleFilters);

        this.setState({
            isDialogOpen: false
        }, () => {
            this.props.onFilterCustomizationChanged?.();
        });
    };

    getTabsData = memoizeOne(() => {
        return [
            {
                id: DEFAULT_TAB_ID,
                title: this.props.storage.data.definition.title ?? this.props.t("Common:General.Default")
            },
            {
                id: DETAIL_TAB_ID,
                title: this.props.t("Common:General.Detail")

            }
        ];
    });

    handleCustomFilterAddChange = async (args: ICheckboxGroupChange) => {
        const nonActiveTabSelectedFilters = this.getSelectedFilters(this.state.selectedTab === DEFAULT_TAB_ID ? DETAIL_TAB_ID : DEFAULT_TAB_ID);
        const visibleFilters: string[] = [
            ...nonActiveTabSelectedFilters,
            ...args.values
        ];

        this.setState({
            visibleFilters
        });
    };

    createGroup = (filter: IFilterGroupDef, fields: IFieldData[]): IFilterBarItem[] => {
        const { storage } = this.props;
        const group: IFilterBarItem[] = fields
            .filter((item) => {
                const isNotRenderedPredefinedFilter = item.type === FieldType.Custom && !item.render;
                return isVisible({ info: item, storage }) && !isNotRenderedPredefinedFilter;
            })
            .map((item) => {
                const filterBarItem: IFilterBarItem = {
                    bindingContext: item.bindingContext,
                    storage: storage,
                    label: item.label,
                    description: item.description as string,
                    groupWithPrevious: !!item.fieldSettings?.groupWithPrevious,
                    // pass component and props, so that the instantiation happens in the filter bar
                    // otherwise, there would be rendering issues, because getTabGroups is memoized => SmartField wouldn't rerender together with SmartFilterBar
                    field: {
                        component: SmartFilterField,
                        props: {
                            fieldDef: {
                                isReadOnly: false,
                                label: item.label,
                                description: item.description
                            },
                            fieldProps: {
                                isLight: true
                            },
                            hasConditionalDialog: filter.id !== FilterBarGroup.Parameters,
                            showLabelDescription: true,
                            storage,
                            key: item.id, //todo: this looks suspicious, but is required for correct info load in smart field constructor , better investigate more due to the perf. reasons
                            bindingContext: item.bindingContext,
                            onChange: this.props.onFilterChange,
                            onBlur: this.props.onFilterBlur
                        }
                    }
                };

                // add option to filter over empty values
                if (item.type === FieldType.LabelSelect) {
                    filterBarItem.field.props.fieldProps.addEmptyItem = true;
                }

                if (isDefined(storage.data.predefinedFilters?.[item.id])) {
                    // drilldown filters should disabled in filter bar
                    filterBarItem.field.props.fieldProps.isDisabled = true;
                    // filters should also be empty
                    filterBarItem.field.props.fieldProps.value = undefined;
                }

                return filterBarItem;
            });

        return group;
    };

    getTabs = (): IFilterBarTab[] => {
        return this.props.storage.data.definition.filterBarDef.map((filter, index) => {
            const groups = this.getTabGroups(filter);

            return {
                id: filter.id,
                icon: filter.icon,
                title: filter.title,
                removePadding: filter.removePadding,
                customButtons: filter.customButtons,
                createQuery: filter.createQuery,
                showEditFiltersButton: filter.allowCustomFilters,
                groups
            };
        });
    };

    getTabGroups = memoize((filterGroup: IFilterGroupDef) => {
        const groupDefs: IFieldData[][] = [];
        const visibleFieldInfos = this.props.storage.getFilterGroupFieldInfos(filterGroup.id);

        const defaultGroup = visibleFieldInfos.filter(info => !info.description);
        const detailGroup = visibleFieldInfos.filter(info => info.description);

        if (this.props.shouldAutoSort) {
            defaultGroup?.sort((a, b) => compareString(a.label, b.label));
            detailGroup?.sort((a, b) => {
                // first, order by description
                // second, order by label
                const descriptionCompare = compareString(a.description as string, b.description as string);

                if (descriptionCompare !== 0) {
                    return descriptionCompare;
                }

                return compareString(a.label, b.label);
            });
        }

        if (defaultGroup.length > 0) {
            groupDefs.push(defaultGroup);
        }

        if (detailGroup.length > 0) {
            groupDefs.push(detailGroup);
        }

        return groupDefs.map((def, idx) => {
            return this.createGroup(filterGroup, def);
        });
    }, (filter: IFilterGroupDef) => {
        const customFilters = this.props.storage.data.visibleFilters[filter.id];
        const path = this.props.storage.data.bindingContext?.getFullPath();
        return `${filter.id}::${path}::${customFilters.join("::")}`;
    });

    handleTabChange = (selectedTab: string) => {
        this.setState({
            selectedTab
        });
    };

    renderCustomizationDialogTabs = () => {
        if (this.state.detailTabFilters.length === 0) {
            return null;
        }

        return <Tabs data={this.getTabsData()}
                     onChange={this.handleTabChange}
                     selectedTabId={this.state.selectedTab}
                     style={{ marginBottom: "15px" }}/>;
    };

    getSelectedFilters = (tabId: string) => {
        const filters = tabId === DEFAULT_TAB_ID ? this.state.availableFilters : this.state.detailTabFilters;

        return this.state.visibleFilters.filter(selectedFilter => {
            return filters.find(filter => filter.id === selectedFilter);
        });
    };

    render() {
        if (!this.props.storage || isObjectEmpty(this.props.storage.data)) {
            return null;
        }
        const { predefinedFilters } = this.props.storage;
        return (
            <>
                <FilterBar
                    className={this.props.className}
                    tabs={this.getTabs()}
                    isDisabled={this.props.isDisabled}
                    expandedGroupKey={this.props.storage.data.expandedGroupKey}
                    expanded={this.state.isExpanded && !this.props.isDisabled}
                    onExpand={this.handleExpand}
                    onExpandFinish={this.props.onExpandFinish}
                    onClear={this.handleClearFilter}
                    isClearDisabled={this.props.storage.isDisabled}
                    onEditFiltersClick={this.handleEditFiltersClick}
                    hideReadOnlyFilters={predefinedFilters ? Object.keys(predefinedFilters) : []}
                    onCustomButtonClick={this.props.onCustomButtonClick}
                />
                {this.state.isDialogOpen &&
                    <CustomizationDialog title={this.props.t("Components:FilterBar.ChooseFilter")}
                                         items={this.state.selectedTab === DEFAULT_TAB_ID ? this.state.availableFilters : this.state.detailTabFilters}
                                         selectedItems={this.getSelectedFilters(this.state.selectedTab)}
                                         itemsSubgroups={this.state.selectedTab === DETAIL_TAB_ID ? this.state.detailTabGroups : undefined}
                                         customPreContent={this.renderCustomizationDialogTabs()}
                                         onChange={this.handleCustomFilterAddChange}
                                         onClose={this.handleCloseDialog}
                                         onConfirm={this.handleConfirmDialog}/>
                }
            </>
        );
    }
}

export default withTranslation(["Common", "Components"])(SmartFilterBar);