import { IFormStorageDefaultCustomData } from "../../../../views/formView/FormStorage";
import { ISubscriptionEntity, ITenantEntity } from "@odata/GeneratedEntityTypes";
import { IFormViewProps } from "../../../../views/formView/FormView";
import { ADD_CARD_URL, IPaymentState } from "../Subscriptions.utils";
import BindingContext from "../../../../odata/BindingContext";
import customFetch, { getDefaultPostParams } from "../../../../utils/customFetch";

export interface IInvoicingFormCustomData extends IFormStorageDefaultCustomData {
    subscription?: ISubscriptionEntity;
    paymentState?: IPaymentState;
}

export const PaymentInfoPath = BindingContext.localContext("PaymentInfo");
export const LimitDialogButtonPath = BindingContext.localContext("LimitDialogButton");
export const CardLimitPath = "##CardLimitPath##";

export interface IInvoicingExtendedEntity extends ITenantEntity {
    [CardLimitPath]?: number;
}

export type InvoicingFormViewProps = IFormViewProps<IInvoicingExtendedEntity, IInvoicingFormCustomData>;

export enum CardAction {
    Add = "add",
    Change = "change"
}

export const createCardActionUrl = async (action: CardAction): Promise<Response> => {
    return await customFetch(ADD_CARD_URL, {
        ...getDefaultPostParams()
    });
};