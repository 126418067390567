import React, { useContext } from "react";
import {
    ControlButtonsGroup,
    DragIconWrapper,
    GroupLabel,
    ItemList,
    PlaceholderVisibility,
    StyledConfigurationGroup,
    StyledGroupHeader
} from "./ConfigurationList.styles";
import {
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided,
    DroppableStateSnapshot
} from "react-beautiful-dnd";
import { BasicInputSizes, GroupListDropType, IconSize, Status } from "../../enums";
import { BinIcon, ReorderSmallIcon } from "../icon";
import {
    connectToContext,
    CustomDnDContext,
    getCleanDraggableId,
    IConfigList,
    IGroupListItemDef,
    IPlaceholderPos,
    shouldComponentUpdateIgnoreData,
    trimCopyId
} from "./ConfigurationList";
import Item, { GroupSeparatorItem, IGroupSubItemProps } from "./Item";
import { isObjectEmpty } from "@utils/general";
import TestIds from "../../testIds";
import { WithTranslation, withTranslation } from "react-i18next";
import { IEditableTextChangeEvent } from "../inputs/editableText";
import ResponsiveEditableText from "../inputs/editableText/ResponsiveEditableText";
import { IconButton } from "../button";
import ReactDOM from "react-dom";
import { ItemPlaceholderLine, ItemPlaceholderWrapper } from "./DnD.utils.styles";
import CustomizationIcon from "./CustomizationIcon";
import { IAlertProps } from "../alert/Alert";

export interface IProps {
    id: string;
    index: number;
    label?: string;
    // collection groups uses prefix - path to the group
    prefix?: string;
    /** Renders group without backround and label, items are still draggable. */
    isTransparent?: boolean;
    /** If group is growing, it has flex-grow set, so it extends to full column size (used for available items). */
    isGrowing?: boolean;
    /** Whole group is draggable via drag icon */
    isDraggable?: boolean;
    /** Items are prefixed with margin based on their hierarchy level */
    isHierarchical?: boolean;
    /** Shows remove icon, fires onRemoveClick event */
    isRemovable?: boolean;
    /** Shows lock icon. It's not possible to drop items */
    isLocked?: boolean;
    /** Label can be edited, fires onLabelChanged event */
    isLabelEditable?: boolean;
    isDropDisabled?: boolean;
    /** Whether the items should be sorted automatically */
    shouldSort?: boolean;
    onRemoveClick?: (groupId: string) => void;
    onLabelChange?: (groupId: string, label: string) => void;
    /** Position of custom item placeholder */
    placeholder?: IPlaceholderPos;
    /** Whole config list definition, injected from CustomDnDContext. Used for advanced checks while performing DnD.*/
    data?: IConfigList;

    items: IGroupListItemDef[];

    subItems?: IGroupSubItemProps[];

    provided?: DraggableProvided;
    snapshot?: DraggableStateSnapshot;
}

class Group extends React.Component<IProps & WithTranslation> {
    shouldComponentUpdate(nextProps: IProps & WithTranslation) {
        return shouldComponentUpdateIgnoreData(this.props, nextProps);
    }

    handleRemoveClick = () => {
        this.props.onRemoveClick?.(this.props.id);
    };

    handleLabelChange = (e: IEditableTextChangeEvent) => {
        this.props.onLabelChange?.(this.props.id, e.value);
    };

    renderCustomPlaceholder = (snapshot: DroppableStateSnapshot) => {
        if (!this.props.placeholder) {
            return null;
        }
        return (
                <>
                    {!isObjectEmpty(this.props.placeholder) && snapshot.isDraggingOver && (
                            <ItemPlaceholderWrapper
                                    style={{
                                        top: this.props.placeholder.clientY,
                                        left: this.props.placeholder.clientX,
                                        height: this.props.placeholder.clientHeight,
                                        width: this.props.placeholder.clientWidth
                                    }}>
                                <ItemPlaceholderLine data-testid={TestIds.PlaceholderLine}/>
                            </ItemPlaceholderWrapper>
                    )}
                </>
        );
    };

    renderItem = (item: IGroupListItemDef, index: number, isCopyDraggingOver?: boolean, movedItemIsCopyOnly?: boolean) => {
        return (
                <Item key={item.id} index={index}
                        // isDragDisabled={this.props.disabled}
                      isHighlighted={isCopyDraggingOver}
                      preventTransform={movedItemIsCopyOnly || isCopyDraggingOver}
                      level={this.props.isHierarchical ? index : 0}
                      {...item}
                >
                    {/*draggingOverWith is not called for disabled droppables*/}
                    {/*https://github.com/atlassian/react-beautiful-dnd/issues/1712*/}
                    {isCopyDraggingOver &&
                            <BinIcon width={IconSize.M} height={IconSize.M}/>
                    }
                </Item>
        );
    };

    getSubItemsIcon = (subItems: IGroupSubItemProps[]) => {
        if (!subItems?.filter(item => !!item.value).length) {
            return null;
        }

        const rows: string[] = [];

        subItems.forEach(item => {
            if (item.subItems) {
                // dependent group "header"
                if (rows.length) {
                    rows.push(""); // empty row to separate from previous group
                    rows.push(`${item.value}:`);
                }
                rows.push(...item.subItems.map(subItem => subItem.value));
                // process items of a dependent group
            } else {
                rows.push(item.value);
            }
        });

        return (
                <CustomizationIcon iconName={"DependentFields"} tooltipRows={rows}/>
        );
    };

    getRequiredItemAlert(): IAlertProps {
        return {
            status: Status.Error,
            title: this.props.t("Components:ConfigurationList.GroupWithRequiredItemTooltip"),
            isSmall: true
        };
    }

    render() {
        const { isLocked, subItems } = this.props;
        // see explanation at Item.tsx renderItem why renderClone has to be used
        const usePortal = this.props.snapshot.isDragging;
        const hasRequiredItem = this.props.items.some(item => item.isRequired);
        let Group = (
                <StyledConfigurationGroup
                        {...this.props.provided.draggableProps}
                        ref={this.props.provided.innerRef}
                        isTransparent={this.props.isTransparent}
                        isDraggable={this.props.isDraggable}
                        isDragging={this.props.snapshot.isDragging}
                        isLocked={isLocked}
                        isRemovable={this.props.isRemovable}
                        isGrowing={this.props.isGrowing}
                        data-testid={TestIds.ConfigurationGroup}>
                    {!this.props.isTransparent &&
                            <StyledGroupHeader isLocked={isLocked}>
                                <GroupLabel isEditable={this.props.isLabelEditable}>
                                    {!this.props.isLabelEditable && this.props.label}
                                    {this.props.isLabelEditable &&
                                            <ResponsiveEditableText
                                                    placeholder={this.props.t("Components:ConfigurationList.CustomGroupLabelPlaceholder")}
                                                    value={this.props.label}
                                                    onChange={this.handleLabelChange}/>
                                    }
                                </GroupLabel>
                                {isLocked && (
                                        <CustomizationIcon iconName={"LockedGroup"}/>
                                )}
                                <ControlButtonsGroup>
                                    {this.props.isRemovable &&
                                            <IconButton isDecorative title={this.props.t("Common:General.Remove")}
                                                        isDisabled={hasRequiredItem}
                                                        hoverAlert={hasRequiredItem ? this.getRequiredItemAlert() : undefined}
                                                        onClick={this.handleRemoveClick}>
                                                <BinIcon width={IconSize.S} height={IconSize.S}/>
                                            </IconButton>
                                    }
                                    {subItems?.length > 0 && this.getSubItemsIcon(subItems)}
                                    <DragIconWrapper
                                            {...this.props.provided.dragHandleProps}>
                                        <ReorderSmallIcon width={IconSize.M} height={IconSize.M}/>
                                    </DragIconWrapper>
                                </ControlButtonsGroup>
                            </StyledGroupHeader>
                    }
                    {!isLocked && (
                            <Droppable droppableId={this.props.id}
                                       type={GroupListDropType.Item}
                                       isDropDisabled={this.props.isDropDisabled}>
                                {
                                    // due to some extremely weird bug https://github.com/atlassian/react-beautiful-dnd/issues/854
                                    // the next block of code needs to be inline and cannot be moved into a method
                                    // otherwise ItemList is not being rerendered when keyboard navigation is used to move the items
                                    (provided: DroppableProvided, snapshot: DroppableStateSnapshot) => {
                                        const cleanDraggingOverWith = getCleanDraggableId(snapshot.draggingOverWith);
                                        const cleanDraggingFromWith = getCleanDraggableId(snapshot.draggingFromThisWith);
                                        const movedGroupItem = this.props.items.find(item => item.id === trimCopyId(cleanDraggingOverWith) || item.id === trimCopyId(cleanDraggingFromWith));
                                        const movedItemIsCopyOnly = movedGroupItem?.isCopyOnly;
                                        const movedDataDefItem = this.props.data.items[trimCopyId(cleanDraggingOverWith)];
                                        const movedItemIsGroupSeparator = movedDataDefItem?.isSeparator;

                                        return (
                                                <ItemList
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                        isTransparent={this.props.isTransparent}
                                                        _width={BasicInputSizes.M}
                                                >
                                                    {this.props.items.map((item, index) => {
                                                        const isCopyDraggingOver = cleanDraggingOverWith !== item.id && trimCopyId(cleanDraggingOverWith) === item.id;// && item.isCopyOnly;

                                                        if (item.isSeparator) {
                                                            return <GroupSeparatorItem key={item.id} {...item}
                                                                                       index={index}/>;
                                                        } else {
                                                            return this.renderItem(item, index, isCopyDraggingOver, movedItemIsCopyOnly);
                                                        }
                                                    })}
                                                    <PlaceholderVisibility visible={!movedItemIsCopyOnly}>
                                                        {provided.placeholder}
                                                    </PlaceholderVisibility>
                                                    {!movedItemIsCopyOnly && !movedItemIsGroupSeparator && this.renderCustomPlaceholder(snapshot)}
                                                </ItemList>
                                        );
                                    }
                                }
                            </Droppable>
                    )}
                </StyledConfigurationGroup>
        );

        if (usePortal) {
            const modalRoot = document.getElementById("modal-root");
            if (modalRoot) {
                Group = ReactDOM.createPortal(Group, modalRoot);
            }
        }

        return Group;
    }
}

const SelectItemPlaceholderFromCustomDnDContext = (props: IProps) => {
    const { placeholder, data } = useContext(CustomDnDContext);

    return placeholder?.droppableId === props.id ? { placeholder, data } : { data };
};

export const WrappedGroup = connectToContext(withTranslation(["Common"])(Group), SelectItemPlaceholderFromCustomDnDContext);

interface IDraggableGroupProps extends Omit<IProps, "provided" | "snapshot"> {
    children?: any;
}

const DraggableGroup = (props: IDraggableGroupProps) => {
    // draggableId has to be unique among ALL draggables (both Items and Groups in our case)
    // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/identifiers.md
    // append -group to differentiate between item with the same id
    return (
            <Draggable draggableId={`${props.id}-${GroupListDropType.Group}`} index={props.index}
                       isDragDisabled={props.isTransparent || !props.isDraggable}
            >
                {(provided, snapshot) => {
                    return (
                            <WrappedGroup {...props}
                                          provided={provided}
                                          snapshot={snapshot}/>
                    );
                }}
            </Draggable>
    );
};

export { DraggableGroup as Group };