import styled from "styled-components/macro";
import { T_HEADER_small, T_TITLE_2_2 } from "../../global.style";
import { TextAlign } from "../../enums";
import { Circle, StyledError } from "../inputs/formError/FormError.styles";
import { StyledIconButton } from "../button";

const HEADER_SPACE_MIN_SIZE = 45;
const HEADER_SPACE_MAX_SIZE = 100;

export const SummaryItemStyled = styled.div<{
    textAlign?: TextAlign;
}>`
    text-align: ${props => props.textAlign || TextAlign.Left};
    /* we need to keep width at minimum size of the space because we hide spaces, which are at the beginning of the line
     * so we don't want after hiding the space to show the item on previous line - @see Header.tsx->updatePaddings() */
    min-width: ${HEADER_SPACE_MIN_SIZE}px;
`;
export const Label = styled.div<{
    isEditable?: boolean;
    hasLink?: boolean;
}>`
    ${T_HEADER_small};
    white-space: nowrap;
    padding-right: ${props => props.isEditable || props.hasLink ? "38px" : 0};
`;
export const Value = styled.div<{
    label: string;
    hasIcon?: boolean;
    hasPadding?: boolean;
}>`
    ${T_TITLE_2_2};
    font-weight: ${props => props.label ? "normal" : "bold"};
    color: ${props => props.color || "inherit"};
    white-space: nowrap;
    position: relative;
    top: ${props => props.hasIcon ? "2px" : 0};
    padding: ${props => props.hasPadding ? "4px 0" : null};

    ${StyledError} {
        bottom: 0;
        color: ${props => props.theme.C_TEXT_primary};

        ${Circle} {
            // WriteLine height is dynamic according to font size - keeps the circle with top line of the text
            top: -1.3em;
            left: -8px;
        }
    }
`;

export const Space = styled.div`
    min-width: ${HEADER_SPACE_MIN_SIZE}px;
    max-width: ${HEADER_SPACE_MAX_SIZE}px;
`;

export const ValueWrapper = styled.div<{
    textAlign?: TextAlign;
    hasPadding?: boolean;
}>`
    display: flex;
    align-items: center;
    justify-content: ${props => props.textAlign === TextAlign.Right ? 'flex-end' : 'flex-start'};

    padding-top: ${props => props.hasPadding ? "4px" : 0};

    ${StyledIconButton} {
        margin-left: 2px;
    }
`;