import React, { Component, ComponentType, ReactElement, useCallback, useContext, useState } from "react";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { AppContext, AppMode, ContextEvents, IAppContext } from "../../../contexts/appContext/AppContext.types";
import { getCompanyLogoUrl, getCompanySelectorTitleKey } from "@utils/CompanyUtils";
import { CaretIcon, PlusIcon } from "../../icon";
import { AvatarSize, IconSize } from "../../../enums";
import { IFieldComponentProps, ISelectionChangeArgs, ISelectItem } from "../../inputs/select/BasicSelect";
import Avatar from "../../avatar/Avatar";
import TestIds from "../../../testIds";
import { useTranslation, WithTranslation, withTranslation } from "react-i18next";
import memoizeOne from "../../../utils/memoizeOne";
import { handleRefHandlers } from "@utils/general";
import { ICompanyEntity } from "@odata/GeneratedEntityTypes";
import { KeyName } from "../../../keyName";
import { Select } from "../../inputs/select";
import { HOTSPOT_ID_ATTR } from "../../hotspots/Hotspots.utils";
import { handleCompanyCreated } from "@pages/companies/Company.utils";
import CompanyDialogPage from "../../../pages/companies/CompanyDialogPage";
import ourOrganizationTileSvg from "../../../pages/companies/ourOrganizationTile.svg";
import {
    WithPermissionContext,
    withPermissionContext
} from "../../../contexts/permissionContext/withPermissionContext";
import { CompanyStateCode, GeneralPermissionCode } from "@odata/GeneratedEnums";
import { ROUTE_COMPANIES, ROUTE_HOME } from "../../../routes";
import {
    Background,
    CompanyNumber,
    CompanySelectorWrapper,
    CompanyTitle,
    Container,
    CreateCompanyButton,
    Hover,
    IconButtonStyled,
    InactiveText,
    LeftPart,
    Text
} from "./Selector.styles";
import { addCompanyIdToUrl } from "../../../contexts/appContext/AppContext.utils";


const OUR_ORGANIZATION_ID = "0";

export const companyToSelectItem = (c: ICompanyEntity): ISelectItem => ({
    id: c.Id,
    label: c.Name,
    tabularData: [c.Name, c.LegalNumber]
});

interface IItemPreviewProps {
    src: string;
    title: string;
    subtitle?: string;
    isActive?: boolean;
}

const ItemPreview = React.memo((props: IItemPreviewProps) => {
    const { t } = useTranslation(["Common"]);

    return (
        <>
            <Avatar src={props.src} size={AvatarSize.M}/>
            <Text>
                <CompanyTitle title={props.title} data-testid={TestIds.Title}>{props.title}</CompanyTitle>
                {props.isActive &&
                    <CompanyNumber data-testid={TestIds.Subtitle}>{props.subtitle}</CompanyNumber>
                }
                {!props.isActive &&
                    <InactiveText data-testid={TestIds.Subtitle}>{t("Common:General.Inactive")}</InactiveText>
                }
            </Text>
        </>
    );
});

interface ICompanyPreviewProps {
    company: ICompanyEntity;
}

const CompanyPreview = React.memo((props: ICompanyPreviewProps) => {
    const logoUrl = getCompanyLogoUrl(props.company);

    return <ItemPreview src={logoUrl}
                        title={props.company.Name}
                        subtitle={props.company.LegalNumber}
                        isActive={props.company.StateCode !== CompanyStateCode.New}/>;
});

const OurOrganizationPreview = React.memo(() => {
    const { t } = useTranslation(["Components"]);
    const context = useContext(AppContext);

    return <ItemPreview src={ourOrganizationTileSvg}
                        title={t(`Components:OurOrganizationTile.${getCompanySelectorTitleKey(context)}`)}
                        subtitle={t("Components:OurOrganizationTile.Subtitle")}
                        isActive/>;
});

const CreateAgendaButton: React.FC = () => {
    const context = useContext(AppContext);
    const { t } = useTranslation("Common");
    const history = useHistory();
    const [isDialogOpen, setOpen] = useState<boolean>(false);

    const handleCreateAgenda = useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = useCallback(async (companyId: number) => {
        if (companyId) {
            await handleCompanyCreated(context, companyId);
            // navigate to companies to set up the created company
            history.push(ROUTE_COMPANIES);
        } else {
            setOpen(false);
        }
    }, [setOpen, context, history]);

    return (
        <>
            <CreateCompanyButton onClick={handleCreateAgenda}
                                 icon={<PlusIcon preventHover/>}
                                 isDecorative>
                {t("Common:General.CreateAgenda")}
            </CreateCompanyButton>
            <Hover/>
            <CompanyDialogPage id={null} isOpen={isDialogOpen} onClose={handleClose}/>
        </>
    );
};


interface IProps {
    onClick: () => void;
    isDisabled?: boolean;
}

type PropsHOC = IProps & WithTranslation & WithPermissionContext & RouteComponentProps;

class CompanySelector extends Component<PropsHOC> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    _ref = React.createRef<HTMLDivElement>();
    _refButton = React.createRef<HTMLButtonElement>();
    handleButtonRef = memoizeOne((openerRef: React.Ref<HTMLElement>) => {
        return (ref: HTMLButtonElement) => {
            handleRefHandlers(ref, this._refButton, openerRef);
        };
    });

    constructor(props: PropsHOC, context: IAppContext) {
        super(props, context);

        context.eventEmitter.on(ContextEvents.CompanyChanged, this.companyChange);
    }

    companyChange = (id: number): void => {
        this.forceUpdate();
    };

    componentWillUnmount(): void {
        this.context.eventEmitter.off(ContextEvents.CompanyChanged, this.companyChange);
    }

    canCreateCompany(): boolean {
        return this.props.permissionContext.generalPermissions.has(GeneralPermissionCode.CompanyManagement);
    }

    getValue = () => {
        return this.context.getAppMode() === AppMode.OrganizationSettings ? OUR_ORGANIZATION_ID : this.context.getCompanyId();
    };

    handleChange = (e: ISelectionChangeArgs): void => {
        if (e.triggerAdditionalTasks) {
            const hasValueChanged = this.getValue() !== e.value;

            if (!hasValueChanged) {
                return;
            }

            if (e.value === OUR_ORGANIZATION_ID) {
                this.context.setAppMode(AppMode.OrganizationSettings);
            } else {
                this.context.setCurrentCompanyId(e.value);
            }

            const url = addCompanyIdToUrl(ROUTE_HOME, e.value && e.value !== OUR_ORGANIZATION_ID ? e.value as number : null);

            this.props.history.push(url);
        }
    };

    renderFieldComponent = (props: IFieldComponentProps): ReactElement => {
        return (
            <IconButtonStyled
                title={""}
                isDecorative
                onKeyDown={(e) => {
                    if (e.key === KeyName.Enter || e.key === KeyName.Space) {
                        props.onIconClick(e as unknown as React.MouseEvent);
                    }
                }}
                onMouseDown={(e: React.MouseEvent) => {
                    // Prevents React from resetting event properties: https://reactjs.org/docs/legacy-event-pooling.html
                    // todo: not needed after update to React 17
                    e.persist();
                    if (!props.isOpen) {
                        setTimeout(() => {
                            props.onIconClick(e);
                        });
                    }
                }}
                passRef={this.handleButtonRef(this._refButton)}>
                <CaretIcon
                    width={IconSize.XS}
                    isLightHover/>
            </IconButtonStyled>
        );
    };

    renderContent(companies: ICompanyEntity[] = []): React.ReactElement {
        const context = this.context as IAppContext;

        if (companies?.length || context.hasLimitedAccess()) {
            const items = companies.map(companyToSelectItem);

            items.unshift({
                id: OUR_ORGANIZATION_ID,
                label: this.props.t(`Components:OurOrganizationTile.${getCompanySelectorTitleKey(context)}`),
                groupId: "soloGroup"
            });

            const isOrganizationSettingsMode = this.context.getAppMode() === AppMode.OrganizationSettings;
            const value = this.getValue();

            return (<>
                <LeftPart onClick={this.props.onClick}
                          data-testid={TestIds.CompanyOpener}>
                    <Hover/>
                    {isOrganizationSettingsMode ?
                            <OurOrganizationPreview/> :
                        <CompanyPreview company={this.context.getCompany()}/>
                    }

                </LeftPart>
                <Select
                    columns={[{
                        id: "LegalName"
                    }, {
                        id: "LegalNumber"
                    }]}
                    openerRef={this._ref}
                    buttonRef={this._refButton}
                    showSearchBoxInMenu={true}
                    value={value}
                    width="240px"
                    isIconSelect={true}
                    onChange={this.handleChange}
                    useAutoselection={false}
                    fieldComponent={this.renderFieldComponent}
                    items={items}/>
            </>);
        }
        return this.canCreateCompany() ? (<CreateAgendaButton/>) : null;
    }

    render() {
        const context = this.context as IAppContext;
        const companies = context.getData().companies;

        if (!companies?.length && !this.canCreateCompany() && !context.hasLimitedAccess()) {
            return null;
        }

        return (
            <CompanySelectorWrapper isDisabled={this.props.isDisabled}>
                <Container ref={this._ref}
                           {...{ [HOTSPOT_ID_ATTR]: "CompanySelector" }}
                           data-testid={TestIds.CompanySelector}
                           tabIndex={0}
                           onKeyDown={(e) => {
                               if (e.key === KeyName.Enter) {
                                   this.props.onClick();
                               }
                           }}
                           isInactiveCompany={this.context.getAddingNewCompany()}>
                    <Background>
                        {this.renderContent(companies)}
                    </Background>
                </Container>
            </CompanySelectorWrapper>
        );
    }
}

const CompanySelectorExtended = withRouter<RouteComponentProps<{}>, any>(withTranslation(["Components"])(withPermissionContext(CompanySelector))) as unknown as ComponentType<IProps>;
CompanySelectorExtended.displayName = "CompanySelectorWithHOCs";

export { CompanySelectorExtended as CompanySelector };