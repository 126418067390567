import styled from "styled-components/macro";
import Avatar from "../../components/avatar/Avatar";
import { getFocusBorderElement, T_PLAIN_small_hig, T_TITLE_4_NORMAL } from "../../global.style";
import { AvatarHover, AvatarSelected } from "@components/avatar";
import { ellipsis } from "polished";

export const Header = styled.div`
    display: flex;
    align-items: flex-end;
    margin-bottom: 35px;
`;

export const AvatarStyledCompany = styled(Avatar)`
    margin-right: 42px;
`;

export const StyledAvatarSelector = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    row-gap: 20px;
    column-gap: 20px;
`;

export const AvatarWrapper = styled.div <{
    _isSelected: boolean;
}>`
    position: relative;
    ${props => props._isSelected && AvatarSelected};

    &:hover:after {
        ${AvatarHover}
    }

    ${getFocusBorderElement({ offset: -3, borderRadius: "3px" })};
`;

interface IStampText {
    image: File | string;
}

export const StampText = styled.div<IStampText>`
    ${T_TITLE_4_NORMAL};
    margin-left: 12px;
    margin-right: 5px;
    margin-bottom: ${props => props.image ? "10px" : "0"};
    flex: 0 0 ${props => props.image ? "100%" : "auto"};
`;

export const CompanyAvatarWithNameWrapper = styled.div`
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const CompanyAvatarName = styled.span`
    ${ellipsis()};
    ${T_PLAIN_small_hig};
    margin-left: 8px;
`;