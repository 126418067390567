import React from "react";
import { FileActionButton, FileInnerWrapper, FileName, StyledFile } from "./FileUploader.styles";
import { ActionState, IconSize, TextAlign } from "../../enums";
import { BinIcon, getIcon, OtherDocIcon, RefreshIcon } from "../icon";
import { withTranslation, WithTranslation } from "react-i18next";
import { ISelectItem } from "../inputs/select/BasicSelect";
import TestIds from "../../testIds";
import { TId } from "../table";
import WriteLine from "../inputs/writeLine";
import { IInputOnChangeEvent } from "../inputs/input";
import { KeyName } from "../../keyName";
import { IValidationError } from "../../model/Validator.types";
import { WithContextMenu, withContextMenu } from "../table/withContextMenu";
import { IFileMetadataEntity } from "@odata/GeneratedEntityTypes";

export interface IFile {
    id: TId;
    name?: string;
    metadata?: IFileMetadataEntity;
}

interface IProps extends IFile, WithTranslation, Omit<WithContextMenu, "id"> {
    icon?: string;
    removingAction?: ActionState;
    isRenaming?: boolean;
    error?: IValidationError;
    onFileNameChange?: (id: TId, newName: string) => void;
    onFileRenameConfirm?: (id: TId) => void;
    onFileRenameCancel?: (id: TId) => void;
    onRemoveActionClick?: (id: TId) => void;
    onClick?: (id: TId) => void;
    items?: ISelectItem[];
    onItemSelection?: (fileId: TId, item: ISelectItem) => void;
    passProps?: any;
}

class File extends React.PureComponent<IProps> {
    static defaultProps: Partial<IProps> = {
        removingAction: ActionState.None,
        icon: "OtherDoc",
        items: []
    };

    fileNameInputRef = React.createRef<HTMLInputElement>();

    get isDisabled() {
        return this.props.isContextMenuOpen;
    }

    componentDidUpdate(prevProps: IProps) {
        if (!prevProps.isRenaming && this.props.isRenaming) {
            this.fileNameInputRef.current?.focus();
        }
    }

    handleActionClick = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();

        this.props.onRemoveActionClick?.(this.props.id);
    };

    handleFileClick = () => {
        if (this.isDisabled || this.props.isRenaming) {
            return;
        }

        this.props.onClick?.(this.props.id);
    };

    handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        switch (event.key) {
            case KeyName.Enter:
                this.handleFileClick();
                break;
        }
    };

    getActionIcon = () => {
        const ActionIcon = this.isRemoving() ? RefreshIcon : BinIcon;

        return (
            <ActionIcon width={IconSize.S}/>
        );
    };

    getActionTitle = () => {
        return this.props.t(`Components:FileUploader.${this.isRemoving() ? "Restore" : "Delete"}`);
    };

    isRemoving = () => {
        return this.props.removingAction === ActionState.Active;
    };

    /** Same visual as disabled File, but clickable */
    isDisabledLook = () => {
        return this.isRemoving();
    };

    getFileIcon = (preventHover = false) => {
        const FileIcon = getIcon(this.props.icon) || OtherDocIcon;

        return (
            <FileIcon width={"140px"} height={"140px"} isLightHover preventHover={preventHover}/>
        );
    };

    handleFileNameChange = (args: IInputOnChangeEvent<string>) => {
        this.props.onFileNameChange?.(this.props.id, args.value);
    };

    handleRenameConfirm = async () => {
        this.props.onFileRenameConfirm(this.props.id);
    };

    handleRenameCancel = () => {
        this.props.onFileRenameCancel(this.props.id);
    };

    renderFileName = () => {
        return (
            <>
                {!this.props.isRenaming && <FileName data-testid={TestIds.AttachmentName}>{this.props.name}</FileName>}
                {this.props.isRenaming &&
                <WriteLine value={this.props.name}
                           error={this.props.error}
                           textAlign={TextAlign.Left}
                           isConfirmable
                           passRef={this.fileNameInputRef}
                           onChange={this.handleFileNameChange}
                           onConfirm={this.handleRenameConfirm}
                           onCancel={this.handleRenameCancel}/>
                }
            </>
        );
    };

    render() {
        const isActionIconVisible = !this.props.removingAction || this.props.removingAction !== ActionState.None;
        return (
            <StyledFile key={this.props.id.toString()}
                        data-id={this.props.id.toString()}
                        title={this.props.name}
                        onKeyDown={this.handleKeyDown}
                        onContextMenu={this.props.onContextMenu}
                        tabIndex={0}
                        {...this.props.passProps}
                        data-testid={TestIds.Attachment}>
                {isActionIconVisible &&
                <FileActionButton onClick={this.handleActionClick}
                                  title={this.getActionTitle()}
                                  isDecorative>
                    {this.getActionIcon()}
                </FileActionButton>
                }
                <FileInnerWrapper onClick={this.handleFileClick}
                                  isActionIconVisible={isActionIconVisible}
                                  isDisabled={this.isDisabled}
                                  isDisabledLook={this.isDisabledLook()}>
                    {this.getFileIcon(isActionIconVisible)}
                    {this.renderFileName()}
                </FileInnerWrapper>
            </StyledFile>
        );
    }
}

export default withTranslation(["Components"])(withContextMenu(File));
