import { FormStorage } from "./FormStorage";
import BindingContext from "../../odata/BindingContext";
import { IInputOnBlurEvent } from "@components/inputs/input";
import SmartField, { ISmartFieldChange } from "../../components/smart/smartField/SmartField";
import Dialog, { IDialogProps } from "../../components/dialog/Dialog";
import React from "react";
import ConfirmationButtons from "../table/ConfirmationButtons";
import BusyIndicator from "../../components/busyIndicator";
import memoizeOne from "../../utils/memoizeOne";
import FieldsWrapper from "../../components/inputs/field/FieldsWrapper";

export interface IRenderFieldProps {
    storage: FormStorage;
    bindingContext: BindingContext;
    onBlur: (e: IInputOnBlurEvent) => void;
    onTemporalChange: (args: ISmartFieldChange) => void;
}

export interface ITemporalFormDialogProps extends Pick<IDialogProps, "width" | "minWidth"> {
    storage: FormStorage;
    fieldPaths: string[][];
    title: string;
    onClose: (isConfirmed: boolean) => void;
    isLoading?: boolean;
    confirmText?: string;
    onChange?: (args: ISmartFieldChange) => void;
    onTemporalChange?: (args: ISmartFieldChange) => void;
    renderField?: (name: string, props: IRenderFieldProps) => React.ReactElement;
}

export default class TemporalFormDialog extends React.PureComponent<ITemporalFormDialogProps> {

    getFieldBindingContexts = memoizeOne((): BindingContext[] => {
        const { storage, fieldPaths } = this.props;

        return [].concat(...fieldPaths).map(propName => storage.data.bindingContext.navigate(propName));
    });

    get isLoading(): boolean {
        return this.props.isLoading || !this.props.storage.loaded || this.props.storage.isBusy();
    }

    handleConfirmDialog = async (): Promise<void> => {
        const errors = await this.props.storage.validateFields(this.getFieldBindingContexts());
        if (!errors?.some(e => !!e)) {
            const isConfirmed = await this.props.storage.confirmFields(this.getFieldBindingContexts(), this.props.onChange);
            if (isConfirmed) {
                this.props.onClose(true);
                return;
            }
        }
        this.props.storage.refreshFields();
    };

    handleCancel = (): void => {
        this.props.storage.cancelFields(this.getFieldBindingContexts());
        this.props.onClose(false);
    };

    handleBlur = (bc: BindingContext, e: IInputOnBlurEvent): void => {
        this.props.storage.validateField(bc)
            .then(() => this.forceUpdate());
    };

    handleTemporalChange = (args: ISmartFieldChange): void => {
        const { storage } = this.props;
        this.props.onTemporalChange?.(args);
        storage.handleTemporalChange(args);
        storage.refreshFields();
    };

    renderFields = (): React.ReactElement => {
        return (<>
            {this.props.fieldPaths.map((row, idx) => (
                <FieldsWrapper key={idx}>
                    {row.map((name) => {
                            const { storage } = this.props;
                            const bindingContext = storage.data.bindingContext.navigate(name);
                            const renderProps = {
                                storage,
                                bindingContext,
                                onBlur: this.handleBlur.bind(this, bindingContext),
                                onTemporalChange: this.handleTemporalChange
                            };

                            return this.props.renderField?.(name, renderProps) ?? (
                                <SmartField key={name} {...renderProps} fieldDef={{ isConfirmable: true }}/>
                            );
                        }
                    )}
                </FieldsWrapper>
            ))}
        </>);
    };

    render = (): React.ReactElement => (
        <Dialog title={this.props.title}
                width={this.props.width}
                minWidth={this.props.minWidth}
                onClose={this.handleCancel}
                onConfirm={this.handleConfirmDialog}
                footer={
                    <ConfirmationButtons
                        confirmText={this.props.confirmText ?? this.props.storage.t("Common:General.Save")}
                        onConfirm={this.handleConfirmDialog}
                        onCancel={this.handleCancel}
                        useWrapper={false}/>
                }>
            {!this.isLoading ? (
                <>
                    {this.props.children}
                    <FieldsWrapper isColumn>
                        {this.renderFields()}
                    </FieldsWrapper>
                </>
            ) : <BusyIndicator/>}
        </Dialog>
    );
}