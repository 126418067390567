import { DeviceType, IAuthDevice } from "../../contexts/authContext/Auth.utils";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, TRootLoginState } from './store';
import { AUTH_DEVICE_URL, AUTH_DEVICE_URL_ADD_URL, AUTH_DEVICE_URL_RESEND_URL } from "../../constants";
import { getDefaultHeaders, getDefaultPostParams } from "@utils/customFetch";
import { loadSession, setDeviceGUID, setDeviceIsVerified } from "./sessionSlice";
import * as deviceDetect from "react-device-detect";


interface IDeviceState {
    list: IAuthDevice[];
    error: string | null;
}

const initialState: IDeviceState = {
    list: [],
    error: null
};

const deviceSlice = createSlice({
    name: 'device',
    initialState,
    reducers: {
        loadStart(state) {
            state.error = null;
        },
        appendDevice(state, action: PayloadAction<IAuthDevice>) {
            const added = action.payload;
            state.list = [...state.list.filter(d => d.Id !== added.Id), added];
        },
        setDevices(state, action: PayloadAction<IAuthDevice[]>) {
            state.list = action.payload;
            state.error = null;
        },
        loadFailure(state, action: PayloadAction<string>) {
            state.error = action.payload;
        }
    },
});

export const {
    loadStart,
    appendDevice,
    setDevices,
    loadFailure
} = deviceSlice.actions;

export default deviceSlice.reducer;

export const loadDevices = (): AppThunk => async dispatch => {
    try {
        dispatch(loadStart());

        const result = await fetch(AUTH_DEVICE_URL);

        if (result.ok) {
            const devices = await result.json();
            dispatch(setDevices(devices));
        } else if (result.status === 401) {
            // todo: general handler
            dispatch(loadFailure("Unauthorized"));
        } else {
            dispatch(loadFailure("Error occurred: " + result));
        }
    } catch (error) {
        dispatch(loadFailure(error.message));
    }
};

// Thunk action for adding device to the trusted device list - permanently or only for current session
export const addDevice = (deviceName: string, oneTime: boolean): AppThunk => async dispatch => {
    try {
        dispatch(loadStart());

        const result = await fetch(AUTH_DEVICE_URL_ADD_URL, {
            ...getDefaultPostParams(),
            body: JSON.stringify({
                Name: deviceName,
                OneTime: oneTime
            })
        });

        if (result.ok) {
            const device = await result.json();
            dispatch(appendDevice(device));
            dispatch(setDeviceGUID(device.Id));
            dispatch(setDeviceIsVerified(device.IsVerified));
        } else if (result.status === 401) {
            // todo: general handler
            dispatch(loadFailure("Unauthorized"));
        } else {
            dispatch(loadFailure("Error occurred: " + result));
        }
    } catch (error) {
        dispatch(loadFailure(error.message));
    }
};

export const resendDeviceEmail = (currentDevice: IAuthDevice): AppThunk => async dispatch => {
    try {
        dispatch(loadStart());

        const { Id, Name, OneTime } = currentDevice;
        const response = await fetch(AUTH_DEVICE_URL_RESEND_URL, {
            ...getDefaultPostParams(),
            body: JSON.stringify({ Id, Name, OneTime })
        });
        if (response.ok) {
            // todo: some confirmation ??
        } else if (response.status === 401) {
            // todo: general handler
            dispatch(loadFailure("unauthorized"));
        } else if (response.status === 409) {
            dispatch(loadSession());
            // device obnoven -> refetch session ??
            // this.setState(state => ({
            //     currentDevice: {
            //         ...state.currentDevice,
            //         IsVerified: true
            //     }
            // }));
        } else {
            dispatch(loadFailure("Error occurred..."));
        }
    } catch (error) {
        dispatch(loadFailure(error.message));
    }
};

/**
 * Remove device from added devices list
 * @param id
 */
export const deleteDevice = (id: string): AppThunk => async dispatch => {
    try {
        const result = await fetch(AUTH_DEVICE_URL, {
            method: "DELETE",
            headers: getDefaultHeaders(),
            body: JSON.stringify(
                {
                    id: id
                }
            )
        });

        if (result.ok) {
            const devices = await result.json();
            dispatch(setDevices(devices));
        } else if (result.status === 401) {
            // todo: general handler
            dispatch(loadFailure("unauthorized"));
        }
    } finally {
    }
};

export const getCurrentDeviceName = () => {
    if (deviceDetect.isMobile) {
        return `${deviceDetect.mobileVendor} ${deviceDetect.mobileModel} ${deviceDetect.osVersion}`;
    }

    return `PC ${deviceDetect.osName} ${deviceDetect.osVersion}`;
};

export const getCurrentDeviceType = (): DeviceType => {
    if (deviceDetect.isTablet) {
        return DeviceType.Tablet;
    }

    return deviceDetect.isMobile ? DeviceType.Mobile : DeviceType.PC;
};

export const selectCurrentInfo = (state: TRootLoginState): IAuthDevice => {
    const { sessionData } = state.session;
    const id = sessionData?.DeviceGuid;
    const savedDevice = id && state.device.list?.find(d => d.Id === id);
    return savedDevice ?? {
        Id: sessionData?.DeviceGuid,
        Type: getCurrentDeviceType(),
        Name: getCurrentDeviceName(),
        OneTime: true,
        IsVerified: !!sessionData?.IsVerifiedDevice
    };
};
export const selectEmailSent = (state: TRootLoginState): boolean => !!state.session.sessionData?.DeviceGuid;
export const selectDevices = (state: TRootLoginState): IAuthDevice[] => state.device.list;

