import React, { useEffect } from "react";
import { LoginSmallBottomClickable, LoginSmallBottomText, LoginTitle } from "./LoginPage.styles";
import i18next from "i18next";
import { ROUTE_LOGIN_INVITATION, ROUTE_LOGIN_REGISTER } from "../routes";
import TestIds from "../testIds";
import LoginForm from "./components/LoginForm";
import { useLoginDispatch, useLoginSelector } from "./state/hooks";
import { BackArrow } from "./components/BackArrow";
import { selectInvitationCode } from "./state/invitationSlice";
import { getQueryParameters } from "../routes/Routes.utils";
import { QueryParam } from "../enums";
import { setAnimationType, setNextURL } from "./state/loginSlice";
import Redirects from "./components/Redirects";
import { AnimationType } from "./Login.utils";


interface IProps {
}

const Login: React.FC<IProps> = () => {
    const dispatch = useLoginDispatch();
    const code = useLoginSelector(selectInvitationCode);

    useEffect(() => {
        const nextURL = getQueryParameters()?.[QueryParam.RedirectUri] as string ?? null;

        dispatch(setNextURL(nextURL));

        dispatch(setAnimationType(AnimationType.BikerWithSign));
    }, [dispatch]);

    return (<>
        <Redirects skipNoSessionCheck/>
        <LoginTitle data-testid={TestIds.Title}>
            {code && <BackArrow url={`${ROUTE_LOGIN_INVITATION}/${code}`}/>}
            {i18next.t("Login:Login.Title")}
        </LoginTitle>
        <LoginForm>
            {!code && (<>
                <LoginSmallBottomText
                    data-testid={TestIds.BottomTextLabel}>{i18next.t("Login:Login.NotRegistered")}</LoginSmallBottomText>
                <LoginSmallBottomClickable testId={TestIds.BottomTextLink}
                                           link={ROUTE_LOGIN_REGISTER}>{i18next.t("Login:Login.RegisterHere")}</LoginSmallBottomClickable>
            </>)}
        </LoginForm>
    </>);
};

export default Login;
