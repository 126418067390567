import React from "react";
import { withTranslation } from "react-i18next";
import { AppContext, IAppContext, IAppContextData } from "../../contexts/appContext/AppContext.types";
import { IEntity, TEntityKey } from "@odata/BindingContext";
import { IconSize, Status, TextAlign, ToolbarItemType } from "../../enums";
import ViewHeader from "../../components/smart/smartHeader/SmartHeader";
import { ScrollBar } from "../../components/scrollBar/ScrollBar";
import { BreadCrumbProvider } from "../../components/breadCrumb/BreadCrumbProvider";
import { WriteLineWrapper } from "@components/navigation/NavDashboard.styles";
import WriteLine from "../../components/inputs/writeLine/WriteLine";
import { AddIcon } from "@components/icon";
import { IInputOnChangeEvent } from "@components/inputs/input";
import { anyPartStartsWithAccentsInsensitive } from "@utils/string";
import ObjectList, { ISection } from "../../components/objectList/ObjectList";
import { IListItem, ListItemContentRow } from "@components/objectList";
import DateType from "../../types/Date";
import { TDefinitionFn, TRecordAny } from "../../global.types";
import { withRouter } from "react-router-dom";
import { Button, IconButton } from "../../components/button";
import { ROUTE_CHARTS_OF_ACCOUNTS_TEMPLATES, ROUTE_NOT_FOUND } from "../../routes";
import BusyIndicator from "../../components/busyIndicator/BusyIndicator";
import Dialog from "../../components/dialog/Dialog";
import { getDefinitions } from "./ChartOfAccountsTemplatesDef";
import { ChartOfAccountTemplateContext, ICOATemplateContext } from "./ChartOfAccountsTemplatesContext";
import ChartOfAccountsTemplatesTableView from "./ChartOfAccountsTemplatesTableView";
import { Toolbar } from "../../components/toolbar";
import {
    cloneChartOfAccountsTemplate,
    createMinimalChartOfAccountsTemplate,
    rewriteChartOfAccountsWithTemplate
} from "../chartOfAccounts/ChartOfAccounts.utils";
import Alert, { AlertPosition, IAlertProps } from "../../components/alert/Alert";
import { isVisibleInContainer, scrollIntoView } from "@utils/domUtils";
import ParentChildPage, { IParentPageProps } from "../ParentChildPage";
import { getUniqName } from "./ChartOfAccountsTemplates.utils";
import { IHistoryState } from "../../model/Model";
import { withOData } from "@odata/withOData";
import {
    CompanySettingEntity,
    EntitySetName,
    IChartOfAccountsEntity,
    IChartOfAccountsTemplateEntity,
    ICompanySettingEntity
} from "@odata/GeneratedEntityTypes";
import { ChartOfAccountsTemplatesViewStyled } from "./ChartOfAccountsTemplates.styles";
import NewTemplateDialog from "../chartOfAccounts/NewTemplateDialog";
import dayjs from "dayjs";
import { WithAlert, withAlert } from "@components/alert/withAlert";
import { refreshFiscalYears } from "../fiscalYear/FiscalYear.utils";
import { AccountingCode } from "@odata/GeneratedEnums";
import { TableButtonsAction } from "../../views/table/TableToolbar";
import KeyboardShortcutsManager, {
    KeyboardShortcut
} from "../../utils/keyboardShortcutsManager/KeyboardShortcutsManager";
import { WithBusyIndicator, withBusyIndicator } from "@components/busyIndicator/withBusyIndicator";
import { ListItemStatus } from "@components/objectList/ListItem.styles";

import { isODataError } from "@odata/Data.types";

interface IProps extends IParentPageProps, WithAlert, WithBusyIndicator {
    className?: string;
}

interface ITemplateAction {
    templateId?: TEntityKey;
    type: ChartOfAccountsTemplateAction;
    confirmed?: boolean;
}

interface IState {
    action?: ITemplateAction;
    alertData?: IAlertMessageData;

    definition?: TDefinitionFn;
    filter?: string;
    companySettings?: ICompanySettingEntity;
}

enum ChartOfAccountsTemplateAction {
    EDIT = "edit",
    DELETE = "delete",
    COPY = "copy",
    ADD = "add",
    VIEW = "view",
    REWRITE = "rewrite"
}

interface IAlertMessageData {
    key: string;
    alert: IAlertProps;
}

class ChartOfAccountsTemplates extends ParentChildPage<IProps, IState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;
    static defaultProps = {
        tableView: ChartOfAccountsTemplatesTableView,
        getDef: getDefinitions
    };

    _scrollRef = React.createRef<HTMLDivElement>();

    // usually, this is stored on a Model, but we don't use any here => use custom variable
    initialHistoryState?: IHistoryState;
    templateContext: ICOATemplateContext;
    _shouldCloseOnSaveName = false;
    _unsubscribeKeyboardShortcuts: () => void;

    constructor(props: IProps, context: IAppContext) {
        super(props, context);

        this.initialHistoryState = this.props.location.state as IHistoryState;

        let action;
        if (this.initialHistoryState?.customData?.created) {
            // Redirected from ChartOfAccountsTableView, where the actual template was created
            //  -> adds action to state, so the flow is same as when we create a new template on this page
            action = {
                templateId: this.getParentKey(),
                type: ChartOfAccountsTemplateAction.ADD
            };
        }

        this._unsubscribeKeyboardShortcuts = KeyboardShortcutsManager.subscribe({
            shortcuts: [KeyboardShortcut.ALT_N],
            callback: this.handleKeyboardShortcut
        });

        this.templateContext = {
            isChangingName: false,
            isChanged: false,
            onTemplateNameChange: this.handleTemplateNameChange,
            onTemplateNameChangeFailed: this.handleTemplateNameChangeFailed
        };

        this.state = {
            filter: "",
            action
        };
    }

    getContext = () => {
        return this.context as IAppContext;
    };

    get showDialog() {
        return !!this.getParentKey();
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.loadCompanySettings();
        this.setupMessageAndScrollToTemplate();
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        await super.componentDidUpdate(prevProps, prevState);

        const templateId = this.getParentKey();
        if (templateId !== prevProps.match.params.ParentId) {
            this.setupMessageAndScrollToTemplate();
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        this._unsubscribeKeyboardShortcuts();
    }

    loadCompanySettings = async (): Promise<void> => {
        const companySettingsRes = await this.props.oData.getEntitySetWrapper(EntitySetName.CompanySettings)
            .query()
            .select(CompanySettingEntity.Id, CompanySettingEntity.InitialChartOfAccountsTemplateId)
            .top(1)
            .fetchData<ICompanySettingEntity[]>();
        const companySettings = (companySettingsRes.value as ICompanySettingEntity[])[0];
        this.setState({ companySettings });
    };

    handleKeyboardShortcut = (shortcut: KeyboardShortcut, event: KeyboardEvent): boolean => {
        if (shortcut === KeyboardShortcut.ALT_N) {
            this.handleAdd();
            return true;
        }

        return false;
    };

    redirectToFirstParent() {
        /* Templates has no default parent - just parent list is displayed when there is no parentId,
           so we don't want to redirect to the first one (do not call "super()" here) */

        // but if ID is defined and it's not in parents -> redirect to error route (as it's invalid ID)
        if (this.parents?.length) {
            const templateId = this.getParentKey();
            if (templateId && !this.parents?.find(item => item.Id.toString() === templateId)) {
                // invalid template ID
                this.props.history.push(ROUTE_NOT_FOUND);
            }
        }
    }

    setBreadCrumbs = () => {
        this.context.setViewBreadcrumbs({
            lockable: false,
            items: [{
                key: "templates",
                link: ROUTE_CHARTS_OF_ACCOUNTS_TEMPLATES,
                title: this.props.t("ChartsOfAccountsTemplates:Title")
            }]
        });
    };

    setupMessageAndScrollToTemplate = () => {
        const template = this.getParentEntity();
        if (!template) {
            // Id was removed from URL - do same actions as dialog would be closed
            this.prepareMessage();

            // clears action
            this.confirmAction(false);
        }
        this.templateContext.isChanged = false;
        if (this.state.alertData) {

            // scroll to the template for which we are showing message
            this.scrollToTemplate(this.state.alertData?.key);
        }
    };

    get isAddingNewCompany() {
        return this.context.getAddingNewCompany();
    }

    createTemplateProperties = (item: IEntity): IListItem => {
        const actions = [];
        if (item.Id > 0) {
            actions.push({
                id: ChartOfAccountsTemplateAction.EDIT,
                label: this.props.t("Common:General.Edit"),
                iconName: "Edit",
                itemType: ToolbarItemType.Icon
            }, {
                id: ChartOfAccountsTemplateAction.DELETE,
                label: this.props.t("Common:General.Remove"),
                iconName: "Bin",
                itemType: ToolbarItemType.Icon
            });
        } else {
            actions.push({
                id: ChartOfAccountsTemplateAction.VIEW,
                label: this.props.t("Common:General.View"),
                iconName: "Visible",
                itemType: ToolbarItemType.Icon
            });
        }

        actions.push({
            id: ChartOfAccountsTemplateAction.COPY,
            label: this.props.t("ChartsOfAccountsTemplates:CreateCopy"),
            itemType: this.context.getAddingNewCompany() ? ToolbarItemType.TransparentButton : ToolbarItemType.Button
        });

        const isActiveTemplate = this.isAddingNewCompany && this.state.companySettings?.InitialChartOfAccountsTemplateId === item.Id;

        if (this.isAddingNewCompany) {
            actions.push({
                id: ChartOfAccountsTemplateAction.REWRITE,
                isDisabled: isActiveTemplate,
                label: this.props.t("ChartsOfAccountsTemplates:UseTemplate"),
                itemType: ToolbarItemType.Button
            });
        }

        return {
            id: item.Id,
            name: item.Name,
            status: isActiveTemplate ? ListItemStatus.Active : undefined,
            iconName: "DocTemplate",
            isDefault: item.Id < 0,
            contents: [{
                content: (
                    <>
                        <ListItemContentRow label={this.props.t(`ChartsOfAccountsTemplates:DateCreated`)}>
                            {DateType.localFormat(item.DateCreated)}
                        </ListItemContentRow>
                        {item.CreatedBy &&
                            <ListItemContentRow label={this.props.t(`ChartsOfAccountsTemplates:CreatedBy`)}>
                                {item.CreatedBy.Name}
                            </ListItemContentRow>}
                        {!dayjs(item.DateLastModified).isSame(dayjs(item.DateCreated)) &&
                            <ListItemContentRow label={this.props.t(`ChartsOfAccountsTemplates:DateLastModified`)}>
                                {DateType.localFormat(item.DateLastModified, DateType.defaultDateTimeFormat)}
                            </ListItemContentRow>}
                    </>
                )
            }],
            actions
        };
    };

    getFilteredTemplates = (): IChartOfAccountsTemplateEntity[] => {
        return this.parents.filter((template: IChartOfAccountsTemplateEntity) => {
            return anyPartStartsWithAccentsInsensitive(template.Name, this.state.filter);
        });
    };

    getSections = (children: IEntity[]): ISection[] => {
        const defaultSection: ISection = {
                id: "default",
                children: []
            },
            customSection: ISection = {
                id: "custom",
                children: []
            };
        children.forEach((item) => {
            const section = item.Id < 0 ? defaultSection : customSection;
            section.children.push(this.createTemplateProperties(item));
        });

        return [defaultSection, customSection].filter(item => item.children.length);
    };

    handleFilterChange = (e: IInputOnChangeEvent) => {
        this.setState({
            filter: e.value as string
        });
    };

    handleAdd = async () => {
        const action = { type: ChartOfAccountsTemplateAction.ADD };
        this.setState({ action });
    };

    handleTemplateNameChange = async (template: IEntity, name: string) => {
        template.Name = name;
        if (this._shouldCloseOnSaveName) {
            this.handleCloseDialog();
            this._shouldCloseOnSaveName = false;
        }

        // when adding new company, we want to keep the name of selected CoA template in sync with the new company CoA name
        if (this.isAddingNewCompany) {
            //     await this.handleRewriteCOA(newTemplate.Id, false);
            const isActive = this.state.companySettings?.InitialChartOfAccountsTemplateId === template?.Id;

            if (isActive) {
                const context = this.context as IAppContext;
                const coa = context.getData()?.fiscalYears[0]?.ChartOfAccounts;

                if (coa) {
                    await this.props.oData.getEntitySetWrapper(EntitySetName.ChartsOfAccounts)
                        .update<IChartOfAccountsEntity>(coa.Id, {
                            Name: name
                        });
                    context.updateFiscalYears();
                }
            }
        }
    };

    handleTemplateNameChangeFailed = () => {
        this._shouldCloseOnSaveName = false;
    };

    handleAction = async (templateId: TEntityKey, type: string) => {
        const template = this.parents.find(item => item.Id === templateId);
        // set action to show busy indicator or other stuff
        const action = { templateId, type: (type as ChartOfAccountsTemplateAction) };
        this.setState({ action });

        switch (type) {
            case ChartOfAccountsTemplateAction.COPY:
                const newTemplate = await this.cloneTemplate(template);
                if (this.isAddingNewCompany) {
                    await this.handleRewriteCOA(newTemplate.Id, false);
                }

                this.showMessage(newTemplate?.Id, { status: Status.Success, key: "TemplateCreated" });
                this.setState({ action: null });
                break;
            case ChartOfAccountsTemplateAction.VIEW:
            case ChartOfAccountsTemplateAction.EDIT:
                this.handleChangeParent(template.Id);
                break;
            case ChartOfAccountsTemplateAction.REWRITE:
                await this.handleRewriteCOA(template.Id);
                this.setState({ action: null });
                break;
            case ChartOfAccountsTemplateAction.DELETE:
                // no special handling, just set the action to state
                break;
        }
    };

    handleRewriteCOA = async (templateId: TEntityKey, showAlert = true): Promise<void> => {
        const data = this.context.getData() as IAppContextData;
        const chartOfAccounts = data.fiscalYears[0].ChartOfAccounts;
        const template = this.getFilteredTemplates().find(template => template.Id === templateId);

        await rewriteChartOfAccountsWithTemplate(chartOfAccounts.Id.toString(), template.Name, templateId as string);
        await refreshFiscalYears(this.context);

        await this.props.oData.getEntitySetWrapper(EntitySetName.CompanySettings).update(this.state.companySettings.Id, {
            InitialChartOfAccountsTemplateId: template.Id
        });
        await this.loadCompanySettings();

        if (showAlert) {
            this.props.setAlert({
                status: Status.Success,
                title: this.props.t("Components:Table.UpdateOk"),
                subTitle: this.props.t("ChartsOfAccountsTemplates:TemplateWasUsed")
            });
        }
    };

    cloneTemplate = async (template: IEntity): Promise<TRecordAny> => {
        const newName = await getUniqName(this.props.oData, template.Name),
            newTemplate = await cloneChartOfAccountsTemplate(template.Id, newName);

        this.parents = [newTemplate, ...this.parents];
        return newTemplate;
    };

    handleDeleteCancel = () => {
        this.confirmAction(false);
    };

    confirmAction = (confirmed = true) => {
        if (this.state.action?.confirmed !== confirmed) {
            const action = confirmed ? { confirmed, ...this.state.action }
                : undefined;
            this.setState({ action });
        }
    };

    handleDeleteConfirm = async () => {
        const templateId = this.state.action.templateId;
        // close the dialog
        this.confirmAction();

        try {
            this.props.setBusy(true);
            const result = await this.props.oData.getEntitySetWrapper(EntitySetName.ChartOfAccountsTemplates)
                .delete(templateId);

            const templateIndx = this.parents.findIndex(t => t.Id === templateId);
            const template = this.parents[templateIndx];

            if (result) {
                if (this.isAddingNewCompany) {
                    const currentTemplate = this.parents.find(item => item.Id === this.state.action.templateId);
                    const isActive = this.state.companySettings?.InitialChartOfAccountsTemplateId === currentTemplate?.Id;
                    if (isActive) {
                        const defaultTemplate = this.parents.find(item => item.Id === -1);
                        await this.handleRewriteCOA(defaultTemplate.Id);
                    }
                }

                this.props.setAlert({
                    status: Status.Success,
                    title: this.props.t("Components:Table.UpdateOk"),
                    subTitle: this.props.t("ChartsOfAccountsTemplates:DeleteConfirm.Deleted", { name: template?.Name })
                });

                // todo: if we have "disabled" state for ObjectListItem, we may just show message here and
                //  remove the template in clearMessage callback
                if (templateIndx !== -1) {
                    this.parents.splice(templateIndx, 1);
                }
            }

        } catch (error) {
            const title = isODataError(error) ? error._message : `${error}`;

            this.showMessage(templateId, { status: Status.Error, title });
        }

        this.props.setBusy(false);
        this.setState({
            action: undefined
        });
    };

    clearMessage = () => {
        this.setState({ alertData: null });
    };

    showMessage = (templateId: TEntityKey, {
        status,
        title,
        key
    }: { status: Status, key?: string, title?: string }) => {
        if (key) {
            const name = this.parents.find(template => template.Id === templateId)?.Name;
            title = this.props.t(`ChartsOfAccountsTemplates:${key}`, { name });
        }
        const alert: IAlertProps = {
            status,
            title: status === Status.Success ? this.props.t("Common:Validation.SuccessTitle") : this.props.t("Common:General.Error"),
            subTitle: title,
            position: AlertPosition.CenteredBottom,
            useFade: true,
            onFadeEnd: () => {
                this.clearMessage();
            }
        };

        this.setState({
            alertData: {
                key,
                alert
            }
        });
    };

    /**
     * Editable window has been closed, confirm changes...
     */
    handleCloseDialog = async () => {
        if (this.templateContext.isChangingName) {
            // writeline with changed name is present, it's automatically confirmed on blur, which may be triggered
            // by hitting the editable window cross. If it's this case, we need to postpone closing the window, in case
            // any error happen
            this._shouldCloseOnSaveName = true;
        } else {
            if (this.templateContext.isChanged) {
                this.areParentsLoaded = false;
                await this.loadParents();
            }
            this.handleChangeParent();
        }
    };

    prepareMessage = () => {
        // show message for Edit, Add, Copy
        const type = this.state.action?.type;
        if (this.templateContext.isChanged && (!type || type === ChartOfAccountsTemplateAction.EDIT)) {
            this.showMessage(this.state.action?.templateId, { status: Status.Success, key: "TemplateChanged" });
        }
    };

    scrollToTemplate = (templateId: string) => {
        const item = document.querySelector(`[data-listitemid="${templateId}"]`) as HTMLElement;

        if (this._scrollRef.current && item && !isVisibleInContainer(this._scrollRef.current, item)) {
            scrollIntoView(this._scrollRef.current, item, { behavior: "smooth", block: "center" });

            item.focus({
                preventScroll: true
            });
        }
    };

    _showBusyIndicator = (): boolean => {
        return [ChartOfAccountsTemplateAction.COPY, ChartOfAccountsTemplateAction.REWRITE].includes(this.state.action?.type);
    };

    _showDeleteConfirm = () => {
        return this.state.action?.type === ChartOfAccountsTemplateAction.DELETE && !this.state.action?.confirmed;
    };

    isReady() {
        // templates should render even if there is no parent key (list is rendered)
        return this.props.tReady && !!this.definition && this.areParentsLoaded;
    }

    handleNewTemplate = async (newTemplateName: string): Promise<void> => {
        try {
            const newTemplate = await createMinimalChartOfAccountsTemplate(AccountingCode.AccountingForBusiness, newTemplateName);

            // reload all parents
            this.areParentsLoaded = false;
            await this.loadParents();

            if (this.isAddingNewCompany) {
                await this.handleRewriteCOA(newTemplate.Id, false);
            }

            this.handleCloseTemplateDialog();

            this.showMessage(newTemplate?.Id, { status: Status.Success, key: "TemplateCreated" });
        } catch (e) {
            return e;
        }
    };

    handleCloseTemplateDialog = (): void => {
        this.setState({
            action: undefined
        });
    };

    showAlert = () => {
        const alert = this.state.alertData?.alert;
        if (alert) {
            return <Alert {...alert} useFade/>;
        }

        return null;
    };

    render() {
        if (!this.isReady()) {
            return null;
        }

        const templates = !!this.state.filter ? this.getFilteredTemplates() : this.parents;
        const isFirstDefault = templates[templates.length - 1]?.Id < 0;

        let name;
        if (this.state.action?.templateId) {
            name = this.parents.find(template => template.Id === this.state.action.templateId)?.Name;
        }

        return (
            <>
                <BreadCrumbProvider back={this.initialHistoryState?.back}
                                    customBreadCrumbs={this.initialHistoryState?.breadCrumbs}/>
                <ChartOfAccountsTemplatesViewStyled hotspotContextId={"chartOfAccountsTemplates"}
                                                    nonScrollableContent={this._showBusyIndicator() && (
                                                        <BusyIndicator isDelayed/>
                                                    )}
                                                    isFirstDefault={isFirstDefault}
                                                    className={this.props.className}>
                    {this.props.alert}
                    <ViewHeader title={this.props.t("ChartsOfAccountsTemplates:Title")}
                                shouldHideVariant/>

                    <Toolbar>
                        <WriteLineWrapper>
                            <WriteLine value={this.state.filter}
                                       width="240px"
                                       placeholder={this.props.t("ChartsOfAccountsTemplates:Search")}
                                       textAlign={TextAlign.Left}
                                       onChange={this.handleFilterChange}/>
                        </WriteLineWrapper>
                        <IconButton title={this.props.t("Common:General.Add")}
                                    hotspotId={TableButtonsAction.Add}
                                    onClick={this.handleAdd}>
                            <AddIcon width={IconSize.M} isLight={true}/>
                        </IconButton>
                    </Toolbar>
                    <ScrollBar primary
                               scrollableNodeProps={{
                                   ref: this._scrollRef
                               }}
                               style={{ overflowX: "hidden", position: "relative" }}>
                        <ObjectList
                            listId={"Templates"}
                            busy={!this.areParentsLoaded}
                            sections={this.getSections(templates)}
                            onTriggerAction={this.handleAction}
                            noDataText={this.props.t(`ChartsOfAccountsTemplates:NoData${this.state.filter ? "Filtered" : ""}`)}/>
                        {this.showAlert()}
                    </ScrollBar>

                    {this.showDialog &&
                        <ChartOfAccountTemplateContext.Provider value={this.templateContext}>
                            <Dialog onClose={this.handleCloseDialog}
                                    onConfirm={null}
                                    isEditableWindow>
                                {/* Render split page in dialog */}
                                {super.render()}
                            </Dialog>
                        </ChartOfAccountTemplateContext.Provider>
                    }
                    {this.state.action?.type === ChartOfAccountsTemplateAction.ADD &&
                        <NewTemplateDialog
                            newTemplateName={""}
                            onSave={this.handleNewTemplate}
                            onClose={this.handleCloseTemplateDialog}
                            parent={this.getParentEntity()}
                        />
                    }
                    {this._showDeleteConfirm() &&
                        <Dialog title={this.props.t("ChartsOfAccountsTemplates:DeleteConfirm.Title")}
                                onClose={this.handleDeleteCancel}
                                onConfirm={this.handleDeleteConfirm}
                                footer={(
                                    <>
                                        <Button isTransparent
                                                onClick={this.handleDeleteCancel}>
                                            {this.props.t("Common:General.Cancel")}
                                        </Button>
                                        <Button onClick={this.handleDeleteConfirm}>
                                            {this.props.t("ChartsOfAccountsTemplates:DeleteConfirm.Delete")}
                                        </Button>
                                    </>
                                )}>
                            {this.props.t("ChartsOfAccountsTemplates:DeleteConfirm.Content", { name: name })}
                        </Dialog>
                    }
                </ChartOfAccountsTemplatesViewStyled>
            </>
        );
    }
}

export default withAlert({
    autoHide: true,
    position: AlertPosition.CenteredBottom
})(withRouter(withOData(withTranslation(["ChartsOfAccountsTemplates", "ChartsOfAccounts", "Error", "Common"])(withBusyIndicator()(ChartOfAccountsTemplates)))));
