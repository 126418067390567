import styled from "styled-components/macro";
import { getDisabledStyle, getFocusBorderElement, multiline_ellipsis, T_PLAIN_big } from "../../../global.style";
import { FieldConfirmButtons } from "../field/FieldConfirmButtons";
import { AuditTrailFieldType } from "../../smart/FieldInfo";
import { StyledTextArea, textAreaErrorStyles } from "../textArea/TextArea.styles";
import { StyledInput } from "../input/Input.styles";

export const ReadLabel = styled.div<{
    _hoverable: boolean;
}>`
    min-height: 14px;
    max-width: 600px;
    padding: 6px 12px 4px 12px;
    white-space: pre-line;
    border: 1px solid transparent;

    &:hover {
        border-color: ${props => props._hoverable ? props.theme.C_ACT_hover_without_opacity : ""};
    }
    
    ${textAreaErrorStyles};
`;

export const ReadLabelText = styled.pre<{
    _hasValue: boolean;
    auditTrailType?: AuditTrailFieldType;
    maxRows: number;
}>`
    font-style: ${props => props._hasValue ? "" : "italic"};            
    color: ${props => props._hasValue ? props.theme.C_TEXT_primary : props.theme.C_ACT_main};
    
    ${T_PLAIN_big};
    
    ${props => multiline_ellipsis("100%", props.maxRows)};
    overflow-wrap: break-word;

    background: transparent;
    ${props => (props.auditTrailType === AuditTrailFieldType.NoDifference || props.auditTrailType === AuditTrailFieldType.HoveredNoDifference) && `
    opacity: 0
`} ${props => (props.auditTrailType === AuditTrailFieldType.HoveredNoDifference) && `
    opacity: ${props.theme.C_AUDIT_TRAIL_DIFF_VISIBLE_OPACITY}
`}

`;

export const Wrapper = styled.div<{
    _width: string;
    _hoverable: boolean;
    isInEdit: boolean;
}>`
    position: relative;
    width: ${props => props.isInEdit && props._width ? props._width : "fit-content"};
    height: fit-content;
    max-width: 100%;
`;

export const TextAreaWrapper = styled.div`
    width: 100%;
    display: flex;

    ${StyledTextArea}, ${StyledInput} {
        display: block;
    }
`;

export const EditableTextConfirmButtons = styled(FieldConfirmButtons)`
    margin-left: 8px;
    margin-top: 5px;
`;

export const StyledEditableText = styled.div<{
    _maxWidth?: string;
    isInEdit: boolean;
    isDisabled: boolean;
    auditTrailType?: AuditTrailFieldType;
}>`
    word-break: break-all;
    display: flex;
    position: relative;
    min-height: 34px;
    background: ${props => props.auditTrailType === AuditTrailFieldType.NoDifference || props.auditTrailType === AuditTrailFieldType.HoveredNoDifference ?
        props.theme.C_AUDIT_TRAIL_NO_DIFF : ""};

        //max-width: ${props => props._maxWidth ? `${parseFloat(props._maxWidth) + (props.isInEdit ? 62 : 0)/*buttons*/}px` : ""};
    ${getFocusBorderElement({ offset: 0, keyboardOnly: true })};
    ${props => getDisabledStyle(props.isDisabled)};

    border: ${props => (props.auditTrailType === AuditTrailFieldType.Difference || props.auditTrailType === AuditTrailFieldType.HoveredDifference) ?
        `3px solid ${props.theme.C_AUDIT_TRAIL_DIFF}` : ""};

    border-radius: 3px;
`;