import { DashboardTileType, IDashboardDefinition, TTileDefinition } from "@components/dashboard";
import { ifAll, not } from "@components/smart/FieldInfo";
import { GeneralPermissionCode } from "@odata/GeneratedEnums";
import { isOnOrganizationLevel } from "@utils/CompanyUtils";
import i18next from "i18next";

import TaxCalendar from "../../components/tiles/taxCalendar";
import { Status } from "../../enums";
import { ROUTE_FISCAL_YEAR_CLOSE_OVERVIEW } from "../../routes";
import {
    getAgendaPerformanceOverviewTileDef,
    getAgendaWorkOverviewTileDef,
    getCustomerSupportTileDef,
    getDemoTenantTileDef,
    getEstimatedAccrualsDef,
    getFutureExpensesAndRevenuesDef,
    getFYcloseOverviewData,
    getInboxTileDef,
    getJournalEntriesLastMonthDef,
    getKeyboardShortcutsTileDef,
    getPostedDocumentsLastMonthDef,
    getPostedDocumentsLastMonthPerUserDef,
    getPurchaseTileDef,
    getTicketsInfoTileDef,
    getUsersAgendaData,
    getWelcomeTileDef,
    hasAnyGeneralPermission,
    isInCustomerPortal
} from "./Home.utils";

/**
 * Dashboard configuration for accountant, when switched to Organization level to get overview about all agendas
 */
export function getDashboardDefinition(): IDashboardDefinition {
    const tileDefinition: TTileDefinition = {
        Welcome: getWelcomeTileDef(),
        Purchase: getPurchaseTileDef(),
        ManagedAgendas: {
            type: DashboardTileType.Table,
            title: i18next.t("Home:ManagedAgendas.Heading"),
            tableData: async (args) => {
                return await getUsersAgendaData(args.oData);
            },
            size: { w: 2, h: 2 },
            isVisible: hasAnyGeneralPermission(GeneralPermissionCode.CompanyManagement)
        },
        FiscalYearCloseOverview: {
            type: DashboardTileType.Info,
            title: i18next.t("Home:FiscalYearCloses.Heading"),
            link: ROUTE_FISCAL_YEAR_CLOSE_OVERVIEW,
            infoData: async () => {
                const data = await getFYcloseOverviewData();
                let notStarted = 0;
                let pending = 0;
                let finished = 0;

                for (const fyc of [...(data?.values() ?? [])]) {
                    if (fyc.SectionDone === fyc.SectionCount) {
                        finished += 1;
                    } else if (fyc.SectionDone === 0) {
                        notStarted += 1;
                    } else {
                        pending += 1;
                    }
                }

                return [
                    {
                        value: notStarted,
                        label: i18next.t("Home:FiscalYearCloses.NotStartedLabel", { count: notStarted })
                    },
                    {
                        value: pending,
                        severity: Status.Warning,
                        label: i18next.t("Home:FiscalYearCloses.PendingLabel", { count: pending })
                    },
                    {
                        value: finished,
                        severity: Status.Success,
                        label: i18next.t("Home:FiscalYearCloses.FinishedLabel", { count: finished })
                    }
                ];
            },
            size: { w: 3, h: 1 }
        },
        TaxCalendar: {
            title: i18next.t("Home:TaxCalendar.Heading"),
            type: DashboardTileType.Custom,
            component: TaxCalendar,
            size: { w: 4, h: 4 }
        },
        // EvalaWorkingTime: {
        //     title: i18next.t("Home:EvalaWorkingTimeTile.Heading"),
        //     type: DashboardTileType.Table,
        //     isVisible: hasAnyGeneralPermission(
        //             GeneralPermissionCode.OrganizationManagement,
        //             GeneralPermissionCode.EmployeePerformance
        //     ),
        //     size: { w: 2, h: 2 }
        // },
        PostedDocumentsLastMonth: getPostedDocumentsLastMonthDef(),
        JournalEntriesLastMonth: getJournalEntriesLastMonthDef(),
        AgendaPerformanceOverview: getAgendaPerformanceOverviewTileDef(),
        PostedDocumentsLastMonthPerUser: getPostedDocumentsLastMonthPerUserDef(),
        KeyboardShortcuts: getKeyboardShortcutsTileDef(),
        AgendaWorkOverview: getAgendaWorkOverviewTileDef(true),
        FutureExpensesAndRevenues: getFutureExpensesAndRevenuesDef(),
        EstimatedAccruals: getEstimatedAccrualsDef(),
        Tickets: getTicketsInfoTileDef(),
        CustomerSupport: getCustomerSupportTileDef(),
        Inbox: getInboxTileDef(),
        DemoTenant: getDemoTenantTileDef()
    };

    const optionalTiles: (keyof TTileDefinition)[] = [
        "FutureExpensesAndRevenues",
        "EstimatedAccruals",
        "Tickets",
        "Inbox",
        "PostedDocumentsLastMonth",
        "JournalEntriesLastMonth"
    ];
    const ownerTiles = ["ManagedAgendas"];
    const defaultTiles = Object.keys(tileDefinition).filter(key => !optionalTiles.includes(key) && !ownerTiles.includes(key));

    return {
        title: i18next.t("Home:TenantDashboardTitle"),
        tileDefinition,
        isVisible: ifAll(isOnOrganizationLevel, not(isInCustomerPortal)),
        groups: [
            {
                id: "owner",
                title: i18next.t("Home:Groups.Owner"),
                tiles: ownerTiles,
                allTiles: ownerTiles
            }, {
                id: "agendaOverview",
                title: i18next.t("Home:Groups.AgendaOverview"),
                tiles: defaultTiles,
                allTiles: [...defaultTiles, ...optionalTiles]
            }
        ]
    };
}
