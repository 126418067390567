import React from "react";
import { ITableViewBaseProps } from "../views/table/TableView";
import { IFormDef, IProps as IFormProps } from "../views/formView/Form";
import BindingContext from "../odata/BindingContext";
import { IProps as ISplitPageProps, SplitPage } from "./SplitPage";
import { EmptyObject } from "../global.types";
import DialogPageBase, { IDialogPageBaseProps } from "./DialogPageBase";
import { ISplitPageTableDef } from "../views/table/TableView.utils";

export interface IProps extends IDialogPageBaseProps {
    tableView?: React.ComponentType<ITableViewBaseProps>;
    tableViewProps?: any;
    formProps?: Partial<IFormProps>;
}

export interface ISplitPagePaneData<Type = IFormDef | ISplitPageTableDef> {
    bc?: BindingContext;
    def?: Type;
    entitySet?: string;
}

export default class DialogPage<P extends IProps, S = EmptyObject> extends DialogPageBase<P, S, ISplitPageProps> {

    getMandatoryProps(): ISplitPageProps & { key: string } {
        const isEditable = this.detailData?.bc?.isNew();
        this.masterData.entitySet = this.getEntitySet();

        return {
            key: this.context.getCompany().Id,
            // need to duplicate wrapper object for correct prevProps in splitpage
            masterData: { ...this.masterData },
            detailData: { ...this.detailData },
            t: this.props.rootStorage.t,
            tableView: this.props.tableView,
            rootStorage: this.props.rootStorage,
            pageViewMode: isEditable ? undefined : this.props.pageViewMode,
            formProps: {
                customFooterButtons: isEditable ? undefined : this.props.customFooterButtons,
                hideBreadcrumbs: true,
                shouldHideAuditTrail: true,
                isSimple: true,
                ...this.props.formProps,
            },
            tableViewProps: this.props.tableViewProps,
            isInDialog: true,

            ...this.getMandatoryChildParentProps(),

            onRowSelect: this.handleRowSelect,
            onCloseDetail: this.handleCloseDetail,
            onAfterSave: this.handleAfterSave
        };
    }

    render() {
        return (
            <SplitPage
                {...this.getMandatoryProps()}
            />
        );
    }
}
