import {
    DefaultVariantEntity,
    EntitySetName,
    EntityTypeName,
    IVariantEntity,
    VariantEntity
} from "@odata/GeneratedEntityTypes";
import { AccountingCode, VariantAccessTypeCode, VatStatusCode } from "@odata/GeneratedEnums";
import { OData } from "@odata/OData";
import { ODataQueryResult } from "@odata/ODataParser";
import { getCompanyAccountingCode, isOnOrganizationLevel, isVatRegisteredCompany } from "@utils/CompanyUtils";
import { isNotDefined } from "@utils/general";

import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { Sort } from "../../enums";
import { TObject } from "../../global.types";
import BindingContext from "../../odata/BindingContext";
import { IGetValueArgs } from "../smart/FieldInfo";
import { IFormGroupDef } from "../smart/smartFormGroup/SmartFormGroup";

export enum VariantType {
    Form = "Form",
    Table = "Table"
}

export enum GroupType {
    Normal = "Normal",
    LineItem = "LineItem"
}

export enum RowType {
    Normal = "Normal",
    Collapsed = "Collapsed"
}

export interface IRow {
    id: string;
}

export interface IVariantColumn {
    id: string;
    sortOrder?: number;
    sortType?: Sort;
    group?: string;
    aggregationFunction?: string;
}

export interface ITableVariant {
    columns: IVariantColumn[];
    filters: TObject;
    // company dependent filters
    // first key is company id,
    // then, inside of it, is the same object as in "filters"
    companyFilters?: Record<string, ITableVariant["filters"]>;
    visibleFilters?: string[];
}

export type IVariant = IFormVariant | ITableVariant;
export type IFormVariant = IFormGroupDef[];

/** Interfaces for the JSON string stored in Variant.VariantData */
interface IVariantDataStringRow {
    RowGroup: number;
    UiId: string;
    RowType: RowType;
}

interface IVariantDataStringColumn {
    UiId: string;
    SortOrder?: number;
    SortType?: "DSC" | "ASC";
    Group?: string;
    AggregationFunction?: string;

}

interface IVariantDataStringFormGroup {
    Title: string;
    UiId: string;
    GroupType?: GroupType;
    Collection?: string;
    Rows?: IVariantDataStringRow[];
    UiOrder?: string;

}

export interface IVariantDataStringObject {
    Columns?: IVariantDataStringColumn[];
    Filters?: TObject;
    CompanyFilters?: Record<string, IVariantDataStringObject["Filters"]>;
    VisibleFilters?: string[];
    FormGroups?: IVariantDataStringFormGroup[];
}


// special system "row" that is not converted into field on FE
// instead used as a flag
export const SHOW_ITEMS_SUMMARY_VARIANT_ROW_ID = "$showItemsSummary";

export interface IVariantParams {
    name: string;
    accessType: VariantAccessTypeCode;
    accountingCode: AccountingCode;
    vatStatusCode: VatStatusCode;
    viewId: string;
    entityType: EntityTypeName;
    variantType: VariantType;
    formGroups?: IFormGroupDef[];
    table?: ITableVariant;

    id?: string;
}

const rowsToBody = (rows: IRow[][], rowType: RowType) => {
    if (isNotDefined(rows)) {
        return [];
    }
    const result: any[] = [];

    for (let groupIndex = 0; groupIndex < rows.length; groupIndex++) {
        const group = rows[groupIndex];
        for (let elementIndex = 0; elementIndex < group.length; elementIndex++) {
            const element = group[elementIndex];
            result.push({
                "RowGroup": groupIndex,
                "UiId": element.id,
                "RowType": rowType
            });
        }
    }

    return result;
};

export class Variant {
    public name: string;
    public accessType: VariantAccessTypeCode;
    public viewId: string;
    public entityType: EntityTypeName;
    public accountingCode: AccountingCode;
    public vatStatusCode: VatStatusCode;
    public variantType: VariantType;
    public formGroups?: IFormGroupDef[];
    public table?: ITableVariant;

    public id?: string;

    constructor(params: IVariantParams) {
        this.name = params.accessType === VariantAccessTypeCode.UserVariant ? params.name?.toLowerCase() : params.name?.toUpperCase();
        this.accessType = params.accessType;
        this.viewId = params.viewId;
        this.variantType = params.variantType;
        this.formGroups = params.formGroups;
        this.table = params.table;
        this.entityType = params.entityType;
        this.accountingCode = params.accountingCode;
        this.vatStatusCode = params.vatStatusCode;

        this.id = params.id;
    }

    private getODataBody(): IVariantEntity {
        const commonProps = {
            [VariantEntity.AccessTypeCode]: this.accessType,
            [VariantEntity.Name]: this.name,
            [VariantEntity.VariantTypeCode]: this.variantType,
            [VariantEntity.ViewId]: this.viewId,
            [VariantEntity.EntityTypeCode]: this.entityType,
            [VariantEntity.AccountingCode]: this.accountingCode,
            [VariantEntity.VatStatusCode]: this.vatStatusCode
        };
        if (this.variantType === VariantType.Form) {
            return {
                ...commonProps,
                "VariantData": JSON.stringify({
                    "FormGroups": this.formGroups.map((group) => {
                        const result: IVariantDataStringFormGroup = {
                            "Title": group.title as string, // todo: getInfoValue
                            "UiId": group.id
                        };

                        if (group.lineItems) {
                            result.GroupType = GroupType.LineItem;
                            result.Collection = group.lineItems.collection;
                            result.UiOrder = group.lineItems.order;
                            result.Rows = group.lineItems.columns.map((e) => ({
                                "RowGroup": 0,
                                "UiId": e.id,
                                "RowType": RowType.Normal
                            }));

                            if (group.lineItems.shouldShowItemsSummary) {
                                result.Rows.push({
                                    "RowGroup": 0,
                                    "UiId": SHOW_ITEMS_SUMMARY_VARIANT_ROW_ID,
                                    "RowType": RowType.Normal
                                });
                            }

                        } else {
                            result.GroupType = GroupType.Normal;
                            // for special groups (used in FiscalYears) that have collection
                            result.Collection = group.collection;
                            result.Rows = [...rowsToBody(group.rows, RowType.Normal)];
                        }

                        return result;
                    })
                } as IVariantDataStringObject)
            };
        }
        return {
            ...commonProps,
            "VariantData": JSON.stringify({
                "Columns": this.table.columns.map((column) => ({
                    "UiId": column.id,
                    "SortOrder": column.sortOrder,
                    // backend use different enum
                    "SortType": !column.sortType ? null : column.sortType === Sort.Desc ? "DSC" : "ASC",
                    "Group": column.group,
                    "AggregationFunction": column.aggregationFunction
                    // todo WasSaveFromDraft?
                } as IVariantDataStringObject)),
                Filters: this.table.filters,
                CompanyFilters: this.table.companyFilters,
                VisibleFilters: this.table.visibleFilters
            })
        };
    }

    public setVariant = (variant: IVariant): void => {
        this.variantType === VariantType.Table ? this.table = variant as ITableVariant : this.formGroups = variant as IFormVariant;
    };

    private async saveNew(odata: OData) {
        const result = await odata.getEntitySetWrapper(EntitySetName.Variants).create(this.getODataBody()) as ODataQueryResult;
        this.id = result.value.Id?.toString();
    }

    private async saveExisting(odata: OData) {
        const body = this.getODataBody();

        await odata.getEntitySetWrapper(EntitySetName.Variants).update(this.id, body);
    }

    async save(odata: OData): Promise<void> {
        if (isNotDefined(this.id)) {
            await this.saveNew(odata);
        } else {
            await this.saveExisting(odata);
        }
    }

    async saveAsDefault(odata: OData, opts: ICompanyVariantContext, accessType: VariantAccessTypeCode = VariantAccessTypeCode.UserVariant): Promise<void> {
        if (isNotDefined(this.id)) {
            await this.saveNew(odata);
        }

        const filter = getVariantFilter({
            viewId: this.viewId,
            variantType: this.variantType,
            accounting: this.accountingCode ?? opts.accounting,
            vatStatus: this.vatStatusCode ?? opts.vatStatus
        }, false);

        const result = await odata.getEntitySetWrapper(EntitySetName.DefaultVariants).query()
            .filter(`${filter} and AccessTypeCode eq '${accessType}'`)
            .select("Id")
            .fetchData<IVariantEntity[]>();
        const defaultVariantId = result.value[0]?.Id;

        if (isNotDefined(defaultVariantId)) {
            await odata.getEntitySetWrapper(EntitySetName.DefaultVariants).create(
                {
                    "AccessTypeCode": accessType,
                    "VariantTypeCode": this.variantType,
                    "ViewId": this.viewId,
                    [DefaultVariantEntity.AccountingCode]: this.accountingCode,
                    [DefaultVariantEntity.VatStatusCode]: this.vatStatusCode,
                    "Variant": {
                        "@odata.id": `Variants(${this.id})`
                    }
                }
            );
        } else {
            await odata.getEntitySetWrapper(EntitySetName.DefaultVariants).update(defaultVariantId, {
                "Variant": {
                    "@odata.id": `Variants(${this.id})`
                }
            });
        }
    }

    async delete(odata: OData): Promise<void> {
        if (isNotDefined(this.id)) {
            return;
        }

        const result = await odata.getEntitySetWrapper(EntitySetName.DefaultVariants).query()
            .filter(`Variant/Id eq ${this.id}`)
            .select("Id")
            .fetchData<IVariantEntity[]>();
        const defaultVariants = result.value;

        const batch = odata.batch();
        batch.beginAtomicityGroup("delete");

        for (const dv of defaultVariants) {
            batch.getEntitySetWrapper(EntitySetName.DefaultVariants).delete(dv.Id);
        }
        batch.getEntitySetWrapper(EntitySetName.Variants).delete(this.id);

        await batch.execute();
        this.id = undefined;
    }
}

export interface IVariants {
    defaultVariant?: Variant;
    currentVariant?: Variant;
    allVariants?: Record<string, Variant>;
    // when working with contexts, there can be multiple default variants for different contexts
    // we need to obtain all of them to be able to manage them in variant dialog
    defaultVariants?: string[];
}

interface IDefaultVariant {
    Id: number;
    AccessTypeCode: VariantAccessTypeCode;
    Variant: {
        Id: number;
    };
}

const defaultVariantOrder = Object.fromEntries([[VariantAccessTypeCode.UserVariant, 0], [VariantAccessTypeCode.GlobalVariant, 1], [VariantAccessTypeCode.SystemVariant, 2]]);

const defaultVariantSorter = (a: IDefaultVariant, b: IDefaultVariant): number => {
    return defaultVariantOrder[a.AccessTypeCode] - defaultVariantOrder[b.AccessTypeCode];
};

const parseFormRows = (rowData: any[], rowType: RowType): IRow[][] => {
    const elements = rowData.filter((d: any) => d.RowType === rowType);
    const result: IRow[][] = [];

    for (const element of elements) {
        while (result.length <= element.RowGroup) {
            result.push([]);
        }
        result[element.RowGroup].push({
            id: element.UiId
        });
    }

    return result;
};

const parseFormGroup = (groupData: any): IFormGroupDef => {
    if (groupData.GroupType === GroupType.Normal) {
        return {
            title: groupData.Title,
            id: groupData.UiId,
            rows: parseFormRows(groupData.Rows, RowType.Normal),
            collection: groupData.Collection
        };
    } else if (groupData.GroupType === GroupType.LineItem) {
        const showSummaryItem = groupData.Rows?.find((row: {
            UiId: string;
        }) => row.UiId === SHOW_ITEMS_SUMMARY_VARIANT_ROW_ID);

        return {
            title: groupData.Title,
            id: groupData.UiId,
            lineItems: {
                collection: groupData.Collection,
                order: groupData.UiOrder,
                columns: groupData.Rows?.filter((r: {
                    UiId: string;
                }) => r.UiId !== SHOW_ITEMS_SUMMARY_VARIANT_ROW_ID).map((r: any) => ({
                    id: r.UiId
                })),
                shouldShowItemsSummary: !!showSummaryItem
            }
        };
    } else {
        throw new Error("Unknow group type");
    }
};

export const parseVariant = (variantData: IVariantEntity): Variant => {
    const params: IVariantParams = {
        name: variantData.Name,
        accessType: variantData.AccessTypeCode as VariantAccessTypeCode,
        viewId: variantData.ViewId,
        variantType: variantData.VariantTypeCode as VariantType,
        entityType: variantData.EntityTypeCode as EntityTypeName,
        vatStatusCode: variantData.VatStatusCode as VatStatusCode,
        accountingCode: variantData.AccountingCode as AccountingCode,
        id: variantData.Id.toString()
    };

    const variantJson = JSON.parse(variantData.VariantData) as IVariantDataStringObject;

    if (params.variantType === VariantType.Form) {
        params.formGroups = variantJson?.FormGroups?.map(parseFormGroup);
    } else if (params.variantType === VariantType.Table) {
        params.table = {
            columns: variantJson?.Columns?.map((c: IVariantDataStringColumn): IVariantColumn => ({
                id: c.UiId,
                sortOrder: c.SortOrder,
                // backend use different enum
                sortType: !c.SortType ? null : c.SortType === "DSC" ? Sort.Desc : Sort.Asc,
                aggregationFunction: c.AggregationFunction,
                group: c.Group
            })),
            filters: variantJson?.Filters,
            companyFilters: variantJson?.CompanyFilters,
            visibleFilters: variantJson?.VisibleFilters
        };
    }

    return new Variant(params);
};

const checkNavigation = (bindingContext: BindingContext, path: string, messageBuilder: () => string) => {
    try {
        bindingContext.navigate(path);
    } catch (_) {
        throw new Error(messageBuilder());
    }
};

const validateFormGroup = (formGroups: IFormGroupDef[], bindingContext: BindingContext) => {
    for (const formGroup of formGroups) {
        if (formGroup.rows) {
            for (const row of formGroup.rows) {
                for (const fieldDef of row) {
                    let path = fieldDef.id;

                    if (formGroup.collection) {
                        path = `${formGroup.collection}/${path}`;
                    }

                    checkNavigation(bindingContext, path, () => `Field with id: ${fieldDef.id} is missing in metadata`);
                }
            }
        }
        if (formGroup.lineItems?.columns) {
            for (const fieldDef of formGroup.lineItems.columns) {
                checkNavigation(bindingContext.navigate(formGroup.lineItems.collection), fieldDef.id, () => `Line item field with id: ${fieldDef.id} is missing in metadata`);
            }
        }
        if (formGroup.lineItems?.order) {
            checkNavigation(bindingContext.navigate(formGroup.lineItems.collection), formGroup.lineItems.order, () => `Order field of line items: ${formGroup.lineItems.order} is missing in metadata`);
        }
    }
};

const validateTable = (table: ITableVariant, bindingContext: BindingContext) => {
    for (const column of table.columns) {
        checkNavigation(bindingContext, column.id, () => `Column with id: ${column.id} is missing in metadata.`);
    }
};

const validateVariants = (variants: IVariants, bindingContext: BindingContext) => {
    for (const [key, variant] of Object.entries(variants.allVariants)) {
        try {
            if (variant.table?.columns) {
                validateTable(variant.table, bindingContext);
            }
            if (variant.formGroups?.length > 0) {
                validateFormGroup(variant.formGroups, bindingContext);
            }
        } catch (ex) {
            throw new Error(`Variant id ${key} is corrupted.\n${ex.message}`);
        }
    }
};

// todo think of a better way to handle this
const nonEvalaDefaultSystemVariantIds = ["-90"];

export const getDefaultSystemVariant = (variants: Variant[]) => {
    const isSystemVariant = (v: Variant) => v.accessType === VariantAccessTypeCode.SystemVariant;
    const evalaVariant = variants.find(v => isSystemVariant(v) && (v.name === "EVALA" || nonEvalaDefaultSystemVariantIds.includes(v.id)));

    return evalaVariant ?? variants.find(isSystemVariant);
};

export interface ICompanyVariantContext {
    accounting?: AccountingCode;
    vatStatus?: VatStatusCode;
}

export function getCompanyVariantContext(context: IAppContext): ICompanyVariantContext {
    if (isOnOrganizationLevel({ context })) {
        return {};
    }
    const accounting = getCompanyAccountingCode(context);
    const vatStatus = isVatRegisteredCompany(context) ? VatStatusCode.VATRegistered : VatStatusCode.NotVATRegistered;
    return {
        accounting, vatStatus
    };
}

export function isInVatPayerVariantContext(args: IGetValueArgs): boolean {
    const { vatStatus } = getCompanyVariantContext(args.context);
    return vatStatus === VatStatusCode.VATRegistered;
}

export interface IVariantContext extends Partial<ICompanyVariantContext> {
    viewId: string;
    variantType: VariantType;
}

function getVariantFilter(opts: IVariantContext, allowNull = true): string {
    const { variantType, viewId, accounting, vatStatus } = opts;
    const variantFilters = [
        `${VariantEntity.VariantTypeCode} eq '${variantType}'`,
        `${VariantEntity.ViewId} eq '${viewId}'`
    ];
    if (accounting) {
        variantFilters.push(`(${VariantEntity.AccountingCode} eq '${accounting}'${allowNull ? ` or ${VariantEntity.Accounting} eq null` : ""})`);
    }
    if (vatStatus) {
        variantFilters.push(`(${VariantEntity.VatStatusCode} eq '${vatStatus}'${allowNull ? ` or ${VariantEntity.VatStatus} eq null` : ""})`);
    }
    return `${variantFilters.join(" and ")}`;
}

export const loadVariants = async (odata: OData, opts: IVariantContext, bindingContext: BindingContext): Promise<IVariants> => {
    const variants: IVariants = {};
    const variantFilter = getVariantFilter(opts);
    const defaultVariantFilter = getVariantFilter(opts, false);

    const batch = odata.batch();

    batch.getEntitySetWrapper(EntitySetName.Variants).query()
        .filter(variantFilter)
        .select(VariantEntity.Id, VariantEntity.Name, VariantEntity.VariantTypeCode, VariantEntity.AccessTypeCode,
            VariantEntity.ViewId, VariantEntity.VariantData, VariantEntity.AccountingCode, VariantEntity.VatStatusCode);

    batch.getEntitySetWrapper(EntitySetName.DefaultVariants).query()
        .filter(defaultVariantFilter)
        .select("Id", "AccessTypeCode")
        .expand("Variant", e => e.select("Id"));

    const results = await batch.execute();

    variants.allVariants = Object.fromEntries(((results[0].body as ODataQueryResult).value ?? []).map(parseVariant).map((v: Variant) => [v.id, v]));

    const defaultVariants = (results[1].body as ODataQueryResult).value as IDefaultVariant[];
    defaultVariants.sort(defaultVariantSorter);

    if (defaultVariants.length === 0) {
        const variantValues = Object.values(variants.allVariants);
        variants.defaultVariant = getDefaultSystemVariant(variantValues);
    } else {
        variants.defaultVariant = variants.allVariants[defaultVariants[0].Variant.Id];
    }

    if (isNotDefined(opts.vatStatus) && isNotDefined(opts.accounting)) {
        // we don't have vatStatus and accounting context in the props -> there are mixed variants from different
        // contexts (used in edit dialog), return all the default variants
        variants.defaultVariants = defaultVariants.map(dv => dv.Variant.Id.toString());
    }

    validateVariants(variants, bindingContext);

    return variants;
};

export const updateNames = async (odata: OData, variants: Variant[]): Promise<void> => {
    const batch = odata.batch();
    batch.beginAtomicityGroup("names");
    for (const variant of variants) {
        batch.getEntitySetWrapper(EntitySetName.Variants).update(variant.id, {
            "Name": variant.name
        });
    }
    await batch.execute();
};
