import { IDefinition, IGetDefinition } from "@pages/PageUtils";
import { IFormDef } from "../../../../views/formView/Form";
import { setDefForTesting } from "@pages/getDefByEntityType";
import React from "react";
import {
    BillingAddressEntity,
    BillingContactEntity,
    EntitySetName,
    ICompanyEntity,
    ISubscriptionEntity,
    TenantEntity
} from "@odata/GeneratedEntityTypes";
import { IAppContext } from "../../../../contexts/appContext/AppContext.types";
import { getAddressFields, SingleBusinessPartnerDef } from "@components/smart/GeneralFieldDefinition";
import { BasicInputSizes, FieldType, GroupedField, Status, TextAlign, ValidatorType } from "../../../../enums";
import { SwitchType } from "@components/inputs/switch/Switch";
import { IFieldDefFn, ISwitchSettings } from "@components/smart/FieldInfo";
import i18next from "i18next";
import Field from "../../../../components/inputs/field";
import { createPath } from "@odata/BindingContext";
import { isDefined, isNotDefined } from "@utils/general";
import {
    CardAction,
    CardLimitPath,
    IInvoicingExtendedEntity,
    IInvoicingFormCustomData,
    LimitDialogButtonPath,
    PaymentInfoPath
} from "./Invoicing.utils";
import Text from "../../../../components/text";
import { FormAlertPosition, FormStorage } from "../../../../views/formView/FormStorage";
import { expirationFormatter, REMOVE_CARD_URL } from "../Subscriptions.utils";
import FieldsWrapper from "../../../../components/inputs/field/FieldsWrapper";
import { Button } from "../../../../components/button";
import { CloseIcon, EditSimpleIcon } from "@components/icon";
import { IInputOnChangeEvent } from "@components/inputs/input";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import CurrencyType from "../../../../types/Currency";
import { CurrencyCode, PaymentMethodCode } from "@odata/GeneratedEnums";
import customFetch, { getDefaultPostParams } from "../../../../utils/customFetch";

const businessPartnerDependentFields = [
    {
        from: { id: "LegalNumber" },
        to: { id: "LegalNumber" }
    },
    {
        from: { id: "Name" },
        to: { id: "BillingAddress/Name" }
    },
    {
        from: { id: "TaxNumber" },
        to: { id: "TaxNumber" }
    },
    {
        from: { id: "BillingAddress/Street" },
        to: { id: "BillingAddress/Street" }
    },
    {
        from: { id: "BillingAddress/City" },
        to: { id: "BillingAddress/City" }
    },
    {
        from: { id: "BillingAddress/Country" },
        to: { id: "BillingAddress/Country" }
    },
    {
        from: { id: "BillingAddress/PostalCode" },
        to: { id: "BillingAddress/PostalCode" }
    }
];

const businessPartnerDef = {
    ...SingleBusinessPartnerDef,
    fieldSettings: {
        ...SingleBusinessPartnerDef.fieldSettings,
        localDependentFields: businessPartnerDependentFields
    }
};

export const getDefinition: IGetDefinition = (context: IAppContext): IDefinition => {
    const entitySet = EntitySetName.Tenants;
    const addressFields = getAddressFields("BillingAddress");

    const form: IFormDef = {
        id: "InvoicingForm",
        translationFiles: getDefinition.translationFiles,
        summary: [],
        fieldDefinition: {
            [createPath(TenantEntity.BillingAddress, BillingAddressEntity.Name)]: {
                ...businessPartnerDef,
                groupedField: GroupedField.MultiStart
            },
            [TenantEntity.LegalNumber]: {
                ...businessPartnerDef,
                groupedField: GroupedField.MultiEnd
            },
            [TenantEntity.TaxNumber]: {},
            ...addressFields,
            [TenantEntity.SendInvoice]: {
                type: FieldType.Switch,
                affectedFields: [{ id: TenantEntity.SendInvoiceCompanyId }],
                fieldSettings: {
                    type: SwitchType.YesNo
                } as ISwitchSettings,
                isVisible: ({ storage }) => {
                    return false; //https://solitea-cz.atlassian.net/browse/DEV-27375
                    // return !!storage.context.getData().companies?.length;
                },
                customFieldStyle: {
                    // random margin to "not make it jump"
                    marginTop: "20px"
                }
            },
            [TenantEntity.SendInvoiceCompanyId]: {
                type: FieldType.ComboBox,
                label: i18next.t("Subscriptions:Invoicing.Company"),
                width: BasicInputSizes.XL,
                isVisible: false, //https://solitea-cz.atlassian.net/browse/DEV-27375
                // use custom render to insert logo in contentBefore
                render: (args: IFieldDefFn) => {
                    const tenant = args.storage.data.entity as IInvoicingExtendedEntity;
                    const sendInvoice = tenant.SendInvoice;
                    const sendInvoiceTo = tenant.SendInvoiceCompanyId;

                    if (!sendInvoice || isNotDefined(sendInvoiceTo)) {
                        return null;
                    }

                    const companies = context.getData().companies as ICompanyEntity[];
                    const selectedCompany = companies.find(company => company.Id === sendInvoiceTo);

                    return (
                        <Field name={args.props.name}
                               label={args.props.info.label}
                               isReadOnly>
                            <Text>
                                {selectedCompany.Name}
                            </Text>
                        </Field>
                    );
                }
            },
            [createPath(TenantEntity.BillingContacts, BillingContactEntity.Email)]: {
                width: BasicInputSizes.L,
                validator: {
                    type: ValidatorType.Email
                }
            },
            [createPath(TenantEntity.BillingContacts, BillingContactEntity.FirstName)]: {},
            [createPath(TenantEntity.BillingContacts, BillingContactEntity.LastName)]: {},
            [PaymentInfoPath]: {
                render: (args: IFieldDefFn) => {
                    const storage = args.storage as FormStorage<IInvoicingExtendedEntity, IInvoicingFormCustomData>;

                    const subscription: ISubscriptionEntity = storage.getCustomData()?.subscription;
                    if (subscription?.PaymentMethodCode === PaymentMethodCode.WireTransfer) {
                        return <FieldsWrapper>
                            <Field label={i18next.t("Subscriptions:Invoicing.WireInfoTitle")}
                                   isReadOnly>
                                <Text>
                                    {i18next.t("Subscriptions:Invoicing.WireInfoText")}
                                </Text>
                            </Field>
                        </FieldsWrapper>;
                    }

                    const paymentState = storage.getCustomData().paymentState;
                    const handleCardButtonClick = (cardAction: CardAction) => {
                        const onInputChange = args.props.onChange as ((data: IInputOnChangeEvent) => void);

                        // cool hack?
                        // propagate the button click via input change event into InvoicingFormView,
                        // so that it can be handled there which is better place than here in definition
                        onInputChange({
                            value: cardAction
                        });
                    };
                    const handleCardRemove = async () => {
                        storage.setBusy(true);

                        const res = await customFetch(`${REMOVE_CARD_URL}`, {
                            ...getDefaultPostParams()
                        });

                        if (res.ok) {
                            storage.setFormAlert({
                                status: Status.Success,
                                title: storage.t("Common:General.Ok"),
                                subTitle: storage.t("Subscriptions:Invoicing.CardRemoved"),
                                isFullWidth: true,
                                useFade: true,
                                position: FormAlertPosition.Top
                            });
                            storage.setCustomData({
                                paymentState: null
                            });
                        } else {
                            storage.setFormAlert({
                                status: Status.Error,
                                title: storage.t("Common:Errors.ErrorHappened"),
                                subTitle: res.statusText,
                                isFullWidth: true,
                                position: FormAlertPosition.Top
                            });
                        }

                        storage.setBusy(false);
                    };
                    const handleLimitButtonClick = async () => {
                        const e: ISmartFieldChange = {
                            bindingContext: args.storage.data.bindingContext.navigate(LimitDialogButtonPath),
                            value: true
                        };
                        args.events.onChange(e);
                    };

                    if (!paymentState?.CreditCard) {
                        return (
                            <Button
                                onClick={() => handleCardButtonClick(CardAction.Add)}
                                icon={<EditSimpleIcon/>}>
                                {i18next.t("Subscriptions:Invoicing.AddCard")}
                            </Button>
                        );
                    }

                    const cardLimit = args.storage.getValueByPath(CardLimitPath);

                    return (
                        <FieldsWrapper>
                            <Field label={i18next.t("Subscriptions:Invoicing.PaymentCardNumber")}
                                   isReadOnly>
                                <Text>
                                    {paymentState.CreditCard.Number}
                                </Text>
                            </Field>
                            <Field label={i18next.t("Subscriptions:Invoicing.Expiration")}
                                   isReadOnly>
                                <Text>
                                    {expirationFormatter(paymentState.CreditCard.Expiration)}
                                </Text>
                            </Field>
                            <Field label={i18next.t("Subscriptions:Invoicing.LimitFormLabel")}
                                   textAlign={isDefined(cardLimit) ? TextAlign.Right : TextAlign.Left}
                                   isReadOnly>
                                <Text textAlign={isDefined(cardLimit) ? TextAlign.Right : TextAlign.Left}>
                                    {CurrencyType.format(isDefined(cardLimit)
                                        ? CurrencyType.format(cardLimit, {
                                            currency: CurrencyCode.CzechKoruna,
                                            maximumFractionDigits: 0
                                        }) : i18next.t("Subscriptions:Invoicing.LimitNotSet").toString(), {})}
                                </Text>
                            </Field>
                            <Button
                                onClick={() => handleCardButtonClick(CardAction.Change)}
                                icon={<EditSimpleIcon/>}
                                isTransparent>
                                {i18next.t("Subscriptions:Invoicing.ChangeCard")}
                            </Button>
                            <Button
                                onClick={() => handleCardRemove()}
                                icon={<CloseIcon/>}
                                isTransparent>
                                {i18next.t("Subscriptions:Invoicing.RemoveCard")}
                            </Button>
                            <Button
                                onClick={() => handleLimitButtonClick()}
                                isTransparent>
                                {i18next.t("Subscriptions:Invoicing.SetLimit")}
                            </Button>
                        </FieldsWrapper>
                    );
                }
            }
        },
        groups: [
            {
                id: "address",
                rows: [
                    [
                        { id: createPath(TenantEntity.BillingAddress, BillingAddressEntity.Name) },
                        { id: TenantEntity.LegalNumber },
                        { id: TenantEntity.TaxNumber }
                    ],
                    [
                        { id: createPath(TenantEntity.BillingAddress, BillingAddressEntity.Street) },
                        { id: createPath(TenantEntity.BillingAddress, BillingAddressEntity.City) },
                        { id: createPath(TenantEntity.BillingAddress, BillingAddressEntity.PostalCode) },
                        { id: createPath(TenantEntity.BillingAddress, BillingAddressEntity.Country) }
                    ],
                    [{ id: TenantEntity.SendInvoice }, { id: TenantEntity.SendInvoiceCompanyId }]
                ]
            },
            {
                id: "paymentInfo",
                title: i18next.t("Subscriptions:Invoicing.PaymentInformation"),
                tooltip: (storage) => {
                    const typedStorage = storage as FormStorage<IInvoicingExtendedEntity, IInvoicingFormCustomData>;
                    const subscription: ISubscriptionEntity = typedStorage.getCustomData()?.subscription;
                    if (subscription?.PaymentMethodCode === PaymentMethodCode.Card) {
                        return i18next.t("Subscriptions:Invoicing.PaymentInformationTooltip");
                    }
                    return null;
                },
                rows: [[{ id: PaymentInfoPath }]]
            },
            {
                id: "BillingContacts",
                title: i18next.t("Subscriptions:Invoicing.InvoiceRecipients"),
                tooltip: i18next.t("Subscriptions:Invoicing.BillingContactsInfo"),
                lineItems: {
                    collection: TenantEntity.BillingContacts,
                    order: "Order",
                    canReorder: false,
                    isGroupToggleable: true,
                    isItemCloneable: false,
                    columns: [
                        { id: BillingContactEntity.FirstName },
                        { id: BillingContactEntity.LastName },
                        { id: BillingContactEntity.Email }
                    ]
                }
            }
        ]
    };

    return {
        entitySet,
        form
    };
};

getDefinition.translationFiles = ["Subscriptions", "Common"];
setDefForTesting("InvoicingDef", getDefinition);