import { IRow, IRowValues, ISort } from "@components/table";
import { IToString, TValue } from "../../../global.types";
import { ICompanyEntity } from "@odata/GeneratedEntityTypes";
import { sortCompareFn } from "@utils/general";

export interface ILinkedPagesState {
    sort: ISort[];
    companies?: ICompanyEntity[];
}

export interface ILinkedPagesSortArgs {
    companyColumnId: string;
    companies: ICompanyEntity[];
    sort: ISort[];
    getValueForSort: (company: ICompanyEntity, column: IToString) => TValue;
    getFormattedValues: (companyId: number) => IRowValues;
}

export const getSortedRows = (args: ILinkedPagesSortArgs): IRow[] => {
    const {companyColumnId, companies, sort, getFormattedValues, getValueForSort} = args;
    const sortBy = sort[0];

    const key = sortBy.id;

    const _getValueForSort = (company: ICompanyEntity): TValue => {
        if (companyColumnId === key) {
            return company.Name;
        }
        return getValueForSort(company, key);
    };

    const sortedCompanies = [...companies].sort((compA, compB) => {
        const valueA = _getValueForSort(compA);
        const valueB = _getValueForSort(compB);
        return sortCompareFn(valueA, valueB, sortBy.sort);
    });

    const rows: IRow[] = sortedCompanies.map(company => ({
        id: company.Id,
        values: {
            company: company.Name,
            ...getFormattedValues(company.Id)
        }
    }));

    return rows;
};
