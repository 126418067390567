import React from "react";
import {
    CaretIconLeft,
    CaretIconRight,
    IconWrapperLeft,
    IconWrapperRight,
    Line,
    Year,
    YearSwitcherStyled
} from "./YearSwitcher.styles";
import { IconSize } from "../../enums";
import { WithTranslation, withTranslation } from "react-i18next";

interface IProps extends WithTranslation {
    year: number;
    onYearMove: (newYear: number) => void;
}

class YearSwitcher extends React.Component<IProps> {
    move = (offset: number) => {
        this.props.onYearMove(this.props.year + offset);
    };

    render() {
        return (
            <YearSwitcherStyled>
                <IconWrapperLeft
                    isTransparent isDecorative
                    title={this.props.t("ElectronicSubmission:LastYear")}
                    onClick={this.move.bind(null, -1)}>
                    <CaretIconLeft isLightHover width={IconSize.S}/>
                </IconWrapperLeft>
                <Year>
                    {this.props.year}
                    <Line></Line>
                </Year>
                <IconWrapperRight
                    isTransparent isDecorative
                    title={this.props.t("ElectronicSubmission:NextYear")}
                    onClick={this.move.bind(null, 1)}>
                    <CaretIconRight isLightHover width={IconSize.S}/>
                </IconWrapperRight>
            </YearSwitcherStyled>
        );
    }
}

export default withTranslation(["Common", "Audit", "ElectronicSubmission"])(YearSwitcher);