import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../../PageUtils";
import {
    addDateGroupDateField,
    clearEmptyDateGroupDateFields,
    getDefinitions as getDocumentDefinitions
} from "../DocumentDef";
import { cloneDeep } from "lodash";
import InvoicesIssuedFormView from "./InvoicesIssuedFormView";
import i18next from "i18next";
import { addDateIssued } from "../invoicesReceived/InvoicesReceivedDef";

import {
    addAccountAssignmentTableDefs,
    addAmountsTableDefs,
    addBankAccountTableDefs,
    addBusinessPartnerFieldDefs,
    addBusinessPartnerTableDefs,
    addCommonDocumentDefs,
    addCorrectiveDocument
} from "../DocumentCommonDefs";
import { setDefByEntityType } from "../../getDefByEntityType";
import { commonDocumentTranslations } from "../Document.utils";
import {
    EntitySetName,
    EntityTypeName,
    InvoiceIssuedEntity,
    InvoiceReceivedEntity
} from "@odata/GeneratedEntityTypes";
import { CompanyPermissionCode, DocumentTypeCode } from "@odata/GeneratedEnums";
import { addTimeResolutionDef } from "../extensions/timeResolution/TimeResolution.utils";
import { addAccrualsDef } from "../extensions/accruals/Accruals.utils";
import { addItemsSummaryDef } from "../extensions/itemsSummary/ItemsSummary.utils";
import { BankAccountAdditionalProps, visibleForAllButCash } from "../../banks/bankAccounts/BankAccounts.utils";
import { addProformaDef } from "../extensions/proforma/Proforma.utils";
import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { isCashBasisAccountingCompany, isVatRegisteredCompany } from "@utils/CompanyUtils";
import { getWorkDate } from "@components/inputs/date/utils";
import { SAVED_VATS_PATH } from "../../admin/vatRules/VatRules.utils";
import { addAssetDef, AssetTranslations } from "../../asset/fixedAsset/FixedAsset.utils";
import { addMinorAssetDef } from "../../asset/minorAsset/MinorAsset.utils";
import { Model } from "../../../model/Model";

// DateTaxableSupply for AR documents
export const addDateTaxableSupplyForAR = (definition: IDefinition, context: IAppContext, isDefault?: boolean, isRequired?: boolean): void => {
    definition.table.columnDefinition.DateTaxableSupply = {};
    definition.table.filterBarDef[0].filterDefinition = {
        ...definition.table.filterBarDef[0].filterDefinition,
        DateTaxableSupply: {}
    };

    definition.form.fieldDefinition.DateTaxableSupply = {
        defaultValue: () => (getWorkDate()),
        affectedFields: [
            { id: SAVED_VATS_PATH }
        ]
    };

    if (isRequired) {
        definition.form.fieldDefinition.DateTaxableSupply.isRequired = true;
    }

    // Replaces whole date group with different dates (with defaults) for issued invoice
    addDateGroupDateField(definition.form, InvoiceReceivedEntity.DateIssued);
    addDateGroupDateField(definition.form, InvoiceReceivedEntity.DateDue);

    if (isVatRegisteredCompany(context)) {
        addDateGroupDateField(definition.form, InvoiceReceivedEntity.DateTaxableSupply);
        if (isDefault) {
            definition.table.columns.splice(3, 0, InvoiceIssuedEntity.DateTaxableSupply);
        }
    }
};


const changeSymbolVariableVisibility = (definition: IDefinition) => {
    definition.form.fieldDefinition.SymbolVariable.isVisible = visibleForAllButCash;
};

export const enhanceIssuedDocDefinition = (
    definition: IDefinition,
    settings: {
        docType: DocumentTypeCode;
        transFile: string;
        withoutAmountDueSummary?: boolean;
        isBusinessPartnerOptional?: boolean;
        hasAccountAssignment: boolean;
        withoutAccruals?: boolean;
        context: IAppContext;
    }
): void => {
    addCommonDocumentDefs(definition, settings.docType);
    addBankAccountTableDefs(definition);
    addBusinessPartnerTableDefs(definition);
    addAmountsTableDefs(definition, settings.withoutAmountDueSummary);
    addDateIssued(definition, true, true);
    addAssetDef(definition);
    addMinorAssetDef(definition, settings.context, false);
    addDateTaxableSupplyForAR(definition, settings.context);
    addBusinessPartnerFieldDefs(definition, settings.docType, settings.transFile, settings.isBusinessPartnerOptional);

    changeSymbolVariableVisibility(definition);

    if (settings.hasAccountAssignment) {
        addTimeResolutionDef(definition);
        addAccountAssignmentTableDefs(definition);

        if (!settings.withoutAccruals) {
            addAccrualsDef(definition, { isRevenue: true, addAction: true });
        }
    }

    definition.form.additionalProperties.push(...BankAccountAdditionalProps);
};

export const getDefinitions: IGetDefinition = (context: IAppContext): IDefinition => {
    const hasAccountAssignment = !isCashBasisAccountingCompany(context);

    const definition = cloneDeep(getDocumentDefinitions({
        entitySet: EntitySetName.InvoicesIssued,
        documentType: DocumentTypeCode.InvoiceIssued,
        tableId: "invoicesIssuedTable",
        formId: "invoicesIssuedForm",
        formControl: InvoicesIssuedFormView,
        translationFiles: getDefinitions.translationFiles,
        getItemBreadCrumbText: (storage: Model) =>
            getItemBreadCrumbsText(storage, i18next.t("InvoicesIssued:Breadcrumbs.NewInvoice"),
                storage.data.entity?.NumberOurs && i18next.t("InvoicesIssued:Breadcrumbs.InvoiceWithNumber", { number: storage.data.entity.NumberOurs })),
        permissions: [CompanyPermissionCode.InvoicesIssued],
        context
    }));

    enhanceIssuedDocDefinition(definition, {
        docType: DocumentTypeCode.InvoiceIssued,
        transFile: "InvoicesIssued",
        hasAccountAssignment,
        context
    });

    addCorrectiveDocument(definition, hasAccountAssignment);
    addItemsSummaryDef(definition, context);
    addProformaDef(definition, hasAccountAssignment);

    return clearEmptyDateGroupDateFields(definition);
};

getDefinitions.translationFiles = ["InvoicesIssued", ...AssetTranslations, ...commonDocumentTranslations];
setDefByEntityType(EntityTypeName.InvoiceIssued, getDefinitions);