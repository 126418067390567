import React from "react";
import {
    ActionWrapper,
    AlertDetailWrapper,
    Body,
    StatusIconWrapper,
    StyledAlert,
    Subtitle,
    Title
} from "./Alert.styles";
import { IconSize, Status } from "../../enums";
import TestIds from "../../testIds";
import {
    CloseIcon,
    CollapseIcon,
    ErrorIcon,
    ExpandIcon,
    IProps as IIconProps,
    SuccessIcon,
    WarningIcon,
} from "../icon";
import { WithTranslation, withTranslation } from "react-i18next";
import Tooltip from "../tooltip";
import { IconButton } from "../button";
import AlertDetail from "./AlertDetail";
import { TRecordAny } from "../../global.types";
import { PropsWithTheme, themes } from "../../theme";
import { DefaultTheme, ThemeProvider, withTheme } from "styled-components";

export interface IBaseAlertProps {
    status: Status;
    title?: React.ReactNode;
    subTitle?: React.ReactNode | React.ReactNode[];
    useFade?: boolean;
    action?: AlertAction;
    detailData?: TRecordAny;
}

export interface IAlertProps extends IBaseAlertProps {
    /** Element title shown on hover. Use if subTitle isn't string.
     * Otherwise, title is built from subTitle/s automatically. */
    hoverTitle?: string;
    /** Renders with width 100% instead of max-content */
    isFullWidth?: boolean;
    /** Renders without smiley, with smaller padding and font */
    isSmall?: boolean;
    /** Renders both title and subtitle on one line */
    isOneLiner?: boolean;
    /** Adds margin between alert and the following content,
     * that should always be present according to specification */
    shouldAddBottomMargin?: boolean;
    position?: AlertPosition;
    /** Use inverse styled-components theme */
    isInverseTheme?: boolean;
    onClose?: () => void;
    onFadeEnd?: () => void;
    className?: string;
    style?: React.CSSProperties;
}

interface IState {
    isExpanded: boolean;
}

export enum AlertAction {
    // no action by default
    None = "None",
    // adds close button which triggers onClose event
    Close = "Action",
    // adds expand/collapse functionality
    Expand = "Expand"
}

export enum AlertPadding {
    S = "S",
    M = "M",
    L = "L"
}

export enum AlertPosition {
    // rendered as is
    Default = "Default",
    // absolutely positioned in the bottom center
    CenteredBottom = "CenteredBottom",
}

class Alert extends React.PureComponent<IAlertProps & WithTranslation & PropsWithTheme, IState> {
    static defaultProps: Partial<IAlertProps> = {
        action: AlertAction.None,
        position: AlertPosition.Default
    };

    state = {
        isExpanded: true
    };

    getIcon = (status: Status) => {
        switch (status) {
            case Status.Success:
                return SuccessIcon;
            case Status.Warning:
                return WarningIcon;
            case Status.Error:
                return ErrorIcon;
        }
        return null;
    };

    renderIcon = () => {
        const Icon = this.getIcon(this.props.status);

        return (
                <StatusIconWrapper>
                    <Icon preventHover/>
                </StatusIconWrapper>
        );
    };

    handleExpand = () => {
        this.setState({
            isExpanded: !this.state.isExpanded
        });
    };

    getActionIconParams = (action: AlertAction): {
        icon: React.ComponentType<IIconProps>,
        event: () => void,
        title: string,
        style?: React.CSSProperties
    } => {
        switch (action) {
            case AlertAction.Close:
                return {
                    icon: CloseIcon,
                    event: this.props.onClose,
                    title: this.props.t("Common:General.Close")
                };
            case AlertAction.Expand:
                return {
                    icon: this.state.isExpanded ? CollapseIcon : ExpandIcon,
                    event: this.handleExpand,
                    title: this.props.t(`Common:General.${this.state.isExpanded ? "Close" : "Open"}`)
                };
            default:
                return null;
        }

    };

    renderDetail = () => {
        if (this.props.detailData) {
            return (
                    <AlertDetailWrapper isInline={this.hasInlineDetail()} isSmall={this.props.isSmall}>
                    <AlertDetail data={this.props.detailData}/>
                </AlertDetailWrapper>
            );
        }

        return null;
    };

    shouldRenderSoloDetail = () => {
        return !this.isInline() && !!this.props.detailData && this.props.subTitle;
    };

    hasInlineDetail = () => {
        return this.isInline() || (this.props.detailData && !this.props.subTitle);
    };

    shouldRenderAction = () => {
        return this.props.action !== AlertAction.None || (!!this.props.detailData && !this.props.subTitle);
    };


    renderAction = () => {
        const actionIcon = this.getActionIconParams(this.props.action);

        return (
                <ActionWrapper status={this.props.status} isSmall={this.props.isSmall} isInline={this.isInline()}>
                    {!this.props.subTitle && this.renderDetail()}
                    {actionIcon &&
                            <IconButton onClick={actionIcon.event}
                                        testid={TestIds.AlertAction}
                                        title={actionIcon.title}
                                        isDecorative
                                        style={actionIcon.style}
                                        status={this.props.status}>
                                <actionIcon.icon width={IconSize.M} height={IconSize.M}/>
                            </IconButton>
                    }
                </ActionWrapper>
        );
    };

    isInline = () => {
        return this.props.isOneLiner || (this.props.action === AlertAction.Expand && !this.state.isExpanded);
    };

    getPaddingType = () => {
        if (this.props.isSmall) {
            return AlertPadding.S;
        }

        if (this.isInline()) {
            return AlertPadding.M;
        }

        return AlertPadding.L;
    };

    renderSubTitles = () => {
        if (this.props.subTitle) {
            const subtitles: React.ReactNode[] = Array.isArray(this.props.subTitle) ? this.props.subTitle : [this.props.subTitle];
            const hasMultipleSubtitles = subtitles.length > 1;

            return subtitles.map((subTitle, index: number) => {
                    let result = subTitle;

                    if (index < subtitles.length - 1) {
                        // add space to the end of subtitle to separate multiple subtitles in inline mode
                        result = (
                            <>
                                {subTitle}
                                {" "}
                            </>
                        );
                    }

                    return (
                        <Subtitle _inline={this.isInline()}
                                  _showDivider={hasMultipleSubtitles}
                                  status={this.props.status}
                                  data-testid={TestIds.AlertSubtitle}
                                  key={index}>
                            {result}
                        </Subtitle>
                    );
                }
            );
        }

        return null;
    };

    getHoverTitle = (): string => {
        if (this.props.hoverTitle) {
            return this.props.hoverTitle;
        }

        if (!this.props.subTitle) {
            return this.props.title as string;
        }

        const texts: React.ReactNode[] = Array.isArray(this.props.subTitle) ? this.props.subTitle : [this.props.subTitle];

        return texts.map(text => typeof text === "string" ? text : "").join(" ");
    };

    getTheme = (): DefaultTheme => {
        const currentTheme = this.props.theme;

        if (!this.props.isInverseTheme) {
            return currentTheme;
        }

        return themes.light === currentTheme ? themes.dark : themes.light;
    };

    render() {
        const isInline = this.isInline();

        return (
            <ThemeProvider theme={this.getTheme()}>
                <StyledAlert className={this.props.className}
                             style={this.props.style}
                             _small={this.props.isSmall}
                             _isFullWidth={this.props.isFullWidth}
                             _shouldAddBottomMargin={this.props.shouldAddBottomMargin}
                             status={this.props.status}
                             useFade={this.props.useFade}
                             position={this.props.position}
                             onAnimationEnd={this.props.onFadeEnd}
                             data-testid={TestIds.Alert}>
                    {!this.props.isSmall && this.renderIcon()}
                    {this.shouldRenderSoloDetail() && this.renderDetail()}
                    <Tooltip
                        onlyShowWhenChildrenOverflowing
                        isHidden={!isInline}
                        content={isInline ? this.getHoverTitle() : ""}>
                        {(ref) => (
                            <Body _small={this.props.isSmall}
                                  _hasInlineDetailAndCloseIcon={this.shouldRenderAction()}
                                  _inline={isInline}
                                  _padding={this.getPaddingType()}
                                  ref={ref}
                                  data-testid={TestIds.AlertContent}>
                                {(this.props.title || this.props.children) &&
                                    <Title data-testid={TestIds.AlertTitle}
                                           _small={this.props.isSmall}
                                           _inline={isInline}
                                           disableDefaultStyles={typeof this.props.title !== "string"}>
                                        {this.props.title}
                                    </Title>
                                }
                                {this.renderSubTitles()}
                            </Body>
                        )}
                    </Tooltip>
                    {this.shouldRenderAction() && this.renderAction()}
                </StyledAlert>
            </ThemeProvider>
        );
    }
}

export default withTheme(withTranslation(["Common"])(Alert));