import {
    getAccountsParamDef,
    IReportTableDefinition,
    NumberAggregationFunction,
    ReportConfigGroup,
    TimeAggregationFunction,
    TReportConfigListItemOverride
} from "../Report.utils";
import i18next from "i18next";
import { BasicInputSizes, ConfigListItemBoundType, FieldType, Sort } from "../../../enums";
import { CommonReportProps, getByDateDef, getDateRangeDef } from "../CommonDefs";
import { getSimpleBoolSelectItems } from "@components/smart/FieldInfo";
import BindingContext from "../../../odata/BindingContext";
import { IReportHierarchy } from "@components/smart/smartTable";
import { ReportId } from "../ReportIds";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { TRecordType, TValue } from "../../../global.types";
import { ISelectItem } from "@components/inputs/select/BasicSelect";
import { IFormatOptions } from "@odata/OData.utils";
import { ICellValueObject } from "@components/table";
import { getTableIntentLink } from "@components/drillDown/DrillDown.utils";
import { getRouteByEntityType } from "@odata/EntityTypes";

export const SaldoProps = {
    accounts: BindingContext.localContext("Accounts"),
    sumAmounts: BindingContext.localContext("SumAmounts"),
    showBalancedSaldo: BindingContext.localContext("ShowBalancedSaldo")
};

export const saldoConfigListSettings: TRecordType<TReportConfigListItemOverride> = {
    Account: {
        isRequired: true,
        isDisabled: true,
        isCopyOnly: false,
        group: ReportConfigGroup.Groups
    },
    JournalEntry_DocumentNumberOurs: {
        isRequired: true,
        isDisabled: true,
        isCopyOnly: false,
        group: ReportConfigGroup.Groups
    },
    JournalEntry_Amount: {
        isRequired: true,
        areItemsReadOnly: true,
        boundTo: ConfigListItemBoundType.Column
    }
};


export const getDefinition = (): IReportTableDefinition => {
    const tableId = ReportId.Saldo;
    const title = i18next.t("Reporting:Saldo.Title");
    const path = "Saldo";
    const initialSortBy = [
        {
            id: `JournalEntry_DateAccountingTransaction_${TimeAggregationFunction.Day}`,
            sort: Sort.Asc
        }
    ];
    const disableAggregateButton = true;
    const parameters: string[] = [
        CommonReportProps.dateRange,
        CommonReportProps.byDate,
        SaldoProps.accounts,
        SaldoProps.sumAmounts,
        SaldoProps.showBalancedSaldo
    ];

    const defaultReportHierarchy: IReportHierarchy = {
        "Aggregate": true,
        "Groups": [
            {
                "ColumnAlias": "Account"
            },
            {
                "ColumnAlias": "JournalEntry_DocumentNumberOurs"
            }
        ],
        "Columns": [
            {
                "ColumnAlias": "JournalEntry_DateAccountingTransaction",
                "AggregationFunction": TimeAggregationFunction.Day
            },
            {
                "ColumnAlias": "JournalEntry_JournalEntryNumberOurs"
            },
            {
                "ColumnAlias": "JournalEntry_SymbolVariable"
            }
        ],
        "Aggregations": [
            {
                "ColumnAlias": "JournalEntry_Amount",
                "AggregationFunction": NumberAggregationFunction.Sum
            }
        ]
    };

    const dateRangeDef = getDateRangeDef();
    const filterBarDef: IFilterGroupDef[] = [
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Parameters),
            defaultFilters: [
                CommonReportProps.dateRange,
                CommonReportProps.byDate,
                SaldoProps.accounts,
                SaldoProps.sumAmounts,
                SaldoProps.showBalancedSaldo
            ],
            filterDefinition: {
                [CommonReportProps.dateRange]: {
                    ...dateRangeDef,
                    fieldSettings: {
                        ...dateRangeDef.fieldSettings,
                        transformFetchedItems: (items: ISelectItem[]): ISelectItem[] => {
                            const transformedItems = items.filter(item => !item.id.toString().includes("FiscalPeriod"));

                            transformedItems.push({
                                id: "All",
                                label: i18next.t("Reporting:Saldo.AllRanges")
                            });

                            return transformedItems;
                        }
                    }
                },
                [CommonReportProps.byDate]: getByDateDef(),
                [SaldoProps.accounts]: getAccountsParamDef(["321", "311"], false, true),
                [SaldoProps.sumAmounts]: {
                    type: FieldType.ComboBox,
                    defaultValue: true,
                    label: i18next.t("Reporting:Saldo.SumAmounts"),
                    fieldSettings: {
                        items: getSimpleBoolSelectItems()
                    }
                },
                [SaldoProps.showBalancedSaldo]: {
                    width: BasicInputSizes.L,
                    type: FieldType.ComboBox,
                    defaultValue: false,
                    label: i18next.t("Reporting:Saldo.ShowBalancedSaldo"),
                    fieldSettings: {
                        items: getSimpleBoolSelectItems()
                    }
                }
            }
        },
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            allowCustomFilters: false,
            defaultFilters: [],
            filterDefinition: {}
        }
    ];

    const columnOverrides = {
        JournalEntry_JournalEntryNumberOurs: {
            formatter: (val: TValue, args: IFormatOptions): ICellValueObject => {
                if (!val) {
                    return null;
                }

                const docId = args.entity["JournalEntry_DocumentId"] ?? args.entity["JournalEntry_PaymentDocumentId"];
                const docType = args.entity["JournalEntry_DocumentTypeCode"];
                const url = `${getRouteByEntityType(docType)}/${docId}`;

                return getTableIntentLink(val as string, {
                    route: url,
                    context: args.storage.context,
                    storage: args.storage
                });
            }
        }
    };


    return {
        title, path, id: tableId, initialSortBy, disableAggregateButton, columnOverrides,
        filterBarDef, parameters, defaultReportHierarchy, configListItemsOverride: saldoConfigListSettings
    };
};