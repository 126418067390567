import React from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import {
    ConsentCheckboxWrapper,
    DialogHeader,
    PaymentTypeDescription,
    PaymentTypeImageWrapper,
    PlugAndPlayFormWrapper,
    ProductTypeSelectionScreenWrapper,
    PurchaseWizardTileFlex,
    SmallDescription,
    SubItemDescription,
    SubItemPrice,
    SubItemPriceWrapper,
    SubItemTitle,
    SubItemTitleWrapper,
    SubItemWrapper
} from "./PurchaseWizard.styles";
import PurchaseWizardTile from "./PurchaseWizardTile";
import { Separator } from "../separator/Separator";
import Currency from "../../types/Currency";
import { CurrencyCode, ProductCode, ProductPriceTypeCode } from "@odata/GeneratedEnums";
import { AppContext, IAppContext } from "../../contexts/appContext/AppContext.types";
import { ArrowIcon, InfoIcon } from "../icon";
import { BasicInputSizes, FieldType, IconSize, LabelStatus, Status } from "../../enums";
import { WithPromisedComponent, withPromisedComponent } from "../dialog/withPromisedComponent";
import {
    consentPath,
    individualSettingsCompanyPath,
    individualSettingsTextPath,
    IPurchaseEvalaResponseData,
    PurchaseDialogPaymentType,
    purchaseEvala,
    PurchaseWizardDialogScreen
} from "./PurchaseWizardDialog.utils";
import Dialog from "../dialog";
import ConfirmationButtons from "../../views/table/ConfirmationButtons";
import { Button } from "../button";
import memoizeOne from "../../utils/memoizeOne";
import { createBindingContext, IEntity } from "@odata/BindingContext";
import {
    AddressEntity,
    EntitySetName,
    IProductPriceEntity,
    ITenantEntity,
    TenantEntity
} from "@odata/GeneratedEntityTypes";
import { withOData, WithOData } from "@odata/withOData";
import { IDefinition } from "@pages/PageUtils";
import { getDefinition } from "@pages/admin/subscriptions/subpages/Invoicing.def";
import PlugAndPlayForm from "../../views/formView/PlugAndPlayForm";
import { FormViewForExtend } from "../../views/formView/FormView";
import { EvalaTermsAndConditionsLink } from "../../login/Login.utils";
import Checkbox, { ICheckboxChange } from "../inputs/checkbox";
import Clickable from "../clickable";
import { IFieldDefFn, IGetValueArgs, TGetValueFn } from "../smart/FieldInfo";
import { SvgImage } from "../svgImage/SvgImage";
import billingPayCardSvg from "../../svg/menu/billingPayCard.svg";
import billingPayWireSvg from "../../svg/menu/billingPayWire.svg";
import Field from "../inputs/field";
import Input, { IInputOnBlurEvent, IInputOnChangeEvent } from "../inputs/input";
import FieldsWrapper from "../inputs/field/FieldsWrapper";
import { phoneNumberInputFormatter, phoneNumberIsValid, phoneNumberParser } from "../smart/GeneralFieldDefinition";
import { IValidationError } from "../../model/Validator.types";
import { WithAlert, withAlert } from "../alert/withAlert";
import PurchaseFinishedDialog from "./PurchaseFinishedDialog";
import i18next from "i18next";
import PurchaseWizardDialogFormView from "./PurchaseWizardDialogFormView";
import Text from "../text";
import { ROUTE_SUBSCRIPTIONS_PLAN } from "../../routes";
import { RouteComponentProps, withRouter } from "react-router-dom";
import TestIds from "../../testIds";

interface IProps extends WithTranslation, WithPromisedComponent, WithOData, WithAlert, RouteComponentProps {
    onClose: (finished: boolean) => void;
}

interface IState {
    isBusy: boolean;
    selectedProduct: ProductCode;
    screen: PurchaseWizardDialogScreen;
    paymentType: PurchaseDialogPaymentType;
    isConsentChecked: boolean;
    tenant: Partial<ITenantEntity>;
    phoneNumber: string;
    phoneNumberError: IValidationError;
    showWireFinished: boolean;
    productPrices: IProductPriceEntity[];
    individualCompanyId: number;
}

class PurchaseWizardDialog extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;

    formRef = React.createRef<FormViewForExtend<ITenantEntity>>();

    constructor(props: IProps, context: IAppContext) {
        super(props);

        this.state = {
            isBusy: true,
            selectedProduct: ProductCode.EvalaForAccountants,
            paymentType: PurchaseDialogPaymentType.Card,
            screen: PurchaseWizardDialogScreen.SelectProductType,
            isConsentChecked: true,
            tenant: null,
            phoneNumber: "+420",
            phoneNumberError: null,
            showWireFinished: false,
            productPrices: null,
            individualCompanyId: null
        };
    }

    componentDidMount() {
        this.init();
    }

    init = async (): Promise<void> => {
        const context = this.context as IAppContext;
        const reqTenant = this.props.oData.getEntitySetWrapper(EntitySetName.Tenants).query(context.getData().tenant.Id)
            .expand(TenantEntity.BillingAddress, q => q.expand(AddressEntity.Country))
            .fetchData<ITenantEntity>();
        const reqProductPrices = this.props.oData.getEntitySetWrapper(EntitySetName.ProductPrices).query().fetchData<IProductPriceEntity[]>();
        const [resTenant, resProductPrices] = await Promise.all([reqTenant, reqProductPrices]);

        const tenant = resTenant.value;
        const productPrices = resProductPrices.value;

        // for some reason, DateCreated is not readonly,
        // and currently our formatDateToDateTimeOffsetString sends wrong value on patch and backend fails
        // => delete DateCreated
        delete tenant.DateCreated;

        this.setState({
            tenant,
            productPrices,
            isBusy: false
        });
    };

    getTenantBc = memoizeOne(() => {
        const context = this.context as IAppContext;

        return createBindingContext(EntitySetName.Tenants, this.props.oData.getMetadata()).addKey(context.getData().tenant.Id);
    }, () => {
        const context = this.context as IAppContext;

        return [context.getData().tenant.Id];
    });

    formatAmount = (amount: number): string => {
        return Currency.format(amount, {
            currency: CurrencyCode.CzechKoruna,
            maximumFractionDigits: 0
        });
    };

    // i think this is used to prevent fetching data every time
    // the form screen is rendered (form of caching)
    syncFormEntityToState = (): void => {
        this.setState({
            tenant: this.formRef.current.entity
        });
    };

    handleProductSelection = (id: string): void => {
        this.setState({
            selectedProduct: id as ProductCode
        });
    };

    handlePaymentTypeSelection = (id: string): void => {
        const purchaseType = id as PurchaseDialogPaymentType;

        if (purchaseType === PurchaseDialogPaymentType.Card) {
            this.setState({
                phoneNumberError: null
            });
        }

        this.setState({
            paymentType: purchaseType
        });
    };

    handleConfirm = async (): Promise<void> => {
        if (this.state.screen === PurchaseWizardDialogScreen.InvoicingInfo) {
            this.syncFormEntityToState();

            this.setState({
                isBusy: true
            });

            const individualCompanyId = (this.formRef.current.entity as IEntity)[individualSettingsCompanyPath] as number;

            const wasSaved = await this.formRef.current.save({
                updateEnabledFor: [TenantEntity.BillingAddress]
            });

            this.setState({
                isBusy: false,
                individualCompanyId
            });

            if (!wasSaved) {
                return;
            }
        } else if (this.state.screen === PurchaseWizardDialogScreen.PaymentType) {
            const productCode = this.state.selectedProduct;
            const individualCompanyId = this.state.individualCompanyId;

            if (this.state.paymentType === PurchaseDialogPaymentType.Card) {
                this.setState({
                    isBusy: true
                });

                const res = await purchaseEvala({
                    paymentType: PurchaseDialogPaymentType.Card,
                    productCode: productCode,
                    phoneNumber: this.state.phoneNumber,
                    individualCompanyId: individualCompanyId
                });
                const data = await res.json();

                if (res.ok) {
                    window.location.href = (data as IPurchaseEvalaResponseData).Url;
                    return;
                } else {
                    this.props.setAlert({
                        status: Status.Error,
                        title: this.props.t("Common:Errors.ErrorHappened"),
                        subTitle: this.props.t("Subscriptions:ProductSelection.PaymentError"),
                        detailData: data,
                        isFullWidth: true
                    });

                    this.setState({
                        isBusy: false
                    });
                    return;
                }
            } else {
                const context = this.context as IAppContext;
                const isPhoneNumberValid = this.isPhoneNumberValid();

                if (!isPhoneNumberValid) {
                    this.setPhoneNumberError(true);
                    return;
                } else {
                    this.setState({
                        isBusy: true
                    });

                    const res = await purchaseEvala({
                        paymentType: PurchaseDialogPaymentType.Wire,
                        productCode: productCode,
                        phoneNumber: this.state.phoneNumber,
                        individualCompanyId: individualCompanyId
                    });

                    if (!res.ok) {
                        const error = await res.json();
                        this.props.setAlert({
                            status: Status.Error,
                            title: this.props.t("Common:Errors.ErrorHappened"),
                            detailData: error,
                            isFullWidth: true
                        });
                        this.setState({
                            isBusy: false
                        });
                        return;
                    } else {
                        const context = this.context as IAppContext;

                        // propagate the updated ProductCode into the context subscription
                        await context.updateTenantAndSubscription();

                        // some companies could've been deleted => update
                        await context.updateCompanies();

                        this.setState({
                            showWireFinished: true,
                            isBusy: false
                        });
                    }
                }
            }
        }

        this.setState({
            screen: this.state.screen + 1
        });
    };

    handleCancel = (): void => {
        this.props.onClose(false);
    };

    handleBack = (): void => {
        this.props.setAlert(null);

        if (this.state.screen === PurchaseWizardDialogScreen.InvoicingInfo) {
            this.syncFormEntityToState();
        }

        this.setState({
            screen: this.state.screen - 1
        });
    };

    getBillingInfoDefinition = (): IDefinition => {
        const tenantFormDef = getDefinition(this.context);
        const addressGroup = tenantFormDef.form.groups.find(group => group.id === "address");
        const rows = addressGroup.rows.filter(row => !row.find(field => field.id === TenantEntity.SendInvoice));
        const groups = [{
            ...addressGroup,
            rows
        }];

        const hasCompaniesFn: TGetValueFn<boolean> = (args: IGetValueArgs) => {
            return args.storage.context.getData()?.companies?.length > 1;
        };

        if (this.state.selectedProduct !== ProductCode.EvalaForCompanies) {
            rows.push([{ id: consentPath }]);
        } else {
            groups.push({
                id: "individualSettings",
                title: i18next.t("Subscriptions:ProductSelection.IndividualSettingsTitle"),
                isVisible: hasCompaniesFn,
                rows: [
                    [{ id: individualSettingsTextPath }],
                    [{ id: individualSettingsCompanyPath }]
                ]
            });
        }

        tenantFormDef.form.fieldDefinition[TenantEntity.LegalNumber] = {
            ...tenantFormDef.form.fieldDefinition[TenantEntity.LegalNumber],
            isRequired: true
        };

        return {
            ...tenantFormDef,
            form: {
                ...tenantFormDef.form,
                fieldDefinition: {
                    ...tenantFormDef.form.fieldDefinition,
                    [consentPath]: {
                        type: FieldType.Custom,
                        labelStatus: LabelStatus.Removed,
                        render: (args: IFieldDefFn) => {
                            const handleChange = (event: ICheckboxChange) => {
                                this.setState({
                                    isConsentChecked: event.value
                                });
                                args.storage.refresh();
                            };

                            return (
                                <ConsentCheckboxWrapper>
                                    <Checkbox checked={this.state.isConsentChecked}
                                              onChange={handleChange}
                                              label={<>
                                                  {`${this.props.t("Subscriptions:ProductSelection.ConsentPrefix")} `}
                                                  <Clickable link={EvalaTermsAndConditionsLink}>
                                                      {this.props.t("Subscriptions:ProductSelection.ConsentVOP")}
                                                  </Clickable>
                                              </>}/>
                                </ConsentCheckboxWrapper>
                            );
                        }
                    },
                    [individualSettingsTextPath]: {
                        type: FieldType.Custom,
                        render: () => {
                            return (
                                <Text>
                                    {this.props.t("Subscriptions:ProductSelection.IndividualSettingsDescription")}
                                </Text>
                            );
                        }

                    },
                    [individualSettingsCompanyPath]: {
                        label: this.props.t("Subscriptions:ProductSelection.IndividualSettingsCompany"),
                        type: FieldType.ComboBox,
                        isVisible: hasCompaniesFn,
                        isRequired: true,
                        width: BasicInputSizes.L
                    }
                },
                groups
            }
        };
    };

    getProductTypePrice = (productTypeCode: ProductPriceTypeCode): number => {
        const productPrice = this.state.productPrices.find(p => p.ProductPriceTypeCode === productTypeCode);

        if (!productPrice) {
            return null;
        }

        return productPrice.PriceNet;
    };

    renderProductTypeSelectionScreen = (): React.ReactElement => {
        if (!this.state.productPrices) {
            return null;
        }

        return (
            <ProductTypeSelectionScreenWrapper>
                <PurchaseWizardTileFlex>
                    <PurchaseWizardTile id={ProductCode.EvalaForAccountants}
                                        title={this.props.t("Subscriptions:ProductSelection.ForAccountants")}
                                        isSelected={this.state.selectedProduct === ProductCode.EvalaForAccountants}
                                        onClick={this.handleProductSelection}>
                        <SubItemWrapper>
                            <SubItemTitle>
                                {this.props.t("Subscriptions:ProductSelection.AccountingForBusiness")}
                            </SubItemTitle>
                            <SubItemPriceWrapper>
                                <SubItemPrice>
                                    {this.formatAmount(this.getProductTypePrice(ProductPriceTypeCode.PerCompanyWithAccountingForBusiness))}
                                </SubItemPrice>
                                <SubItemDescription>
                                    {this.props.t("Subscriptions:ProductSelection.Customer")} / {this.props.t("Subscriptions:ProductSelection.Month")}
                                </SubItemDescription>
                            </SubItemPriceWrapper>
                        </SubItemWrapper>

                        <Separator isEmphasised/>

                        <SubItemWrapper>
                            <SubItemTitle>
                                {this.props.t("Subscriptions:ProductSelection.CashBasisAccounting")}
                            </SubItemTitle>
                            <SubItemPriceWrapper>
                                <SubItemPrice>
                                    {this.formatAmount(this.getProductTypePrice(ProductPriceTypeCode.PerCompanyWithCashBasisAccounting))}
                                </SubItemPrice>
                                <SubItemDescription>
                                    {this.props.t("Subscriptions:ProductSelection.Customer")} / {this.props.t("Subscriptions:ProductSelection.Month")}
                                </SubItemDescription>
                            </SubItemPriceWrapper>
                        </SubItemWrapper>

                        <Separator isEmphasised/>

                        <SubItemWrapper>
                            <SubItemTitle>
                                {this.props.t("Subscriptions:ProductSelection.ArchivedAgenda")}
                            </SubItemTitle>
                            <SubItemPriceWrapper>
                                <SubItemPrice>
                                    {this.formatAmount(this.getProductTypePrice(ProductPriceTypeCode.PerArchivedCompany))}
                                </SubItemPrice>
                                <SubItemDescription>
                                    {this.props.t("Subscriptions:ProductSelection.Customer")} / {this.props.t("Subscriptions:ProductSelection.Month")}
                                </SubItemDescription>
                            </SubItemPriceWrapper>
                        </SubItemWrapper>
                    </PurchaseWizardTile>
                    <PurchaseWizardTile id={ProductCode.EvalaForCompanies}
                                        title={this.props.t("Subscriptions:ProductSelection.ForCompanies")}
                                        description={this.props.t("Subscriptions:ProductSelection.ForCompaniesDescription")}
                                        isSelected={this.state.selectedProduct === ProductCode.EvalaForCompanies}
                                        onClick={this.handleProductSelection}>
                        <SubItemWrapper>
                            <SubItemTitleWrapper>
                                <SubItemTitle>
                                    {this.props.t("Subscriptions:ProductSelection.AccountingForBusiness")}
                                </SubItemTitle>
                            </SubItemTitleWrapper>
                            <SubItemPriceWrapper>
                                <SubItemPrice>
                                    {this.formatAmount(this.getProductTypePrice(ProductPriceTypeCode.PerUserAccountingForBusiness))}
                                </SubItemPrice>
                                <SubItemDescription>
                                    {this.props.t("Subscriptions:ProductSelection.User")} / {this.props.t("Subscriptions:ProductSelection.Month")}
                                </SubItemDescription>
                            </SubItemPriceWrapper>
                        </SubItemWrapper>

                        <Separator isEmphasised/>

                        <SubItemWrapper>
                            <SubItemTitleWrapper>
                                <SubItemTitle>
                                    {this.props.t("Subscriptions:ProductSelection.CashBasisAccounting")}
                                </SubItemTitle>
                            </SubItemTitleWrapper>
                            <SubItemPriceWrapper>
                                <SubItemPrice>
                                    {this.formatAmount(this.getProductTypePrice(ProductPriceTypeCode.PerUserCashBasisAccounting))}
                                </SubItemPrice>
                                <SubItemDescription>
                                    {this.props.t("Subscriptions:ProductSelection.User")} / {this.props.t("Subscriptions:ProductSelection.Month")}
                                </SubItemDescription>
                            </SubItemPriceWrapper>
                        </SubItemWrapper>
                    </PurchaseWizardTile>
                </PurchaseWizardTileFlex>
                <SmallDescription>
                    <InfoIcon width={IconSize.XS}/>
                    <span>
                        <Trans i18nKey={"Subscriptions:ProductSelection.AboveDocumentsLimit"}
                               values={{ price: this.formatAmount(this.getProductTypePrice(ProductPriceTypeCode.PerAcceptableUserPolicyDocumentsCount)) }}
                               components={{
                                   1: <strong/>
                               }}/>
                    </span>
                </SmallDescription>
            </ProductTypeSelectionScreenWrapper>
        );
    };

    renderBillingInfoScreen = () => {
        return (
            // hack to prevent form from having scrollbar (by removing height 100%)
            <PlugAndPlayFormWrapper>
                <PlugAndPlayForm
                    getDef={this.getBillingInfoDefinition}
                    formRef={this.formRef}
                    t={this.props.t}
                    bindingContext={this.getTenantBc()}
                    initialData={{
                        entity: this.state.tenant
                    }}
                    ignoreVariants
                    formView={PurchaseWizardDialogFormView}
                    formViewProps={{
                        isInDialog: true,
                        formProps: {
                            hideHeader: true,
                            hideBreadcrumbs: true,
                            renderScrollbar: false,
                            withoutPadding: true
                        },
                        hideButtons: true
                    }}
                />
            </PlugAndPlayFormWrapper>
        );
    };

    isPhoneNumberValid = (): boolean => {
        return phoneNumberIsValid(this.state.phoneNumber);
    };

    setPhoneNumberError = (isError: boolean) => {
        this.setState({
            phoneNumberError: isError ? {
                message: this.props.t("Common:Validation.WrongNumber")
            } : null
        });
    };

    handlePhoneNumberChange = (e: IInputOnChangeEvent<string>): void => {
        this.setState({
            phoneNumber: e.value
        });
    };

    handlePhoneNumberBlur = (e: IInputOnBlurEvent): void => {
        this.setPhoneNumberError(!this.isPhoneNumberValid());
    };

    handleFinishedDialogClose = (): void => {
        if (this.props.history.location.pathname !== ROUTE_SUBSCRIPTIONS_PLAN) {
            // Wire mode selected - current company could've been removed
            // => redirect to ROUTE_SUBSCRIPTIONS_PLAN just to show safe page
            const context = this.context as IAppContext;
            // change to our agenda, just to be sure
            context.setCurrentCompanyId(null);

            // redirect to /org/plan if not already there
            this.props.history.push(ROUTE_SUBSCRIPTIONS_PLAN);
        }
        this.props.onClose(true);
    };

    renderPaymentTypeScreen = (): React.ReactElement => {
        return (
            <PurchaseWizardTileFlex>
                <PurchaseWizardTile id={PurchaseDialogPaymentType.Card}
                                    title={this.props.t("Subscriptions:ProductSelection.PaymentCard")}
                                    isCentered
                                    isSelected={this.state.paymentType === PurchaseDialogPaymentType.Card}
                                    isWithoutDescription
                                    onClick={this.handlePaymentTypeSelection}>
                    <PaymentTypeImageWrapper>
                        <SvgImage src={billingPayCardSvg}/>
                    </PaymentTypeImageWrapper>
                    <PaymentTypeDescription>
                        {this.props.t("Subscriptions:ProductSelection.PaymentCardDescription")}
                    </PaymentTypeDescription>
                </PurchaseWizardTile>
                <PurchaseWizardTile id={PurchaseDialogPaymentType.Wire}
                                    title={this.props.t("Subscriptions:ProductSelection.PaymentWire")}
                                    isCentered
                                    isSelected={this.state.paymentType === PurchaseDialogPaymentType.Wire}
                                    isWithoutDescription
                                    onClick={this.handlePaymentTypeSelection}>
                    <PaymentTypeImageWrapper>
                        <SvgImage src={billingPayWireSvg}/>
                    </PaymentTypeImageWrapper>
                    <PaymentTypeDescription>
                        {this.props.t("Subscriptions:ProductSelection.PaymentWireDescription")}
                    </PaymentTypeDescription>
                    <FieldsWrapper>
                        <Field id={"phoneNumber"}
                               label={this.props.t("Subscriptions:ProductSelection.PhoneNumber")}
                               width={BasicInputSizes.L}
                               isRequired>
                            <Input id={"phoneNumber"}
                                   width={BasicInputSizes.L}
                                   onChange={this.handlePhoneNumberChange}
                                   onBlur={this.handlePhoneNumberBlur}
                                   value={this.state.phoneNumber}
                                   error={this.state.phoneNumberError}
                                   formatter={phoneNumberInputFormatter}
                                   parser={phoneNumberParser}
                                   isValid={phoneNumberIsValid}
                            />
                        </Field>
                    </FieldsWrapper>
                </PurchaseWizardTile>
            </PurchaseWizardTileFlex>
        );
    };

    renderHeader = () => {
        let titleKey: string;

        switch (this.state.screen) {
            case PurchaseWizardDialogScreen.SelectProductType:
                titleKey = "HeaderSelectPlanType";
                break;

            case PurchaseWizardDialogScreen.InvoicingInfo:
                titleKey = "HeaderInvoicingInfo";
                break;
            case PurchaseWizardDialogScreen.PaymentType:
                titleKey = "HeaderPaymentType";
                break;
        }

        const title = this.props.t(`Subscriptions:ProductSelection.${titleKey}`);

        return (
            <DialogHeader data-testid={TestIds.DialogHeader}>
                {`${this.state.screen + 1}/${Object.keys(PurchaseWizardDialogScreen).filter(key => isNaN(Number(key))).length}: ${title}`}
            </DialogHeader>
        );
    };

    renderFooter = () => {
        let isContinueDisabled = false;

        if (this.state.screen === PurchaseWizardDialogScreen.InvoicingInfo) {
            isContinueDisabled = !this.state.isConsentChecked && this.state.selectedProduct !== ProductCode.EvalaForCompanies;
        }

        return (
            <ConfirmationButtons onConfirm={this.handleConfirm}
                                 onCancel={this.handleCancel}
                                 isDisabled={isContinueDisabled}
                                 confirmText={this.state.screen < 2
                                     ? this.props.t("Common:General.Continue")
                                     : this.props.t("Subscriptions:ProductSelection.CompletePurchase")}>
                {this.state.screen > 0 ?
                    <Button onClick={this.handleBack}
                            icon={<ArrowIcon width={IconSize.S} height={IconSize.S}/>}
                            isTransparent>
                        {this.props.t("Common:General.Back")}
                    </Button>
                    : null}
            </ConfirmationButtons>
        );
    };

    renderScreen = (): React.ReactElement => {
        switch (this.state.screen) {
            case PurchaseWizardDialogScreen.SelectProductType:
                return this.renderProductTypeSelectionScreen();
            case PurchaseWizardDialogScreen.InvoicingInfo:
                return this.renderBillingInfoScreen();
            case PurchaseWizardDialogScreen.PaymentType:
                return this.renderPaymentTypeScreen();
        }
    };

    render() {
        if (!this.props.tReady) {
            return null;
        }

        if (this.state.showWireFinished) {
            return <PurchaseFinishedDialog type={PurchaseDialogPaymentType.Wire}
                                           onClose={this.handleFinishedDialogClose}/>;
        }

        return (
            <Dialog title={this.props.t("Subscriptions:ProductSelection.DialogTitle")}
                    onConfirm={this.handleConfirm}
                    onClose={this.handleCancel}
                    footer={this.renderFooter()}
                    busy={this.state.isBusy}
                    width={"1000px"}
                    height={"692px"}>
                {this.props.alert}
                {this.renderHeader()}
                {this.renderScreen()}
            </Dialog>
        );
    }
}

export default withRouter(withTranslation(["Subscriptions", "Common"])(withPromisedComponent(withOData(withAlert()(PurchaseWizardDialog)))));