import { EntityTypeName } from "@odata/GeneratedEntityTypes";
import { LanguageCode } from "@odata/GeneratedEnums";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import PDFViewer, { FileToolbarItem, PdfZoomType } from "../../components/fileViewers/pdfViewer";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { QueryParam } from "../../enums";
import { getQueryParameters } from "../../routes/Routes.utils";
import View from "../../views/View";
import { getInvoicePdfUrl } from "../documents/Document.utils";
import { StyledWebInvoice } from "./WebInvoice.styles";

interface IRouteParams {
    id: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {

}

class WebInvoice extends React.PureComponent<IProps> {
    static contextType = AppContext;

    render() {
        const id = parseInt(this.props.match.params.id);
        const companyId = this.context.getCompany().Id;
        const queryParams = getQueryParameters();
        const documentType = (queryParams?.[QueryParam.DocumentType] as EntityTypeName) ?? EntityTypeName.InvoiceIssued;
        let language = queryParams?.[QueryParam.Language] as LanguageCode ?? LanguageCode.Czech;

        if (!Object.values(LanguageCode).includes(language)) {
            language = LanguageCode.Czech;
        }

        return (
            <StyledWebInvoice>
                <View hotspotContextId={"webInvoice"}>
                    <PDFViewer
                        src={getInvoicePdfUrl({
                            documentType: documentType,
                            entityId: id,
                            companyId: companyId,
                            isForPrint: false,
                            language
                        })}
                        printSrc={getInvoicePdfUrl({
                            documentType: documentType,
                            entityId: id,
                            companyId: companyId,
                            isForPrint: true,
                            language
                        })}
                        hiddenItems={[FileToolbarItem.Dashboard]}
                        defaultZoomType={PdfZoomType.FitToPage}
                    />
                </View>
            </StyledWebInvoice>
        );
    }
}

export default withRouter(WebInvoice);