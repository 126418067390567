import React, { useCallback, useEffect, useState } from "react";
import { LoginFormStyled, LostPass } from "../LoginPage.styles";
import TestIds from "../../testIds";
import WriteLine from "../../components/inputs/writeLine/WriteLine";
import PasswordWriteLine from "../../components/inputs/writeLine/PasswordWriteLine";
import { ROUTE_LOGIN_RESET_PASS_REQUEST } from "../../routes";
import { ButtonSize } from "@components/button/Button.utils";
import { IInputOnChangeEvent } from "@components/inputs/input";
import { clearError, login, selectSessionError, selectSessionRequestStatus } from "../state/sessionSlice";
import { useLoginDispatch, useLoginSelector } from "../state/hooks";
import { selectAnimationStatus, selectLoginEmail, setLoginEmail } from "../state/loginSlice";
import LoginField from "./LoginField";
import { useTranslation } from "react-i18next";
import { AnimationPlayStatus, LoginTranslationNamespaces, RequestStatus } from "../Login.utils";
import LoginButtonGroup from "./LoginButtonGroup";
import { Button } from "../../components/button";

interface IProps {
}

const LoginForm: React.FC<IProps> = ({ children }) => {

    const { t } = useTranslation([...LoginTranslationNamespaces]);

    const dispatch = useLoginDispatch();
    const error = useLoginSelector(selectSessionError);
    const status = useLoginSelector(selectSessionRequestStatus);
    const animationStatus = useLoginSelector(selectAnimationStatus);
    const email = useLoginSelector(selectLoginEmail);

    const [password, setPassword] = useState<string>("");

    const handleEmailChanged = useCallback((args: IInputOnChangeEvent<string>): void => {
        dispatch(setLoginEmail(args.value));
        dispatch(clearError());
    }, [dispatch]);

    const handlePasswordChange = useCallback((args: IInputOnChangeEvent<string>): void => {
        setPassword(args.value);
        dispatch(clearError());
    }, [dispatch]);

    const handleLoginPressed = useCallback(() => {
        dispatch(login(email, password));
    }, [dispatch, email, password]);

    useEffect(() => {
        // clear error on route mount
        dispatch(clearError());
    }, [dispatch]);

    const isBusy = status === RequestStatus.Pending || animationStatus === AnimationPlayStatus.Playing;

    return (
            <LoginFormStyled>
                <LoginField name={"username"} label={t("Login:Login.Email")}>
                    <WriteLine error={error}
                               isExtending
                               value={email}
                               autocomplete={"username"}
                               onChange={handleEmailChanged}/>
                </LoginField>

                <LoginField name={"password"}
                            label={t("Login:Login.Password")}
                            afterContent={(
                                    <LostPass testId={TestIds.LostPass} link={ROUTE_LOGIN_RESET_PASS_REQUEST}>
                                        {t("Login:Login.ForgottenPass")}
                                    </LostPass>
                            )}>
                    <PasswordWriteLine
                            autocomplete={"current-password"}
                            error={error}
                            isExtending
                            value={password}
                            onChange={handlePasswordChange}/>
                </LoginField>

                <LoginButtonGroup additionalText={children}>
                    <Button size={ButtonSize.Big}
                            // enable form submitting by enter press
                            type={"submit"}
                            isDisabled={isBusy}
                            onClick={handleLoginPressed}>{t("Login:Login.SubmitLogin")}</Button>
                </LoginButtonGroup>
            </LoginFormStyled>
    );
};

export default LoginForm;