import i18next from "i18next";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../../PageUtils";
import {
    BankAccountType,
    BasicInputSizes,
    FastEntryInputSizes,
    FieldType,
    Sort,
    TextAlign,
    ToolbarItemType,
    ValidatorType
} from "../../../enums";
import BindingContext from "../../../odata/BindingContext";
import { IFormDef } from "../../../views/formView/Form";
import BankStatementsFormView from "./BankStatementsFormView";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import { createBankAccountsItems, generalBankAccountFormatter } from "@utils/BankUtils";
import { ifAny, IGetValueArgs } from "@components/smart/FieldInfo";
import {
    AttachmentsAdditionalProperties,
    AttachmentsDef,
    CurrencyDef,
    ExchangeRatePerUnit,
    getCommonFilterDefs,
    getCommonTableColumnsDefs,
    NoteFieldDef,
    SymbolVariableDef
} from "@components/smart/GeneralFieldDefinition";
import {
    getNumberOursSummaryDef,
    getNumberRangeFieldDefs,
    NumberRangeAdditionalProperties
} from "../../numberRange/NumberRange.utils";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { TRecordAny, TValue } from "../../../global.types";
import { IFormatOptions } from "@odata/OData.utils";
import { setDefByEntityType } from "../../getDefByEntityType";
import { ClearedStatusCode, CurrencyCode, PostedStatusCode } from "@odata/GeneratedEnums";
import {
    BankStatementEntity,
    EntitySetName,
    EntityTypeName,
    IBankStatementEntity,
    IBankTransactionEntity,
    IFileMetadataEntity
} from "@odata/GeneratedEntityTypes";
import { ISplitPageTableDef } from "../../../views/table/TableView.utils";
import { getTransactionsRender, isFieldTransactionDisabled } from "./BankStatements.utils";
import CurrencyType, { formatCurrency, formatCurrencyVariableDecimals } from "../../../types/Currency";
import { removeInactiveAccounts } from "../bankAccounts/BankAccounts.utils";
import { FormStorage } from "../../../views/formView/FormStorage";
import { DOCUMENT_DATE_CHANGE_DEBOUNCE } from "../../documents/DocumentDef";
import { getFileNameExtension, isFileCsvOrGpc } from "@components/fileUploader/File.utils";
import { currencyScaleParser } from "../../../types/Number";
import { IBankStatementsCustomData, paymentDocDateValidator } from "../bankTransactions/BankTransactions.utils";
import {
    ISmartBankAccountFilterCustomData
} from "@components/smart/smartBankAccountFilter/SmartBankAccountFilter.utils";
import { ISelectItem } from "@components/inputs/select/BasicSelect";
import { isValidDate } from "@components/inputs/date/utils";
import { addBankAccountTableDefs } from "../../documents/DocumentCommonDefs";
import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { getCompanyCurrency } from "@utils/CompanyUtils";
import { Model } from "../../../model/Model";
import { getUtcDate } from "../../../types/Date";

const accountAdditionalFields = [{
    id: "TransactionCurrency"
}, {
    id: "BankCode"
}, {
    id: "AbaNumber"
}, {
    id: "Country"
}, {
    id: "IBAN"
}];

const hasPostedTransaction = (args: IGetValueArgs): boolean => {
    const transactions = args.data.Transactions || [];

    const postedTransaction = transactions.find((t: IBankTransactionEntity) => {
        return t.ClearedStatusCode === ClearedStatusCode.Cleared || t.ClearedStatusCode === ClearedStatusCode.PartiallyCleared;
    });

    return !!postedTransaction;
};

export const APPLY_BANKFILE_IMPORT = "applybankfileimport";

export const bankDependentFields = [
    {
        from: { id: "BankStatementNumberRangeDefinition" },
        to: { id: "BankAccount/BankStatementNumberRangeDefinition" }
    },
    { from: { id: "TransactionCurrency" }, to: { id: "BankAccount/TransactionCurrency" } },
    { from: { id: "BankCode" }, to: { id: "BankAccount/BankCode" } },
    { from: { id: "AbaNumber" }, to: { id: "BankAccount/AbaNumber" } },
    { from: { id: "Country" }, to: { id: "BankAccount/Country" } },
    { from: { id: "IBAN" }, to: { id: "BankAccount/IBAN" } },
    { from: { id: "Name" }, to: { id: "BankAccount/Name" } },
    { from: { id: "PaymentServiceID" }, to: { id: "BankAccount/PaymentServiceID" } }
];

export const OpeningBalancePath = BindingContext.localContext("OpeningBalance");
export const ClosingBalancePath = BindingContext.localContext("ClosingBalance");

const getBankStatementTransactionCurrency = ({ BankAccount }: IBankStatementEntity, context: IAppContext): CurrencyCode =>
    (BankAccount?.TransactionCurrencyCode ?? BankAccount?.TransactionCurrency?.Code ?? getCompanyCurrency(context)) as CurrencyCode;

const bankStatementAmountSummaryFormatter = (value: TValue, args: IFormatOptions) => {
    const currency = getBankStatementTransactionCurrency(args.entity, args.context);
    return formatCurrencyVariableDecimals((value as number) ?? 0, currency);
};

const bankStatementAmountTableFormatter = (value: TValue, args: IFormatOptions) => {
    const currency = getBankStatementTransactionCurrency(args.entity, args.context);
    return formatCurrency((value as number) ?? 0, currency);
};

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const filterBarDef: IFilterGroupDef[] = [{
        ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
        isValueHelp: true,
        defaultFilters: [
            "NumberOurs",
            "DateFrom",
            "TransactionsCount",
            "TransactionsTurnover",
            "BankAccount/AccountNumber",
            "BankAccount/Name"
        ],
        filterDefinition: {
            NumberOurs: {},
            Note: {},
            NumberTheirs: {},
            DateFrom: {},
            "BankAccount/Name": {
                label: i18next.t("Banks:Statements.AccountName")
            },
            ...getCommonFilterDefs(),
            TransactionsCount: {},
            TransactionsTurnover: {},
            CreditTurnover: {
                formatter: bankStatementAmountTableFormatter,
                filter: {
                    groupByProperties: [
                        "BankAccount/TransactionCurrencyCode"
                    ]
                }
            },
            DebitTurnover: {
                formatter: bankStatementAmountTableFormatter,
                filter: {
                    groupByProperties: [
                        "BankAccount/TransactionCurrencyCode"
                    ]
                }
            }
        }
    }];

    const table: ISplitPageTableDef = {
        filterBarDef,
        id: "BankStatements",
        lockProperty: BankStatementEntity.Locks,
        initialSortBy: [
            { id: "DateFrom", sort: Sort.Desc }
        ],
        columns: [
            "NumberOurs",
            "BankAccount",
            "BankAccount/Name",
            "TransactionsCount",
            "TransactionsTurnover",
            "DateFrom"
        ],
        filter: "IsVisible eq true",
        columnDefinition: {
            Note: {},
            NumberTheirs: {},
            ...getCommonTableColumnsDefs(),
            NumberOurs: {
                additionalProperties: [{ id: "/Transactions" }]
            },
            BankAccount: {
                additionalProperties: accountAdditionalFields.concat({ id: "AccountNumber" }, { id: "Name" }, { id: "CompanyBankAccountTypeCode" }, { id: "PaymentServiceID" }),
                formatter: generalBankAccountFormatter,
                label: i18next.t("Banks:Statements.AccountNumber")
            },
            "BankAccount/Name": {
                label: i18next.t("Banks:Statements.AccountName")
            },
            DateFrom: {},
            TransactionsCount: {},
            TransactionsTurnover: {
                additionalProperties: [{ id: "/BankAccount/TransactionCurrencyCode" }],
                formatter: (val: TValue, args?: IFormatOptions) => {
                    const currency = args.item?.BankAccount?.TransactionCurrencyCode;
                    return formatCurrency(val as number, currency);
                }
            },
            CreditTurnover: {
                formatter: bankStatementAmountTableFormatter,
                additionalProperties: [
                    { id: "/BankAccount/TransactionCurrency" }
                ]
            },
            DebitTurnover: {
                formatter: bankStatementAmountTableFormatter,
                additionalProperties: [
                    { id: "/BankAccount/TransactionCurrency" }
                ]
            }
        },
        title: i18next.t("Banks:Statements.Title")
    };

    const summary: ISummaryItem[] = [
        getNumberOursSummaryDef("Banks"),
        {
            id: BindingContext.localContext("TransactionCount"),
            formatter: (val: TValue, args: IFormatOptions) => {
                return args.storage.getCustomData<ISmartBankAccountFilterCustomData>().transactionCount || 0;
            },
            label: i18next.t("Banks:Statements.TransactionsCount")
        }, {
            id: "CreditTurnover",
            label: i18next.t("Banks:Statements.CreditTurnover"),
            updateFromLiveValue: true,
            formatter: bankStatementAmountSummaryFormatter,
            color: "C_SEM_text_good"
        }, {
            id: "DebitTurnover",
            label: i18next.t("Banks:Statements.DebitTurnover"),
            updateFromLiveValue: true,
            formatter: bankStatementAmountSummaryFormatter,
            color: "C_SEM_text_bad"
        }, {
            id: OpeningBalancePath,
            label: i18next.t("Banks:Statements.OpeningBalance"),
            textAlign: TextAlign.Right,
            updateFromLiveValue: true,
            formatter: (value: TValue, args: IFormatOptions) => {
                const { initialBalance } = args.storage.getCustomData<ISmartBankAccountFilterCustomData>();
                return bankStatementAmountSummaryFormatter(initialBalance, args);
            }
        }, {
            id: ClosingBalancePath,
            label: i18next.t("Banks:Statements.ClosingBalance"),
            textAlign: TextAlign.Right,
            updateFromLiveValue: true,
            additionalProperties: [{ id: "TransactionsTurnover" }],
            formatter: (value: TValue, args: IFormatOptions) => {
                const statementInitialBalance = args.storage.getCustomData<ISmartBankAccountFilterCustomData>().initialBalance ?? 0;
                const transactionsTurnover = args.storage.getEntity<IBankStatementEntity>().TransactionsTurnover ?? 0;
                return bankStatementAmountSummaryFormatter(statementInitialBalance + transactionsTurnover, args);
            }
        }
    ];

    const getDisabledText = (args: IGetValueArgs) => {
        const row = args.storage.getValue(args.bindingContext.getParent());
        const isLocked = !!row?.Lock?.Id;

        if (isLocked) {
            return args.storage.t("Banks:Transactions.TransactionLocked");
        }

        const isPosted = row?.PostedStatus?.Code === PostedStatusCode.Posted;
        if (isPosted) {
            return args.storage.t("Banks:Transactions.IsPosted");
        }

        return null;
    };

    const isPassiveAccount = (args: IGetValueArgs) => {
        return args.storage.data.entity.BankAccount?.IsActive === false;
    };

    const transactionDisabledProps = {
        isDisabled: isFieldTransactionDisabled,
        disabledText: getDisabledText
    };

    const getDisabledBankAccountText = (args: IGetValueArgs) => {
        if (isPassiveAccount(args)) {
            return i18next.t("Banks:Transactions.BankAccIsNotActive");
        }

        return i18next.t("Banks:Transactions.HasPostedTransaction");
    };

    const form: IFormDef = {
        id: "bankStatementForm",
        summary,
        files: AttachmentsDef,
        translationFiles: getDefinitions.translationFiles,
        formControl: BankStatementsFormView,
        lockProperty: BankStatementEntity.Locks,
        isDeletable: true,
        getCustomAttachmentFileActions: (file: IFileMetadataEntity) => {
            const firstFileExtension = getFileNameExtension(file?.Name);

            if (isFileCsvOrGpc(firstFileExtension)) {
                return [{
                    id: APPLY_BANKFILE_IMPORT,
                    label: i18next.t("Banks:Statements.ApplyImportFile"),
                    itemType: ToolbarItemType.Icon,
                    iconName: "Arrow"
                }];
            }
            return null;
        },
        getItemBreadCrumbText: (storage: Model) =>
            getItemBreadCrumbsText(storage, i18next.t("Banks:Statements:NewFormTitle"), storage.data.entity?.NumberOurs),
        title: "Banks:Statements.FormTitle",
        additionalProperties: [...NumberRangeAdditionalProperties, { id: "Transactions/TransactionAmount" },
            { id: "Transactions/SymbolVariable" }, { id: "Transactions/RemittanceInformation" },
            { id: "Transactions/DateBankTransaction" }, { id: `Transactions/${BankStatementEntity.Locks}` }, { id: "Transactions/PostedStatus" }, { id: "Transactions/PostedStatusCode" },
            // load properties that are add in BankStatementFormView.onBeforeSave,
            // so that during saving, compareEntities can recognize changed properties correctly,
            // and won't create unnecessary PATCH requests when not needed
            { id: "Transactions/Amount" }, { id: "Transactions/BankTransactionTypeCode" },
            { id: "Transactions/ClearedStatusCode" },
            { id: "Transactions/Company" }, { id: "Transactions/TransactionAmount" },
            ...AttachmentsAdditionalProperties,
            { id: "BankAccount/BankStatementNumberRangeDefinition" },
            { id: `${BankStatementEntity.Locks}/CreatedBy` },
            { id: `${BankStatementEntity.Locks}/Type` }
        ],
        fieldDefinition: {
            ...getNumberRangeFieldDefs("Banks"),
            BankAccount: {
                label: i18next.t("Banks:Statements.AccountNumber"),
                isDisabled: ifAny(hasPostedTransaction, isPassiveAccount),
                disabledText: getDisabledBankAccountText,
                additionalProperties: accountAdditionalFields.concat([{ id: "Name" }, { id: "PaymentServiceID" }, { id: "IsActive" }, { id: "Currency/MinorUnit" }]),
                type: FieldType.ComboBox,
                width: BasicInputSizes.XXL,
                affectedFields: [{
                    id: "Transactions"
                }, {
                    id: "BankAccount/TransactionCurrency"
                }, {
                    id: BindingContext.localContext("Sum")
                }],
                fieldSettings: {
                    preloadItems: true,
                    groups: [
                        { id: BankAccountType.Account },
                        { id: BankAccountType.ABA },
                        { id: BankAccountType.IBANOnly },
                        {
                            id: BankAccountType.Service,
                            title: i18next.t("Banks:Accounts.OtherServices")
                        }
                    ],
                    localDependentFields: bankDependentFields,
                    displayName: "AccountNumber",
                    shouldDisplayAdditionalColumns: true,
                    itemsFactory: async (args: IGetValueArgs): Promise<ISelectItem[]> => {
                        const accounts = (args.storage as FormStorage).context.getCompanyBankAccounts();
                        const items = createBankAccountsItems({ bankAccounts: accounts, addCurrency: true });
                        const statement = (args.storage.data.entity as IBankStatementEntity);
                        const accountId = statement.BankAccount?.Id;

                        return removeInactiveAccounts(items, accountId);
                    }
                },
                formatter: generalBankAccountFormatter,
                columns: [{
                    id: "AccountNumber",
                    formatter: generalBankAccountFormatter,
                    additionalProperties: accountAdditionalFields.concat([
                        { id: "Name" }, { id: "BankStatementNumberRangeDefinition" },
                        { id: "PaymentServiceID" }, { id: "IsActive" }, { id: "CompanyBankAccountTypeCode" }
                    ])
                }, {
                    id: "Name"
                }]
            },
            "BankAccount/TransactionCurrency": {
                type: FieldType.ComboBox,
                width: BasicInputSizes.S,
                fieldSettings: {
                    displayName: "Code",
                    shouldDisplayAdditionalColumns: true,
                    preloadItems: true
                },
                isReadOnly: true,
                additionalProperties: [{ id: "Name" }],
                columns: [
                    { id: "Code" },
                    { id: "Name" }
                ]
            },
            DateFrom: {
                isDisabled: hasPostedTransaction,
                disabledText: i18next.t("Banks:Transactions.HasPostedTransaction"),
                width: "130px",
                fieldSettings: {
                    debouncedWait: DOCUMENT_DATE_CHANGE_DEBOUNCE
                },
                defaultValue: () => {
                    return getUtcDate();
                },
                type: FieldType.Date
            },
            "Transactions/NumberOurs": {
                ...transactionDisabledProps,
                width: BasicInputSizes.L,
                defaultValue: "A",
                useForValidation: false
            },
            "Transactions/DateBankTransaction": {
                ...transactionDisabledProps,
                fieldSettings: {
                    workDate: (args: IGetValueArgs, obj: TRecordAny) => {
                        const rootDate = args.storage.data.entity.DateFrom;
                        return isValidDate(rootDate) ? rootDate : getUtcDate();
                    }
                },
                validator: {
                    type: ValidatorType.Custom,
                    settings: {
                        customValidator: paymentDocDateValidator
                    }
                },
                width: "150px"
            },
            "Transactions/TransactionCurrency": {
                isRequired: false,
                clearIfInvisible: false,
                isReadOnly: true
            },
            "Transactions/TransactionAmount": {
                ...transactionDisabledProps,
                width: FastEntryInputSizes.S,
                label: i18next.t("Banks:Transactions.Amount"),
                affectedFields: [{ id: BindingContext.localContext("Sum") }],
                parser: (value: TValue, args: IFormatOptions) => {
                    return currencyScaleParser(value as string, args.storage.data.entity.BankAccount?.TransactionCurrency);
                },
                fieldSettings: {
                    unit: (args: IGetValueArgs) => {
                        const currency = getBankStatementTransactionCurrency(args.storage.data.entity, args.context);
                        return CurrencyType.getCurrencyUnit(currency);
                    }
                }
            },
            "Transactions/Amount": {
                ...transactionDisabledProps,
                useForValidation: false,
                customizationData: {
                    useForCustomization: false
                },
                isRequired: false
            },
            "Transactions/Currency": {
                ...CurrencyDef,
                ...transactionDisabledProps,
                customizationData: {
                    useForCustomization: false
                },
                defaultValue: args => getCompanyCurrency(args.context)
            },
            "Transactions/ExchangeRatePerUnit": {
                ...ExchangeRatePerUnit,
                defaultValue: "",
                ...transactionDisabledProps,
                width: FastEntryInputSizes.S,
                label: i18next.t("Document:Form.CurrencyRate"),
                isVisible: (args: IGetValueArgs) => {
                    const currency = args.storage.data.entity.BankAccount?.TransactionCurrency?.Code;
                    return currency !== getCompanyCurrency(args.context);
                }
            },
            "Transactions/RemittanceInformation": {
                ...transactionDisabledProps,
                width: BasicInputSizes.XXL
            },
            "Transactions/PaymentInformation": {
                ...transactionDisabledProps
            },
            "Transactions/SymbolVariable": {
                ...SymbolVariableDef,
                ...transactionDisabledProps
            },
            Note: NoteFieldDef,
            NumberTheirs: {}
        },
        groups: [{
            id: "noteGroup",
            title: i18next.t("Common:General.Note"),
            rows: [[{ id: "Note" }]]
        }, {
            id: "mainGroup",
            title: i18next.t("Common:General.Information"),
            rows: [[{ id: "DateFrom" }, { id: "BankAccount" }, { id: "BankAccount/TransactionCurrency" }]]
        }, {
            id: "Transactions",
            isVisible: (args: IGetValueArgs) => {
                const isNew = args.storage.data.bindingContext.isNew();
                const hideTransactions = (args.storage as FormStorage<IBankStatementEntity, IBankStatementsCustomData>).getCustomData().hideTransactions;
                // if we open this form in bank transaction pairing dialog (available only for creating new) we hade transactions
                return !(isNew && hideTransactions);
            },
            title: i18next.t("Banks:AutomaticPairingDialog.BankTransactionsPairing"),
            lineItems: {
                isReadOnly: isPassiveAccount,
                additionalFields: [{ id: "Amount" }, { id: "NumberOurs" }],
                collection: "Transactions",
                showLineNumbers: true,
                isTheOnlyItemRemovable: true,
                order: "Order",
                render: getTransactionsRender,
                columns: [
                    { id: "DateBankTransaction" }, { id: "SymbolVariable" }, { id: "TransactionAmount" }, { id: "ExchangeRatePerUnit" }, { id: "RemittanceInformation" }
                ]
            }
        }]
    };

    const definition: IDefinition = {
        entitySet: EntitySetName.BankStatements,
        table,
        form
    };

    addBankAccountTableDefs(definition);

    return definition;
};

getDefinitions.translationFiles = ["Banks", "Document"];
setDefByEntityType(EntityTypeName.BankStatement, getDefinitions);