import ChartOfAccountsTableView from "./ChartOfAccountsTableView";
import { getDefinitions } from "./ChartOfAccountsDef";
import { withTranslation } from "react-i18next";
import ParentChildPage, { IParentPageProps } from "../ParentChildPage";
import { withRouter } from "react-router-dom";
import { withOData } from "@odata/withOData";
import { getCorrectPath } from "../../routes";
import { IEntity } from "@odata/BindingContext";
import { FiscalYearStatusCode } from "@odata/GeneratedEnums";

class ChartOfAccounts extends ParentChildPage<IParentPageProps> {
    static defaultProps = {
        tableView: ChartOfAccountsTableView,
        getDef: getDefinitions
    };

    redirectToFirstParent(): void {
        const parents = this.parents || [],
            activeId = this.getParentKey();

        if (parents.length) {
            if (!activeId) {
                const newUrl = getCorrectPath(this.props.match.path, {
                    ParentId: parents.find(parent => parent.FiscalYear.StatusCode === FiscalYearStatusCode.Active)?.Id ?? parents[0].Id
                });
                if (newUrl !== this.props.match.url) {
                    this.props.history.replace(newUrl);
                }
            }
            if (!parents.find((entity: IEntity) => entity.Id as string === activeId)) {
                // 404 - invalid EntityId in URL
                // todo: show error
            }
        }
    }
}

export default withRouter(withTranslation(["ChartsOfAccounts", "Common", "Components", "Error"])(withOData(ChartOfAccounts)));