import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import ElectronicSubmissionOverview from "./ElectronicSubmissionOverview";
import { Pane, SplitLayout } from "../../components/splitLayout";
import { CacheStrategy, PaneStatus } from "../../enums";
import { isDefined } from "@utils/general";
import VatSubmissionDetail from "./VatSubmissionDetail";
import {
    ElectronicSubmissionNamespaces,
    getElectronicSubmissionsMemoized,
    getSubmissionId,
    getSubmissionIdBy,
    getVatStatementFrequencyFromSubmission,
    isSubmissionLocked
} from "./ElectronicSubmission.utils";
import { IElectronicSubmissionEntity } from "@odata/GeneratedEntityTypes";
import dayjs, { Dayjs } from "dayjs";
import { getOldestActiveFY, getSortedFYs } from "../fiscalYear/FiscalYear.utils";
import { getCompanyVatStatementFrequencyCode } from "../companies/Company.utils";
import BusyIndicator from "../../components/busyIndicator";
import { withOData, WithOData } from "@odata/withOData";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { ElectronicSubmissionTypeCode, VatStatusCode } from "@odata/GeneratedEnums";
import { getSubmissionDetailRoute } from "@odata/EntityTypes";
import { getCompanyVatStatusesSorted } from "@utils/CompanyUtils";
import { getUtcDateBy, getUtcDayjs } from "../../types/Date";
import { isSameDay } from "@components/inputs/date/utils";

interface IRouteProps {
    year: string;
    type: string;
    month?: string;
}

interface IProps extends WithTranslation, WithOData, RouteComponentProps<IRouteProps> {
}

interface IState {
    busy: boolean;
    paneStatus: PaneStatus[];
    data?: Map<string, IElectronicSubmissionEntity>;
    firstVatPeriodDate?: Dayjs;
    lastVatPeriodDate?: Dayjs;
}

const defaultPaneStatus = [PaneStatus.Expanded, PaneStatus.Normal];
const detailOpenedPaneStatus = [PaneStatus.Normal, PaneStatus.Normal];

class ElectronicSubmission extends React.Component<IProps, IState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            busy: true,
            paneStatus: this.isDetailOpened ? detailOpenedPaneStatus : defaultPaneStatus
        };
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (!this.isDetailOpened && isDefined(prevProps.match.params.month)) {
            this.setPaneStatus(defaultPaneStatus);
        }
    }

    get year() {
        const { year } = this.props.match.params;
        const { lastVatPeriodDate = getUtcDayjs() } = this.state;
        return year ? parseInt(year) : lastVatPeriodDate?.year();
    }

    get vatFrequency() {
        const firstJanuaryInYear = getUtcDateBy(this.year);
        const firstSubmission = this.state.data.get(getSubmissionIdBy(ElectronicSubmissionTypeCode.VatStatement, firstJanuaryInYear));

        return firstSubmission ? getVatStatementFrequencyFromSubmission(firstSubmission)
                : getCompanyVatStatementFrequencyCode(this.context, firstJanuaryInYear);
    }

    get monthIndex() {
        const { month } = this.props.match.params;
        return month ? parseInt(month) - 1 : null;
    }

    get type() {
        return this.props.match.params.type as ElectronicSubmissionTypeCode;
    }

    get isDetailOpened() {
        return isDefined(this.props.match.params.month);
    }

    async loadData() {
        const { oData } = this.props;

        const rows = await getElectronicSubmissionsMemoized({ oData }, CacheStrategy.Route);
        const data = new Map<string, IElectronicSubmissionEntity>();

        let firstVatPeriodDate: Dayjs;
        let lastVatPeriodDate: Dayjs;
        let lastLockedVatPeriod: IElectronicSubmissionEntity;
        let firstUnlockedVatPeriod: IElectronicSubmissionEntity;

        rows.forEach(row => {
            if (row.ElectronicSubmissionTypeCode === ElectronicSubmissionTypeCode.VatStatement) {
                if (!firstVatPeriodDate || firstVatPeriodDate.isAfter(row.DatePeriodStart)) {
                    firstVatPeriodDate = getUtcDayjs(row.DatePeriodStart);
                }
                if (isSubmissionLocked(row)) {
                    if (!lastLockedVatPeriod || getUtcDayjs(lastLockedVatPeriod.DatePeriodStart).isBefore(row.DatePeriodStart)) {
                        lastLockedVatPeriod = row;
                    }
                } else {
                    if (!firstUnlockedVatPeriod || getUtcDayjs(firstUnlockedVatPeriod.DatePeriodStart).isAfter(row.DatePeriodStart)) {
                        firstUnlockedVatPeriod = row;
                    }
                }
            }

            data.set(getSubmissionId(row), row);
        });

        if (!firstVatPeriodDate) {
            const FY = getOldestActiveFY(this.context);
            const dateFromLastPeriod = getUtcDayjs(FY?.DateStart).subtract(1, "day");
            firstVatPeriodDate = this.getNextPeriodStart(dateFromLastPeriod.toDate());
        }

        if (firstUnlockedVatPeriod) {
            lastVatPeriodDate = this.getClosestVisiblePeriod(firstUnlockedVatPeriod);
        } else if (lastLockedVatPeriod) {
            lastVatPeriodDate = this.getNextPeriodStart(lastLockedVatPeriod.DatePeriodEnd);
            // in case user has some locked periods, but is no longer VAT registered, there is no next period
            // => show last locked period...
            if (!lastVatPeriodDate) {
                lastVatPeriodDate = dayjs(lastLockedVatPeriod.DatePeriodStart);
            }
        } else {
            lastVatPeriodDate = firstVatPeriodDate;
        }

        this.setState({
            data, busy: false,
            firstVatPeriodDate,
            lastVatPeriodDate
        });
    }

    getNextPeriodStart(prevPeriodEndDate: Date) {
        const preferred = getUtcDayjs(prevPeriodEndDate).add(1, "day");
        const preferredDate = preferred.toDate();
        const FYs = getSortedFYs(this.context);
        const lastFY = FYs[FYs.length - 1];
        if (preferred.isAfter(lastFY.DateEnd)) {
            return null;
        }
        const vatStatuses = getCompanyVatStatusesSorted(this.context.getCompany());
        const nextVatRegisteredStatus = vatStatuses.find(status => status.DateValidTo > preferredDate && status.VatStatusCode === VatStatusCode.VATRegistered);
        return nextVatRegisteredStatus ? dayjs.max(preferred, getUtcDayjs(nextVatRegisteredStatus.DateValidFrom)).startOf("month") : null;
    }

    getClosestVisiblePeriod(submission: IElectronicSubmissionEntity) {
        const previousPeriodEndDate = getUtcDayjs(submission.DatePeriodStart).subtract(1, "day");
        // find next period according to previous date as it could be the submission itself or next start of VatRegistered period
        return this.getNextPeriodStart(previousPeriodEndDate.toDate());
    }

    setPaneStatus(paneStatus: PaneStatus[]) {
        this.setState({ paneStatus: [...paneStatus] });
    }

    handlePaneStatusChange = (paneStatus: PaneStatus[]): void => {
        this.setPaneStatus(paneStatus);
    };

    handleSelectYear = (year: number) => {
        this.props.history.replace(getSubmissionDetailRoute(year));
    };

    handleSelectDetail = (type: ElectronicSubmissionTypeCode, month: number) => {
        this.props.history.replace(getSubmissionDetailRoute(this.year, type, month + 1));
        this.setPaneStatus(detailOpenedPaneStatus);
    };

    handleSubmissionChange = (submission: IElectronicSubmissionEntity, isRemoved = false): boolean => {
        const { data } = this.state;
        const key = getSubmissionId(submission);
        const current = data.get(key) ?? {};
        const updatedSubmission = {
            ...current,
            ...submission
        };
        const updatedData = new Map(data);
        if (isRemoved) {
            updatedData.delete(key);
        } else {
            updatedData.set(key, updatedSubmission);
        }

        let shouldCloseDetail = false;

        let { lastVatPeriodDate } = this.state;
        // in case IsLocked flag has changed, we need to update lastVatPeriodDate
        if (isSubmissionLocked(submission) !== isSubmissionLocked(current)) {
            // If last submission is locked, render next period as unlocked to let user work with it and create submission
            if (isSubmissionLocked(submission)) {
                if (lastVatPeriodDate?.isSame(updatedSubmission.DatePeriodStart, "day")) {
                    const nextPeriod = this.getNextPeriodStart(updatedSubmission.DatePeriodEnd);
                    if (nextPeriod) {
                        lastVatPeriodDate = nextPeriod;
                    }
                } else {
                    // there is no next period, close the detail
                    shouldCloseDetail = true;
                }
            } else {
                lastVatPeriodDate = this.getClosestVisiblePeriod(updatedSubmission);
                if (!isSameDay(lastVatPeriodDate, updatedSubmission.DatePeriodStart)) {
                    // close the detail in case next submission is not the actual one
                    shouldCloseDetail = true;
                }
            }
        }

        this.setState({ data: updatedData, lastVatPeriodDate });

        if (shouldCloseDetail) {
            // close the detail in next tick, so the detail can be unmounted properly
            setTimeout(() => {
                this.handleSelectYear(this.year);
            }, 0);

            return true;
        }

        return false;
    };

    renderDetailView() {
        switch (this.type) {
            case ElectronicSubmissionTypeCode.VatStatement:
                const submission = this.state.data.get(getSubmissionIdBy(this.type, getUtcDateBy(this.year, this.monthIndex, 1)));
                return (
                        <VatSubmissionDetail year={this.year}
                                             monthIndex={this.monthIndex}
                                             submission={submission}
                                             vatFrequency={this.vatFrequency}
                                             onSubmissionChange={this.handleSubmissionChange}/>
                );

            default:
                return null;
        }
    }

    render() {
        if (this.state.busy || !this.props.tReady || !this.year) {
            return (<BusyIndicator/>);
        }

        return (<>
            <SplitLayout onPaneStatusChanged={this.handlePaneStatusChange}
                         paneStatus={this.state.paneStatus}>
                <Pane visible icon="List" width="50%">
                    <ElectronicSubmissionOverview year={this.year}
                                                  month={this.monthIndex}
                                                  type={this.type}
                                                  initialHistoryState={this.props.history?.location?.state}
                                                  data={this.state.data}
                                                  firstVatPeriodDate={this.state.firstVatPeriodDate}
                                                  lastVatPeriodDate={this.state.lastVatPeriodDate}
                                                  vatFrequency={this.vatFrequency}
                                                  onSelectYear={this.handleSelectYear}
                                                  onSelectDetail={this.handleSelectDetail}/>
                </Pane>
                <Pane visible={this.isDetailOpened} icon="Form" width="50%">
                    {this.isDetailOpened && this.renderDetailView()}
                </Pane>
            </SplitLayout>
        </>);
    }
}

export default withOData(withRouter(withTranslation(ElectronicSubmissionNamespaces)(ElectronicSubmission)));
