import React from "react";
import { BreadCrumbProvider } from "../../components/breadCrumb/index";
import { StyledCompanyWizard, UsersWithPermissions, WizardButtonGroup } from "./CompanyWizard.styles";
import { AppContext, IAppContext, IAppContextData } from "../../contexts/appContext/AppContext.types";
import { WithTranslation, withTranslation } from "react-i18next";
import { Button } from "../../components/button";
import TestIds from "../../testIds";
import { FormFooter, SmartHeaderStyled } from "../../views/formView/FormView.styles";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { Status } from "../../enums";
import {
    ROUTE_CHARTS_OF_ACCOUNTS_TEMPLATES,
    ROUTE_CURRENCY_SETTINGS,
    ROUTE_FISCAL_YEAR,
    ROUTE_INITIAL_ACCOUNT_BALANCES,
    ROUTE_USERS
} from "../../routes";
import View from "../../views/View";
import { withAlert, WithAlert } from "@components/alert/withAlert";
import { WithOData, withOData } from "@odata/withOData";
import { WithBusyIndicator, withBusyIndicator } from "@components/busyIndicator/withBusyIndicator";
import {
    EntitySetName,
    ICompanySettingEntity,
    ICurrencyUsedByCompanyEntity,
    IFiscalYearEntity,
    IUserEntity
} from "@odata/GeneratedEntityTypes";
import SettingsList2, { ISettingItem2Props } from "../../components/settingsList2";
import { InputDescriptionText } from "@components/inputs/select/Select.styles";
import DateType, { getUtcDayjs } from "../../types/Date";
import { UserNameStyled } from "../admin/users/Users.styles";
import i18next from "i18next";
import { CompanyStateCode } from "@odata/GeneratedEnums";
import { arrayInsert, isDefined } from "@utils/general";
import { getUserNameInitials } from "../admin/users/Users.utils";
import { WithPermissionContext, withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { isAccountAssignmentCompany } from "@utils/CompanyUtils";
import UserInitial from "../../components/token/UserInitial";
import { withPromisedComponent, WithPromisedComponent } from "@components/dialog/withPromisedComponent";
import { InitialYearDialog } from "./InitialYearDialog";
import Checkbox, { ICheckboxChange } from "../../components/inputs/checkbox";

interface IProps extends WithBusyIndicator, WithOData, WithTranslation, RouteComponentProps, WithAlert, WithPermissionContext, WithPromisedComponent {
    onOk?: () => void;
    onCancel: () => void;
}

interface IState {
    isInitialBalancesSet: boolean;
    companySettings: ICompanySettingEntity;
    usersWithCompanyPermissions: IUserEntity[];
}

enum SettingsActions {
    FiscalPeriod = "FiscalPeriod",
    Currencies = "Currencies",
    ChartOfAccount = "ChartOfAccount",
    InitialBalances = "InitialBalances",
    Permissions = "Permissions",
    InitialYear = "InitialYear",
    CreateAccount = "CreateAccount" // create user account for company primary contact
}

class CompanyWizard extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;

    state: IState = {
        isInitialBalancesSet: null,
        usersWithCompanyPermissions: [],
        companySettings: null
    };

    componentDidMount() {
        this.load().then(() => this.forceUpdate());
    }

    load = async () => {
        // no need to wait, will change state when loaded
        this.loadCompanySettings();
        this.loadUsersWithCompanyPermissions();
    };

    loadCompanySettings = async (): Promise<void> => {
        const res = await this.props.oData.getEntitySetWrapper(EntitySetName.CompanySettings)
                .query()
                .expand("InitialAccountBalances")
            .top(1)
            .fetchData<ICompanySettingEntity[]>();

        const companySettings = res.value[0];
        this.setState({
            companySettings,
            isInitialBalancesSet: companySettings?.InitialAccountBalances?.length > 0
        });
    };

    loadUsersWithCompanyPermissions = async (): Promise<void> => {
        const companyId = this.context.getCompanyId();
        const res = await this.props.oData.getEntitySetWrapper(EntitySetName.Users)
            .query()
            .filter(`CompanyRoles/any(role: role/Company/Id eq ${companyId})`)
            .expand("CompanyRoles",
                (q => q.select("CompanyRole").top(1).filter(`Company/Id eq ${companyId}`).expand("CompanyRole",
                    (qq => qq.select("CompanyRoleName")))))
            .fetchData<IUserEntity[]>();

        this.setState({
            usersWithCompanyPermissions: res.value
        });
    };

    initializeCompany = async () => {
        const context = this.context as IAppContext;
        await this.props.oData.getEntitySetWrapper(EntitySetName.Companies)
            .update(this.context.getCompany().Id, {
                StateCode: CompanyStateCode.Initialized
            });

        await this.props.permissionContext.refreshUserPermissions();

        context.updateCompany(this.context.getCompany().Id, {
            StateCode: CompanyStateCode.Initialized
        });
        await Promise.all(context.updateCompanySpecificData());
    };

    handleActivation = async () => {
        let isOk = false;

        try {
            this.props.setBusy(true);
            await this.initializeCompany();
            isOk = true;
        } catch (e) {
            const errorTranslationKey = `Error:${e._validationMessages?.[0]?.code}`;
            this.props.setAlert({
                status: Status.Error,
                title: this.props.t("Common:Errors.ErrorHappened"),
                subTitle: i18next.exists(errorTranslationKey) ? this.props.t(errorTranslationKey) : null
            });
        }

        this.props.setBusy(false);

        if (isOk) {
            this.props.onOk?.();
        }
    };

    renderUserWithPermissions = (user: IUserEntity): React.ReactElement => {
        const shortCut = getUserNameInitials(user);

        return (
                <div key={user.Id}>
                    <UserInitial title={shortCut} tooltip={user.Name}/>
                    <UserNameStyled data-testid={TestIds.UserName}
                                    isBold={false}>{`${user.Name} (${user?.CompanyRoles?.[0]?.CompanyRole?.CompanyRoleName})`}</UserNameStyled>
                </div>
        );
    };

    handleInitializeUserChange = async (args: ICheckboxChange): Promise<void> => {
        this.setState({
            companySettings: {
                ...this.state.companySettings,
                InitializeUser: args.value
            }
        });
        await this.props.oData.getEntitySetWrapper(EntitySetName.CompanySettings)
                .update(this.state.companySettings.Id, { InitializeUser: args.value });
    }

    get items() {
        const data = this.context.getData() as IAppContextData;
        const fy: IFiscalYearEntity = data?.fiscalYears?.[0];

        let items: ISettingItem2Props[] = [{
            id: SettingsActions.Currencies,
            label: this.props.t("Companies:Wizard.Currencies"),
            content: data.currenciesUsedByCompany?.map((c: ICurrencyUsedByCompanyEntity) => c.FromCurrency.Name).join(", ")
        }, {
            id: SettingsActions.Permissions,
            label: this.props.t("Companies:Wizard.AgendaPermissions"),
            content: (
                    <UsersWithPermissions>
                        {this.state.usersWithCompanyPermissions.map(user => this.renderUserWithPermissions(user))}
                    </UsersWithPermissions>
            ),
            children: [{
                id: SettingsActions.CreateAccount,
                label: this.props.t("Companies:Wizard.CreateAccount", { email: data.company?.CommunicationContact?.Email }),
                content: <div
                        style={{ display: "flex", justifyContent: "flex-end", position: "relative", top: "-24px" }}>
                    <Checkbox
                            checked={this.state.companySettings?.InitializeUser ?? true}
                            onChange={this.handleInitializeUserChange}/>
                </div>,
                isWithoutButton: true
            }]
        }];

        if (isAccountAssignmentCompany(this.context)) {
            items = arrayInsert(items, {
                id: SettingsActions.FiscalPeriod,
                label: this.props.t("Companies:Wizard.FiscalYear"),
                content: fy ? <>{fy.Number}<InputDescriptionText>{` | ${DateType.format(fy.DateStart)}–${DateType.format(fy.DateEnd)}`}</InputDescriptionText></> : "",
            }, 0);

            items = arrayInsert(items, {
                id: SettingsActions.ChartOfAccount,
                label: this.props.t("Companies:Wizard.ChartOfAccounts"),
                content: fy?.ChartOfAccounts?.Name,
                children: [{
                    id: SettingsActions.InitialBalances,
                    label: this.props.t("Companies:Wizard.InitialAccountBalances"),
                    content: isDefined(this.state.isInitialBalancesSet) ? this.props.t(`Companies:Wizard.${this.state.isInitialBalancesSet ? "Set" : "NotSet"}`) : ""
                }]
            }, 2);
        } else {
            items = arrayInsert(items, {
                id: SettingsActions.InitialYear,
                label: this.props.t("Companies:Wizard.InitialYear"),
                content: this.state.companySettings?.InitialYear ?? getUtcDayjs().get("year"),
            }, 0);
        }

        return items;
    }

    handleChangeClick = async (id: string) => {
        const goTo = (route: string): void => {
            this.props.history.push(route);
        };

        switch (id) {
            case SettingsActions.FiscalPeriod:
                goTo(ROUTE_FISCAL_YEAR);
                break;
            case SettingsActions.Currencies:
                goTo(ROUTE_CURRENCY_SETTINGS);
                break;
            case SettingsActions.ChartOfAccount:
                goTo(ROUTE_CHARTS_OF_ACCOUNTS_TEMPLATES);
                break;
            case SettingsActions.InitialBalances:
                goTo(ROUTE_INITIAL_ACCOUNT_BALANCES);
                break;
            case SettingsActions.Permissions:
                goTo(ROUTE_USERS);
                break;
            case SettingsActions.InitialYear:
                const res = await this.props.promisedComponent.openComponent<number>((onFinish) => {
                    return (
                            <InitialYearDialog
                                    initialYear={this.state.companySettings?.InitialYear}
                                    onConfirm={onFinish}
                                    onCancel={() => onFinish(null)}/>
                    );
                });
                if (res) {
                    this.setState({
                        companySettings: {
                            ...this.state.companySettings,
                            InitialYear: res
                        }
                    });
                    await this.props.oData.getEntitySetWrapper(EntitySetName.CompanySettings)
                            .update(this.state.companySettings.Id, { InitialYear: res });
                }
                break;
        }
    };

    render() {
        return (
            <>
                <BreadCrumbProvider/>
                <View hotspotContextId={"companyWizard"}>
                    <StyledCompanyWizard data-testid={TestIds.Wizard}>
                        <SmartHeaderStyled shouldHideVariant
                                           title={this.props.t("Companies:Wizard.Title")}/>
                        {this.props.alert}
                        <SettingsList2
                            items={this.items}
                            onChangeClick={this.handleChangeClick}
                        />
                        <FormFooter data-testid={TestIds.FormFooter}>
                            <WizardButtonGroup>
                                <Button isTransparent
                                        onClick={this.props.onCancel}>{this.props.t("Companies:Wizard.SetNextTime")}</Button>
                                <Button
                                    onClick={this.handleActivation}>{this.props.t("Common:General.Activate")}</Button>
                            </WizardButtonGroup>
                        </FormFooter>
                    </StyledCompanyWizard>
                </View>
            </>
        );
    }
}


export default withTranslation(["Companies", "Error", "Common"])(withPromisedComponent(withPermissionContext(withRouter(withOData(withBusyIndicator()(withAlert()(CompanyWizard)))))));