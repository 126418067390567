import styled from "styled-components/macro";
import { CaretIcon } from "@components/icon";
import { T_HEADER_small } from "../../global.style";
import { IconButton } from "@components/button";

export const YearSwitcherStyled = styled.div`
    display: flex;
    align-items: self-end;
`;

export const CaretIconLeft = styled(CaretIcon)`
    transform: rotate(90deg);
`;

export const CaretIconRight = styled(CaretIcon)`
    transform: rotate(270deg);
`;

export const Year = styled.div`
    ${T_HEADER_small};
    position: relative;
    user-select: none;
`;

export const Line = styled.div`
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${props => props.theme.C_BTN_4L_emph_bg_selected};
`;

export const IconWrapperLeft = styled(IconButton) `
    margin-right: 8px;
`;

export const IconWrapperRight = styled(IconButton) `
    margin-left: 8px;
`;