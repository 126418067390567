import React from "react";
import memoizeOne from "../../utils/memoizeOne";
import { ICellValueObject, IMetaColumn, IRow, TColumn, TId } from "./Table";
import { getValue, isDefined, TabIndex } from "@utils/general";
import { CaretIconStyle } from "./Table.styles";
import { IconSize } from "../../enums";
import { IconButton } from "../button";
import i18next from "i18next";

export const isMetaColumn = (column: TColumn): column is IMetaColumn => {
    return !!(column as IMetaColumn).columns;
};

export const isRowValueCellValueObject = (value: unknown): value is ICellValueObject => {
    const realValue = getValue(value);

    return !!(isDefined(realValue) && typeof realValue === "object" && 'value' in realValue && 'tooltip' in realValue);
};

export const getLeavesColumns = memoizeOne((columns: TColumn[]): any[] => {
    const leavesColumns = [];
    const stack = [...columns];

    while (stack.length > 0) {
        const currentColumn = stack.shift();

        if (!isMetaColumn(currentColumn)) {
            leavesColumns.push(currentColumn);
        } else {
            stack.unshift(...currentColumn.columns);
        }
    }

    return leavesColumns;
});

export function isRowSelected(selectedRows: TId[], rowId: TId): boolean {
    return !!(selectedRows ?? []).find(id => id.toString() === rowId.toString());
}

export const isTableWithoutHeader = (columns: TColumn[]): boolean => {
    return !columns.find(col => col.label);
};

export function getAction(row: IRow, isForPrint: boolean): React.ReactElement {
    if (!row.drilldown || isForPrint) {
        return null;
    }

    // apparently, during action te drilldown button is supposed to have 0.5 opacity
    // but it's different from when row is disabled
    let Action: React.ReactElement;
    if (typeof row.drilldown === "function") {
        Action = row.drilldown(row);
    } else {
        Action = typeof row.drilldown !== "boolean" ? row.drilldown
            : (<CaretIconStyle width={IconSize.M} height={IconSize.M}/>);
    }

    if (!Action) {
        // case can still return null
        return null;
    }

    return Action.type === "a" ? Action : (
        <IconButton title={i18next.t("Components:Table.Drilldown")}
                    isDecorative
                    tabIndex={TabIndex.Disabled}>
            {Action}
        </IconButton>
    );
}