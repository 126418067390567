import React from "react";
import Dialog, { IDialogProps } from "./Dialog";
import ConfirmationButtons from "../../views/table/ConfirmationButtons";

interface IProps extends IDialogProps {
    confirmText?: string;
}

export class ConfirmationDialog extends React.PureComponent<IProps> {
    render() {
        return (
                <Dialog {...this.props} isConfirmation
                        footer={<ConfirmationButtons confirmText={this.props.confirmText}
                                                     onConfirm={this.props.onConfirm} onCancel={this.props.onClose}
                                                     useWrapper={false}/>}>
                    {this.props.children}
                </Dialog>
        );
    }
}