import styled, { css } from "styled-components/macro";
import Alert from "../../components/alert";
import { StyledButton, StyledButtonGroup } from "@components/button";
import Tabs from "../../components/tabs/Tabs";
import { TableWrapper } from "../table/TableView.styles";
import SmartHeader from "../../components/smart/smartHeader";
import { OneLinerHeight } from "../../components/alert/Alert.styles";
import { VIEW_PADDING_VALUE } from "../View.styles";

export const FormFooter = styled.div<{
    hasTopMargin?: boolean;
}>`
    display: flex;
    /* Top margin reserves space for possible alert, but do we need this always (e.g. in read-only state)? */
    margin-top: ${props => props.hasTopMargin === false ? 0 : "22px"};
    justify-content: flex-end;

    ${StyledButton} {
        margin-bottom: 38px;
    }

    ${StyledButtonGroup} {
        justify-content: flex-end;
    }
`;

export const FormContent = styled.form.attrs({
    autocomplete: "off"
})<{ withoutPadding?: boolean }>`
    position: relative;
    ${props => !props.withoutPadding && css`
        padding-bottom: ${VIEW_PADDING_VALUE}px;
    `}
`;

export const FormHeaderTopMargin = 12;

export const SmartHeaderStyled = styled(SmartHeader) <{
    hideBreadcrumbs?: boolean
}>`
`;

export const FormAlertContainer = styled(Alert)`
    margin-bottom: 30px;
`;

export const StyledSuccessWrapper = styled.div`
    position: absolute;
    width: 100%;

    pointer-events: none; // otherwise cannot click on the add button BEHIND IT

    z-index: 500;
    bottom: ${(60 - OneLinerHeight) / 2 - 22}px; // show just below the form, in the middle of the space
`;

export const FormTabs = styled(Tabs) <{
    hasTitle?: boolean;
    isFirst?: boolean;
}>`
    margin-top: ${props => props.isFirst ? 0 : props.hasTitle ? "35px" : "65px"};

    ${TableWrapper} {
        margin-top: 0;
    }
`;