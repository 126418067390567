import React, { useCallback, useContext } from "react";
import { LinkProps } from "react-router-dom";
import { LinkStyled, StyledDashboardTileBackground } from "./Dashboard.styles";
import { Theme } from "../../theme";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { enhanceLinkWithDrilldownParams, THistoryLocation } from "../drillDown/DrillDown.utils";

interface IProps extends Partial<Pick<LinkProps, "to">> {
    inEditMode?: boolean;
    backgroundColor?: keyof Theme;
    className?: string;
    isLoading?: boolean;
    hideBackground?: boolean;
    overflowed?: boolean;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const DashboardTileBackground: React.FC<IProps> = (props) => {
    const { inEditMode, backgroundColor, to, onClick, className, children } = props;
    const context = useContext(AppContext);

    const handleDragStart = useCallback((e: React.DragEvent<HTMLAnchorElement>) => {
        e.preventDefault();
    }, []);

    const handleClick = useCallback((e) => {
        if (inEditMode) {
            e.preventDefault();
        } else {
            onClick?.(e);
        }
    }, [inEditMode, onClick]);

    const handleClickOnBg = onClick && !to;

    const content = (
        <StyledDashboardTileBackground className={className}
                                       $backgroundColor={backgroundColor}
                                       $overflowed={props.overflowed}
                                       $isLoading={props.isLoading}
                                       $cursor={handleClickOnBg || to ? "pointer" : "inherit"}
                                       onClick={handleClickOnBg ? handleClick : undefined}
                                       $hideBackground={props.hideBackground}>
            {children}
        </StyledDashboardTileBackground>
    );

    if (to) {
        return (
                <LinkStyled to={enhanceLinkWithDrilldownParams(to as THistoryLocation, context)}
                        $isEdit={inEditMode}
                        onDragStart={handleDragStart}
                        onClick={handleClick}>
                {content}
            </LinkStyled>
        );
    }

    return content;
};

export default DashboardTileBackground;