import styled from "styled-components/macro";
import { ellipsis } from "polished";
import { TooltipIconCopyLink } from "../tooltipIcon";
import { VIEW_PADDING_VALUE } from "../../views/View.styles";

export const TooltipIconCopyLinkBreadcrumb = styled(TooltipIconCopyLink)`
    visibility: hidden;
`;

export const BreadCrumbWrapper = styled.div`
    position: absolute;     
    display: flex;
    align-items: center;
    
    top: 10px;
    left: ${VIEW_PADDING_VALUE}px;
    // provide space for the maximize and close icon in split page
    right: 93px;
    
    &:hover ${TooltipIconCopyLinkBreadcrumb} {
        visibility: visible;
    } 
`;

export const StyledBreadCrumb = styled.ul`
    position: relative;   
    padding: 0;
    margin: 0;
    // truncate text (ellipsis) from left side instead of right
    direction: rtl;
    unicode-bidi: isolate-override;
    ${ellipsis()};
`;

export const ClickIconWrapper = styled.div`
    
`;