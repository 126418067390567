import React from "react";
import { IDefinition, IGetDefinition } from "../PageUtils";
import { ISplitPageTableDef } from "../../views/table/TableView.utils";
import i18next from "i18next";
import { IconSize, ODataFilterFunction, Sort, TextAlign } from "../../enums";
import { EntitySetName, EntityTypeName, IInboxFileEntity, InboxFileEntity } from "@odata/GeneratedEntityTypes";
import { setDefByEntityType } from "../getDefByEntityType";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { TValue } from "../../global.types";
import { IFormatOptions } from "@odata/OData.utils";
import { fileNameFormatter, strongTextFormatter } from "@components/fileUploader/File.utils";
import { ICellValueObject, TCellValue } from "@components/table";
import { NoAttachmentText } from "./Inbox.styles";
import { smartStrongTextFormatter, TFormatterFn } from "@components/smart/smartTable/SmartTable.utils";
import { ApprovalStatusTypeCode, InboxFileSourceCode } from "@odata/GeneratedEnums";
import { emailFormatter } from "@components/smart/GeneralFieldDefinition";
import { StatusIsdocIcon, StatusNoDataReadIcon, StatusRossumIcon } from "@components/icon";
import { isFileRossumEligible } from "@utils/Rossum.utils";
import { TSmartODataTableStorage } from "@components/smart/smartTable/SmartODataTableBase";
import {
    getStatusTooltipFromApprovalStatus,
    IInboxTableCustomData,
    isRossumModuleActivated,
    isSupportedFolderForDataMining
} from "./Inbox.utils";


function inboxFileNameFormatter(value: TValue, { entity }: IFormatOptions<IInboxFileEntity>): TCellValue {
    let cell: ICellValueObject;
    if (!entity?.FileMetadata?.Id) {
        const text = i18next.t("Inbox:TableView.WithoutAttachment");
        cell = {
            tooltip: text,
            value: (
                <NoAttachmentText>{text}</NoAttachmentText>
            )
        };
    } else {
        cell = fileNameFormatter(value);
    }
    const status = entity?.ApprovalStatusTypeCode as ApprovalStatusTypeCode;
    if (entity.IsSorted && [ApprovalStatusTypeCode.Rejected, ApprovalStatusTypeCode.Pending].includes(status)) {
        cell.tooltip = getStatusTooltipFromApprovalStatus(status);
        cell.onlyShowTooltipWhenChildrenOverflowing = false;
    }
    return cell;
}

const dataRetrievalFormatter: TFormatterFn = (value: TValue, options: IFormatOptions<IInboxFileEntity>): TCellValue => {
    const inboxFile = options.entity;
    const { FileMetadata } = inboxFile;
    const storage = options.storage as TSmartODataTableStorage<IInboxTableCustomData>;
    const showIcon = !inboxFile.IsSorted || isSupportedFolderForDataMining(inboxFile.EntityTypeCode);

    let titleKey, Icon;
    switch (true) {
        case showIcon && FileMetadata.IsIsdocReadable:
            titleKey = "HasIsdoc";
            Icon = StatusIsdocIcon;
            break;
        case showIcon && isRossumModuleActivated(storage) && isFileRossumEligible(FileMetadata):
            titleKey = "HasRossum";
            Icon = StatusRossumIcon;
            break;
        default:
            titleKey = "NoDataRead";
            Icon = StatusNoDataReadIcon;
    }

    const title = i18next.t(`Inbox:TableView.${titleKey}`);

    return {
        tooltip: title,
        value: <Icon title={title} width={IconSize.XL} height={IconSize.M}/>
    };
};

export function subjectFormatter(value: TValue, options: IFormatOptions): TCellValue {
    if (!value && [InboxFileSourceCode.CustomerPortal, InboxFileSourceCode.UploadedByAccountant].includes(options.entity?.InboxFileSourceCode)) {
        const subject = i18next.t(`Inbox:TableView.${options.entity?.InboxFileSourceCode}Subject`);
        return {
            tooltip: `${subject}`,
            value: (
                <em>{subject}</em>
            )
        };
    }
    return strongTextFormatter(value);
}

export const InboxFilterId = "Sender";

export const getDefinitions: IGetDefinition = (): IDefinition => {

    const filterBarDef: IFilterGroupDef[] = [{
        ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
        defaultFilters: [InboxFilterId],
        filterDefinition: {
            /* Note: InboxTableView doesn't display real filterBar. But we use this definition
               to show custom fulltext filter */
            Sender: {
                id: InboxFilterId,
                isValueHelp: false,
                filterName: ["", `/FileMetadata/Name`],
                filter: {
                    stringFilterFn: ODataFilterFunction.Contains
                }
            }
        }
    }];

    const table: ISplitPageTableDef = {
        id: "InboxFile",
        columns: [
            "FileMetadata/Name", "FileMetadata/IsIsdocReadable", "Sender", "DateReceived", "Subject"
        ],
        columnDefinition: {
            "FileMetadata/Name": {
                formatter: inboxFileNameFormatter
            },
            "FileMetadata/IsIsdocReadable": {
                label: i18next.t("Inbox:TableView.DataRetrievalLabel"),
                formatter: dataRetrievalFormatter,
                textAlign: TextAlign.Center,
                additionalProperties: [{ id: "/EntityTypeCode" }, { id: "/FileMetadata/IsRossumReadable" }]
            },
            Sender: {
                formatter: (value: TValue, opts: IFormatOptions) => {
                    let name: string = null;
                    if ([InboxFileSourceCode.UploadedByAccountant, InboxFileSourceCode.CustomerPortal].includes(opts.entity?.InboxFileSourceCode)) {
                        name = opts.entity?.CreatedBy?.Name;
                    }
                    return emailFormatter(value, { customArgs: { name } });
                },
                additionalProperties: [{ id: "/CreatedBy/Name" }]
            },
            DateReceived: {
                formatter: smartStrongTextFormatter,
                additionalProperties: [{ id: "/ApprovalStatusTypeCode" }]
            },
            Subject: {
                formatter: subjectFormatter,
                additionalProperties: [{ id: "/InboxFileSourceCode" }]
            }
        },
        additionalProperties: [{ id: InboxFileEntity.IsSorted }],
        initialSortBy: [{
            id: "DateReceived", sort: Sort.Desc
        }],
        filterBarDef,
        preventStoreVariant: true
    };

    return {
        entitySet: EntitySetName.InboxFiles,
        table
    };
};

getDefinitions.translationFiles = ["Inbox", "Common"];
setDefByEntityType(EntityTypeName.InboxFile, getDefinitions);
