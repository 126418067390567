import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { FormStorage } from "../../views/formView/FormStorage";
import SendMessageDialog, { IDataBoxSettings, IEmailSettings, IFileSettings } from "../messages/SendMessageDialog";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { DATABOX_REST_URL, REST_API_URL } from "../../constants";
import { ISelectItem } from "@components/inputs/select/BasicSelect";
import BindingContext, { IEntity } from "../../odata/BindingContext";
import { WithOData, withOData } from "@odata/withOData";
import { EntitySetName, EntityTypeName, IBusinessPartnerEntity } from "@odata/GeneratedEntityTypes";
import { getDocumentExportFileName, getInvoicePdfUrl } from "./Document.utils";
import customFetch from "../../utils/customFetch";
import { IDocumentCustomData } from "./DocumentInterfaces";
import { LanguageCode } from "@odata/GeneratedEnums";

interface IProps extends WithTranslation, WithOData {
    storage: FormStorage<any, IDocumentCustomData>;
    onAfterSend: () => void;
}

interface IState {
    emailSettings: IEmailSettings;
    dataBoxSettings: IDataBoxSettings;
    fileSettings?: IFileSettings[];
}

const DISPATCH_DOCUMENT_URL = `${REST_API_URL}/Dispatch/Document`;

class SendInvoiceDialog extends React.Component<IProps, IState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;
    static findDataBoxIdByLegalUrl = `${DATABOX_REST_URL}/getId/`;

    state: IState = {
        emailSettings: {
            sendMessageUrl: `${DISPATCH_DOCUMENT_URL}/email`
        },
        dataBoxSettings: {
            sendMessageUrl: `${DISPATCH_DOCUMENT_URL}/databox`
        }
    };

    get message() {
        return (<>Vážená paní / Vážený pane,<br/><br/>
        vystavili jsme Vám fakturu.<br/><br/></>);
    }

    getEmailSettings = async () => {
        const id = this.props.storage?.data.entity?.BusinessPartner?.BusinessPartner?.Id;
        const selectGroups = [];
        const contacts: ISelectItem<string>[] = [];
        const groupId = "group1";

        function _add(data: IEntity) {
            if (data.Email) {
                const name = [data.FirstName, data.LastName].filter(i => !!i).join(" ");
                contacts.push({
                    id: data.Email,
                    label: data.Email,
                    groupId,
                    tabularData: [data.Email, name, data.Role],
                });
            }
        }

        if (id) {
            const result = await this.props.storage.oData.getEntitySetWrapper(EntitySetName.BusinessPartners).query()
                .filter(`Id eq ${id}`)
                .expand("Contacts",
                    g => g.select("Email", "FirstName", "LastName", "Role")
                        .orderBy("Order"))
                .top(1)
                .fetchData<IBusinessPartnerEntity[]>();

            const businessPartner = result.value[0];

            if (businessPartner.Contacts) {
                for (const contact of businessPartner.Contacts) {
                    _add(contact);
                }
            }

            if (contacts.length) {
                selectGroups.push({
                    id: groupId,
                    title: businessPartner.Name,
                    hideDivider: true
                });
            }
        }

        return {
            ...this.state.emailSettings,
            selectGroups,
            selectItems: contacts
        };
    };

    getDataBoxSettings = async () => {
        const legalNumber = this.props.storage?.data.entity?.BusinessPartner?.LegalNumber;
        const response = await customFetch(`${SendInvoiceDialog.findDataBoxIdByLegalUrl}${legalNumber}`);
        let defaultId = null;
        if (response.ok) {
            defaultId = await response.text();
        }

        return {
            ...this.state.dataBoxSettings,
            defaultId
        };
    };

    handleCloseDialog = () => {
        this.props.storage.setCustomData({ sendingMessageDialogOpen: false });
        this.props.storage.refresh();
    };

    onAfterOpen = async () => {
        const { entity } = this.props.storage?.data ?? {};
        if (!entity) {
            return;
        }
        const [emailSettings, fileName] = await Promise.all([this.getEmailSettings(), getDocumentExportFileName(this.context, "document", entity.Id)]);
        const dataBoxSettings = await this.getDataBoxSettings();

        const fileSettings = [{
            previewUrl: getInvoicePdfUrl({
                documentType: this.props.storage.data.bindingContext.getEntityType().getName() as EntityTypeName,
                entityId: this.props.storage?.data.entity?.Id,
                companyId: this.context.getCompany().Id,
                isForPrint: false,
                language: LanguageCode.Czech
            }),
            name: fileName,
            fileId: BindingContext.NEW_ENTITY_ID_PROP as unknown as number
        }];
        this.setState({ emailSettings, dataBoxSettings, fileSettings });
    };

    render = () => {
        if (!this.props.tReady) {
            return null;
        }
        const company = this.context.getCompany();
        const subject = `${company.LegalName}: ${this.props.t("DataBox:SendDialog.Invoice")} ${this.props.storage?.data.entity?.NumberOurs}`;
        const additionalRequestData = {
            DocumentId: this.props.storage.data.entity.Id
        };

        return (
            <SendMessageDialog
                title={this.props.t("DataBox:SendDialog.SendInvoice")}
                message={this.message}
                addSignature={true}
                subject={subject}
                onAfterSend={this.props.onAfterSend}
                handleClose={this.handleCloseDialog}
                emailSettings={this.state.emailSettings}
                dataBoxSettings={this.state.dataBoxSettings}
                fileSettings={this.state.fileSettings}
                onAfterOpen={this.onAfterOpen}
                additionalRequestData={additionalRequestData}
            />
        );
    };
}

export default withTranslation(["DataBox"], {withRef: true})(withOData(SendInvoiceDialog));