import React from "react";
import { Circle, InnerCircle, Label, StyledRadioButton, StyledRadioButtonGroup } from "./RadioButtonGroup.styles";
import { RadioButtonGroupLayout } from "../../../enums";
import { KeyName } from "../../../keyName";
import TestIds from "../../../testIds";
import FocusManager, { FocusDirection, IFocusableItemProps } from "../../focusManager/FocusManager";
import { isDefined } from "@utils/general";
import { DescriptionSeparator, InputDescriptionText } from "../select/Select.styles";

export interface IRadioButtonDefinition {
    id: string;
    label?: string | React.ReactElement;
    isDisabled?: boolean;
    description?: string;
}

interface IRadioButtonProps {
    label?: string | React.ReactElement;
    description?: string;
    onChecked: (id: string) => void;
    id?: string;
    checked: boolean;
    isDisabled?: boolean;
    itemProps?: IFocusableItemProps;
    className?: string;
}

export class RadioButton extends React.Component<IRadioButtonProps> {
    _refCircle = React.createRef<HTMLDivElement>();

    select = (event: React.MouseEvent): void => {
        if (!this.props.isDisabled) {
            // for cases when user clicks the label not the button itself
            this._refCircle.current.focus();
            if (!this.props.checked) {
                this.props.onChecked(this.props.id);
            }
        }
    };

    handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === KeyName.Space) {
            this.props.onChecked(this.props.id);
        }
    };

    render() {
        const itemProps = this.props.isDisabled ? {} : this.props.itemProps;
        return (
            <StyledRadioButton role={"radio"}
                               tabIndex={0}
                               {...itemProps}
                               aria-checked={this.props.checked}
                               _disabled={this.props.isDisabled}
                               onKeyDown={this.handleKeyDown}
                               onClick={this.select}
                               className={this.props.className}
                               data-testid={TestIds.RadioButton}
            >
                <Circle ref={this._refCircle}
                        _disabled={this.props.isDisabled}
                        _checked={this.props.checked}>
                    {this.props.checked &&
                        <InnerCircle/>
                    }
                </Circle>
                {!!this.props.label &&
                <Label data-testid={TestIds.RadioButtonLabel}>
                    {this.props.label}
                    {!!this.props.description &&
                        <InputDescriptionText>{DescriptionSeparator}{this.props.description}</InputDescriptionText>
                    }
                </Label>
                }
            </StyledRadioButton>
        );
    }
}

export interface IProps {
    definition: IRadioButtonDefinition[];
    defaultChecked?: string;
    layout?: RadioButtonGroupLayout;
    /** If undefined RadioButtonGroup will behave as uncontrolled component.
     * If set to true/false RadioButtonGroup will behave as controlled component, showing the value coming from this prop. */
    checkedButton?: string;
    onChange?: (id: string) => void;
    style?: React.CSSProperties;
}

interface IState {
    checkedButton?: string;
}

export default class RadioButtonGroup extends React.Component<IProps, IState> {
    static defaultProps = {
        layout: RadioButtonGroupLayout.Row
    };

    constructor(props: IProps) {
        super(props);
        this.state = {
            checkedButton: props.defaultChecked !== undefined ? props.defaultChecked : props.definition && props.definition[0] && props.definition[0].id
        };
    }

    handleChecked = (key: string): void => {
        if (!isDefined(this.props.checkedButton)) {
            this.setState({
                checkedButton: key
            });
        }

        this.props.onChange?.(key);
    };

    getCheckedButtonId = (): string => {
        return isDefined(this.props.checkedButton) ? this.props.checkedButton : this.state.checkedButton;
    };

    render() {
        const checkedButton = this.getCheckedButtonId();

        return (
            <FocusManager
                direction={this.props.layout === RadioButtonGroupLayout.Row ? FocusDirection.Horizontal : FocusDirection.Vertical}>
                {({ itemProps, wrapperProps }) => (
                    <StyledRadioButtonGroup role={"radiogroup"} {...wrapperProps as any}
                                            _layout={this.props.layout}
                                            style={this.props.style}
                                            data-testid={TestIds.RadioButtonGroup}>
                        {this.props.definition.map(button => {
                            return (
                                <RadioButton itemProps={itemProps}
                                             isDisabled={button.isDisabled}
                                             checked={checkedButton === button.id}
                                             label={button.label}
                                             description={button.description}
                                             key={button.id}
                                             id={button.id}
                                             onChecked={this.handleChecked}/>
                            );
                        })}
                    </StyledRadioButtonGroup>
                )}
            </FocusManager>
        );
    }
}