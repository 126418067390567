import styled, { css } from "styled-components/macro";
import { getDisabledStyle, LocalStackOrder, T_TITLE_4 } from "../../global.style";
import { mix } from "polished";
import { FIELD_VER_MARGIN } from "../inputs/field/Field.styles";
import SmartField from "../smart/smartField/SmartField";
import { AuditTrailLineComparison } from "../../model/Model";
import TestIds from "../../testIds";
import { ButtonGroup, IconButton } from "../button";
import FormGroupActions from "./FormGroupActions";
import { TableWrapper } from "../../views/table/TableView.styles";
import { StyledFieldsWrapper } from "../inputs/field/FieldsWrapper.styles";
import { StyledSmartFastEntryList } from "../smart/smartFastEntryList/SmartFastEntryList.styles";
import { Separator } from "../separator/Separator";
import { StyledLabel } from "../inputs/field/Label.styles";
import { Label } from "../inputs/switch/Switch.styles";
import { CheckboxLabel } from "../inputs/checkbox/Checkbox.styles";
import Switch from "../inputs/switch";

export const StyledFormGroup = styled.fieldset<{
    auditTrailType: AuditTrailLineComparison;
    isSmallBottomMargin: boolean;
    isFlex?: boolean;
}>`
    border: none;
    padding: 0;
    margin: 0;
    margin-bottom: ${props => props.isSmallBottomMargin ? FIELD_VER_MARGIN : "30px"};

    &:last-of-type {
        margin-bottom: 0;
    }

    // fix weird bug 
    // https://stackoverflow.com/questions/17408815/fieldset-resizes-wrong-appears-to-have-unremovable-min-width-min-content/17863685
    min-width: 0;
    display: table-cell;

    ${props => props.isFlex && css`
        display: flex;
        flex-direction: column;
    `};

    position: relative;
    ${props => props.auditTrailType === AuditTrailLineComparison.MissingRow && `
        & > div:not([data-testId=${TestIds.FormGroupAuditTrailLayer}]){
            opacity: 0
        }
     `};

    ${StyledFieldsWrapper} + ${StyledSmartFastEntryList} {
        margin-top: ${FIELD_VER_MARGIN};
    }
`;

export const AuditTrailLayer = styled.div`
    position: absolute;
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    border-radius: 15px;
    border: 3px dashed ${props => props.theme.C_AUDIT_TRAIL_DIFF};
    z-index: ${LocalStackOrder.Overlay};
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    position: relative;

    button {
        margin-left: 6px;
    }
`;

export const Title = styled.legend`
    padding: 0;
    flex-shrink: 0;
    ${T_TITLE_4};
`;

export const SeparatorStyledFormGroup = styled(Separator)`
    margin: 0 0 0 12px;
    flex: 1 1 auto;
`;

export const ExpandIconParent = styled.div`
    display: flex;
`;

export const CreatingTitle = styled.div`
    align-self: flex-end;
    margin-bottom: 9px;

    ${T_TITLE_4};
    color: ${props => props.theme.C_ACT_main};
    text-transform: uppercase;
    margin-left: 20px;
`;

export const ExpandIconBackground = styled.div<{
    expanded: boolean;
    _isCreating?: boolean;
    _isDisabled?: boolean;
}>`
    align-self: flex-end;
    width: 38px;
    height: ${props => props.expanded ? "46px" : "38px"};
    background-color: ${props => props._isCreating ? mix(props.theme.lightFieldGroupOpacity, props.theme.C_BG_filterbar, props.theme.C_BG_floorplan) : props.theme.C_BG_filterbar};
    ${props => props._isDisabled && getDisabledStyle(props._isDisabled)};

    display: flex;
    align-items: flex-start;
    justify-content: center;

    border-top-right-radius: 25px;
    border-top-left-radius: 25px;

    border-bottom-right-radius: ${props => props.expanded ? 0 : "25px"};
    border-bottom-left-radius: ${props => props.expanded ? 0 : "25px"};

    transition: border-radius 0.1s /*, height 0.1s*/;
`;

export const ExpandIconWrapper = styled.div`
    position: relative;
    width: 38px;
    height: 38px;
    align-self: flex-end;

    // render over the collapse wrappers shadow
    z-index: ${LocalStackOrder.AboveContent};
`;

export const SmartFieldStyled = styled(SmartField) <{
    _isLast?: boolean;
}>`
    margin-right: ${props => props._isLast ? "20px" : ""};
`;

const StyledHeaderItem = css`
    margin: 0 0 0 12px;
`;

export const SmartFieldInHeaderStyled = styled(SmartField)`
    ${StyledHeaderItem};
`;

export const SwitchInHeaderStyled = styled(Switch)`
    ${StyledHeaderItem};
`;

export const ButtonGroupStyled = styled(ButtonGroup)`
    ${StyledFieldsWrapper} + &, ${TableWrapper} + & {
        margin-top: ${FIELD_VER_MARGIN};
    }
`;

export const CollapseWrapper = styled.div`
    width: 100%;
    position: relative;

    ${StyledSmartFastEntryList} {
        flex-grow: 1
    }
    
    ${StyledLabel}, ${Label}, ${CheckboxLabel} {
        color: ${props => props.theme.C_TEXT_purple_bg};
    }
`;

export const Body = styled.div<{
    isWithoutMargin: boolean;
    isDarkBackground: boolean;
    isFlex?: boolean;
}>`
    ${Header} + & {
        margin-top: ${props => props.isWithoutMargin ? "0" : "20px"};
    }

    ${props => props.isDarkBackground && css`
        background: ${props => props.theme.C_BG_popover};
        // when screen to small, we want to have horizontal scrollbar for line items
        // if background is used
        overflow: auto;
    `};

    // if FormGroup is flex-column, stretch body
    ${props => props.isFlex && css`
        flex: 1 1 auto;
    `};
`;

export const GroupedKey = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
`;

export const Field = styled.div<{
    sharpRight: boolean,
    _width?: string
}>`
    // for relative width put 100% so we don't short field by parent's  
    width: ${props => props._width === "100%" ? "100%" : ""};
    max-width: 100%;
    display: flex;
`;

export const ExpandIconBackgroundFormGroupAction = styled(ExpandIconBackground)`
    background: ${props => props.expanded ? "#9e037f" : "transparent"};
`;

export const IconButtonStyledOptionsButton = styled(IconButton)`
    position: relative;

    && {
        margin: 0;
    }
`;

export const FormGroupActionsStyled = styled(FormGroupActions)`
    margin-bottom: 20px;
`;