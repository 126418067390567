import React, { PureComponent } from "react";
import { NoDataWrapper, StyledObjectList } from "./ObjectList.styles";
import ListItem, { IListItem } from "./ListItem";
import { Separator } from "../separator/Separator";
import { withTranslation, WithTranslation } from "react-i18next";
import TestIds from "../../testIds";
import { IAlertProps } from "../alert/Alert";
import { withBusyIndicator, WithBusyIndicator } from "../busyIndicator/withBusyIndicator";
import { NoData } from "../table/NoData";


export interface ISection {
    id: string;
    name?: string;
    children: IListItem[];
}

export interface IObjectListProps extends WithTranslation, WithBusyIndicator {
    listId?: string;
    sections: ISection[];
    noDataText?: string;
    onTriggerAction?: (itemId: string, action: string) => void;
}

class ObjectList extends PureComponent<IObjectListProps> {
    renderSections = (sections: ISection[]) => {
        return sections.map((section, idx) => {
            // renders separator if it's not first section or name is explicitly specified
            const renderSeparator = idx || section.name;
            return (
                <React.Fragment key={section.id}>
                    {renderSeparator && <Separator label={section.name} isBold/>}
                    {this.renderListItems(section.children)}
                </React.Fragment>
            );
        });
    };

    renderListItems = (items: IListItem[]) => {
        return items.map((item, idx) => {
            const id = `${this.props.listId}/${item.id}`,
                key = `${this.props.listId}/${item.key ?? item.id}`;
            return (
                <React.Fragment key={key}>
                    <ListItem id={id}  {...item} onTriggerAction={this.props.onTriggerAction}/>
                    {idx !== items.length - 1 ? <Separator isEmphasised={item.isDefault} isBold/> : ""}
                </React.Fragment>
            );
        });
    };

    renderNoData = () => (
        <NoDataWrapper>
            <NoData noDataText={this.props.noDataText ?? this.props.t("Components:ObjectList.NoData")}/>
        </NoDataWrapper>
    );

    render = () => {
        const sectionsWithChildren = this.props.sections?.filter(section => section.children?.length);

        return (
            <StyledObjectList data-testid={TestIds.ObjectList}>
                {this.props.busyIndicator}
                {!this.props.busy &&
                (sectionsWithChildren?.length ? this.renderSections(sectionsWithChildren) : this.renderNoData())}
            </StyledObjectList>
        );
    };
}


export default withBusyIndicator({ passBusyIndicator: true })(withTranslation(["Components"])(ObjectList));