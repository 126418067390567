import i18next from "i18next";
import { BasicInputSizes, CacheStrategy, FieldType, LabelStatus, Sort, ValueType } from "../../enums";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../PageUtils";
import { TValue } from "../../global.types";
import BindingContext from "../../odata/BindingContext";
import {
    AccountDefFormatter,
    getCommonFilterDefs,
    getCommonTableColumnsDefs
} from "@components/smart/GeneralFieldDefinition";
import { IFormatOptions } from "@odata/OData.utils";
import AccountAssignmentFormView from "./AccountAssignmentFormView";
import { IFormDef } from "../../views/formView/Form";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import {
    EntitySetName,
    EntityTypeName,
    IAccountAssignmentDocumentTypeEntity,
    IAccountAssignmentEntity,
    IAccountEntity
} from "@odata/GeneratedEntityTypes";
import { isMatchingAccount } from "../documents/Document.utils";
import { setDefByEntityType } from "../getDefByEntityType";
import { ISplitPageTableDef } from "../../views/table/TableView.utils";
import { TFormatterFn } from "@components/smart/smartTable/SmartTable.utils";
import { getDocumentTypeLabel } from "./AccountAssignment.utils";
import { ISelectItem } from "@components/inputs/select/BasicSelect";
import { Model } from "../../model/Model";

export const flattenAccountsDef = {
    isRequired: true,
    type: FieldType.ComboBox,
    cacheStrategy: CacheStrategy.View,
    columns: [{
        id: "Number",
        additionalProperties: [{
            id: "/Name"
        }],
        formatter: AccountDefFormatter
    }],
    formatter: AccountDefFormatter,
    fieldSettings: {
        displayName: "Number",
        entitySet: EntitySetName.FlattenAccounts
    },
    width: BasicInputSizes.XL
};

export const getAccountFormatter = (propName: string): TFormatterFn => {
    return (val: TValue, { entity }: IFormatOptions) => {
        return entity[`${propName}Number`] || entity[`${propName}Name`] ? `${entity[`${propName}Number`] ?? ""} - ${entity[`${propName}Name`] ?? ""}` : null;
    };
};

export const getAccountComparisonFunction = (propName: string) => {
    return (accAssignment1: IAccountAssignmentEntity, accAssignment2: IAccountAssignmentEntity) => {
        const account1: IAccountEntity = {
            Name: accAssignment1[`${propName}Name` as keyof IAccountAssignmentEntity] as string,
            Number: accAssignment1[`${propName}Number` as keyof IAccountAssignmentEntity] as string
        };
        const account2: IAccountEntity = {
            Name: accAssignment2[`${propName}Name` as keyof IAccountAssignmentEntity] as string,
            Number: accAssignment2[`${propName}Number` as keyof IAccountAssignmentEntity] as string
        };

        return isMatchingAccount(account1, account2);
    };
};

export const AccAssCreditAccountPath = BindingContext.localContext("CreditAccount");
export const AccAssDebitAccountPath = BindingContext.localContext("DebitAccount");

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const filterBarDef: IFilterGroupDef[] = [{
        ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
        isValueHelp: true,
        defaultFilters: [
            "ShortName",
            "Name",
            "DebitAccountNumber",
            "CreditAccountNumber",
            BindingContext.localContext("DocumentTypes")
        ],
        filterDefinition: {
            ShortName: {},
            Name: {},
            DebitAccountNumber: {},
            CreditAccountNumber: {},
            // once again, similar problem as we have e.g. in document form with account assignments
            // the source of the filter is different than what the AccountAssignment/DocumentItems points to (type AccountAssignmentDocumentType)
            // but we need to load the data from the DocumentTypes entity set (type DocumentType)
            // this causes mismatch between properties a fails on multiple places => using local binding context instead
            // todo enable this without local binding context
            [BindingContext.localContext("DocumentTypes")]: {
                label: i18next.t("AccountAssignment:AvailableFor"),
                filterName: ["/DocumentTypes/DocumentTypeCode"],
                isValueHelp: false,
                type: FieldType.MultiSelect,
                valueType: ValueType.String,
                fieldSettings: {
                    entitySet: EntitySetName.DocumentTypes,
                    displayName: "Name",
                    transformFetchedItems: (items) => {
                        return items.map((item: ISelectItem) => ({
                            id: item.id,
                            label: getDocumentTypeLabel({
                                Code: item.id as string,
                                Name: item.label
                            })
                        }));
                    }
                },
                defaultValue: []
            },
            Note: {},
            ...getCommonFilterDefs()
        }
    }];

    const table: ISplitPageTableDef = {
        filterBarDef,
        id: "accounts",
        columns: [
            "ShortName",
            "Name",
            "DebitAccountNumber",
            "CreditAccountNumber"
        ],
        columnDefinition: {
            ShortName: { id: "ShortName" },
            Name: { id: "Name" },
            DebitAccountNumber: {
                additionalProperties: [{ id: "/DebitAccountName" }],
                formatter: getAccountFormatter("DebitAccount"),
                label: i18next.t("AccountAssignment:DebitAccount")
            },
            CreditAccountNumber: {
                additionalProperties: [{ id: "/CreditAccountName" }],
                formatter: getAccountFormatter("CreditAccount"),
                label: i18next.t("AccountAssignment:CreditAccount")
            },
            Note: {},
            ...getCommonTableColumnsDefs(),
            DocumentTypes: {
                additionalProperties: [
                    { id: "DocumentType/Name" }
                ],
                formatter: (val: TValue, args?: IFormatOptions) => {
                    return args.entity.DocumentTypes
                            ?.map((documentType: IAccountAssignmentDocumentTypeEntity) => documentType.DocumentType.Name).join(", ");
                }
            }

        },
        title: i18next.t("AccountAssignment:Title"),
        initialSortBy: [{
            id: "ShortName", sort: Sort.Asc
        }]
    };

    const summary: ISummaryItem[] = [];

    const form: IFormDef = {
        id: "accountAssignmentForm",
        summary,
        formControl: AccountAssignmentFormView,
        translationFiles: getDefinitions.translationFiles,
        isDeletable: true,
        getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t("AccountAssignment:NewAccountAssignment"), storage.data.entity?.Name),
        additionalProperties: [
            { id: "DebitAccountNumber" }, { id: "DebitAccountName" },
            { id: "CreditAccountNumber" }, { id: "CreditAccountName" }
        ],
        groups: [
            {
                id: "basic",
                rows: [[{ id: "ShortName" }, { id: "Name" },
                    { id: AccAssDebitAccountPath },
                    { id: AccAssCreditAccountPath }
                ]]
            },
            {
                id: "note",
                title: i18next.t("Common:General.Note"),
                rows: [[{ id: "Note" }]]
            }, {
                id: "availableFor",
                title: i18next.t("Common:Form.AvailableFor"),
                rows: [[{ id: "DocumentTypes" }]]
            }],
        fieldDefinition: {
            Name: {
                width: BasicInputSizes.XL
            },
            ShortName: {
                width: BasicInputSizes.S
            },
            [AccAssCreditAccountPath]: {
                label: i18next.t("AccountAssignment:CreditAccount"),
                ...flattenAccountsDef,
                comparisonFunction: getAccountComparisonFunction("CreditAccount"),
                formatter: getAccountFormatter("CreditAccount")
            },
            [AccAssDebitAccountPath]: {
                label: i18next.t("AccountAssignment:DebitAccount"),
                ...flattenAccountsDef,
                comparisonFunction: getAccountComparisonFunction("DebitAccount"),
                formatter: getAccountFormatter("DebitAccount")
            },

            Note: {
                type: FieldType.EditableText,
                labelStatus: LabelStatus.Removed
            },
            DocumentTypes: {
                fieldSettings: {
                    keyPath: "DocumentType/Code"
                },
                customizationData: {
                    isRequired: true
                },
                additionalProperties: [{
                    id: "DocumentType"
                }, {
                    id: "DocumentType/Name",
                    isRequired: false
                }],
                labelStatus: LabelStatus.Removed,
                type: FieldType.CheckboxGroup
            }
        }
    };

    return {
        entitySet: EntitySetName.AccountAssignments,
        table,
        form
    };
};

getDefinitions.translationFiles = ["AccountAssignment"];
setDefByEntityType(EntityTypeName.AccountAssignment, getDefinitions);