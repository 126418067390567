import { EditSimpleIcon } from "@components/icon";
import { formatDateToDateString, formatVariants } from "@components/inputs/date/utils";
import { ISelectItem } from "@components/inputs/select/BasicSelect";
import { ICellValueObject, IColumn, IRow, IRowValues, TCellValue } from "@components/table";
import {
    BankStatementCsvSettingsTextEntity,
    BankTransactionCsvColumnMappingEntity,
    CompanyBankAccountEntity,
    EntitySetName,
    IBankEntity,
    IBankTransactionCsvColumnMappingEntity,
    IBankTransactionEntity,
    ICompanyBankAccountEntity,
    IDateFormatEntity,
    NumberRangeDefinitionEntity
} from "@odata/GeneratedEntityTypes";
import { BankTransactionTypeCode, PaymentStatusCode } from "@odata/GeneratedEnums";
import { OData } from "@odata/OData";
import { correctBankCode, createIBANFromAccount } from "@utils/BankUtils";
import { getCompanyCurrency } from "@utils/CompanyUtils";
import { isDefined } from "@utils/general";
import { removeWhiteSpace } from "@utils/string";
import dayjs, { Dayjs } from "dayjs";
import i18next, { TFunction } from "i18next";
import { cloneDeep, isEmpty, isInteger } from "lodash";
import React from "react";

import { BANK_STATEMENTS } from "../../constants";
import { IAppContext, IBreadcrumb } from "../../contexts/appContext/AppContext.types";
import { FieldType, IconSize, Status, TextAlign } from "../../enums";
import { ColoredText } from "../../global.style";
import { TRecordAny, TRecordString } from "../../global.types";
import { formatCurrency } from "../../types/Currency";
import DateType, { parseDayjs } from "../../types/Date";
import NumberType, { NumberParser } from "../../types/Number";
import customFetch, { getDefaultPostParams } from "../../utils/customFetch";
import memoizeOne from "../../utils/memoizeOne";
import { IUploadArgs } from "./CsvImport";
import { StyledErrorValue } from "./CsvImport.styles";

export interface IFieldToUpdate {
    value: string;
    originalValue: string;
    row: number;
    column: string;
    label: string;
    fieldDef: IImportFieldDef;
}

export interface IImportFieldDef {
    title: string,
    required?: boolean,
    validator?: (val: string, context?: IAppContext, dateFormat?: string) => boolean | string,
    parser?: (val: string, args?: ICsvParserUtils) => string,
    formatter?: (val: string, args?: ICsvFormatterArgs) => string,
    columnProps?: Partial<IColumn>
    type?: FieldType
}

interface ICsvFormatterArgs {
    currencyCode?: string,
    dateFormat?: string
}

export interface IBankAccountCSVImportSettings {
    DataStartPosition: number,
    DateFormat: string,
    AmountFormat: string,
    FileEncoding: string,
    HeaderPosition: number
    ColumnMap: IBankTransactionCsvColumnMappingEntity[],
    BankStatementNumberRangeDefinitionId?: number
}

interface ICsvParserUtils {
    localization?: string;
    columnMap?: IBankTransactionCsvColumnMappingEntity[];
    row?: IRow;
}

const SUBMIT_IMPORT_URL = `${BANK_STATEMENTS}/SubmitCsvImport`;

export const parseFormattedNumber = (val: string, args?: ICsvParserUtils): string => {
    if (!val) {
        return "";
    }

    const localization = args?.localization ?? "cs-CZ";
    const parser = new NumberParser(localization);
    const res = parser.parse(val);
    if (!Number.isNaN(res)) {
        return res?.toString();
    }
    return val;
};

/**
 * We need to use "strict" in dayjs for parsing, so that we don't parse other date formats resulting in wrong value,
 * but we want to be able to parse dates that contain time part as well.
 * ==> First, use regex to differentiate the date part from the time part and THEN parse the date with dayjs
 * */
export const parseDateWithoutTimePart = (date: string, dateFormat: string): Dayjs => {
    if (typeof date !== "string") {
        return dayjs("invalid date");
    }

    const sortedFormats = ["DD", "MM", "YY"].sort((a, b) => dateFormat.indexOf(a) - dateFormat.indexOf(b));
    // find first non-alphabetical character => delimiter
    const delimiter = dateFormat.match(/\W/)[0];
    const regexContent: string[] = [];

    for (const part of sortedFormats) {
        if (["DD", "MM"].includes(part)) {
            regexContent.push("\\d{1,2}");
        } else {
            regexContent.push("\\d{4}");
        }
    }

    const regexp = new RegExp(regexContent.join(delimiter));
    const datePart = date.match(regexp)?.[0];

    if (!datePart) {
        return dayjs("invalid date");
    }

    const variants = formatVariants(dateFormat, true);

    return parseDayjs({ date: datePart, format: variants, strictMode: true });
};

export const transactionPropsDef = (context: IAppContext): Record<string, IImportFieldDef> => ({
    DateBankTransaction: {
        title: i18next.t("CsvImport:Properties.DateBankTransaction").toString(),
        required: true,
        type: FieldType.Date,
        validator: (val, context, dateFormat) => {
            if (isEmpty(val)) {
                return i18next.t("Common:Validation.Required").toString();
            } else if (!parseDateWithoutTimePart(val, dateFormat).isValid()) {
                return i18next.t("Common:Validation.NotADate").toString();
            }
            return true;
        },
        parser: removeWhiteSpace,
        formatter: (date, { dateFormat }) => {
            return DateType.format(parseDateWithoutTimePart(date, dateFormat));
        }
    },
    TransactionAmount: {
        title: i18next.t("CsvImport:Properties.TransactionAmount").toString(),
        required: true,
        type: FieldType.NumberInput,
        validator: val => {
            if (isEmpty(val)) {
                return i18next.t("Common:Validation.Required").toString();
            } else if (!NumberType.isValid(NumberType.parse(val))) {
                return i18next.t("Common:Validation.MustBeNumber").toString();
            }
            return true;
        },
        formatter: (val, args) => {
            return formatCurrency(val, args.currencyCode ?? getCompanyCurrency(context));
        },
        columnProps: {
            textAlign: TextAlign.Right
        },
        parser: parseFormattedNumber
    },
    BankInternalId: {
        title: i18next.t("CsvImport:Properties.BankInternalId").toString()
    },
    PaymentType: {
        title: i18next.t("CsvImport:Properties.PaymentType").toString()
    },
    AccountNumber: {
        title: i18next.t("CsvImport:Properties.AccountNumber").toString(),
        validator: value => {
            const split = value?.includes("/") ? value.split('/')[0] : value;

            if (split && !split.match(/^[0-9]+-?[0-9]+$/)) {
                return i18next.t("Common:General.WrongAccountNumber").toString();
            }

            return true;
        },
        parser: (value: string, args?: ICsvParserUtils) => {
            if (value?.includes(`/`)) {
                return value.split('/')[0];
            }

            if (!value) {
                const accData = getValueByColumnName(args.columnMap, "BankCode", args.row);
                if (isDefined(accData.value) && accData.value.includes?.('/')) {
                       return accData.value.split('/')[0];
                }
            }

            return value;
        }
    },
    BankCode: {
        title: i18next.t("CsvImport:Properties.BankCode").toString(),
        parser: (value: string, args?: ICsvParserUtils) => {
            if (!value) {
                const accData = getValueByColumnName(args.columnMap, "AccountNumber", args.row);
                if (isDefined(accData.value)) {
                    return accData.value.split('/')?.[1];
                }
            }

            return value?.includes("/") ? value.split('/')[1] : value;
        }
    },
    BankAccountDescription: {
        title: i18next.t("CsvImport:Properties.BankAccountDescription").toString(),
    },
    RemittanceInformation: {
        title: i18next.t("CsvImport:Properties.RemittanceInformation").toString(),
        validator: val => {
            if (!isDefined(val) || val.length <= 140) {
                return true;
            }
            return i18next.t("Common:Validation.Max", { max: 140 }).toString();
        },
        columnProps: {
            textAlign: TextAlign.Right
        }
    },
    SymbolConstant: {
        title: i18next.t("CsvImport:Properties.SymbolConstant").toString(),
        validator: val => {
            const symbol = Number(val);
            if (isDefined(val) && (!isInteger(symbol) || val.length > 10)) {
                return i18next.t("CsvImport:Validation.Symbol", { max: 10 }).toString();
            }
            return true;
        },
        parser: val => {
            if (/^\d+$/.test(val)) {
                val = val.replace(/^0+/, "");
                return val.padStart(4, "0");
            }
            return val;
        },
        columnProps: {
            textAlign: TextAlign.Right
        }
    },
    SymbolSpecific: {
        title: i18next.t("CsvImport:Properties.SymbolSpecific").toString(),
        validator: val => {
            const symbol = Number(val);
            if (isDefined(val) && (!isInteger(symbol) || val.length > 10)) {
                return i18next.t("CsvImport:Validation.Symbol", { max: 10 }).toString();
            }
            return true;
        },
        columnProps: {
            textAlign: TextAlign.Right
        }
    },
    SymbolVariable: {
        title: i18next.t("CsvImport:Properties.SymbolVariable").toString(),
        validator: val => {
            const symbol = Number(val);
            if (isDefined(val) && (!isInteger(symbol) || val.length > 10)) {
                return i18next.t("CsvImport:Validation.Symbol", { max: 10 }).toString();
            }
            return true;
        },
        columnProps: {
            textAlign: TextAlign.Right
        }
    },
    PaymentInformation: {
        title: i18next.t("CsvImport:Properties.PaymentInformation").toString()
    }
});

export const getCsvImportBreadCrumbs = (): IBreadcrumb => ({
    items: [{
        key: "uploadFile",
        link: null,
        title: i18next.t("CsvImport:UploadCSVFile")
    }],
    lockable: false
});

// get alphabet index like Excel column
export const getAlphabetKey = (key: number): string => {
    const alphabetLength = 25;
    const getChar = (key: number): string => {
        const overflow = Math.floor(key / alphabetLength);
        const char = (key % alphabetLength) + 65; // 65 is position of A
        if (overflow) {
            return getChar(overflow) + String.fromCharCode(char);
        }
        return String.fromCharCode(char);
    };

    return getChar(key);
};

export const getDateFormats = memoizeOne(async (oData: OData): Promise<ISelectItem<string>[]> => {
    const items: ISelectItem<string>[] = [];
    const formats = await oData.getEntitySetWrapper(EntitySetName.DateFormats)
        .query()
        .fetchData<IDateFormatEntity[]>();

    formats?.value?.forEach((format: IDateFormatEntity) => {
        items.push({
            label: format.Name,
            id: format.Format
        });
    });
    return items;
});

const getCompanyBankAccEntity = async (bankAccountId: number, oData: OData): Promise<ICompanyBankAccountEntity> => {
    const result = await oData.getEntitySetWrapper(EntitySetName.CompanyBankAccounts)
        .query(bankAccountId)
        .expand(CompanyBankAccountEntity.BankStatementCsvSettingsText,
            q => q.select(BankStatementCsvSettingsTextEntity.DataStartPosition, BankStatementCsvSettingsTextEntity.DateFormat, BankStatementCsvSettingsTextEntity.AmountFormat, BankStatementCsvSettingsTextEntity.FileEncoding, BankStatementCsvSettingsTextEntity.HeaderPosition))
        .expand(CompanyBankAccountEntity.BankTransactionCsvColumnMappings,
            q => q.select(BankTransactionCsvColumnMappingEntity.PropertyName, BankTransactionCsvColumnMappingEntity.ColumnId, BankTransactionCsvColumnMappingEntity.Id))
        .expand(CompanyBankAccountEntity.BankStatementNumberRangeDefinition, query => query.select(NumberRangeDefinitionEntity.Id))
        .fetchData<ICompanyBankAccountEntity>();

    return result.value;
};

export const getBankAccountCSVImportSettings = async (bankAccountId: number, oData: OData): Promise<{
    currencyCode: string,
    settings: IBankAccountCSVImportSettings
}> => {
    const companyBankAccount = await getCompanyBankAccEntity(bankAccountId, oData);

    if (!companyBankAccount) {
        return null;
    }
    return {
        currencyCode: companyBankAccount.TransactionCurrencyCode,
        settings: {
            DataStartPosition: companyBankAccount.BankStatementCsvSettingsText.DataStartPosition,
            DateFormat: companyBankAccount.BankStatementCsvSettingsText.DateFormat,
            AmountFormat: companyBankAccount.BankStatementCsvSettingsText.AmountFormat,
            FileEncoding: companyBankAccount.BankStatementCsvSettingsText.FileEncoding,
            HeaderPosition: companyBankAccount.BankStatementCsvSettingsText.HeaderPosition,
            ColumnMap: companyBankAccount.BankTransactionCsvColumnMappings,
            BankStatementNumberRangeDefinitionId: companyBankAccount.BankStatementNumberRangeDefinition?.Id
        }
    };
};

export const saveBankAccountSettings = async (bankAccountId: number, settings: IBankAccountCSVImportSettings, oData: OData): Promise<void> => {
    const { BankStatementNumberRangeDefinitionId, ColumnMap, ...rest } = settings;
    await oData.getEntitySetWrapper(EntitySetName.CompanyBankAccounts).update(bankAccountId, {
        BankTransactionCsvColumnMappings: ColumnMap,
        BankStatementCsvSettingsText: { ...rest }
    });
};

export const csvRowsParse = (csvRows: TRecordString[], headerPosition: number): IRow[] => {
    return csvRows.map((values: TRecordString, index: number) => {
        return {
            id: index,
            values: { ...values },
            selected: headerPosition >= 0 && index === headerPosition,
            customData: {
                hasError: false
            }
        };
    });
};

export const getImportTableEditableErrorValue = (value: ICellValueObject["tooltip"], onClick: React.MouseEventHandler, isWithoutError?: boolean): TCellValue => {
    return {
        value: (
            <StyledErrorValue onClick={onClick}>
                {!isWithoutError ? <ColoredText color={"C_SEM_text_bad"}><b>{value}</b></ColoredText> : <b>{value}</b>}
                <EditSimpleIcon width={IconSize.XS} height={IconSize.XS}/>
            </StyledErrorValue>
        ),
        tooltip: value
    };
};

const getValueByColumnName = (columnMap: IBankTransactionCsvColumnMappingEntity[], colName: string, row: IRow) => {
    const boundColumn = columnMap.find(col => col.PropertyName === colName)?.ColumnId;
    return {
        value: isDefined(boundColumn) ? row.values[boundColumn] as string : null,
        index: boundColumn
    };
};

export const transformItem = (args: ITransformItemsArgs, row: IRow, rowIndex: number, context: IAppContext): IRow => {
    const propsDefs = transactionPropsDef(context);
    row.customData.entity = {};
    row.values = Object.entries(propsDefs).reduce((values: IRowValues, [key, def]) => {
        let value = getValueByColumnName(args.settings.ColumnMap, key, row).value;
        if (propsDefs[key]?.parser) {
            value = propsDefs[key].parser(value, {
                localization: args.localization,
                columnMap: args.settings.ColumnMap,
                row
            });
        }

        if (!def.validator || def.validator(value, args.context, args.settings.DateFormat) === true) {
            values[key] = !!value ? value : null;
        } else {
            row.customData.hasError = row.customData.hasOriginalError = true;
            if (args.storeOriginalValue) {
                row.customData.originalValue = value;
            }

            values[key] = getImportTableEditableErrorValue(value, () => {
                args.handleValueChange?.({
                    value,
                    originalValue: row.customData.originalValue,
                    row: rowIndex,
                    column: key,
                    label: args.t(`CsvImport:Properties.${key}`),
                    fieldDef: transactionPropsDef(context)[key]
                });
            });
        }
        row.customData.entity[key] = value;
        return values;
    }, {});

    row.statusHighlight = row.customData?.hasOriginalError ? Status.Error : null;
    row.customData.isChecked = !row.customData.hasOriginalError;

    return row;
};

interface ITransformItemsArgs {
    rows: IRow[];
    settings: IBankAccountCSVImportSettings;
    context: IAppContext;
    t: TFunction;
    handleValueChange?: (fieldToUpdate: IFieldToUpdate) => void;
    localization?: string;
    storeOriginalValue?: boolean;
}

export const transformItems = (args: ITransformItemsArgs): IRow[] => {
    const dataRows = cloneDeep(args.rows)?.slice(args.settings.DataStartPosition);

    if (!dataRows) {
        return [];
    }

    return dataRows.map((row: IRow, rowIndex: number): IRow => {
        return transformItem(args, row, rowIndex, args.context);
    });
};

export const parseTransactions = async (transformedRows: IRow[], dateFormat: string, oData: OData): Promise<IBankTransactionEntity[]> => {
    const result = await oData.getEntitySetWrapper(EntitySetName.Banks as EntitySetName).query().fetchData();
    const banks: IBankEntity[] = await result?.value;

    return transformedRows.map((row, index) => {
        const amount = parseFloat(row.customData.entity["TransactionAmount"]);
        const entity = row.customData.entity;
        const transaction: TRecordAny = {
            TransactionAmount: amount,
            PaymentStatusCode: PaymentStatusCode.WaitsForProcessing,
            BankTransactionTypeCode: amount > 0 ? BankTransactionTypeCode.IncomingPayment : BankTransactionTypeCode.OutgoingPayment,
            DateBankTransaction: formatDateToDateString(parseDayjs({
                date: row.customData.entity["DateBankTransaction"],
                format: formatVariants(dateFormat, true),
                strictMode: false
            })),
            BankAccountDescription: row.customData.entity.BankAccountDescription,
            PaymentType: row.customData.entity.PaymentType,
            BankInternalId: row.customData.entity.BankInternalId,
            Order: index + 1,
            RemittanceInformation: row.customData.entity["RemittanceInformation"],
            PaymentInformation: row.customData.entity["PaymentInformation"],
            SymbolConstant: row.customData.entity["SymbolConstant"],
            SymbolVariable: row.customData.entity["SymbolVariable"],
            SymbolSpecific: row.customData.entity["SymbolSpecific"]
        };

        if (entity.AccountNumber && entity.BankCode && banks) {
            const bankCode= correctBankCode(entity.BankCode);
            const bank = banks.find(bank => bank.Code === bankCode);
            
            if (bank) {
                transaction.BankAccount = {
                    CountryCode: bank.CountryCode,
                    ...transaction.BankAccount,
                    AccountNumber: entity.AccountNumber,
                    BankCode: bankCode,
                    IBAN: createIBANFromAccount(entity.AccountNumber, bankCode, bank.CountryCode),
                    SWIFT: bank.SWIFT
                };
            }
        }
        return Object.fromEntries(Object.entries(transaction).filter(([_, v]) => v != null && v !== ""));
    });
};

export const uploadCsvBankTransactions = async (args: IUploadArgs): Promise<Response> => {
    const bankTransactions: IBankTransactionEntity[] = await parseTransactions(args.transformedRows, args.dateFormat, args.oData);

    const bankStatement = {
        Attachments: [{ Id: args.fileId }],
        BankAccount: { Id: args.bankAccountId },
        NumberRangeDefinition: { Id: args.numberRangeId },
        TransactionsCount: bankTransactions.length,
        TransactionsTurnover: bankTransactions.reduce((turnover, transaction) => {
            return turnover + (transaction.TransactionAmount);
        }, 0)
    };

    const result = await customFetch(SUBMIT_IMPORT_URL, {
        ...getDefaultPostParams(),
        body: JSON.stringify({
            BankStatement: bankStatement,
            BankTransactions: bankTransactions
        })
    });

    return result;
};

export const findLongestRow = (arr: TRecordString[]): TRecordString => {
    let longestLength = 0;
    let longestRow: TRecordString;

    for (const row of arr) {
        if (Object.keys(row).length > longestLength) {
            longestLength = Object.keys(row).length;
            longestRow = row;
        }
    }
    return longestRow;
};

export const encodings = [
    "UTF-8",
    "IBM866",
    "ISO-8859-2",
    "ISO-8859-3",
    "ISO-8859-4",
    "ISO-8859-5",
    "ISO-8859-6",
    "ISO-8859-7",
    "ISO-8859-8",
    "ISO-8859-8-I",
    "ISO-8859-10",
    "ISO-8859-13",
    "ISO-8859-14",
    "ISO-8859-15",
    "ISO-8859-16",
    "KOI8-R",
    "KOI8-U",
    "macintosh",
    "windows-874",
    "windows-1250",
    "windows-1251",
    "windows-1252",
    "windows-1253",
    "windows-1254",
    "windows-1255",
    "windows-1256",
    "windows-1257",
    "windows-1258",
    "x-mac-cyrillic",
    "GBK",
    "gb18030",
    "Big5",
    "EUC-JP",
    "ISO-2022-JP",
    "Shift_JIS",
    "EUC-KR",
    "replacement",
    "UTF-16BE",
    "UTF-16LE",
    "x-user-defined"
];

export const localizationItems: ISelectItem[] = [{
    id: "cs-CZ",
    label: "1 000,12"
}, {
    id: "en-US",
    label: "1,000.12"
}, {
    id: "de-DE",
    label: "1.000,12"
}];