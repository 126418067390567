import styled, { css } from "styled-components/macro";
import {
    getDisabledStyle,
    getFocusBorderElement,
    LayoutStackOrder,
    LocalStackOrder,
    T_TITLE_1,
    T_TITLE_4
} from "../../global.style";
import { Link } from "react-router-dom";
import { VIEW_PADDING_VALUE } from "../../views/View.styles";
import { Theme } from "../../theme";
import WorkDatePicker from "../workDatePicker";

export const StyledNavDashboard = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${VIEW_PADDING_VALUE}px 0 0 0;
    height: 100%;

    position: absolute;
    width: 100%;
    overflow: hidden;
    top: 0;
    z-index: ${LayoutStackOrder.MenuDashboard};
    background-color: ${props => props.theme.C_BG_menu};
`;

export const StyledNavDashBoardBody = styled.div`
    height: 100%;
`;

export const LEFT_BAR_OFFSET = 64;
export const REPEAT_WAVE_OFFSET = 2000;

export const DashboardBody = styled.div<{
    $isWithoutPadding?: boolean // eg. company dashboard is in VIew which already has desired padding
}>`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: ${props => props.$isWithoutPadding ? "0" : `0 ${VIEW_PADDING_VALUE}px ${VIEW_PADDING_VALUE}px ${VIEW_PADDING_VALUE}px`};
    margin-top: 10px;
    position: relative;
    gap: 34px;
`;

interface IDashboardBackgroundProps {
    $isTransparent?: boolean;
    $isBehindContent?: boolean;
    $type: keyof Theme;
    $hasShellBar?: boolean;
}

export const DashboardBackgroundStyled = styled.div<IDashboardBackgroundProps>`
    background-image: url(${props => props.theme[props.$type]});
    background-repeat: no-repeat;
    background-position: ${props => props.$hasShellBar ? `${LEFT_BAR_OFFSET}px` : 0} bottom;
    background-size: auto 445px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: ${props => props.$isTransparent ? 0.4 : 1};
    z-index: ${props => props.$isBehindContent ? LocalStackOrder.BelowContent : LocalStackOrder.AboveContent};

    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 117px;
        left: ${props => REPEAT_WAVE_OFFSET + (props.$hasShellBar ? LEFT_BAR_OFFSET : 0)}px;
        bottom: 0;
        ${props => props.$type === "F_wave" ? css`
            background-color: #A3047B;
            opacity: 0.3;
        ` : (props.$type === "F_wave_mountain" ? css`
            background-color: #DCDCDC;
        ` : props.$type === "F_wave_mountain_complex" ? css`
            background-color: #DCDCDC;
        ` : css`
            background-color: #AFAFAF;
        `)}
    }
`;

export const DashboardTitle = styled.div`
    display: flex;
    ${T_TITLE_1}
`;


export const WorkDatePickerStyled = styled(WorkDatePicker)`
    margin-left: 20px;
`;

export const WriteLineWrapper = styled.div<{
    isBig?: boolean;
}>`
    width: 240px;
    margin-right: 10px;
    font-size: ${props => props.isBig ? "18px" : "14px"};
`;

export const BodyWrapper = styled.div`
    height: 100%;
    margin-top: 10px;
`;
export const DashboardHeader = styled.div`
    margin-bottom: 20px;
    padding: 0 ${VIEW_PADDING_VALUE}px;
    height: 60px;
`;

export const ItemBody = styled.div`
    width: 100%;
    height: calc(100% - 10px);
`;

export const StyledLink = styled(Link)<{ $isDisabled?: boolean }>`
    display: block;
    width: 100%;
    height: 100%;
    ${getFocusBorderElement({ offset: 1 })};
    ${props => getDisabledStyle(props.$isDisabled)};
`;

export const ItemFooter = styled.div`
    ${T_TITLE_4};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    position: absolute;
    top: 152px;
    width: 100%;

`;

export const NavListItem = styled.div<{ $isDisabled?: boolean }>`
    width: 200px;
    height: 200px;
    border-radius: 3px;
    border: 2px solid transparent;
    margin-bottom: 30px;
    margin-right: 30px;
    position: relative;

    [data-icon="UnderConstruction"] {
        opacity: 0.3;
    }

    ${props => !props.$isDisabled && css`
        &:hover {
            border-color: ${props => props.theme.C_ACT_hover_without_opacity};
        }

        &:active {
            background-color: ${props => props.theme.C_ACT_hover_without_opacity};
        }

        &:focus {
            outline: 1px dashed ${props => props.theme.C_ACT_main};
        }
    `};
`;
