import { AlertPosition } from "@components/alert/Alert";
import { WithAlert, withAlert } from "@components/alert/withAlert";
import { WithBusyIndicator, withBusyIndicator } from "@components/busyIndicator/withBusyIndicator";
import { IconButton } from "@components/button";
import { getIntentLink } from "@components/drillDown/DrillDown.utils";
import { IReadOnlyListItem } from "@components/readOnlyList/ReadOnlyListItem";
import { getRow, TCustomRowAction } from "@components/smart/smartTable/SmartTable.utils";
import { IActionRendererArgs, IRowAction, TId } from "@components/table";
import { IRowProps } from "@components/table/Rows";
import { IToolbarItem, TToolbarItem } from "@components/toolbar";
import { ODataError } from "@odata/Data.types";
import { IDocumentEntity, IDocumentLinkEntity, IInvoiceReceivedEntity } from "@odata/GeneratedEntityTypes";
import { AccruedProcessingTypeCode, DocumentLinkTypeCode, DocumentTypeCode } from "@odata/GeneratedEnums";
import { parseResponse } from "@odata/ODataParser";
import { PropertyTranslationCache } from "@odata/PropertyTranslationCache";
import {
    ACCRUAL_ACCOUNT_ACTIVE,
    ACCRUAL_ACCOUNT_PASSIVE,
    CLOSING_ACCOUNTS_PREFIX,
    FUTURE_EXPENSES_ACCOUNT,
    FUTURE_REVENUES_ACCOUNT
} from "@utils/accounting";
import { getCompanyCurrency } from "@utils/CompanyUtils";
import i18next from "i18next";
import React from "react";

import { ChainedIcon, IProps as IIconProps, UnchainedIcon } from "../../../../components/icon";
import ReadOnlyList from "../../../../components/readOnlyList/ReadOnlyList";
import { SmartTable } from "../../../../components/smart/smartTable";
import { ActionState, BackendErrorCode, IconSize, RowAction, Status } from "../../../../enums";
import { ColoredText, ToolbarStaticText } from "../../../../global.style";
import BindingContext from "../../../../odata/BindingContext";
import { ROUTE_BUSINESS_PARTNER } from "../../../../routes";
import { formatCurrency } from "../../../../types/Currency";
import DateType from "../../../../types/Date";
import customFetch, { getDefaultPostParams } from "../../../../utils/customFetch";
import memoizeOne from "../../../../utils/memoizeOne";
import { TableButtonsAction, TableButtonsActionType } from "../../../../views/table/TableToolbar";
import TableView, { IHandleCustomActionArgs, ITableViewBaseProps } from "../../../../views/table/TableView";
import { getConfirmationActionText } from "../../../../views/table/TableView.render.utils";
import { SecondaryTableViewTitle, TableWrapper } from "../../../../views/table/TableView.styles";
import { createCustomTableToolbarItem } from "../../../../views/table/TableView.utils";
import View from "../../../../views/View";
import { isReceived } from "../../Document.utils";
import {
    AccrualDialogType,
    ACCRUALS_FORM_TAB,
    ACCRUED_DOC_LINK_ALL_TYPES,
    ACCRUED_DOC_LINK_TYPES,
    FUTURE_EXPENSES_FORM_TAB,
    FUTURE_REVENUES_FORM_TAB,
    PROCESS_ACCRUAL_URL
} from "./Accruals.utils";

const PAIR_ACCRUALS_ACTION_ID = "PairAccrualsAction";

export interface IProps extends ITableViewBaseProps, WithAlert, WithBusyIndicator {
    dialogType?: AccrualDialogType;
}

class PairingAccrualsTableView extends TableView<IProps> {
    _selectedItems: BindingContext[];
    _itemsAmount = 0;

    constructor(props: IProps) {
        super(props);

        this.handleCustomAction = this.handleCustomAction.bind(this);

        const docId = props.rootStorage.data.bindingContext.getKey();
        const account = this.isEstimate ? this.isExpense ? ACCRUAL_ACCOUNT_PASSIVE : ACCRUAL_ACCOUNT_ACTIVE : this.isExpense ? FUTURE_EXPENSES_ACCOUNT : FUTURE_REVENUES_ACCOUNT;

        const getFilterPath = (prefix = "", isExpenseOverride = this.isExpense) => {
            return `${prefix}AccountAssignmentSelection/AccountAssignment/${isExpenseOverride ? "Credit" : "Debit"}Account/Number`;
        };

        const getStartsWithFilter = (prefix = "", isExpenseOverride = this.isExpense) => {
            return `startswith(${getFilterPath(prefix, isExpenseOverride)},'${account}')`;
        };

        const getNotStartsWithInitialAccountFilter = (prefix = "", isExpenseOverride = !this.isExpense) => {
            return `not(startswith(${getFilterPath(prefix, isExpenseOverride)},'${CLOSING_ACCOUNTS_PREFIX}'))`;
        };

        let filter = `DocumentTypeCode eq '${DocumentTypeCode.InternalDocument}'`;
        filter += ` AND DocumentLinks/all(link: not(link/TypeCode in (${ACCRUED_DOC_LINK_ALL_TYPES.map(type => `'${type}'`).join(",")})) OR (link/TargetDocument/Id eq ${docId}) AND link/TypeCode in (${ACCRUED_DOC_LINK_TYPES.map(type => `'${type}'`).join(",")}))`;
        filter += ` AND ((${getStartsWithFilter()} AND ${getNotStartsWithInitialAccountFilter("", !this.isExpense)}) OR Items/any(item: (${getStartsWithFilter("item/")} AND ${getNotStartsWithInitialAccountFilter("item/", !this.isExpense)})))`;

        this.setTableAction(PAIR_ACCRUALS_ACTION_ID, true);
        props.storage.store({ customSecondaryFilter: filter });
        props.storage.data.definition.title = i18next.t(`Accruals:Dialog.${this.isEstimate ? "SecondaryTitleAccruals" : this.isExpense ? "SecondaryTitleExpenses" : "SecondaryTitleRevenues"}`);
    }


    shouldComponentUpdate(nextProps: IProps): boolean {
        return this.props.alert !== nextProps.alert || this.props.busy !== nextProps.busy;
    }

    get isExpense(): boolean {
        return isReceived(this.props.rootStorage.data.entity.DocumentTypeCode as DocumentTypeCode);
    }

    get isEstimate(): boolean {
        return this.props.dialogType === AccrualDialogType.Accruals;
    }

    getHeaderData = (): IReadOnlyListItem[] => {
        const bc = this.props.rootStorage.data.bindingContext;
        const entity = this.props.rootStorage.data.entity as IInvoiceReceivedEntity;
        const numberOursBc = bc.navigate("NumberOurs");
        const datePath = this.isExpense ? "DateReceived" : "DateIssued";

        return [
            {
                // NumberOurs is used as summary item with custom label => label in fieldInfo isn't the one we want
                label: PropertyTranslationCache.getCachedTranslation(numberOursBc.getProperty()),
                value: entity.NumberOurs
            },
            {
                label: this.props.rootStorage.getInfo(bc.navigate("BusinessPartner/BusinessPartner")).label,
                value: getIntentLink(entity.BusinessPartner.Name, {
                    route: `${ROUTE_BUSINESS_PARTNER}/${entity.BusinessPartner.BusinessPartner.Id}`,
                    context: this.props.rootStorage.context,
                    storage: this.props.rootStorage
                })
            },
            {
                label: this.props.rootStorage.getInfo(bc.navigate(datePath)).label,
                value: DateType.format(entity[datePath])
            },
            {
                label: this.props.rootStorage.getInfo(bc.navigate("Amount")).label,
                value: formatCurrency(entity.Amount, entity.Currency?.Code)
            },
            {
                label: this.props.rootStorage.getInfo(bc.navigate("AmountNet")).label,
                value: formatCurrency(entity.AmountNet, entity.Currency?.Code)
            },
            {
                label: this.props.rootStorage.getInfo(bc.navigate("TransactionAmountDue")).label,
                value: formatCurrency(entity.TransactionAmountDue, entity.TransactionCurrency?.Code)
            }
        ];
    };

    handleTableLoad(): void {
        this._selectedItems = [];

        const docId = this.props.rootStorage.data.bindingContext.getKey();
        const tableRows = this.props.storage.tableAPI.getRowsArray();


        for (const row of tableRows) {
            const docLinks = row.customData.entity.DocumentLinks as IDocumentLinkEntity[];
            const rowHasAccrualLink = docLinks.find(docLink => ACCRUED_DOC_LINK_TYPES.includes(docLink.TypeCode as DocumentLinkTypeCode) && docLink.TargetDocument.Id === docId);

            if (rowHasAccrualLink) {
                this._selectedItems.push(row.id as BindingContext);
            }
        }

        super.handleTableLoad();
    }

    renderHeader = (): React.ReactElement => {
        return (
            <>
                <ReadOnlyList title={this.props.rootStorage.t(this.props.rootStorage.data.definition.title)}
                              data={this.getHeaderData()}/>
                <SecondaryTableViewTitle>{this.props.storage.data.definition.title}</SecondaryTableViewTitle>
            </>
        );
    };

    handleCustomAction(action: string, { update = true, value }: IHandleCustomActionArgs = {}): void {
        this.setTableAction(action, true, false);
        if (update) {
            this.forceUpdate();
        }
    }

    handleToolbarConfirm = async () => {
        const affectedRows = await this.props.storage.tableAPI.getAffectedRows();
        const affectedRowsBc = affectedRows.map(affectedRow => affectedRow.bc);
        const isEstimate = this.isEstimate;
        const isExpense = this.isExpense;
        const invoiceId = this.props.rootStorage.data.bindingContext.getKey();
        const anyRowAdded = !!affectedRowsBc.find(bc => !this._selectedItems.find(origBc => bc.toString() === origBc.toString()));
        const anyRowRemoved = !!this._selectedItems.find(origBc => !affectedRowsBc.find(bc => bc.toString() === origBc.toString()));
        let processingCode: string;

        this.props.setBusy(true);

        if (isExpense) {
            processingCode = isEstimate ? AccruedProcessingTypeCode.EstimatedAccruedExpense : AccruedProcessingTypeCode.AccruedExpense;
        } else {
            processingCode = isEstimate ? AccruedProcessingTypeCode.EstimatedAccruedRevenue : AccruedProcessingTypeCode.AccruedRevenue;
        }

        const res = await customFetch(`${PROCESS_ACCRUAL_URL}/${invoiceId}`, {
            ...getDefaultPostParams(),
            body: JSON.stringify({
                AccruedProcessingTypeCode: processingCode,
                OriginalAccruedDocumentIds: affectedRowsBc.map(bc => bc.getKey())
            })
        });

        if (res.status < 300) {
            this._selectedItems = affectedRowsBc;

            const isRemoval = anyRowRemoved && !anyRowAdded;
            const typeKey = isEstimate ? "Estimate" : isExpense ? "Expense" : "Revenue";

            this.setTableAction(null);
            this.props.rootStorage.updateTabsVisibility([ACCRUALS_FORM_TAB, FUTURE_REVENUES_FORM_TAB, FUTURE_EXPENSES_FORM_TAB]);
            this._itemsAmount = 0;

            this.props.setAlert({
                status: Status.Success,
                title: this.props.storage.t("Common:Validation.SuccessTitle"),
                subTitle: this.props.storage.t(`Accruals:Dialog.${isRemoval ? `RemoveSuccess${typeKey}` : `Success${typeKey}`}`)
            });
        } else {
            const error: ODataError = await parseResponse(res);

            this.props.setAlert({
                status: Status.Error,
                title: this.props.storage.t("Common:Errors.ErrorHappened"),
                subTitle: error._code === BackendErrorCode.ValidationError ? error._validationMessages[0].message : error._message
            });
        }

        this.props.setBusy(false);
    };

    handleToolbarCancel(): void {
        this._itemsAmount = 0;
        super.handleToolbarCancel();
    }

    getConfirmText(tableAction: TableButtonsAction | string): string | React.ReactElement {
        const activeRowActionCount = this.props.storage.tableAPI?.getState().activeRows.size;

        if (tableAction === PAIR_ACCRUALS_ACTION_ID) {
            return getConfirmationActionText(this.props.storage.t(`Accruals:Dialog.PairActionText`), activeRowActionCount);
        }

        return super.getConfirmText(tableAction);
    }

    getToolbarButtons(): TableButtonsActionType[] {
        return [
            TableButtonsAction.Settings
        ];
    }

    get customToolbarItems(): IToolbarItem[] {
        const action = this.getTableAction();

        return [createCustomTableToolbarItem({
            tableAction: action,
            id: PAIR_ACCRUALS_ACTION_ID,
            iconName: this.isEstimate ? "PairingAccruedItems" : "PairingExpenses",
            label: this.props.storage.t(`Accruals:Dialog.PairActionText`) as string
        })];
    }

    getStaticToolbarItems = (): TToolbarItem[] => {
        if (this.getTableAction() !== PAIR_ACCRUALS_ACTION_ID) {
            return [];
        }

        const formattedAmount = formatCurrency(this._itemsAmount, getCompanyCurrency(this.props.storage.context));
        return [(
            <ToolbarStaticText key={"summary"}>
                {this.props.storage.t("Accruals:Dialog.SumOfSelected")}: <ColoredText
                color="C_SEM_text_warning"><strong>{formattedAmount}</strong></ColoredText>
            </ToolbarStaticText>
        )];
    };

    handleActiveRowActionCountChange = async (): Promise<void> => {
        const rows = this.props.storage.tableAPI.getState().rows;
        const selectedRows = await this.props.storage.tableAPI.getAffectedRows();
        this._itemsAmount = selectedRows.reduce((sum: number, item) => {
            const row = getRow(rows, item.bc);
            const values = row.customData.entity as IDocumentEntity;

            return sum + values.Amount;
        }, 0);

        // refresh would reload secondary query filters, we just need to rerender confirmation buttons
        this.forceUpdate();
    };

    getIcon = (isActive: boolean) => isActive ? ChainedIcon : UnchainedIcon;

    getCustomRowAction = memoizeOne((): TCustomRowAction => {
        return {
            actionType: RowAction.Custom,
            render: (args: IActionRendererArgs) => {
                const isActive = args.actionState === ActionState.Active;
                const Icon = this.getIcon(isActive);
                return (
                        <IconButton title=""
                                    onClick={args.onClick}
                                    isDisabled={args.isDisabled}
                                    isDecorative>
                            <Icon width={IconSize.S} height={IconSize.S}/>
                        </IconButton>
                );
            }
        };
    });

    getRowIcon = (id: TId, row: IRowProps, rowAction: IRowAction): React.ComponentType<IIconProps> => {
        if (rowAction) {
            return null;
        }

        return (this._selectedItems?.find(item => item.toString() === id.toString())) && this.getIcon(true);
    };

    render() {
        if (this.props.busy) {
            return this.props.busyIndicator;
        }
        return (
            <View hotspotContextId={this.props.storage.id}>
                {this.renderHeader()}
                {this.renderFilterBar()}
                {this.renderToolbar()}
                {this.props.alert}
                <TableWrapper>
                    <SmartTable
                        {...this.getTableSharedProps()}
                        initialActiveRows={this._selectedItems}
                    />
                </TableWrapper>
                {this.renderCustomizationDialog()}
            </View>
        );
    }
}

export default withAlert({
    autoHide: true,
    position: AlertPosition.CenteredBottom
})(withBusyIndicator({ passBusyIndicator: true })(PairingAccrualsTableView));