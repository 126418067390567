import React from "react";
import { IAlertProps } from "@components/alert/Alert";
import { IPopperOptions } from "@components/popperWrapper";
import {
    ICashBoxEntity,
    ICompanyBankAccountEntity,
    ICompanyEntity,
    ICurrencyUsedByCompanyEntity,
    IFiscalYearEntity,
    IOrganizationEntity,
    ISubscriptionEntity,
    ITenantEntity,
    IUserEntity
} from "@odata/GeneratedEntityTypes";
import { WithAuthContext } from "../authContext/withAuthContext";
import { OData } from "@odata/OData";
import { CompanyPermissionCode, CurrencyCode, FeatureCode, GeneralPermissionCode } from "@odata/GeneratedEnums";
import { FormStorage } from "../../views/formView/FormStorage";
import { ICompany } from "@odata/EntityTypes";
import { TRecordAny, ValueOf } from "../../global.types";
import { ExtendedShellContent } from "../../views/main/Shell.types";
import { P13n } from "@utils/p13n";
import { IPersonalizationSettings, PersonalizationSettings } from "./AppContext.utils";
import { TTheme } from "../../theme";
import { IMenuSelected } from "@components/navigation";
import { RouteComponentProps } from "react-router";
import { WithTranslation } from "react-i18next";
import Emittery from "emittery";

export const AppContext = React.createContext<IAppContext>(undefined);

export enum AppMode {
    // default mode, default shell bar menu - some company is selected
    Company = "Company",
    // shell bard and menu are switched to our agenda settings
    OrganizationSettings = "OrganizationSettings"
}

export interface IBreadcrumbItem {
    key: string;
    link: string;
    title: string;
}

export interface ILockAlert {
    alert: IAlertProps | (() => IAlertProps);
    popperOptions?: IPopperOptions;
}

export interface IBreadcrumb {
    items: IBreadcrumbItem[];
    lockable: boolean | (() => boolean);
    isLockDisabled?: boolean | (() => boolean);
    onLockClick?: (event: React.MouseEvent) => void;
    lockAlert?: ILockAlert;
}

export interface ICompanyTilesResponseEntity {
    Company: ICompanyEntity;
    CanAccessCustomerPortal: boolean;
}

export interface IContextProps extends RouteComponentProps, WithTranslation, WithAuthContext {
    oData: OData;
    companyPermissions: Record<string, Set<CompanyPermissionCode>>;
    generalPermissions: Set<GeneralPermissionCode>;
}

export interface IAuditTrailData {
    isShown?: boolean;
    storage?: FormStorage;
}

export enum ContextEvents {
    CompanyChanged = "companyChanged",
    // called when company form is saved
    CompanyUpdated = "companyUpdated",
    OpenExtendedShell = "openExtendedShell",
    ExtendedShellHidden = "extendedShellHidden",
    ModalToggled = "modalToggled",
    RecalculateDrafts = "recalculateDrafts",
    SelectedRowChanged = "selectedRowChanged",
    SelectedMenuChanged = "selectedMenuChanged",
    ViewBreadcrumbsChanged = "viewBreadcrumbsChanged",
    //force recalculate position for every registered popper
    UpdatePoppers = "updatePoppers",
    //force refresh tickets unreadCount -> will be moved to socket
    TicketsUnreadCount = "TicketsUnreadCount"
}

export interface IAppContext {
    error: string;
    getCompany: () => ICompany;
    getCompanyId: () => number;
    getDefaultCurrency: () => CurrencyCode;
    getOrganization: () => IOrganizationEntity;
    setAppMode: (mode: AppMode) => void;
    getAppMode: () => AppMode;
    setCurrentCompanyId: (companyId: number, isCustomerPortal?: boolean) => void;
    updateCompany: (companyId: number, updateValues: TRecordAny) => void;
    updateCompanySpecificData: () => Promise<void>[];
    eventEmitter: Emittery;
    rerenderApp: () => void;

    getData: () => IAppContextData;
    setData: (newData: Partial<IAppContextData>) => void;
    setAppBusy: (isBusy: boolean) => void;
    isAppBusy: boolean;
    // number of opened modals (mostly Dialogs)
    modalOpened: number;
    isModalOpened: () => boolean;
    openExtendedShell: (type: ExtendedShellContent) => void;
    updateCompanies: () => void;
    getFYPromise: () => Promise<void>;
    updateTenantAndSubscription: () => Promise<Awaited<void>[]>;
    getCompanyBankAccounts: (activeOnly?: boolean) => ICompanyBankAccountEntity[];
    getCashBoxes: (activeOnly?: boolean) => ICashBoxEntity[];
    getCompanyPermissions: () => Set<CompanyPermissionCode>;
    getGeneralPermissions: () => Set<GeneralPermissionCode>;
    updateFiscalYears: () => void;
    updateCompanyBankAccounts: () => void;
    updateCashBoxes: () => void;
    updateCurrenciesUsedByCompany: () => void;
    // Flag if all data are loaded on init. While loaded is false the context is not ready yet.
    loaded: boolean;
    // set to true on company change while loading company specific data that are needed for the rest of the app to render properly.
    isLoadingCompany: boolean;
    p13n: P13n;
    feP13nSettings: PersonalizationSettings;
    resetError: () => void;
    changeUserSetting: (key: keyof IUserEntity, val: string) => Promise<void>;
    changePersonalizationSetting: (key: keyof IPersonalizationSettings, val: ValueOf<IPersonalizationSettings>) => void;
    getCurrentTheme: () => TTheme;
    getSelectedMenu: () => IMenuSelected;
    setSelectedMenu: (menu: IMenuSelected) => void;

    openAuditTrailDialog?: (storage: FormStorage) => void;
    closeAuditTrailDialog?: () => void;
    auditTrailData?: IAuditTrailData;

    setViewBreadcrumbs: (breadcrumbs: IBreadcrumb) => void;
    getViewBreadcrumbs: () => IBreadcrumb;

    setCustomData: (data: TRecordAny) => void;
    getAddingNewCompany: () => boolean;
    /** User can't access most of the app,
     * either because agenda has been terminated
     * or hasn't been paid for for too long */
    hasLimitedAccess: () => boolean;
    isFeatureSwitchEnabled: (feature: FeatureCode) => boolean;
    refreshAppData: () => Promise<void>;
}

export interface IBreadcrumbWithRelatedPathname extends IBreadcrumb {
    relatedPathname?: string;
}

export interface IAppContextData {
    organization?: IOrganizationEntity;
    tenant?: ITenantEntity;
    subscription?: ISubscriptionEntity;
    company?: ICompany;
    companies?: ICompany[];
    fiscalYears?: IFiscalYearEntity[];
    companyBankAccounts?: ICompanyBankAccountEntity[];
    cashBoxes?: ICashBoxEntity[];
    // list doesn't include CZK, only other currencies and their exchange rate
    currenciesUsedByCompany?: ICurrencyUsedByCompanyEntity[];

    viewBreadcrumbs?: IBreadcrumbWithRelatedPathname;
    selectedMenu?: IMenuSelected;

    enabledFeatures?: FeatureCode[];

    userSettings?: IUserEntity;
    personalizationSettings?: IPersonalizationSettings;

    custom?: TRecordAny;
}