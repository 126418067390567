import React from "react";
import { Description, ProgressValue, ProgressWrapper, StyledCanvas, StyledProgressBar } from "./ProgressBar.styles";
import { WithTranslation, withTranslation } from "react-i18next";
import { withTheme } from "styled-components/macro";
import { PropsWithTheme } from "../../theme";
import { transparentize } from "polished";
import TestIds from "../../testIds";
import { ProgressBarColor } from "./ProgressBar.utils";
import { roundToDecimalPlaces } from "../../utils/general";

export type TProgressBarDescFormatter = (value: number, parts: number) => string | React.ReactElement;

export interface IProps {
    value: number;
    parts: number;
    showMarks?: boolean;
    /** Displayed number of parts in description is value subtracted from parts. */
    isDescriptionInverseValue?: boolean;
    /** Changes the color scheme */
    color?: ProgressBarColor;
    /** Custom string before the value/of part */
    customDescription?: string;
    /** Fully custom description */
    descriptionFormatter?: TProgressBarDescFormatter;
    descriptionFlexDirection?: "row" | "column";
    style?: React.CSSProperties;
    className?: string;
    /** Custom description formatter */
    hideNumbers?: boolean;
}

interface IPropsExtended extends IProps, PropsWithTheme, WithTranslation {

}

class ProgressBar extends React.PureComponent<IPropsExtended> {
    static defaultProps: Partial<IProps> = {
        descriptionFlexDirection: "row",
        color: ProgressBarColor.Default
    };

    canvasRef = React.createRef<HTMLCanvasElement>();

    componentDidMount() {
        if (this.canvasRef.current) {
            this.canvasRef.current.width = this.canvasRef.current.parentElement.offsetWidth;
        }

        this.renderCanvas();
    }

    componentDidUpdate(prevProps: Readonly<IPropsExtended>, prevState: Readonly<{}>, snapshot?: any) {
        this.renderCanvas();
    }

    renderCanvas = () => {
        if (!this.props.showMarks) {
            return;
        }

        const ctx = this.canvasRef.current.getContext("2d");
        const width = this.canvasRef.current.width;
        const partWidth = width / this.props.parts;

        ctx.clearRect(0, 0, this.canvasRef.current.width, this.canvasRef.current.height);

        for (let i = 1; i < this.props.parts; i++) {
            let color = "transparent";

            if (i < this.props.value) {
                color = this.props.theme.C_ACT_light_line;
            } else if (i > this.props.value) {
                color = transparentize(0.5, this.props.theme.C_ACT_main);
            }

            ctx.fillStyle = color;
            ctx.fillRect(i * partWidth - 1, 0, 1, 8);
        }

    };

    getPercentage = () => {
        if (this.props.parts === 0) {
            return 100;
        }

        return this.props.value * 100 / this.props.parts;
    };

    renderDescription = () => {
        const { parts, value, hideNumbers } = this.props;
        const calcValue = roundToDecimalPlaces(0, this.props.isDescriptionInverseValue ? parts - value : value);

        if (this.props.descriptionFormatter) {
            const description = this.props.descriptionFormatter(calcValue, this.props.parts);
            return typeof description === "string" ? <span>{description}</span> : description;
        }

        const showNumbers = !hideNumbers && parts > 0;

        return (
            <>
                <span>{this.props.customDescription ?? this.props.t(`Components:ProgressBar.${showNumbers ? "Remaining" : "InProgress"}`)}&nbsp;</span>
                {showNumbers && <span>{`${calcValue}/${parts}`}</span>}
            </>
        );
    };

    render() {
        return (
            <StyledProgressBar data-testid={TestIds.ProgressBar}
                               style={this.props.style}
                               className={this.props.className}>
                <ProgressWrapper $color={this.props.color}>
                    <ProgressValue data-testid={TestIds.ProgressValue}
                                   $value={this.getPercentage()}
                                   $color={this.props.color}/>
                    {this.props.showMarks &&
                        <StyledCanvas ref={this.canvasRef}/>
                    }
                </ProgressWrapper>
                <Description data-testid={TestIds.ProgressBarDescription}
                             flexDirection={this.props.descriptionFlexDirection}>
                    {this.renderDescription()}
                </Description>

            </StyledProgressBar>
        );
    }
}

export default withTranslation(["Components"])(withTheme(ProgressBar));