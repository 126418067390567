import React from 'react';
import { IDefinition, IGetDefinition } from "../PageUtils";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef,
    TFilterDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import i18next from "i18next";
import { ISplitPageTableDef, SECONDARY_FILTERS_ALL } from "../../views/table/TableView.utils";
import {
    CustomerPortalPaymentEntity,
    DocumentEntity,
    EntitySetName,
    EntityTypeName,
    IBankAccountEntity
} from "@odata/GeneratedEntityTypes";
import { IFormDef } from "../../views/formView/Form";
import CustomerPortalPaymentForm from "./CustomerPortalPaymentFormView";
import { TValue } from "../../global.types";
import { IFormatOptions } from "@odata/OData.utils";
import { formatBankAccount, formatBankAccountWithoutType, getTypeFromAccount } from "@utils/BankUtils";
import { ColoredText } from "../../global.style";
import { BankAccountType, FieldType, Sort } from "../../enums";
import { DefaultTheme } from "styled-components/macro";
import { getLinks, withDisplayName } from "@components/smart/GeneralFieldDefinition";
import { TCellValue } from "@components/table";
import { IFieldDef, IFieldDefFn, IFieldInfoProperties, IGetValueArgs } from "@components/smart/FieldInfo";
import {
    IsPaidWrapper,
    QrCodeDataColumn,
    QrCodeDataValue,
    QrCodeDataValueInner,
    QrCodeDataWrapper,
    QrCodeValue,
    QrCodeWrapper,
    TooltipIconCopyLinkQrCode
} from "./CustomerPortalPayment.styles";
import CurrencyType, { formatCurrency } from "../../types/Currency";
import Switch from "../../components/inputs/switch";
import { SwitchType } from "@components/inputs/switch/Switch";
import { setDefByEntityType } from "../getDefByEntityType";
import { getCustomerPaymentBankAccountsFieldBaseDef } from "../banks/bankAccounts/BankAccounts.utils";
import BindingContext, { createPath, IEntity } from "../../odata/BindingContext";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import DateType from "../../types/Date";
import { currentUserIsCustomer } from "@pages/admin/users/Users.utils";

const accountFormatter = (value: TValue, args: IFormatOptions) => {
    return formatBankAccountWithoutType(args.entity as IBankAccountEntity);
};

enum CustomerPaymentTabNames {
    ToPay = "topay",
    Paid = "paid"
}

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const filterBarDef: IFilterGroupDef[] = [{
        ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
        isValueHelp: true,
        defaultFilters: [
            CustomerPortalPaymentEntity.NumberOurs, CustomerPortalPaymentEntity.TransactionAmount, CustomerPortalPaymentEntity.DateDue, CustomerPortalPaymentEntity.BusinessPartnerName
        ],
        filterDefinition: {
            [CustomerPortalPaymentEntity.NumberOurs]: {},
            [CustomerPortalPaymentEntity.TransactionAmount]: {},
            [CustomerPortalPaymentEntity.DateDue]: {},
            [CustomerPortalPaymentEntity.BusinessPartnerName]: {},
            [CustomerPortalPaymentEntity.NumberTheirs]: {},
        }
    }];

    const table: ISplitPageTableDef = {
        filterBarDef,
        tabs: [{
            id: SECONDARY_FILTERS_ALL,
            title: i18next.t("CustomerPortalPayment:All")
        }, {
            id: CustomerPaymentTabNames.ToPay,
            value: false,
            title: i18next.t("CustomerPortalPayment:Title")
        }, {
            id: CustomerPaymentTabNames.Paid,
            value: true,
            title: i18next.t("CustomerPortalPayment:Paid")
        }],
        tabsSettings: {
            filterFieldName: CustomerPortalPaymentEntity.IsPaid
        },
        preventStoreVariant: true,
        id: "CustomerPortalPaymentTable",
        initialSortBy: [{ id: CustomerPortalPaymentEntity.DateDue, sort: Sort.Asc }],
        additionalProperties: [{ id: CustomerPortalPaymentEntity.DocumentType }, { id: CustomerPortalPaymentEntity.BankCode },
            { id: CustomerPortalPaymentEntity.IBAN }, { id: CustomerPortalPaymentEntity.AccountNumber }, { id: CustomerPortalPaymentEntity.AbaNumber },
            { id: CustomerPortalPaymentEntity.Document }, { id: createPath(CustomerPortalPaymentEntity.Document, DocumentEntity.NumberOurs) },
            { id: createPath(CustomerPortalPaymentEntity.Document, DocumentEntity.DocumentTypeCode) }],
        columns: [
            CustomerPortalPaymentEntity.NumberOurs,
            CustomerPortalPaymentEntity.NumberTheirs,
            CustomerPortalPaymentEntity.TransactionAmount,
            CustomerPortalPaymentEntity.IsPaid,
            CustomerPortalPaymentEntity.DateDue,
            CustomerPortalPaymentEntity.BusinessPartnerName,
            CustomerPortalPaymentEntity.AccountNumber
        ],
        columnDefinition: {
            ...withDisplayName(CustomerPortalPaymentEntity.DocumentType),
            [CustomerPortalPaymentEntity.NumberOurs]: {
                formatter: (val: TValue, args?: IFormatOptions) => {
                    const code = getLinks(args, [{
                        LinkedDocument: args.item.Document
                    }]);

                    return code.fragment as TCellValue;
                },
            },
            [CustomerPortalPaymentEntity.NumberTheirs]: {},
            [CustomerPortalPaymentEntity.TransactionAmount]: {},
            [CustomerPortalPaymentEntity.IsPaid]: {
                formatter: (val: TValue, args?: IFormatOptions) => {
                    const color = val ? "C_TEXT_primary" : "C_SEM_el_bad";
                    const text = val ? i18next.t("CustomerPortalPayment:Paid") : i18next.t("CustomerPortalPayment:Title");

                    return {
                        value: <ColoredText color={color as keyof DefaultTheme}><b>{text}</b></ColoredText>,
                        tooltip: text
                    };
                }
            },
            [CustomerPortalPaymentEntity.DateDue]: {},
            [CustomerPortalPaymentEntity.BusinessPartnerName]: {},
            [CustomerPortalPaymentEntity.AccountNumber]: {
                label: i18next.t("CustomerPortalPayment:Account"),
                disableSort: true, // sort won't work correctly, as we are using custom formatter
                formatter: accountFormatter
            }
        },
        title: i18next.t("CustomerPortalPayment:Title")
    };

    const summary: ISummaryItem[] = [
        {
            id: CustomerPortalPaymentEntity.NumberTheirs,
            label: i18next.t("CustomerPortalPayment:NumberTheirs"),
        },
        {
            id: CustomerPortalPaymentEntity.TransactionAmount,
            label: i18next.t("CustomerPortalPayment:Amount"),
            formatter: (val: TValue, args: IFormatOptions) => {
                return formatCurrency(val, args.storage.data.entity.TransactionCurrencyCode);
            }
        },
        {
            id: CustomerPortalPaymentEntity.DateDue,
            label: i18next.t("CustomerPortalPayment:Date"),
            formatter: (val: TValue, args: IFormatOptions) => {
                return DateType.localFormat(val as Date);
            }
        }, {
            id: CustomerPortalPaymentEntity.IsPaid,
            label: i18next.t("CustomerPortalPayment:State"),
            colorFormatter: (val: TValue, data?: IEntity) => {
                return val ? "C_TEXT_primary" : "C_SEM_el_bad";
            },
            formatter: (val: TValue, args: IFormatOptions) => {
                return val ? i18next.t("CustomerPortalPayment:Paid") : i18next.t("CustomerPortalPayment:Title");
            }
        }
    ];

    const _formatAcc = (entity: IEntity) => {
        const type = getTypeFromAccount(entity);
        return formatBankAccount(entity, type);
    };

    const withReadOnly = <T extends IFieldInfoProperties | TFilterDef>(id: string, fieldDef?: T): Record<string, T extends IFieldDef ? IFieldDef : TFilterDef & {
        id: string
    }> => {
        return {
            [id]: {
                id,
                ...fieldDef,
                isReadOnly: true
            }
        };
    };


    const renderValueWithCopyLink = (value: string) => {
        return (
            <>
                <QrCodeDataValue>
                    <QrCodeDataValueInner>
                        <QrCodeValue>{value}</QrCodeValue>
                        {value &&
                            <TooltipIconCopyLinkQrCode
                                link={value}
                                title={i18next.t("CustomerPortalPayment:CopyValue")}
                                successTitle={i18next.t("CustomerPortalPayment:ValueCopied")}
                            ></TooltipIconCopyLinkQrCode>
                        }
                    </QrCodeDataValueInner>
                </QrCodeDataValue>
            </>
        );
    };

    const accRenderer = (entity: IBankAccountEntity) => {
        const type = getTypeFromAccount(entity);
        if (type === BankAccountType.Account) {
            const acc = entity.AccountNumber?.replace(/^0+-/, "");
            const bank = entity.BankCode;
            return (
                <>
                    <tr>
                        <QrCodeDataColumn>{i18next.t("Banks:Accounts.AccountNumber")}:</QrCodeDataColumn>
                        {renderValueWithCopyLink(acc)}
                    </tr>
                    <tr>
                        <QrCodeDataColumn>{i18next.t("Banks:Form.BankCode")}:</QrCodeDataColumn>
                        {renderValueWithCopyLink(bank)}
                    </tr>
                </>
            );
        }

        return (
            <tr>
                <QrCodeDataColumn>{i18next.t("Banks:Pairing.FromAccount")}:</QrCodeDataColumn>
                {renderValueWithCopyLink(_formatAcc(entity))}
            </tr>
        );
    };

    const form: IFormDef = {
        id: "CustomerPortalPaymentForm",
        summary,
        translationFiles: getDefinitions.translationFiles,
        formControl: CustomerPortalPaymentForm,
        title: i18next.t("CustomerPortalPayment:PaidForm"),
        additionalProperties: [{ id: CustomerPortalPaymentEntity.BankCode },
            { id: CustomerPortalPaymentEntity.Document }, { id: createPath(CustomerPortalPaymentEntity.Document, DocumentEntity.NumberOurs) },
            { id: createPath(CustomerPortalPaymentEntity.Document, DocumentEntity.DocumentTypeCode) }],
        // we don't use isReadOnly as the form is still "savable" and there are some CSS shenanigans with alert for
        // readOnly forms we don't want to deal with
        fieldDefinition: {
            ...withReadOnly(CustomerPortalPaymentEntity.BusinessPartnerName),
            ...withReadOnly(CustomerPortalPaymentEntity.LegalNumber),
            ...withReadOnly(CustomerPortalPaymentEntity.SymbolVariable),
            ...withReadOnly(CustomerPortalPaymentEntity.SymbolConstant),
            ...withReadOnly(CustomerPortalPaymentEntity.SymbolSpecific),
            ...withReadOnly(CustomerPortalPaymentEntity.RemittanceInformation),
            ...withReadOnly(CustomerPortalPaymentEntity.TransactionAmount),
            ...withReadOnly(CustomerPortalPaymentEntity.TransactionCurrencyCode),

            DocumentType: {
                // this force expand for query
                isReadOnly: true,
                type: FieldType.ComboBox,
                fieldSettings: {
                    displayName: "Name"
                },
            },
            NumberOurs: {
                isReadOnly: true,
                formatter: (val: TValue, args?: IFormatOptions) => {
                    const code = getLinks(args, [{
                        LinkedDocument: args.entity.Document
                    }]);

                    return code.fragment as TCellValue;
                }
            },
            QrCode: {
                render: (args: IFieldDefFn) => {
                    const entity = args.storage.data.entity;
                    return (
                        <QrCodeWrapper>
                            <img width="150px" height="150px" src={`data:image/png;base64, ${args.props.value}`}
                                 alt="QR code"/>
                            <QrCodeDataWrapper>
                                <table>
                                    {accRenderer(entity)}
                                    <tr>
                                        <QrCodeDataColumn> {i18next.t("Banks:Pairing.SymbolVariable")}:</QrCodeDataColumn>
                                        {renderValueWithCopyLink(entity.SymbolVariable)}
                                    </tr>
                                    <tr>
                                        <QrCodeDataColumn>{`${i18next.t("Banks:Transactions.Amount")} (${CurrencyType.getCurrencyUnit(entity.TransactionCurrencyCode)})`} :</QrCodeDataColumn>
                                        {renderValueWithCopyLink(entity.TransactionAmount)}
                                    </tr>
                                </table>
                            </QrCodeDataWrapper>
                        </QrCodeWrapper>
                    );
                }
            },
            IsPaid: {
                isVisible: (args:IGetValueArgs) => {
                    return currentUserIsCustomer(args.storage.context);
                },
                render: (args: IFieldDefFn) => {
                    const _change = (checked: boolean) => {
                        args.events.onChange({
                            value: checked,
                            parsedValue: checked,
                            type: FieldType.Switch,
                            bindingContext: args.props.info.bindingContext
                        });
                    };

                    return (
                        <IsPaidWrapper>
                            <Switch type={SwitchType.YesNo} hasSemanticColor
                                    label={i18next.t("Banks:Pairing.AmountPaid")}
                                    checked={args.props.checked}
                                    onChange={_change}></Switch>
                        </IsPaidWrapper>
                    );
                }
            },
            [BindingContext.localContext("FormattedAccount")]: {
                isReadOnly: true,
                label: i18next.t("CustomerPortalPayment:Account"),
                formatter: (val: TValue, args: IFieldDefFn) => {
                    return _formatAcc(args.storage.data.entity);
                },
                collapsedRows: [[{ id: CustomerPortalPaymentEntity.Country }],
                    [
                        { id: CustomerPortalPaymentEntity.AbaNumber },
                        { id: CustomerPortalPaymentEntity.AccountNumber },
                        { id: CustomerPortalPaymentEntity.IBAN },
                        { id: CustomerPortalPaymentEntity.SWIFT }
                    ]]
            },
            ...getCustomerPaymentBankAccountsFieldBaseDef()
        },
        groups: [{
            id: "noteGroup",
            rows: [[{ id: CustomerPortalPaymentEntity.BusinessPartnerName }, { id: CustomerPortalPaymentEntity.LegalNumber },
                { id: CustomerPortalPaymentEntity.DocumentType }, { id: CustomerPortalPaymentEntity.NumberOurs }]]
        }, {
            id: "Payment",
            title: i18next.t("Banks:Transactions.Payment"),
            rows: [[
                { id: BindingContext.localContext("FormattedAccount") }
            ]]
        }, {
            id: "Rest",

            rows: [
                [{ id: CustomerPortalPaymentEntity.SymbolVariable }, { id: CustomerPortalPaymentEntity.SymbolSpecific },
                    { id: CustomerPortalPaymentEntity.SymbolConstant }, { id: CustomerPortalPaymentEntity.RemittanceInformation }],
                [{ id: CustomerPortalPaymentEntity.QrCode }],
                [{ id: CustomerPortalPaymentEntity.IsPaid }]]
        }]
    };

    return {
        entitySet: EntitySetName.CustomerPortalPayments,
        form,
        table
    };
};

getDefinitions.translationFiles = ["CustomerPortalPayment", "Banks", "Common"];
setDefByEntityType(EntityTypeName.CustomerPortalPayment, getDefinitions);