import { PlusIcon } from "@components/icon";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

import BusyIndicator from "../components/busyIndicator";
import { ButtonSize } from "../components/button/Button.utils";
import FocusManager, { FocusDirection } from "../components/focusManager";
import { ScrollBar } from "../components/scrollBar";
import { changeCompany } from "../contexts/appContext/AppContext.utils";
import { CustomerPortal } from "../pages/home/CustomerPortal.utils";
import { ROUTE_LOGIN, ROUTE_LOGIN_TENANT_REGISTER, ROUTE_LOGOUT } from "../routes";
import TestIds from "../testIds";
import { BackArrow } from "./components/BackArrow";
import Redirects from "./components/Redirects";
import TenantSelector from "./components/TenantSelector";
import {
    AnimationType,
    getLoginOptionsData,
    Id,
    ILoginOptions,
    ISessionTenantData,
    LoginTranslationNamespaces
} from "./Login.utils";
import {
    LoginTitle,
    NewTenantButtonStyled,
    TenantButtonWrapper,
    Tenants,
    TenantsBody,
    TenantScrollbarWrapper
} from "./LoginPage.styles";
import { useLoginDispatch, useLoginSelector } from "./state/hooks";
import { setAnimationType } from "./state/loginSlice";
import { loadPostLoginVerifications, selectSessionData } from "./state/sessionSlice";
import { handleTenantSwitch } from "./TenantPicker.utils";

interface IProps {
}

const getOptionId = (opt: ILoginOptions) => {
    return opt.isCustomer ? `${opt.TenantId}-${opt.CompanyId}` : opt.TenantId;
};

const TenantPicker: React.FC<IProps> = () => {
    const { t } = useTranslation([...LoginTranslationNamespaces]);
    const dispatch = useLoginDispatch();
    const session = useLoginSelector(selectSessionData);

    const [skipActiveSessionCheck, setSkipActiveSessionCheck] = useState(true);
    const [loginOptions, setLoginOptions] = useState<ILoginOptions[]>([]);
    const [redirect, setRedirect] = useState<string>(null);
    const [isBusy, setIsBusy] = useState<boolean>(true);

    useEffect(() => {
        (async function loadData() {
            const loginOptions = await getLoginOptionsData();

            if (loginOptions) {
                setLoginOptions(loginOptions);
                await dispatch(loadPostLoginVerifications());

                if (loginOptions.length === 0) {
                    setRedirect(ROUTE_LOGIN_TENANT_REGISTER);
                }
            } else {
                setRedirect(ROUTE_LOGIN);
            }
            setIsBusy(false);
        })();
    }, []);

    useEffect(() => {
        dispatch(setAnimationType(AnimationType.StaticBiker));
    }, []);

    const handleTenantClicked = async (id: Id) => {
        if (isBusy) {
            return;
        }

        const option = loginOptions.find(opt => id === getOptionId(opt));

        try {
            const sessionParams: ISessionTenantData = {
                TenantId: option.TenantId
            };

            CustomerPortal.isActive = option.isCustomer;
            if (option.isCustomer) {
                // we need to set current companyId also
                await changeCompany(option.CompanyId, true);

                sessionParams.LoginToCustomerPortal = true;
                sessionParams.CompanyId = option.CompanyId;
            }

            setIsBusy(true);

            const tenantSwitched = await handleTenantSwitch(session, sessionParams, setRedirect, option);
            if (tenantSwitched) {
                setSkipActiveSessionCheck(false);
            }
        } finally {
            setIsBusy(false);
        }
    };

    const handleAddTenantClicked = () => {
        setRedirect(ROUTE_LOGIN_TENANT_REGISTER);
    };

    if (redirect) {
        return (
            <Redirect to={redirect}/>
        );
    } else {
        const hasAnyOption = loginOptions?.length > 0;
        const hasTenantOption = loginOptions?.find(item => !item.isCustomer);
        const isPureCustomer = hasAnyOption && !hasTenantOption;
        const showTitle = !isBusy || hasAnyOption;
        return (<>
            {isBusy && <BusyIndicator isDelayed/>}
            <Redirects skipActiveSessionCheck={skipActiveSessionCheck}/>
            <LoginTitle data-testid={TestIds.Title}>
                <BackArrow url={ROUTE_LOGOUT}/>
                {showTitle && t(`Login:TenantRegister.${isPureCustomer ? "PickTitleCompanies" : "PickTitle"}`)}
            </LoginTitle>
            <TenantsBody>
                <TenantScrollbarWrapper>
                    <ScrollBar style={{
                        overflowX: "hidden"
                    }}>
                        <FocusManager direction={FocusDirection.Vertical}
                            // todo disable (and the keyboard click)when busy?
                            // isDisabled={this.state.isBusy}
                        >
                            {({ itemProps, wrapperProps }) => (
                                <Tenants {...wrapperProps}
                                         ref={wrapperProps.ref as React.RefObject<HTMLDivElement>}>
                                    {loginOptions.map(opt => {
                                        const id = getOptionId(opt);
                                        return (
                                            <TenantSelector key={id}
                                                            id={id}
                                                            name={opt.CompanyName ?? opt.TenantName}
                                                            isActive={opt.IsTenantActive}
                                                            isCustomer={opt.isCustomer}
                                                            onClick={handleTenantClicked}
                                                            itemProps={itemProps}/>
                                        );
                                    })}
                                </Tenants>
                            )}
                        </FocusManager>
                    </ScrollBar>
                </TenantScrollbarWrapper>
                <TenantButtonWrapper>
                    <NewTenantButtonStyled onClick={handleAddTenantClicked}
                                           icon={<PlusIcon/>}
                                           size={ButtonSize.Big}
                                           isDisabled={isBusy}
                                           isTransparent>
                        {t("Login:TenantRegister.AddNew")}
                    </NewTenantButtonStyled>
                </TenantButtonWrapper>
            </TenantsBody>
        </>);
    }
};

export default TenantPicker;
