import React from "react";
import {
    Content,
    ElCol,
    ElectronicSubmissionCellStyled,
    HeaderCol,
    HeaderTile,
    IconWrapper,
    RowHeaderCell,
    TableStyled,
    TileTitle
} from "./ElectronicSubmission.styles";
import { capitalize } from "@utils/string";
import DateType, { DateFormat, getUtcDateBy, getUtcDayjs } from "../../types/Date";
import { getIcon } from "@components/icon";
import { BreadCrumbProvider } from "../../components/breadCrumb";
import View from "../../views/View";
import Header from "../../components/header/Header";
import YearSwitcher from "./YearSwitcher";
import ElectronicSubmissionTableCell from "./ElectronicSubmissionTableCell";
import {
    ElectronicSubmissionNamespaces,
    getSubmissionIdBy,
    getVatStatementPeriod,
    isSubmissionLocked
} from "./ElectronicSubmission.utils";
import { withTranslation, WithTranslation } from "react-i18next";
import {
    ElectronicSubmissionTypeCode,
    GeneralPermissionCode,
    VatStatementFrequencyCode,
    VatStatusCode
} from "@odata/GeneratedEnums";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { AUDIT_TRAIL, ROUTE_ELECTRONIC_SUBMISSION } from "../../routes";
import { IElectronicSubmissionEntity } from "@odata/GeneratedEntityTypes";
import { Dayjs } from "dayjs";
import { IHistoryState } from "../../model/Model";
import { WithPermissionContext, withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { getCompanyVatStatusForDate } from "@utils/CompanyUtils";

interface IProps extends WithTranslation, WithPermissionContext {
    year: number;
    type?: ElectronicSubmissionTypeCode;
    month?: number;
    onSelectYear?: (year: number) => void;
    onSelectDetail?: (type: ElectronicSubmissionTypeCode, month: number) => void;
    data?: Map<string, IElectronicSubmissionEntity>;
    firstVatPeriodDate?: Dayjs;
    lastVatPeriodDate?: Dayjs;
    vatFrequency?: VatStatementFrequencyCode;
    initialHistoryState?: IHistoryState;
}

class ElectronicSubmissionOverview extends React.Component<IProps> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    handleChangeYear = (year: number) => {
        this.props.onSelectYear?.(year);
    };

    handleSelect = (type: ElectronicSubmissionTypeCode, date: Date) => {
        this.props.onSelectDetail(type, date.getMonth());
    };

    renderVatStatementCell(date: Date) {
        const period = getVatStatementPeriod(date, this.props.vatFrequency);
        if (!period.from.isSame(date, "day")) {
            return null;
        }
        const { firstVatPeriodDate, lastVatPeriodDate, vatFrequency, data } = this.props;

        // company is VatPayer at least part of the month
        const vatStatus = getCompanyVatStatusForDate(this.context.getCompany(), date);
        const vatStatusAtTheEnd = getCompanyVatStatusForDate(this.context.getCompany(), getUtcDayjs(date).endOf("month").toDate());
        const isVatPayer = !!vatFrequency && (vatStatus === VatStatusCode.VATRegistered || vatStatusAtTheEnd === VatStatusCode.VATRegistered);
        const size = vatFrequency === VatStatementFrequencyCode.Monthly ? 1 : 3;

        const submission = data.get(getSubmissionIdBy(ElectronicSubmissionTypeCode.VatStatement, date));
        const isLocked = isSubmissionLocked(submission);

        const isEmptyPeriod = (!isVatPayer && !isLocked) || firstVatPeriodDate?.isAfter(date, "day") || lastVatPeriodDate?.isBefore(date, "day");
        if (isEmptyPeriod) {
            return /*EmptyCell*/(<ElectronicSubmissionCellStyled rowSpan={size}/>);
        }

        const isSelected = this.props.type === ElectronicSubmissionTypeCode.VatStatement
            && this.props.year === date.getFullYear() && this.props.month === date.getMonth();

        // we are displaying end of period date
        const displayedDate = getUtcDayjs(date).endOf("month").add(size - 1, "months").toDate();

        return (
            <ElectronicSubmissionTableCell date={date}
                                           displayedDate={displayedDate}
                                           size={size}
                                           type={ElectronicSubmissionTypeCode.VatStatement}
                                           onSelect={this.handleSelect}
                                           isLocked={isLocked}
                                           isSelected={isSelected}/>
        );
    }

    renderRow = (date: Date) => {
        return (
            <tr key={date.getTime().toString()}>
                <RowHeaderCell>
                    {capitalize(DateType.format(date, DateFormat.month))}
                </RowHeaderCell>
                {this.renderVatStatementCell(date)}
            </tr>
        );
    };

    renderRows = (year: number) => {
        const rows = [];
        for (let i = 0; i < 12; i++) {
            const date = getUtcDateBy(year, i, 1);
            rows.push(this.renderRow(date));
        }

        return rows;
    };

    renderHeaderTile = (iconName: string, title: string) => {
        const Icon = getIcon(iconName);

        return (
            <HeaderTile>
                <IconWrapper>
                    <Icon/>
                </IconWrapper>
                <TileTitle>
                    {title}
                </TileTitle>
            </HeaderTile>
        );
    };

    render() {
        return (<>
            <BreadCrumbProvider back={this.props.initialHistoryState?.back}
                                customBreadCrumbs={this.props.initialHistoryState?.breadCrumbs}
                                removeLast={!!this.props.type}/>
            <View hotspotContextId={"electronicSubmissionOverview"}>
                <Header title={this.props.t("ElectronicSubmission:Title")}
                        icons={[{
                            id: "auditTrail",
                            label: this.props.t("Audit:AuditTrail"),
                            iconName: "AuditTrail",
                            link: `${ROUTE_ELECTRONIC_SUBMISSION}/${AUDIT_TRAIL}`,
                            isDisabled: !this.props.permissionContext?.generalPermissions?.has(GeneralPermissionCode.AuditTrail)
                        }]}/>
                <Content>
                    <TableStyled>
                        <thead>
                        <tr>
                            <ElCol>
                                <YearSwitcher year={this.props.year} onYearMove={this.handleChangeYear}/>
                            </ElCol>
                            <HeaderCol>
                                {this.renderHeaderTile("VatStatement", this.props.t("ElectronicSubmission:VatSubmission.Title"))}
                            </HeaderCol>
                        </tr>
                        </thead>
                        <tbody>
                        {this.renderRows(this.props.year)}
                        </tbody>
                    </TableStyled>
                </Content>
            </View>
        </>);
    }
}

export default withTranslation(ElectronicSubmissionNamespaces)(withPermissionContext(ElectronicSubmissionOverview));