import React from "react";
import { FileText } from "./TextViewer.style";
import BusyIndicator from "../../busyIndicator/BusyIndicator";
import { StyledFileViewer } from "../FileViewers.styles";
import { WithTranslation, withTranslation } from "react-i18next";
import { Header } from "../pdfViewer/PDFViewer.style";
import TestIds from "../../../testIds";
import { Toolbar } from "../../toolbar/Toolbar";
import { TValue } from "../../../global.types";
import {
    FileToolbarItem,
    getFileNameFromSrc,
    getSharedToolbarDefinition,
    IFileViewerProps
} from "../FileViewers.utils";
import NoPreviewSquirrel from "../NoPreviewSquirrel";
import { saveAs } from "file-saver";

interface IProps extends IFileViewerProps, WithTranslation {

}

interface IState {
    loaded: boolean;
    text: string;
    error: string;
}

class TextViewer extends React.PureComponent<IProps, IState> {
    static defaultProps: Partial<IProps> = {
        hiddenItems: []
    };

    state = {
        loaded: false,
        text: "",
        error: ""
    };

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (this.props.src !== prevProps.src) {
            this.init();
        }
    }

    init = async () => {
        if (!this.props.src) {
            return;
        }

        this.setState({
            loaded: false
        });

        let text = "";
        let error = "";

        try {
            const res = await fetch(this.props.src);

            if (!res.ok) {
                error = this.props.t("Components:FileViewers.FetchFailed");
            } else {
                text = await res.text();
            }
        } catch (e) {
            error = e.message;
        }


        this.setState({
            text,
            error,
            loaded: true
        });
    };

    renderError = () => {
        return (
            <NoPreviewSquirrel text={this.state.error}/>
        );
    };

    handleItemChange = (id: string, value?: TValue) => {
        switch (id) {
            case FileToolbarItem.Dashboard:
                this.props.onGoToList?.();
                break;
            case FileToolbarItem.Download:
                saveAs(this.props.src, this.props.fileName ?? getFileNameFromSrc(this.props.src));
                break;
            case FileToolbarItem.Remove:
                this.props.onFileRemove?.(this.props.src);
                break;
        }
    };

    render() {
        const def = getSharedToolbarDefinition(this.props.isReadOnly, this.props.hiddenItems, this.props.isRemoveDisabled, this.props.t);

        return (
            <StyledFileViewer data-testid={TestIds.FileViewer}>
                <Header>
                    <Toolbar
                        onItemChange={this.handleItemChange}
                        staticItems={def.staticItems}>
                        {def.items}
                    </Toolbar>
                </Header>
                {!this.state.loaded &&
                <BusyIndicator/>
                }
                {!this.state.error &&
                <FileText data-testid={TestIds.FileText}>
                    {this.state.text}
                </FileText>
                }
                {this.state.error && this.renderError()}
            </StyledFileViewer>
        );
    }
}

export default withTranslation(["Components"])(TextViewer);