import React, { lazy } from "react";
import PrivateRoute from "../routes/PrivateRoute";
import {
    ROUTE_COMPANY_ROLES,
    ROUTE_SUBSCRIPTIONS_EXTENSIONS,
    ROUTE_SUBSCRIPTIONS_INVOICING,
    ROUTE_SUBSCRIPTIONS_PAYMENT_HISTORY,
    ROUTE_SUBSCRIPTIONS_PLAN,
    ROUTE_SYSTEM_ROLES,
    ROUTE_USERS,
    ROUTE_VAT
} from "../routes";
import { GeneralPermissionCode } from "@odata/GeneratedEnums";
import FormRoute from "../routes/FormRoute";
import { EntityTypeName } from "@odata/GeneratedEntityTypes";

const CompanyRoles = lazy(() => import(/* webpackChunkName: "administration" */"../pages/admin/companyRoles"));
const GeneralRoles = lazy(() => import(/* webpackChunkName: "administration" */"../pages/admin/generalRoles"));
const VatRules = lazy(() => import(/* webpackChunkName: "administration" */"../pages/admin/vatRules/VatRules"));
const Plan = lazy(() => import(/* webpackChunkName: "administration" */"../pages/admin/subscriptions/subpages/Plan"));
const Invoicing = lazy(() => import(/* webpackChunkName: "administration" */"../pages/admin/subscriptions/subpages/Invoicing"));
const Extensions = lazy(() => import(/* webpackChunkName: "administration" */"../pages/admin/subscriptions/subpages/Extensions"));
const BillingPaymentHistory = lazy(() => import(/* webpackChunkName: "administration" */"../pages/admin/subscriptions/subpages/BillingPaymentHistory"));
const Users = lazy(() => import(/* webpackChunkName: "administration" */"../pages/admin/users"));

export default function administrationBundle() {
    return [
        <FormRoute path={ROUTE_USERS}
                   key={ROUTE_USERS}
                   entityType={EntityTypeName.User}
                   permission={GeneralPermissionCode.OrganizationManagement}
                   page={Users}/>,
        <FormRoute
                path={ROUTE_COMPANY_ROLES}
                key={ROUTE_COMPANY_ROLES}
                entityType={EntityTypeName.CompanyRole}
                permission={GeneralPermissionCode.OrganizationManagement}
                page={CompanyRoles}/>,
        <FormRoute
                path={ROUTE_SYSTEM_ROLES}
                key={ROUTE_SYSTEM_ROLES}
                entityType={EntityTypeName.GeneralRole}
                permission={GeneralPermissionCode.OrganizationManagement}
                page={GeneralRoles}/>,
        <FormRoute path={ROUTE_VAT}
                   key={ROUTE_VAT}
                   entityType={EntityTypeName.VatClassification}
                   permission={GeneralPermissionCode.CommonSettingsManagement}
                   page={VatRules}/>,
        <PrivateRoute
                path={ROUTE_SUBSCRIPTIONS_PLAN}
                key={ROUTE_SUBSCRIPTIONS_PLAN}
                permission={GeneralPermissionCode.OrganizationManagement}
                component={Plan}/>,
        <PrivateRoute
                path={ROUTE_SUBSCRIPTIONS_INVOICING}
                key={ROUTE_SUBSCRIPTIONS_INVOICING}
                permission={GeneralPermissionCode.OrganizationManagement}
                component={Invoicing}/>,
        <PrivateRoute
                path={ROUTE_SUBSCRIPTIONS_EXTENSIONS}
                key={ROUTE_SUBSCRIPTIONS_EXTENSIONS}
                permission={GeneralPermissionCode.OrganizationManagement}
                component={Extensions}/>,
        <PrivateRoute
                path={ROUTE_SUBSCRIPTIONS_PAYMENT_HISTORY}
                key={ROUTE_SUBSCRIPTIONS_PAYMENT_HISTORY}
                permission={GeneralPermissionCode.OrganizationManagement}
                component={BillingPaymentHistory}/>
    ];
}