import React from "react";
import { FormStorage } from "../../views/formView/FormStorage";
import { CompanyDialog } from "./CompanyDialog";
import { IDefinition } from "../PageUtils";
import { AppContext, ContextEvents, IAppContext } from "../../contexts/appContext/AppContext.types";
import { withOData, WithOData } from "@odata/withOData";
import { withTranslation, WithTranslation } from "react-i18next";
import { withPermissionContext, WithPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { GeneralPermissionCode } from "@odata/GeneratedEnums";
import { getDefinitions } from "./CompanyDef";
import { createBindingContext } from "@odata/BindingContext";
import { EntitySetName } from "@odata/GeneratedEntityTypes";
import { NEW_ITEM_DETAIL } from "../../constants";
import { CompanyFormViewClean } from "./CompanyFormView";


interface IProps extends WithOData, WithTranslation, WithPermissionContext {
    id: number;
    isOpen?: boolean;
    onClose?: (savedId: number) => void;
}

class CompanyDialogPage extends React.PureComponent<IProps> {
    static contextType = AppContext;

    static defaultProps = {
        isOpen: true
    };

    storage: FormStorage;
    _formRef = React.createRef<CompanyFormViewClean>();
    _dialogRef = React.createRef<CompanyDialog>();
    _def: IDefinition;

    constructor(props: IProps, context: IAppContext) {
        super(props);

        const { oData, t } = props;

        this.storage = new FormStorage({
            id: "companies",
            oData,
            t,
            context,
            refresh: () => {
                this._formRef.current?.forceUpdate();
                this._dialogRef.current?.forceUpdate();
            }
        });
    }

    componentDidUpdate(prevProps: IProps) {
        if (this.props.isOpen && this.props.tReady && (!prevProps.isOpen || !prevProps.tReady)) {
            // load data on every open, to prevent etag problems
            this.loadData(this.props.id);
        }
    }

    async loadData(id: number) {
        if (!this._def) {
            this._def = getDefinitions(this.context);
        }

        const bindingContext =
            createBindingContext(EntitySetName.Companies, this.props.oData.getMetadata())
                .addKey(id ?? NEW_ITEM_DETAIL, !id);

        await this.storage.init({
            definition: this._def.form,
            bindingContext
        });
    }

    handleClose = () => {
        this.props.onClose?.(null);
    };

    handleSave = async () => {
        const result = await this._formRef.current.save();

        if (result) {
            const context = this.context as IAppContext;

            await context.updateCompanies();
            context.eventEmitter.emit(ContextEvents.CompanyUpdated);
            this.props.onClose?.(result?.bindingContext?.getKey() as number);
        }
    };

    render() {
        if (!this.props.isOpen || !this.props.tReady) {
            return null;
        }

        return (
            <CompanyDialog passRef={this._formRef}
                           permissionContext={this.props.permissionContext}
                           ref={this._dialogRef}
                           id={this.props.id}
                           storage={this.storage}
                           isReadOnly={!this.props.permissionContext.generalPermissions.has(GeneralPermissionCode.CompanyManagement)}
                           onClose={this.handleClose}
                           onSave={this.handleSave}/>
        );
    }

}

export default withTranslation([...getDefinitions.translationFiles])(withOData(withPermissionContext(CompanyDialogPage)));