import BindingContext, { IEntity, TEntityKey } from "../../odata/BindingContext";
import { Dayjs } from "dayjs";
import { DocumentEntity, IFiscalPeriodEntity, IFiscalYearEntity } from "@odata/GeneratedEntityTypes";
import { FormStorage, IFormStorageDefaultCustomData } from "../../views/formView/FormStorage";
import { getFiscalDataCorrespondingToDateAccountingTransaction } from "../documents/Document.utils";
import { IAppContext } from "../../contexts/appContext/AppContext.types";
import memoizeOne from "../../utils/memoizeOne";
import { FiscalYearStatusCode } from "@odata/GeneratedEnums";
import { Sort } from "../../enums";
import { TRecordAny } from "../../global.types";
import {
    Condition,
    ConditionType,
    IComplexFilter,
    PredefinedFilter
} from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import { uuidv4 } from "@utils/general";
import { getDateFromDateOrDayjs, isDateBetween } from "@components/inputs/date/utils";
import { getUtcDate, getUtcDayjs } from "../../types/Date";

export enum FiscalYearDefaultPeriod {
    Monthly = "Monthly",
    Quarterly = "Quarterly",
    Custom = "Custom"
}

export const DefaultPeriodsPath = "##DefaultPeriods##";

export interface IFiscalYearEntityExtendedEntity extends IFiscalYearEntity {
    [DefaultPeriodsPath]?: FiscalYearDefaultPeriod;
}

export interface IFiscalYearCustomData extends IFormStorageDefaultCustomData {
    periodsBackup?: IFiscalPeriodEntity[];
    arePeriodsValid?: boolean;
}

export const getDefaultFiscalYearDateStart = (): Date => {
    // start of the next month
    return getUtcDayjs().startOf("month").startOf("day").add(1, "month").toDate();
};

export const getDefaultFiscalYearDateEnd = (): Date => {
    // end of the same month, next year
    return getUtcDayjs().endOf("month").startOf("day").add(1, "year").toDate();
};

export interface ICreateDefaultPeriods {
    dateStart: Date;
    dateEnd: Date;
    step?: number;
    includeIds?: boolean;
    fiscalYearNumber?: string;
}

export const createDefaultPeriods = ({
                                         dateStart,
                                         dateEnd,
                                         step = 1,
                                         includeIds,
                                         fiscalYearNumber
                                     }: ICreateDefaultPeriods): IFiscalPeriodEntity[] => {
    const periods: IEntity[] = [];

    if (dateStart && dateEnd) {
        const dayJsdateStart = getUtcDayjs(dateStart);
        const dayJsdateEnd = getUtcDayjs(dateEnd);
        let monthDiff = dayJsdateEnd.diff(dayJsdateStart, "month");
        const fiscalYear = fiscalYearNumber ?? dayJsdateEnd.year().toString();

        if (dayJsdateEnd.diff(dayJsdateStart) < 0) {
            monthDiff = -1;
        }

        for (let i = 0; i <= monthDiff; i += step) {
            const newPeriod: IFiscalPeriodEntity = includeIds ? BindingContext.createNewEntity(i + 1) : {};

            let endMonth = i + step - 1;

            // last period should always end with the dayEnd month
            if (i + step > monthDiff) {
                endMonth = monthDiff;
            }

            newPeriod.Number = getFiscalPeriodNumber(fiscalYear, i / step);
            newPeriod.Name = getFiscalPeriodName(fiscalYear, i / step);
            newPeriod.IsLockedForAP = false;
            newPeriod.IsLockedForAR = false;
            newPeriod.DateStart = dayJsdateStart.add(i, "month").toDate();
            newPeriod.DateEnd = dayJsdateStart.add(endMonth, "month").endOf("month").toDate();

            periods.push(newPeriod);
        }
    }

    return periods;
};

export const getFiscalPeriodName = (fiscalYear: string, index: number): string => {
    return `${fiscalYear}/${String(index + 1).padStart(3, "0")}`;
};

export const getFiscalPeriodNumber = (fiscalYear: string, index: number): string => {
    if (!fiscalYear) {
        return "";
    }

    return `${fiscalYear}/${String(index + 1).padStart(3, "0")}`;
};

export function updatePeriodNumbers(storage: FormStorage, number: string): void {
    const { bindingContext, entity } = storage.data;
    const defaultPeriods = storage.getValue(bindingContext.navigate(DefaultPeriodsPath));

    let index = 0;
    for (const element of bindingContext.iterateNavigation("Periods", entity.Periods)) {
        if (defaultPeriods !== FiscalYearDefaultPeriod.Custom) {
            storage.clearAndSetValue(element.bindingContext.navigate("Name"), getFiscalPeriodName(number, index));
        }
        storage.clearAndSetValue(element.bindingContext.navigate("Number"), getFiscalPeriodNumber(number, index));

        index++;
    }
}

export function getFiscalYearNumbers(dateStart: Date, dateEnd: Date, otherFiscalYears: IFiscalYearEntity[]): string[] {
    const numbers: string[] = [];

    const periodStart = getUtcDayjs(dateStart);
    const periodEnd = getUtcDayjs(dateEnd);

    let endYear: number, startYear: number;
    const findSameFiscalYearNumber = (number: string) => {
        return otherFiscalYears?.find((fiscalYear: IEntity) => fiscalYear.Number === number);
    };

    if (periodEnd.isValid()) {
        endYear = periodEnd.get("year");

        if (!findSameFiscalYearNumber(endYear.toString())) {
            numbers.push(endYear.toString());
        }
    }

    if (endYear && periodStart.isValid() && periodEnd.diff(periodStart, "month") <= 24) {
        startYear = periodStart.get("year");

        for (let i = endYear - 1; i >= startYear; i--) {
            if (!findSameFiscalYearNumber(i.toString())) {
                numbers.push(i.toString());
            }
        }
    }

    if (!numbers.length && endYear) {
        const shortenFiscalYearNumber = `${endYear}/Z`;
        if (!findSameFiscalYearNumber(shortenFiscalYearNumber)) {
            numbers.push(shortenFiscalYearNumber);
        }
    }

    return numbers;
}

export const getFiscalYearNumber = (dateStart: Date, dateEnd: Date, otherFiscalYears: IFiscalYearEntity[] = []): string => {
    const possibleNumbers = getFiscalYearNumbers(dateStart, dateEnd, otherFiscalYears);

    // fiscal year number has to be unique https://solitea-cz.atlassian.net/browse/DEV-3723
    return possibleNumbers ? possibleNumbers[0] : null;
}; // finds and sets fiscalPeriod matching to selected dateAccountingTransaction

export const getFiscalYears = (context: IAppContext, throwError = false): IFiscalYearEntity[] => {
    const fiscalYears = context?.getData().fiscalYears;

    if (!fiscalYears && throwError) {
        throw new Error("context.getFYPromise() has to be awaited before getFiscalYears");
    }

    return fiscalYears;
};

export const refreshFiscalYears = async (context: IAppContext): Promise<void> => {
    return context.updateFiscalYears();
};

export const getSortedFYs = memoizeOne((context: IAppContext, order: Sort = Sort.Asc): IFiscalYearEntity[] => {
    return [...getFiscalYears(context, true)]
        .sort((f1, f2) => (order === Sort.Desc ? 1 : -1) * (f2.DateStart?.getTime() - f1.DateStart?.getTime()));
}, (context: IAppContext, order: Sort = Sort.Asc) => [context.getData().fiscalYears]);

export const isFiscalYearActive = (FY: IFiscalYearEntity): boolean => FY?.StatusCode === FiscalYearStatusCode.Active;
export const isFiscalYearClosed = (FY: IFiscalYearEntity): boolean => FY?.StatusCode === FiscalYearStatusCode.Closed;
export const isFiscalYearOpen = (FY: IFiscalYearEntity): boolean => FY && FY?.StatusCode !== FiscalYearStatusCode.Closed;

export const hasClosedFY = (context: IAppContext): boolean => {
    const firstFY = getSortedFYs(context)?.[0];
    return isFiscalYearClosed(firstFY);
};

export const getNewestInactiveFY = (context: IAppContext): IFiscalYearEntity =>
    getSortedFYs(context, Sort.Desc).find(isFiscalYearClosed);

export const getOldestActiveFY = (context: IAppContext): IFiscalYearEntity =>
    getSortedFYs(context).find(isFiscalYearActive);

export const getActiveFiscalYears = memoizeOne((context: IAppContext, order: Sort = Sort.Asc) => {
    const sortedFiscalYearsOfCurrentCompany = getSortedFYs(context, order);
    return sortedFiscalYearsOfCurrentCompany.filter(isFiscalYearActive);
}, (context) => {
    return [getFiscalYears(context)];
});

// returns both active and not used fiscal years
export const getOpenFiscalYears = memoizeOne((context: IAppContext) => {
    const fiscalYearsOfCurrentCompany = getSortedFYs(context);
    return fiscalYearsOfCurrentCompany.filter(isFiscalYearOpen);
}, (context: IAppContext) => {
    return [getFiscalYears(context)];
});

export const isInFYorPeriod = (FY: Pick<IFiscalYearEntity, "DateStart" | "DateEnd">, day: Date): boolean => {
    if (!FY) {
        return false;
    }
    return isDateBetween(FY.DateStart, FY.DateEnd, day as Date);
};

export const getMostCurrentFiscalYearBy = memoizeOne(
    (context: IAppContext, getFilteredFiscalYears: (context: IAppContext) => IFiscalYearEntity[]): IFiscalYearEntity => {
        const now = getUtcDate();
        const nowDayjs = getUtcDayjs();
        const FYs = getFilteredFiscalYears(context);
        // if more than one FY is active, pick the one related to currentDate (will be in most cases useful)
        // fallbacks to one closest to the current date
        return (FYs ?? []).find(FY => isInFYorPeriod(FY, now))
            ?? FYs.sort((a, b) => {
                return nowDayjs.diff(a.DateStart) - nowDayjs.diff(b.DateStart);
            })[0];
    }, (context: IAppContext) => [context?.getCompanyId()]);

export const getActiveFiscalYear = (context: IAppContext): IFiscalYearEntity => {
    return getMostCurrentFiscalYearBy(context, getActiveFiscalYears);
};

export const getOpenedFiscalYear = (context: IAppContext): IFiscalYearEntity => {
    return getMostCurrentFiscalYearBy(context, getOpenFiscalYears);
};

export const getFiscalYearByDate = (context: IAppContext, date: Date | Dayjs): IFiscalYearEntity => {
    const FYs = getSortedFYs(context);
    // don't use dayjs, it's slow
    const current = getDateFromDateOrDayjs(date);

    return FYs.find(FY => isInFYorPeriod(FY, current));
};

export function getFiscalYearByChartOfAccount(context: IAppContext, choaId: TEntityKey): IFiscalYearEntity {
    const FYs = getSortedFYs(context);
    return FYs.find(FY => FY.ChartOfAccounts?.Id === choaId);
}

export const getActiveChartOfAccountsId = (context: IAppContext): number => {
    return getActiveFiscalYear(context)?.ChartOfAccounts?.Id;
};

// finds and sets fiscalPeriod matching to selected dateAccountingTransaction
export const setMatchingFiscalPeriod = (storage: FormStorage, dateTransaction?: Date): void => {
    const fiscalData = getFiscalDataCorrespondingToDateAccountingTransaction(storage, dateTransaction);

    storage.setValueByPath("FiscalPeriod", fiscalData?.fiscalPeriod ?? {});
    // We need to store also Fiscal Year to be able to work with it on that granularity, e.g. for getting related NumberRanges
    storage.setValueByPath("FiscalYear", fiscalData?.fiscalYear ?? {});
};

/**
 * Creates filter for DateAccountingTransaction from given FiscalYear
 */
export function getDateFilterFromFY(fiscalYear: IFiscalYearEntity, dateProp: string = DocumentEntity.DateAccountingTransaction): TRecordAny {
    const _createFilter = (condition: Condition, value: Date): IComplexFilter => ({
        id: uuidv4(),
        type: ConditionType.Included,
        filter: PredefinedFilter.Value,
        condition, value
    });
    return {
        [dateProp]: [
            _createFilter(Condition.IsAfterOrEqualsTo, fiscalYear.DateStart),
            _createFilter(Condition.IsBeforeOrEqualsTo, fiscalYear.DateEnd)
        ]
    };
}