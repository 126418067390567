import TableView from "../../views/table";
import { IHandleCustomActionArgs, ITableViewBaseProps } from "../../views/table/TableView";
import React, { ComponentType } from "react";
import BindingContext, { IEntity } from "../../odata/BindingContext";
import { AlertAction, AlertPosition, IAlertProps } from "../alert/Alert";
import { EmptyObject } from "../../global.types";
import { TableButtonsAction, TableButtonsActionType } from "../../views/table/TableToolbar";
import { IToolbarItem } from "../toolbar";
import { createCustomTableToolbarItem } from "../../views/table/TableView.utils";
import { getIcon, IProps as IIconProps } from "../icon";
import memoizeOne from "../../utils/memoizeOne";
import { getRow, TCustomRowAction } from "./smartTable/SmartTable.utils";
import { ActionState, IconSize, RowAction, Status } from "../../enums";
import { IActionRendererArgs, IRow, IRowAction, TId } from "../table";
import { IconButton } from "../button";
import { IRowProps } from "../table/Rows";
import { TitleStyled } from "../readOnlyList/ReadOnlyList.styles";
import View from "../../views/View";
import { TableWrapper } from "../../views/table/TableView.styles";
import { SmartTable } from "./smartTable";
import { ModelEvent } from "../../model/Model";
import { WithBusyIndicator } from "../busyIndicator/withBusyIndicator";
import { getConfirmationActionText } from "../../views/table/TableView.render.utils";
import { IReadOnlyListItem } from "../readOnlyList/ReadOnlyListItem";
import ReadOnlyList from "../readOnlyList/ReadOnlyList";
import { getValue } from "@utils/general";
import { TSmartODataTableStorage } from "./smartTable/SmartODataTableBase";
import { IProps as ISmartFilterBarProps } from "./smartFilterBar/SmartFilterBar";

export const SELECTION_TABLEVIEW_ACTION = "selectionTableViewAction";

export type THeaderDataGetter = IReadOnlyListItem[] | (() => IReadOnlyListItem[]);

export interface ISelectionTableViewProps extends ITableViewBaseProps {
    selectedItems?: BindingContext[];
    onClose?: () => void;
    onSelection?: (selected: BindingContext[], entities: IEntity[]) => void;

    title?: string;
    secondaryTitle?: string;
    headerData?: THeaderDataGetter;
    confirmText?: string;
    successMessage?: string;
    isRowWithoutAction?: (storage: TSmartODataTableStorage, rowId: TId, action: RowAction, row: IRow) => boolean;
    presetDefaultFilters?: (storage: TSmartODataTableStorage) => void;
    /** Whether only one row can be selected (paired), or multiple, true by default. */
    isSingleSelect?: boolean;
    // for multi select variant, if we don't want to render the main toggle
    withoutMainToggle?: boolean;
}

interface IProps extends ISelectionTableViewProps, WithBusyIndicator {
}

export const SelectionTableViewAlertProps = {
    useFade: true,
    action: AlertAction.Close,
    isFullWidth: false,
    position: AlertPosition.CenteredBottom,
    style: {
        width: "500px",
        maxWidth: "calc(100% - 100px)"
    }
};

export default abstract class SelectionTableViewBase<Props extends IProps = IProps> extends TableView<Props> {
    // configuration options
    _actionIconName = "CopyLink";
    _selectedIconName = "Chained";
    _notSelectedIconName = "Unchained";
    _hotspotContextId: string = null;

    // common props
    _selectedItems: BindingContext[];

    // ReadOnlyList could be customized in extended classes or passed by props
    get title(): string {
        return this.props.title;
    }

    get headerData(): THeaderDataGetter {
        return this.props.headerData;
    }

    get secondaryTitle(): string {
        return this.props.secondaryTitle;
    }

    get selectionConfirmText(): string {
        return this.props.confirmText;
    }

    get successMessage(): string {
        return this.props.successMessage;
    }

    get isSingleSelect(): boolean {
        return this.props.isSingleSelect ?? true;
    }

    constructor(props: IProps) {
        // @ts-ignore
        super(props);

        this.handleCustomAction = this.handleCustomAction.bind(this);
        this.presetDefaultFilters = this.presetDefaultFilters.bind(this);

        this._selectedItems = props.selectedItems;
        this.handleCustomAction(SELECTION_TABLEVIEW_ACTION, { update: false });

        props.storage.emitter.on(ModelEvent.FiltersCleared, this.presetDefaultFilters);
        this.presetDefaultFilters();
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<EmptyObject>): boolean {
        return this.props.busy !== nextProps.busy;
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        this.props.storage.emitter.off(ModelEvent.FiltersCleared, this.presetDefaultFilters);
    }

    presetDefaultFilters(): void {
        // we can customize default filters for the tableView, which
        // should be kept even when user clears filters. They can be only removed manually or changed.
        if (this.props.presetDefaultFilters) {
            this.props.presetDefaultFilters(this.props.storage);
        }
    }

    getCustomFilterBarProps(): Partial<ISmartFilterBarProps> {
        return {
            onFilterCustomizationChanged: this.presetDefaultFilters
        };
    }

    getToolbarButtons(): TableButtonsActionType[] {
        return [
            // https://solitea-cz.atlassian.net/browse/DEV-24786
            // apparently, users don't want to see the table settings
            // TableButtonsAction.Settings
        ];
    }

    get customToolbarItems(): IToolbarItem[] {
        return [this.createToolbarItem(SELECTION_TABLEVIEW_ACTION, this._actionIconName, this.selectionConfirmText)];
    }

    createToolbarItem(id: string, iconName: string, label: string, forceDisabled = false): IToolbarItem {
        const action = this.getTableAction();

        return createCustomTableToolbarItem({
            tableAction: action,
            id,
            iconName,
            label,
            forceDisabled
        });
    }

    getRow = (bindingContext: BindingContext): IRow => {
        return getRow(this.props.storage.tableAPI.getState().rows, bindingContext);
    };

    getConfirmText = (tableAction: TableButtonsAction | string): string | React.ReactElement => {
        const { storage } = this.props;

        if (tableAction === SELECTION_TABLEVIEW_ACTION) {
            if (this.isSingleSelect) {
                return this.selectionConfirmText;
            } else {
                const activeRowActionCount = storage.tableAPI?.getState().activeRows.size ?? 0;
                return getConfirmationActionText(this.selectionConfirmText, activeRowActionCount);
            }
        }

        return super.getConfirmText(tableAction);
    };

    handleCustomAction(action: string, { update = true, value }: IHandleCustomActionArgs = {}): void {
        this.setTableAction(action, true, false);
        if (update) {
            this.forceUpdate();
        }
    }

    getIcon = (isActive: boolean): ComponentType<IIconProps> => isActive ? getIcon(this._selectedIconName) : getIcon(this._notSelectedIconName);

    isRowWithoutAction(rowId: TId, action: RowAction, row: IRow): boolean {
        if (!this.props.isRowWithoutAction) {
            return false;
        }
        return this.props.isRowWithoutAction?.(this.props.storage, rowId, action, row);
    }

    getCustomRowAction = memoizeOne((): TCustomRowAction => {
        return {
            actionType: RowAction.Custom,
            isSingleSelect: this.isSingleSelect,
            render: (args: IActionRendererArgs) => {
                if (args.isMainToggle && (this.props.withoutMainToggle || this.isSingleSelect)) {
                    return null;
                }

                const isActive = args.actionState === ActionState.Active;
                const Icon = this.getIcon(isActive);
                return (
                    <IconButton title=""
                                onClick={args.onClick}
                                isDisabled={args.actionState === ActionState.None || args.isDisabled}
                                isDecorative>
                        <Icon width={IconSize.S} height={IconSize.S}/>
                    </IconButton>
                );
            }
        };
    });

    setAlert({ status, ...restProps }: IAlertProps): void {
        const { storage } = this.props;
        const title = status === Status.Success ? storage.t("Common:Validation.SuccessTitle") : storage.t("Common:General.Error");

        this.props.storage.data.alert = {
            status,
            title,
            ...SelectionTableViewAlertProps,
            ...restProps
        };
    }

    getRowIcon = (id: TId, row: IRowProps, rowAction: IRowAction): React.ComponentType<IIconProps> => {
        if (rowAction) {
            return null;
        }

        // this._selectedItems has corrected entity type, different from the one in id
        // (e.g. Documents vs InvoicesIssued)
        // but documents have unique id across all the types
        // => use just key
        return this._selectedItems.map(item => item.getKey()).includes((id as BindingContext).getKey()) && this.getIcon(true);
    };

    // can be overridden in extended tableview
    getCorrectBindingContext = (bindingContext: BindingContext, row: IRow): BindingContext => {
        return bindingContext;
    };

    handleToolbarConfirm = async (): Promise<void> => {
        const { storage } = this.props;

        const affectedRows = await storage.tableAPI.getAffectedRows();

        // contains the original bcs
        this._selectedItems = affectedRows?.map(affectedRow => {
            return affectedRow.bc;
        });
        // contains the corrected bcs
        const affectedBCs = affectedRows?.map(affectedRow => {
            const bc = affectedRow.bc;
            const row = bc && this.getRow(bc);

            return this.getCorrectBindingContext(bc, row);
        });
        const entities = affectedRows.map(row => this.getRow(row.bc).customData?.entity);

        this.props.onSelection?.(affectedBCs, entities);
        this.setTableAction(null);

        // if success message is configured and at least one entity selected, shows success message
        if (this.successMessage && entities?.[0]) {
            this.setAlert({
                status: Status.Success,
                subTitle: this.successMessage
            });
        }
    };

    renderHeader(): React.ReactElement {
        return (<>
            <ReadOnlyList title={this.title}
                          data={getValue(this.headerData ?? [])}
            />
            {this.secondaryTitle &&
                <TitleStyled>{this.secondaryTitle}</TitleStyled>}
        </>);
    }

    render() {
        if (this.props.busy) {
            return this.props.busyIndicator;
        }
        return (<>
            <View hotspotContextId={this._hotspotContextId ?? `${this.props.storage.id}Selection`}>
                {this.renderHeader()}
                {this.renderFilterBar()}
                {this.renderAlert()}
                {this.renderTabs()}
                {this.renderToolbar()}
                <TableWrapper>
                    <SmartTable
                        {...this.getTableSharedProps()}
                        initialActiveRows={this._selectedItems}
                    />
                </TableWrapper>
                {this.renderCustomizationDialog()}
            </View>
        </>);
    }
}