import { OData } from "@odata/OData";
import { EntitySetName, IBackgroundJobEntity, ICompanyEntity, OdataActionName } from "@odata/GeneratedEntityTypes";
import { IBackgroundJobWebsocketMessage } from "@utils/websocketManager/Websocket.types";
import { BackgroundJobStatusCode, BackgroundJobTypeCode } from "@odata/GeneratedEnums";
import { IAppContext } from "../appContext/AppContext.types";

export interface IBackgroundJobsContext {
    backgroundJobs: IBackgroundJobEntity[];
}

export enum BackgroundJobActionType {
    ConfirmDuplicates = "ConfirmDuplicates"
}

export const IgnoredBackgroundJobStatuses = [BackgroundJobStatusCode.Cancelling, BackgroundJobStatusCode.Canceled];
export const CancelableBackgroundJobStatuses = [BackgroundJobStatusCode.Running, BackgroundJobStatusCode.NotStarted];

export const getBackgroundJobFromWebsocketMessage = (message: IBackgroundJobWebsocketMessage, companies?: ICompanyEntity[]): IBackgroundJobEntity => {
    const backgroundJob = { ...message.Parameters } as IBackgroundJobEntity;

    // BE returns strings instead of numbers for ids => convert them
    if (backgroundJob.Id) {
        backgroundJob.Id = Number(backgroundJob.Id);
    }
    const companyId = Number(message.Parameters.CompanyId) ?? null;

    if (companies) {
        // BE sends CompanyId not whole Company object
        // => pair it
        backgroundJob.Company = companies.find(company => company.Id === companyId);
    }

    if (message.Parameters.IsSystemJob) {
        backgroundJob.Type = {
            IsSystemJob: message.Parameters.IsSystemJob.toLowerCase() === "true",
            Code: message.Parameters.TypeCode
        };
    }

    return backgroundJob;
};

interface IIsRelevantBackgroundJobArgs {
    backgroundJob: IBackgroundJobEntity;
    context: IAppContext;
    relevantJobTypes: BackgroundJobTypeCode[];
}

export const isRelevantBackgroundJob = (args: IIsRelevantBackgroundJobArgs): boolean => {
    return args.backgroundJob.Company.Id === args.context.getCompanyId()
        && [BackgroundJobStatusCode.Finished || BackgroundJobStatusCode.FinishedWithWarning].includes(args.backgroundJob.StatusCode as BackgroundJobStatusCode)
        && args.relevantJobTypes.includes(args.backgroundJob.TypeCode as BackgroundJobTypeCode);
};

export const cancelBackgroundJob = async (oData: OData, jobId: number): Promise<boolean> => {
    // todo get info from BE whether the cancel went through, or the job was already fulfilled
    await oData.getEntitySetWrapper(EntitySetName.BackgroundJobs)
        .action(OdataActionName.BackgroundJobCancel, jobId);

    return true;
};

export const rollbackBackgroundJob = async (oData: OData, jobId: number): Promise<boolean> => {
    await oData.getEntitySetWrapper(EntitySetName.BackgroundJobs)
        .action(OdataActionName.BackgroundJobRollback, jobId);

    return true;
};

export const markSeenBackgroundJobs = async (oData: OData, jobIds: number[]): Promise<boolean> => {
    if (jobIds.length === 0) {
        return true;
    }

    if (jobIds.length === 1) {
        await oData.getEntitySetWrapper(EntitySetName.BackgroundJobs)
            .action(OdataActionName.BackgroundJobMarkSeen, jobIds[0]);
    } else {
        const batch = oData.batch();
        batch.beginAtomicityGroup("group1");

        for (const jobId of jobIds) {
            batch.getEntitySetWrapper(EntitySetName.BackgroundJobs)
                .action(OdataActionName.BackgroundJobMarkSeen, jobId);
        }

        await batch.execute();
    }

    return true;
};