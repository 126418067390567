import React from "react";
import { getIcon } from "../../icon";
import { IconSize, ValueType } from "../../../enums";
import {
    BoldText,
    ColorBackground,
    ColSpanWrapper,
    Description,
    GroupEndLine,
    HorizontalIndentLine,
    ItemWrapper,
    StyledItem,
    StyledText,
    TabularColumn,
    TabularIconColumn,
    VerticalIndentLine
} from "./Select.styles";
import TestIds from "../../../testIds";
import { IColumn, ISelectItem, ISharedData } from "./BasicSelect";
import { splitStringToSearchedParts } from "@utils/string";

export enum MenuLineItem {
    None = "none",
    Bottom = "bottom",
    Deep = "Deep"
}

export interface ISelectMenuItemContentArgs {
    onClick: (e: React.MouseEvent) => void;
}

interface IMenuItemProps {
    isSelected: boolean;
    isTabular?: boolean;
    isItalic: boolean;
    isHighlighted: boolean;
    inputValue: string;
    item: ISelectItem;
    defaultWidth: number;
    index: number;
    isMulti: boolean;
    /** Callback that is used dynamically for all items. Renders custom content before the item in the select menu.
     * itemContent property (not a callback) of ISelectItem can be used instead, if the content is ready before initialization.*/
    itemContent: (item: ISelectItem, args: ISelectMenuItemContentArgs) => React.ReactNode;
    onMouseClick: (item: ISelectItem, triggerAdditionalTasks: boolean) => void;
    onMouseEnter: (index: number) => void;
    onMouseLeave: (index: number) => void;
    line?: MenuLineItem;
    sharedData: ISharedData;
    useIconOffset?: boolean;
    columns?: IColumn[];
    isDisabled?: boolean;
    searchType?: ValueType;
    renderItemWithoutCheckboxes?: boolean;
}

export class MenuItem extends React.PureComponent<IMenuItemProps> {
    _isHighlightable = () => {
        return !this.props.item.isNotHighlightable && !this.props.isDisabled;
    };

    handleMouseClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        !this.props.isDisabled && this.props.onMouseClick(this.props.item, true);
    };

    handleMouseDown = (e: React.MouseEvent) => {
        e.preventDefault();
    };

    handleMouseEnter = () => {
        if (!this.props.isHighlighted && this._isHighlightable()) {
            this.props.onMouseEnter(this.props.index);
        }
    };

    handleMouseLeave = () => {
        if (this.props.isHighlighted) {
            this.props.onMouseLeave(this.props.index);
        }
    };

    renderItemIcon = (item: ISelectItem) => {
        const left = ((item.indent || 0) * 20 + 10) + (this.props.isMulti ? 28 : 0);
        const Icon = item.iconName ? getIcon(item.iconName) : null;
        return Icon ? <Icon style={{
            flexShrink: 0,
            position: "absolute",
            left: `${left}px`
        }} width={IconSize.S} isLight ignoreTheme preventHover/> : null;
    };

    renderTabularIcon = (item: ISelectItem, isFirst: boolean) => {
        const Icon = item.iconName ? getIcon(item.iconName) : null;
        return this.props.useIconOffset ?
            <TabularIconColumn isFirst={isFirst}>
                {Icon &&
                <Icon width={IconSize.S} height={IconSize.S} isLight preventHover/>
                }
            </TabularIconColumn> : null;
    };

    renderTabularItemContent = (item: ISelectItem) => {
        if (!this.props.itemContent && !item.itemContent) {
            return null;
        }

        return (
            <TabularColumn
                isContentBefore={true}
                isFirst={true}
                data-testid={TestIds.SelectMenuColumn}>
                {item.itemContent}
                {this.props.itemContent?.(item, { onClick: this.handleMouseClick })}
            </TabularColumn>
        );
    };

    renderTabularItem = (isSelected: boolean) => {
        const item = this.props.item;
        const currentValue = this.props.inputValue || "";

        return (
            <>
                {this.renderTabularItemContent(item)}
                {this.renderTabularIcon(item, !this.props.itemContent)}
                {item.tabularData.map((text, index) => {
                    const isFirst = index === 0;
                    const isLast = index === item.tabularData.length - 1;
                    const column = this.props.columns?.[index];

                    return (
                        <TabularColumn
                            column={column}
                            isFirst={isFirst}
                            isLast={isLast}
                            isSelected={isSelected && !isLast}
                            key={index}
                            data-testid={TestIds.SelectMenuColumn}>
                            <ColSpanWrapper
                                column={column}
                                isFirst={isFirst}
                                isSelected={isSelected}
                                isLast={isLast}>
                                {this.renderText(text, currentValue)}
                            </ColSpanWrapper>
                        </TabularColumn>
                    );
                })}
            </>
        );
    };

    renderSingleItem = (isSelected: boolean) => {
        const item = this.props.item;
        const currentValue = this.props.inputValue || "";
        const useIconPadding = this.props.useIconOffset && !this.props.isTabular;

        return (
            <>
                {item.itemContent}
                {this.props.itemContent?.(item, { onClick: this.handleMouseClick })}
                {this.renderItemIcon(item)}

                <ItemWrapper
                    indent={item.indent}
                    hasIcon={useIconPadding}
                    hasPreContent={!!this.props.itemContent && !this.props.renderItemWithoutCheckboxes}
                    isLabel={!!this.props.item.color}
                    isSelected={isSelected}>

                    {!!this.props.item.color &&
                    <ColorBackground
                        _color={this.props.item.color}/>
                    }
                    {this.renderText(item.label, currentValue, item.description)}
                </ItemWrapper>
            </>
        );
    };

    renderItem = (isSelected: boolean) => {
        return this.props.isTabular ? this.renderTabularItem(isSelected) : this.renderSingleItem(isSelected);
    };

    renderText = (text: string, pre = "", description?: string): React.ReactNode => {
        const res = splitStringToSearchedParts(text, pre, this.props.searchType);
        if (res) {
            const { prefix, root, suffix } = res;

            return (
                <StyledText
                    _hasColor={!!this.props.item.color}
                    data-testid={TestIds.Text}>
                    {prefix.length > 0 && <span>{prefix}</span>}
                    <BoldText data-testid={TestIds.BoldText}>{root}</BoldText>
                    {suffix.length > 0 && <span>{suffix}</span>}
                    {description && <Description>&nbsp;{description}</Description>}
                </StyledText>
            );
        }

        return (
            <StyledText
                _hasColor={!!this.props.item.color}
                data-testid={TestIds.Text}>
                {text}
                {description && <Description>&nbsp;{description}</Description>}
            </StyledText>
        );
    };

    render() {
        const useIconPadding = this.props.useIconOffset && !this.props.isTabular;
        const isSelected = this.props.isSelected && !this.props.isMulti;
        return (
            <StyledItem
                className="select-item"
                _useIconPadding={useIconPadding}
                _indent={this.props.item.indent}
                _italic={this.props.isItalic}
                _defaultWidth={this.props.defaultWidth}
                _isDisabled={this.props.isDisabled}

                aria-selected={this.props.isSelected || undefined}
                isTabular={this.props.isTabular}
                isHighlighted={this.props.isHighlighted}

                onClick={this.handleMouseClick}
                onMouseDown={this.handleMouseDown}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}

                data-testid={TestIds.SelectMenuItem}>
                {this.renderItem(isSelected)}
                {this.props.line && this.props.line !== MenuLineItem.None &&
                    <GroupEndLine _line={this.props.line}/>
                }

                {!!this.props.item.indent &&
                    <>
                        <HorizontalIndentLine
                            _drawLineForMissingIcon={useIconPadding && !this.props.item.iconName}
                            _isMulti={this.props.isMulti}
                            _indent={this.props.item.indent}
                            _diff={this.props.item.index - this.props.item.parent.index}/>
                        <VerticalIndentLine
                            _isMulti={this.props.isMulti}
                            _indent={this.props.item.indent}
                            _diff={this.props.item.index - this.props.item.parent.index}/>
                    </>
                }
            </StyledItem>

        );
    }
}
