import { getRandomInt, isDefined } from "@utils/general";
import { ellipsis, mix, transparentize } from "polished";
import React from "react";
import styled, { css, keyframes } from "styled-components/macro";

import { BorderSize, IconSize, RowType, Sort, Status, TableSizes, TextAlign } from "../../enums";
import {
    getDisabledStyle,
    getFocusBorderElement,
    getSemanticElColor,
    getSemanticTextColor,
    LocalStackOrder,
    T_PLAIN_small,
    T_PLAIN_small_hig,
    textAlignToJustify
} from "../../global.style";
import { PropsWithTheme } from "../../theme";
import { SortDownIcon, SortIcon } from "../icon";
import { IRow, ISticky } from "./Table";

export const getColumnFlex = (width: number): string => {
    return `0 0 ${width ?? TableSizes.MinColumnWidth}px`;
};

const leftBorderRadius = css`
    border-top-left-radius: ${({ theme }) => theme.borderRadius};
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius};
`;

const rightBorderRadius = css`
    border-top-right-radius: ${({ theme }) => theme.borderRadius};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius};
`;

export const ValueWrapper = styled.div<{
    textAlign: TextAlign
}>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: ${props => textAlignToJustify(props.textAlign)};
    overflow: hidden;
    order: ${props => props.textAlign === TextAlign.Right ? 1 : 0};
`;

export const LabelTooltipWrapper = styled.div<{
    textAlign: TextAlign
}>`
    display: flex;
    justify-content: ${props => textAlignToJustify(props.textAlign)};
`;

export const Label = styled.div<{
    textAlign?: TextAlign
}>`
    text-align: ${props => props.textAlign ? props.textAlign : "right"};
    ${ellipsis()};
`;

export const LabelGroup = styled.div<{
    disableSort?: boolean,
    textAlign?: TextAlign
}>`
    position: relative;
    display: flex;
    max-width: 100%;
    cursor: ${props => props.disableSort ? "default" : "pointer"};
    ${props => props.textAlign === TextAlign.Center && !props.disableSort && css`
        left: 8px; // half of the size of the sort arrow
    `};
`;

export interface ISortIcon {
    sort: Sort;
    disableSort: boolean;
}

const getSortHoverOpacity = (props: ISortIcon) => {
    if (props.sort) {
        return 1;
    }

    return props.disableSort ? 0 : 0.5;
};

const sortIconStyles = css<ISortIcon>`
    transition: transform 0.3s, opacity 200ms;
    // uncomment if html2canvas is replaced with something that supports rotation
        //transform: rotate(${props => !props.sort || props.sort === Sort.Asc ? "0deg" : "180deg"});
    opacity: ${props => props.sort ? 1 : 0};
    display: ${props => props.disableSort ? "none" : "block"};

    ${ValueWrapper}:hover + div && {
        // double && according to https://stackoverflow.com/questions/72883165/styled-component-generate-same-css-class-with-different-conditions
        opacity: ${props => getSortHoverOpacity(props)};
    }
`;

export const SortIconStyled = styled(SortIcon).attrs({
    // width and height has to be used as attribute for html2canvas to process svg properly
    width: IconSize.XS,
    height: IconSize.XS
})<ISortIcon>`
    ${sortIconStyles};
`;

export const SortDownIconStyled = styled(SortDownIcon).attrs({
    width: IconSize.XS,
    height: IconSize.XS
})<ISortIcon>`
    ${sortIconStyles};
`;

export interface IStyledCellProps {
    _width?: number;
    first?: boolean;
    last?: boolean;
    sticky?: ISticky;
    isColumnHighlighted?: boolean;
    isRowHighlighted?: boolean;
    border?: BorderSize;
    height?: number;
    textAlign?: TextAlign;
    isHierarchy?: boolean;
    isForPrint?: boolean;
    customBackgroundColor?: string;
    hasHoverContent?: boolean;
    keepSpaceForStatus?: boolean;
    hasAction?: boolean;
}

export const getStickyStyles = (sticky: ISticky): React.CSSProperties => ({
    position: sticky ? "sticky" : "relative",
    // transform: "translateZ(1px)", // this messes up table performance badly
    zIndex: sticky ? 1 : 0,
    left: sticky ? `${sticky.left}px` : null,
    right: sticky ? `${sticky.right}px` : null
});

// this has to be put in style attribute, because it can change often for the cells and causes generation of too many styled classes
const stickyColumn = (props: IStyledCellProps) => ({
    flex: getColumnFlex(props._width),
    width: props._width ?? `${TableSizes.MinColumnWidth}px`,
    ...getStickyStyles(props.sticky)
});

export const textAlignToPadding = (textAlign?: TextAlign, isFirst?: boolean, isHierarchy?: boolean, isLast?: boolean, hasAction?: boolean, keepSpaceForStatus?: boolean): {
    paddingLeft: number,
    paddingRight: number
} => {
    let paddingLeft, paddingRight;

    switch (textAlign) {
        case TextAlign.Right:
            paddingLeft = 24;
            paddingRight = 12;
            break;
        case TextAlign.Center:
            paddingLeft = 12;
            paddingRight = 12;
            break;
        case TextAlign.Left:
        default:
            paddingLeft = isFirst ? isHierarchy ? 28 : keepSpaceForStatus === false ? 12 : 18 : 12;
            paddingRight = 24;
    }

    if (isLast && hasAction) {
        paddingRight = 33;
    }

    return { paddingLeft, paddingRight };
};

export const getRowPadding = (textAlign?: TextAlign, isFirst?: boolean, isHierarchy?: boolean, isLast?: boolean, hasAction?: boolean, keepSpaceForStatus?: boolean) => {
    const {
        paddingLeft,
        paddingRight
    } = textAlignToPadding(textAlign, isFirst, isHierarchy, isLast, hasAction, keepSpaceForStatus);

    return css<{ textAlign?: TextAlign }>`
        // first column has bigger padding to accommodate for the highlight stripe
        padding-left: ${paddingLeft}px;
        padding-right: ${paddingRight}px;
    `;
};

export const getCellBorder = (props: (IStyledCellProps & PropsWithTheme)): string => {
    if (props.isForPrint) {
        // html2canvas tends to do wrong things with box-shadow
        return "none";
    }

    switch (props.border) {
        case BorderSize.Thin:
            return `inset -2px 0 0 0 ${props.theme.C_DEF_tableBorder}`;
        case BorderSize.Thick:
            return `inset -2px 0 0 0 ${props.theme.C_BG_stripes}`;
        case BorderSize.None:
        default:
            return "none";
    }
};

export const headerCellBoxShadow = css`
    &::after {
        content: "";
        position: absolute;
        width: calc(100% - 1px);
        height: 100%;
        box-shadow: ${props => getCellBorder(props)};
        pointer-events: none;
    }
`;

export const StyledHeaderCell = styled.div<IStyledCellProps>`
    height: 100%;
    display: flex;
    align-items: flex-end;
    background-color: ${props => props.isColumnHighlighted ? props.theme.C_BG_menu : "transparent"};
    position: relative;

    ${headerCellBoxShadow};
`;

export const HeaderCellInner = styled.div.attrs<IStyledCellProps>(props => ({
    style: {
        ...stickyColumn(props),
        height: `${props.height}px`
    }
}))<IStyledCellProps>`
    position: relative;
    ${ellipsis()};
    height: ${TableSizes.RowHeight}px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    ${props => getRowPadding(props.textAlign, props.first, false, props.last, props.hasAction, false)};
    justify-content: ${props => textAlignToJustify(props.textAlign)};
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
`;

const rowLoadingAnim = keyframes`
    0% {
        background-position: -250px 0;
    }

    100% {
        background-position: 250px 0;
    }
`;

interface IStyledBodyCellContent {
    allowOverflow: boolean;
    stretchContent?: boolean;
    isLoading?: boolean;
    _width?: number;
    _minWidth?: number;
}

export const StyledBodyCellContent = styled.div<IStyledBodyCellContent>`
    max-height: 100%;
    max-width: 100%;
    ${props => props.stretchContent && `min-width: ${isDefined(props._minWidth) ? props._minWidth + "px" : "fit-content"};`};
    // min-width: 30px; // TODO: drilldown link doesn't work for narrow content
    ${props => !props.allowOverflow && ellipsis()};

    ${props => props.isLoading && css<IStyledBodyCellContent>`
        width: ${props => getRandomInt(45, props._width ?? 75)}px;
        height: 17px;
        border-radius: 3px;
        background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
        background-size: 500px 100px;
        animation-name: ${rowLoadingAnim};
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
    `};
`;

export const ActionColumn = styled.div`
    position: sticky;
    flex: 0 0 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -1.5px;
    ${rightBorderRadius}
`;

export const RowStretcher = styled.div`
    position: relative;
    flex: 1 1 auto;

    &:not(:has(+ ${ActionColumn})) {
        ${rightBorderRadius}
    }
`;

const getCellBackground = (props: IStyledCellProps & PropsWithTheme) => {
    if (props.customBackgroundColor) {
        return props.customBackgroundColor;
    }

    if (props.isColumnHighlighted) {
        return props.isRowHighlighted ? mix(0.2, props.theme.C_FIELD_line, props.theme.C_BG_menu) : props.theme.C_BG_menu;
    }
    return "transparent";
};

export const HoverContentWrapper = styled.div<Pick<IStyledCellProps, "textAlign">>`
    position: absolute;
    ${props => props.textAlign === TextAlign.Right ? "right: 0;" : "left: 0;"};
    top: 50%;
    transform: translateY(-50%);
    max-width: 100%;
    max-height: 100%;
`;

export const StyledBodyCell = styled.div.attrs<IStyledCellProps>(props => ({
    style: {
        ...stickyColumn(props)
    }
}))<IStyledCellProps>`
    position: relative;
    ${T_PLAIN_small};
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: ${props => textAlignToJustify(props.textAlign)};
    background-color: ${props => getCellBackground(props)};
    box-shadow: ${props => getCellBorder(props)};
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;

    &, ${HoverContentWrapper} {
        ${props => getRowPadding(props.textAlign, props.first, props.isHierarchy, props.last, props.hasAction, props.keepSpaceForStatus)};
    }

    ${props => props.hasHoverContent && css`
        cursor: help;

        &:hover ${StyledBodyCellContent}, ${HoverContentWrapper} {
            visibility: hidden;
        }

        ${StyledBodyCellContent}, &:hover ${HoverContentWrapper} {
            visibility: visible;
        }
    `}
`;

export const SimpleBodyCell = styled(RowStretcher)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    ${getRowPadding()};
`;

export const Resizer = styled.div`
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    width: 4px;
    cursor: col-resize;
`;

export const GroupIconWrapperStyles = css<{ isOpen: boolean }>`
    cursor: pointer;

    svg {
        transform: translateZ(2px) rotate(${props => props.isOpen ? 0 : -90}deg);
        transition: transform ${props => props.theme.transition_transform_time}
    }
`;

export const GroupIconWrapper = styled.span<{
    isOpen: boolean
}>`
    flex: 0 0 auto;
    position: absolute;
    padding: 9px 6px;
    z-index: 1;
    ${GroupIconWrapperStyles};
`;

export const IconWrapper = styled.div<{
    textAlign: TextAlign;
    isAscending: boolean;
}>`
    position: relative;
    top: -1.5px;
    flex-shrink: 0;
    order: ${props => props.textAlign === TextAlign.Right ? 0 : 1};
`;

export interface IStyledRowProps {
    sticky: ISticky;
    _offset: number;
    _minWidth?: number;
    level: number;
    isDisabled: boolean;
    isHoverDisabled?: boolean;
    isHighlighted?: boolean;
    selected: boolean;
    isList: boolean;
    hasAction: boolean;
    hasGroups: boolean;
    isOpen: boolean;
    hasRows: boolean;
    nextRow: IRow;
    nextRowIsSelected: boolean;
    isBold?: boolean;
    isDivider?: boolean;
    customBackgroundColor?: string;
    customBorderColor?: string;
    _type?: RowType;
}

const RowBox = css`
    height: ${`${TableSizes.RowHeight - TableSizes.RowSpacing}px`};
    margin-bottom: ${TableSizes.RowSpacing}px;
`;

const getRowLeftIndent = (level: number) => {
    let indent = 0;
    for (let i = level; i > 0; i--) {
        indent += TableSizes.GroupMargin;
    }
    return `${indent}px`;
};

export const StyledValueRow = styled.div.attrs<IStyledRowProps>(props => {
    return {
        style: {
            top: props.sticky ? `${props.sticky.top - props._offset}px` : "auto",
            bottom: props.sticky ? `${props.sticky.bottom + props._offset}px` : "auto"
        }
    };
})<IStyledRowProps>`
    display: flex;
    flex: 1 1 auto;
    height: ${TableSizes.RowHeight}px;
    align-items: stretch;
    position: ${props => props.sticky ? "sticky" : "relative"};
    z-index: ${props => props.sticky ? 2 : 0};
    outline: none;
    ${props => !props.isHoverDisabled && getFocusBorderElement({
        offset: -1,
        left: "var(--left)",
        width: "var(--width)"
    })};
    // default focus element values, then controlled in table.handleScrollThrottled and table.handleRowFocus
    --left: 1px;
    --width: calc(100% + -2px);

    ${props => props._minWidth && css<IStyledRowProps>`
        min-width: ${props._minWidth}px;
    `}
    ${props => props.level !== undefined && css<IStyledRowProps>`
        left: ${props => getRowLeftIndent(props.level)};
        // we need to add width size of the action button, otherwise last column value could be overlapped by it 
        width: ${props => `calc(100% - ${getRowLeftIndent(props.level)})`};
    `}
    ${props => props.sticky && css`
        & * {
            color: black; // TODO: what theme color use here
        }
    `}
    & > ${StyledBodyCell} {
        ${props => getDisabledStyle(props.isDisabled)};
        // we just want to change opacity,
        // keep pointer events, so that user can hover over truncated text and still get Tooltip shown
        pointer-events: all;
    }

    // we need to be able to catch events to show tooltip
    pointer-events: auto;

    ${props => props.isHighlighted && css`
        background-color: ${props => transparentize(0.8, props.theme.C_FIELD_line)};
    `}

    ${props => (props._type === RowType.Group) && css`
        ${props.hasRows && css`
            cursor: pointer;
        `};

        ${StyledBodyCell}, ${RowStretcher}, ${ActionColumn} {
            position: relative;
            ${T_PLAIN_small_hig};

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: -1px;
                height: 100%;
                width: calc(100% + 2px);
                border-bottom: 2px solid ${getBottomLineColor(props)};
            }
        ;
        }

        ${!props.isHoverDisabled && css`
            &:hover {
                & ${StyledBodyCell}, & ${RowStretcher}, & ${ActionColumn} {
                    background-color: ${props.theme.C_ACT_hover};

                    &::before {
                        border-bottom: 2px solid ${props.theme.C_ACT_hover_without_opacity};
                    }
                }
            }
        `}

        ${props.isBold && css`
            font-weight: 900;
        `};
    `};

    ${props => (props._type === RowType.Aggregation || props._type === RowType.Merged) && css`
        &:not(:last-child) {
            border-bottom: 2px solid ${getBottomLineColor(props)}
        }

        & ${StyledBodyCell} {
            ${T_PLAIN_small_hig};
            ${props.isBold && css`
                font-weight: 900;
            `};
        }
    `}

    ${props => props._type === RowType.New && css<IStyledRowProps>`
        ${RowBox};
    `};

    ${props => props._type === RowType.Value && css<IStyledRowProps>`
        ${RowBox};
        border-radius: ${props.theme.borderRadius};

        ${props.selected && css`
            transform: translateZ(2px);
            z-index: 1;
        `}
        & ${StyledBodyCell} {
            border-right: 1px solid ${props => props.customBorderColor ?? props.theme.C_DEF_tableBorder};
            border-left: 1px solid ${props => props.customBorderColor ?? props.theme.C_DEF_tableBorder};

            &:nth-last-child(${props.hasAction ? 3 : 2}) {
                ${rightBorderRadius};
                border-right: 2px solid transparent;
            }

            &:first-of-type {
                ${leftBorderRadius}
            }

            ${props.isBold && css`
                font-weight: 900;
            `};
        }

        & ${ActionColumn} {
            ${rightBorderRadius}
        }

        ${!props.isHoverDisabled && css`
            &:hover ${StyledBodyCell}, &:hover ${RowStretcher}, &:hover ${ActionColumn} {
                background-color: ${props.theme.C_BG_row_field_hover};
            }
        `}
        & ${StyledBodyCell}, & ${RowStretcher}, & ${ActionColumn} {
            background-color: ${props.customBackgroundColor ?? props.selected ? props.theme.C_BG_row_field_hover : props.theme.C_BG_row_field};

            ${(props.selected || props.isList) && css`
                border-color: rgba(0, 0, 0, 0);
            `}
        }
    `};

    ${props => props.isDivider && css<IStyledRowProps>`
        &::after {
            content: "";
            ${RowDivider};
            left: ${props => `-${getRowLeftIndent(props.level)}`};
            width: ${props => `calc(100% + ${getRowLeftIndent(props.level)})`};
        }
    `}
`;

export const RowDivider = css`
    position: absolute;
    left: 0;
    bottom: -3px;
    height: 1px;
    width: 100%;
    opacity: 0.5;
    z-index: 5;
    transform: translateZ(2px);
    background: ${props => props.theme.C_ACT_der};
`;

export const StyledStatusHighlight = styled.div<{
    statusHighlight: Status;
    selected: boolean;
}>`
    border-top-left-radius: ${props => props.theme.borderRadius};
    border-bottom-left-radius: ${props => props.theme.borderRadius};
    content: "";
    width: ${props => props.selected ? "7px" : "6px"};
    top: 0;
    left: ${props => props.selected ? "-1px" : "0"};
    height: 100%;
    position: absolute;
    background-color: ${props => getSemanticElColor(props.theme, props.statusHighlight)};
    z-index: ${LocalStackOrder.AboveContent};
`;

export const RowShadowWrapper = styled.div`
    position: absolute;
    width: calc(100% - ${2 * TableSizes.ShadowPadding}px);
    height: calc(100% - ${2 * TableSizes.ShadowPadding}px);
    top: ${TableSizes.ShadowPadding}px;
    left: ${TableSizes.ShadowPadding}px;
`;

interface IRowShadow {
    selected: boolean;
    isChecked: boolean;
    isList: boolean;
    level: number;
    isValueRow: boolean;
    isForPrint: boolean;
}

export const RowShadow = styled.div<IRowShadow>`
    ${RowBox};
    position: relative;
    border-radius: ${({ theme }) => theme.borderRadius};
    box-shadow: ${props => props.selected ? /*props.isList ? "0 0 4px 0 rgba(0, 0, 0, 0.6)" :*/ "0 0 18px 0 rgba(0, 0, 0, 0.15)" : props.isValueRow ? "0 0 4.9px 0.2px rgba(0, 0, 0, 0.05)" : "none"};
    pointer-events: none;

    ${props => props.level !== undefined && css<IRowShadow>`
        margin-left: ${props => getRowLeftIndent(props.level)};
    `} // html2canvas has problem with box-shadow, render similar background color instead
    ${props => props.isForPrint && props.isValueRow && css`
        position: relative;
        top: 5px;
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0.05);
    `};

    ${props => props.isChecked && css<IRowShadow>`
        &::after {
            content: "";
            position: absolute;
            height: calc(100% - 2px);
            width: calc(100% - 2px);
            left: 0;
            top: 0;
            border-radius: ${props.theme.borderRadius};
            border: 1px solid ${props => transparentize(0.5, props.theme.C_ACT_der)};
        }
    `};
`;

export const getBottomLineColor = (props: IStyledRowProps & PropsWithTheme): string => {
    if (!props.nextRowIsSelected && !props.selected && (!props.isOpen || (!props.hasRows)) && props.nextRow?.type !== RowType.Value) {
        return props.theme.C_DEF_tableBorder;
    }
    return "transparent";
};

export const TableCellStretchedContent = css`
    justify-content: space-between;
    gap: 6px;
`;

export const CellMicroChart = styled.div`
    display: flex;
    ${TableCellStretchedContent};
    align-items: center;
`;

export const CellMicroChartText = styled.div<{ status?: Status }>`
    color: ${props => getSemanticTextColor(props.theme, props.status)};
`;

export const AfterContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    margin-left: 7px;
`;

export const AfterContent = styled.div<{ _minWidth: number; }>`
    width: fit-content;
    display: flex;
    white-space: nowrap;
    align-items: center;
    min-width: ${props => `${props._minWidth}px`};
`;