import React from "react";
import { Icons, StyledTitle } from "./SettingsMenu.style";
import { AvatarSize } from "../../enums";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { SettingsDefinition } from "../../menu-def";
import { WithTranslation, withTranslation } from "react-i18next";
import { getCompanyLogoUrl } from "@utils/CompanyUtils";
import Avatar from "../../components/avatar/Avatar";
import Menu from "../menu/Menu";
import TestIds from "../../testIds";
import { ExtendedShell, IExtendedShellPropsForExtend } from "./ExtendedShell";
import { getSortedFYs } from "@pages/fiscalYear/FiscalYear.utils";
import { FiscalYearStatusCode } from "@odata/GeneratedEnums";
import { getValue, isNotDefined } from "@utils/general";

interface IProps extends IExtendedShellPropsForExtend, WithTranslation {
}

class SettingsMenu extends React.PureComponent<IProps> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    handleItemClick = () => {
        this.props.onHide(true);
    };

    renderHeader() {
        const currentCompany = this.context.getCompany();
        const logoUrl = getCompanyLogoUrl(currentCompany);

        return (
            <>
                <Icons>
                    <Avatar src={logoUrl} size={AvatarSize.L} transparent/>
                </Icons>
                <StyledTitle data-testid={TestIds.Title}>{currentCompany.Name}</StyledTitle>
            </>
        );
    }

    render() {
        let definition = SettingsDefinition
            .filter(group => isNotDefined(group.isVisible) ? true : getValue(group.isVisible, { context: this.context }));

        // only show initial balances if the oldest fiscal year is still active
        const oldestFiscalYear = getSortedFYs(this.context)[0];

        if (oldestFiscalYear.StatusCode !== FiscalYearStatusCode.Active) {
            definition = definition
                .map(group => {
                    return {
                        ...group,
                        items: group.items.filter(item => item.key !== "InitialBalances")
                    };
                });
        }

        return (
            <ExtendedShell header={this.renderHeader()}
                           hotspotContextId={"SettingsMenu"}
                           {...this.props}>
                <Menu definition={definition} onItemClick={this.handleItemClick}/>
            </ExtendedShell>
        );
    }
}


export default withTranslation(["Companies"])(SettingsMenu);
