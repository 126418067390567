import React from 'react';
import TableView from "../../views/table";
import { TableButtonsAction, TableButtonsActionType } from "../../views/table/TableToolbar";


export class CustomerPortalPaymentTableView extends TableView {
    getToolbarButtons(): TableButtonsActionType[] {
        return [
            TableButtonsAction.CSVExport,
            TableButtonsAction.XLSXExport,
            TableButtonsAction.PdfExport,
            TableButtonsAction.Sorting,
            TableButtonsAction.Settings,
        ];
    }
}