import { TFieldDefinition } from "../../PageUtils";
import { BasicInputSizes, FieldType } from "../../../enums";
import { ifAny, IGetValueArgs } from "@components/smart/FieldInfo";
import { FormStorage } from "../../../views/formView/FormStorage";
import { ISelectItem } from "@components/inputs/select/BasicSelect";
import {
    CashBoxEntity,
    ICashBoxEntity,
    ICashReceiptEntity,
    IDocumentEntity
} from "@odata/GeneratedEntityTypes";
import { IEntity } from "@odata/BindingContext";
import { isCashBasisAccountingCompany } from "@utils/CompanyUtils";

export const isInactiveCashBox = (args: IGetValueArgs): boolean => {
    const accounts = (args.storage as FormStorage).context.getCashBoxes();
    const cashBoxId = args.storage.data.entity?.CashBox?.Id;
    const activeCashBox = accounts?.find(box => box.Id === cashBoxId);
    return activeCashBox?.IsActive === false;
};

export const hasItems = (args: IGetValueArgs): boolean => {
    const entity = args.storage.getEntity<ICashReceiptEntity>();

    return entity.Items?.length > 0;
};


export const getDefaultCashBox = (accounts: ICashBoxEntity[], entity: IEntity): ICashBoxEntity => {
    const transactionCurrency = entity.TransactionCurrency?.Code;
    const accountsWithCorrectCurrency = accounts.filter(account => !transactionCurrency || account.TransactionCurrencyCode === transactionCurrency);

    if (accountsWithCorrectCurrency.length === 1) {
        return accountsWithCorrectCurrency[0];
    }

    return accountsWithCorrectCurrency.find(account => account.IsDefault);
};

export const getBasicCashBoxSelectDef = (): TFieldDefinition => {
    return {
        type: FieldType.ComboBox,
        width: BasicInputSizes.XL,
        defaultValue: (args: IGetValueArgs) => {
            const accounts = args.context.getCashBoxes(true);
            const entity = args.data as IDocumentEntity;
            return getDefaultCashBox(accounts, entity)?.Id;
        },
        additionalProperties: [{ id: CashBoxEntity.ReceiptIssuedNumberRangeDefinition }, { id: CashBoxEntity.ReceiptReceivedNumberRangeDefinition }],
        fieldSettings: {
            shouldDisplayAdditionalColumns: true,
            additionalProperties: [{ id: "TransactionCurrencyCode" }],

            itemsFactory: async (args: IGetValueArgs): Promise<ISelectItem[]> => {
                const accounts = (args.storage as FormStorage).context.getCashBoxes(!isInactiveCashBox(args));
                if (isCashBasisAccountingCompany(args.storage.context)) {
                    return accounts?.map(acc => {
                        const item: ISelectItem = {
                            id: acc.Id,
                            label: acc.Name,
                            additionalData: acc,
                            tabularData: [acc.Name, acc.TransactionCurrencyCode]
                        };

                        return item;
                    });
                }
                return accounts?.map(acc => {
                    const label = `211${acc.BalanceSheetAccountNumberSuffix}`;
                    const item: ISelectItem = {
                        id: acc.Id,
                        label,
                        additionalData: acc,
                        tabularData: [label, acc.TransactionCurrencyCode, acc.Name]
                    };

                    return item;
                });
            }
        },

        columns: [{
            id: "BalanceSheetAccountNumberSuffix",
            additionalProperties: [{ id: "ReceiptIssuedNumberRangeDefinition" }, { id: "ReceiptReceivedNumberRangeDefinition" }, { id: "CurrencyCode" }]
        }, { id: "Name" }]
    };
};

export const getCashBoxSelectDef = (): TFieldDefinition => {
    const def = getBasicCashBoxSelectDef();

    return {
        ...def,
        isDisabled: ifAny(isInactiveCashBox, hasItems),
        fieldSettings: {
            ...def.fieldSettings,
            localDependentFields: [{
                from: { id: "TransactionCurrencyCode" },
                to: { id: "TransactionCurrency/Code" }
            }, {
                from: { id: "TransactionCurrency/MinorUnit" },
                to: { id: "TransactionCurrency/MinorUnit" }
            }, {
                from: { id: "BalanceSheetAccountNumberSuffix" },
                to: { id: "CashBox/BalanceSheetAccountNumberSuffix" }
            }, {
                from: { id: "Name" },
                to: { id: "CashBox/Name" }
            }]
        },
        affectedFields: [{ id: "TransactionAmount" }, { id: "Items/TransactionAmount" }, { id: "Items" }, { id: "ExchangeRatePerUnit" }]
    };
};