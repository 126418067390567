import styled, { css } from "styled-components/macro";

import {
    multiline_ellipsis,
    T_PLAIN_big_hig,
    T_PLAIN_EMP,
    T_TITLE_2,
    T_TITLE_3,
    T_TITLE_3_small,
    T_TITLE_4,
    T_TITLE_4_NORMAL
} from "../../global.style";
import { Button } from "../button";

export const ListItemColumnWidth = 320;
export const TitleColumnWidth = 181;

interface ISmall {
    _small?: boolean;
}

export enum ListItemStatus {
    Active,
    Progress
}

export enum ItemContentRowType {
    OneRow,
    Small,
    Large
}

export const StyledListItem = styled.div<ISmall>`
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: ${props => props._small ? "flex-start" : "stretch"};
    flex-direction: ${props => props._small ? "column" : "row"};
    padding: 30px 0 32px 0;
`;

export const DefaultTitle = styled.div `
    ${T_PLAIN_big_hig};
    font-style: italic;
    text-align: center;
    font-weight: bold;
`;

export const ActiveTitle = styled.span<{ status: ListItemStatus }>`
    color: ${props => props.status === ListItemStatus.Active ? props.theme.C_SEM_el_highlighted : props.theme.C_SEM_text_warning};
    ${T_PLAIN_big_hig};
    font-style: italic;
    text-align: center;
    margin-top: 5px;
`;

export const ControlsWrapper = styled.div<ISmall>`
    padding-left: ${props => props._small ? `${TitleColumnWidth + 50}px` : "0"};
    display: flex;
    flex-direction: ${props => props._small ? "row" : "column"};
    width: ${props => props._small ? "100%" : "auto"};
    justify-content: flex-end;
    align-items: flex-start;
    padding-right: 10px;
`;

export const ActiveBar = styled.div<{ status: ListItemStatus }>`
    position: absolute;
    top: 28px;
    left: 0;
    bottom: 28px;
    width: 6px;
    background-color: ${props => props.status === ListItemStatus.Active ? props.theme.C_SEM_el_highlighted : props.theme.C_SEM_text_warning};
    border-bottom-left-radius: 7px;
    border-top-left-radius: 7px;
`;

export const ContentWrapper = styled.div<ISmall>`
    display: flex;
    align-items: flex-start;
    padding-left: 8px;
    min-height: 220px;
`;

export const ColumnWrapper = styled.div<ISmall>`
    display: flex;
    flex-wrap: wrap;
    // compensate for the margin on ContentColumn
    margin-bottom: ${props => props._small? "" : "5px"};
`;

export const ColumnContent = styled.div`
`;

export const ColumnTitle = styled.div `
    ${T_PLAIN_big_hig};
    margin-bottom: 7px;
`;

export const ContentColumn = styled.div<ISmall & {_isLast?: boolean}>`
    flex: 0 0 auto;
    width: ${ListItemColumnWidth}px;
    margin-right: 30px;
    /* min-height: 210px; */
    max-height: 320px;
    overflow: hidden;
    // margin between columns when wrapped
    margin-bottom: ${props => props._small && props._isLast? "14px" : "30px"};

    &:hover, &:focus {
        overflow-y: auto;
    }

    h6 {
        margin-bottom: 1em;
        ${T_PLAIN_big_hig};
    }
`;

interface IListItem {
    hasSpaceAfter?: boolean;
    hasEllipsis?: boolean;
}

export const ListItemContentRowStyled = styled.div<{ hasSpaceAfter?: boolean }>`
    ${multiline_ellipsis("100%", 2)};
    margin-bottom: ${props => props.hasSpaceAfter ? "12px" : 0};
`;


export const ListItemValueNewRowWrapper = styled.div``;

export const ListItemContentValue = styled.span`
    ${T_TITLE_4_NORMAL};
    margin-left: 4px;
`;

export const ListItemValueNewRow = styled.div <{ isLarge: boolean }>`
    ${props => props.isLarge ? T_PLAIN_EMP : T_TITLE_4_NORMAL}
`;

const OneRowDesc = css`
    ${T_TITLE_4_NORMAL};
    opacity: 0.5;
`;

export const ListItemDescription = styled.span<{ type: ItemContentRowType }>`
    ${({ type }) => {
        if (type === ItemContentRowType.OneRow) return OneRowDesc;
        if (type === ItemContentRowType.Large) return T_TITLE_3;
        if (type === ItemContentRowType.Small) return T_TITLE_3_small;
        
        return T_TITLE_3;
    }}
`;

export const ListItemDelimiter = styled.span`
    margin-left: 2px;
    margin-right: 2px;
`;


export const ListItemRow = styled.div`
    margin-bottom: 2px;
`;

export const ListItemLabel = styled.span`
    ${T_TITLE_4};
`;

export const ListItemMoreRowLabel = styled.div`
    ${T_TITLE_4};
    margin-bottom: 4px;
`;

export const ListItemContentLabel = styled.span<IListItem>`
    ${T_TITLE_4};
    margin-bottom: 4px;
`;

export const ListItemValue = styled.div<IListItem>`
    margin-bottom: ${props => props.hasSpaceAfter ? "1em" : 0};
    ${T_TITLE_4_NORMAL};
`;

export const TitleColumn = styled(ContentColumn)`
    width: ${TitleColumnWidth}px;
    min-height: 143px;
    display: grid;
    grid-gap: 0;
    grid-template-columns: 143px 1fr;
    grid-template-rows: 130px 1fr;
    grid-template-areas: "icon toolbar" "text toolbar";
    margin-bottom: 15px;
`;

export const ToolbarWrapper = styled.div`
    grid-area: toolbar;
    grid-column: 2;
    grid-row: 1 / span 2;
`;

export const Title = styled.span`
    text-align: center;
    grid-area: text;
    ${T_TITLE_2};
`;

export const TitleWrapper = styled.div`
    ${multiline_ellipsis("100%", 2)};
    max-width: 320px;
    margin-bottom: 18px;
`;

export const IconWrapper = styled.div<{
    isClickable?: boolean;
}>`
    width: 143px;
    height: 146px;
    margin-top: -13px;
    grid-area: icon;
    color: ${props => props.theme.C_TEXT_primary};
    cursor: ${props => props.isClickable ? "pointer" : "default"};
`;

export const CustomImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    grid-area: icon;
    cursor: pointer;
`;

export const ListItemButton = styled(Button)<{
    isSmall?: boolean
}>`
    white-space: nowrap;

    &:not(:first-child) {
        margin-left: 21px;
    }
`;

export const ListItemColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ExtensionListDescriptionText = styled.div `
    margin-bottom: 12px;
`;

export const ListItemDescriptionTitle = styled.div`
    && {
        ${T_TITLE_2};
        margin-bottom: 15px;
    }

    margin-right: 18px;

`;

export const ListItemTitlesWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    margin-bottom: 15px;

    ${ListItemDescriptionTitle} {
        margin-bottom: 0;
    }
`;

export const ListItemSubDescription = styled.div`
    ${T_TITLE_3_small};
    white-space: nowrap;
    text-transform: capitalize;
    position: relative;
    bottom: 2px;
`;