import React from "react";
import { FormStorage, IFormStorageSaveResult, ISaveArgs } from "../../views/formView/FormStorage";
import { ISmartFieldChange, ISmartFieldValidationError } from "@components/smart/smartField/SmartField";
import { Button, ButtonGroup } from "../../components/button";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import BindingContext, { IEntity } from "../../odata/BindingContext";
import { getNewItemsMaxId } from "@odata/Data.utils";
import { AddButtonWrapper, ButtonsWrapper } from "./FiscalYear.styles";
import { FormMode, Sort, ValidationErrorType } from "../../enums";
import { isSameMonth, isValidDate } from "@components/inputs/date/utils";
import { FormViewForExtend, IFormViewProps } from "../../views/formView/FormView";
import {
    createDefaultPeriods,
    DefaultPeriodsPath,
    FiscalYearDefaultPeriod,
    getActiveFiscalYears,
    getFiscalPeriodNumber,
    getFiscalYearNumber,
    getSortedFYs,
    IFiscalYearCustomData,
    IFiscalYearEntityExtendedEntity,
    refreshFiscalYears
} from "./FiscalYear.utils";
import { setBreadCrumbs } from "../../views/formView/Form.utils";
import { createChartOfAccountsFromChartOfAccounts } from "../chartOfAccounts/ChartOfAccounts.utils";
import {
    FiscalPeriodEntity,
    FiscalYearEntity,
    IChartOfAccountsEntity,
    IFiscalYearEntity
} from "@odata/GeneratedEntityTypes";
import { WithOData, withOData } from "@odata/withOData";
import { withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { FiscalYearStatusCode } from "@odata/GeneratedEnums";
import SmartFormDeleteButton from "../../components/smart/smartFormDeleteButton/SmartFormDeleteButton";
import { getUtcDayjs } from "../../types/Date";

interface IProps extends IFormViewProps<IFiscalYearEntityExtendedEntity, IFiscalYearCustomData>, WithOData {

}

class FiscalYearFormView extends FormViewForExtend<IFiscalYearEntityExtendedEntity, IProps> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;
    static defaultProps = {
        title: "FiscalYear:TitleSingular"
    };

    _refAddButton = React.createRef<AddButton>();

    onAfterLoad = async () => {
        if (this.props.storage.formMode !== FormMode.AuditTrail) {
            setBreadCrumbs(this.props.storage);

            if (this.props.storage.data.bindingContext.isNew()) {
                await this.setDefaultInterval();

                this.props.storage.setCustomData({
                    periodsBackup: null
                });
                // default periods should be monthly by default
                this.setDefaultPeriods(FiscalYearDefaultPeriod.Monthly);
                this.createDefaultPeriods(FiscalYearDefaultPeriod.Monthly);
                this.props.storage.refreshFields();
            } else {
                const currentDataLook = this.getCurrentDataLook();

                if (currentDataLook !== FiscalYearDefaultPeriod.Custom) {
                    this.storePeriodsBackup();
                    this.props.storage.refreshFields();
                }

                this.setDefaultPeriods(currentDataLook);
            }

            this.revalidatePeriods(true);
        }

        this.props.storage.refresh();
        return super.onAfterLoad();
    };

    /**
     * We need to store fiscal periods so that we are able to restore them when default periods switch is turned off
     */
    storePeriodsBackup = () => {
        const periods = this.props.storage.data.entity?.Periods ? [...this.props.storage.data.entity.Periods] : null;

        this.props.storage.setCustomData({
            periodsBackup: periods
        });
    };

    restorePeriodsBackup = () => {
        const { storage } = this.props;
        const { entity } = storage.data;
        entity.Periods = storage.getCustomData().periodsBackup;

        if (!entity.Periods) {
            const newFiscalPeriod = {
                ...BindingContext.createNewEntity(1),
                Number: getFiscalPeriodNumber(entity.Number, 0),
                DateStart: this.props.storage.data.entity.DateStart
            };

            entity.Periods = [newFiscalPeriod];

            this.setPeriodEndDatePreviewValue(newFiscalPeriod);
        } else {
            this.correctPeriodNumbers(storage);
        }
    };

    correctPeriodNumbers(storage: FormStorage<IFiscalYearEntity>) {
        const { entity, bindingContext } = storage.data;
        // correct possible wrong period numbers, so they match with FY
        entity.Periods?.forEach((period: IEntity, idx) => {
            const periodNumberBc = bindingContext.navigate(FiscalYearEntity.Periods).addKey(period).navigate(FiscalPeriodEntity.Number);
            storage.setValue(periodNumberBc, getFiscalPeriodNumber(entity.Number, idx));
        });
    }

    getFiscalYears = (): IFiscalYearEntity[] => {
        return getSortedFYs(this.context);
    };

    getOtherFiscalYears = (): IFiscalYearEntity[] => {
        return this.getFiscalYears().filter((fiscalYear: IFiscalYearEntity) => fiscalYear.Number !== this.props.storage.data.entity.Number);
    };

    setDefaultInterval = async (): Promise<void> => {
        // force reload FYs in case there were some change in the meantime, so we have actual data
        await refreshFiscalYears(this.props.storage.context);
        // get last active fiscal year, check every other after it
        const fiscalYears = this.getFiscalYears();
        if (fiscalYears?.length) {
            let newStartDate = undefined;
            let newEndDate = undefined;
            let i = fiscalYears.findIndex((year: IEntity) => year.StatusCode === FiscalYearStatusCode.Active);
            for (i; i < fiscalYears.length; i++) {
                const end = getUtcDayjs(fiscalYears[i].DateEnd);
                if (i === (fiscalYears.length - 1)) {
                    newStartDate = end.add(1, "day");
                    newEndDate = end.add(12, "month").endOf("month"); // end of month because of leap years;
                } else {
                    const start = getUtcDayjs(fiscalYears[i + 1]?.DateStart);
                    const monthDiff = end.diff(start, "month");
                    if (monthDiff < 0) {
                        newStartDate = end.add(1, "day");
                        newEndDate = start.subtract(1, "day");
                        if (newEndDate.diff(newStartDate, "month") > 11) {
                            newEndDate = newEndDate = end.add(12, "month").endOf("month"); // end of month because of leap years
                        }
                        break;
                    }
                }
            }
            this.props.storage.setValueByPath(FiscalYearEntity.DateStart, newStartDate.toDate());
            this.props.storage.setValueByPath(FiscalYearEntity.DateEnd, newEndDate.toDate());
            const newFiscalYearNumber = getFiscalYearNumber(newStartDate.toDate(), newEndDate.toDate(), this.getOtherFiscalYears());
            this.props.storage.setValueByPath(FiscalYearEntity.Number, newFiscalYearNumber);
            this.props.storage.refreshGroupByKey("basic");
        }
    };

    createDefaultPeriods = (defaultPeriod: FiscalYearDefaultPeriod) => {
        const fiscalYear = this.props.storage.data.entity;
        const periods: IEntity[] = createDefaultPeriods({
            dateStart: fiscalYear.DateStart,
            dateEnd: fiscalYear.DateEnd,
            includeIds: true,
            step: defaultPeriod === FiscalYearDefaultPeriod.Quarterly ? 3 : 1,
            fiscalYearNumber: fiscalYear.Number
        });

        const bindingContext = this.props.storage.data.bindingContext;
        this.props.storage.setValue(bindingContext.navigate(FiscalYearEntity.Periods), periods);
    };

    /**
     * Tests if the current data of FiscalYear looks like data generated by the createDefaultPeriods
     * We don't store default periods state on backend, but we need the switch to be initialized in its correct state
     */
    getCurrentDataLook = () => {
        if (this.hasDefaultMonthlyLook()) {
            return FiscalYearDefaultPeriod.Monthly;
        } else if (this.hasDefaultQuarterlyLook()) {
            return FiscalYearDefaultPeriod.Quarterly;
        } else {
            return FiscalYearDefaultPeriod.Custom;
        }
    };

    hasDefaultMonthlyLook = () => {
        const dateStart = getUtcDayjs(this.props.storage.data.entity.DateStart);
        const dateEnd = getUtcDayjs(this.props.storage.data.entity.DateEnd);
        const periods = this.props.storage.data.entity.Periods;
        const monthDiff = dateEnd.diff(dateStart, "month");

        if (periods?.length !== monthDiff + 1) {
            return false;
        }

        // todo check that names of the periods looks like generated names from createDefaultPeriods
        // or even better, remove check completely or implement it on backend

        return true;
    };

    hasDefaultQuarterlyLook = () => {
        const dateStart = getUtcDayjs(this.props.storage.data.entity.DateStart);
        const dateEnd = getUtcDayjs(this.props.storage.data.entity.DateEnd);
        const periods = this.props.storage.data.entity.Periods;
        const monthDiff = dateEnd.diff(dateStart, "month");

        return periods?.length === (monthDiff + 1) / 3;
    };

    getDefaultPeriods = () => {
        return this.props.storage.getValue(this.props.storage.data.bindingContext.navigate(DefaultPeriodsPath));
    };

    setFieldError = (bindingContext: BindingContext, message: string) => {
        const error: ISmartFieldValidationError = {
            bindingContext: bindingContext,
            data: null
        };
        if (message) {
            error.data = {
                errorType: ValidationErrorType.Field,
                message: message
            };
        }
        this.props.storage.handleValidationError(error);
    };

    clearPeriodsErrors = () => {
        const bindingContext = this.props.storage.data.bindingContext;

        for (const element of bindingContext.iterateNavigation(FiscalYearEntity.Periods, this.props.storage.data.entity.Periods)) {
            for (const path of [FiscalPeriodEntity.DateStart, FiscalPeriodEntity.DateEnd, FiscalPeriodEntity.Name]) {
                const pathBc = element.bindingContext.navigate(path);

                this.setFieldError(pathBc, null);
            }
        }
    };

    revalidatePeriods = (ignoreErrors?: boolean) => {
        let isValid = true;
        const bindingContext = this.props.storage.data.bindingContext;
        let firstPeriodStartBc, monthsValid: boolean;
        let lastPeriodEndBc: BindingContext = undefined;
        for (const element of bindingContext.iterateNavigation(FiscalYearEntity.Periods, this.props.storage.data.entity.Periods)) {
            const start = element.bindingContext.navigate(FiscalYearEntity.DateStart);
            if (!firstPeriodStartBc) {
                firstPeriodStartBc = start;
            }
            if (lastPeriodEndBc) {
                const lastDate = this.props.storage.getValue(lastPeriodEndBc) as Date;
                const startDate = this.props.storage.getValue(start) as Date;
                const nextMonth = getUtcDayjs(lastDate).add(1, "month").toDate();

                monthsValid = isSameMonth(startDate, nextMonth);
                isValid = isValid && monthsValid;

                if (!ignoreErrors) {
                    if (monthsValid) {
                        this.setFieldError(lastPeriodEndBc, null);
                        this.setFieldError(start, null);
                    } else {
                        this.setFieldError(lastPeriodEndBc, this.props.storage.t("FiscalYear:Errors.NextPeriodDoesNotFollow"));
                        this.setFieldError(start, this.props.storage.t("FiscalYear:Errors.LastPeriodDoesNotFollow"));
                    }
                }
            }
            lastPeriodEndBc = element.bindingContext.navigate(FiscalPeriodEntity.DateEnd);
        }

        const periodStart = bindingContext.navigate(FiscalYearEntity.DateStart);
        const startDate = this.props.storage.getValue(periodStart) as Date;
        const firstDate = firstPeriodStartBc ? this.props.storage.getValue(firstPeriodStartBc) as Date : null;

        monthsValid = isSameMonth(startDate, firstDate);
        isValid = isValid && monthsValid;

        if (!ignoreErrors) {
            if (!firstPeriodStartBc || monthsValid) {
                this.setFieldError(periodStart, null);

                if (firstPeriodStartBc) {
                    this.setFieldError(firstPeriodStartBc, null);
                }
            } else {
                // this.setFieldError(periodStart, this.props.storage.t("FiscalYear:Errors.FirstPeriodError"));
                this.setFieldError(firstPeriodStartBc, this.props.storage.t("FiscalYear:Errors.FirstPeriodError"));
            }
        }


        const periodEnd = bindingContext.navigate("DateEnd");
        const endDate = this.props.storage.getValue(periodEnd) as Date;
        const lastDate = lastPeriodEndBc ? this.props.storage.getValue(lastPeriodEndBc) as Date : null;

        monthsValid = isSameMonth(endDate, lastDate);
        isValid = isValid && monthsValid;

        if (!ignoreErrors) {
            if (!lastPeriodEndBc || monthsValid) {
                this.setFieldError(periodEnd, null);

                if (lastPeriodEndBc) {
                    this.setFieldError(lastPeriodEndBc, null);
                }
            } else {
                // this.setFieldError(periodEnd, this.props.storage.t("FiscalYear:Errors.LastPeriodError"));
                this.setFieldError(lastPeriodEndBc, this.props.storage.t("FiscalYear:Errors.LastPeriodError"));
            }
        }

        this.props.storage.setCustomData({
            arePeriodsValid: isValid
        });
        this._refAddButton.current?.forceUpdate();
    };

    handleDefaultPeriodsChange = (e: ISmartFieldChange) => {
        if (e.bindingContext.getNavigationPath() === DefaultPeriodsPath) {
            const currentDefaultPeriods = this.getDefaultPeriods();

            if (e.value === currentDefaultPeriods) {
                return;
            }

            if (this.getDefaultPeriods() === FiscalYearDefaultPeriod.Custom) {
                this.storePeriodsBackup();
            }

            switch (e.value) {
                case FiscalYearDefaultPeriod.Monthly:
                    this.createDefaultPeriods(FiscalYearDefaultPeriod.Monthly);
                    break;
                case FiscalYearDefaultPeriod.Quarterly:
                    this.createDefaultPeriods(FiscalYearDefaultPeriod.Quarterly);
                    break;
                case FiscalYearDefaultPeriod.Custom:
                    this.restorePeriodsBackup();
                    break;
            }

            this.clearPeriodsErrors();
            this.revalidatePeriods(true);
            this.props.storage.refresh();
        }
    };

    handleEndDateValues = (e: ISmartFieldChange) => {
        if (e.bindingContext.getPath() === "DateEnd" && e.value) {
            //End dates must be the end of given month
            e.value = getUtcDayjs(e.value as Date).endOf("month").toDate();
        }
    };

    handleFiscalYearEndDateChange = (e: ISmartFieldChange) => {
        if (e.bindingContext.getNavigationPath(true) === "DateEnd") {
            const { storage } = this.props;
            const { entity } = storage.data;

            const newFiscalYearNumber = getFiscalYearNumber(entity.DateStart, e.value as Date, this.getOtherFiscalYears());
            storage.setValueByPath(FiscalYearEntity.Number, newFiscalYearNumber);
            this.correctPeriodNumbers(storage);
        }
    };

    handleFiscalPeriodDateStartChange = (e: ISmartFieldChange) => {
        if (e.bindingContext.getPath() === "DateStart" && e.bindingContext.getParent().removeKey().getPath() === "Periods" && e.value) {
            this.props.storage.setPreviewValue(e.bindingContext.getParent().navigate("DateEnd"),
                    getUtcDayjs(e.value as Date).endOf("month").toDate()
            );
        }
    };

    cancelDefaultPeriodsIfCriticalChange = (e: ISmartFieldChange) => {
        const criticalProps = ["Periods/Name", "Periods/DateStart", "Periods/DateEnd"];
        const path = e.bindingContext.getNavigationPath(true);

        if (criticalProps.includes(path)) {
            this.setDefaultPeriods(FiscalYearDefaultPeriod.Custom);
            this.props.storage.refreshFields();
        }
    };

    handleFiscalYearDateChange = (e: ISmartFieldChange) => {
        const path = e.bindingContext.getPath(true);

        if (path === "DateStart" || path === "DateEnd") {
            const parentContext = e.bindingContext.getParent();
            const dateStartBc = parentContext.navigate("DateStart");
            const dateStart = this.props.storage.getValue(dateStartBc);
            const dateStartError = this.props.storage.getError(dateStartBc);
            const dateStartErrorOk = dateStartError?.errorType !== ValidationErrorType.TypeError;
            const dateEndBc = parentContext.navigate("DateEnd");
            const dateEnd = this.props.storage.getValue(dateEndBc);
            const dateEndError = this.props.storage.getError(dateEndBc);
            const dateEndErrorOk = dateEndError?.errorType !== ValidationErrorType.TypeError;
            const datesValid = isValidDate(dateStart) && isValidDate(dateEnd);

            if (datesValid && dateStart >= dateEnd) {
                this.setFieldError(dateStartBc, this.props.storage.t("FiscalYear:Errors.StartDateTooBig"));
            } else if (datesValid && getUtcDayjs(dateEnd).diff(dateStart, "month") > 24) {
                this.setFieldError(dateEndBc, this.props.storage.t("FiscalYear:Errors.FiscalYearRangeTooBig"));
            } else {
                if (dateStartErrorOk) {
                    this.setFieldError(dateStartBc, null);
                }

                if (dateEndErrorOk) {
                    this.setFieldError(dateEndBc, null);
                }

                if (dateStartErrorOk && dateEndErrorOk) {
                    const defaultPeriods = this.getDefaultPeriods();

                    if (defaultPeriods !== FiscalYearDefaultPeriod.Custom) {
                        this.createDefaultPeriods(defaultPeriods);
                        this.props.storage.refresh();
                    }
                }
            }
            this.revalidatePeriods(true);
        }
    };

    handleChange = async (e: ISmartFieldChange) => {
        // handlers BEFORE handleChange is called
        this.handleEndDateValues(e);
        this.cancelDefaultPeriodsIfCriticalChange(e);
        this.handleDefaultPeriodsChange(e);
        // so far, isn't supposed to be used
        // this.handlePeriodStartDateChange(e);
        this.handleFiscalYearEndDateChange(e);

        this.props.storage.handleChange(e);

        // handlers AFTER handleChange is called that need already updated data
        this.handleFiscalYearDateChange(e);
        this.handleFiscalPeriodDateStartChange(e);

        this.props.storage.refreshFields();
    };

    handleRemove = (bc: BindingContext) => {
        this.setDefaultPeriods(FiscalYearDefaultPeriod.Custom);

        this.props.storage.handleRemove(bc);
        this.revalidatePeriods(true);
        this.props.storage.refresh();
    };

    handleAddPeriod = () => {
        this.setDefaultPeriods(FiscalYearDefaultPeriod.Custom);

        const periods = [...this.props.storage.data.entity.Periods ?? []];
        const previousPeriod = periods[periods.length - 1];
        const maxNewId = getNewItemsMaxId(periods);
        const newPeriod = BindingContext.createNewEntity(maxNewId + 1);

        newPeriod.Number = getFiscalPeriodNumber(this.props.storage.data.entity.Number, periods.length);

        if (previousPeriod && previousPeriod.DateEnd) {
            newPeriod.DateStart = getUtcDayjs(previousPeriod.DateEnd).add(1, "month").startOf("month").toDate();
        }

        periods.push(newPeriod);
        this.setPeriodEndDatePreviewValue(newPeriod);

        this.props.storage.data.entity.Periods = periods;
        this.revalidatePeriods(true);
        this.props.storage.refresh();
    };

    setPeriodEndDatePreviewValue = (period: IEntity) => {
        const periodBc = this.props.storage.data.bindingContext.navigate("Periods").addKey(period).navigate("DateEnd");
        this.props.storage.setPreviewValue(periodBc,
                getUtcDayjs(period.DateStart).endOf("month").toDate()
        );
    };

    setDefaultPeriods = (defaultPeriods: FiscalYearDefaultPeriod) => {
        this.props.storage.setValue(this.props.storage.data.bindingContext.navigate(DefaultPeriodsPath), defaultPeriods);
    };

    getFiscalYearStatus = () => {
        return this.props.storage.data?.entity?.StatusCode;
    };

    save = async (args: ISaveArgs = {}) => {
        const fnOnFail = () => {
            this.props.onSaveFail();
            this.forceUpdate(this.scrollPageUp);
        };

        this.props.onBeforeSave();

        this.revalidatePeriods();

        if (await this.props.storage.validate()) {
            fnOnFail();
            return null;
        }

        let result: IFormStorageSaveResult;
        const isNew = this.props.storage.data.bindingContext.isNew();

        if (isNew) {
            // inject current company to new fiscal year
            this.props.storage.data.entity.Company = { Id: this.context.getCompany().Id };

            const name = this.props.storage.getValueByPath("Number");
            let newChartOfAccounts: IChartOfAccountsEntity;

            try {
                const activeFiscalYears = getActiveFiscalYears(this.context, Sort.Desc);
                const newestActiveFiscalYear = activeFiscalYears?.[0];

                if (newestActiveFiscalYear) {
                    newChartOfAccounts = await createChartOfAccountsFromChartOfAccounts(
                        newestActiveFiscalYear.ChartOfAccounts.Id.toString(),
                        name,
                        this.props.storage.data.entity,
                        this.props.storage.oData);

                    result = {
                        bindingContext: this.props.storage.data.bindingContext.removeKey().addKey(newChartOfAccounts.Id.toString()),
                        data: newChartOfAccounts,
                        response: newChartOfAccounts
                    };
                }
            } catch (e) {
                this.props.storage.handleOdataError({
                    error: e,
                    bindingContext: this.props.storage.data.bindingContext
                });
                fnOnFail();
                return result;
            }

            this.props.storage.store({
                bindingContext: this.props.storage.data.bindingContext.removeKey().addKey(newChartOfAccounts.FiscalYear.Id)
            });

            this.props.storage.displaySaveOkMessage(this.props.storage.t("FiscalYear:Validation.Saved"));
            // todo better solution
            this.props.storage._preserveAlert = true;
        } else {
            result = await this.props.storage.save({
                data: args.data,
                successSubtitle: this.props.storage.t("FiscalYear:Validation.Saved")
            });

            if (!result) {
                fnOnFail();
                return result;
            }
        }

        // clear cached fiscal years to retrieve new/updated data
        refreshFiscalYears(this.props.storage.context);

        setBreadCrumbs(this.props.storage);
        // refreshWholeTable because form doesn't change only one entity, but fiscal year and all of its periods
        this.onAfterSave(isNew, false);
        this.forceUpdate(isNew ? this.scrollPageDown : undefined);

        return result;
    };

    handleSaveClick = () => {
        this.save();
    };

    handleDeactivate = () => {
        this.setFYStatus(FiscalYearStatusCode.NotUsed);
    };

    handleActivate = () => {
        this.setFYStatus(FiscalYearStatusCode.Active);
    };

    async setFYStatus(StatusCode: FiscalYearStatusCode) {
        this.props.storage.setBusy(true);

        await this.save({ data: { StatusCode } });
    }

    async handleDelete(): Promise<void> {
        await super.handleDelete();
        await refreshFiscalYears(this.props.storage.context);
    }

    renderButtons = () => {
        const fiscalYearStatus = this.getFiscalYearStatus();
        const isAddingNewCompany = this.context.getAddingNewCompany();

        return (
            <ButtonsWrapper>
                {fiscalYearStatus === FiscalYearStatusCode.NotUsed &&
                    <AddButtonWrapper>
                        <AddButton
                            ref={this._refAddButton}
                            onAddPeriod={this.handleAddPeriod}
                            title={this.props.storage.t("Common:General.Add")}
                        >
                        </AddButton>
                    </AddButtonWrapper>
                }
                <ButtonGroup wrap={"wrap"}>
                    <Button onClick={this.props.onCancel}
                            isTransparent>{this.props.storage.t("Common:General.Cancel")}</Button>
                    {!isAddingNewCompany && this.isDeletable &&
                        <SmartFormDeleteButton storage={this.props.storage}
                                               onClick={this.handleDelete}/>
                    }
                    {!isAddingNewCompany && !this.props.storage.data.bindingContext.isNew() &&
                        <>
                            {fiscalYearStatus === FiscalYearStatusCode.NotUsed &&
                                <Button
                                    isTransparent
                                    onClick={this.handleActivate}>{this.props.storage.t("Common:General.Activate")}</Button>
                            }
                            {fiscalYearStatus === FiscalYearStatusCode.Active &&
                                <Button
                                    isTransparent
                                    onClick={this.handleDeactivate}>{this.props.storage.t("Common:General.Deactivate")}</Button>
                            }
                        </>
                    }
                    {fiscalYearStatus !== FiscalYearStatusCode.Closed &&
                        <Button onClick={this.handleSaveClick}>{this.props.storage.t("Common:General.Save")}</Button>
                    }
                </ButtonGroup>
            </ButtonsWrapper>
        );
    };
}

interface IAddButtonProps {
    onAddPeriod: () => void;
    title: string;
}

class AddButton extends React.Component<IAddButtonProps> {
    render() {
        return <Button onClick={this.props.onAddPeriod}>{this.props.title}</Button>;
    }
}


export default withPermissionContext(withOData(FiscalYearFormView));