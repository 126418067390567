import React from "react";
import { IDashboardTileComponentProps } from "../../dashboard";
import DashboardTileHeader from "../../dashboard/DashboardTileHeader";
import DashboardTileBackground from "../../dashboard/DashboardTileBackground";
import {
    ItemGroupHeader,
    KeyboardShortcutTileGrid, ShortcutDescription
} from "./KeyboardShortcutsTile.styles";
import { WithTranslation, withTranslation } from "react-i18next";
import { TFunction } from "i18next";

interface IProps extends IDashboardTileComponentProps, WithTranslation {

}


interface IKeyboardShortcutItemProps {
    shortcut: string;
    description: string;
}

const KeyboardShortcutItem: React.FC<IKeyboardShortcutItemProps> = (props) => {
    return (
        <>
            <div>{props.shortcut}</div>
            <ShortcutDescription>{props.description}</ShortcutDescription>
        </>
    );
};

const getMainShortcuts = (t: TFunction): IKeyboardShortcutItemProps[] => {
    return [
        { shortcut: "ALT + N", description: t("Home:KeyboardShortcuts.ALT_N") },
        { shortcut: "ALT + C", description: t("Home:KeyboardShortcuts.ALT_C") },
        { shortcut: "ALT + S", description: t("Home:KeyboardShortcuts.ALT_S") },
        { shortcut: "ALT + D", description: t("Home:KeyboardShortcuts.ALT_D") }
    ];
};

const getItemShortcuts = (t: TFunction): IKeyboardShortcutItemProps[] => {
    return [
        { shortcut: "ALT + R", description: t("Home:KeyboardShortcuts.ALT_R") },
        { shortcut: "ALT + X", description: t("Home:KeyboardShortcuts.ALT_X") },
        { shortcut: "ALT + C", description: t("Home:KeyboardShortcuts.ALT_C_ITEMS") },
        { shortcut: "ALT + ↓", description: t("Home:KeyboardShortcuts.ALT_DOWN_ARROW") },
        { shortcut: "ALT + ↑", description: t("Home:KeyboardShortcuts.ALT_UP_ARROW") }
    ];
};


const KeyboardShortcutsTile: React.FC<IProps> = (props) => {
    return (
        <DashboardTileBackground backgroundColor={props.info.backgroundColor}
                                 inEditMode={props.inEditMode}>
            <DashboardTileHeader>{props.info.title}</DashboardTileHeader>
            <KeyboardShortcutTileGrid>
                {getMainShortcuts(props.t).map(shortcut =>
                    <KeyboardShortcutItem key={shortcut.shortcut}
                                          shortcut={shortcut.shortcut}
                                          description={shortcut.description}/>
                )}


                <ItemGroupHeader>{props.t("Home:KeyboardShortcuts.GroupItemsHeader")}</ItemGroupHeader>
                {getItemShortcuts(props.t).map(shortcut =>
                    <KeyboardShortcutItem key={shortcut.shortcut}
                                          shortcut={shortcut.shortcut}
                                          description={shortcut.description}/>
                )}
            </KeyboardShortcutTileGrid>
        </DashboardTileBackground>
    );
};

export default withTranslation(["Home"])(React.memo(KeyboardShortcutsTile));