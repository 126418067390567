import { isCurrentDay } from "@components/inputs/date/utils";
import Tooltip from "@components/tooltip";
import dayjs from "dayjs";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { AvatarSize, MouseButton } from "../enums";
import { KeyName } from "../keyName";
import TestIds from "../testIds";
import DateType from "../types/Date";
import { INotification } from "./Notifications";
import {
    MarkAsRead,
    MarkAsReadWrapper,
    NotificationAvatar,
    NotificationContentWrapper,
    NotificationDescription,
    NotificationInfo,
    NotificationSubtitle,
    NotificationTime,
    NotificationTitle,
    NotificationWrapper,
    StyledNotification
} from "./Notifications.styles";

interface IProps extends INotification {
    onClick?: (id: string) => void;
    onIsUnreadChange?: (id: string, isUnread: boolean) => void;
}

class Notification extends React.PureComponent<IProps & WithTranslation> {
    handleIsUnreadClick = (): void => {
        this.props.onIsUnreadChange?.(this.props.id, !this.props.isUnread);
    };

    handleIsUnreadKeydown = (e: React.KeyboardEvent): void => {
        if ([KeyName.Enter, KeyName.Space].includes(e.key as KeyName)) {
            e.stopPropagation();
            this.handleIsUnreadClick();
        }
    };

    handleNotificationClick = (event: React.SyntheticEvent): void => {
        if ((event as React.MouseEvent).button === MouseButton.MainButton) {
            this.props.onClick?.(this.props.id);
        }
    };

    getDateTime = (): string => {
        let dateTimeString: string;

        if (isCurrentDay(dayjs(this.props.time))) {
            dateTimeString = `${this.props.t("Components:Notifications.Today")}, ${DateType.localFormat(this.props.time, DateType.defaultTimeFormat)}`;
        } else if (dayjs(this.props.time).isSame(dayjs().subtract(1, "day"), "day")) {
            dateTimeString = `${this.props.t("Components:Notifications.Yesterday")}, ${DateType.localFormat(this.props.time, DateType.defaultTimeFormat)}`;
        } else {
            dateTimeString = DateType.localFormat(this.props.time, DateType.defaultDateFormat);
        }

        return dateTimeString;
    };

    render() {
        return (
                <NotificationWrapper data-testid={TestIds.Notification}>
                    <MarkAsReadWrapper>
                        <MarkAsRead $isUnread={this.props.isUnread}
                                    onClick={this.handleIsUnreadClick}
                                    onKeyDown={this.handleIsUnreadKeydown}
                                    title={this.props.t(`Components:Notifications.${this.props.isUnread ? "MarkAsRead" : "MarkAsUnread"}`)}
                                    tabIndex={0}/>
                    </MarkAsReadWrapper>
                    <StyledNotification $isUnread={this.props.isUnread}
                                        onClick={this.handleNotificationClick}
                                        tabIndex={0}
                                        isWithoutStyle
                                        link={this.props.url || "#"}>
                        <NotificationAvatar src={this.props.avatarSrc}
                                            size={AvatarSize.SM}
                                            isRound>

                        </NotificationAvatar>
                        <NotificationContentWrapper>
                            <NotificationTitle data-testid={TestIds.Title}>
                                {this.props.title}
                            </NotificationTitle>
                            <NotificationSubtitle data-testid={TestIds.Subtitle}>
                                {this.props.subtitle}
                            </NotificationSubtitle>
                            <Tooltip content={this.props.info} onlyShowWhenChildrenOverflowing>
                                {(ref) => (
                                        <NotificationInfo data-testid={TestIds.NotificationInfo}
                                                          ref={ref}>
                                            {this.props.info}
                                        </NotificationInfo>
                                )}
                            </Tooltip>
                            <Tooltip content={this.props.description}
                                     onlyShowWhenChildrenOverflowing>
                                {(ref) => (
                                        <NotificationDescription data-testid={TestIds.NotificationDescription}
                                                                 ref={ref}>
                                            {this.props.description}
                                        </NotificationDescription>
                                )}
                            </Tooltip>
                        </NotificationContentWrapper>
                        <NotificationTime>
                            {this.getDateTime()}
                        </NotificationTime>
                    </StyledNotification>
                </NotificationWrapper>
        );
    }
}

export default withTranslation(["Components"])(Notification);