import { withTranslation, WithTranslation } from "react-i18next";
import React from "react";
import SettingsMenu from "./SettingsMenu";
import { MenuIconWrapper, ShellIconsGroup, StyledShell, StyledShellMenuIconTemplate } from "./Main.style";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
    AddIcon,
    HomeIcon,
    HotSpotsIcon,
    IProps as IIconProps,
    NotificationIcon,
    SearchIcon,
    SettingsIcon,
    UserIcon
} from "../../components/icon";
import { IExtendedShellPropsForExtend } from "./ExtendedShell";
import { AppContext, AppMode, ContextEvents, IAppContext } from "../../contexts/appContext/AppContext.types";
import { ROUTE_HOME, ROUTE_USER_SETTING } from "../../routes";
import TestIds from "../../testIds";
import { withPageLoader, WithPageLoader } from "@components/pageLoader/pageLoaderContext";
import { IconSize } from "../../enums";
import { WithPermissionContext, withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { CompanyPermissionCode, GeneralPermissionCode } from "@odata/GeneratedEnums";
import GlobalSearch from "./GlobalSearch";
import { ExtendedShellOverlay } from "./ExtendedShell.style";
import { WithHotspots, withHotspots } from "@components/hotspots/withHotspots";
import AddMenu from "./AddMenu";
import { ExtendedShellContent, SHELL_NOTIFICATION_ID } from "./Shell.types";
import DashboardCustomization from "../../pages/home/DashboardCustomization";
import { NotificationsCount } from "../../notifications/Notifications.styles";
import { WithNotifications, withNotifications } from "../../notifications/withNotifications";
import SmartNotificationsExtendedShell from "../../notifications/SmartNotificationsExtendedShell";
import { isInCustomerPortal } from "@pages/home/Home.utils";
import AppsList from "./AppsList";
import { getBadgeCount } from "@components/badge/Badge.utils";
import { addCompanyIdToUrl } from "../../contexts/appContext/AppContext.utils";
import { KeyName } from "../../keyName";

interface IShellMenuIconTemplateProps {
    id?: string;
    link?: string;
    title?: string;
    Icon: React.ComponentType<IIconProps>;
    onClick?: () => void;
    isDisabled?: boolean;
    isClickDisabled?: boolean;
    isHidden?: boolean;
    isSelected?: boolean;
    circleSelection?: boolean;
    customContent?: React.ReactNode;
}

const ShellMenuIconTemplate: React.FC<IShellMenuIconTemplateProps> = (props) => {
    const {
        id,
        link,
        title,
        Icon,
        onClick,
        isDisabled,
        isClickDisabled,
        isHidden,
        isSelected,
        circleSelection
    } = props;

    if (isHidden) {
        return null;
    }

    return (
        <StyledShellMenuIconTemplate>
            <MenuIconWrapper onClick={isDisabled || isClickDisabled ? undefined : onClick}
                             hotspotId={id}
                             id={id}
                             title={title}
                             link={isClickDisabled ? undefined : link}
                             isDisabled={isDisabled}
                             isDecorative
                             isLight
                             ignoreTheme
                             $preventHover={isClickDisabled}
                             $isSelected={isSelected}
                             $isFullCircle={circleSelection}>
                <Icon style={{}} height={IconSize.L} width={IconSize.L} isLight ignoreTheme/>
            </MenuIconWrapper>
            {props.customContent}
        </StyledShellMenuIconTemplate>
    );
};

interface IState {
    isExtendedShellOpen?: boolean;
    shellContent?: ExtendedShellContent;
}

interface IProps extends WithTranslation, WithPageLoader, RouteComponentProps, WithPermissionContext, WithHotspots, WithNotifications {
}

class Shell extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    state: IState = {
        isExtendedShellOpen: false,
        shellContent: ExtendedShellContent.None,
    };

    get isCompanySelected() {
        return !!this.context.getCompanyId();
    }

    get isModalOpened() {
        const context = this.context as IAppContext;

        return context.isModalOpened();
    }

    get isDashboardSettingsOpened() {
        return this.state.shellContent === ExtendedShellContent.DashboardSettings && this.state.isExtendedShellOpen;
    }

    constructor(props: IProps, context: IAppContext) {
        super(props, context);
        context.eventEmitter.on(ContextEvents.OpenExtendedShell, this.showExtendedShell);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        if (this.props.location !== prevProps.location) {
            this.handleRouteChange();
        }

        if (this.state.isExtendedShellOpen && !prevState.isExtendedShellOpen) {
            this.startListeningToKeyDown();
        } else if (!this.state.isExtendedShellOpen && prevState.isExtendedShellOpen) {
            this.stopListeningToKeyDown();
        }
    }

    componentWillUnmount() {
        this.context.eventEmitter.off(ContextEvents.OpenExtendedShell, this.showExtendedShell);
        this.stopListeningToKeyDown();
    }

    startListeningToKeyDown = (): void => {
        document.addEventListener("keydown", this.handleKeyDown);
    };

    stopListeningToKeyDown = (): void => {
        document.removeEventListener("keydown", this.handleKeyDown);
    };

    handleKeyDown = (event: KeyboardEvent): void => {
        if (event.key === KeyName.Escape) {
            this.hideExtendedShell();
            event.stopPropagation();
        }
    };

    handleRouteChange = (): void => {
        this.hideExtendedShell();
    };

    handleShellIconClick = (content: ExtendedShellContent) => {
        if (this.state.shellContent === content && this.state.isExtendedShellOpen) {
            this.hideExtendedShell();
        } else if (content === ExtendedShellContent.Help) {
            this.props.hotspots.setOpen(!this.props.hotspots.isOpen);
        } else {
            this.showExtendedShell(content);
        }
    };

    handleTransitionEnd = () => {
        if (!this.state.isExtendedShellOpen) {
            // clean up, so extended shell is not rendered in the DOM and we can always test it same way
            this.setState({
                shellContent: ExtendedShellContent.None
            });
        }
    };

    showExtendedShell = (shellContent: ExtendedShellContent): void => {
        if (shellContent === null || shellContent === ExtendedShellContent.None) {
            this.hideExtendedShell();
        } else {
            this.setState({ shellContent }, () => {
                if (!this.state.isExtendedShellOpen) {
                    // set this prop on callback, so shell is rendered first and displayed afterwards, so open animation could be trigger
                    setTimeout(() => this.setState({ isExtendedShellOpen: true }), 0);
                }
            });
        }
    };

    hideExtendedShell = () => {
        if (this.state.isExtendedShellOpen) {
            this.setState({
                isExtendedShellOpen: false
            });

            this.context.eventEmitter.emit(ContextEvents.ExtendedShellHidden);
        }
    };

    renderExtendedShell() {
        let Component: React.ComponentType<IExtendedShellPropsForExtend>;

        switch (this.state.shellContent) {
            case ExtendedShellContent.Search:
                Component = GlobalSearch;
                break;
            case ExtendedShellContent.Help:
                return null;
            case ExtendedShellContent.Add:
                Component = AddMenu;
                break;
            case ExtendedShellContent.Applications:
                Component = AppsList;
                break;
            case ExtendedShellContent.DashboardSettings:
                Component = DashboardCustomization;
                break;
            case ExtendedShellContent.Settings:
                Component = SettingsMenu;
                break;
            case ExtendedShellContent.Notifications:
                Component = SmartNotificationsExtendedShell;
                break;
            default:
                return null;
        }

        return (
            <Component onHide={this.hideExtendedShell}
                       onTransitionEnd={this.handleTransitionEnd}
                       show={this.state.isExtendedShellOpen}/>
        );
    }

    isSelected(type: ExtendedShellContent) {
        return this.state.isExtendedShellOpen && this.state.shellContent === type;
    }

    isHotspotsSelected = (): boolean => {
        return this.props.hotspots.isOpen;
    };

    isUserSettingSelected = (): boolean => {
        return window.location.pathname === ROUTE_USER_SETTING
            && Object.values(ExtendedShellContent).every(shellContentType => !this.isSelected(shellContentType))
            && !this.isHotspotsSelected();
    };

    hasPermission = (...permissions: (CompanyPermissionCode | GeneralPermissionCode)[]) => {
        return permissions.some(p => this.props.permissionContext.companyPermissions.has(p as CompanyPermissionCode) || this.props.permissionContext.generalPermissions.has(p as GeneralPermissionCode));
    };

    getNotificationsCount = (): React.ReactNode => {
        const count = this.props.notifications.unreadNotificationsCount;
        const context = this.context as IAppContext;

        if (!count || context.hasLimitedAccess()) {
            return null;
        }

        return (
            <NotificationsCount data-testid={TestIds.NotificationsCount} $ignoreTheme={true}>
                {getBadgeCount(count)}
            </NotificationsCount>
        );
    };

    renderIconsGroups = () => {
        const context = this.context as IAppContext;
        const isOrganizationSettingsMode = context.getAppMode() === AppMode.OrganizationSettings;
        const hasLimitedAccess = context.hasLimitedAccess();
        const hasActiveCompany = !isOrganizationSettingsMode && !context.getAddingNewCompany() && this.isCompanySelected && !hasLimitedAccess;

        const _isInCustomerPortal = isInCustomerPortal();
        const companyId = context.getCompanyId();

        return (
            <>
                <ShellIconsGroup data-testid={TestIds.ShellIconsGroup}>
                    <ShellMenuIconTemplate id={"ShellHome"}
                                           isDisabled={hasLimitedAccess}
                                           isClickDisabled={this.isDashboardSettingsOpened}
                                           isSelected={this.props.history.location.pathname === ROUTE_HOME && [ExtendedShellContent.DashboardSettings, ExtendedShellContent.None].includes(this.state.shellContent)}
                                           title={this.props.t("Common:ShellBar.Home")}
                                           Icon={HomeIcon}
                                           link={addCompanyIdToUrl(ROUTE_HOME, companyId)}/>
                    {hasActiveCompany &&
                        <>
                            <ShellMenuIconTemplate id={"ShellSettings"}
                                                   isDisabled={this.isModalOpened || this.isDashboardSettingsOpened}
                                                   isHidden={_isInCustomerPortal || !this.hasPermission(CompanyPermissionCode.CustomerSettings, CompanyPermissionCode.PayrollSettings, CompanyPermissionCode.CanImport, CompanyPermissionCode.RecurringTask)}
                                                   isSelected={this.isSelected(ExtendedShellContent.Settings)}
                                                   onClick={this.handleShellIconClick.bind(this, ExtendedShellContent.Settings)}
                                                   title={this.props.t("Common:ShellBar.Settings")}
                                                   Icon={SettingsIcon}/>
                            <ShellMenuIconTemplate id={"ShellSearch"}
                                                   isDisabled={this.isModalOpened || this.isDashboardSettingsOpened}
                                                   isHidden={_isInCustomerPortal}
                                                   isSelected={this.isSelected(ExtendedShellContent.Search)}
                                                   onClick={this.handleShellIconClick.bind(this, ExtendedShellContent.Search)}
                                                   title={this.props.t("Common:ShellBar.Search")}
                                                   Icon={SearchIcon}/>
                            <ShellMenuIconTemplate id={"ShellAdd"}
                                                   isDisabled={this.isModalOpened || this.isDashboardSettingsOpened}
                                                   isHidden={_isInCustomerPortal || !this.hasPermission(
                                                       CompanyPermissionCode.InvoicesIssued,
                                                       CompanyPermissionCode.InvoicesReceived,
                                                       CompanyPermissionCode.InternalAndCorrectiveDocuments)}
                                                   isSelected={this.isSelected(ExtendedShellContent.Add)}
                                                   onClick={this.handleShellIconClick.bind(this, ExtendedShellContent.Add)}
                                                   title={this.props.t("Common:General.Add")}
                                                   Icon={AddIcon}/>
                        </>
                    }
                </ShellIconsGroup>
                <ShellIconsGroup data-testid={TestIds.ShellIconsGroup}>
                    <ShellMenuIconTemplate id={SHELL_NOTIFICATION_ID}
                                           isSelected={this.isSelected(ExtendedShellContent.Notifications)}
                                           isDisabled={this.isModalOpened || this.isDashboardSettingsOpened || hasLimitedAccess}
                                           onClick={this.handleShellIconClick.bind(this, ExtendedShellContent.Notifications)}
                                           title={this.props.t("Common:ShellBar.Notifications")}
                                           Icon={NotificationIcon}
                                           customContent={this.getNotificationsCount()}/>
                    <ShellMenuIconTemplate id={"ShellUser"}
                                           isSelected={this.isUserSettingSelected()}
                                           isDisabled={this.isModalOpened || this.isDashboardSettingsOpened || hasLimitedAccess}
                                           title={this.props.t("Common:ShellBar.User")}
                                           link={addCompanyIdToUrl(ROUTE_USER_SETTING, companyId)}
                                           Icon={UserIcon}/>
                    <ShellMenuIconTemplate isSelected={this.isHotspotsSelected()}
                                           onClick={this.handleShellIconClick.bind(this, ExtendedShellContent.Help)}
                                           title={this.props.t("Common:ShellBar.Help")}
                                           Icon={HotSpotsIcon}/>
                    {/*// not ready, hidden*/}
                    {/*<ShellMenuIconTemplate isSelected={this.isSelected(ExtendedShellContent.Applications)}*/}
                    {/*                       onClick={this.handleShellIconClick.bind(this, ExtendedShellContent.Applications)}*/}
                    {/*                       isHidden={!_isInCustomerPortal}*/}
                    {/*                       title={this.props.t("Common:ShellBar.Applications")}*/}
                    {/*                       Icon={AppsIcon}/>*/}
                </ShellIconsGroup>
            </>
        );
    };

    render() {
        return (
            <>
                <StyledShell data-testid={TestIds.Shellbar} isInactiveCompany={this.context.getAddingNewCompany()}>
                    {this.renderIconsGroups()}
                </StyledShell>
                {this.renderExtendedShell()}
                <ExtendedShellOverlay _show={this.state.isExtendedShellOpen}
                                      onClick={this.isDashboardSettingsOpened ? undefined : this.hideExtendedShell.bind(this, false)}/>
            </>
        );
    }
}

export default withNotifications(withHotspots(withTranslation(["Common"])(withPageLoader(withRouter(withPermissionContext(Shell))))));
