import React from "react";
import TableView, {
    IHandleCustomActionArgs,
    ITableViewBaseProps,
    ITableViewBaseState
} from "../../views/table/TableView";
import { SmartTable } from "../../components/smart/smartTable/SmartTable";
import { FileAction, RowAction, Status, ToolbarItemType } from "../../enums";
import { IRowProps } from "@components/table/Rows";
import { IRow, TId } from "@components/table";
import { IInboxFileEntity } from "@odata/GeneratedEntityTypes";
import { getFileContextMenuItems } from "@components/fileUploader/File.utils";
import { ICustomButtonDefArgs, TableButtonsAction, TableButtonsActionType } from "../../views/table/TableToolbar";
import { createCustomTableToolbarItem } from "../../views/table/TableView.utils";
import { getConfirmationActionText } from "../../views/table/TableView.render.utils";
import { IModifierKeys, TValue } from "../../global.types";
import { IToolbarItem } from "@components/toolbar";
import {
    approveInboxFiles,
    changeInboxFileApprovalStatus,
    createInboxFilesFromUploads,
    getApprovalStatus,
    getEntityTypeFileStripItems,
    getInboxFile,
    getInboxFileContextMenuItems,
    getSearchBoxToolbarItem,
    getStatusFromApprovalStatus,
    getStatusTooltipFromApprovalStatus,
    handleDeleteFile,
    handleRenameInboxFile,
    hasInboxApprovers,
    IInboxTableCustomData,
    INBOX_ACTIONS_GROUP_ID,
    InboxAction,
    InboxFilter,
    isRowWithFile,
    loadInboxFileDetail,
    SEARCH_ID
} from "./Inbox.utils";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import BindingContext, { createBindingContext } from "../../odata/BindingContext";
import { doesElementContainsElement } from "@utils/general";
import { GroupDividerSize, ISelectGroup, ISelectItem, SelectGroups } from "@components/inputs/select/BasicSelect";
import { ProgressBarStyled, TableWrapperStyled } from "./Inbox.styles";
import { ApprovalStatusTypeCode, GeneralPermissionCode, InboxEntityTypeCode } from "@odata/GeneratedEnums";
import { getAlertFromError } from "../../views/formView/Form.utils";
import { WithAlert, withAlert } from "@components/alert/withAlert";
import { AlertPosition, IAlertProps, IBaseAlertProps } from "@components/alert/Alert";
import { getRow, updateRowsArray } from "@components/smart/smartTable/SmartTable.utils";
import FileStorage from "../../utils/FileStorage";
import FileInfoDialog, { IFileInfoDialogDataProps } from "../../components/fileUploader/FileInfoDialog";
import FileRenameDialog from "../../components/fileUploader/FileRenameDialog";
import { WithBusyIndicator, withBusyIndicator } from "@components/busyIndicator/withBusyIndicator";
import { InboxFilterId } from "./InboxDef";
import { WithConfirmationDialog, withConfirmationDialog } from "@components/dialog/withConfirmationDialog";
import memoizeOne from "../../utils/memoizeOne";
import { AUDIT_TRAIL, ROUTE_INBOX, ROUTE_INBOX_CUSTOMER_APPROVAL } from "../../routes";
import { currentUserIsCustomer } from "../admin/users/Users.utils";
import i18next from "i18next";
import { SmartHeaderStyled } from "../../views/formView/FormView.styles";
import FileDropArea from "../../components/fileUploader/FileDropArea";
import BusyIndicator from "../../components/busyIndicator";
import FileUploadButton from "../../components/fileUploadButton/FileUploadButton";
import memoize from "../../utils/memoize";
import { WithPermissionContext, withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";

export const COMPLETE_ACTION_ID = "Complete";
export const APPROVE_ACTION_ID = "Approve";
export const UPLOAD_ACTION_ID = "UploadInboxFile";

export interface IInboxTableViewProps extends ITableViewBaseProps<IInboxTableCustomData>, WithAlert, WithBusyIndicator, WithConfirmationDialog, WithPermissionContext {
    selectedRows?: BindingContext[];

    onDragStart?: (selectedRows: BindingContext[], fileNames: string[], event: React.DragEvent) => void;
    onDragEnter?: () => void;
    onDragLeave?: () => void;
    onDrop?: (event: React.DragEvent) => void;
    onContextMenu?: (selectedRows: BindingContext[], item: ISelectItem) => void;
    onAttachFiles?: (inboxFiles: TId[]) => Promise<IAlertProps>;
    onAfterRemoveFiles?: (bcs: BindingContext[]) => void;
    onAfterUpdateFile?: () => void;
    isDragging?: boolean;
    // when used inside editableWindow dialog, actions should always be preselected
    inEditableWindow?: boolean;
    folderId?: string;

    supportedEntities: InboxEntityTypeCode[];
}

interface IState extends ITableViewBaseState {
    isDraggingSource?: boolean;
    fileInfoMetadata?: IFileInfoDialogDataProps;
    renameFile?: BindingContext;
    sendCount?: number;
    doneCount?: number;
    canApproveInboxFiles?: boolean;
}

const defaultState: IState = {
    isDraggingSource: false
};

class InboxTableView extends TableView<IInboxTableViewProps, IState> {
    _tableWrapperRef = React.createRef<HTMLDivElement>();
    _viewRef = React.createRef<HTMLDivElement>();

    constructor(props: IInboxTableViewProps) {
        super(props);

        this.handleCustomAction = this.handleCustomAction.bind(this);

        this.state = {
            ...this.state,
            ...defaultState
        };
    }

    isCustomerUser = memoizeOne(() => currentUserIsCustomer(this.props.storage.context),
        () => [this.props.storage.context]);

    get type(): InboxFilter {
        return this.props.storage.getCustomData().type;
    }

    get entityType(): InboxEntityTypeCode {
        return this.props.storage.getCustomData().entityType;
    }

    get isApproval(): boolean {
        return this.type === InboxFilter.ForApprove;
    }

    get isUnsorted(): boolean {
        return InboxFilter.Unsorted === this.type;
    }

    get shouldRenderBreadcrumbs(): boolean {
        return !this.props.inEditableWindow;
    }

    get isTableEmpty() {
        return !this.props.storage.tableAPI?.getState().rowCount;
    }

    get searchFilterString(): string {
        return this.props.storage.getAdditionalFieldData(this.filterBc, "parsedValue") as string;
    }

    get isFilteredBySearch() {
        return !!this.searchFilterString;
    }

    get completionButton(): IToolbarItem {
        const action = this.getTableAction();
        return createCustomTableToolbarItem({
            tableAction: action,
            id: COMPLETE_ACTION_ID,
            iconName: "AddTo",
            label: this.getConfirmTextLabel(COMPLETE_ACTION_ID),
            forceDisabled: this.isTableEmpty
        });
    }

    get showCompletionButton(): boolean {
        return !!this.props.onAttachFiles;
    }

    get approvalButton(): IToolbarItem {
        const action = this.getTableAction();
        return createCustomTableToolbarItem({
            tableAction: action,
            id: APPROVE_ACTION_ID,
            iconName: "Confirm",
            label: this.getConfirmTextLabel(APPROVE_ACTION_ID),
            forceDisabled: this.isTableEmpty || !this.isCustomerUser()
        });
    }

    get isDragging(): boolean {
        return this.state.isDraggingSource && this.props.isDragging;
    }

    get shouldHandleDragOver(): boolean {
        return this.props.onDrop && !this.state.isDraggingSource;
    }

    // InboxTableView is never draftView
    isDraftView = (): boolean => {
        return false;
    };

    componentDidMount() {
        super.componentDidMount();
        document.addEventListener("click", this.handleDocumentClick);

        this.loadInboxApprovers();

        this.setViewBreadcrumbs();
    }

    componentDidUpdate(prevProps: Readonly<IInboxTableViewProps>, prevState: Readonly<IState>) {
        if (prevProps.isDragging !== this.props.isDragging && !this.props.isDragging && this.state.isDraggingSource) {
            // dragging can be turned off from "outside"
            this.setState({ isDraggingSource: false });
        }

        if (prevProps.folderId !== this.props.folderId) {
            this.setTableAction(null);
        }

        this.setViewBreadcrumbs();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        document.removeEventListener("click", this.handleDocumentClick);
    }

    shouldComponentUpdate(nextProps: IInboxTableViewProps, nextState: IState): boolean {
        return this.props.isDragging !== nextProps.isDragging
            || this.state.fileInfoMetadata !== nextState.fileInfoMetadata
            || this.state.renameFile !== nextState.renameFile
            || this.state.sendCount !== nextState.sendCount
            || this.state.doneCount !== nextState.doneCount
            || this.props.folderId !== nextProps.folderId
            || this.props.selectedRows !== nextProps.selectedRows;
    }

    setViewBreadcrumbs(): void {
        const key = `Inbox_${this.type}`;
        let link: string;
        let title: string;

        switch (this.type) {
            case InboxFilter.Other:
                title = "Inbox:DotsLabel";
                link = InboxFilter.Other;
                break;
            case InboxFilter.Entity:
                link = this.entityType;
                title = `Inbox:Folder.${link}`;
                break;
            case InboxFilter.Unsorted:
            case InboxFilter.ForApprove:
                // No view breadcrumbs
                break;
        }

        this.props.storage.context.setViewBreadcrumbs({
            items: title ? [{ key, title: i18next.t(title), link: `${ROUTE_INBOX}/${link}` }] : [],
            lockable: false
        });
    }

    loadInboxApprovers = async () => {
        const canApproveInboxFiles = await hasInboxApprovers(this.props.storage.oData);
        this.setState({ canApproveInboxFiles });
    }

    getToolbarButtons(): TableButtonsActionType[] {
        if (this.props.inEditableWindow) {
            return [];
        }
        const buttons = this.isApproval ? [APPROVE_ACTION_ID] : [TableButtonsAction.Remove];
        if (this.isUnsorted) {
            buttons.push(UPLOAD_ACTION_ID);
        }
        return buttons;
    }

    getConfirmTextLabel = (action: TableButtonsActionType) => {
        let key: string;
        switch (action) {
            case APPROVE_ACTION_ID:
                key = "Approve";
                break;
            case COMPLETE_ACTION_ID:
                key = this.type === InboxFilter.Entity ? "Complete" : "Attach";
                break;
        }
        return this.props.storage.t(`Inbox:TableView.${key}`).toString();
    };

    getConfirmText = (tableAction: TableButtonsAction | string): string | React.ReactElement => {
        if ([COMPLETE_ACTION_ID, APPROVE_ACTION_ID].includes(tableAction)) {
            const count = this.props.storage.tableAPI?.getState().activeRows.size ?? 0;
            return getConfirmationActionText(this.getConfirmTextLabel(tableAction), count);
        }

        return super.getConfirmText(tableAction);
    };

    getContextMenuGroups = memoizeOne((): ISelectGroup[] => {
        return [
            { id: SelectGroups.Default },
            { id: INBOX_ACTIONS_GROUP_ID, dividerSize: GroupDividerSize.Small },
            { id: SelectGroups.Action }
        ];
    });

    getContextMenuItems = () => {
        const contextMenuItems: ISelectItem[] = [];
        const { selectedRows, storage } = this.props;
        const isBasicInboxTableView = !this.isApproval && !this.props.inEditableWindow;

        if (this.getTableAction()) {
            // no context menu items when table action is active
            return contextMenuItems;
        }

        if (storage.tableAPI && selectedRows?.length <= 1) {
            const rowBc = selectedRows?.[0];
            let hasFileContents = true;
            let approvalStatus: ApprovalStatusTypeCode = null;
            if (rowBc) {
                const rowProps = getRow(storage.tableAPI.getState().rows, rowBc);
                hasFileContents = isRowWithFile(rowProps);
                approvalStatus = getApprovalStatus(getInboxFile(rowProps));
            }
            if (isBasicInboxTableView) {
                contextMenuItems.push(...getInboxFileContextMenuItems(false, approvalStatus, this.state.canApproveInboxFiles).map(item => ({
                    ...item,
                    groupId: INBOX_ACTIONS_GROUP_ID
                })));
            }
            contextMenuItems.push(...getFileContextMenuItems(false, hasFileContents).map(item => ({
                ...item,
                groupId: SelectGroups.Action
            })));
        }

        if (isBasicInboxTableView) {
            const allFolderItems = getEntityTypeFileStripItems(this.props.supportedEntities, false);

            let removeId: string;
            switch (this.type) {
                case InboxFilter.Other:
                    removeId = InboxFilter.Other;
                    break;
                case InboxFilter.Entity:
                    removeId = this.entityType;
                    break;
            }
            const folderItems = (removeId ? allFolderItems.filter(item => item.id !== removeId) : allFolderItems)
                    .map(item => ({ ...item, groupId: SelectGroups.Default }));
            contextMenuItems.push(...folderItems);
        }

        return contextMenuItems;
    };

    customToolbarContent = (): IToolbarItem[] => [
        getSearchBoxToolbarItem(this.searchFilterString),
        this.showCompletionButton && this.completionButton,
        this.isApproval && this.approvalButton
    ].filter(i => i);

    getCustomButtonDef = (action: TableButtonsActionType, args: ICustomButtonDefArgs): IToolbarItem => {
        if (UPLOAD_ACTION_ID === action) {
            return {
                id: UPLOAD_ACTION_ID,
                itemType: ToolbarItemType.Custom,
                render: () => (
                    <FileUploadButton onFileChange={this.handleNewFiles} multiple
                                      key={UPLOAD_ACTION_ID}
                                      isTransparent
                                      hotspotId={"inboxTableUploadButton"}/>
                )
            };
        }
        return null;
    };

    // InboxFileData loaded through the table config
    getInboxFileById(rowId: TId): IInboxFileEntity {
        const tableState = this.props.storage.tableAPI.getState();
        const row = getRow(tableState.rows, rowId);
        return row.customData.entity as IInboxFileEntity;
    }

    async processTableAction(action: (keys: string[]) => Promise<IBaseAlertProps>, withoutBusy?: boolean): Promise<void> {
        const { tableAPI } = this.props.storage;

        if (!withoutBusy) {
            this.props.setBusy(true);
            this.forceUpdate();
        }

        const affectedRows = await tableAPI.getAffectedRows();
        const keys = affectedRows.map(row => row.key);
        let alert: IAlertProps;
        try {
            alert = await action(keys);
        } catch (error) {
            alert = getAlertFromError(error);
        }

        this.props.setAlert(alert);
        this.props.setBusy(false);
        this.forceUpdate();

        if (alert?.status === Status.Success) {
            const bcs = affectedRows.map(row => row.bc);
            // when action is successfully processed, inbox files are removed from table
            this.props.onAfterRemoveFiles?.(bcs);
            tableAPI.reloadTable();
        }
    }

    approveInboxFiles = async (keys: string[]): Promise<IBaseAlertProps> => {
        const { storage } = this.props;
        if (await approveInboxFiles(storage.oData, keys)) {
            return {
                status: Status.Success,
                title: storage.t("Inbox:TableView.FilesApproved")
            };
        }
        return {
            status: Status.Error,
            title: storage.t("Common:General.Error")
        };
    };

    handleTableLoad(): void {
        super.handleTableLoad();
        // unset selected files
        this.setState({ ...defaultState });
        // todo: unselect rows, which are not loaded
        // this.props.onRowSelect?.(null);
    }

    handleDocumentClick = (e: MouseEvent) => {
        const inTable = doesElementContainsElement(this._tableWrapperRef.current, e.target as Element);
        const inView = doesElementContainsElement(this._viewRef.current, e.target as Element);
        if (inView && !inTable) {
            // todo: how to handle this - we most likely don't know when user wants to unselect all rows and
            //  when just e.g. searching for another, sorting, etc...
            // this.props.onRowSelect?.(null);
        }
    };

    handleToolbarConfirm = async (): Promise<void> => {
        let refreshNeeded;
        const { tableAPI } = this.props.storage;

        switch (this.getTableAction()) {
            case TableButtonsAction.Remove:
                const affectedRows = await tableAPI.getAffectedRows();
                refreshNeeded = await tableAPI.confirmAction();
                this.props.onAfterRemoveFiles?.(affectedRows.map(row => row.bc));
                break;
            case APPROVE_ACTION_ID:
                await this.processTableAction(this.approveInboxFiles);
                break;
            case COMPLETE_ACTION_ID:
                await this.processTableAction(this.props.onAttachFiles, true);
                break;
        }

        this.setTableAction(null);

        if (refreshNeeded) {
            this.props.onFormRefreshNeeded();
        }
    };

    handleCustomAction(action: string, { update = true, value }: IHandleCustomActionArgs = {}): void {
        if (action === SEARCH_ID) {
            this.handleSearch(action, value);

            return;
        }

        this.setTableAction(action, true, false);
        if (update) {
            this.forceUpdate();
        }
    }

    get filterBc() {
        return this.props.storage.data.bindingContext.navigate(InboxFilterId);
    }

    handleSearch = (id: string, value?: TValue): void => {
        const bindingContext = this.filterBc;
        const args: ISmartFieldChange = {
            bindingContext, value
        };

        // note: handleFilterChange is debounced. This might look strange now as we use submitted input but in the
        // future, submittedInput in toolbar should be changed to debounced input and we may want to remove the debounce
        // behaviour from handleFilterChange and move it to the inputs
        this.handleFilterChange(args);
    };

    handleRowSelect = (bindingContext: BindingContext, props: IRowProps, modifiers?: IModifierKeys): void => {
        if (props.isDisabled) {
            // We can select rows only because of dragging
            return;
        }
        this.props.onRowSelect?.(bindingContext, props, {}, { modifiers });
    };

    handleRowContextMenuSelection = (bindingContext: BindingContext, props: IRowProps, modifiers?: IModifierKeys): void => {
        const isSelected = this.props.selectedRows?.find(rowId => rowId.toString() === bindingContext.toString());
        if (!isSelected) {
            // row with the context menu is not selected -> unselect all other rows and
            // select this one to show correct context menu
            this.handleRowSelect(bindingContext, props, modifiers);
        }
    };

    handleContextMenu = (item: ISelectItem): void => {
        if ([FileAction.Download, FileAction.Info, FileAction.Delete, FileAction.Rename, InboxAction.CancelApproval, InboxAction.AskForApproval].includes(item.id as (FileAction | InboxAction))) {
            this.handleFileContextMenu(item, this.props.selectedRows[0]);
        } else {
            this.props.onContextMenu?.(this.props.selectedRows, item);
        }
    };

    handleFileContextMenu = async (item: ISelectItem, id: TId) => {
        const action = item.id as (FileAction | InboxAction);
        const bc = createBindingContext(id.toString(), this.props.storage.oData.metadata);
        const inboxFile = this.getInboxFileById(id);

        switch (action) {
            case FileAction.Download:
                const fileId = inboxFile.FileMetadata.Id;
                FileStorage.download(fileId);
                break;
            case FileAction.Info:
                const inboxFileDetail = await loadInboxFileDetail(bc, this.props.storage.oData);
                this.setState({
                    fileInfoMetadata: {
                        ...inboxFileDetail.FileMetadata,
                        Sender: inboxFileDetail.Sender
                    }
                });
                break;
            case FileAction.Delete:
                if (await handleDeleteFile(this.props.storage, this.props.confirmationDialog, bc)) {
                    this.props.onAfterRemoveFiles([bc]);
                }
                break;
            case FileAction.Rename:
                this.setState({ renameFile: bc });
                break;
            case InboxAction.AskForApproval:
            case InboxAction.CancelApproval:
                const status = action === InboxAction.AskForApproval ? ApprovalStatusTypeCode.Pending : ApprovalStatusTypeCode.Canceled;
                if (await changeInboxFileApprovalStatus(this.props.storage.oData, [inboxFile.Id?.toString()], status)) {
                    // refresh table
                    await this.props.storage.tableAPI.reloadRow(bc);
                    this.props.onAfterUpdateFile?.();
                }
                this.forceUpdate();
                break;

        }
    };

    handleDragStart = (rowId: TId, props: IRowProps, event: React.DragEvent<HTMLDivElement>): void => {
        const bindingContext = rowId as BindingContext;
        let selectedRows = [...this.props.selectedRows];
        let isContained = selectedRows.includes(bindingContext);
        if ((event.ctrlKey || event.metaKey) && !isContained) {
            selectedRows.push(bindingContext);
            isContained = true;
        }
        if (!isContained) {
            // if dragged row is not included in selected ones, use just the dragged row
            selectedRows = [bindingContext];
        }
        const rowsArray = this.props.storage.tableAPI.getRowsArray();
        const selRowData = rowsArray.filter(row => selectedRows.includes(row?.id as BindingContext));
        const fileNames = selRowData.map(row => (row.customData.entity as IInboxFileEntity).FileMetadata.Name);

        this.props.onDragStart?.(selectedRows, fileNames, event);
        this.setState({
            isDraggingSource: true
        });
    };

    handleDragOver = (event: React.DragEvent): void => {
        event.preventDefault();
    };

    isRowApproved = (row: IRowProps | IRow): boolean => {
        const status = getApprovalStatus(getInboxFile(row));
        return [ApprovalStatusTypeCode.Approved, ApprovalStatusTypeCode.NotNeeded, ApprovalStatusTypeCode.Canceled].includes(status);
    };

    isRowWithoutAction(rowId: TId, action: RowAction, row: IRow): boolean {
        if (action === RowAction.Custom) {
            const tableAction = this.getTableAction();
            if (tableAction === APPROVE_ACTION_ID) {
                return false;
            }
            return !((this.type === InboxFilter.Other || this.isRowApproved(row)) && isRowWithFile(row));
        }

        return super.isRowWithoutAction(rowId, action, row);
    }

    getSelectedRows = memoizeOne(() => {
        if (this.getTableAction()) {
            return [];
        }
        return this.props.selectedRows ?? [];
    }, () => [this.getTableAction(), this.props.selectedRows]);

    rowsFactory = (rows: IRow[]): IRow[] => {
        if (this.type !== InboxFilter.Entity) {
            return rows;
        }

        const action = this.getTableAction();
        const isAddToAccountingActive = action === COMPLETE_ACTION_ID;

        return updateRowsArray(rows, (row: IRow) => {
            const approvalStatus = getApprovalStatus(getInboxFile(row));
            const isDisabled = isAddToAccountingActive && approvalStatus === ApprovalStatusTypeCode.Pending;
            return {
                ...row,
                isDisabled,
                statusHighlight: getStatusFromApprovalStatus(approvalStatus),
                statusHighlightTooltip: getStatusTooltipFromApprovalStatus(approvalStatus)
                // tooltip: tooltip
            };
        });
    };

    handleInfoDialogClose = () => {
        this.setState({ fileInfoMetadata: null });
    };

    additionalInfoDialogFields = memoize(() => {
        return [
            { id: "Sender", label: this.props.storage.t("Inbox:TableView.Sender") }
        ];
    });

    renderInfoDialog() {
        return (
            <FileInfoDialog onClose={this.handleInfoDialogClose}
                            file={this.state.fileInfoMetadata}
                            additionalColumns={this.additionalInfoDialogFields()}/>
        );
    }

    handleRenameDialogConfirm = async (newName: string): Promise<void> => {
        await handleRenameInboxFile(this.props.storage, this.state.renameFile, newName);
        this.handleRenameDialogClose();
    };

    handleRenameDialogClose = () => {
        this.setState({ renameFile: null });
    };

    handleProgress = (current: number): void => {
        this.setState({ doneCount: current });
    };

    handleNewFiles = async (files: File[]) => {
        const { tableAPI, context, oData, t } = this.props.storage;

        try {
            // set busy
            this.setState({ sendCount: files.length, doneCount: 0 });
            // create inbox files
            await createInboxFilesFromUploads(context, oData, files, null, this.handleProgress);
            // unset busy
            this.setState({ sendCount: null, doneCount: null });
        } catch (e) {
            this.props.setAlert({
                status: Status.Error,
                title: t("Common:General.Error"),
                subTitle: t("Inbox:SendToAccountant.ErrorSend")
            });
            // unset busy
            this.setState({ sendCount: null, doneCount: null });
            return;
        }
        // show success message
        this.props.setAlert({
            status: Status.Success,
            title: t("Common:Validation.SuccessTitle"),
            subTitle: t("Inbox:SendToAccountant.SuccessSend")
        });

        await tableAPI.reloadTable();
    };

    renderRenameDialog() {
        const inboxFile = this.getInboxFileById(this.state.renameFile);
        return (
            <FileRenameDialog currentName={inboxFile.FileMetadata.Name}
                              onConfirm={this.handleRenameDialogConfirm}
                              onClose={this.handleRenameDialogClose}/>
        );
    }

    getViewProps() {
        const viewProps = {
            passRef: this._viewRef,
            nonScrollableContent: this.props.busyIndicator,
        };

        return this.shouldHandleDragOver ? {
            onDragEnter: this.props.onDragEnter,
            onDragLeave: this.props.onDragLeave,
            onDragOver: this.handleDragOver,
            onDrop: this.props.onDrop,
            ...viewProps
        } : { ...viewProps };
    }

    renderSmartHeader(): React.ReactNode {
        const { isUnsorted, isApproval } = this;

        if (isUnsorted || isApproval) {
            return (
                    <SmartHeaderStyled
                            storage={this.props.storage}
                            title={this.props.storage.data.definition.title}
                            hotspotId={"tableHeader"}
                            shouldHideVariant
                            icons={[{
                                id: "auditTrail",
                                label: this.props.storage.t("Audit:AuditTrail"),
                                iconName: "AuditTrail",
                                link: `${isApproval ? ROUTE_INBOX_CUSTOMER_APPROVAL : ROUTE_INBOX}/${AUDIT_TRAIL}`,
                                // on inbox approval page - customer doesn't need to have permission, it has it by default
                                isDisabled: isApproval ? false : !this.props.permissionContext?.generalPermissions?.has(GeneralPermissionCode.AuditTrail)
                            }]}/>
            );
        }
        return null;
    }

    renderFilterBar(): React.ReactElement {
        return null;
    }

    renderTable(): React.ReactElement {
        const tableProps = this.getTableSharedProps();
        const { sendCount, doneCount } = this.state;

        return (
                <TableWrapperStyled isDragging={this.isDragging}>
                    <FileDropArea onNewFiles={this.handleNewFiles}
                                  isReadOnly={!this.isUnsorted}
                                  showContent>
                        <SmartTable {...tableProps}
                                    contextMenuItems={this.getContextMenuItems()}
                                    contextMenuGroups={this.getContextMenuGroups()}
                                    onContextMenuSelection={this.handleContextMenu}
                                    onRowContextMenuSelection={this.handleRowContextMenuSelection}
                                    onDragStart={this.props.onDragStart && this.handleDragStart}
                                    isList
                                    tableWrapperRef={this._tableWrapperRef}
                                    considerInitialActiveRowsAsChanged/>
                    </FileDropArea>
                    {!!sendCount && (
                            <BusyIndicator customContent={(
                                    <ProgressBarStyled value={doneCount} parts={sendCount}
                                                       customDescription={this.props.storage.t("Inbox:SendToAccountant.SendingFiles")}/>
                            )}/>
                    )}
                </TableWrapperStyled>
        );
    }

    renderDefaultDialogs(): React.ReactElement {
        return (
            <>
                {super.renderDefaultDialogs()}
                {!!this.state.fileInfoMetadata && this.renderInfoDialog()}
                {!!this.state.renameFile && this.renderRenameDialog()}
                {this.props.alert}
            </>
        );
    }
}

export default withAlert({
    autoHide: true,
    position: AlertPosition.CenteredBottom
})(withBusyIndicator({
    passBusyIndicator: true,
    isDelayed: true
})(withConfirmationDialog(withPermissionContext(InboxTableView))));
