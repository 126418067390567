import BindingContext from "../../odata/BindingContext";
import { TFilterDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ReportStorage } from "./ReportStorage";
import { TReportColumnOverrides } from "./Report.utils";
import { TValue } from "../../global.types";
import { IFormatOptions } from "@odata/OData.utils";
import { ICellValueObject } from "@components/table";
import { getRouteByEntityType } from "@odata/EntityTypes";
import { getTableIntentLink } from "@components/drillDown/DrillDown.utils";

export const VatReportProps = {
    vatStatementPeriod: BindingContext.localContext("DateRange"),
    unlockedElectronicSubmissionDocuments: BindingContext.localContext("UnlockedElectronicSubmissionDocuments")
};

export const getUnlockedElectronicSubmissionDocumentsDef = (): Record<string, TFilterDef> => {
    return {
        [VatReportProps.unlockedElectronicSubmissionDocuments]: {
            // needs to be true for table storage to load info
            // set to false onAfterLoad
            isVisible: true,
            defaultValue: false
        }
    };
};

export const onAfterVatReportLoad = (storage: ReportStorage) => {
    // hide VatReportProps.unlockedElectronicSubmissionDocuments that is only visible to be loaded by table storage
    // but is not supposed to be seen (hidden parameter, only used for drilldown from VatSubmissionDetail)
    const info = storage.getInfo(storage.data.bindingContext.navigate(VatReportProps.unlockedElectronicSubmissionDocuments));

    info.isVisible = false;
    storage.refresh();
};

export const getVatReportColumnOverrides = (): TReportColumnOverrides => {
    return {
        Document_NumberOurs: {
            formatter: (val: TValue, args: IFormatOptions): ICellValueObject => {
                if (!val) {
                    return null;
                }

                const docId = args.entity["Document_DocumentId"] ?? args.entity["Document_Id"];
                const docType = args.entity["Document_DocumentTypeCode"] ?? args.entity["BusinessPartner_DocumentTypeCode"];
                const url = `${getRouteByEntityType(docType)}/${docId}`;

                return getTableIntentLink(val as string, {
                    route: url,
                    context: args.storage.context,
                    storage: args.storage
                });
            }
        }
    };
};