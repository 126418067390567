import { IDefinition, IGetDefinition } from "../../PageUtils";
import { getDefinitions as getStatementDefinition } from "./../bankStatements/BankStatementsDef";
import i18next from "i18next";
import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { BankTransactionEntity } from "@odata/GeneratedEntityTypes";
import { IChangedFilter } from "../../../views/table/TableView.utils";
import { ICreateFilterStringSettings } from "@odata/OData.utils";
import BindingContext from "../../../odata/BindingContext";
import { FieldType, ValueType } from "../../../enums";
import { getSimpleBoolSelectItems } from "@components/smart/FieldInfo";

export const BankTransactionLocksFilterPath = BindingContext.localContext("Locks");


export const getDefinitions: IGetDefinition = (context: IAppContext): IDefinition => {
    const statementsDef = getStatementDefinition(context, true);


    // mandatory default filter in pairing dialog
    statementsDef.table.filterBarDef[0].defaultFilters.push("BankAccount/IsActive");
    statementsDef.table.filterBarDef[0].filterDefinition["BankAccount/IsActive"] = {
        description: i18next.t("Banks:Statements.Account").toString()
    };


    statementsDef.table.filterBarDef[0].defaultFilters.push(BankTransactionLocksFilterPath);
    statementsDef.table.filterBarDef[0].filterDefinition[BankTransactionLocksFilterPath] = {
        type: FieldType.ComboBox,
        valueType: ValueType.Boolean,
        isValueHelp: false,
        label: i18next.t("Banks:Transactions.ShowLockedStatements").toString(),
        fieldSettings: {
            items: getSimpleBoolSelectItems()
        },
        filter: {
            buildFilter(item: IChangedFilter, settings: ICreateFilterStringSettings): string {
                const value = item.value as boolean;

                if (value) {
                    return "";
                }

                return `not(${BankTransactionEntity.Locks}/any())`;
            }
        },
        defaultValue: false
    };

    statementsDef.table.additionalProperties = [
        ...(statementsDef.table.additionalProperties ?? []),
        { id: "BankAccount/IsActive" }
    ];

    statementsDef.table.id = `${statementsDef.table.id}Pair`;
    statementsDef.form.id = `${statementsDef.form.id}Pair`;

    return statementsDef;
};

getDefinitions.translationFiles = ["Banks", "Document"];
