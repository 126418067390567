import React from "react";
import SmartField, { ISmartFieldChange, ISmartFieldProps } from "../smartField/SmartField";
import { BulkChangeFieldWrapper, CheckboxStyled } from "./BulkChange.styles";
import { ICheckboxChange } from "../../inputs/checkbox";
import { TRecordAny } from "../../../global.types";
import { BasicInputSizes, FieldType, LabelStatus, TextAlign } from "../../../enums";
import { ISelectionChangeArgs, ISelectItem } from "../../inputs/select/BasicSelect";
import {
    BulkChangeAction,
    BulkChangeActionPerFieldType,
    getBulkChangeActionTranslation,
    getDefaultBulkChangeAction,
    IBulkChangeDialogStorageCustomData
} from "./SmartBulkChange.utils";
import { Select } from "../../inputs/select";
import Field from "../../inputs/field";
import memoizeOne from "../../../utils/memoizeOne";
import { FormStorage } from "../../../views/formView/FormStorage";
import { IEntity } from "@odata/BindingContext";
import { IFieldInfo } from "@odata/FieldInfo.utils";

export default class SmartBulkChangeField extends React.Component<ISmartFieldProps> {

    getInfo = memoizeOne(() => {
        return this.props.storage.getInfo(this.props.bindingContext);
    });

    handleCheckboxChange = (e: ICheckboxChange): void => {
        const { bindingContext } = this.props;
        const storage = this.props.storage as FormStorage<IEntity, IBulkChangeDialogStorageCustomData>;

        if (e.value) {
            storage.setValue(bindingContext, null, undefined, true);
        } else {
            // Clears completely value from storage with all additionalFields
            storage.clearValue(bindingContext, false, true);
        }

        storage.setCustomData({
            isChecked: {
                ...storage.getCustomData().isChecked,
                [bindingContext.getNavigationPath()]: e.value
            }
        });
        storage.refresh(true);
    };

    handleFieldChange = (args: ISmartFieldChange): void => {
        this.props.storage.clearError(args.bindingContext);
        this.props.onChange(args);
        this.props.storage.refresh();
    };

    handleSelectChange = (args: ISelectionChangeArgs): void => {
        const { storage, bindingContext } = this.props;

        storage.clearError(bindingContext);
        storage.setAdditionalFieldData(bindingContext, "parsedValue", args.value);

        if (args.value === BulkChangeAction.RemoveAll || (args.value === BulkChangeAction.Remove && !bindingContext.isCollection())) {
            // cleanup all selected labels
            storage.setValue(bindingContext, null, undefined, true);
        }

        storage.refresh(true);
    };

    get isChecked(): boolean {
        const { bindingContext } = this.props;
        const storage = this.props.storage as FormStorage<IEntity, IBulkChangeDialogStorageCustomData>;

        return !!storage.getCustomData()?.isChecked?.[bindingContext.getNavigationPath()];
    }

    isDisabled = (): boolean => {
        if (!this.isChecked) {
            return true;
        }

        const action = this.props.storage.getAdditionalFieldData(this.props.bindingContext, "parsedValue") as BulkChangeAction;

        if (action === BulkChangeAction.RemoveAll) {
            return true;
        }

        if (action === BulkChangeAction.Remove && !this.props.bindingContext.isCollection()) {
            return true;
        }

        return false;
    };

    actionSelectItems = (): ISelectItem[] => {
        const actions = BulkChangeActionPerFieldType[this.getInfo().type];


        return actions.map((id) => ({
            id,
            label: getBulkChangeActionTranslation(id, this.props.bindingContext.isCollection(), this.props.storage.t)
        }));
    };

    renderExtraContent = (value: string): React.ReactElement => {
        return (
            <Field className={this.props.className}
                   style={this.props.style}
                   textAlign={TextAlign.Left}
                   type={FieldType.ComboBox}
                   isDisabled={!this.isChecked}
                   label={this.props.storage.t("Common:BulkChange.Action")}
                   width={BasicInputSizes.L}>
                <Select value={value}
                        onChange={this.handleSelectChange}
                        items={this.actionSelectItems()}
                        isDisabled={!this.isChecked}
                        width={BasicInputSizes.L}
                />
            </Field>
        );
    };

    renderField = (field: React.ReactNode, info: IFieldInfo, data: TRecordAny): React.ReactNode => {
        const { storage, bindingContext } = this.props;
        if (data.props.isDisabled) {
            return null;
        }

        let extra = null;
        let extraValue = null;
        const isCollection = bindingContext.isCollection();
        // for EditableText, we change manually fieldDef the render function
        // => don't use the altered info passed from SmartField to get the original type
        const originalInfo = this.getInfo();

        if (BulkChangeActionPerFieldType[originalInfo.type]) {
            const parsedValue = storage.getAdditionalFieldData(bindingContext, "parsedValue") as BulkChangeAction;
            extraValue = parsedValue ?? getDefaultBulkChangeAction(isCollection);
            extra = this.renderExtraContent(extraValue);
        }

        return (
            <BulkChangeFieldWrapper>
                <CheckboxStyled
                    _hasLabel={true}
                    checked={this.isChecked}
                    onChange={this.handleCheckboxChange}/>
                {extra}
                {field}
            </BulkChangeFieldWrapper>
        );
    };

    render() {
        // Local context fields are not intended to be used in bulk change (they are mostly dependent on custom form data)
        const { bindingContext } = this.props;
        if (bindingContext.isLocal()) {
            return null;
        }

        const { fieldProps, onChange, ...restProps } = this.props;

        let fieldDef: ISmartFieldProps["fieldDef"] = null;

        if (this.getInfo().type === FieldType.EditableText) {
            fieldDef = {
                labelStatus: LabelStatus.Visible,
                width: BasicInputSizes.XL
            };
        }

        return (
            <SmartField
                {...restProps}
                onChange={this.handleFieldChange}
                customRenderWrapper={this.renderField}
                fieldProps={{
                    ...fieldProps,
                    isDisabled: this.isDisabled()
                }}
                fieldDef={fieldDef}
            />
        );
    }
}