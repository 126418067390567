import { ISelectItem } from "../inputs/select/BasicSelect";
import { IconSize } from "../../enums";

export enum FileStripItemSize {
    XL,
    L,
    M,
    S
}

export interface IFileStripItem extends ISelectItem {
    id: string;
    count?: number;
    shortcut?: string;
    isRequired?: boolean;
}

export interface FileStripSizeConfig {
    padding: number;
    width: number;
    addHasIcon: boolean;
}

export const MAX_FILE_STRIP_CONFIG = {
    width: 180,
    padding: 16,
    addHasIcon: true
};

export function getFileStripConfig(windowWidth: number): FileStripSizeConfig {
    switch (true) {
        case windowWidth < 1180:
            return {
                width: 104,
                padding: 10,
                addHasIcon: false
            };
        case windowWidth < 1278:
            return {
                width: 120,
                padding: 10,
                addHasIcon: false
            };
        case windowWidth < 1640:
            return {
                width: 154,
                padding: 16,
                addHasIcon: true
            };
        default:
            return MAX_FILE_STRIP_CONFIG;
    }
}

interface IFileStripItemConfig {
    height: number,
    iconSize?: number,
    shortcutIconSize?: number,
    hasSmallBadge?: boolean,
    caretIconSize: number,
    margin: number
}

export function getFileStripItemConfig(size: FileStripItemSize): IFileStripItemConfig {
    switch (size) {
        case FileStripItemSize.S:
            return {
                height: 38,
                iconSize: 30,
                shortcutIconSize: 84,
                caretIconSize: IconSize.asNumber("XS"),
                hasSmallBadge: true,
                margin: 0
            };
        case FileStripItemSize.M:
            return {
                height: 60,
                caretIconSize: IconSize.asNumber("XS"),
                hasSmallBadge: true,
                margin: 12
            };
        case FileStripItemSize.L:
            return {
                height: 84,
                caretIconSize: IconSize.asNumber("S"),
                margin: 18
            };
        case FileStripItemSize.XL:
            return {
                height: 120,
                caretIconSize: IconSize.asNumber("M"),
                margin: 26
            };
    }
}

export function getFileStripSizeForSpace(horizontal: number, vertical: number): FileStripItemSize {
    let maxSize: number;
    // keep order to return largest possible
    [FileStripItemSize.S, FileStripItemSize.M, FileStripItemSize.L, FileStripItemSize.XL]
        .forEach((size) => {
            const { height, margin } = getFileStripItemConfig(size);
            if (!maxSize || (horizontal > height && vertical > height + margin)) {
                maxSize = size;
            }
        });
    return maxSize;
}

export function getBadgeOffsetTopByFileStripItemSize(size: FileStripItemSize): number {
    switch (size) {
        case FileStripItemSize.S:
            return 2;
        case FileStripItemSize.M:
            return 5;
        case FileStripItemSize.L:
            return 10;
        case FileStripItemSize.XL:
            return 21;
    }
}

export function getBadgeOffsetRightByFileStripItemSize(size: FileStripItemSize): number {
    switch (size) {
        case FileStripItemSize.S:
            return 114;
        case FileStripItemSize.M:
            return -5;
        case FileStripItemSize.L:
            return 10;
        case FileStripItemSize.XL:
            return 14;
    }
}