import { IReportStorageDefaultCustomData, ReportStorage } from "../ReportStorage";
import { EntitySetName, ICompanySettingEntity } from "@odata/GeneratedEntityTypes";
import { BankExchangeRateFreshnessCode } from "@odata/GeneratedEnums";
import { formatDateToDateString } from "@components/inputs/date/utils";
import { Status } from "../../../enums";
import DateType, { getUtcDate } from "../../../types/Date";
import { ITabsProps } from "@components/tabs";
import { REST_API_URL } from "../../../constants";
import customFetch from "../../../utils/customFetch";
import { toTuple } from "../../../global.types";
import { ExchangeRateProps } from "./ExchangeRateDef";

export const EXCHANGE_RATE_CONTROLLER_URL = `${REST_API_URL}/ExchangeRate`;
export const EXCHANGE_RATE_DATES_URL = `${EXCHANGE_RATE_CONTROLLER_URL}/GetExchangeRateDates`;
export const GET_EXCHANGE_RATE_URL = `${EXCHANGE_RATE_CONTROLLER_URL}/GetExchangeRate`;

export interface IExchangeRateCustomData extends IReportStorageDefaultCustomData {
    tabs?: Partial<ITabsProps>;
    selectedTab?: string;
}

export const fetchAndSetExchangeRateDays = async (storage: ReportStorage): Promise<void> => {
    const promises = toTuple([
        await storage.oData.getEntitySetWrapper(EntitySetName.CompanySettings).query()
            .select("BankExchangeRateFreshnessCode")
            .fetchData<ICompanySettingEntity[]>(),
        await customFetch(EXCHANGE_RATE_DATES_URL)
    ]);
    const [settingsRes, datesRes] = await Promise.all(promises);


    const bankExchangeRateFreshnessCode = settingsRes.value[0].BankExchangeRateFreshnessCode;
    const exchangeRateDates: { DateExchangeRate: string, IsBankExchangeRateForDate: boolean }[] = await datesRes.json();
    const customData: IExchangeRateCustomData = { tabs: {} };

    if (exchangeRateDates.length) {
        const newestDateObject = exchangeRateDates.shift();
        const newestDate = getUtcDate(newestDateObject.DateExchangeRate, true);
        const hasDataForNewestDate = newestDateObject.IsBankExchangeRateForDate;
        const showFuture = bankExchangeRateFreshnessCode === BankExchangeRateFreshnessCode.PreviousDay;

        const mainTabId = formatDateToDateString(newestDate);

        storage.settings = {
            ...storage.settings,
            [ExchangeRateProps.exchangeRateDate]: mainTabId
        };

        customData.tabs.data = [{
            id: mainTabId,
            hotspotId: "exchangeRateCurrentTab",
            title: storage.t("ExchangeRate:Current"),
            status: Status.Success
        }];
        customData.selectedTab = mainTabId;

        if (showFuture) {
            customData.tabs.data.push({
                id: "future",
                hotspotId: "exchangeRateFutureTab",
                title: storage.t("ExchangeRate:Future"),
                isDisabled: !hasDataForNewestDate,
                status: hasDataForNewestDate ? Status.Success : Status.Error
            });
        }

        customData.tabs.additionalData = exchangeRateDates.map((dateObject) => {
            const utcDate = getUtcDate(dateObject.DateExchangeRate, true);
            return {
                id: formatDateToDateString(utcDate),
                hotspotId: "exchangeRateHistoryTab",
                title: DateType.format(utcDate)
            };
        });

        storage.setCustomData(customData);
    }
};