import React from "react";
import { ButtonWrapper, DetailBody, ObjectContent, ObjectWrapper } from "./Alert.styles";
import { ScrollBar } from "../scrollBar";
import { ObjectExplorer } from "../objectExplorer";
import { Button, IconButton } from "../button";
import { CheckIcon, InfoIcon } from "../icon";
import { IconSize, Status } from "../../enums";
import PopperTooltip from "../popperTooltip/PopperTooltip";
import { composeRefHandlers } from "@utils/general";
import { WithHideOnBlur, withHideOnBlur } from "../../HOC/withHideOnBlur";
import i18next from "i18next";
import { TRecordAny } from "../../global.types";

interface IProps extends WithHideOnBlur {
    data: TRecordAny;
}

interface IState {
    isCopying: boolean;
}

class AlertDetail extends React.PureComponent<IProps, IState> {
    tooltipRef = React.createRef<HTMLDivElement>();
    buttonRef = React.createRef<HTMLDivElement>();

    _timeout: ReturnType<typeof setTimeout>;

    state = {
        isCopying: false
    };

    componentDidMount() {
        this.props.setHideOnBlurOptions([this.buttonRef, this.tooltipRef]);
    }

    showDetail = () => {
        this.props.setIsVisible(!this.props.isVisible);
    };

    handleCopyClick = () => {
        const text = JSON.stringify(this.props.data);
        navigator.clipboard.writeText(text);

        this.setState({
            isCopying: true
        });

        clearTimeout(this._timeout);
        this._timeout = setTimeout(() => {
            this.setState({ isCopying: false });
        }, 3000);
    };

    renderContent = () => {
        const Icon =  <CheckIcon/>;
        const text = this.state.isCopying ? i18next.t("Components:Alert.Copied") : i18next.t("Components:Alert.Copy");

        return (
            <ObjectWrapper>
                <DetailBody>
                    <ScrollBar style={{
                        overflowX: "hidden",
                        maxHeight: "235px"
                    }}>
                        <ObjectContent>
                            <ObjectExplorer data={this.props.data}/>
                        </ObjectContent>
                    </ScrollBar>
                </DetailBody>
                <ButtonWrapper isCopying={this.state.isCopying}>
                    <Button
                        isHover={this.state.isCopying}
                        onClick={this.handleCopyClick}
                        icon={Icon}
                        isTransparent
                        isLight>
                        {text}
                    </Button>
                </ButtonWrapper>
            </ObjectWrapper>
        );
    };

    render() {
        return (
            <PopperTooltip content={this.renderContent()}
                           isHidden={!this.props.isVisible}
                           placement={"bottom"}
                           passRef={this.tooltipRef}
                           offsetY={5}
                           style={{
                               maxHeight: "300px",
                           }}
            >
                {(ref) => {
                    return (
                        <IconButton
                            passRef={composeRefHandlers(this.buttonRef, ref)}
                            status={Status.Error}
                            onClick={this.showDetail} title={i18next.t("Components:Alert.ShowDetail")} isDecorative>
                            <InfoIcon width={IconSize.M}/>
                        </IconButton>
                    );
                }}
            </PopperTooltip>
        );
    }
}

export default withHideOnBlur(AlertDetail);