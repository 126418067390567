import React, { Component } from "react";
import { FormStorage } from "../../../views/formView/FormStorage";
import memoizeOne from "../../../utils/memoizeOne";
import {
    EntitySetName,
    IGeneralPermissionEntity,
    IGeneralRoleEntity,
    IGeneralRolePermissionEntity
} from "@odata/GeneratedEntityTypes";
import { IconSize } from "../../../enums";
import { PermissionsHeader, PermissionsOverviewGrid } from "./Users.styles";
import { getIcon } from "@components/icon";
import TestIds from "../../../testIds";

interface IProps {
    storage: FormStorage;
}

class PermissionsOverview extends Component<IProps> {
    allPermissions: IGeneralPermissionEntity[];

    getAllPermissions = memoizeOne(async (): Promise<IGeneralPermissionEntity[]> => {
        const permissions = await this.props.storage.oData.getEntitySetWrapper(EntitySetName.GeneralPermissions)
                .query()
                .fetchData();
        return permissions.value;
    });

    getRolePermissions = (generalRole: IGeneralRoleEntity): string[] => {
        return generalRole?.GeneralRolePermissions?.filter((p: IGeneralRolePermissionEntity) => p.IsEnabled)
            .map((p: IGeneralRolePermissionEntity) => p.PermissionCode);
    };

    componentDidMount = async (): Promise<void> => {
        this.allPermissions = await this.getAllPermissions();
        this.forceUpdate();
    };

    render() {
        const selectedCodes = this.getRolePermissions(this.props.storage.data.entity?.GeneralRoles?.[0]?.GeneralRole);

        if (!selectedCodes) {
            return null;
        }

        return <>
            <PermissionsHeader style={{
                marginTop: "10px",
                marginBottom: "3px"
            }}>{this.props.storage.t("Users:Permissions")}</PermissionsHeader>
            <PermissionsOverviewGrid data-testid={TestIds.PermissionsGrid}>
            {this.allPermissions?.map(permission => {
                const iconName = selectedCodes?.includes(permission.Code) ? "StatusYes" : "StatusNo";
                const Icon = getIcon(iconName);
                return <div data-testid={TestIds.PermissionComponent} key={permission.Code}>
                    <Icon width={IconSize.S}/>
                    <span data-testid={TestIds.Name}>{permission.Name}</span>
                </div>;
            })}
            </PermissionsOverviewGrid>
        </>;
    }
}

export default PermissionsOverview;