import React, { useCallback, useEffect, useRef, useState } from "react";
import TestIds from "../../testIds";
import { CustomDescription, Description, DropArea, Placeholder } from "./FileUploader.styles";
import { doesEventContainFiles, getDroppedFiles, getNativeEvent } from "./File.utils";
import { UploadIcon } from "../icon";
import { useTranslation } from "react-i18next";
import { IconSize } from "../../enums";
import CustomResizeObserver from "../customResizeObserver";

interface IProps {
    showContent?: boolean;
    additionalContent?: React.ReactNode;
    customDescription?: string;
    onNewFiles?: (files: File[]) => void;
    onDragEnter?: (event: DragEvent) => void;
    onDragLeave?: (event: DragEvent) => void;
    isReadOnly?: boolean;
    isLocalDropArea?: boolean;
}

/**
 * DropArea with upload button. Just wrapper without content, ready for reuse
 *
 * ToDo:
 *  a/ filter dropped files according to accept/multiple parameter, show error if wrong files has been dropped
 *  b/
 *
 * @param showContent
 * @param additionalContent
 * @param isReadOnly
 * @param isLocalDropArea
 * @param onDragLeave
 * @param onDragEnter
 * @param onNewFiles
 * @param customDescription
 * @param children
 * @constructor
 */
const FileDropArea: React.FC<IProps> = ({
                                            showContent,
                                            additionalContent,
                                            isReadOnly,
                                            isLocalDropArea,
                                            onDragLeave,
                                            onDragEnter,
                                            onNewFiles,
                                            customDescription,
                                            children
                                        }) => {

    const { t } = useTranslation(["Common", "Components"]);
    const [isFileOver, setFileIsOver] = useState<boolean>(false);

    const areaRef = useRef<HTMLDivElement>();
    const [areaHeight, setHeight] = useState<number>(0);

    const handleFilesChange = useCallback((files: File[]) => {
        setFileIsOver(false);
        onNewFiles?.(files);
    }, [onNewFiles]);

    const handleDragOver = useCallback((event: React.DragEvent | DragEvent) => {
        if (!doesEventContainFiles(event)) {
            return;
        }

        // otherwise drop is not triggered at all
        // https://stackoverflow.com/questions/50230048/react-ondrop-is-not-firing
        event.preventDefault();
    }, []);

    const handleDragEnter = useCallback((event: React.DragEvent | DragEvent) => {
        if (!doesEventContainFiles(event)) {
            return;
        }

        event.preventDefault();

        let dragEventContainsFiles = false;

        for (const type of event.dataTransfer.types) {
            if (type === "Files") {
                dragEventContainsFiles = true;
                break;
            }
        }

        if (dragEventContainsFiles) {
            setFileIsOver(true);

            onDragEnter?.(getNativeEvent(event));
        }
    }, [onDragEnter]);

    const handleDragLeave = useCallback((event: React.DragEvent | DragEvent) => {
        event.preventDefault();

        // different check for global listener needed, for some reason some input fields returns false for document.body.contains
        const isDragLeaveGlobal = !isLocalDropArea && !event.relatedTarget;
        const isDragLeaveLocal = isLocalDropArea && !(event.currentTarget as HTMLElement).contains(event.relatedTarget as HTMLElement);

        if (isFileOver && (isDragLeaveGlobal || isDragLeaveLocal)) {
            setFileIsOver(false);

            onDragLeave?.(getNativeEvent(event));
        }
    }, [isFileOver, isLocalDropArea, onDragLeave]);


    const handleDrop = useCallback(async (event: React.DragEvent | DragEvent) => {
        if (!doesEventContainFiles(event)) {
            return;
        }

        event.preventDefault();

        const files = await getDroppedFiles(event);

        if (files.length > 0) {
            handleFilesChange(files);
        } else {
            setFileIsOver(false);
        }
    }, [handleFilesChange]);

    const handleResize = useCallback(() => {
        const height = areaRef.current?.offsetHeight;
        if (height !== areaHeight) {
            setHeight(height);
        }
    }, [areaHeight]);

    const shouldListenForLocalEvents = !isReadOnly && isLocalDropArea;

    useEffect(() => {
        const shouldListenForGlobalEvents = !isReadOnly && !isLocalDropArea;

        if (shouldListenForGlobalEvents) {
            document.body.addEventListener("dragover", handleDragOver);
            document.body.addEventListener("dragenter", handleDragEnter);
            document.body.addEventListener("dragleave", handleDragLeave);
            document.body.addEventListener("drop", handleDrop);

            return () => {
                // remove listeners on unmount
                document.body.removeEventListener("dragover", handleDragOver);
                document.body.removeEventListener("dragenter", handleDragEnter);
                document.body.removeEventListener("dragleave", handleDragLeave);
                document.body.removeEventListener("drop", handleDrop);
            };
        }

        handleResize();

        // no removal callback
        return null;
    }, [handleDragEnter, handleDragLeave, handleDragOver, handleDrop, isLocalDropArea, isReadOnly]);

    return (
        <DropArea isFileOver={isFileOver}
                  ref={areaRef}
                  hasFiles={showContent}
                  disableBorders={isReadOnly}
                  onDragOver={shouldListenForLocalEvents ? handleDragOver : null}
                  onDragEnter={shouldListenForLocalEvents ? handleDragEnter : null}
                  onDragLeave={shouldListenForLocalEvents ? handleDragLeave : null}
                  onDrop={shouldListenForLocalEvents ? handleDrop : null}
                  data-testid={TestIds.DropArea}
        >
            {showContent ? children : (
                <Placeholder>
                    {!isReadOnly && areaHeight ? (
                        <>
                            <UploadIcon preventHover color={isFileOver ? null : "C_ACT_thick_line"}
                                        width={IconSize.XL} height={IconSize.XL}/>
                            <Description areaHeight={areaHeight} data-testid={TestIds.Description}>
                                {t("Components:FileUploader.DragHere")}
                            </Description>
                            {customDescription &&
                                <CustomDescription>
                                    {customDescription}
                                </CustomDescription>
                            }
                        </>
                    ) : null}
                    {isReadOnly &&
                            <Description areaHeight={0}>
                            {t("Components:FileUploader.NoFiles")}
                        </Description>
                    }
                </Placeholder>
            )}
            {additionalContent}
            <CustomResizeObserver onResize={handleResize}/>
        </DropArea>
    );
};

export default FileDropArea;