import { logger } from "@utils/log";
import { handleRedirectResponse } from "@utils/RedirectUtils";

import { SessionType } from "../contexts/authContext/Auth.utils";
import { ROUTE_LOGIN } from "../routes";
import { ILoginOptions, ISessionTenantData, switchToTenant } from "./Login.utils";
import { handleErrorResponse } from "./state/loginSlice";
import { ISessionData, setSessionData } from "./state/sessionSlice";
import store from "./state/store";

export async function handleTenantSwitch(session: ISessionData, sessionParams: ISessionTenantData, setRedirect: (url: string) => void, option?: ILoginOptions): Promise<boolean> {
    // todo: setup customer/user session according to selected option
    try {
        const response = await switchToTenant(sessionParams);
        if (response.ok) {
            // update sessionData so that Redirects know what to do,
            // instead of having to manually handle redirect here
            store.dispatch(setSessionData({
                ...session,
                Type: option?.isCustomer ? SessionType.Customer : SessionType.Application,
                TenantId: option?.TenantId ?? session.TenantId
            }));

            return true;
        } else if (response.status === 403) {
            await handleRedirectResponse(response);
        } else if (response.status === 401) {
            setRedirect(ROUTE_LOGIN);
        } else {
            store.dispatch(handleErrorResponse(response));
            logger.error("Failed to select tenant.");
        }
        return false;
    } catch (e) {
        logger.error("Failed to select tenant.", e);
        return false;
    }
}