import React from "react";
import { IInputOnBlurEvent, IInputOnChangeEvent, IValueInputComponentProps } from "../input";
import {
    FloatingText,
    Line,
    StyledWriteLine,
    Suffix,
    ValueInput,
    ValueWrapper,
    WRITE_LINE_INPUT_PADDING,
    WriteLineConfirmButtons,
    WriteLineIcon,
    WriteLineWrapper
} from "./WriteLine.style";
import { TextMeasure } from "../responsiveInput/ResponsiveInput.styles";
import { composeRefHandlers, doesElementContainsElement } from "@utils/general";
import { IconSize, TextAlign } from "../../../enums";
import { KeyName } from "../../../keyName";
import { renderReadOnlyText } from "../utils";
import TestIds from "../../../../src/testIds";
import { withErrorAndTooltip, WithErrorAndTooltip, WithErrorAndTooltipProps } from "../formError/WithErrorAndTooltip";
import { withFormatter } from "../input/WithFormatter";
import { IAuditTrailData } from "../../../model/Model";
import { RequiredMark } from "../field/Label.styles";
import { IProps as IIconProps } from "../../icon/Icon";
import { logger } from "@utils/log";
import KeyboardShortcutsManager, {
    KeyboardShortcut
} from "../../../utils/keyboardShortcutsManager/KeyboardShortcutsManager";

export interface IProps<Type = string> extends IValueInputComponentProps, WithErrorAndTooltip {
    value?: Type;
    maxLength?: number;
    placeholder?: string;
    isRequired?: boolean;
    /** WriteLine will extend, based on the content size, upto MAX_WIDTH_RESPONSIVE/props.maxWidth*/
    isExtending?: boolean;
    textAlign?: TextAlign;
    /** Always use colors from default theme */
    ignoreTheme?: boolean;
    passRef?: React.Ref<HTMLInputElement>;
    outerRef?: React.RefObject<HTMLDivElement>;
    onChange?: (args: IInputOnChangeEvent<Type>) => void;
    onBlur?: (args: IInputOnBlurEvent) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onEnterPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    floatingText?: string;
    onWheel?: (event: React.WheelEvent<HTMLElement>) => void;
    onConfirm?: () => void;
    onCancel?: () => void;
    description?: string;
    width?: string;
    minWidth?: string;
    maxWidth?: string;
    isReadOnly?: boolean;
    isConfirmable?: boolean;
    icon?: React.ComponentType<IIconProps>;
    onIconClick?: (e: React.MouseEvent) => void;
    iconTitle?: string;
    type?: string;
    id?: string;
    name?: string;
    // html attribute autocomplete, defaults to "off"
    autocomplete?: string;
    testid?: string;
    className?: string;
    style?: React.CSSProperties;

    auditTrailData?: IAuditTrailData;
}

interface IState {
    showButtons: boolean;
}

const MIN_WIDTH_FIXED = 19;
const MAX_WIDTH_FIXED = 240;
const MIN_WIDTH_EXTENDING = 240;
const MAX_WIDTH_EXTENDING = 600;

class WriteLine extends React.PureComponent<IProps, IState> {
    static defaultProps: Partial<IProps> = {
        autocomplete: "off"
    };

    state: IState = {
        showButtons: false
    };

    _inputRef = React.createRef<HTMLInputElement>();
    _textMeasure = React.createRef<HTMLSpanElement>();
    _floatingText = React.createRef<HTMLSpanElement>();

    _wasChangedSinceBlur = false;
    _buttonsRef = React.createRef<HTMLDivElement>();

    get minWidth(): string {
        return this.props.minWidth ?? (this.props.isExtending ? MIN_WIDTH_EXTENDING : MIN_WIDTH_FIXED) + "px";
    }

    get maxWidth(): string {
        return this.props.maxWidth ?? (this.props.isExtending ? MAX_WIDTH_EXTENDING : MAX_WIDTH_FIXED) + "px";
    }

    componentDidMount(): void {
        if (this.props.isRequired && !this.props.placeholder) {
            logger.error("WriteLine with isRequired is expects to have placeholder set as well");
        }

        this.adaptSize();
    }

    componentDidUpdate(prevProps: IProps): void {
        this.adaptSize();

        if (this.props.value !== prevProps.value && this._inputRef.current === document.activeElement) {
            this._wasChangedSinceBlur = true;
        }
    }

    adaptSize = (): void => {
        if ((!this.props.isExtending && !this.props.floatingText) || this.props.isReadOnly) {
            return;
        }

        // use computed width to get precise sub pixel value,
        // otherwise chrome kind of randomly changes rounding of that value and the measurement breaks
        let textMeasureWidth = Math.round(parseFloat(getComputedStyle(this._textMeasure.current).width));

        if (this.props.floatingText) {
            // if text is too long for input, we just prevent enlarge it
            const floatingTextWidth = this._floatingText.current?.offsetWidth;
            const inputWidth = this._inputRef.current?.offsetWidth;
            const maxWidth = inputWidth - floatingTextWidth;
            textMeasureWidth = Math.min(maxWidth, textMeasureWidth);

            this._floatingText.current.style.left = `${textMeasureWidth + 6}px`;
            this._inputRef.current.style.paddingRight = `${floatingTextWidth + WRITE_LINE_INPUT_PADDING}px`;
        }

        // +1 because sometimes the input has scrollWidth 1px bigger for some reason
        this._inputRef.current.style.width = `calc(${textMeasureWidth}px + ${2 * WRITE_LINE_INPUT_PADDING}px - 1px)`;
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value;

        if (this.props.isConfirmable && !this.state.showButtons) {
            this.setState({
                showButtons: true
            });
        }

        this.props.onChange({
            value,
            origEvent: event
        });
    };

    handleBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
        this.props.onBlur?.({
            origEvent: event,
            wasChanged: this._wasChangedSinceBlur
        });

        this._wasChangedSinceBlur = false;

        if (this.props.isConfirmable) {
            this.autoConfirmOnBlur(event);
        }
    };

    handleFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
        if (this.props.isConfirmable) {
            this.setState({
                showButtons: true
            });
        }

        this.props.onFocus?.(event);
    };

    handleButtonsBlur = (e: React.FocusEvent): void => {
        if (this.props.isConfirmable) {
            this.autoConfirmOnBlur(e);
        }
    };

    handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        this.props.onKeyDown?.(event);

        switch (true) {
            case event.key === KeyName.Enter:
                this.props.onEnterPress?.(event);

                if (!event.defaultPrevented && this.props.isConfirmable) {
                    this.handleConfirm();
                    event.preventDefault();
                }
                break;
            case event.key === KeyName.Escape:
                if (this.props.isConfirmable) {
                    this.handleCancel();
                    event.preventDefault();
                }
                break;
            case KeyboardShortcutsManager.isEventShortcut(event, KeyboardShortcut.ALT_S):
                if (this.props.isConfirmable) {
                    this.handleConfirm();
                }
                break;
        }
    };

    autoConfirmOnBlur(e: React.FocusEvent): void {
        const target = e.relatedTarget as HTMLDivElement;
        const contains = doesElementContainsElement(this._inputRef.current, target) || doesElementContainsElement(this._buttonsRef.current, target);
        if (!contains) {
            this.handleConfirm();
        }
    }

    handleConfirm = (): void => {
        this.setState({
            showButtons: false
        });
        this.props.onConfirm?.();
    };

    handleCancel = (): void => {
        this.setState({
            showButtons: false
        });
        this.props.onCancel?.();
    };

    renderWriteLine = (): React.ReactElement => {
        const styleProps = {
            isDisabled: this.props.isDisabled
        };
        const Icon = this.props.icon;

        return (
            <StyledWriteLine
                className={this.props.className}
                style={this.props.style}
                isExtending={this.props.isExtending}
                data-testid={TestIds.WriteLine}
                auditTrailType={this.props.auditTrailData?.type}
                {...styleProps}>
                <WriteLineWrapper
                    ref={this.props.outerRef}
                    _width={this.props.isExtending ? null : this.props.width}
                    _minWidth={this.minWidth}
                    _maxWidth={this.maxWidth}
                    isExtending={this.props.isExtending}>
                    {this.props.isRequired &&
                    <RequiredMark style={{ top: "2px", left: "2px" }}
                                  data-testid={TestIds.RequiredMark}/>}
                    <ValueWrapper>
                        <ValueInput
                                placeholder={this.props.placeholder}
                                id={this.props.id}
                                name={this.props.name}
                                autoComplete={this.props.autocomplete}
                                value={this.props.value}
                                type={this.props.type || "text"}
                                maxLength={this.props.maxLength}
                                textAlign={this.props.textAlign}
                                data-testid={this.props.testid}
                                ref={composeRefHandlers(this._inputRef, this.props.passRef)}
                                onFocus={this.handleFocus}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                onKeyDown={this.handleKeyDown}
                                onWheel={this.props.onWheel}
                                isLight={this.props.isLight}
                                readOnly={this.props.isReadOnly}
                                disabled={this.props.isDisabled}
                                // if there is an icon, input should be actually shorter, so if it has some background
                                // color, e.g. filled password by password manager, it won't be filled under the icon
                                marginRight={Icon ? IconSize.asNumber("M") : null}/>
                        {(this.props.isExtending || this.props.floatingText) &&
                        <TextMeasure ref={this._textMeasure}>
                            {this.props.value}
                        </TextMeasure>
                        }
                        {this.props.floatingText &&
                        <FloatingText ref={this._floatingText} data-testid={TestIds.FloatingText}>
                            {this.props.floatingText}
                        </FloatingText>
                        }
                        {this.props.description &&
                        <Suffix>{this.props.description}</Suffix>
                        }
                        {Icon &&
                            <WriteLineIcon onClick={this.props.onIconClick}
                                           title={this.props.iconTitle}
                                           isDecorative>
                            <Icon width={IconSize.M}/>
                        </WriteLineIcon>
                        }
                    </ValueWrapper>
                    <Line isLight={this.props.isLight}
                          ignoreTheme={this.props.ignoreTheme}
                          data-testid={TestIds.Line}/>

                    {this.props.errorAndTooltip}
                </WriteLineWrapper>
                {this.state.showButtons &&
                <WriteLineConfirmButtons isExtending={this.props.isExtending}
                                         passRef={this._buttonsRef}
                                         onCancel={this.handleCancel}
                                         onConfirm={this.handleConfirm}
                                         onBlur={this.handleButtonsBlur}/>
                }
            </StyledWriteLine>
        );
    };

    render(): React.ReactElement {
        return this.props.isReadOnly ? renderReadOnlyText(this) : this.renderWriteLine();
    }
}

const WriteLineWithErrorAndTooltip = withErrorAndTooltip(WriteLine, {
    renderErrorTextWithFocus: true,
    useErrorMinWidth: true
});

export { WriteLine as WriteLineComponentType, WriteLineWithErrorAndTooltip };

export default withFormatter<IProps & WithErrorAndTooltipProps, string>(WriteLineWithErrorAndTooltip);