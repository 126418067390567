import React from "react";
import RemainingTrialDialog from "../../components/remainingTrialDialog";
import { WithOData, withOData } from "@odata/withOData";
import { ISubscriptionEntity, ITenantEntity } from "@odata/GeneratedEntityTypes";
import { MainLocalSettings, VariantId } from "./Main.utils";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
    containsRoute,
    LIMITED_ACCESS_SUPPORTED_ROUTES,
    ROUTE_GOODBYE_SCREEN,
    ROUTE_LOGIN_TENANT,
    ROUTE_SUBSCRIPTIONS_INVOICING
} from "../../routes";
import { PaymentMethodCode, PurchaseStatusCode, SubscriptionTypeCode } from "@odata/GeneratedEnums";
import { AppContext, IAppContext } from "../../contexts/appContext/AppContext.types";
import PurchaseWizardDialog from "../../components/purchaseWizardDialog/PurchaseWizardDialog";
import {
    getRemainingGracePeriodDays,
    getRemainingTrialPeriodDays,
    isInTrial,
    isNotYetPayedForSubscription,
    setSubscriptionCheckerSeen
} from "@pages/admin/subscriptions/Subscriptions.utils";
import { getUtcDate, getUtcDayjs } from "../../types/Date";
import { isInDevtools } from "../../devtools/Devtools.utils";
import { isUserOwner } from "../../pages/admin/users/Users.utils";

interface IProps extends WithOData, RouteComponentProps {
}

interface IState {
    isOpened?: boolean;
    isPurchaseWizardOpened?: boolean;
    remainingDaysGracePeriod?: number;
}

// when user skips the notification, it will be shown again after X days
const Frequency = 1;
// when app is loaded, checks every X ms if the subscription is going to expire [1 Hour]
const CheckIntervalFrequency = 1000 * 60 * 60;

class SubscriptionChecker extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;
    _checkIntervalId: ReturnType<typeof setInterval>;

    state: IState = {
        isOpened: false
    };

    componentDidMount() {
        this.init();
        this._checkIntervalId = setInterval(() => this.init(), CheckIntervalFrequency);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
        this.redirectIfCanceled();
    }

    componentWillUnmount() {
        clearInterval(this._checkIntervalId);
    }

    get subscription(): ISubscriptionEntity {
        const { subscription } = (this.context as IAppContext).getData();

        return subscription;
    }

    get tenant(): ITenantEntity {
        const { tenant } = (this.context as IAppContext).getData();

        return tenant;
    }

    redirectIfCanceled = (): boolean => {
        const context = this.context as IAppContext;

        if (!this.subscription || !this.tenant) {
            return false;
        }

        // if the Subscription is ended (HasLimitedAccess),
        // redirect to GoodbyeScreen
        if ((context.hasLimitedAccess()) && !containsRoute(LIMITED_ACCESS_SUPPORTED_ROUTES, this.props.location.pathname)) {
            this.props.history.replace(ROUTE_GOODBYE_SCREEN);
            return true;
        }

        return false;
    };

    async init() {
        const context = this.context as IAppContext;

        try {
            // redirects to GoodByeScreen if the subscription is canceled
            if (this.redirectIfCanceled()) {
                return;
            }

            // only relevant for Owner, others are not supposed to see RemainingTrialDialog
            // https://solitea-cz.atlassian.net/browse/DEV-29439
            const isOwner = !!isUserOwner(context.getData()?.userSettings);

            if (!isOwner || !this.tenant || containsRoute(LIMITED_ACCESS_SUPPORTED_ROUTES, this.props.location.pathname)) {
                return;
            }

            const subscription = this.subscription;
            const isTrial = isInTrial(subscription);
            let isGracefulPeriod: boolean;
            let remainingDays: number;

            if (isTrial && subscription.PurchaseStatusCode === PurchaseStatusCode.NotPurchased) {
                // last 14 days of trial should work the same way as "grace period"
                const remainingTrialDays = getRemainingTrialPeriodDays(subscription);
                // could be some constant, but this is done purely on FE so...
                const GracePeriodLength = 14;

                isGracefulPeriod = remainingTrialDays <= GracePeriodLength;
                remainingDays = remainingTrialDays;
            } else {
                isGracefulPeriod = subscription.IsGracefulPeriod;
                remainingDays = getRemainingGracePeriodDays(subscription);
            }

            const { SubscriptionNotificationClosedAt } = MainLocalSettings.get(VariantId);
            const isInvoicingRoute = this.props.location.pathname.startsWith(ROUTE_SUBSCRIPTIONS_INVOICING);
            const isFreeSubscription = [SubscriptionTypeCode.Free].includes(subscription.SubscriptionTypeCode as SubscriptionTypeCode);
            const today = getUtcDayjs();

            const isOpened = (isGracefulPeriod && !isFreeSubscription && !isInvoicingRoute &&
                (!SubscriptionNotificationClosedAt || (today.diff(SubscriptionNotificationClosedAt, "days") >= Frequency) || remainingDays <= 0));

            this.setState({
                remainingDaysGracePeriod: remainingDays,
                isOpened: isOpened && !isInDevtools()
            });
        } catch (error) {
            // fall silently
        }
    }

    handleClose = (addSubscription: boolean) => {
        if (addSubscription) {
            // if not purchased (trial) => open purchase wizard dialog
            if (isNotYetPayedForSubscription(this.subscription)) {
                this.setState({
                    isPurchaseWizardOpened: true
                });
            } else {
                if (this.subscription.PaymentMethodCode === PaymentMethodCode.Card) {
                    setSubscriptionCheckerSeen(getUtcDate());
                    // redirect to Invoicing
                    this.props.history.replace(ROUTE_SUBSCRIPTIONS_INVOICING);
                    this.setState({ isOpened: false });
                    return;
                } else {
                    // Wire => shouldn't get there, the button should be hidden
                }
            }

        } else if ((this.context as IAppContext).hasLimitedAccess()) {
            // navigate to tenant login
            this.props.history.replace(ROUTE_LOGIN_TENANT);
        } else {
            // set the value per tenant,
            // so that the SubscriptionNotificationClosedAt works for each tenant/subscription independently
            // save that user closes the dialog to localStorage, so the dialog is displayed after "Frequency" days, not always
            setSubscriptionCheckerSeen(getUtcDate());
        }
        this.setState({ isOpened: false });
    };

    handlePurchaseWizardClose = async (finished: boolean): Promise<void> => {
        if (finished) {
            setSubscriptionCheckerSeen(getUtcDate());
        }

        this.setState({
            isPurchaseWizardOpened: false,
            isOpened: !finished
        });
    };

    render() {
        if (this.state.isPurchaseWizardOpened) {
            return (
                <PurchaseWizardDialog onClose={this.handlePurchaseWizardClose}/>
            );
        }

        if (!this.state.isOpened) {
            return null;
        }

        return (
            <RemainingTrialDialog remainingDays={this.state.remainingDaysGracePeriod} onClose={this.handleClose}/>
        );
    }
}

export default withRouter(withOData(SubscriptionChecker));

