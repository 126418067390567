import { IAlertProps } from "@components/alert/Alert";
import { WithBusyIndicator, withBusyIndicator } from "@components/busyIndicator/withBusyIndicator";
import { FIELD_VER_MARGIN, FIELD_VER_MARGIN_SMALL } from "@components/inputs/field/Field.styles";
import { SimpleTimelineItem } from "@components/simpleTimeline/SimpleTimeline";
import {
    SIMPLE_TIMELINE_POINT_MARGIN,
    SIMPLE_TIMELINE_POINT_SIZE,
    SimpleTimeLineStatus
} from "@components/simpleTimeline/SimpleTimeline.utils";
import { ifAny, IFieldDef, IGetValueArgs, TTemporalDialogSettings } from "@components/smart/FieldInfo";
import { ISmartFieldBlur, ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { getBoundValue } from "@odata/Data.utils";
import { IFieldInfo } from "@odata/FieldInfo.utils";
import { PrEntityValueSourceCode } from "@odata/GeneratedEnums";
import {
    cleanupHistoryValues,
    EntityValueSourcePropNameSuffix,
    getDefaultTemporalDateValidFrom,
    getDefaultTemporalDateValidTo,
    getGranularity,
    getGroupingProperties,
    getTimelineStatus,
    groupAndOptimizeRanges,
    ISmartTemporalPropertyDialogCustomData,
    TTemporal,
    ungroupAndOptimizeRanges
} from "@odata/TemporalUtils";
import { logger } from "@utils/log";
import _ from "lodash";
import React from "react";

import SmartFastEntryList, {
    ActionType,
    ICustomPreContentArgs,
    ISmartFastEntriesActionEvent
} from "../../components/smart/smartFastEntryList";
import { LabelStatus, Status, ValidationErrorType } from "../../enums";
import { IValidationError } from "../../model/Validator.types";
import BindingContext, { IEntity } from "../../odata/BindingContext";
import DateType, { DATE_MAX, getUtcDayjs } from "../../types/Date";
import memoizeOne from "../../utils/memoizeOne";
import { FormStorage } from "../../views/formView/FormStorage";
import ConfirmationButtons from "../../views/table/ConfirmationButtons";
import { DialogStyledTemporalPropertyDialog } from "./SmartTemporalPropertyDialog.styles";

interface IProps extends WithBusyIndicator {
    storage: FormStorage;
    bindingContext: BindingContext;
    temporalItemBindingContext: BindingContext;
    onChange?: (args: ISmartFieldChange) => void;
    onClose?: (current?: TTemporal) => void;
}

class SmartTemporalPropertyDialog extends React.Component<IProps> {
    oldTemporalBag: TTemporal[] = null;
    oldOrigTemporalBag: TTemporal[] = null;
    // DateValidFrom/To fields has shared definitions for whole property bag
    // SmartTemporalPropertyDialog changes the definition dynamically with correct configuration,
    // backups are used to restore original values when the dialog is closed
    dateValidFromFieldInfoBackup: IFieldInfo;
    dateValidToFieldInfoBackup: IFieldInfo;

    componentDidMount() {
        this.init()
            .then(() => this.props.setBusy(false));
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (!this.props.bindingContext.getRootParent().isSame(this.props.storage.data.bindingContext)) {
            // close dialog on url/row change
            this.close();
        }
    }

    componentWillUnmount() {
        const dateValidFromPath = this.props.temporalItemBindingContext.navigate(`${this.temporalPropertyBagPath}/DateValidFrom`).toString(true);
        const dateValidToPath = this.props.temporalItemBindingContext.navigate(`${this.temporalPropertyBagPath}/DateValidTo`).toString(true);

        this.props.storage.data.fieldsInfo[dateValidFromPath] = this.dateValidFromFieldInfoBackup;
        this.props.storage.data.fieldsInfo[dateValidToPath] = this.dateValidToFieldInfoBackup;

        this.clearItemsInfo(this.props);

        (this.props.storage as FormStorage<unknown, ISmartTemporalPropertyDialogCustomData>).setCustomData({
            openedDialogBindingContext: null
        });
    }

    async init(): Promise<void> {
        const { bindingContext, storage } = this.props;

        (storage as FormStorage<unknown, ISmartTemporalPropertyDialogCustomData>).setCustomData({
            openedDialogBindingContext: bindingContext
        });

        const dialogSettings = this.getDialogSettings();
        const groupingProperties = this.getGroupingProperties();
        this.oldTemporalBag = this.entity[this.temporalPropertyBagPath];
        this.oldOrigTemporalBag = this.origEntity[this.temporalPropertyBagPath];

        let newBag = groupAndOptimizeRanges(this.entity[this.temporalPropertyBagPath], groupingProperties);

        if (dialogSettings.extractHistory) {
            newBag = await dialogSettings.extractHistory(storage, this.entity, newBag);
        }

        if (!newBag?.length) {
            const emptyItem = BindingContext.createNewEntity(1, {
                DateValidFrom: getDefaultTemporalDateValidFrom(dialogSettings),
                DateValidTo: getDefaultTemporalDateValidTo(dialogSettings)
            }) as TTemporal<Record<string, unknown>>;
            newBag = [emptyItem];

            // remove #id, item will be given Id in the next few lines
            delete emptyItem[BindingContext.NEW_ENTITY_ID_PROP];
        }
        this.entity[this.temporalPropertyBagPath] = newBag.map((item, index) => ({
            ...item,
            // not saved items in the property bag can be without Id,
            // so we are not extracting it in groupTemporalRanges at all,
            // and instead we can just use indexes, this Id is not used anyway, it is just needed for SmartFastEntry list to work properly
            Id: index
        }));
        // we need to convert even origEntity.TemporalPropertyBag,
        // so that we can use it in callbacks, e.g. to find SimpleTimeLineStatus.History items
        this.origEntity[this.temporalPropertyBagPath] = groupAndOptimizeRanges(this.oldOrigTemporalBag, groupingProperties).map((item, index) => ({
            ...item,
            Id: index
        }));

        const dateValidFromFieldInfo = this.props.storage.getInfo(this.props.temporalItemBindingContext.navigate(`${this.temporalPropertyBagPath}/DateValidFrom`));
        const dateValidToFieldInfo = this.props.storage.getInfo(this.props.temporalItemBindingContext.navigate(`${this.temporalPropertyBagPath}/DateValidTo`));

        // backup field infos,
        // alter them just for the current use in the dialog
        // restore them when the dialog is closed
        this.dateValidFromFieldInfoBackup = _.cloneDeep(dateValidFromFieldInfo);
        this.dateValidToFieldInfoBackup = _.cloneDeep(dateValidToFieldInfo);

        const dateValidToIsDisabled = (args: IGetValueArgs) => {
            const items = this.getItems();
            const currentItemBc = args.bindingContext.getParent();
            const currentItemIndex = items.findIndex(item => currentItemBc.isSame(this.getBindingContext().addKey(item)));
            const isLast = items.length - 1 === currentItemIndex;

            if (dialogSettings?.cannotBeEnded) {
                if (isLast) {
                    return true;
                }
            }

            if (dialogSettings?.withoutGaps) {
                if (!isLast) {
                    return true;
                }
            }

            return false;
        };

        if (dateValidToFieldInfo.isDisabled) {
            dateValidToFieldInfo.isDisabled = ifAny(dateValidToFieldInfo.isDisabled, dateValidToIsDisabled);
        } else {
            dateValidToFieldInfo.isDisabled = dateValidToIsDisabled;
        }

        if (dialogSettings?.dateType) {
            dateValidFromFieldInfo.type = dialogSettings.dateType;
            dateValidToFieldInfo.type = dialogSettings.dateType;
        }

        if (dialogSettings?.isCalendarDateDisabledDateValidFrom) {
            if (!dateValidFromFieldInfo.fieldSettings) {
                dateValidFromFieldInfo.fieldSettings = {};
            }

            dateValidFromFieldInfo.fieldSettings.isDateDisabled = dialogSettings.isCalendarDateDisabledDateValidFrom;
        }

        if (dialogSettings?.isCalendarDateDisabledDateValidTo) {
            if (!dateValidToFieldInfo.fieldSettings) {
                dateValidToFieldInfo.fieldSettings = {};
            }

            dateValidToFieldInfo.fieldSettings.isDateDisabled = dialogSettings.isCalendarDateDisabledDateValidTo;
        }

        this.clearItemsInfo(this.props);
    }

    /** Usually, temporal properties are inside TemporalPropertyBag collection.
     * We have one case in Company, with collection that contains DateValidFrom/DateValidTo,
     * but has different name, because it was created before backend implemented temporal property concept.
     * Since BE guys doesn't seem to be keen on changing that, use custom path or get the collection from binding context instead of using hardcoded TemporalPropertyBag.*/
    getTemporalPropertyBagPath = memoizeOne((): string => {
        return this.props.bindingContext.getParent().getPath(true);
    }, () => [this.props.bindingContext.toString()]);

    get temporalPropertyBagPath(): string {
        return this.getTemporalPropertyBagPath();
    }

    getEntity = memoizeOne((): IEntity => {
        return getBoundValue({
            data: this.props.storage.data.entity,
            bindingContext: this.props.temporalItemBindingContext,
            dataBindingContext: this.props.storage.data.bindingContext
        });
    }, () => [this.props.storage.data.entity, this.props.temporalItemBindingContext]);

    getOrigEntity = memoizeOne((): IEntity => {
        return getBoundValue({
            data: this.props.storage.data.origEntity,
            bindingContext: this.props.temporalItemBindingContext,
            dataBindingContext: this.props.storage.data.bindingContext
        }) ?? {};
    }, () => [this.props.storage.data.origEntity, this.props.temporalItemBindingContext]);

    get entity(): IEntity {
        return this.getEntity();
    }

    get origEntity(): IEntity {
        return this.getOrigEntity();
    }

    clearItemsInfo = (props: IProps): void => {
        // clear field info for items, so that the changes done to it here, won't be transferred to another dialog
        // => the items (their SmartField) will be forced to copy the field info from the default one in each dialog
        props.storage.clearCollectionInfoByPath(`${this.props.temporalItemBindingContext.toString()}/${this.temporalPropertyBagPath}(`);
    };

    isItemRemovable = (args: IGetValueArgs): boolean => {
        // todo not sure how this should actually work
        // => keep line commented for now
        // return this.getItems().length > 1 && isTemporalLineItemRemovable(args);

        // this should support our only case (VatRegistered state in company form)
        const items = this.getItems();
        const itemIndex = items.findIndex(item => args.bindingContext.isSame(args.bindingContext.removeKey().addKey(item)));

        return itemIndex !== 0 && itemIndex === items.length - 1;
    };

    getGroupingProperties = memoizeOne((): string[] => {
        return getGroupingProperties(this.props.bindingContext, this.props.storage);
    });

    getColumns = memoizeOne((): IFieldDef[] => {
        const dialogSettings = this.getDialogSettings();

        return [
            ...(dialogSettings.columns ? dialogSettings.columns.map(col => ({ id: col })) : [{ id: this.props.bindingContext.getPath() }]),
            { id: "DateValidFrom" },
            { id: "DateValidTo" }
        ];
    }, () => [this.props.bindingContext]);

    close = (current?: TTemporal<Record<string, unknown>>): void => {
        this.clearAllErrors();

        this.props.onClose?.(current);
        this.props.storage.setCustomData({ temporalPropertyDialogBc: null });
        this.props.storage.refresh();
    };

    getBindingContext = memoizeOne(() => {
        return this.props.temporalItemBindingContext.navigate(this.temporalPropertyBagPath);
    });

    updateDateValidToBasedOnNextValidFrom = (dateValidToBc: BindingContext, nextDateValidFrom: Date): void => {
        const dialogSettings = this.getDialogSettings();
        const granularity = getGranularity(dialogSettings);
        const newPreviousItemDateValidTo = getUtcDayjs(nextDateValidFrom).subtract(1, granularity).toDate();

        if (DateType.isValid(newPreviousItemDateValidTo)) {
            this.props.storage.setValue(dateValidToBc, newPreviousItemDateValidTo);
        }
    };

    setItemDefaultValues = (item: TTemporal): void => {
        const items = this.getItems();
        const newItemBc = this.getBindingContext().addKey(item);
        const newItemIndex = items.length - 1;
        const previousItem = items[newItemIndex - 1];
        const dialogSettings = this.getDialogSettings();

        for (const column of this.getColumns()) {
            const colBc = newItemBc.navigate(column.id);

            if (column.id === "DateValidFrom") {
                this.props.storage.setValue(colBc, getDefaultTemporalDateValidFrom(dialogSettings, items));
            } else if (column.id === "DateValidTo") {
                this.props.storage.setValue(colBc, getDefaultTemporalDateValidTo(dialogSettings, items));
            } else {
                this.props.storage.setValue(colBc, previousItem[column.id]);
            }
        }

        const updatedNewItem = this.props.storage.getValue(newItemBc) as TTemporal;

        this.updateDateValidToBasedOnNextValidFrom(this.getBindingContext().addKey(previousItem).navigate("DateValidTo"), updatedNewItem.DateValidFrom);
    };

    validateDates = (): boolean => {
        const itemsBc = this.getBindingContext();
        const items = this.getItems();
        let anyError = false;

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            const itemBc = itemsBc.addKey(item);
            const dateValidFromBc = itemBc.navigate("DateValidFrom");
            const dateValidToBc = itemBc.navigate("DateValidTo");
            let dateFromHasError = false;
            let dateToHasError = false;

            // compare dates in the current item
            if (item.DateValidFrom && item.DateValidTo && getUtcDayjs(item.DateValidFrom).isSameOrAfter(item.DateValidTo, "date")) {
                dateFromHasError = true;
                dateToHasError = true;
                const error: IValidationError = {
                    message: this.props.storage.t("Components:TemporalProperty.ValidationDateFromBeforeDateTo"),
                    errorType: ValidationErrorType.Field
                };

                this.props.storage.setError(dateValidFromBc, error);
                this.props.storage.setError(dateValidToBc, error);
            }

            // compare with previous items
            if (i > 0) {
                const previousItem = items[i - 1];

                if (previousItem.DateValidTo && item.DateValidFrom && getUtcDayjs(item.DateValidFrom).isSameOrBefore(previousItem.DateValidTo, "date")) {
                    dateFromHasError = true;

                    this.props.storage.setError(dateValidFromBc, {
                        message: this.props.storage.t("Components:TemporalProperty.ValidationDateFromAfterPreviousDateTo"),
                        errorType: ValidationErrorType.Field
                    });
                }
            }

            if (!dateFromHasError) {
                this.props.storage.clearError(dateValidFromBc);
            } else {
                anyError = true;
            }

            if (!dateToHasError) {
                this.props.storage.clearError(dateValidToBc);
            } else {
                anyError = true;
            }
        }

        return !anyError;
    };

    clearAllErrors = (): void => {
        this.props.storage.clearCollectionAdditionalFieldDataByPath(this.getBindingContext().getFullPath());
    };

    handleDateValidChange = (args: ISmartFieldChange): void => {
        const path = args.bindingContext.getPath();

        if (!["DateValidFrom", "DateValidTo"].includes(path)) {
            return;
        }

        const dialogSettings = this.getDialogSettings();

        if (dialogSettings.withoutGaps && path === "DateValidFrom") {
            const items = this.getItems();
            const itemBc = args.bindingContext.getParent();
            const itemIndex = items.findIndex(item => itemBc.isSame(itemBc.removeKey().addKey(item)));
            const prevItem = items[itemIndex - 1];

            if (prevItem) {
                this.updateDateValidToBasedOnNextValidFrom(itemBc.removeKey().addKey(prevItem).navigate("DateValidTo"), args.value as Date);
            }
        }

        // when DateValidFrom/To is changed, we need to re-render whole SmartFastEntryList
        // to re-render SimpleTimeline (could be changed for two line items for SimpleTimeLineStatus.Future)
        this.props.storage.addActiveField(this.getBindingContext());
    };

    handleLineItemsChange = (args: ISmartFieldChange): void => {
        this.props.storage.handleLineItemsChange(args);

        this.props.onChange?.(args);
        this.handleDateValidChange(args);
        this.props.storage.refreshFields();
    };

    handleLineItemsBlur = async (args: ISmartFieldBlur): Promise<void> => {
        await this.props.storage.handleBlur(args);
        this.props.storage.refreshFields();
    };

    handleLineItemsAction = (args: ISmartFastEntriesActionEvent): void => {
        this.props.storage.handleLineItemsAction(args);

        if (args.actionType === ActionType.Add) {
            const [item] = args.affectedItems;
            this.setItemDefaultValues(item as TTemporal);
            this.validateAll();
        } else if (args.actionType === ActionType.Remove) {
            // last item of cannotBeEnded should always have infinity in DateValidTo
            const items = this.getItems();
            const lastItem = items[items.length - 1];

            if (lastItem.DateValidTo !== DATE_MAX) {
                this.props.storage.setValue(this.getBindingContext().addKey(lastItem).navigate("DateValidTo"), DATE_MAX);
            }

            this.validateAll();
        }

        this.props.storage.refresh();
    };

    /** Validates all fields inside the dialog,
     * returns true if ok, false if error */
    validateAll = async (): Promise<boolean> => {
        const items = this.getItems();
        const columns = this.getColumns();
        const promises: Promise<IValidationError>[] = [];

        for (const item of items) {
            const itemBc = this.getBindingContext().addKey(item);

            for (const column of columns) {
                promises.push(
                    this.props.storage.validateField(itemBc.navigate(column.id))
                );
            }
        }

        const res = await Promise.all(promises);

        this.props.storage.refreshFields();


        return res.every(error => !error);
    };

    handleConfirm = async (): Promise<void> => {
        const { storage, temporalItemBindingContext } = this.props;

        if (!(await this.validateAll())) {
            return;
        }

        try {
            const properties = this.getGroupingProperties();
            const cleanedValues = cleanupHistoryValues<TTemporal>(this.entity[this.temporalPropertyBagPath]);
            const ungrouped = ungroupAndOptimizeRanges(cleanedValues, this.oldTemporalBag, properties);

            const today = getUtcDayjs();
            const current = this.entity[this.temporalPropertyBagPath].find((bag: TTemporal) => today.isBetween(bag.DateValidFrom, bag.DateValidTo, "day", "[]"));

            // only apply CurrentTemporalPropertyBag if it exists in the entity
            // not the case for Company/VatStatuses
            if (temporalItemBindingContext.isValidNavigation("CurrentTemporalPropertyBag")) {
                const propertyBagBc = temporalItemBindingContext.navigate("CurrentTemporalPropertyBag");
                if (current) {
                    if (!this.entity.CurrentTemporalPropertyBag) {
                        this.entity.CurrentTemporalPropertyBag = {};
                    }
                    for (const prop of properties) {
                        this.entity.CurrentTemporalPropertyBag[prop] = current[prop];
                        const sourceCodePropName = `${prop}${EntityValueSourcePropNameSuffix}`;
                        if (propertyBagBc.isValidNavigation(sourceCodePropName)) {
                            this.entity.CurrentTemporalPropertyBag[sourceCodePropName] = current.SourceCode ?? PrEntityValueSourceCode.Entity;
                        }
                    }
                } else {
                    if (this.entity.CurrentTemporalPropertyBag) {
                        // only remove grouping properties,
                        // we don't want to affect other, unrelated properties that can have current value
                        for (const prop of properties) {
                            delete this.entity.CurrentTemporalPropertyBag[prop];
                        }
                    }

                }
            }

            this.entity[this.temporalPropertyBagPath] = ungrouped;
            this.origEntity[this.temporalPropertyBagPath] = this.oldOrigTemporalBag;
            this.close(current);
        } catch (e) {
            logger.error("error ranges ungrouping", e);
        }
    };

    handleCancel = (): void => {
        this.entity[this.temporalPropertyBagPath] = this.oldTemporalBag;
        this.origEntity[this.temporalPropertyBagPath] = this.oldOrigTemporalBag;
        this.close();
    };

    getDialogSettings = memoizeOne((): TTemporalDialogSettings => {
        const fieldInfo = this.props.storage.getInfo(this.props.bindingContext);

        return fieldInfo.fieldSettings.temporalDialog ?? {};
    }, () => []);

    getCustomPreContent = (args: ICustomPreContentArgs): React.ReactNode => {
        if (args.items?.length < 2) {
            return null;
        }

        const status = getTimelineStatus(args.item as TTemporal);
        const nextItem = args.items[args.index + 1] as TTemporal;
        let isNextFuture = false;

        if (nextItem) {
            isNextFuture = getTimelineStatus(nextItem) === SimpleTimeLineStatus.Future;
        }

        // some margins differ for when the fast entry items are wrapped and not wrapped (LabelStatus.Removed)
        const topOffset = args.labelStatus === LabelStatus.Removed ? 18 : 35;
        const bottomLineOverflow = args.labelStatus === LabelStatus.Removed ? FIELD_VER_MARGIN_SMALL : FIELD_VER_MARGIN;

        return (
            <div style={{ marginRight: `${SIMPLE_TIMELINE_POINT_MARGIN}px`, position: "relative" }}>
                <SimpleTimelineItem isFirst={args.index === 0}
                                    isLast={args.index === args.items.length - 1}
                                    status={status}
                                    isNextFuture={isNextFuture}
                                    topOffset={`${topOffset}px`}
                                    bottomLineOverflow={bottomLineOverflow}
                />
            </div>
        );
    };

    getAddDisabledAlert = (): IAlertProps => {
        return {
            isSmall: true,
            status: Status.Warning,
            title: this.props.storage.t("Components:TemporalProperty.WarningOnlyOnePlanPossible")
        };
    };

    getItems = (): TTemporal[] => {
        return this.entity[this.temporalPropertyBagPath] ?? [];
    };

    render() {
        const dialogSettings = this.getDialogSettings();
        const title = dialogSettings.dialogTitle;
        const items = this.getItems();
        const addDisabled = dialogSettings.onlyOneFuturePlan
            ? items.filter(item => getTimelineStatus(item) === SimpleTimeLineStatus.Future).length >= 1
            : false;
        const planChange = !(dialogSettings.withoutGaps && dialogSettings.cannotBeEnded);

        return (
            <DialogStyledTemporalPropertyDialog
                showTimeLine={items.length > 1}
                onConfirm={this.handleConfirm}
                onClose={this.handleCancel}
                busy={this.props.busy}
                title={title}
                footer={<ConfirmationButtons
                    confirmText={this.props.storage.t("Common:General.Save")}
                    onConfirm={this.handleConfirm}
                    onCancel={this.handleCancel}
                    useWrapper={false}
                />}>
                <SmartFastEntryList
                    bindingContext={this.getBindingContext()}
                    columns={this.getColumns()}
                    storage={this.props.storage as FormStorage}
                    onChange={this.handleLineItemsChange}
                    onAction={this.handleLineItemsAction}
                    useLabelWrapping={true}
                    labelsLeftMargin={items?.length > 1 ? SIMPLE_TIMELINE_POINT_SIZE + SIMPLE_TIMELINE_POINT_MARGIN : 0}
                    canAdd={true}
                    isAddDisabled={addDisabled}
                    customAddButtonText={this.props.storage.t(`Components:TemporalProperty.${planChange ? "PlanChange" : "AddChange"}`)}
                    addDisabledAlert={addDisabled ? this.getAddDisabledAlert() : null}
                    isCollapsible={false}
                    onBlur={this.handleLineItemsBlur}
                    canReorder={false}
                    isItemRemovable={this.isItemRemovable}
                    isItemCloneable={false}
                    isItemSelectable={false}
                    groupId={"temporalPropertyDialog"}
                    customPreContent={this.getCustomPreContent}
                    order={null}
                />
            </DialogStyledTemporalPropertyDialog>
        );
    }
}

export default withBusyIndicator({ defaultBusyState: true })(SmartTemporalPropertyDialog);