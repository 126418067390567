import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ISelectionChangeArgs } from "@components/inputs/select/BasicSelect";
import Dialog from "../../components/dialog/Dialog";
import ConfirmationButtons from "../../views/table/ConfirmationButtons";
import FieldsWrapper from "../../components/inputs/field/FieldsWrapper";
import Field from "../../components/inputs/field";
import { NumericInput } from "@components/inputs/numericInput/NumericInput";
import { BasicInputSizes, TextAlign } from "../../enums";
import NumberType from "../../types/Number";

interface IProps {
    initialRatio: number;
    onConfirm: (ratio: number) => void;
    onCancel: () => void;
}

const ClearingRatioDialog: React.FunctionComponent<IProps> = React.memo((props) => {
    const { initialRatio, onConfirm } = props;
    const { t, ready: tReady } = useTranslation(["ElectronicSubmission", "Common"]);

    const [ratio, setRatio] = useState<number>(initialRatio ?? 0);
    const [error, setError] = useState<string>(null);

    const handleChange = useCallback((e: ISelectionChangeArgs) => {
        setRatio(e.value as number);
        setError(null);
    }, []);

    const valid = ratio && NumberType.isValid(ratio) && ratio >= 0 && ratio <= 100;

    const handleBlur = useCallback(() => {
        if (!valid) {
            setError(t("ElectronicSubmission:VatSubmission.ValidationMessage"));
        }
    }, [t, valid]);

    const handleConfirm = useCallback(() => {
        onConfirm(ratio);
    }, [onConfirm, ratio]);

    return tReady && (
        <Dialog title={t("ElectronicSubmission:VatSubmission.ChangeDialogTitle")}
                onConfirm={handleConfirm}
                onClose={props.onCancel}
                footer={<ConfirmationButtons onConfirm={handleConfirm}
                                             onCancel={props.onCancel}
                                             useWrapper={false}
                                             isDisabled={!valid}/>}
        >
            <FieldsWrapper>
                <FieldsWrapper>
                    <Field label={t("ElectronicSubmission:VatSubmission.ClearingRatio")}
                           isRequired>
                        <NumericInput width={BasicInputSizes.M}
                                      error={error ? { message: error } : null}
                                      textAlign={TextAlign.Right}
                                      unit={"%"}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={ratio}/>
                    </Field>
                </FieldsWrapper>
            </FieldsWrapper>
        </Dialog>
    );
});

export default ClearingRatioDialog;