import React from "react";
import { SelectInputWrapper, StyledInput, StyledSearchIcon } from "./Select.styles";
import { IconSize } from "../../../enums";
import TestIds from "../../../testIds";

interface IProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export default class SelectInput extends React.Component<IProps> {
    _refInput = React.createRef<HTMLInputElement>();

    componentDidMount(): void {
        this._refInput.current.focus();
    }

    render() {
        return (
            <SelectInputWrapper>
                <StyledSearchIcon width={IconSize.S} isLight ignoreTheme preventHover/>
                <StyledInput
                    data-testid={TestIds.SelectSearchInput}
                    ref={this._refInput}
                    onBlur={this.props.onBlur}
                    onKeyDown={this.props.onKeyDown}
                    onChange={this.props.onChange}/>
            </SelectInputWrapper>
        );
    }
}