import { IGetDefinition } from "./PageUtils";
import { EntityTypeName } from "@odata/GeneratedEntityTypes";

const DefByEntityType: Partial<Record<EntityTypeName, IGetDefinition>> = {};

export const setDefByEntityType = (entityType: EntityTypeName, definition: IGetDefinition): void => {
    DefByEntityType[entityType] = definition;
};

// keep in standalone module to prevent circular dependency
export const getDefByEntityType = (entityType: EntityTypeName): IGetDefinition => {
    return DefByEntityType[entityType];
};

export const getAllEntityTypes = (): EntityTypeName[] => {
    return Object.values(EntityTypeName).filter(key => DefByEntityType[key]);
};

export const getAllDefinitions = (): IGetDefinition[] => {
    return Object.values(DefByEntityType);
};

// definitions for stuffs like forms in dialogs, that don't have variants
// ==> we don't want to test them for existing systemVariant,
// but we still want to verify the definition integrity
const DefsForTesting: Record<string, IGetDefinition> = {};

export const setDefForTesting = (name: string, definition: IGetDefinition): void => {
    DefsForTesting[name] = definition;
};

export const getDefsForTesting = () => {
    return DefsForTesting;
};