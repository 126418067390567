import { IGetValueArgs } from "@components/smart/FieldInfo";
import { IDefinition } from "../../PageUtils";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { withDisplayName } from "@components/smart/GeneralFieldDefinition";
import { ISplitPageTableDef, ITableDefTabData, SECONDARY_FILTERS_ALL } from "../../../views/table/TableView.utils";
import { getDocumentStatusColumnDefinition } from "../DocumentCommonDefs";
import { EntitySetName, EntityTypeName } from "@odata/GeneratedEntityTypes";
import { DocumentTypeCode } from "@odata/GeneratedEnums";
import i18next from "i18next";
import { Sort } from "../../../enums";
import { TSmartODataTableStorage } from "@components/smart/smartTable/SmartODataTableBase";
import { getDocumentStatusFilterDefinition } from "../DocumentDef";
import { DocumentStatusLocalPath, StatusEntities } from "../../reports/CommonDefs";
import { correctedDocAdditionalProperties } from "./CommonCorrectiveSharedUtils";

const getWithoutCorrectiveDocumentFilter = (args: IGetValueArgs) => {
    let filter = `CorrectiveDocument eq null`;
    // this is called from both form (for select) and DialogPage TableView (for pairing dialog)
    // => get the correct storage
    const storage = (args.storage as TSmartODataTableStorage).getCustomData().rootStorage ?? args.storage;
    const isNew = storage.data.bindingContext.isNew();

    if (!isNew) {
        filter = `(${filter} OR CorrectiveDocument/Id eq ${storage.data.entity.Id})`;
    }

    return filter;
};


export const getReceivedDocsWithoutCorrectionFilter = (args: IGetValueArgs): string => {
    return `${getWithoutCorrectiveDocumentFilter(args)} AND DocumentTypeCode in (${[`'${DocumentTypeCode.InvoiceReceived}'`, `'${DocumentTypeCode.OtherLiability}'`].join(",")})`;
};


export const getIssuedDocsWithoutCorrectionFilter = (args: IGetValueArgs): string => {
    return `${getWithoutCorrectiveDocumentFilter(args)} AND DocumentTypeCode in (${[`'${DocumentTypeCode.InvoiceIssued}'`, `'${DocumentTypeCode.OtherReceivable}'`].join(",")})`;
};

export const getDefinition = (isReceivableDocType?: boolean): IDefinition => {
    const filterBarDef: IFilterGroupDef[] = [{
        ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
        isValueHelp: true,
        defaultFilters: [
            "DocumentType",
            ...(isReceivableDocType ? ["NumberTheirs"] : []),
            "Amount",
            "BusinessPartner",
            DocumentStatusLocalPath
        ],
        filterDefinition: {
            ...withDisplayName("DocumentType"),
            ...(isReceivableDocType ? { NumberTheirs: {} } : {}),
            NumberOurs: {},
            Amount: {},
            ...withDisplayName("BusinessPartner"),
            ...getDocumentStatusFilterDefinition(StatusEntities),
            ...withDisplayName("TransactionCurrency"),
            "BusinessPartner/Street": {},
            "BusinessPartner/City": {},
            "BusinessPartner/PostalCode": {},
            "BusinessPartner/Country": {},
            "BusinessPartner/FirstName": {},
            "BusinessPartner/LastName": {},
            "BusinessPartner/Email": {},
            "BusinessPartner/PhoneNumber": {}

        }
    }];

    const tabs: ITableDefTabData[] = [{
        id: SECONDARY_FILTERS_ALL,
        title: i18next.t("Components:FilterBar.All")
    },
        {
            id: isReceivableDocType ? DocumentTypeCode.InvoiceReceived : DocumentTypeCode.InvoiceIssued,
            title: i18next.t(`Common:GlobalSearchGroups.${isReceivableDocType ? EntityTypeName.InvoiceReceived : EntityTypeName.InvoiceIssued}`)
        },
        {
            id: isReceivableDocType ? DocumentTypeCode.OtherLiability : DocumentTypeCode.OtherReceivable,
            title: i18next.t(`Common:GlobalSearchGroups.${isReceivableDocType ? EntityTypeName.OtherLiability : EntityTypeName.OtherReceivable}`)
        }
    ];

    const table: ISplitPageTableDef = {
        filterBarDef,
        preventStoreVariant: true,
        tabs,
        tabsSettings: {
            filterFieldName: "DocumentTypeCode"
        },
        id: "CorrectiveDocumentPairing",
        // todo get the definition from already existing document defs?
        columnDefinition: {
            NumberOurs: { id: "NumberOurs" },
            // commented lines are not included in PayingDocuments
            // DateAccountingTransaction: { id: "DateAccountingTransaction" },
            DateDue: { id: "DateDue" },
            // PaymentMethodCode: { id: "PaymentMethodCode" },
            SymbolVariable: { id: "SymbolVariable" },
            // SymbolSpecific: { id: "SymbolSpecific" },
            // SymbolConstant: { id: "SymbolConstant" },
            // "BankAccount/AccountNumber": {},
            // "BankAccount/BankCode": {},
            Amount: {},
            TransactionAmountDue: {},
            Note: {},
            ...getDocumentStatusColumnDefinition(),
            BusinessPartner: {
                id: "BusinessPartner",
                fieldSettings: {
                    displayName: "Name"
                }
            },
            "BusinessPartner/Street": {},
            "BusinessPartner/City": {},
            "BusinessPartner/PostalCode": {},
            "BusinessPartner/Country": {},
            "BusinessPartner/FirstName": {},
            "BusinessPartner/LastName": {},
            "BusinessPartner/Email": {},
            "BusinessPartner/PhoneNumber": {},
            // "AccountAssignmentSelection/AccountAssignment": {
            //     id: "AccountAssignmentSelection/AccountAssignment",
            //     fieldSettings: {
            //         displayName: "Name"
            //     }
            // },
            // ...getCommonTableColumnsDefs(),
            DocumentType: {
                fieldSettings: {
                    displayName: "Name"
                }
            },
            DateIssued: {},
            DateCreated: {},
            ...(isReceivableDocType ? { NumberTheirs: {} } : {})
        },
        columns: [
            "NumberOurs",
            DocumentStatusLocalPath,
            "DocumentType",
            ...(isReceivableDocType ? ["NumberTheirs"] : []),
            "BusinessPartner",
            "DateIssued",
            "DateDue",
            "Amount",
            "TransactionAmountDue",
            "SymbolVariable",
            "Note"
        ],
        filter: isReceivableDocType ? getReceivedDocsWithoutCorrectionFilter : getIssuedDocsWithoutCorrectionFilter,
        initialSortBy: [{ id: "DateCreated", sort: Sort.Desc }],
        additionalProperties: correctedDocAdditionalProperties
    };

    return {
        entitySet: EntitySetName.PayingDocuments,
        table
    };
};