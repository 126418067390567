import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import SendMessageDialog, { IDataBoxSettings, IEmailSettings } from "../../messages/SendMessageDialog";
import { TableStorage } from "../../../model/TableStorage";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { REST_API_URL } from "../../../constants";
import { PrintReportType } from "../Report.utils";
import { CommonReportProps } from "../CommonDefs";
import DateType, { getUtcDate } from "../../../types/Date";
import { getBalanceSheetExportUrl } from "./BalanceSheet";
import BindingContext from "../../../odata/BindingContext";
import { IBalanceSheetCustomData } from "./BalanceSheet.utils";
import { getDocumentExportFileName, IGetExportFileNameProps } from "../../documents/Document.utils";
import { formatDateToDateString } from "@components/inputs/date/utils";


interface IProps extends WithTranslation {
    storage: TableStorage<unknown, IBalanceSheetCustomData>;
    onAfterSend?: () => void;
}

class SendBalanceSheetDialog extends React.Component<IProps> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    get message() {
        const byDate = this.props.storage.data.entity[CommonReportProps.byDate];
        const company = this.context.getCompany();

        return (<>Vážená paní / Vážený pane,<br/><br/>
            v příloze Vám zasíláme rozvahu společnosti {company.LegalName} ke dni {DateType.format(getUtcDate(byDate))},
            prosím podívejte se.<br/><br/>
        </>);
    }

    emailSettings: IEmailSettings = {
        sendMessageUrl: `${REST_API_URL}/Dispatch/report/${PrintReportType.BalanceSheet}/EMAIL`
    };

    dataBoxSettings: IDataBoxSettings = {
        isReport: true,
        sendMessageUrl: `${REST_API_URL}/Dispatch/report/${PrintReportType.BalanceSheet}/DATABOX`
    };

    filename = "";

    onAfterOpen = async (): Promise<void> => {
        const company = this.context.getCompany();
        const { storage } = this.props;
        this.emailSettings = {
            ...this.emailSettings,
            defaultEmail: company?.CommunicationContact?.Email
        };

        this.dataBoxSettings = {
            ...this.dataBoxSettings,
            defaultId: company.FinancialAdministration?.DataBoxId
        };

        const isFull = storage.getCustomData().messageReportType === "full";
        const opts: IGetExportFileNameProps = {
            queryParams: {
                byDate: formatDateToDateString(storage.data.entity[CommonReportProps.byDate])
            },
            isFull: (!isFull).toString()
        };
        this.filename = await getDocumentExportFileName(this.context, "report", PrintReportType.BalanceSheet, opts);

        this.forceUpdate();
    }


    handleCloseDialog = () => {
        this.props.storage.setCustomData({ sendingMessageDialogOpen: false });
        this.forceUpdate();
    };

    render = () => {
        if (!this.props.tReady || !this.props.storage?.getCustomData().sendingMessageDialogOpen) {
            return null;
        }
        const company = this.context.getCompany();
        const fullReport = this.props.storage.getCustomData().messageReportType === "full";
        const byDate = this.props.storage.data.entity[CommonReportProps.byDate];
        const subject = `${company.LegalName}: ${fullReport ? this.props.t("DataBox:SendDialog.FullBalanceSheet") : this.props.t("DataBox:SendDialog.ShortBalanceSheet")} ${DateType.format(getUtcDate(byDate))}`;

        const additionalRequestData = {
            BasicView: !fullReport,
            ByDate: byDate
        };

        const fileSettings = [{
            previewUrl: getBalanceSheetExportUrl(this.props.storage, !fullReport),
            name: this.filename,
            fileId: BindingContext.NEW_ENTITY_ID_PROP as unknown as number,
            shouldHideCheckBox: true
        }];

        return (
            <SendMessageDialog
                title={this.props.t("DataBox:SendDialog.SendBalanceSheet")}
                message={this.message}
                addSignature={true}
                subject={subject}
                handleClose={this.handleCloseDialog}
                emailSettings={this.emailSettings}
                fileSettings={fileSettings}
                onAfterOpen={this.onAfterOpen}
                onAfterSend={this.props.onAfterSend}
                dataBoxSettings={this.dataBoxSettings}
                additionalRequestData={additionalRequestData}
            />
        );
    };
}

export default withTranslation(["DataBox"], {withRef: true})(SendBalanceSheetDialog);