import { IVariant, IVariants, loadVariants, Variant, VariantType } from "@components/variantSelector/VariantOdata";
import { IModelData, Model } from "./Model";
import LocalSettings, { ILocalSettings } from "../utils/LocalSettings";
import BindingContext, { IEntity } from "../odata/BindingContext";
import { TRecordAny } from "../global.types";
import { getCompanyAccountingCode, isVatRegisteredCompany } from "@utils/CompanyUtils";
import { VatStatusCode } from "@odata/GeneratedEnums";


export interface IStorageModelData<E, C extends TRecordAny = object> extends IModelData<E, C> {
    variants?: IVariants;
}

export abstract class StorageModel<E extends IEntity = IEntity, P extends IStorageModelData<E> = IStorageModelData<E>, C extends TRecordAny = object> extends Model<P, E, C> {
    _cachedVariant: IVariant;

    LOCAL_STORAGE_VARIANT_NAME = "";

    data: IStorageModelData<E>;

    abstract setLocalStorageVariant(variant: IVariant): void;

    abstract getLocalStorageVariant(): IVariant;

    abstract getVariant(): IVariant;

    get isReadOnly(): boolean {
        return false;
    }

    get isDisabled(): boolean {
        return false;
    }

    async changeVariant(variant: Variant): Promise<void> {
        this.clearLocalStorageVariant();
        this.data.variants.currentVariant = variant;
    }

    clearLocalStorageVariant(): void {
        this._cachedVariant = null;
        LocalSettings.remove(this.id, this.LOCAL_STORAGE_VARIANT_NAME as keyof ILocalSettings);
    }

    async loadVariants(variantType: VariantType, bindingContext: BindingContext): Promise<IVariants> {
        if (!this.data.variants) {
            const accounting = getCompanyAccountingCode(this.context);
            const vatStatus = isVatRegisteredCompany(this.context) ? VatStatusCode.VATRegistered : VatStatusCode.NotVATRegistered;
            // don't use direct assignment this.data.variants = await ....
            // as data can be recreated during waiting for loadVariants, direct assignment will not work
            const loadedVariants = await loadVariants(this.oData, {
                viewId: this.id,
                variantType,
                accounting, vatStatus
            }, bindingContext);
            this.data.variants = loadedVariants;
            this.data.variants.currentVariant = this.data.variants.defaultVariant;
        }

        return this.data.variants;
    }
}