import styled, { css } from "styled-components/macro";
import Checkbox from "../checkbox/Checkbox";
import { T_PLAIN_small_hig, T_TITLE_6 } from "../../../global.style";
import { InputWithErrorAndTooltip } from "../input";
import { ellipsis, transparentize } from "polished";
import { ZoomSmallIcon } from "../../icon";
import { MenuLineItem } from "./SelectMenuItem";
import { GroupDividerSize, IColumn } from "./BasicSelect";
import { TextAlign } from "../../../enums";
import { AuditTrailFieldType } from "../../smart/FieldInfo";
import { AuditTrailInputCss } from "../input/Input.styles";
import { StyledToken } from "../../token";
import Clickable from "../../clickable";
import { themes } from "../../../theme";


const SelectUnderline = css<{
    isSelected?: boolean;
    isLabel?: boolean;
}>`
    ${props => props.isSelected && `
     &:after{
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: ${props.theme.C_TEXT_dialogue};
        left: 0;                       
        bottom: ${props.isLabel ? "1px" : "4px"};
    }
    `};
`;

const TabularSelectedUnderline = css<{
    isSelected?: boolean;
}>`
    ${SelectUnderline};
    ${props => props.isSelected && `
     &:after{
        bottom: -4px;
    }
  `};
`;

export const DescriptionSeparator = " | ";

export const InputDescriptionText = styled.span`
    color: ${props => props.theme.C_ACT_thick_line}
`;

export const InputTabularAllText = styled.div<{
    auditTrailType?: AuditTrailFieldType;
    hasError?: boolean;
}>`
    ${props => props.hasError && css`
        position: relative;
        top: 10px;
    `};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    ${AuditTrailInputCss};
`;

export const ArrowCover = styled.div`
    position: absolute;
    height: 14px;
    width: 19px;
    overflow: hidden;
`;

export const ArrowTop = styled.div`
    position: absolute;
    width: 15px;
    height: 15px;
    left: 2px;
    background-color: #9e037f;
    transform: rotate(135deg);
    border-radius: 2px;
`;

export const StyledSelect = styled.div<{
    _height: string,
    _cursor: string
}>`
    position: relative;
    cursor: ${props => props._cursor};
    height: ${props => props._height ? props._height : ""};
    align-self: flex-start; // -> Select in read-only mode should be aligned same way as other inputs
`;

export const InputWrapper = styled.div<{
    _isItalic?: boolean;
}>`
    height: 100%;
    display: flex;
    font-style: ${props => props._isItalic ? "italic" : ""};
`;

export const ReadOnlyTabularData = styled.span<{ isLight?: boolean }>`
    font-size: 14px;
    opacity: 0.5;
    color: ${props => !props.isLight ? props.theme.C_TEXT_primary : props.theme.C_TEXT_dialogue};
    padding-top: 8px;
    margin-left: 4px;
    white-space: nowrap;
`;

export const StyledMenuWrapper = styled.div`
    outline: none;
`;

export const StyledMenu = styled.div`
    outline: none;
    border-radius: 3px;
    box-shadow: 0 0 12.7px 0.3px rgba(0, 0, 0, 0.15);
    background: ${props => props.theme.C_BG_popover};
    list-style: none;
    padding-left: 0;

    position: absolute;
    margin: 0;
    border-top: 0;
    cursor: default;
    z-index: 1000;

    &[data-popper-placement^="top"] {
        ${ArrowCover} {
            bottom: -13px;
        }

        ${ArrowTop} {
            top: -7px;
        }
    }

    &[data-popper-placement^="bottom"] {
        ${ArrowCover} {
            top: -13px;
        }

        ${ArrowTop} {
            top: 6px;
        }
    }
`;

export const SelectGroup = styled.div<{
    _isTabular?: boolean;
}>`
    width: 100%;
`;

export const SelectGroupContent = styled.div<{
    _isTabular?: boolean;
}>`
    display: ${props => props._isTabular ? "table" : "block"};
    width: 100%;
`;

export const BoldText = styled.span`
    font-weight: 900;
`;
export const StyledText = styled.span<{
    _hasColor?: boolean;
}>`
    display: block;
    font-size: 12px;
    position: relative;
    height: 100%;
    color: ${props => props._hasColor ? props.theme.C_TEXT_primary : props.theme.C_TEXT_dialogue};
    ${ellipsis()};

    ${props => props._hasColor && `
    text-transform: uppercase;
    font-weight: bold
    `}
`;

export const Description = styled.span`
    font-style: italic;
`;

export const ColSpanWrapper = styled.div<{
    isLast?: boolean;
    isFirst?: boolean;
    isSelected?: boolean;
    column: IColumn;
}>`
    width: ${props => props.isLast && props.column?.textAlign !== TextAlign.Right ? "fit-content" : "100%"};
    position: relative;
    padding-left: ${props => props.isFirst ? "" : props.column?.textAlign === TextAlign.Right ? "24px" : "12px"};
    padding-right: ${props => props.isLast ? "" : props.column?.textAlign === TextAlign.Right ? "12px" : "24px"};
    max-width: 100%;
    min-height: 17px;
    ${TabularSelectedUnderline};
`;
export const StyledLine = styled.div`
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.C_TEXT_dialogue};
    left: 0;
    bottom: -4px;
    z-index: 300;
`;

export const ItemWrapper = styled.div<{
    indent: number;
    isSelected: boolean;
    hasPreContent: boolean;
    hasIcon: boolean;
    isLabel: boolean;
}>`
    position: relative;
    margin-left: ${props => ((props.hasPreContent ? 12 : 0) + (props.indent || 0) * 20) + (props.hasIcon ? 28 : 0) + "px"};
    padding-top: 9px;
    ${SelectUnderline};
    height: 100%;
    overflow: hidden;
    ${props => props.isLabel && `
        padding-left: 4px;
        padding-right: 4px;
    `}
`;

export const ColorBackground = styled.div<{
    _color: string;
}>`
    position: absolute;
    top: 4px;
    left: 0;
    height: 22px;
    width: 100%;
    border-radius: 3px;
    background: ${props => props._color};
`;


export const StyledItem = styled.div.attrs({
    selected: true
})<{
    _indent?: number;
    _useIconPadding?: boolean;
    _italic?: boolean;
    isHighlighted?: boolean,
    isSelected?: boolean,
    isTabular?: boolean
    _isDisabled?: boolean;
    _defaultWidth?: number;
}>`
    font-style: ${props => props._italic ? "italic" : "normal"};
    height: 30px;
    display: ${props => props.isTabular ? "table-row" : "flex"};
    align-items: center;
    background-color: ${props => props.isHighlighted ? themes.light.C_ACT_hover : ""};
        // padding-left: ${props => 12 + ((props._indent || 0) * 20 + (props._useIconPadding ? 28 : 0)) + "px"};
        // padding-left: ${props => 12 + (props._useIconPadding ? 28 : 0) + "px"};
    padding-left: ${props => 12 + "px"};

    padding-right: 24px;
    cursor: ${props => props._isDisabled ? "default" : "pointer"};
    position: relative;
    // for table-row relative hack
    transform: scale(1);
    width: 100%;
    opacity: ${props => props._isDisabled ? 0.5 : 1};

    ${props => props._defaultWidth && `
        min-width: ${props._defaultWidth - 36}px;
    `}
`;

export const ItemContent = styled.div`

`;

export const SelectedLine = styled.div`
    position: absolute;
    width: 100%;
    bottom: 0;
    background: white;
    height: 1px;
    left: 0;
`;

export const GroupEndLine = styled.div <{
    _line: MenuLineItem;
}>`
    position: absolute;
    width: calc(100% - 24px);
    background-color: ${props => transparentize(0.7, props.theme.C_ACT_hover_without_opacity)};
    height: 1px;
    left: 12px;
    bottom: ${props => props._line === MenuLineItem.Deep ? "-12px" : 0};
`;


export const GroupStartLine = styled.div`
    position: absolute;
    width: calc(100% - 24px);
    background-color: ${props => transparentize(0.7, props.theme.C_ACT_hover_without_opacity)};
    height: 1px;
    left: 12px;
    top: 6px;
`;

export const MultiValueContent = styled.div`
    display: flex;
    padding: 0 12px;
    align-items: center;
`;

// use 'any', because some weird ts bug
// https://github.com/microsoft/TypeScript/issues/37597
export const TabularHeader = (styled.div as any)`
  display: table-header-group;
  transform: scale(1);

  ${T_PLAIN_small_hig};
  width: 100%;
  justify-content: space-between;  
  color: ${(props: any) => props.theme.C_BG_menu}; 
  
  &:after{
    content: '';
    position: absolute;
    height: 1px;
    top: 30px;
    width: calc(100% - 24px);
    left: 12px;
    opacity: 0.3;
    background: ${(props: any) => props.theme.C_ACT_hover_without_opacity};
    border-radius: 1.5px;
  }
`;


export const TabularHeaderContent = styled.div`
    font-size: 12px;
    font-weight: 900;
    width: 100%;
    justify-content: space-between;
    color: ${props => props.theme.C_BG_menu};

`;

export const TableHeaderBottomLine = styled.div`
    position: absolute;
    height: 1px;
    bottom: 0;
    width: calc(100% - 24px);
    left: 12px;
    opacity: 0.3;
    background: ${props => props.theme.C_ACT_hover_without_opacity};
    border-radius: 1.5px;
`;

export const TabularHeaderItem = styled.div<{
    column?: IColumn;
    _isContentBefore?: boolean;
}>`
    display: table-cell;
    padding: ${props => props._isContentBefore ? "0 0 0 12px" : "8px 12px"};
    vertical-align: middle;
    height: 30px;
    white-space: nowrap;
    text-align: ${props => props.column?.textAlign};
`;

export const TabularRow = styled.div`
    display: table-row;
`;

export const ContentWrapper = styled.div<{
    _minWidth: number,
    _maxWidth: number
}>`
    min-width: ${props => `${props._minWidth}px`};
    max-width: ${props => props._maxWidth ? `${props._maxWidth}px` : null};;
    display: block;
    width: fit-content;
    z-index: 5;
`;

export const ActionItemsWrapper = styled.div<{ $hasContentBefore: boolean }>`
    position: relative;
    ${props => props.$hasContentBefore && css`padding-top: 9px;`};
`;

export const ActionIconsDelimiter = styled.div`
    position: absolute;
    left: 10px;
    width: calc(100% - 20px);
    height: 1px;
    background: ${props => props.theme.C_TEXT_dialogue};
    top: 10px;
    opacity: 0.3;
`;

export const Content = styled.div`
    position: relative;
`;
export const SoloColumnChild = styled.div`
    width: fit-content;
    position: relative;
`;
export const SoloColumn = styled.div<{
    isHighlighted: boolean;
    isSelected: boolean;
}>`
    background-color: ${props => props.isHighlighted ? props.theme.C_ACT_hover : ""};

    width: 100%;
    position: absolute;
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 12px;

    ${props => props.isSelected && `
    &:after{
        content: '';
        position: absolute;
        width: "100%";
        height: 1px;
        left: "0";
        background-color: ${props.theme.C_TEXT_dialogue};
        bottom: 4px;
    }
  `}

`;

export const TabularIconColumn = styled.div <{
    isFirst?: boolean;
}>`
    display: table-cell;
    padding-left: ${props => props.isFirst ? "12px" : "0"};
    vertical-align: middle;
    width: 33px;
`;


export const TabularColumn = styled.div<{
    isSelected?: boolean;
    isFirst?: boolean;
    isLast?: boolean;
    column?: IColumn;
    isContentBefore?: boolean;
}>`
    display: table-cell;
    vertical-align: middle;
    white-space: nowrap;
    font-size: 12px;
    color: ${props => props.theme.C_BG_menu};
    //padding-top: 8px;
    overflow: hidden;
    position: relative;
    text-align: ${props => props.column?.textAlign};
    //to make content before colum as small as possible
    width: ${props => props.isContentBefore ? "1px" : ""};
    padding-left: ${props => props.isFirst ? `${props.column?.textAlign === TextAlign.Right ? 24 : 12}px` : "0"};
    padding-right: ${props => props.isLast ? `${props.column?.textAlign === TextAlign.Right ? 12 : 24}px` : ""};

    max-width: 400px;
    ${TabularSelectedUnderline};
`;

export const StyledCheckbox = styled(Checkbox)<{
    _isTabular: boolean
}>``;

export const GlobalAllPrefix = styled.div`
    margin-left: -10px;
`;

export const Header = styled.div`
    padding: 0 24px;
    height: 43px;
    width: 100%;
    display: flex;
    align-items: center;
    color: ${props => props.theme.C_TEXT_dialogue};
    position: relative;
    justify-content: center;
    ${T_TITLE_6}
`;

export const HeaderText = styled.div`
    ${ellipsis()};
    color: ${props => props.theme.C_TEXT_dialogue_title};
`;

export const StyledSelectInput = styled(InputWithErrorAndTooltip)<{
    _isItalic?: boolean;
}>`
    ${props => props._isItalic && `
        & input {
            font-style: italic;
        }`}
    ${StyledToken} {
        opacity: ${props => props.auditTrailData?.type === AuditTrailFieldType.NoDifference ? 0 : 1}
    }
`;

export const GroupTitle = styled.div`
    ${T_PLAIN_small_hig};
    padding-left: 12px;
    display: flex;
    align-items: flex-start;
    color: ${props => props.theme.C_TEXT_dialogue};
    padding-top: 10px;
    padding-bottom: 10px;
    flex-direction: column;
    position: relative;
`;

export const AresSpecialGroupTitle = styled(GroupTitle)`
    padding-top: 20px;
`;

export const AresSpecialGroupTitleWithoutLine = styled(GroupTitle)`
    padding-top: 10px;
`;

export const GroupDivider = styled.div <{
    _dividerSize: GroupDividerSize;
}>`
    position: relative;
    height: ${props => props._dividerSize === GroupDividerSize.Large ? "20px" : props._dividerSize === GroupDividerSize.Small ? "10px" : "0"};
`;


export const GroupDividerLine = styled.div`
    position: absolute;
    left: 12px;
    width: calc(100% - 24px);
    background-color: ${props => transparentize(0.7, props.theme.C_ACT_hover_without_opacity)};
    height: 1px;
    top: 50%;
`;

export const GroupSubTitle = styled.span`
    margin-top: 10px;
    font-weight: normal;
    font-style: italic;
`;

const LEFT_START = 15;
const LEFT_NEXT = 19;
const MULTI_START = LEFT_START + 30;
export const HorizontalIndentLine = styled.div <{
    _diff: number;
    _isMulti: boolean;
    _indent: number;
    _drawLineForMissingIcon?: boolean;
}>`
    position: absolute;
    width: ${props => props._drawLineForMissingIcon ? "40px" : "11px"};
    height: 1px;
    background-color: ${props => props.theme.C_HIERARCHICAL_SELECT_LINE};
    top: 14px;
    left: ${props => (props._indent - 1) * LEFT_NEXT + (props._isMulti ? MULTI_START : LEFT_START) + "px"};
`;

export const VerticalIndentLine = styled.div <{
    _diff: number;
    _isMulti: boolean;
    _indent: number;
}>`
    position: absolute;
    width: 1px;
    height: ${props => 19 + ((props._diff - 1) * 30) + "px"};
    background-color: ${props => props.theme.C_HIERARCHICAL_SELECT_LINE};
    bottom: 15px;
    left: ${props => (props._indent - 1) * LEFT_NEXT + (props._isMulti ? MULTI_START : LEFT_START) + "px"};
`;

export const StyledInput = styled.input`
    background: ${props => props.theme.C_SELECT_SEARCH_INPUT_BACKGROUND};
    border: none;
    border-radius: 25px;
    padding-left: 35px;
    margin: 0;
    outline: none;
    height: 100%;;
    width: 100%;
    color: ${props => props.theme.C_TEXT_dialogue};
`;

export const SelectInputWrapper = styled.div`
    position: relative;
    border-radius: 25px;
    margin: 10px 12px;
    height: 34px;
    border: none;

    &:hover:after,
    &:focus-within:after {
        content: '';
        position: absolute;
        width: calc(100% - 4px);
        height: 30px;
        border: 1px solid ${props => props.theme.C_SELECT_SEARCH_INPUT_FOCUS};
        left: 2px;
        top: 2px;
        border-radius: 25px;
        pointer-events: none;
    }

`;

export const StyledSearchIcon = styled(ZoomSmallIcon)`
    position: absolute;
    left: 10px;
    color: #fff;
`;

export const SelectItemLink = styled(Clickable)`
    position: absolute;
    ${ellipsis()};
`;