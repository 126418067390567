import React, { Ref, useEffect, useRef } from "react";
import styled, { css } from "styled-components/macro";
import SimpleBar from "simplebar-react";
import TestIds from "../../testIds";
import { composeRefHandlers } from "@utils/general";

export interface IScrollBarProps {
    className?: string;
    scrollableNodeProps?: object;
    style?: React.CSSProperties;
    autoHide?: boolean | undefined;
    primary?: boolean;
    // adds styles necessary for the scrollbar to shrink itself in flex container
    isInFlex?: boolean;
    children: React.ReactNode;
}

// wrapper class to prevent props (like 'primary') from beeing sent to SimpleBar which tries to render all unknown props to DOM
const ScrollBar = React.forwardRef((props: IScrollBarProps, ref: Ref<SimpleBar>) => {
    const simplebarRef = useRef(null);

    // SimpleBar added tabIndex to allow users keyboard navigation directly on scroll element,
    // https://github.com/Grsmto/simplebar/commit/3875dbbc5019214cd41467ae1cd7958bae7824da
    // but didn't add an option to remove it, and we don't want it
    useEffect(() => {
        const el = simplebarRef.current.el as HTMLDivElement;

        el.querySelector(".simplebar-content-wrapper")?.removeAttribute("tabIndex");
    });

    return (
        <SimpleBar style={props.style} className={props.className}
                   scrollableNodeProps={props.scrollableNodeProps}
            // autoHide is true by default and takes even 'undefined' as falsy value
            // @ts-ignore
                   autoHide={props.autoHide === undefined ? true : props.autoHide}
            // simplebar doesn't support having always shown scrollbar when mouse is over, but hiding it when it isn't
            // but we can use autoHide true with extremely long timeout :/
            // timeout={60000}
                   ref={composeRefHandlers(ref, simplebarRef)}
                   data-testid={TestIds.ScrollBar}>
            {props.children}
        </SimpleBar>
    );
});


// use styled component on the wrapper instead pf SimpleBar
// otherwise custom props like 'primary' would cause either warnings or console
// or they would get rendered to DOM without a reason
const StyledScrollBar = styled(ScrollBar)<IScrollBarProps>`
    width: 100%;
    height: 100%;

    .simplebar-track.simplebar-hover.simplebar-vertical {
        width: 14px;
    }

    //.simplebar-vertical .simplebar-scrollbar.simplebar-visible.simplebar-hover {
    //    width: 14px;
    //}

    .simplebar-track.simplebar-hover.simplebar-horizontal {
        height: 11px;
    }

    .simplebar-track.simplebar-horizontal {
        height: 7px;
    }

    .simplebar-horizontal .simplebar-scrollbar {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
    }

    .simplebar-content {
        height: 100%;
    }

    // TODO pointer events are disabled by default, this could cause something wrong? 
    .simplebar-track {
        pointer-events: all;
    }

    .simplebar-scrollbar.simplebar-visible::before {
        opacity: 0.7;
    }

    ${props => props.primary && css`
        // don't affect all the simplebars inside, only the root one
        & > div > .simplebar-scrollbar::before {
            background-color: ${props => props.theme.C_ACT_main};
        }
    `};

    ${props => props.isInFlex && css`
        flex: 1 1 auto;
        min-height: 0;
    `};
`;

export { StyledScrollBar as ScrollBar };