import styled, { css } from "styled-components/macro";
import { CheckIcon } from "../../icon";
import { getDisabledStyle, getFocusBorderDefinition, T_PLAIN_big } from "../../../global.style";
import { AuditTrailFieldType } from "../../smart/FieldInfo";
import { transparentize } from "polished";

const CheckboxContainer = styled.div<{
    hasLabel?: boolean;
    isDisabled?: boolean;
}>`
    position: relative;
    display: flex;
    align-items: center;
    vertical-align: middle;
    cursor: pointer;

    ${props => getDisabledStyle(props.isDisabled)};
`;

interface ILabel {
    isLight?: boolean;
    auditTrailType?: AuditTrailFieldType;
}

const CheckboxLabel = styled.div<ILabel>`
    margin-left: 12px;
    white-space: nowrap;
    ${T_PLAIN_big};
    color: ${props => !props.isLight ? props.theme.C_TEXT_primary : props.theme.C_TEXT_dialogue};

    ${props => (props.auditTrailType === AuditTrailFieldType.NoDifference || props.auditTrailType === AuditTrailFieldType.HoveredNoDifference) && {
        borderRadius: "3px",
        backgroundColor: props.theme.C_AUDIT_TRAIL_NO_DIFF
    }};

`;

export const InnerLabel = styled.span <{
    auditTrailType?: AuditTrailFieldType
}>`
    opacity: ${props => props.auditTrailType === AuditTrailFieldType.NoDifference ? 0 : 1};
    ${props => props.auditTrailType === AuditTrailFieldType.HoveredNoDifference && {
        opacity: props.theme.C_AUDIT_TRAIL_DIFF_VISIBLE_OPACITY
    }};
`;

const Icon = styled.svg`
    fill: none;
    stroke: white;
    stroke-width: 2px;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

const StyledCheckbox = styled.div<{
    checked?: boolean;
    hasLabel?: boolean;
    $decorative?: boolean;
    $isLight?: boolean;
    auditTrailType?: AuditTrailFieldType;
}>`
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
    flex: 0 0 auto;

    border: solid 1px ${props => props.theme.C_ACT_main};
    background-color: ${props => props.checked ? props.theme.C_ACT_hover_without_opacity : props.theme.C_BG_menu};

    ${props => (props.auditTrailType === AuditTrailFieldType.NoDifference || props.auditTrailType === AuditTrailFieldType.HoveredNoDifference) && {
        border: `solid 1px ${props.theme.C_AUDIT_TRAIL_NO_DIFF}`,
        backgroundColor: props.theme.C_AUDIT_TRAIL_NO_DIFF
    }};

    ${props => (props.auditTrailType === AuditTrailFieldType.Difference || props.auditTrailType === AuditTrailFieldType.HoveredDifference) && {
        border: `solid 3px ${props.theme.C_AUDIT_TRAIL_DIFF}`
    }};

    ${props => props.$decorative ? css`
        border-color: currentColor;
        background-color: transparent;
        border-radius: 50%;
    ` : css`
        box-shadow: 0 0 4.9px 0.2px rgba(0, 0, 0, 0.05);
        border-radius: 3px;
    `};

    transition: all 150ms;

    &:hover {
        background-color: ${props => props.checked ? transparentize(0.5, props.theme.C_ACT_hover_without_opacity) : props.theme.C_ACT_hover_without_opacity};
    }

    &:focus-within:before {
        ${props => getFocusBorderDefinition({
            color: props.$isLight ? props.theme.C_ACT_light_line : props.theme.C_TEXT_primary,
            offset: 3
        })};
    }
`;

const CheckIconStyled = styled(CheckIcon)<{
    checked?: boolean;
    $decorative?: boolean;
    auditTrailType?: AuditTrailFieldType;
}>`
    ${props => props.$decorative && css`
        .IC_4L_main {
            stroke: currentColor !important;
            stroke-width: 6 !important;
        }
    `};

    ${props => props.auditTrailType === AuditTrailFieldType.NoDifference && {
        visibility: "hidden"
    }};

    ${props => props.auditTrailType === AuditTrailFieldType.HoveredNoDifference && {
        opacity: props.theme.C_AUDIT_TRAIL_DIFF_VISIBLE_OPACITY
    }};
`;

export { CheckboxLabel, CheckboxContainer, Icon, StyledCheckbox, HiddenCheckbox, CheckIconStyled };