import { IDefinition } from "@pages/PageUtils";
import i18next from "i18next";
import {
    calcLineItemValues,
    getDocumentPairedTable,
    ILineItemValues,
    isDocumentLocked,
    isProformaBc,
    isReceivedBc
} from "../../Document.utils";
import { DocumentLinkTypeCode, SelectionCode } from "@odata/GeneratedEnums";
import { FormStorage } from "../../../../views/formView/FormStorage";
import { IProformaEntity } from "../../proformaInvoices/ProformaInvoice.utils";
import { OData } from "@odata/OData";
import {
    DocumentEntity,
    DocumentLinkEntity,
    EntitySetName,
    IDocumentLinkEntity,
    IRegularDocumentItemEntity,
    ProformaInvoiceIssuedEntity
} from "@odata/GeneratedEntityTypes";
import BindingContext, { createPath } from "../../../../odata/BindingContext";
import { cloneDeep } from "lodash";
import { prepareQuery } from "@odata/OData.utils";
import { ModelEvent } from "../../../../model/Model";
import { forEachKey, isDefined } from "@utils/general";
import { setDirtyFlag } from "@odata/Data.utils";
import { isFormReadOnly } from "../../../../views/formView/Form.utils";
import { not, TValidatorFn } from "@components/smart/FieldInfo";
import { ValidatorType } from "../../../../enums";
import { logger } from "@utils/log";
import { IFormGroupDef } from "@components/smart/smartFormGroup/SmartFormGroup";
import { SAVED_VATS_PATH } from "@pages/admin/vatRules/VatRules.utils";
import { CREATE_RECEIPT_PATH } from "@pages/banks/bankAccounts/BankAccounts.utils";
import { refreshAccountIdsForActualFiscalYear } from "@pages/accountAssignment/AccountAssignment.utils";
import { TFetchFn } from "@utils/oneFetch";


export const DEDUCT_DEPOSIT_ACTION = "deductDeposit";
export const PROFORMA_FORM_TAB = "Proformas";
export const PROFORMA_DOC_LINK_FILTER = `TypeCode eq '${DocumentLinkTypeCode.ProformaInvoiceDeduction}'`;
export const RELATED_PROFORMA_ADDITIONAL_RESULT_IDX = 0;

const hasSameCurrencyAsProforma: TValidatorFn = (value, args) => {
    const storage = args.storage as FormStorage;
    return !storage.data?.additionalResults?.[RELATED_PROFORMA_ADDITIONAL_RESULT_IDX]
        .find((row: IProformaEntity) => row.TransactionCurrencyCode !== value);
};

const getProformaTab = (isCbaCompany: boolean): IFormGroupDef => {
    return {
        id: PROFORMA_FORM_TAB,
        title: i18next.t("Document:FormTab.Proforma"),
        table: getDocumentPairedTable({
            selectQuery: PROFORMA_DOC_LINK_FILTER,
            addTransactionAmountColumn: true,
            isCbaCompany
        })
    };
};

export const addProformaDef = (definition: IDefinition, hasAccountAssignment: boolean): void => {

    definition.form.customHeaderActions = definition.form.customHeaderActions ?? [];

    definition.form.customHeaderActions.push({
        id: DEDUCT_DEPOSIT_ACTION,
        label: i18next.t("Proforma:Pairing.DeductDeposit"),
        iconName: "PairingDeposit",
        isVisible: not(isFormReadOnly),
        isDisabled: isDocumentLocked
    });

    definition.form.additionalProperties.push({
        id: DocumentEntity.DocumentLinks,
        additionalProperties: [
            { id: DocumentLinkEntity.Amount },
            { id: DocumentLinkEntity.TransactionAmount },
            { id: DocumentLinkEntity.TypeCode },
            { id: createPath(DocumentLinkEntity.TargetDocument, DocumentEntity.Id) }
        ]
    });

    const currencyDef = definition.form.fieldDefinition.TransactionCurrency;
    if (currencyDef.validator) {
        logger.error("Resolve validator conflict on TransactionCurrency with Proforma Currency validator");
    }

    currencyDef.validator = {
        type: ValidatorType.Custom,
        settings: {
            customValidator: [{
                validator: hasSameCurrencyAsProforma,
                message: i18next.t("Error:ProformaInvoiceShouldHaveSameTransactionCurrencyAsInvoice")
            }]
        }
    };

    const tabsGroup: IFormGroupDef = definition.form.groups.find(group => group.id === "Tabs");
    tabsGroup.tabs.push(getProformaTab(!hasAccountAssignment));
};

export function findProformaDocumentLink(links: IDocumentLinkEntity[], proformaId: number): IDocumentLinkEntity {
    return links.find(docLink =>
        docLink.TypeCode === DocumentLinkTypeCode.ProformaInvoiceDeduction && docLink.TargetDocument.Id === proformaId);
}

export async function loadRelatedProformas(bindingContext: BindingContext, oData: OData, proformaBcs?: BindingContext[], oneFetch?: TFetchFn): Promise<IProformaEntity[]> {
    if ((bindingContext.isNew() || isProformaBc(bindingContext)) && !proformaBcs) {
        return [];
    }
    const isExpense = isReceivedBc(bindingContext);
    const entitySet = isExpense ? EntitySetName.ProformaInvoicesReceived : EntitySetName.ProformaInvoicesIssued;
    let filter: string;

    if (!isDefined(proformaBcs)) {
        // load all saved links according to invoice BC
        const docId = bindingContext.getKey();
        filter = `OppositeDocumentLinks/any(link: link/TypeCode in ('${DocumentLinkTypeCode.ProformaInvoiceDeduction}') AND link/SourceDocument/Id eq ${docId})`;
    } else if (proformaBcs?.length) {
        // load explicit Ids specified in 3rd parameter
        const ids = proformaBcs.map(bc => bc.getKey());
        filter = `Id in (${ids.join(",")})`;
    } else {
        // nothing to load (proformaBcs was specified, but is empty)
        return [];
    }

    const res = await oData.getEntitySetWrapper(entitySet).query()
        .filter(filter)
        .select("Id", "IsTaxDocument", "TransactionCurrencyCode", "TransactionAmount", "TransactionAmountNet", "TransactionAmountVat")
        .fetchData<IProformaEntity[]>(oneFetch);

    return res.value;
}

export function createProformaDocumentLink(bc: BindingContext, entity: IProformaEntity): IDocumentLinkEntity {
    const type = bc.getEntitySet().getType().getFullName();
    return {
        TargetDocument: {
            _metadata: {
                "": { type: `#${type}` }
            },
            Id: parseInt(bc.getKey() as string)
        },
        TypeCode: DocumentLinkTypeCode.ProformaInvoiceDeduction,
        Amount: -1 * entity.Amount,
        TransactionAmount: -1 * entity.TransactionAmount
    };
}


export const fetchAndApplyProformaInvoice = async (storage: FormStorage, proformaBc: BindingContext): Promise<void> => {
    // todo some better way? allowList vs blockList, same problem in DocumentFormView
    const EXCLUDED_FIELDS = [
        "Explanation", "Id", DocumentEntity.Locks, "Attachments", "DocumentTypeCode", "NumberOurs", "NumberTheirs",
        "NumberRange", "NumberRange/Name", "NumberRange/NextNumber", "NumberRange/Parent", "DocumentDraft",
        "OppositeDocumentLinks", "DocumentLinks", "DeferredPlans",
        "Note", DocumentEntity.SymbolVariable, DocumentEntity.SymbolConstant, DocumentEntity.SymbolSpecific,
        DocumentEntity.RemittanceInformation,
        DocumentEntity.ClearedStatusCode, DocumentEntity.ClearedStatus, DocumentEntity.PostedStatus, DocumentEntity.PostedStatusCode,
        DocumentEntity.VatStatementStatus, DocumentEntity.VatStatementStatusCode
    ];
    const invoiceMergedDef = cloneDeep((storage as FormStorage).data.mergedDefinition);

    forEachKey(invoiceMergedDef, (key) => {
        if (BindingContext.isLocalContextPath(key) || EXCLUDED_FIELDS.find(name => key.toString().startsWith(name))) {
            delete invoiceMergedDef[key];
        }
    });

    // don't copy any of the date fields
    const dateGroup = storage.data.definition.groups.find(group => group.id === "date");

    for (const row of dateGroup.rows) {
        for (const field of row) {
            delete invoiceMergedDef[field.id];
        }
    }

    // columns prepared from document mergedDef, so all configured fields in the variant are populated
    const mergedDefColumns = (storage as FormStorage).convertMergedDefToColumns(invoiceMergedDef);
    // add proforma specific fields, which is needed to do the calculations
    const proformaColumns = [{ id: ProformaInvoiceIssuedEntity.CalculateVat }, { id: ProformaInvoiceIssuedEntity.IsTaxDocument }];

    const columns = [...mergedDefColumns, ...proformaColumns];
    const result = await prepareQuery({
        oData: storage.oData,
        bindingContext: proformaBc,
        fieldDefs: columns
    }).fetchData<IProformaEntity>();

    const proformaInvoice = result?.value ?? {};
    const alteredProformaInvoice = cloneDeep(proformaInvoice);

    delete alteredProformaInvoice.Id;
    proformaColumns.forEach(col => {
        delete alteredProformaInvoice[col.id as keyof IProformaEntity];
    });

    storage.data.entity = {
        ...storage.data.entity,
        ...alteredProformaInvoice
    };

    if (!proformaInvoice.IsTaxDocument) {
        const bc = storage.data.bindingContext.navigate("Items");
        const items = (storage.data.entity.Items ?? []) as IRegularDocumentItemEntity[];

        items.forEach(item => {
            const itemBc = bc.addKey(item);
            let newItemValues: ILineItemValues;
            if (!proformaInvoice.CalculateVat) {
                const itemVatBc = itemBc.navigate("Vat");
                storage.setDefaultValue(itemVatBc);
                const vat = storage.getValue(itemVatBc, { useDirectValue: true });
                const decimalPlaces = storage.data.entity.Currency?.MinorUnit ?? 2;
                newItemValues = calcLineItemValues({
                    Quantity: item.Quantity,
                    TransactionUnitPrice: item.TransactionUnitPrice,
                    Vat: (vat?.Code && vat?.Rate) ?? 0
                }, "TransactionUnitPrice", null, decimalPlaces);

                delete newItemValues.Vat;
            }

            // always set default selection for Items, is not taxDocument, where selection is already selected
            storage.setValue(itemBc, {
                ...item,
                ...(newItemValues ?? {}),
                [SAVED_VATS_PATH]: SelectionCode.Default
            });
        });
    }

    // When proforma is applied -> cashbox should not be set and also createReceipt local context
    // as there will be no amount rest for clearing
    if (storage.data.bindingContext.isValidNavigation("CashBox")) {
        storage.setValueByPath(CREATE_RECEIPT_PATH, false);
        storage.clearValueByPath("CashBox");
    }

    // in case proforma is from different FY than the new invoice, we need to correct IDs of account
    // assignments to match the new FY
    await refreshAccountIdsForActualFiscalYear(storage, storage.data.entity.FiscalYear);

    // set fields as dirty to work correctly
    BindingContext.each(storage.data.entity, storage.data.bindingContext, (value, bc) => {
        // only set for fields, not their "parent" bc
        if (!bc.getKey()) {
            setDirtyFlag(storage, bc);
        }

        return true;
    });

    // call DocumentFormView.onAfterLoad to call all necessary handlers
    // it should remove the storage busy state as well
    storage.emitter.emit(ModelEvent.AfterLoad, true);
};