import React from "react";
import { AvatarSize } from "../../enums";
import { FILES_API_URL } from "../../constants";
import Avatar from "../../components/avatar/Avatar";
import { AvatarWrapper, StyledAvatarSelector } from "./Company.styles";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { FormStorage } from "../../views/formView/FormStorage";
import FocusManager, { FocusDirection, IFocusableItemProps } from "../../components/focusManager/FocusManager";
import { KeyName } from "../../keyName";
import { SystemAvatarDefs } from "@components/avatar/SystemAvatarDef";
import memoizeOne from "../../utils/memoizeOne";
import { composeRefHandlers } from "@utils/general";

interface IProps {
    storage: FormStorage;
}

interface IListAvatarProps {
    itemProps: IFocusableItemProps;
    onClick: (id: number) => void;
    onKeyDown: (e: React.KeyboardEvent, id: number) => void;
    isSelected: boolean;
    id: number;
}

const getLogoUrl = (id: number) => {
    return FILES_API_URL + "/" + id;
};

class ListAvatar extends React.PureComponent<IListAvatarProps> {
    handleKeyDown = (e: React.KeyboardEvent) => {
        this.props.onKeyDown(e, this.props.id);
    };

    handleClick = () => {
        this.props.onClick(this.props.id);
    };

    render() {
        return (
            <AvatarWrapper
                {...this.props.itemProps}
                onClick={this.handleClick}
                onKeyDown={this.handleKeyDown}
                _isSelected={this.props.isSelected}>
                <Avatar
                    size={AvatarSize.XL}
                    src={getLogoUrl(this.props.id)}
                />
            </AvatarWrapper>
        );
    }
}

export default class CompanyAvatarSelector extends React.Component<IProps> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    wrapperRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        const currentId = this.props.storage.getValueByPath("Logo/Id");
        const selectedIndex = this.getColumns().findIndex(id => id === currentId);

        // focus selected avatar
        (this.wrapperRef.current.children[selectedIndex] as HTMLDivElement).focus();
    }

    getColumns = memoizeOne(() => {
        return Object.values(SystemAvatarDefs).map(o => o.id);
    });

    handleAvatarClick = (id: number): void => {
        this.props.storage.setValueByPath("Logo/Id", id);
        this.forceUpdate();
    };

    handleKeyDown = (e: React.KeyboardEvent, id: number): void => {
        if (e.key === KeyName.Enter || e.key === KeyName.Space) {
            this.handleAvatarClick(id);
        }
    };

    render() {
        const currentId = this.props.storage.getValueByPath("Logo/Id");
        // dialog has hardcoded width("676px") so we can use grid focus direction with columnsCount
        return (
                <FocusManager direction={FocusDirection.Grid} columnsCount={6}>
                    {({ itemProps, wrapperProps }) => (
                            <StyledAvatarSelector {...wrapperProps}
                                                  ref={composeRefHandlers(wrapperProps.ref, this.wrapperRef)}>
                                {this.getColumns().map((id: number) => {
                                    return <ListAvatar
                                            key={id}
                                            itemProps={itemProps}
                                            onClick={this.handleAvatarClick}
                                            isSelected={currentId === id}
                                            onKeyDown={this.handleKeyDown}
                                id={id}
                            />;
                        })}
                    </StyledAvatarSelector>

                )}
            </FocusManager>
        );
    }
}