import styled, { css } from "styled-components/macro";
import { T_TITLE_4_NORMAL } from "../../global.style";
import { ChangeButton } from "../settingsList/SettingItem";

export const StyledSettingsList2 = styled.div`
    display: flex;
    flex-direction: column;
`;

const LEVEL_MARGIN = 55;

export const SettingListItem2Wrapper = styled.div`
`;

interface IListItem {
    $level: number;
    $childrenCount: number;
}

export const StyledSettingListItem2 = styled.div<IListItem>`
    position: relative;
    display: flex;
    width: calc(572px - ${props => props.$level * LEVEL_MARGIN}px);
    margin-left: ${props => props.$level * LEVEL_MARGIN}px;
    border-radius: 6px;
    background-color: ${props => props.theme.C_DEF_tableBorder};
    padding: 18px 20px;
    margin-top: 20px;
    min-height: 77px;

    ${props => props.$level && css`
        &::before {
            content: "";
            position: absolute;
            top: 39px;
            width: 28px;
            left: 0;
            transform: translateX(-100%);
            height: 1px;
            background-color: ${props => props.theme.C_FIELD_line};
        }
    `};

    ${props => props.$childrenCount > 0 && css<IListItem>`
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            transform: translateY(100%);
            left: 27px;
            height: ${props => (props.$childrenCount - 1) * 97 + 59}px;
            width: 1px;
            background-color: ${props => props.theme.C_FIELD_line};
        }
    `};
`;

export const SettingListItem2LeftSide = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
`;

export const SettingListItem2Content = styled.div<{
    isWithoutButton?: boolean
}>`
    margin-top: 5px;
    padding-right: ${props => props.isWithoutButton ? 0 : "90px"};
    ${T_TITLE_4_NORMAL};
`;

export const ChangeButtonStyled = styled(ChangeButton)`
    position: absolute;
    top: 18px;
    right: 20px;
`;