import React from 'react';
import { BottomText, LoginButtonGroupStyled } from "../LoginPage.styles";
import { TextAlign } from "../../enums";

interface IProps {
    additionalText?: React.ReactNode;
}

const LoginButtonGroup: React.FC<IProps> = ({ additionalText, children }) => {
    return (<>
        <LoginButtonGroupStyled align={TextAlign.Left}>
            {children}
        </LoginButtonGroupStyled>
        {additionalText && (
                <BottomText>
                    {additionalText}
                </BottomText>
        )}
    </>);
};

export default LoginButtonGroup;