import { IFormDef } from "../views/formView/Form";
import { IFieldDef, IFieldInfoProperties, TInfoValue } from "@components/smart/FieldInfo";
import { ISort } from "@components/table";
import { PageViewMode, QueryParam, ToolbarItemType } from "../enums";
import { getQueryParameters } from "../routes/Routes.utils";
import { TFunction } from "i18next";
import { OData } from "@odata/OData";
import { TRecordAny, TRecordType } from "../global.types";
import { Model } from "../model/Model";
import { ISplitPageTableDef } from "../views/table/TableView.utils";
import { IToolbarItem } from "@components/toolbar";
import { TEntityKey } from "@odata/BindingContext";
import { uuidv4 } from "@utils/general";
import { IAppContext } from "../contexts/appContext/AppContext.types";

export type TFieldDefinition = IFieldInfoProperties & Partial<IFieldDef>;
export type TFieldDefinitionFactory<T = IFieldDef> = boolean | ((storage: Model, id?: string) => Promise<T[]>);
export type TFieldsDefinition = TRecordType<TFieldDefinition>;

//todo: will be moved to different location in the next step
export interface IPageProps {
    childCollection?: string;
}

export interface IDefinition {
    entitySet?: TInfoValue<string>;

    table?: ISplitPageTableDef;
    form?: IFormDef;
}

export interface IGetDefinition {
    (context: IAppContext, ...args: any[]): IDefinition;

    // "static" property for retrieving translationFiles before calling getDefinition itself
    translationFiles: string[];
}

// don't know how to keep the call signature of the interface when using Partial/Optional
// => using new interface as workaround
export interface IGetDefinitionOptionalTranslation {
    (context: IAppContext, ...args: any[]): IDefinition;

    translationFiles?: string[];
}

export const DRAFT_BC_PREFIX = "draft-";
const UNIQ_SUFFIX_SEPARATOR = "#";

export const getCleanDraftKey = (key: TEntityKey): string => {
    const [base] = key.toString().split(UNIQ_SUFFIX_SEPARATOR, 2);
    return base.replace(DRAFT_BC_PREFIX, "");
};

export const getUniqBcSuffix = (): string => `${UNIQ_SUFFIX_SEPARATOR}${uuidv4()}`;

export interface IFilesDefinition {
    /** Defines collection on the current entity set, which contains the fields */
    collection: string;
    /** If the entity of the given collection isn't FileMetadata directly, defines the property that is */
    property?: string;
}

export interface IFetchDefinition {
    entitySet: TInfoValue<string>;
    columns: IFieldDef[];
    sort?: ISort[];
    filter?: TInfoValue<string>;
}

export const getPageViewMode = (viewMode?: PageViewMode): PageViewMode => {
    return viewMode ?? getQueryParameters()?.[QueryParam.ViewMode] as PageViewMode;
};

export enum ObjectListAction {
    Edit = "edit",
    ToggleActive = "activate",
    Delete = "delete"
}

export const getStandartObjectListActions = (t: TFunction, isActive: boolean, isDefault?: boolean): IToolbarItem[] => {
    let actions: IToolbarItem [] = [{
        id: ObjectListAction.Edit,
        iconName: "Edit",
        itemType: ToolbarItemType.Icon,
        label: t("Common:General.Edit")
    }];

    if (!isDefault) {
        actions = actions.concat({
            id: ObjectListAction.ToggleActive,
            itemType: isActive ? ToolbarItemType.TransparentButton : ToolbarItemType.Button,
            label: isActive ? t("Common:General.Deactivate") : t("Common:General.Activate")
        }, {
            id: ObjectListAction.Delete,
            iconName: "Bin",
            itemType: ToolbarItemType.Icon,
            label: t("Common:General.Delete")
        });
    }

    return actions;
};

export const deleteObjectListItem = async (entitySet: string, oData: OData, items: TRecordAny[], id: TEntityKey): Promise<void> => {
    await oData.fromPath(entitySet).delete(id);

    const idx = items?.findIndex((item: TRecordAny) => item.Id?.toString() === id?.toString());
    if (idx !== -1) {
        items.splice(idx, 1);
    }
};

export function getItemBreadCrumbsText(storage: Model, newItemText: string, itemText?: string): string {
    return storage.data.bindingContext.isNew() ? newItemText : (itemText ?? newItemText);
}