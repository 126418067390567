import React from "react";
import { IDefinition, IGetDefinitionOptionalTranslation } from "../../pages/PageUtils";
import { FormStorage, IFormStorageData } from "./FormStorage";
import { AppContext, IAppContext } from "../../contexts/appContext/AppContext.types";
import i18next, { TFunction } from "i18next";
import BindingContext, { IEntity } from "../../odata/BindingContext";
import { FormViewForExtend, IFormViewProps } from "./FormView";
import { handleRefHandlers } from "@utils/general";
import { WithOData, withOData } from "@odata/withOData";
import { WithPermissionContext, withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { IDialogFormViewProps } from "./DialogFormView";
import { PropsWithTheme } from "../../theme";
import { withTheme } from "styled-components";
import * as History from "history";
import { IHistoryState } from "../../model/Model";
import { Optional } from "../../global.types";

export type TFormViewProps =
    Optional<IFormViewProps<unknown>, keyof WithPermissionContext>
    | Optional<IDialogFormViewProps<unknown>, keyof WithPermissionContext>;

/** Use form anywhere, without preparing form storage */
interface IProps extends WithOData, WithPermissionContext, PropsWithTheme {
    getDef: IGetDefinitionOptionalTranslation;
    data?: IEntity;
    // expects correct translations to be loaded and provided
    t: TFunction;
    bindingContext: BindingContext;

    history?: History.History<IHistoryState>;
    initialData?: IFormStorageData<any, any>;
    formViewProps?: Partial<TFormViewProps>;
    formView?: React.ComponentType<TFormViewProps>;
    onAfterLoad?: (formStorage: FormStorage) => void;
    onCancel?: () => void;

    formRef?: React.Ref<FormViewForExtend<any>>;
    showButtons?: boolean;
    ignoreVariants?: boolean;

    className?: string;
}

class PlugAndPlayForm extends React.PureComponent<IProps> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;
    storage: FormStorage;
    def: IDefinition;
    formRef = React.createRef<FormViewForExtend<unknown>>();

    constructor(props: IProps, context: IAppContext) {
        super(props, context);

        this.def = this.props.getDef(this.context);

        this.storage = new FormStorage({
            id: this.def.form.id,
            oData: this.props.oData,
            t: this.props.t,
            theme: this.props.theme,
            context: context,
            history: this.props.history,
            initialHistoryState: this.props.history?.location?.state,
            refresh: () => {
                this.forceUpdate();
                this.formRef.current?.forceUpdate();
            }
        });
    }

    componentDidMount() {
        this.init();
    }

    init = async () => {
        if (this.props.getDef.translationFiles) {
            // first load translations, then get definition
            await i18next.loadNamespaces(this.props.getDef.translationFiles);
            // retrieve the definition again, now with surely loaded translations
            this.def = this.props.getDef(this.context);
        }

        await this.storage.init({
            definition: this.def.form,
            bindingContext: this.props.bindingContext,
            data: this.props.data,
            ignoreVariants: this.props.ignoreVariants !== false,
            onAfterLoad: () => {
                // stores initial data before onAfterLoad on FormView is called
                if (this.props.initialData) {
                    this.storage.store({
                        ...this.props.initialData,
                        entity: {
                            ...this.storage.data.entity,
                            ...this.props.initialData?.entity
                        },
                        customData: {
                            ...this.storage.data.customData,
                            ...this.props.initialData?.customData
                        }
                    });
                }
            }
        });

        this.props.onAfterLoad?.(this.storage);

        this.storage.refresh();
    };

    handleFormRef = (ref: FormViewForExtend<any>) => {
        handleRefHandlers(ref, this.formRef, this.props.formRef);
    };

    render() {
        const FormView = this.props.formView;

        return (<FormView {...this.props.formViewProps}
                          title={this.def?.form?.title}
                          ref={this.handleFormRef}
                          onCancel={this.props?.onCancel}
                          storage={this.storage}
                          permissionContext={this.props.permissionContext}
                          formProps={{
                              hideBreadcrumbs: true,
                              hideHeader: true,
                              withoutPadding: true,
                              ...this.props.formViewProps?.formProps
                          }}
                          hideButtons={!this.props.showButtons}/>
        );
    }
}

export default withTheme(withPermissionContext(withOData(PlugAndPlayForm)));