import { IValueInterval } from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import { IGroupListItemDef } from "@components/configurationList";
import { getTableDrillDownLink } from "@components/drillDown/DrillDown.utils";
import { formatDateToDateString } from "@components/inputs/date/utils";
import { ISelectItem, SelectGroups } from "@components/inputs/select/BasicSelect";
import { IFieldInfoProperties } from "@components/smart/FieldInfo";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { IReportRowDef, ISmartReportTableRow } from "@components/smart/smartTable";
import { getNestedValue } from "@odata/Data.utils";
import { IFormatOptions } from "@odata/OData.utils";
import { getCompanyCurrency } from "@utils/CompanyUtils";
import { isNotDefined } from "@utils/general";
import i18next from "i18next";
import React from "react";

import {
    BasicInputSizes,
    ConfigListItemBoundType,
    FieldType,
    LabelStatus,
    RowType,
    Sort,
    ValueType
} from "../../../enums";
import { TRecordValue, TValue } from "../../../global.types";
import BindingContext from "../../../odata/BindingContext";
import { ROUTE_ACCOUNT_ANALYSIS } from "../../../routes";
import CurrencyType from "../../../types/Currency";
import { AccountAnalysisProps } from "../accountAnalysis/AccountAnalysisDef";
import {
    columnBorderFormatter,
    getReportDisplayType,
    metaColumnFormatter,
    resetReportDisplayType,
    toggleGroupsByReportDisplayType
} from "../balanceSheet/BalanceSheet";
import { getColumnGroupForColumn, IBalanceSheetCustomData } from "../balanceSheet/BalanceSheet.utils";
import {
    CommonReportProps,
    FiscalYearComparison,
    getComparisonDef,
    getDateRangeDef,
    getSplitIntoPeriodsDef,
    getUnitsDef,
    ReportDisplayType,
    ReportSectionTypeCode
} from "../CommonDefs";
import {
    composedDateRangeOnBeforeLoadCallback,
    CUSTOM_DATE_RANGE_ID,
    getDateRangeCustomValueDef
} from "../customFilterComponents/ComposedDateRange";
import {
    getLabelDrilldownFilters,
    IGetReportDefVal,
    IReportTableDefinition,
    labelColumnNamePrefix,
    NumberAggregationFunction,
    ReportConfigGroup,
    TGetReportConfigListItemOverride,
    TReportConfigListItemOverride
} from "../Report.utils";
import { ReportId } from "../ReportIds";
import { ReportStorage } from "../ReportStorage";
import { INC_STMNT_SHORT_SHEET_OPEN_LINES, IncomeStatementDelta } from "./IncomeStatement";
import { StyledIndication } from "./IncomeStatement.styles";
import { enhanceRowsWithAccountIds } from "./IncomeStatement.utils";

export const initialSortBy = [{ id: "Line", sort: Sort.Asc }];

export const IncomeStatementProps = {
    incomeStatementStructure: BindingContext.localContext("IncomeStatementStructure")
};

export enum IncomeStatementStructure {
    ByNatureOfExpense = "ByNatureOfExpense",
    ByFunctionOfExpense = "ByFunctionOfExpense"
}

const onAfterTableLoad = (storage: ReportStorage<IBalanceSheetCustomData>) => {
    const reportDisplayType = getReportDisplayType(storage);

    if (reportDisplayType !== ReportDisplayType.None) {
        toggleGroupsByReportDisplayType(storage, reportDisplayType, INC_STMNT_SHORT_SHEET_OPEN_LINES);
    }
};

const onGroupToggle = (storage: ReportStorage) => {
    resetReportDisplayType(storage);
};

const rowFormatter = (rowDef: IReportRowDef): Partial<ISmartReportTableRow> => {
    const newRow: Partial<ISmartReportTableRow> = {};

    if (rowDef.Value.ReportSectionTypeCode === ReportSectionTypeCode.Sum) {
        newRow.isHighlighted = true;
        newRow.type = RowType.Aggregation;
        newRow.isBold = true;
    }

    return newRow;
};

export const getDefinition = (): IReportTableDefinition => {
    const tableId = ReportId.IncomeStatement;
    const title = i18next.t("Reporting:IncomeStatement.Title");
    const path = "IncomeStatement";
    const parameters: string[] = [
        IncomeStatementProps.incomeStatementStructure,
        CommonReportProps.dateRange, CommonReportProps.dateRangeCustomValue,
        CommonReportProps.splitIntoPeriods,
        CommonReportProps.showDelta,
        CommonReportProps.comparison
    ];
    const updateFiltersFromResponse = true;

    const configListItemsOverride: TGetReportConfigListItemOverride = (item: IGroupListItemDef): TReportConfigListItemOverride => {
        switch (true) {
            case item.id === "Section":
                return {
                    ...item,
                    isRequired: true,
                    isDisabled: true,
                    isCopyOnly: false,
                    group: ReportConfigGroup.Groups
                };
            case item.id === "Line":
                return {
                    ...item,
                    isRequired: true,
                    isDisabled: true,
                    isCopyOnly: false,
                    boundTo: ConfigListItemBoundType.Group,
                    group: ReportConfigGroup.Columns

                };
            case item.id === "Number":
                return {
                    ...item,
                    isRequired: true,
                    isCopyOnly: false,
                    boundTo: ConfigListItemBoundType.Group,
                    group: ReportConfigGroup.Columns
                };
            case item.id === "Indication":
                return {
                    ...item,
                    isRequired: true,
                    isDisabled: true,
                    isCopyOnly: false,
                    boundTo: ConfigListItemBoundType.Group,
                    group: ReportConfigGroup.Columns
                };
            case item.id === "JournalEntry_Amount":
                return {
                    ...item,
                    isRequired: true,
                    isDisabled: true,
                    isCopyOnly: false,
                    areItemsReadOnly: true,
                    value: i18next.t(`Reporting:IncomeStatement.Values`) as string,
                    group: ReportConfigGroup.Aggregations
                };
            case item.id.startsWith(labelColumnNamePrefix):
                return {
                    ...item,
                    allowedGroups: [ReportConfigGroup.Groups, ReportConfigGroup.Columns, ReportConfigGroup.AvailableColumns]
                };
        }

        return item;
    };

    const defaultReportHierarchy = {
        "Aggregate": true,
        "Groups": [
            {
                "ColumnAlias": "Section"
            }
        ],
        "Columns": [
            {
                "ColumnAlias": "Indication"
            },
            {
                "ColumnAlias": "Line"
            }
        ],
        "Aggregations": [
            {
                "ColumnAlias": "JournalEntry_Amount",
                "AggregationFunction": NumberAggregationFunction.Sum
            }
        ]

    };

    /** Backend sends account ids that are needed for drilldown only for the leaves
     * => we need to collect those data and propagate them to all levels */
    const rowsDataFactory = (rowsData: IReportRowDef[]): IReportRowDef[] => {
        enhanceRowsWithAccountIds(rowsData, ["AccountNumbers"]);
        // clear cache for getColumnGroupForColumn, in case column ids has changed
        getColumnGroupForColumn.cache.clear();

        return rowsData;
    };

    const columnOverrides = (columnId: string): IFieldInfoProperties => {
        const formatCurrency = (val: number, storage: ReportStorage) => {
            return CurrencyType.format(val, {
                currency: getCompanyCurrency(storage.context),
                scale: currencyUnit({ storage: storage, settings: {} })
            });
        };

        if (columnId === "Indication") {
            return {
                formatter: (val: TValue, args: IFormatOptions) => {
                    if (isNotDefined(val) || args.entity.ReportSectionTypeCode === ReportSectionTypeCode.Sum) {
                        return null;
                    }

                    const isIncome = args.entity.ReportSectionTypeCode === ReportSectionTypeCode.Income;

                    return {
                        value: (<StyledIndication isIncome={isIncome}>{val}</StyledIndication>),
                        tooltip: val as string
                    };
                }
            };
        } else if (columnId.startsWith("Value")) {
            return {
                formatter: (val: TValue, args: IFormatOptions) => {
                    const storage = args.storage as ReportStorage;
                    const formattedVal = formatCurrency(val as number, storage);

                    if (!val || val === 0 || args.entity.ReportSectionTypeCode === ReportSectionTypeCode.Sum) {
                        return formattedVal;
                    }

                    const isComp = columnId.includes("Comp");
                    const accountsPropName = "AccountNumbers" + (isComp ? "_Comp" : "");
                    const accounts = args.entity[accountsPropName];

                    const filters: TRecordValue = {
                        [CommonReportProps.dateRange]: CUSTOM_DATE_RANGE_ID,
                        [AccountAnalysisProps.accounts]: accounts,
                        [AccountAnalysisProps.sumAmounts]: true,
                        ...getLabelDrilldownFilters(args.entity)
                    };

                    const columnGroup = getColumnGroupForColumn(columnId, args.storage as ReportStorage);
                    const dateStart: string = columnGroup.AdditionalInfo.FirstDayOfPeriod;
                    const dateEnd: string = columnGroup.AdditionalInfo.LastDayOfPeriod;

                    const dateRangeParam: IValueInterval = {
                        from: formatDateToDateString(dateStart),
                        to: formatDateToDateString(dateEnd)
                    };

                    filters[CommonReportProps.dateRangeCustomValue] = dateRangeParam;

                    return getTableDrillDownLink(formattedVal, {
                        route: ROUTE_ACCOUNT_ANALYSIS,
                        context: args.storage.context,
                        storage: args.storage,
                        filters
                    });
                }
            };
        }

        return null;
    };

    const currencyUnit = (args: IGetReportDefVal) => {
        return getNestedValue(CommonReportProps.units, args.storage.data.entity);
    };

    const dateRangeDef = getDateRangeDef();

    const filterBarDef: IFilterGroupDef[] = [
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Parameters),
            defaultFilters: [
                IncomeStatementProps.incomeStatementStructure,
                CommonReportProps.dateRange, CommonReportProps.dateRangeCustomValue,
                CommonReportProps.comparison,
                CommonReportProps.showDelta,
                CommonReportProps.splitIntoPeriods,
                CommonReportProps.units
            ],
            filterDefinition: {
                [IncomeStatementProps.incomeStatementStructure]: {
                    type: FieldType.ComboBox,
                    width: BasicInputSizes.M,
                    defaultValue: IncomeStatementStructure.ByNatureOfExpense,
                    label: i18next.t("Reporting:IncomeStatement.IncomeStatementStructure"),
                    fieldSettings: {
                        items: [
                            {
                                label: i18next.t("Reporting:IncomeStatement.ByNatureOfExpense"),
                                id: IncomeStatementStructure.ByNatureOfExpense
                            },
                            {
                                label: i18next.t("Reporting:IncomeStatement.ByFunctionOfExpense"),
                                id: IncomeStatementStructure.ByFunctionOfExpense
                            }
                        ]
                    }
                },
                [CommonReportProps.dateRange]: {
                    ...dateRangeDef,
                    fieldSettings: {
                        ...dateRangeDef.fieldSettings,
                        transformFetchedItems: (items: ISelectItem[]): ISelectItem[] => {
                            return items.filter(item => !item.id.toString().includes("FiscalPeriod"));
                        }
                    }
                },
                [CommonReportProps.dateRangeCustomValue]: getDateRangeCustomValueDef(),
                [CommonReportProps.comparison]: getComparisonDef(),
                [CommonReportProps.showDelta]: {
                    type: FieldType.ComboBox,
                    width: BasicInputSizes.M,
                    defaultValue: "No",
                    label: i18next.t("Reporting:BalanceSheet.ShowDelta"),
                    labelStatus: LabelStatus.Visible,
                    isDisabled: (arg) => {
                        const comparison = arg.storage.getValueByPath(CommonReportProps.comparison);

                        return !comparison || comparison === FiscalYearComparison.None;
                    },
                    fieldSettings: {
                        items: [
                            {
                                label: i18next.t("Reporting:IncomeStatement.InValues"),
                                id: IncomeStatementDelta.Yes
                            },
                            {
                                label: i18next.t("Reporting:IncomeStatement.InPercent"),
                                id: IncomeStatementDelta.InPercent
                            }
                        ],
                        additionalItems: [
                            {
                                label: i18next.t("Reporting:IncomeStatement.DontShow"),
                                id: IncomeStatementDelta.No,
                                groupId: SelectGroups.Default
                            }
                        ]
                    }
                },
                [CommonReportProps.splitIntoPeriods]: getSplitIntoPeriodsDef(),
                [CommonReportProps.units]: getUnitsDef()
            }
        },
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            allowCustomFilters: false,
            // filters that are not dynamic and can be specified before hand
            // rest are created AFTER the data are loaded from BE
            defaultFilters: [
                BindingContext.localContext("Section"),
                BindingContext.localContext("Indication"),
                BindingContext.localContext("Line")
            ],
            filterDefinition: {
                [BindingContext.localContext("Section")]: {
                    label: i18next.t("Reporting:Columns.Section"),
                    valueType: ValueType.String,
                    isValueHelp: true
                },
                [BindingContext.localContext("Indication")]: {
                    label: i18next.t("Reporting:Columns.Indication"),
                    valueType: ValueType.String,
                    isValueHelp: true
                },
                [BindingContext.localContext("Line")]: {
                    label: i18next.t("Reporting:Columns.Line"),
                    valueType: ValueType.String,
                    isValueHelp: true
                }
            }
        }
    ];

    const onBeforeLoad = async (storage: ReportStorage) => {
        await composedDateRangeOnBeforeLoadCallback(storage, false, false);
    };

    return {
        title, path, id: tableId,
        columnOverrides, onAfterTableLoad,
        onBeforeLoad, rowsDataFactory, currencyUnit,
        onGroupToggle, rowFormatter, initialSortBy,
        metaColumnFormatter, updateFiltersFromResponse,
        columnFormatter: columnBorderFormatter, filterBarDef, parameters,
        defaultReportHierarchy,
        configListItemsOverride
    };
};