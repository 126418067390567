import React from "react";
import styled from "styled-components/macro";

interface IProps {
    onResize: (entries: readonly ResizeObserverEntry[]) => void;
    includeWindowResize?: boolean;
}

const StyledResizeObserver = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
`;

export default class CustomResizeObserver extends React.PureComponent<IProps> {
    resizeObserver: ResizeObserver;
    divRef = React.createRef<HTMLDivElement>();

    constructor(props: IProps) {
        super(props);
        this.resizeObserver = new ResizeObserver(this.props.onResize);
    }

    componentDidMount() {
        this.observeElement();
        if (this.props.includeWindowResize) {
            window.addEventListener("resize", this.handleResize);
        }
    }

    observeElement = (): void => {
        this.resizeObserver.observe(this.divRef.current);
    };

    componentWillUnmount() {
        this.resizeObserver.disconnect();
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (): void => {
        this.props.onResize?.([]);
    };

    render(): React.ReactNode {
        // ref passed from parent is not going to get propagated automatically,
        // only if the parent gets rerendered, which doesn't have to always happen
        // => render div here to observe instead
        // => one extra div, but easier to use component than having to handle all the events everytime
        return <StyledResizeObserver ref={this.divRef}/>;
    }
}