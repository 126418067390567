import { IFieldDefFn } from "@components/smart/FieldInfo";
import { FilterBarGroup, getDefaultFilterGroupDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import { BackgroundJobEntity, EntitySetName, EntityTypeName } from "@odata/GeneratedEntityTypes";
import { IFormatOptions } from "@odata/OData.utils";
import { setDefByEntityType } from "@pages/getDefByEntityType";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "@pages/PageUtils";
import React from "react";

import Field from "../../components/inputs/field";
import { FieldType, Sort } from "../../enums";
import { TValue } from "../../global.types";
import { Model } from "../../model/Model";
import DateType, { getUtcDate } from "../../types/Date";
import { IFormDef } from "../../views/formView/Form";
import FormView from "../../views/formView/FormView";
import { ISplitPageTableDef } from "../../views/table/TableView.utils";

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const table: ISplitPageTableDef = {
        filterBarDef: [{
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            isValueHelp: true,
            defaultFilters: [
                BackgroundJobEntity.Status,
                BackgroundJobEntity.Type,
                BackgroundJobEntity.DateStarted
            ],
            filterDefinition: {
                [BackgroundJobEntity.Status]: {
                    fieldSettings: {
                        displayName: "Name"
                    }
                },
                [BackgroundJobEntity.Type]: {
                    fieldSettings: {
                        displayName: "Name"
                    }
                },
                [BackgroundJobEntity.DateStarted]: {},
                [BackgroundJobEntity.DateLastModified]: {}
            }
        }],
        id: "backgroundJobsHistoryTable",
        initialSortBy: [{ id: BackgroundJobEntity.DateStarted, sort: Sort.Desc }],
        columns: [
            BackgroundJobEntity.DateStarted,
            BackgroundJobEntity.DateLastModified,
            BackgroundJobEntity.Type,
            BackgroundJobEntity.Status
        ],
        columnDefinition: {
            [BackgroundJobEntity.Status]: {
                fieldSettings: {
                    displayName: "Name"
                }
            },
            [BackgroundJobEntity.Type]: {
                fieldSettings: {
                    displayName: "Name"
                }
            },
            [BackgroundJobEntity.DateStarted]: {
                formatter: (val: TValue, args?: IFormatOptions): string => {
                    return DateType.localFormat(val as Date, "DD.MM.YYYY, HH:mm:ss");
                }
            },
            [BackgroundJobEntity.DateLastModified]: {
                formatter: (val: TValue, args?: IFormatOptions): string => {
                    return DateType.localFormat(val as Date, "DD.MM.YYYY, HH:mm:ss");
                }
            }
        },
        title: "Background jobs"
    };

    const summary: ISummaryItem[] = [];

    const form: IFormDef = {
        id: "backgroundJobsHistoryTableForm",
        translationFiles: getDefinitions.translationFiles,
        summary,
        isDeletable: false,
        isReadOnly: true,
        getItemBreadCrumbText: (storage: Model) =>
            getItemBreadCrumbsText(storage, "test"),
        formControl: FormView,
        fieldDefinition: {
            [BackgroundJobEntity.Type]: {
                type: FieldType.ComboBox,
                fieldSettings: {
                    displayName: "Name"
                }
            },
            [BackgroundJobEntity.Status]: {
                type: FieldType.ComboBox,
                fieldSettings: {
                    displayName: "Name"
                }
            },
            [BackgroundJobEntity.DateStarted]: {
                formatter: (val: TValue, args?: IFormatOptions): string => {
                    const date = getUtcDate(args.entity[BackgroundJobEntity.DateStarted] as string);

                    return DateType.localFormat(date, "DD.MM.YYYY, HH:mm:ss");
                }
            },
            [BackgroundJobEntity.DateLastModified]: {
                formatter: (val: TValue, args?: IFormatOptions): string => {
                    const date = getUtcDate(args.entity[BackgroundJobEntity.DateLastModified] as string);

                    return DateType.localFormat(date, "DD.MM.YYYY, HH:mm:ss");
                }
            },
            [BackgroundJobEntity.Result]: {
                render: (args: IFieldDefFn) => {
                    let value: string;

                    try {
                        value = JSON.stringify(JSON.parse(args.props.value), undefined, 2);
                    } catch (e) {
                        value = args.props.value;
                    }

                    return (
                        <Field name={args.props.name}
                               label={args.props.info.label}
                               isReadOnly>
                            <div style={{ maxWidth: "100%", whiteSpace: "pre-wrap" }}>
                                {value}
                            </div>
                        </Field>
                    );
                }
            },
            [BackgroundJobEntity.Parameters]: {
                render: (args: IFieldDefFn) => {
                    let value: string;

                    try {
                        value = JSON.stringify(JSON.parse(args.props.value), undefined, 2);
                    } catch (e) {
                        value = args.props.value;
                    }

                    return (
                        <Field name={args.props.name}
                               label={args.props.info.label}
                               isReadOnly>
                            <div style={{ maxWidth: "100%", whiteSpace: "pre-wrap" }}>
                                {value}
                            </div>
                        </Field>
                    );
                }
            }
        },
        groups: [
            {
                id: "basic",
                rows: [
                    [
                        { id: BackgroundJobEntity.DateStarted },
                        { id: BackgroundJobEntity.DateLastModified }
                    ],
                    [
                        { id: BackgroundJobEntity.Type },
                        { id: BackgroundJobEntity.Status }
                    ],
                    [
                        { id: BackgroundJobEntity.Result }
                    ],
                    [
                        { id: BackgroundJobEntity.Parameters }
                    ]
                ]
            }

        ]
    };

    return {
        entitySet: EntitySetName.BackgroundJobs,
        table,
        form
    };
};

getDefinitions.translationFiles = ["Common"];
setDefByEntityType(EntityTypeName.BackgroundJob, getDefinitions);