import { REST_API_URL } from "../../constants";
import { IAppContext } from "../../contexts/appContext/AppContext.types";
import FileStorage from "../../utils/FileStorage";
import { memoizedWithCacheStrategy } from "@utils/CacheCleaner";
import { OData, ODataQueryBuilder } from "@odata/OData";
import {
    DocumentEntity,
    ElectronicSubmissionEntity,
    EntitySetName,
    IDocumentEntity,
    IElectronicSubmissionEntity,
    InternalDocumentEntity,
    OdataActionName,
    ODataActionPath
} from "@odata/GeneratedEntityTypes";
import customFetch, { getDefaultPostParams } from "../../utils/customFetch";
import { ElectronicSubmissionTypeCode, VatStatementFrequencyCode } from "@odata/GeneratedEnums";
import { VatSubmissionType } from "./VatSubmission.utils";
import { createVatStatementPeriod, getDayjsUnit, VatStatementPeriod } from "../companies/Company.utils";
import { saveAs } from "file-saver";
import { parseResponse } from "@odata/ODataParser";
import { getUtcDate, getUtcDayjs } from "../../types/Date";
import { formatDateToDateString } from "@components/inputs/date/utils";
import { IEntity } from "@odata/BindingContext";
import { IDateRangeParam } from "../reports/Report.utils";

import { isODataError } from "@odata/Data.types";

export const ElectronicSubmissionNamespaces = [
    "Common", "Audit", "ElectronicSubmission"
];

export function isSubmissionLocked(submission: IElectronicSubmissionEntity): boolean {
    // no IsLocked property enymore, any existing submission is considered locked
    return !!submission?.Id;
}

interface IGetSubmissionFileUrl {
    context: IAppContext;
    type: VatSubmissionType;
    submission: IElectronicSubmissionEntity;
    generateType: "xml" | "epo";
}

export function getSubmissionFileUrl(args: IGetSubmissionFileUrl): string {
    let endpoint: string;
    switch (args.type) {
        case VatSubmissionType.VatControlStatement:
            endpoint = "VatControlStatement";
            break;
        case VatSubmissionType.VatStatement:
            endpoint = "VatStatement";
            break;
        case VatSubmissionType.VatVIESStatement:
            endpoint = "VatVIESStatement";
            break;
    }
    const ident = args.submission?.Id;
    return `${REST_API_URL}/${endpoint}/${args.generateType === "xml" ? "GenerateXml" : "GenerateEpoUrl"}/${ident}?CompanyId=${args.context.getCompany().Id}`;
}

export async function downloadSubmissionFile(args: IGetSubmissionFileUrl, fileName?: string): Promise<void> {
    const url = getSubmissionFileUrl(args);

    const file = await FileStorage.get(null, { url, fileName: fileName ? `${fileName}.xml` : null });

    if (file) {
        saveAs(file);
    }
}

export async function getLastSubmissionByType(oData: OData, type?: ElectronicSubmissionTypeCode): Promise<IElectronicSubmissionEntity> {
    try {
        const filters = [];

        if (type) {
            filters.push(`ElectronicSubmissionTypeCode eq '${ElectronicSubmissionTypeCode.VatStatement}'`);
        }

        const query = oData.getEntitySetWrapper(EntitySetName.ElectronicSubmissions).query()
            .filter(filters.join(" AND "))
            .orderBy("DatePeriodStart", false);

        const res = await query.fetchData<IElectronicSubmissionEntity[]>();

        return res?.value?.[0];
    } catch (e) {
        return null;
    }
}

interface IGetElectronicSubmissionArgs {
    oData: OData;
    year?: number;
}

export async function getElectronicSubmissions({
                                                   oData,
                                                   year
                                               }: IGetElectronicSubmissionArgs): Promise<IElectronicSubmissionEntity[]> {
    let query = oData.getEntitySetWrapper(EntitySetName.ElectronicSubmissions).query()
        .select(
            ElectronicSubmissionEntity.Id, ElectronicSubmissionEntity.DatePeriodStart, ElectronicSubmissionEntity.DatePeriodEnd,
            ElectronicSubmissionEntity.DateSubmission, ElectronicSubmissionEntity.ElectronicSubmissionTypeCode, ElectronicSubmissionEntity.VatLiability
        );

    query = expandElectronicSubmissionEntityForVat(query);

    if (year) {
        query.filter(`${ElectronicSubmissionEntity.DatePeriodStart} qe '${year}-01-01' AND ${ElectronicSubmissionEntity.DatePeriodStart} le '${year}-12-31'`);
    }

    const res = await query.fetchData<IElectronicSubmissionEntity[]>();

    return res?.value;
}

export const getElectronicSubmissionsMemoized = memoizedWithCacheStrategy<IGetElectronicSubmissionArgs, IElectronicSubmissionEntity[]>(getElectronicSubmissions, ({ year }) => {
    return `${year ?? "all"}`;
});

export function getSubmissionIdBy(type: ElectronicSubmissionTypeCode, date: Date): string {
    return `${type}-${date.getFullYear()}-${date.getMonth()}`;
}

export function getSubmissionId(entity: IElectronicSubmissionEntity): string {
    const date = getUtcDate(entity.DatePeriodStart);
    return getSubmissionIdBy(entity.ElectronicSubmissionTypeCode as ElectronicSubmissionTypeCode, date);
}

export function getVatStatementFrequencyFromSubmission(submission: IElectronicSubmissionEntity): VatStatementFrequencyCode {
    const periodLength = getUtcDayjs(submission.DatePeriodEnd).add(1, "day").diff(submission.DatePeriodStart, "months");
    return periodLength === 3 ? VatStatementFrequencyCode.Quarterly : VatStatementFrequencyCode.Monthly;
}

export function getVatStatementFrequencyFromDateRange(dateRange: IDateRangeParam): VatStatementFrequencyCode {
    const periodLength = getUtcDayjs(dateRange.DateEnd).add(1, "day").diff(dateRange.DateStart, "months");
    return periodLength === 3 ? VatStatementFrequencyCode.Quarterly : VatStatementFrequencyCode.Monthly;
}


function expandElectronicSubmissionEntityForVat(query: ODataQueryBuilder): ODataQueryBuilder {
    return query
        .expand(ElectronicSubmissionEntity.VatClearingDocument, (q) => q.select(InternalDocumentEntity.Id, InternalDocumentEntity.NumberOurs))
        .expand(ElectronicSubmissionEntity.VatStatementDocument, (q) => q.select(DocumentEntity.Id, DocumentEntity.NumberOurs, DocumentEntity.Amount, DocumentEntity.CurrencyCode, DocumentEntity.DocumentTypeCode));
}

export async function lockSubmission(oData: OData, submission: IElectronicSubmissionEntity, IsLocked = true): Promise<IElectronicSubmissionEntity> {
    const wrapper = oData.getEntitySetWrapper(EntitySetName.ElectronicSubmissions);

    const action = ODataActionPath[IsLocked ? OdataActionName.ElectronicSubmissionsLock : OdataActionName.ElectronicSubmissionUnlock];
    const query = expandElectronicSubmissionEntityForVat(wrapper.query(submission.Id, action));

    const result = await query.fetchData<IElectronicSubmissionEntity>();
    const updatedSubmission = result.value;
    // if BE adds removing the submission entity, it won't return the updated entity most likely
    // -> return the original one with the updated IsLocked flag, so we know which entity was unlocked and
    // for which we can process the handlers
    return updatedSubmission?.Id ? updatedSubmission : { ...submission, Id: null };
}

export function getVatStatementPeriod(forDate: Date, frequency: VatStatementFrequencyCode): VatStatementPeriod {
    const unit = getDayjsUnit(frequency);
    const periodDate = getUtcDayjs(forDate);
    return createVatStatementPeriod(periodDate, unit);
}

export async function createLockedVatStatementSubmission(context: IAppContext, oData: OData, date: Date, freq: VatStatementFrequencyCode): Promise<IElectronicSubmissionEntity> {
    const period = getVatStatementPeriod(date, freq);
    const data: Partial<Record<ElectronicSubmissionEntity, string | IEntity>> = {
        DatePeriodStart: formatDateToDateString(period.from),
        DatePeriodEnd: formatDateToDateString(period.to),
        ElectronicSubmissionTypeCode: ElectronicSubmissionTypeCode.VatStatement,
        Company: { Id: context.getCompanyId() }
    };
    const wrapper = oData.getEntitySetWrapper(EntitySetName.ElectronicSubmissions);
    const query = expandElectronicSubmissionEntityForVat(wrapper.query(null, ODataActionPath[OdataActionName.ElectronicSubmissionsLock]));

    const res = await query.fetchData<IElectronicSubmissionEntity>(null, null, data);
    return res.value;
}

export async function createVatSubmissionDocuments(submission: IElectronicSubmissionEntity, amount: number): Promise<IDocumentEntity[]> {
    const url = `${REST_API_URL}/ElectronicSubmissions/CreateVatDocuments/${submission.Id}`;
    const res = await customFetch(url, {
        ...getDefaultPostParams(),
        body: JSON.stringify({
            CustomVatLiability: amount
        })
    });
    const body = await parseResponse<IDocumentEntity[]>(res);
    if (isODataError(body)) {
        throw body;
    }
    return body;
}

export async function removeVatSubmissionDocuments(submission: IElectronicSubmissionEntity): Promise<boolean> {
    const url = `${REST_API_URL}/ElectronicSubmissions/RemoveVatDocuments/${submission.Id}`;
    const res = await customFetch(url, {
        ...getDefaultPostParams(),
        body: JSON.stringify({})
    });
    return res.ok;
}