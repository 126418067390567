import { getRouteByDocumentType } from "@odata/EntityTypes";
import { IFieldInfo } from "@odata/FieldInfo.utils";
import {
    CountryEntity,
    EntitySetName,
    IAddressEntity,
    ICompanyBankAccountEntity,
    IDocumentEntity,
    IEntityLabelEntity,
    ILabelHierarchyEntity,
    IPaymentDocumentItemEntity,
    IVatEntity,
    VatEntity
} from "@odata/GeneratedEntityTypes";
import { CountryCode, DocumentTypeCode, VatCode, VatReverseChargeCode } from "@odata/GeneratedEnums";
import { IFormatOptions } from "@odata/OData.utils";
import { isEuRule, isEuRuleExceptAgendaDomestic, isVatRegisteredEntity } from "@pages/admin/vatRules/VatRules.utils";
import { TEntityWithAccount } from "@pages/banks/bankAccounts/BankAccounts.utils";
import {
    getAccountUsageAcrossCharts,
    IAccountUsage,
    isAccountUsedCheck
} from "@pages/chartOfAccounts/ChartOfAccounts.utils";
import { getCompanyUsedCurrencyCodes } from "@pages/companies/Company.utils";
import { getVatItemsDecisiveDateName, isReceivedBc } from "@pages/documents/Document.utils";
import { TFieldDefinition, TFieldsDefinition } from "@pages/PageUtils";
import { getCompanyCurrency, isVatRegisteredCompany } from "@utils/CompanyUtils";
import { isDefined, isNotDefined } from "@utils/general";
import { removeWhiteSpace } from "@utils/string";
import i18next from "i18next";
import React from "react";
import { ValidationError } from "yup";

import {
    BankAccountType,
    BasicInputSizes,
    CacheStrategy,
    FastEntryInputSizes,
    FieldType,
    GroupedField,
    LabelStatus,
    NavigationSource,
    TextAlign,
    ValidatorType
} from "../../enums";
import { TRecordAny, TRecordType, TValue } from "../../global.types";
import { Model } from "../../model/Model";
import { PHONE_REG, POSTAL_CODE_REG } from "../../model/Validator.types";
import BindingContext, { IEntity } from "../../odata/BindingContext";
import { getUtcDayjs } from "../../types/Date";
import NumberType from "../../types/Number";
import memoize from "../../utils/memoize";
import { FormStorage, IFormStorageDefaultCustomData } from "../../views/formView/FormStorage";
import Clickable from "../clickable";
import { getIntentLink } from "../drillDown/DrillDown.utils";
import { IFormatterFns } from "../inputs/input/WithFormatter";
import { GroupDividerSize, ISelectItem } from "../inputs/select/BasicSelect";
import { ICellValueObject, TCellValue, TId } from "../table";
import Text from "../text";
import { tableTokenFormatter } from "../token";
import { getInfoValue, IFieldDef, IFieldInfoProperties, IGetValueArgs, isFieldReadOnly, not } from "./FieldInfo";
import { getCollapsedGroupId } from "./Smart.utils";
import { IFilterDef, TFilterDef } from "./smartFilterBar/SmartFilterBar.types";
import { TFormatterFn } from "./smartTable/SmartTable.utils";

export const isNewEntity = (args: IGetValueArgs): boolean => {
    return args.storage.data.bindingContext?.isNew();
};

export const AccountDefFormatter = (val: TValue, args?: IFormatOptions): string => {
    const entity = args.item ?? {};
    return entity.Number ? `${entity.Number ?? ""} - ${entity.Name ?? ""}` : entity.Name;
};

export const getAccountDef = (path?: string): IFieldInfoProperties => {
    const obj: IFieldInfoProperties = {
        type: FieldType.HierarchyComboBox,
        cacheStrategy: CacheStrategy.View,
        fieldSettings: {
            displayName: "Number",
            localDependentFields: [
                {
                    from: { id: "Number" }, to: { id: "Number" },
                    navigateFrom: NavigationSource.Itself
                },
                {
                    from: { id: "Name" }, to: { id: "Name" },
                    navigateFrom: NavigationSource.Itself
                }
            ]
        },
        columns: [{
            id: "Number",
            additionalProperties: [{ id: "/Name" }, { id: "/IsClosed" }],
            formatter: AccountDefFormatter
        }],
        additionalProperties: [{ id: "Name" }]
    };

    if (path) {
        obj.additionalProperties.push({
            id: path
        });
    }

    return obj;
};

export const CurrencyDef: IFieldInfoProperties = {
    type: FieldType.ComboBox,
    cacheStrategy: CacheStrategy.Route,
    fieldSettings: {
        displayName: "Code",
        shouldDisplayAdditionalColumns: true,
        entitySet: EntitySetName.Currencies
    },
    filter: {
        select: ({ context }) => {
            const usedByCompany = getCompanyUsedCurrencyCodes(context);
            return `Code in (${usedByCompany.map((code: string) => `'${code}'`).join(", ")})`;
        }
    },
    defaultValue: (args: IGetValueArgs) => {
        return getCompanyCurrency(args.context);
    },
    columns: [{ id: "Code" }, { id: "Name" }],
    additionalProperties: [{
        id: "MinorUnit"
    }, {
        id: "Name"
    }]
};

export const SingleBusinessPartnerDef: IFieldInfoProperties = {
    type: FieldType.BusinessPartnerSelect,
    fieldSettings: {
        allowCreate: true,
        groups: [{
            id: "aresCZ",
            title: "ARES",
            dividerSize: GroupDividerSize.Tiny
        }, {
            id: "aresSK",
            title: "ARES.SK",
            dividerSize: GroupDividerSize.Tiny
        }]
    }
};

export const getLabelsDef = (translationFile: string): IFieldInfoProperties => ({
    label: i18next.t(`${translationFile}:Form.Labels`),
    type: FieldType.LabelSelect,
    width: BasicInputSizes.XL,
    defaultValue: [],
    fieldSettings: {
        keyPath: "Label/Id",
        localDependentFields: [{
            from: { id: "LabelHierarchy/Id" },
            to: { id: "LabelHierarchy/Id" }
        }]
    },
    additionalProperties: [{ id: "Label" }, { id: "LabelHierarchy" }]
});

export async function extractVirtualColumnsDef(def: IFieldDef, storage: Model): Promise<IEntity[]> {
    const columnBc = storage.data.bindingContext.navigate(def.id);
    const entitySet = columnBc.getEntitySet().getName();
    const keyPropertyName = columnBc.getKeyPropertyName();
    const { displayName } = def.fieldSettings;
    const query = storage.oData.getEntitySetWrapper(entitySet as EntitySetName)
        .query()
        .filter(getInfoValue(def.filter, "select", { context: storage.context }))
        .select(displayName, keyPropertyName)
        .orderBy(displayName);
    const result = await query.fetchData<IEntity[]>();

    return result?.value;
}

export const extractVirtualColumnsDefMemoized = memoize(extractVirtualColumnsDef,
    (def: IFieldDef): string => {
        return def.id; // todo: better resolver...
    });

const LabelsTableColumnDefinitionId = "Labels/LabelHierarchy";
export const LabelsTableColumnDef: TFieldsDefinition = {
    [LabelsTableColumnDefinitionId]: {
        factory: async (storage): Promise<IFieldDef[]> => {
            const labelHierarchies: ILabelHierarchyEntity[] = await extractVirtualColumnsDefMemoized({
                id: LabelsTableColumnDefinitionId,
                fieldSettings: { displayName: "Name" }
            }, storage);

            const tableColumnDef = {
                fieldSettings: {
                    disableSort: true,
                    displayName: "Name"
                },
                textAlign: TextAlign.Left,
                additionalProperties: [{ id: "/Labels/Label/Name" }, { id: "Color" }],
                formatter: (val: TValue, args: IFormatOptions): TCellValue => {
                    const { entity, info } = args;
                    const { key } = BindingContext.parseKey(info.id, true);

                    const item = entity.Labels.find((documentLabel: IEntityLabelEntity) => documentLabel.LabelHierarchy?.Id === key);

                    return tableTokenFormatter(item?.Label?.Name, item?.LabelHierarchy?.Color);
                }
            };
            const columnBc = storage.data.bindingContext.navigate(LabelsTableColumnDefinitionId);

            return labelHierarchies.map(hierarchy => {
                const columnId = columnBc.addKey(hierarchy).getNavigationPath();
                return {
                    ...tableColumnDef,
                    id: columnId,
                    label: hierarchy.Name
                };
            });
        }
    }
};

/**
 * Returns filter definition
 * @param basePaths
 */
export const getLabelsFilterDef = (...basePaths: string[]): TRecordType<TFilterDef> => {
    const filterProp = "Label";
    const filterPaths = basePaths.map(basePath => `${basePath}/${filterProp}`);
    const basePath = basePaths[0];

    return {
        [filterPaths[0]]: {
            factory: async (storage, filterId): Promise<(IFilterDef & IFieldInfo)[]> => {
                let labelHierarchies: ILabelHierarchyEntity[] = await extractVirtualColumnsDefMemoized({
                    id: `${basePath}/LabelHierarchy`,
                    fieldSettings: {
                        displayName: "Name"
                    }
                }, storage);

                let hierarchyId: number;
                const columnBc = storage.data.bindingContext.navigate(basePath);

                if (filterId) {
                    // filterId is specified -> return only the filter, which corresponds with the Id
                    const result = BindingContext.parseKey(filterId, true);
                    hierarchyId = result?.key as number;
                    labelHierarchies = labelHierarchies.filter(hierarchy => hierarchy.Id === hierarchyId);
                }

                return labelHierarchies
                    .map((hierarchy) => {
                        const bindingContext = columnBc.addKey(hierarchy).navigate(filterProp);
                        const id = bindingContext.getNavigationPath(false);
                        return {
                            id,
                            bindingContext,
                            filterName: filterPaths.map(path => `/${path}/Name`),
                            type: FieldType.LabelSelect,
                            label: hierarchy.Name,
                            isValueHelp: false,
                            textAlign: TextAlign.Left,
                            additionalProperties: [{ id: "/Labels/Label/Name" }, { id: "Color" }],
                            fieldSettings: {
                                entitySet: `LabelHierarchies(${hierarchy.Id})/Labels`,
                                displayName: "Name",
                                // todo this in combination with filterName helps to show correct label in read only filter bar
                                // but sometimes when you add/remove label hierarchy from filters, filter query is not built correctly
                                idName: "Name",
                                dontSetCurrentValueFromEntity: true,
                                oneItemPerHierarchy: false
                            },
                            // generated filter flag -> uses keys in path
                            factory: true,
                            shouldNotFilterCollection: true,
                            filter: {
                                select: hierarchyId ? `Id eq ${hierarchyId}` : "",
                                nullFilterMeansEmptyCollectionFilter: true
                            }
                        };
                    });
            }
        }
    };
};


export const vatEntityRateFormatter = (vat: TValue | IVatEntity): string => {
    const vatEntity = vat as IVatEntity;
    return vatRateFormatter(typeof (vat) === "number" ? vat : vatEntity?.Rate) as string;
};

export const vatRateFormatter: TFormatterFn = function(value) {
    return isDefined(value) ? `${value} %` : i18next.t("Common:Select.NoVat") as string;
};

export function getValidVats(storage: FormStorage, docType: DocumentTypeCode, date?: Date): IVatEntity[] {
    const allVats = (storage.context.getData().custom?.Vats ?? []) as IVatEntity[];
    const countryCode = storage.context.getCompany().Accounting.CountryCode;

    // https://solitea-cz.atlassian.net/browse/DEV-26594
    const docTypesWithAlmostAllVats = [DocumentTypeCode.CorrectiveInvoiceReceived, DocumentTypeCode.CorrectiveInvoiceIssued];

    const currentDate = date ?? storage.getValueByPath(getVatItemsDecisiveDateName(storage)) ?? getUtcDayjs();
    let fromDate = currentDate;

    if (docTypesWithAlmostAllVats.includes(docType)) {
        // we need to display valid vats in last 3 years
        fromDate = getUtcDayjs(currentDate).subtract(3, "years").startOf("year");
    }

    return allVats.filter((vat) => vat.CountryCode === countryCode
        && (!vat.DateValidFrom || getUtcDayjs(vat.DateValidFrom).isSameOrBefore(currentDate, "day"))
        && (!vat.DateValidTo || getUtcDayjs(vat.DateValidTo).isSameOrAfter(fromDate, "day"))
    );
}

// if we access i18next, we have to use callback fn instead of static variable
export const getDocumentItemVatDef: (docType: DocumentTypeCode) => Omit<IFieldDef, "id"> = (docType: DocumentTypeCode) => {
    return {
        type: FieldType.ComboBox,
        cacheStrategy: CacheStrategy.View,
        fieldSettings: {
            displayName: VatEntity.Rate,
            itemsFactory: async (args: IGetValueArgs): Promise<ISelectItem[]> => {
                const correspondingVats = getValidVats(args.storage as FormStorage, docType);

                const items: ISelectItem[] = correspondingVats.map((vat: IVatEntity): ISelectItem => {
                    const label = vatEntityRateFormatter(vat) as string;

                    return {
                        label: label,
                        id: vat.Code,
                        // since we're building the item by our selves,
                        // we need to add additionalProperties as well, otherwise Rate won't get propagated properly
                        additionalData: {
                            Code: vat.Code,
                            Rate: vat.Rate,
                            DateValidFrom: vat.DateValidFrom,
                            DateValidTo: vat.DateValidTo
                        }
                    };
                });

                items.unshift({
                    id: null,
                    label: i18next.t("Common:Select.NoVat")
                });

                return items;
            }
        },
        formatter: (val, args) => {
            const value = args.item?.Rate ?? val;
            return vatEntityRateFormatter(value ?? 0);
        },
        additionalProperties: [{
            id: "Rate"
        }],
        defaultValue: (args) => {
            const isReceived = isReceivedBc(args.storage.data.bindingContext);
            const vatRule = (args.storage.data.entity as IDocumentEntity).VatClassificationSelection?.VatClassification;
            const _isEuRuleExceptAgendaDomestic = isEuRuleExceptAgendaDomestic(args);
            const _isVatRegistered = isVatRegisteredEntity(args);
            if (isReceived && !_isVatRegistered && isEuRule(args)) {
                return null; // Received document from non-payer has no Vat
            }
            if (isReceived && _isEuRuleExceptAgendaDomestic && !vatRule?.IsInAutomatedVatInclusionMode) {
                return null; // DEV-13419 - received documents in automatedVatInclusionMode
            }
            if (!isReceived && _isEuRuleExceptAgendaDomestic && vatRule && vatRule.VatReverseChargeCode !== VatReverseChargeCode.Ne) {
                return null; // DEV-13415 - issued documents in reverseCharge
            }

            if (!isVatRegisteredCompany(args.storage.context)) {
                return null; // Company is not VAT registered
            }

            return {
                Code: VatCode.CzStd2013,
                // TODO it would be better to retrieve additionalProperties for defaultValue from backend
                Rate: 21
            };
        },
        width: FastEntryInputSizes.S
    };
};

export const countryDef: IFieldInfoProperties = {
    type: FieldType.ComboBox,
    cacheStrategy: CacheStrategy.EndOfTime,
    defaultValue: (args: IGetValueArgs) => {
        const context = args?.context || args?.storage?.context;
        const currentCompany = context?.getCompany();
        return currentCompany?.Accounting?.CountryCode ?? CountryCode.CzechRepublic;
    },
    columns: [
        {
            id: "Name",
            additionalProperties: [
                { id: CountryEntity.IsEuMember }
            ]
        },
        { id: "Code" }
    ],
    fieldSettings: {
        displayName: "Name",
        shouldDisplayAdditionalColumns: true,
        preloadItems: true
    }
};

export const getAddressFields = (name: string, args: Partial<IFieldInfoProperties> = {}): Record<string, IFieldInfoProperties> => {
    return {
        [`${name}/Street`]: {
            width: BasicInputSizes.L,
            ...args
        },
        [`${name}/City`]: {
            groupedField: GroupedField.MultiStart,
            width: BasicInputSizes.M,
            ...args
        },
        [`${name}/PostalCode`]: {
            ...getPostalCodeDef(),
            groupedField: GroupedField.MultiEnd,
            ...args
        },
        [`${name}/Country`]: {
            ...countryDef,
            affectedFields: [
                { id: "PostalCode", navigateFromParent: true, revalidate: true }
            ],
            ...args
        }
    };
};

function getNumberValidator(length: number) {
    return {
        type: ValidatorType.String,
        settings: {
            numberString: true,
            max: length
        }
    };
}

export const SymbolConstantDef: IFieldInfoProperties = {
    fieldSettings: {
        parser: (val: string) => {
            const value = removeWhiteSpace(val);
            // strip / pad with leading zeros (if it contains just numbers) as it's the only valid case
            return /^[0-9]+$/.test(value) ? parseInt(value).toString().padStart(4, "0") : value;
        }
    },
    validator: getNumberValidator(10)
};

export const SymbolVariableDef: IFieldInfoProperties = {
    validator: getNumberValidator(10),
    fieldSettings: {
        parser: (val: string) => {
            return val?.trim();
        }
    }
};

export const SymbolSpecificDef: IFieldInfoProperties = {
    validator: getNumberValidator(10),
    fieldSettings: {
        parser: (val: string) => {
            return val?.trim();
        }
    }
};

export const getSavedAccountsGroups = (): IFieldDef[] => {
    return [
        { id: BankAccountType.Account },
        { id: BankAccountType.ABA },
        { id: BankAccountType.IBANOnly },
        { id: BankAccountType.Service }
    ];
};

export const getSavedAccountsDef = (): IFieldInfoProperties => {
    return {
        type: FieldType.ComboBox,
        fieldSettings: {
            items: [],
            groups: getSavedAccountsGroups()
        },
        comparisonFunction: (entity: IDocumentEntity, origEntity: IDocumentEntity) => {
            return origEntity.BankAccount?.BankCode === entity.BankAccount?.BankCode &&
                    origEntity.BankAccount?.AccountNumber === entity.BankAccount?.AccountNumber;
        }
    };
};


// Todo: we may default the prefix / enhance formatting according to country
// Todo: consider DB format as +420.123456789, so we can distinguish country prefix
export const phoneNumberInputFormatter: IFormatterFns<string>["formatter"] = (value: string): string => {
    if (!value || !PHONE_REG.test(value)) {
        return value;
    }

    const match = value.match(/^(\+(42[01]|[0-9]{1,2}))?([0-9]+)$/);
    const countryCode = match[1];
    const number = match[3];
    const parts: string[] = number.match(/.{1,3}/g) || [];
    parts.unshift(countryCode ?? "+420");
    return parts.join(" ");
};

export const phoneNumberFormatter: TFormatterFn = (val: TValue, args?: IFormatOptions) => {
    return phoneNumberInputFormatter(val as string);
};

export const phoneNumberParser: IFormatterFns<string>["parser"] = (value: string) => {
    // replace leading zeroes to +
    value = value.replace(/^00/, "+");
    // we just remove whitespaces and dash characters, other characters
    // should correct user manually if present @see Validator.ts -> PHONE_REG
    return value.replaceAll(/[-\s]+/g, "");
};

export const phoneNumberIsValid: IFormatterFns<string>["isValid"] = (value: string): boolean => {
    return PHONE_REG.test(value);
};

export const PhoneNumberDef: IFieldInfoProperties = {
    validator: {
        type: ValidatorType.Phone
    },
    fieldSettings: {
        formatter: phoneNumberInputFormatter,
        parser: phoneNumberParser,
        isValid: phoneNumberIsValid
    }
};

export const postalCodeFormatter = (value: string, countryCode: CountryCode): string => {
    if (!value) {
        // cant return null, breaks WithFormatter => always return empty string instead
        return "";
    }

    if (countryCode !== CountryCode.CzechRepublic) {
        return value;
    }

    // add 0 prefix in case postal code is stored without leading zero
    const postalCode = value?.length === 4 ? `0${value}` : value;

    return `${postalCode.slice(0, 3)} ${postalCode.slice(3)}`;
};

export const hasOptionalPostalCode = (args: IGetValueArgs): boolean => {
    const address = args.storage.getValue(args.bindingContext.getParent()) as IAddressEntity;

    return ![CountryCode.CzechRepublic, CountryCode.Slovakia].includes(address?.Country?.Code as CountryCode);
};

const postalCodeValidator = (value: TValue, args: IGetValueArgs): boolean => {
    if (hasOptionalPostalCode(args)) {
        return true;
    }

    return !value || POSTAL_CODE_REG.test(removeWhiteSpace(value as string));
};

export const getPostalCodeDef: () => TFieldDefinition = () => ({
    width: BasicInputSizes.S,
    validator: {
        type: ValidatorType.Custom,
        settings: {
            customValidator: postalCodeValidator,
            message: i18next.t("Common:Validation.PostalCode")
        }
    },
    isRequired: not(hasOptionalPostalCode),
    formatter: (val, args) => {
        const address = args.storage.getValue(args.bindingContext.getParent()) as IAddressEntity;

        return postalCodeFormatter(val as string, address?.Country?.Code as CountryCode);
    },
    parser: (val) => {
        return removeWhiteSpace(val);
    },
    isValid: postalCodeValidator,
    fieldSettings: {
        placeholder: (args) => {
            const address = args.storage.getValue(args.bindingContext.getParent()) as IAddressEntity;

            return !address?.Country?.Code || address.Country.Code === CountryCode.CzechRepublic ? "123 45" : "";
        }
    }
});

export const withDisplayName = <T extends IFieldInfoProperties | TFilterDef>(id: string, displayNameOrDef?: string | T, fieldDef?: T): Record<string, T extends IFieldDef ? IFieldDef : TFilterDef & {
    id: string
}> => {
    let displayName = "Name";
    if (typeof displayNameOrDef === "string") {
        displayName = displayNameOrDef;
    } else if (isNotDefined(fieldDef)) {
        fieldDef = displayNameOrDef;
    }
    return {
        [id]: {
            id,
            ...fieldDef,
            fieldSettings: {
                ...fieldDef?.fieldSettings,
                displayName
            }
        }
    };
};

/** Columns that should be available in almost all tables */
export const getCommonTableColumnsDefs = (): TFieldsDefinition => {
    return {
        DateCreated: {
            label: i18next.t("Common:General.Created")
        },
        DateLastModified: {},
        ...withDisplayName("CreatedBy"),
        ...withDisplayName("LastModifiedBy")
    };
};

/** Filters that should be available in almost all tables */
export const getCommonFilterDefs = (def?: TFilterDef): Record<string, TFilterDef> => ({
    DateCreated: { ...def },
    DateLastModified: { ...def },
    ...withDisplayName("CreatedBy", { isValueHelp: true, ...def }),
    ...withDisplayName("LastModifiedBy", { isValueHelp: true, ...def })
});

export const AttachmentsDef = {
    collection: "Attachments",
    property: "File"
};

export const AttachmentsAdditionalProperties: IFieldDef[] = [
    {
        id: "Attachments",
        additionalProperties: [
            { id: "File/Name" },
            { id: "File/Size" },
            { id: "File/DateCreated" },
            { id: "File/DateLastModified" },
            { id: "File/IsIsdocReadable" },
            { id: "File/IsRossumReadable" },
            { id: "Description" }
        ]
    }
];

export const getLinks = (args: IFormatOptions, items: TRecordAny[]) => {
    let text = "";
    let fragment: React.ReactElement;
    const filteredItems = items?.filter(item => !!item.LinkedDocument?.NumberOurs);
    if (filteredItems?.length > 0) {
        fragment =
                <>
                    {filteredItems.map((postingItem: IPaymentDocumentItemEntity, i: number) => {
                        if (postingItem.LinkedDocument?.Id) {
                            const route = getRouteByDocumentType(postingItem.LinkedDocument.DocumentTypeCode as DocumentTypeCode);
                            const to = `${route}/${postingItem.LinkedDocument.Id}`;
                            const intentLink = getIntentLink(postingItem.LinkedDocument.NumberOurs, {
                                route: to,
                                context: args.storage.context,
                                storage: args.storage
                            });
                            const isLast = filteredItems.length - 1 === i;
                            text = postingItem.LinkedDocument.NumberOurs;
                            if (!isLast) {
                                text += " ,";
                            }

                            return (
                                    <React.Fragment key={postingItem.LinkedDocument?.Id}>
                                        {intentLink}
                                        {isLast ? "" : " ,"}
                                    </React.Fragment>
                            );
                        }

                        return null;
                    })}
                </>;
    }

    return {
        fragment: fragment ?? "",
        text
    };
};

export const DocumentLinks = {
    additionalProperties: [
        { id: "LinkedDocument/NumberOurs" },
        { id: "LinkedDocument/DocumentTypeCode" }
    ],
    formatter: (val: TValue, args?: IFormatOptions): TCellValue => {
        const code = getLinks(args, args.entity.Items);

        return {
            tooltip: code.text,
            value: code.fragment
        };
    }
};


const balanceSheetLayoutAccountValidator = (value: TValue, args: IGetValueArgs) => {
    const pattern = /^\d+$/;
    const isNumber = pattern.test(value as string);

    if (!value) {
        return new ValidationError(i18next.t("Banks:Validation.Required"), false, args.bindingContext.getPath(true));
    }

    if (!isNumber) {
        return new ValidationError(i18next.t("Banks:Validation.Balance"), false, args.bindingContext.getPath(true));
    }

    return true;
};

/** Custom data related to payment entities (CashBox, BankAccount) */
export interface IPaymentRelatedEntityCustomData extends IFormStorageDefaultCustomData {
    /** Usage data for the selected account in current CoA*/
    accountUsage?: IAccountUsage;
    // todo used in multiple places for isReadOnly check,
    // could be removed, because backend sends correct value in disabledProperties metadata, but field would change from readOnly to disabled
    /** Either CashReceipt or BankTransactions exists for this entity */
    hasRelatedEntity?: boolean;
    /* we need to be able to access all bank accounts in AccountsRelatedToBankAccountsSelectPath definition
       so that we can filter out accounts already used in some bank account
       => store in PARENT_DATA_KEY custom data */
    parentData?: TEntityWithAccount[];
}

export function isBalanceSheetLayoutAccountUsed({ storage }: IGetValueArgs): boolean {
    const usage = (storage as FormStorage<unknown, IPaymentRelatedEntityCustomData>).getCustomData().accountUsage;

    return !storage.data.bindingContext.isNew() && isAccountUsedCheck(usage);
}

export async function balanceSheetLayoutAccountOnAfterLoad(storage: FormStorage, accNumber: number): Promise<void> {
    let usage: IAccountUsage = null;

    const bankAccount = storage.data.entity as ICompanyBankAccountEntity;
    if (!storage.data.bindingContext.isNew() && bankAccount?.BalanceSheetAccountNumberSuffix) {
        const accountNumber = `${accNumber}${bankAccount.BalanceSheetAccountNumberSuffix}`;
        usage = await getAccountUsageAcrossCharts(accountNumber);
    }

    (storage as FormStorage<unknown, IPaymentRelatedEntityCustomData>).setCustomData({ accountUsage: usage });
}

export const AccountSettingsAccountsSelectPath = BindingContext.localContext("AccountSettingsAccountsSelect");
export const getBalanceSheetLayoutAccount = (accNumber: number): TFieldDefinition => {
    return {
        label: i18next.t("Document:Form.AnalyticalAccount"),
        width: "130px",
        isRequired: true,
        groupedField: GroupedField.MultiStart,
        validator: {
            type: ValidatorType.Custom,
            settings: {
                customValidator: balanceSheetLayoutAccountValidator
            }
        },
        extraFieldContent: (args) => {
            const { bindingContext, storage, info } = args;
            const isReadOnly = isFieldReadOnly({
                id: bindingContext.getPath(true),
                bindingContext, ...info
            }, storage, bindingContext);
            const styles = isReadOnly ? {} : { paddingLeft: "12px" };
            const groupStatus = (args.storage as FormStorage).getGroupStatus(getCollapsedGroupId({ id: AccountSettingsAccountsSelectPath }));
            return (
                    <Text isLight={!groupStatus?.isCreating} style={styles}>
                        {accNumber}
                    </Text>);
        }
    };
};

export const fourDigitsFormatter = (val: TValue): string => {
    return NumberType.format(val as number, {
        minimumFractionDigits: 4,
        maximumFractionDigits: 4
    });
};

export const ExchangeRatePerUnit = {
    defaultValue: (args: IGetValueArgs): number => {
        const document = args.storage.getEntity<IDocumentEntity>();
        return document.TransactionCurrencyCode === getCompanyCurrency(args.context) ? 1 : null;
    },
    textAlign: TextAlign.Right,
    validator: {
        type: ValidatorType.Number,
        settings: {
            min: 0,
            excludeMin: true
        }
    },
    formatter(val: TValue, args: IFormatOptions): string {
        return fourDigitsFormatter(val);
    },
    isVisible: (args: IGetValueArgs): boolean => {
        const code = args.storage.data?.entity?.TransactionCurrency?.Code;
        return !!(code && code !== getCompanyCurrency(args.context));
    }
};


export const NoteFieldDef = {
    isConfirmable: true,
    type: FieldType.EditableText,
    labelStatus: LabelStatus.Removed
};

export const BirthNumberFormatter: TFormatterFn = (val: TValue, args?: IFormatOptions) => {
    const birthNumber = val as string;

    if (!birthNumber || birthNumber.length < 9) {
        return birthNumber;
    }

    const index = 6;

    return birthNumber.substring(0, index) + "/" + birthNumber.substring(index);
};

export const BirthNumberFieldDef: TFieldDefinition = {
    // do we want to implement birth number check function?
    // isValid
    parser: (val: string, args?: IFormatOptions) => {
        return val.replace("/", "");
    },
    formatter: BirthNumberFormatter
};

export function emailFormatter(value: TValue, opts?: IFormatOptions): ICellValueObject {
    const { name } = opts?.customArgs ?? {};
    return value ? {
        tooltip: `${value}`,
        value: (
                <Clickable link={`mailto:${value}`} isLink><strong>{name ?? value}</strong></Clickable>
        )
    } : null;
}

/**
 * form-based isReadOnly flag - system entities (with negative IDs => id < 0) are readOnly
 */
export const isSystemEntityId = (id: TId): boolean => id < 0;
export const isSystemEntity = (args: IGetValueArgs): boolean => isSystemEntityId(args.data?.Id);