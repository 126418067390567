import { DefaultTheme } from "styled-components/macro";
import { EntitySetName, EntityTypeName, ICompanyEntity } from "./GeneratedEntityTypes";
import {
    ROUTE_BANK_STATEMENTS,
    ROUTE_BANK_TRANSACTIONS,
    ROUTE_BUSINESS_PARTNER,
    ROUTE_CASH_RECEIPTS_ISSUED,
    ROUTE_CASH_RECEIPTS_RECEIVED,
    ROUTE_CORRECTIVE_INVOICE_ISSUED,
    ROUTE_CORRECTIVE_INVOICE_RECEIVED,
    ROUTE_ELECTRONIC_SUBMISSION,
    ROUTE_FISCAL_YEAR,
    ROUTE_FIXED_ASSET,
    ROUTE_INTERNAL_DOCUMENT,
    ROUTE_INVOICE_ISSUED,
    ROUTE_INVOICE_RECEIVED,
    ROUTE_MINOR_ASSET,
    ROUTE_OTHER_LIABILITY,
    ROUTE_OTHER_RECEIVABLE,
    ROUTE_PROFORMA_INVOICE_ISSUED,
    ROUTE_PROFORMA_INVOICE_RECEIVED,
    ROUTE_USERS
} from "../routes";
import BindingContext from "./BindingContext";
import { DocumentTypeCode, ElectronicSubmissionTypeCode } from "./GeneratedEnums";
import { TValue } from "../global.types";

export interface ICompany extends ICompanyEntity {
    ColorCode?: keyof DefaultTheme;
    CanAccessCustomerPortal?: boolean;
}

export const getEntitySetByDocumentType = (documentType: DocumentTypeCode): EntitySetName => {
    switch (documentType) {
        case DocumentTypeCode.InvoiceReceived:
            return EntitySetName.InvoicesReceived;
        case DocumentTypeCode.InvoiceIssued:
            return EntitySetName.InvoicesIssued;
        case DocumentTypeCode.ProformaInvoiceReceived:
            return EntitySetName.ProformaInvoicesReceived;
        case DocumentTypeCode.ProformaInvoiceIssued:
            return EntitySetName.ProformaInvoicesIssued;
        case DocumentTypeCode.InternalDocument:
            return EntitySetName.InternalDocuments;
        case DocumentTypeCode.OtherLiability:
            return EntitySetName.OtherLiabilities;
        case DocumentTypeCode.OtherReceivable:
            return EntitySetName.OtherReceivables;
        case DocumentTypeCode.CorrectiveInvoiceIssued:
            return EntitySetName.CorrectiveInvoicesIssued;
        case DocumentTypeCode.CorrectiveInvoiceReceived:
            return EntitySetName.CorrectiveInvoicesReceived;
        case DocumentTypeCode.BankTransaction:
            return EntitySetName.BankTransactions;
        case DocumentTypeCode.CashReceiptReceived:
            return EntitySetName.CashReceiptsReceived;
        case DocumentTypeCode.CashReceiptIssued:
            return EntitySetName.CashReceiptsIssued;
        default:
            // not every DocumentType has entitySet
            return null;
    }
};

export const getRouteByDocumentType = (documentType: DocumentTypeCode): string => {
    switch (documentType) {
        case DocumentTypeCode.InvoiceReceived:
            return ROUTE_INVOICE_RECEIVED;
        case DocumentTypeCode.InvoiceIssued:
            return ROUTE_INVOICE_ISSUED;
        case DocumentTypeCode.ProformaInvoiceReceived:
            return ROUTE_PROFORMA_INVOICE_RECEIVED;
        case DocumentTypeCode.ProformaInvoiceIssued:
            return ROUTE_PROFORMA_INVOICE_ISSUED;
        case DocumentTypeCode.InternalDocument:
            return ROUTE_INTERNAL_DOCUMENT;
        case DocumentTypeCode.OtherLiability:
            return ROUTE_OTHER_LIABILITY;
        case DocumentTypeCode.OtherReceivable:
            return ROUTE_OTHER_RECEIVABLE;
        case DocumentTypeCode.CorrectiveInvoiceReceived:
            return ROUTE_CORRECTIVE_INVOICE_RECEIVED;
        case DocumentTypeCode.CorrectiveInvoiceIssued:
            return ROUTE_CORRECTIVE_INVOICE_ISSUED;
        case DocumentTypeCode.BankTransaction:
            return ROUTE_BANK_TRANSACTIONS;
        case DocumentTypeCode.CashReceiptReceived:
            return ROUTE_CASH_RECEIPTS_RECEIVED;
        case DocumentTypeCode.CashReceiptIssued:
            return ROUTE_CASH_RECEIPTS_ISSUED;
    }
};

export const getDocumentTypeCodeFromEntityType = (entityType: EntityTypeName): DocumentTypeCode => {
    switch (entityType) {
        case EntityTypeName.InternalDocument:
            return DocumentTypeCode.InternalDocument;
        case EntityTypeName.OtherReceivable:
            return DocumentTypeCode.OtherReceivable;
        case EntityTypeName.OtherLiability:
            return DocumentTypeCode.OtherLiability;
        case EntityTypeName.ProformaInvoiceIssued:
            return DocumentTypeCode.ProformaInvoiceIssued;
        case EntityTypeName.ProformaInvoiceReceived:
            return DocumentTypeCode.ProformaInvoiceReceived;
        case EntityTypeName.InvoiceIssued:
            return DocumentTypeCode.InvoiceIssued;
        case EntityTypeName.InvoiceReceived:
            return DocumentTypeCode.InvoiceReceived;
        case EntityTypeName.CorrectiveInvoiceIssued:
            return DocumentTypeCode.CorrectiveInvoiceIssued;
        case EntityTypeName.CorrectiveInvoiceReceived:
            return DocumentTypeCode.CorrectiveInvoiceReceived;
        case EntityTypeName.BankTransaction:
            return DocumentTypeCode.BankTransaction;
        case EntityTypeName.CashReceiptReceived:
            return DocumentTypeCode.CashReceiptReceived;
        case EntityTypeName.CashReceiptIssued:
            return DocumentTypeCode.CashReceiptIssued;

        default:
            return null;
    }
};

export const getEntityTypeFromDocumentType = (docType: DocumentTypeCode): EntityTypeName => {
    switch (docType) {
        case DocumentTypeCode.InternalDocument:
            return EntityTypeName.InternalDocument;
        case DocumentTypeCode.OtherReceivable:
            return EntityTypeName.OtherReceivable;
        case DocumentTypeCode.OtherLiability:
            return EntityTypeName.OtherLiability;
        case DocumentTypeCode.ProformaInvoiceIssued:
            return EntityTypeName.ProformaInvoiceIssued;
        case DocumentTypeCode.ProformaInvoiceReceived:
            return EntityTypeName.ProformaInvoiceReceived;
        case DocumentTypeCode.InvoiceIssued:
            return EntityTypeName.InvoiceIssued;
        case DocumentTypeCode.InvoiceReceived:
            return EntityTypeName.InvoiceReceived;
        case DocumentTypeCode.CorrectiveInvoiceIssued:
            return EntityTypeName.CorrectiveInvoiceIssued;
        case DocumentTypeCode.CorrectiveInvoiceReceived:
            return EntityTypeName.CorrectiveInvoiceReceived;
        case DocumentTypeCode.BankTransaction:
            return EntityTypeName.BankTransaction;
        case DocumentTypeCode.CashReceiptReceived:
            return EntityTypeName.CashReceiptReceived;
        case DocumentTypeCode.CashReceiptIssued:
            return EntityTypeName.CashReceiptIssued;

        default:
            return null;
    }
};

// add more entity types if needed
export const getRouteByEntityType = (entityType: EntityTypeName): string => {
    const docType = getDocumentTypeCodeFromEntityType(entityType);

    if (docType) {
        return getRouteByDocumentType(docType);
    } else {
        switch (entityType) {
            case EntityTypeName.Asset:
                return ROUTE_FIXED_ASSET;
            case EntityTypeName.MinorAsset:
                return ROUTE_MINOR_ASSET;
            case EntityTypeName.BankStatement:
                return ROUTE_BANK_STATEMENTS;
            case EntityTypeName.BankTransaction:
                return ROUTE_BANK_TRANSACTIONS;
            case EntityTypeName.BusinessPartner:
                return ROUTE_BUSINESS_PARTNER;
            case EntityTypeName.FiscalYear:
                return ROUTE_FISCAL_YEAR;
            case EntityTypeName.User:
                return ROUTE_USERS;
            default:
                return null;
        }
    }
};

export const getDetailRouteByEntityType = (entityType: EntityTypeName, id: TValue, readOnly?: boolean): string => {
    switch (entityType) {
        // todo: CorrectiveInvoiceIssued, CorrectiveInvoiceReceived
        default:
            const route = getRouteByEntityType(entityType as EntityTypeName);
            return route && `${route}/${id}${readOnly ? "?viewMode=readonly" : ""}`;
    }
};

export function getEntityTypeFromBindingContext(bindingContext: BindingContext): EntityTypeName {
    return bindingContext.getEntityType().getName() as EntityTypeName;
}

export function getSubmissionDetailRoute(year: number, type?: ElectronicSubmissionTypeCode, month?: number): string {
    if (type) {
        return `${ROUTE_ELECTRONIC_SUBMISSION}/${year}/${type}/${month}`;
    } else {
        return `${ROUTE_ELECTRONIC_SUBMISSION}/${year}`;
    }
}

