import React, { useCallback, useContext, useMemo } from "react";
import { ICompanyEntity } from "@odata/GeneratedEntityTypes";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { getCompanyLogoUrl } from "@utils/CompanyUtils";
import { CompanyStateCode } from "@odata/GeneratedEnums";
import { AuthContext } from "../../../contexts/authContext/AuthContext";

export interface WithCompanySelector {
    companies: ICompanyEntity[];
    company: ICompanyEntity;
    logo: string;
    handleCompanyChange: (id: number) => void;
}

interface IWithCompanySelectorOpts {
    isCustomerPortal?: boolean;
    showOnlyInitialised?: boolean;
}

export const withCompanySelector = ({
                                        isCustomerPortal,
                                        showOnlyInitialised
                                    }: IWithCompanySelectorOpts) => <P extends WithCompanySelector>(Component: React.ComponentType<P>): React.ComponentType<Omit<P, keyof WithCompanySelector>> => {
    return React.forwardRef((props: Omit<P, keyof WithCompanySelector>, ref) => {

        const context = useContext(AppContext);
        const authContext = useContext(AuthContext);
        const companies = context.getData().companies.filter(c => (!isCustomerPortal || !!c.CanAccessCustomerPortal) && (!showOnlyInitialised || c.StateCode === CompanyStateCode.Initialized));
        const companyId = context.getCompanyId() ?? authContext.companyId;
        const company = companies.find(c => c.Id === companyId);
        const logoUrl = useMemo(() => getCompanyLogoUrl(company), [company]);

        const handleCompanyChange = useCallback((id: number) => {
            if (id !== company.Id) {
                // company has changed
                context.setCurrentCompanyId(id, !!isCustomerPortal);
            }
            // otherwise no action
        }, [company, context]);

        return (
            <Component company={company}
                       companies={companies}
                       logo={logoUrl}
                       handleCompanyChange={handleCompanyChange}
                       ref={ref}
                // cast props as per https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046
                       {...props as P}/>
        );
    }) as any;
};