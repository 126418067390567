import { IDefinition, IGetDefinition } from "@pages/PageUtils";
import { setDefForTesting } from "@pages/getDefByEntityType";
import { EntitySetName } from "@odata/GeneratedEntityTypes";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { Sort } from "../../../../enums";
import { getDocumentStatusColumnDefinition } from "../../DocumentCommonDefs";
import { ISplitPageTableDef } from "../../../../views/table/TableView.utils";
import { DocumentStatusFilterNames, getDocumentStatusFilterDefinition } from "../../DocumentDef";
import { IAppContext } from "../../../../contexts/appContext/AppContext.types";
import { DocumentStatusLocalPath, StatusEntities } from "@pages/reports/CommonDefs";

export const getDefinitions: IGetDefinition = (context: IAppContext, isExpense: boolean): IDefinition => {
    const entitySet = EntitySetName.InternalDocuments;

    const table: ISplitPageTableDef = {
        filterBarDef: [{
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            isValueHelp: true,
            defaultFilters: [
                "NumberOurs",
                ...DocumentStatusFilterNames,
                "DateAccountingTransaction",
                "Amount",
            ],
            filterDefinition: {
                NumberOurs: {},
                ...getDocumentStatusFilterDefinition(StatusEntities),
                DateAccountingTransaction: {},
                Amount: {}
            }
        }],
        id: "accrualsPairingTable",
        columns: [
            "NumberOurs",
            DocumentStatusLocalPath,
            "DateAccountingTransaction",
            "Amount",
            "Explanation"
        ],
        columnDefinition: {
            NumberOurs: {
                additionalProperties: [
                    { id: "/DocumentLinks/TargetDocument" },
                    { id: "/DocumentLinks/TypeCode" }
                ]
            },
            ...getDocumentStatusColumnDefinition(),
            DateAccountingTransaction: {},
            Amount: {},
            Explanation: {},
            DateCreated: {}
        },
        // set dynamically in PairingAccrualsTableView
        title: null,
        initialSortBy: [{ id: "DateCreated", sort: Sort.Desc }]
    };

    return {
        entitySet,
        table
    };
};

getDefinitions.translationFiles = ["Accruals", "Documents", "Common"];
setDefForTesting("AccrualsPairing", getDefinitions);