import styled from "styled-components/macro";
import { getDisabledStyle, multiline_ellipsis, T_TITLE_3, T_TITLE_4_NORMAL } from "../../global.style";
import { TextAlign } from "../../enums";
import { ellipsis } from "polished";

interface IStyledText {
    isBig: boolean;
    maxRows: number;
    _width: string;
    _textAlign: TextAlign;
    isLight: boolean;
    isDisabled: boolean;
    isWithoutDefaultFormatting: boolean;
}

export const StyledText = styled.div<IStyledText>`
    position: relative;
    width: ${props => props._width};
    color: ${props => !props.isLight ? props.theme.C_TEXT_primary : props.theme.C_TEXT_dialogue};
    text-align: ${props => props._textAlign ?? TextAlign.Left};

    ${props => props.maxRows ? multiline_ellipsis(600, props.maxRows) : ellipsis()};
    ${props => props.isBig ? T_TITLE_3 : T_TITLE_4_NORMAL};

    ${props => getDisabledStyle(props.isDisabled)};

    white-space: pre;
    align-self: ${props => props.isWithoutDefaultFormatting ? "auto" : "flex-start"};
    padding-top: ${props => props.isWithoutDefaultFormatting ? "0" : "8px"};
`;

export const InnerTextWrapper = styled.div`
    display: inline;
`;