import { IDefinition, IGetDefinition } from "@pages/PageUtils";
import { setDefForTesting } from "@pages/getDefByEntityType";
import {
    EntitySetName,
    ProformaInvoiceIssuedEntity,
    ProformaInvoiceReceivedEntity
} from "@odata/GeneratedEntityTypes";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { withDisplayName } from "@components/smart/GeneralFieldDefinition";
import { ISplitPageTableDef } from "../../../../views/table/TableView.utils";
import i18next from "i18next";
import { FieldType, Sort } from "../../../../enums";
import BindingContext from "../../../../odata/BindingContext";
import { DocumentLinkTypeCode, DocumentTypeCode } from "@odata/GeneratedEnums";
import { DocumentStatusFilterNames, getDocumentStatusFilterDefinition, getUsedStatuses } from "../../DocumentDef";
import { IAppContext } from "../../../../contexts/appContext/AppContext.types";
import { DocumentStatusLocalPath } from "@pages/reports/CommonDefs";
import {
    getDefinitions as getProformaInvoicesReceivedDef
} from "../../proformaInvoices/received/ProformaInvoicesReceivedDef";
import {
    getDefinitions as getProformaInvoicesIssuedDef
} from "../../proformaInvoices/issued/ProformaInvoicesIssuedDef";
import { isCashBasisAccountingCompany, isVatRegisteredCompany } from "@utils/CompanyUtils";

export const NOT_PAIRED_PROFORMAS_LOCAL_FILTER_PATH = BindingContext.localContext("NOT_PAIRED_PROFORMAS");

export const getDefinitions: IGetDefinition = (context: IAppContext, isIssuedType: boolean): IDefinition => {

    const entitySet = isIssuedType ? EntitySetName.ProformaInvoicesIssued : EntitySetName.ProformaInvoicesReceived;
    const baseDef = isIssuedType ? getProformaInvoicesIssuedDef(context) : getProformaInvoicesReceivedDef(context);
    const ProformaEntity = isIssuedType ? ProformaInvoiceIssuedEntity : ProformaInvoiceReceivedEntity;
    const isVatPayer = isVatRegisteredCompany(context);
    const isCbaCompany = isCashBasisAccountingCompany(context);

    const table: ISplitPageTableDef = {
        filterBarDef: [{
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            isValueHelp: true,
            defaultFilters: [
                ProformaEntity.NumberOurs,
                isCbaCompany ? ProformaEntity.DateCbaDocument : ProformaEntity.DateAccountingTransaction,
                ProformaEntity.BusinessPartner,
                ProformaEntity.Amount,
                ...DocumentStatusFilterNames,
                ProformaEntity.TransactionCurrency,
                NOT_PAIRED_PROFORMAS_LOCAL_FILTER_PATH
            ],
            filterDefinition: {
                ...(baseDef.table.filterBarDef[0].filterDefinition),
                ...getDocumentStatusFilterDefinition(getUsedStatuses(context, DocumentTypeCode.ProformaInvoiceIssued)),
                DateAccountingTransaction: {},
                DateCbaDocument: {},
                ...withDisplayName("TransactionCurrency"),
                // Filters only cleared proforma invoices without relation to any document or
                //  if proforma id is listed in the filter value, which is used to add exception for proformas linked with current document
                [NOT_PAIRED_PROFORMAS_LOCAL_FILTER_PATH]: {
                    id: NOT_PAIRED_PROFORMAS_LOCAL_FILTER_PATH,
                    // there is no field visible, it's only used as predefined filter value
                    type: FieldType.Custom,
                    render: null,
                    isValueHelp: false,
                    formatter: () => i18next.t(`Proforma:Pairing.${isVatPayer ? "NotPairedProformasAndDDOPP" : "NotPairedProformas"}`),
                    label: i18next.t("Proforma:Pairing.FilterLabel"),
                    filter: {
                        buildFilter: (item, settings) => {
                            let filter = `OppositeDocumentLinks/all(link: not(link/TypeCode in ('${DocumentLinkTypeCode.ProformaInvoiceDeduction}')))`;
                            if (item.value && item.value !== true) {
                                filter = `((${filter}) OR Id in (${item.value}))`;
                            }

                            return filter;
                        }
                    },
                }
            }
        }],
        id: `${entitySet}PairingTable`,
        preventStoreVariant: true,
        columns: [
            ProformaEntity.NumberOurs,
            DocumentStatusLocalPath,
            ProformaEntity.BusinessPartner,
            isCbaCompany ? ProformaEntity.DateCbaDocument : ProformaEntity.DateAccountingTransaction,
            ProformaEntity.Amount
        ],
        additionalProperties: [
            // we need this for document link creating when pairing with new invoice
            { id: "TransactionAmount" },
            { id: "TransactionAmountNet" },
            { id: "TransactionAmountVat" },
            { id: "TransactionCurrency" }
        ],
        columnDefinition: {
            ...(baseDef.table.columnDefinition),
            DateCbaDocument: {},
            NumberOurs: {
                additionalProperties: [
                    { id: "/OppositeDocumentLinks/SourceDocument" },
                    { id: "/OppositeDocumentLinks/TypeCode" }
                ]
            }
        },
        title: i18next.t("Proforma:Pairing.Title"),
        initialSortBy: [{ id: "DateCreated", sort: Sort.Desc }]
    };

    return {
        entitySet,
        table
    };
};

getDefinitions.translationFiles = ["Proforma", "Documents", "Common"];
setDefForTesting("ProformaPairing", getDefinitions);