import React from "react";
import { IBaseSelectionChangeArgs, ISelectItem, TSelectItemId } from "../select/BasicSelect";
import { ValueType } from "../../../enums";
import { IMultiSelectionChangeArgs, IMultiSelectProps, MultiSelect } from "../select/MultiSelect";
import { IComplexFilter, isComplexFilterArr } from "../../conditionalFilterDialog/ConditionalFilterDialog.utils";
import { WithTranslation, withTranslation } from "react-i18next";

export interface IValueHelperChangeArgs extends IBaseSelectionChangeArgs, Pick<IMultiSelectionChangeArgs, "selectedItems"> {
    value: IComplexFilter[] | TSelectItemId[];
}

interface IProps extends Omit<IMultiSelectProps, "value" | "onChange">, WithTranslation {
    value: IComplexFilter[] | TSelectItemId[];
    onChange: (args: IValueHelperChangeArgs) => void;

    items: ISelectItem[];
    isLoadingItems?: boolean;

    type: ValueType;
}

class ValueHelper extends React.PureComponent<IProps> {
    render = () => {
        const { value = [], onChange, ...restProps } = this.props;

        const { type } = this.props;
        const isMultiSelectValue = !isComplexFilterArr(value);
        const multiSelectValues = (isMultiSelectValue ? value : []) as string[];

        const multiSelectProps: IMultiSelectProps = {
            ...restProps,
            value: multiSelectValues,
            onChange: this.props.onChange,
            showClearSelection: true,
            searchType: type,
            onIconActivate: this.props.onIconActivate
        };

        return (
            <MultiSelect {...multiSelectProps} isLoading={this.props.isLoadingItems}/>
        );
    };
}

export default withTranslation(["Components"])(ValueHelper);