import React, { ReactElement } from "react";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";
import {
    ConditionType,
    createFilterRow
} from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import {
    ClearedStatusCode,
    CompanyPermissionCode,
    DocumentTypeCode,
    PostedStatusCode,
    SelectionCode
} from "@odata/GeneratedEnums";
import {
    IPairTableViewBaseProps,
    IPaymentDocumentItemEntityExpanded,
    PairTableViewBaseForExtend
} from "../../banks/PairTableViewBase";
import { FormStorage } from "../../../views/formView/FormStorage";
import { TEntityKey } from "@evala/odata-metadata/src";
import {
    EntityTypeName,
    IInternalDocumentEntity,
    IInternalDocumentItemEntity,
    IPayingDocumentEntity,
    IPaymentDocumentItemEntity,
    PayingDocumentEntity
} from "@odata/GeneratedEntityTypes";
import BindingContext, { IEntity } from "../../../odata/BindingContext";
import { getNewItemsMaxId, setNestedValue } from "@odata/Data.utils";
import i18next from "i18next";
import { AmountDialog } from "../../banks/PairTableAmountDialog";
import { TId } from "@components/table";
import { getRow } from "@components/smart/smartTable/SmartTable.utils";
import { getCorrectExchangeRate, getCorrectTranAmount } from "../../banks/Pair.utils";
import { isObjectEmpty, roundToDecimalPlaces } from "@utils/general";
import { SAVED_VATS_PATH } from "../../admin/vatRules/VatRules.utils";
import { docOnlyAmountPaidFormatter } from "../../banks/bankTransactions/BankTransactionDocumentPairDef";
import { IBankCustomData } from "../../banks/bankTransactions/BankTransactions.utils";
import { DocumentStatusLocalPath, getStatusFilterId } from "../../reports/CommonDefs";
import { getUtcDate } from "../../../types/Date";

class InternalDocumentPairTableView extends PairTableViewBaseForExtend {
    constructor(props: IPairTableViewBaseProps) {
        super(props);

        this.presetDefaultFilters = this.presetDefaultFilters.bind(this);

        this.presetDefaultFilters();
    }

    getAmountPaidFormatterFn = () => {
        return docOnlyAmountPaidFormatter;
    };

    setOriginItems(): void {
        const { rootStorage, storage } = this.props;
        const entity: IInternalDocumentEntity = rootStorage.data.entity;
        const pairedDocuments: Record<TEntityKey, IPaymentDocumentItemEntityExpanded> = {};

        for (const item of (entity.Items || [])) {
            if (item.LinkedDocument?.Id) {
                const key = item.LinkedDocument.Id;
                pairedDocuments[key] = {
                    ...pairedDocuments[key],
                    Description: item.Description,
                    LinkedDocument: item.LinkedDocument,
                    Amount: item.Amount,
                    Id: item.Id,
                    TransactionAmount: item.TransactionAmount,
                    AccountAssignmentSelection: item.AccountAssignmentSelection
                };
            }
        }

        this.origPairedDocuments = { ...pairedDocuments };

        storage.setCustomData({
            rootStorage,
            pairedDocuments
        });

        (rootStorage as FormStorage<unknown, IBankCustomData>).setCustomData({
            pairedStorage: storage
        });
    }

    savePairChanges = async (storage: FormStorage, pairedDocs: Record<TEntityKey, IPaymentDocumentItemEntityExpanded>): Promise<void> => {
        const entity = storage.data.entity as IInternalDocumentEntity;
        if (!entity.Items) {
            entity.Items = [];
        }

        const items = entity.Items.filter((item: IEntity) => !item.LinkedDocument?.Id);
        const newItems = [];

        let maxId = getNewItemsMaxId(entity.Items) + 1;

        for (const [key, link] of Object.entries(pairedDocs)) {
            let item = entity.Items.find((item) => item.LinkedDocument?.Id?.toString() === key);
            let isNew = false;

            if (!item) {
                isNew = true;
                item = BindingContext.createNewEntity(maxId++, link);
            }

            item.Description = item.Description ?? this.props.rootStorage.t("InternalDocument:Form.DocumentClearing");
            item.Amount = link.Amount;
            item.TransactionAmount = link.TransactionAmount;
            item.TransactionCurrencyCode = (link as IInternalDocumentItemEntity).LinkedDocument.TransactionCurrencyCode;


            item.LinkedDocument = {
                ...item.LinkedDocument,
                ExchangeRatePerUnit: (link as IInternalDocumentItemEntity).LinkedDocument.ExchangeRatePerUnit
            };

            delete (item as IPaymentDocumentItemEntity).ExchangeRate;

            if (isObjectEmpty(item.AccountAssignmentSelection)) {
                item.AccountAssignmentSelection = {
                    Selection: {
                        Code: SelectionCode.Default
                    },
                    AccountAssignment: {
                        ...this.props.rootStorage.getValueByPath("AccountAssignmentSelection/AccountAssignment"),
                        Id: SelectionCode.Default as unknown as number
                    }
                };
            }


            if (isObjectEmpty(item.LabelSelection)) {
                item.LabelSelection = {
                    SelectionCode: SelectionCode.Default
                };
            }

            setNestedValue(SelectionCode.Default, SAVED_VATS_PATH, item);
            isNew ? newItems.push(item) : items.push(item);
        }

        storage.data.entity.Items = [...items, ...newItems];
        storage.handleFirstChange();
    };

    presetDefaultFilters() {
        const storage = this.props.storage;

        storage.setFilterValueByPath(DocumentStatusLocalPath, [
            createFilterRow({
                type: ConditionType.Excluded,
                value: [
                    getStatusFilterId(EntityTypeName.ClearedStatus, ClearedStatusCode.Cleared)
                ]
            }),
            createFilterRow({
                type: ConditionType.Excluded,
                value: [
                    getStatusFilterId(EntityTypeName.PostedStatus, PostedStatusCode.NotPosted)
                ]
            })
        ]);

        storage.setFilterValueByPath(PayingDocumentEntity.DocumentType, [createFilterRow({
            value: [DocumentTypeCode.InvoiceReceived, DocumentTypeCode.InvoiceIssued,
                DocumentTypeCode.OtherLiability, DocumentTypeCode.OtherReceivable,
                DocumentTypeCode.CorrectiveInvoiceIssued, DocumentTypeCode.CorrectiveInvoiceReceived]
        })
        ]);

        this.props.storage.applyFilters();
    }

    canRowBePaired = (rowId: TId) => {
        const row = getRow(this.props.storage.tableAPI.getState().rows, rowId);
        const isPaired = !!this.origPairedDocuments?.[(rowId as BindingContext)?.getKey?.()];

        if (!isPaired && row?.customData) {
            const entity: IPayingDocumentEntity = row.customData.entity as IPayingDocumentEntity;
            const isNotPosted = entity.PostedStatusCode === PostedStatusCode.NotPosted;
            const isNotCleared = entity.ClearedStatusCode !== ClearedStatusCode.Cleared;

            return !isNotPosted && isNotCleared;
        }

        return true;
    };

    get secondaryTitle(): string {
        return i18next.t("InternalDocument:Form.ClearedDocument");
    }

    get successSubtitle(): string {
        return i18next.t("InternalDocument:Form.Paired");
    }

    get addMenuConfiguration() {
        return [
            { id: "InvoicesReceived", permission: CompanyPermissionCode.InvoicesReceived },
            { id: "InvoicesIssued", permission: CompanyPermissionCode.InvoicesIssued },
            { id: "OtherLiabilities", permission: CompanyPermissionCode.Reports },
            { id: "OtherReceivables", permission: CompanyPermissionCode.InvoicesReceived },
            { id: "CorrectiveInvoicesReceived", permission: CompanyPermissionCode.InvoicesReceived },
            { id: "CorrectiveInvoicesIssued", permission: CompanyPermissionCode.InvoicesIssued }
        ];
    }

    handleRowPairClick = async (rowId: TId): Promise<void> => {
        const bc = rowId as BindingContext;
        const key = bc.getKey();
        const pairedDoc = this.getPairedDocuments();

        if (pairedDoc[key]) {
            delete pairedDoc[key];
        } else {
            const type = this.props.rootStorage.oData.metadata.getTypeForPath(this.getRowEntitySet(bc));
            const row = getRow(this.props.storage.tableAPI.getState().rows, bc);
            const document = row?.customData?.entity;
            const numberOurs = document.NumberOurs;

            const docCurrency = document?.TransactionCurrency?.Code;
            const docAmount = getCorrectTranAmount(this.props.rootStorage, document.Id, document.TransactionAmountDue, docCurrency);
            const docDate = document?.DateAccountingTransaction;

            const docExchangeRate = await getCorrectExchangeRate({
                id: document?.Id,
                date: docDate ?? getUtcDate(),
                currency: docCurrency
            }, this.props.storage.context);

            const amount = roundToDecimalPlaces(2, docAmount * docExchangeRate);
            const transactionAmount = docAmount;

            pairedDoc[key] = {
                Amount: amount,
                TransactionAmount: transactionAmount,
                LinkedDocument: {
                    TransactionAmount: document?.TransactionAmount,
                    ExchangeRatePerUnit: docExchangeRate,
                    TransactionCurrencyCode: docCurrency,
                    DateAccountingTransaction: docDate,
                    TransactionCurrency: {
                        Code: docCurrency
                    },
                    TransactionAmountDue: document?.TransactionAmountDue,
                    Id: parseInt(key as string),
                    NumberOurs: numberOurs as string,
                    DocumentTypeCode: document?.DocumentType.Code,
                    _metadata: {
                        "": { type: `#${type.getFullName()}` }
                    }
                }
            };
        }

        this.props.storage.setCustomData({ pairedDocuments: pairedDoc });

        this.forceUpdate();
        this.rerenderTable();
    };

    // forceUpdate won't re-render table, because no table props has changed
    // call tableApi.forceUpdate directly, to re-render just the table
    rerenderTable = () => {
        this.props.storage.tableAPI.forceUpdate();
    };

    renderAmountDialog = (): ReactElement => {
        return <AmountDialog
            needConfirmDialog={this.needConfirmDialog}
            rootStorage={this.props.rootStorage}
            clearingByID={true}
            onClose={() => {
                this.needConfirmDialog = null;
                this.forceUpdate();
                this.rerenderTable();
            }}
            storage={this.props.storage}/>;
    };
}

export default withPermissionContext(InternalDocumentPairTableView);