import React, { useCallback, useMemo } from "react";
import { withCompanySelector, WithCompanySelector } from "./withCompanySelector";
import TestIds from "../../../testIds";
import { Title } from "../../../global.style";
import Avatar from "../../avatar";
import { AvatarSize, TextAlign } from "../../../enums";
import { CustomerCompanySelectorWrapper } from "./Selector.styles";
import IconSelect from "../../inputs/select/IconSelect";
import { CaretIcon } from "../../icon";
import { companyToSelectItem } from "./CompanySelector";
import { ISelectionChangeArgs } from "../../inputs/select/BasicSelect";
import { addCompanyIdToUrl } from "../../../contexts/appContext/AppContext.utils";
import { ROUTE_HOME } from "../../../routes";
import { useHistory } from "react-router-dom";

interface IProps extends WithCompanySelector {
}

const CustomerCompanySelectorBase: React.FC<IProps> = ({ company, companies, logo, handleCompanyChange }) => {
    const history = useHistory();
    const handleCompanySelect = useCallback((args: ISelectionChangeArgs) => {
        handleCompanyChange(args.value as number);

        if (args.value as number !== company?.Id) {
            history.replace(addCompanyIdToUrl(ROUTE_HOME, args.value as number));
        }
    }, [handleCompanyChange]);

    const items = useMemo(() => companies.map(companyToSelectItem), [companies]);

    if (!company) {
        return null;
    }
    const columns = [{ textAlign: TextAlign.Left }, { textAlign: TextAlign.Right }];

    return (
            <CustomerCompanySelectorWrapper data-testid={TestIds.CustomerCompanySelector}>
                <Avatar src={logo}
                        size={AvatarSize.almostM}
                        isRound/>
                <Title data-testid={TestIds.HeaderTitle}>
                    {company.Name}
                </Title>
                {items.length > 1 && (
                        <IconSelect items={items}
                                    hotspotId={"CustomerCompanySelectorItems"}
                                    value={company.Id}
                                    columns={columns}
                                    icon={<CaretIcon/>}
                                    iconButtonProps={{
                                        isLight: false,
                                        isTransparent: true
                                    }}
                                    onChange={handleCompanySelect}
                                    showSearchBoxInMenu={false}/>
                )}
            </CustomerCompanySelectorWrapper>
    );
};

const extendedCustomerCompanySelector = withCompanySelector({
    isCustomerPortal: true,
    showOnlyInitialised: true
})(CustomerCompanySelectorBase);
export { extendedCustomerCompanySelector as CustomerCompanySelector };