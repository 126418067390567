import React from "react";
import { BasicSelect, IProps } from "./BasicSelect";


export class Autocomplete extends React.Component<IProps> {
    renderContent = (): React.ReactElement => {
        const sharedProps: IProps = {
            closeOnSelection: true,
            openOnClick: false,
            useAutoselection: true,
            dontDisplayNoDataFound: true,
            allowCreate: true
        };

        return <BasicSelect
            {...this.props}
            {...sharedProps}
            isMultiLine={false}/>;
    };

    render() {
        return this.renderContent();
    }
}
