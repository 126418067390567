import React from "react";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { BodyWrapper } from "./ConditionalFilterDialog.styles";
import { Button } from "../button";
import { WithTranslation, withTranslation } from "react-i18next";
import CollapsibleSection from "../collapsibleSection/CollapsibleSection";
import TestIds from "../../testIds";
import ConditionalFilterRow, { TFilterValueError, TGetCustomValueInput } from "./ConditionalFilterRow";
import { PlusIcon } from "../icon";
import Dialog from "../dialog";
import {
    ConditionType,
    createFilterRow,
    IComplexFilter,
    isInterval,
    IValueInterval,
    PredefinedFilter,
    TFilterValue
} from "./ConditionalFilterDialog.utils";
import { ValueType } from "../../enums";

export interface IProps extends WithTranslation {
    name: string;
    values: IComplexFilter[];
    type: ValueType;
    isEnum?: boolean;
    validator?: (value: TFilterValue) => void;
    getCustomValueInput?: TGetCustomValueInput;

    onCancel: () => void;
    onConfirm: (values: IComplexFilter[]) => void;
}

interface IState {
    rows: IComplexFilter[];
    errors: Map<string, TFilterValueError>;
}

function isFilterValueEmpty(val: TFilterValue) {
    const isValueEmpty = (v: any) => [undefined, null, "", NaN].includes(v);

    if (Array.isArray(val)) {
        return !val?.length || (val as unknown[]).every(isValueEmpty);
    } else if (isInterval(val)) {
        const i = val as IValueInterval;
        return isValueEmpty(i.from) || isValueEmpty(i.to);
    }
    return isValueEmpty(val);
}

export function isFilterEmpty(complexFilter: IComplexFilter): boolean {
    return !complexFilter.filter || (complexFilter.filter === PredefinedFilter.Value && isFilterValueEmpty(complexFilter.value));
}

class ConditionalFilterDialog extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    constructor(props: IProps) {
        super(props);

        const rows = this.prepareRows(props.values, this.props.type);

        this.state = {
            rows,
            errors: new Map()
        };
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        // refresh state
    }

    prepareRows(values: IComplexFilter[], valueType: ValueType): IComplexFilter[] {
        const rows = values.map(v => createFilterRow(v, valueType));

        [ConditionType.Included, ConditionType.Excluded]
            .forEach(type => {
                if (!rows.find((item: IComplexFilter) => item.type === type)) {
                    rows.push(createFilterRow({ type }, valueType));
                }
            });

        return rows;
    }

    handleAdd = (type: ConditionType) => {
        const item = createFilterRow({ type }, this.props.type);

        this.setState({ rows: [...this.state.rows, item] });
    };

    handleRowRemove = (id: string) => {
        // clear possible error for given row
        this.handleValueError(id, null);
        // remove row
        this.setState(({ rows }) => ({ rows: [...rows.filter((val: IComplexFilter) => val.id !== id)] }));
    };

    handleChange = (val: IComplexFilter) => {
        const newRows = [...this.state.rows],
            idx = newRows.findIndex(item => item.id === val.id);

        newRows.splice(idx, 1, val);

        this.setState({
            rows: newRows
        });
    };

    handleClear = () => {
        const rows = this.prepareRows([], this.props.type);
        this.setState({
            rows,
            errors: new Map()
        });
    };

    handleConfirm = () => {
        const filledValues = this.state.rows.filter(item => !isFilterEmpty(item));
        this.props.onConfirm(filledValues);
    };

    handleValueError = (rowId: string, error: TFilterValueError) => {
        if (error || this.state.errors.has(rowId)) {
            this.setState(({ errors }) => {
                if (error) {
                    errors.set(rowId, error);
                } else {
                    errors.delete(rowId);
                }

                return {
                    errors: new Map(errors)
                };
            });
        }
    };

    renderSection(type: ConditionType) {
        const rows = this.state.rows.filter((val: IComplexFilter) => val.type === type);
        const isEnum = this.props.isEnum;

        const commonProps = {
            label: this.props.name,
            type: this.props.type,
            onChange: this.handleChange,
            onRemove: this.handleRowRemove,
            onValueError: this.handleValueError,
            validator: this.props.validator,
            canRemove: rows.length > 1,
            isEnum: isEnum
        };

        const hasUnfilledOrInvalidRow = !!rows.find(row => isFilterEmpty(row) || this.state.errors.has(row.id));

        return (
            <>
                {rows.map((val, idx) =>
                    <ConditionalFilterRow key={val.id}
                                          row={val}
                                          getCustomValueInput={this.props.getCustomValueInput}
                                          error={this.state.errors.get(val.id)}
                                          isLabelVisible={!idx}
                                          {...commonProps}
                    />)
                }
                <Button onClick={this.handleAdd.bind(this, type)}
                        isDisabled={hasUnfilledOrInvalidRow}
                        testid={TestIds.ValueHelperAddButton}
                        icon={<PlusIcon/>}
                        isTransparent>
                    {this.props.t("Common:General.Add")}
                </Button>
            </>
        );
    }

    render() {
        const hasFilledRow = !!this.state.rows.find(row => !isFilterEmpty(row));
        const hasError = !!this.state.errors.size;

        return (
            <Dialog title={this.props.name}
                    onConfirm={hasError ? null : this.handleConfirm}
                    onClose={this.props.onCancel}
                    footer={(
                        <>
                            <Button isTransparent
                                    isDisabled={!hasFilledRow}
                                    onClick={this.handleClear}>
                                {this.props.t("Components:ValueHelper.Clear")}
                            </Button>
                            <Button isTransparent
                                    onClick={this.props.onCancel}>
                                {this.props.t("Components:ValueHelper.Cancel")}
                            </Button>
                            <Button isDisabled={hasError}
                                    onClick={this.handleConfirm}>
                                {this.props.t("Components:ValueHelper.Confirm")}
                            </Button>
                        </>
                    )}
                    testid={TestIds.ConditionalFilterDialog}>
                <BodyWrapper>
                    <CollapsibleSection title={this.props.t("Components:ValueHelper.Include")}>
                        {this.renderSection(ConditionType.Included)}
                    </CollapsibleSection>
                    <CollapsibleSection title={this.props.t("Components:ValueHelper.Exclude")}>
                        {this.renderSection(ConditionType.Excluded)}
                    </CollapsibleSection>
                </BodyWrapper>
            </Dialog>
        );
    }
}

export default withTranslation(["Components"])(ConditionalFilterDialog);
