import React from "react";
import { Header, Info, StyledVersionSelect, Title, VersionIcon } from "./AuditTrail.styles";
import { ISelectionChangeArgs, ISelectItem } from "../inputs/select/BasicSelect";
import { AuditTrailIcon } from "../icon";
import { IconSize, TextAlign } from "../../enums";
import IconSelect from "../inputs/select/IconSelect";
import { WithTranslation, withTranslation } from "react-i18next";

interface IProps extends WithTranslation {
    items: ISelectItem[];
    value: string;
    text: string;
    onChange?: (args: ISelectionChangeArgs) => void;

    className?: string;
}

const Icon = <AuditTrailIcon width={IconSize.M}/>;

class VersionSelect extends React.Component<IProps> {
    render() {
        return (
            <StyledVersionSelect className={this.props.className}>
                <Header>
                    <Title>{this.props.t("Components:VersionSelect.Change")}:</Title>
                    <Info>{this.props.text}</Info>
                </Header>
                <VersionIcon>
                    <IconSelect
                        hotspotId={"auditTrailVersionSelect"}
                        columns={[
                            {
                                textAlign: TextAlign.Right,
                                label: this.props.t("Components:VersionSelect.Date")
                            },
                            {
                                label: this.props.t("Components:VersionSelect.Time")
                            },
                            {
                                label: this.props.t("Components:VersionSelect.Name")
                            },
                            {
                                label: this.props.t("Components:VersionSelect.Type")
                            }
                        ]}
                        icon={Icon}
                        items={this.props.items}
                        value={this.props.value}
                        onChange={this.props.onChange}
                        useAutoselection={false}
                    />
                </VersionIcon>
            </StyledVersionSelect>
        );
    }
}

const versionSelectWithTranslation = withTranslation("Components")(VersionSelect);
export { versionSelectWithTranslation as VersionSelect };