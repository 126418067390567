import React, { ReactElement } from "react";
import { FieldMouseCapture, StyledField, StyledFieldContent } from "./Field.styles";
import { AuditTrailFieldMode, AuditTrailFieldType, IFieldInfoProperties } from "../../smart/FieldInfo";
import { FieldType, LabelStatus, Status, TextAlign } from "../../../enums";
import TestIds from "../../../testIds";
import { IAuditTrailData } from "../../../model/Model";
import { Label } from "./Label";
import { isDefined, uuidv4 } from "@utils/general";
import { HOTSPOT_ID_ATTR } from "../../hotspots/Hotspots.utils";
import { IconButton } from "../../button";
import SyncSymbol from "./SyncSymbol";
import Alert from "../../alert/Alert";
import PopperWrapper from "../../popperWrapper";

export interface IFieldProps extends React.HTMLAttributes<HTMLDivElement> {
    /** Text of label (if visible)*/
    width?: string;
    height?: string;
    label?: string;
    /** Smaller, italic text, next to the label*/
    description?: string;
    labelStatus?: LabelStatus;
    tooltip?: string;
    name: string;
    textAlign?: TextAlign;
    isRequired?: boolean;
    isDisabled?: boolean;
    // adds grey background behind the Field content
    isSynchronized?: boolean;
    isValueHelp?: boolean;

    // for fields used alone, not in form with other fields after them
    // e.g. in small Dialogs
    isWithoutRightMargin?: boolean;

    // by default read only field takes width of its content. With this flag it has the same width as editable field.
    useWidthWhenReadOnly?: boolean;

    /** If field disabled and disabledMessage set, its value will be shown in alert popup */
    disabledMessage?: string;
    isReadOnly?: boolean;
    /** Enforce left padding on content to align it with label.
     * Can be used with arbitrary content, instead of "type" which is utilized by SmartField  */
    hasPadding?: boolean;
    isLight?: boolean;
    isSharpRight?: boolean;
    isSharpLeft?: boolean;
    className?: string;

    type?: FieldType;

    style?: React.CSSProperties;
    auditTrailData?: IAuditTrailData;
}

export const FieldsWithOwnLabel: Record<string, boolean> = {
    [FieldType.Text]: true,
    [FieldType.Switch]: true
};

export const FieldsWithOwnLabelWithSpacePlaceholder: Record<string, boolean> = {
    // todo: switch is aligned well next to standard field and if wrapped (e.g. in NumberRange form ->
    //  description | isActive | isDefault, wrapped on next line), there is no extra space because of hidden label.
    //  same we probably don't want the space when it is alone on a row, e.g. ProformaInvoice "vyčíslit DPH".
    //  Extra space looks weird in this case and according to Zeplin, it should not be there, so lets remove it everywhere.
    [FieldType.Switch]: false
};

export const FieldsWithoutRequired: Record<string, boolean> = {
    ...FieldsWithOwnLabel,
    [FieldType.Checkbox]: true
};

export const FieldsWithPadding: Record<string, boolean> = {
    [FieldType.Checkbox]: true,
    [FieldType.Switch]: true,
    [FieldType.CheckboxGroup]: true,
    [FieldType.RadioButtonGroup]: true,
    [FieldType.Slider]: true
};

/** Set the Fields width directly to the StyledField wrapper */
export const FieldsApplyingWidthToWholeField: Record<string, boolean> = {
    [FieldType.CheckboxGroup]: true
};

/**
 * Fields without minHeight should be these, which are not displayed usually on one line with another fields,
 * because using top margin instead of min-height won't align them vertically with the rest fields
 */
export const FieldsWithoutMinHeight: Record<string, boolean> = {
    [FieldType.Text]: true,
    [FieldType.CheckboxGroup]: true
};

interface IState {
    isHovered: boolean;
    showDisabledMessage: boolean;
}

class Field extends React.PureComponent<IFieldProps, IState> {
    static defaultProps: Partial<IFieldProps> = {
        textAlign: TextAlign.Left
    };

    private _refComparisonLabel = React.createRef<HTMLDivElement>();
    // create unique id to match label with input for accessibility purposes
    id = uuidv4();

    state = {
        isHovered: false,
        showDisabledMessage: false
    };

    _isRequired = (): boolean => {
        return !this.props.isReadOnly && this.props.isRequired && !FieldsWithoutRequired[this.props.type];
    };

    _showLabel = (): boolean => {
        return this.props.labelStatus === LabelStatus.Visible || this.props.isValueHelp ||
            (!FieldsWithOwnLabelWithSpacePlaceholder[this.props.type] && (this.props.label && this.props.labelStatus !== LabelStatus.Removed
                && this.props.labelStatus !== LabelStatus.Hidden));
    };

    _hasOwnLabel = (): boolean => {
        return !this.props.isValueHelp && FieldsWithOwnLabel[this.props.type] && !FieldsWithOwnLabelWithSpacePlaceholder[this.props.type]
            && this.props.labelStatus !== LabelStatus.Visible;
    };

    handleComparisonHover = (isHovered: boolean): void => {
        const isAuditTrail = this.isAuditTrail();
        if (isAuditTrail) {
            this.setState({ isHovered });
        }
    };

    /** Absolutely positioned FieldMouseCapture captures all events.
     * Sometimes we want to pass mouseenter and mouseleave events to field content,
     * e.g. to show Tooltip with tokens on disabled MultiSelect */
    passEventToFieldContent = (event: React.MouseEvent): void => {
        const content = (event.target as HTMLElement).parentElement.querySelector((`[data-testid=${TestIds.FieldContent}]`))?.firstChild;

        if (!content) {
            return;
        }

        const newEvent = new MouseEvent(event.type, {
            ...(event as any)
        });
        content.dispatchEvent(newEvent);
    };

    onMouseEnter = (event: React.MouseEvent): void => {
        if (this.isAuditTrail()) {
            return this.handleComparisonHover(true);
        }

        if (this.shouldShowDisableMessageOnClick()) {
            this.passEventToFieldContent(event);
        }
    };

    onMouseLeave = (event: React.MouseEvent): void => {
        if (this.isAuditTrail()) {
            return this.handleComparisonHover(false);
        }

        if (this.shouldShowDisableMessageOnClick()) {
            this.passEventToFieldContent(event);
        }
    };

    isAuditTrail = (): boolean => {
        const auditTrailMode = (this.props as IFieldInfoProperties).auditTrailMode;
        if (auditTrailMode === AuditTrailFieldMode.DisplayedButIgnored) {
            return false;
        }

        return !!this.props.auditTrailData;
    };

    getAuditTrailDataForField = (useOwnHandler: boolean): IAuditTrailData => {
        if (useOwnHandler && this.props.auditTrailData?.type) {
            let type;
            // even for fields with custom audit trail rendering field still does mouse hover thing, so we don't need to
            // handle in every control and can focus on rendering itself
            // fields basically switches types between (no)difference and (no)hoveredDifference
            if (this.props.auditTrailData.type === AuditTrailFieldType.NoDifference) {
                type = this.state.isHovered ? AuditTrailFieldType.HoveredNoDifference : AuditTrailFieldType.NoDifference;
            } else {
                type = this.state.isHovered ? AuditTrailFieldType.HoveredDifference : AuditTrailFieldType.Difference;
            }

            return {
                ...this.props.auditTrailData,
                type
            };
        }

        return this.props.auditTrailData;
    };

    handleCaptureClick = (): void => {
        this.setState({
            showDisabledMessage: true
        });
    };

    shouldShowDisableMessageOnClick = (): boolean => {
        return !this.props.isReadOnly && this.props.isDisabled && !!this.props.disabledMessage;
    };

    handleAlertFade = (): void => {
        this.setState({
            showDisabledMessage: false
        });
    };

    renderDisabledMessage = (): ReactElement => {
        // do not use oneLiner as field alert. The alert is in popover and if the content don't fit,
        // we are rendering tooltip over floting alert with ellipsis, which looks very strange
        // Alert itself is in Popper, so it should be OK to show whole content to the user.
        return (
            <Alert
                title={this.props.disabledMessage}
                useFade onFadeEnd={this.handleAlertFade}
                status={Status.Warning} isSmall/>
        );
    };

    renderFieldContent = (ref?: React.Ref<any>): React.ReactElement => {
        const type = this.props.type;
        const sharpRight = this.props.isSharpRight ?? (this.props.children as any)?.props?.isSharpRight;
        const sharpLeft = this.props.isSharpLeft ?? (this.props.children as any)?.props?.isSharpLeft;
        const additionalChildProps: Partial<IFieldProps> = {};
        const isAuditTrail = this.isAuditTrail();

        if (isAuditTrail) {
            // only add 'auditTrailData' if needed
            // otherwise forcing it to child as its prop can cause error when div is direct child of Field instead of react component
            additionalChildProps["auditTrailData"] = this.getAuditTrailDataForField(isAuditTrail);
        }

        return (
            <StyledFieldContent
                ref={ref}
                data-testid={TestIds.FieldContent}
                {...{ [HOTSPOT_ID_ATTR]: this.props.name ? `${this.props.name}-fieldContent` : undefined }}
                textAlign={this.props.textAlign}
                hasMinHeight={FieldsWithPadding[type] || (!FieldsWithoutMinHeight[type])}
                hasPadding={isDefined(this.props.hasPadding) ? this.props.hasPadding : (!this.props.isValueHelp && FieldsWithPadding[type] && !this.props.isReadOnly)}
                hasExtraTopMargin={FieldsWithoutMinHeight[type] && !FieldsWithOwnLabel[type]}
                isSharpRight={sharpRight}
                isSharpLeft={sharpLeft}
                isSynchronized={this.props.isSynchronized}>
                <>
                    {this.props.isSynchronized && !this.props.isSharpLeft ? (<SyncSymbol/>) : null}
                    {
                        React.Children.map(this.props.children, (child) => {
                            return child && React.cloneElement(child as React.ReactElement, {
                                width: this.props.isReadOnly && !this.props.useWidthWhenReadOnly ? "auto" : (child as React.ReactElement).props.width,
                                // don't pass id to icon next to the field, causes html error because there are two elements in dom with same id
                                // only relevant to SmartTemporalPropertyDialog pending button
                                id: (child as React.ReactElement).type === IconButton || (child as React.ReactElement)?.props?.id ? null : this.id,
                                ...additionalChildProps
                            });
                        })
                    }
                </>
            </StyledFieldContent>
        );
    };

    render() {
        const type = this.props.type;
        const isAuditTrail = this.isAuditTrail();

        const auditTrailMode = (this.props as IFieldInfoProperties).auditTrailMode;
        if (isAuditTrail && auditTrailMode === AuditTrailFieldMode.Hidden) {
            return null;
        }

        const isAuditTrailIgnored = isAuditTrail && auditTrailMode === AuditTrailFieldMode.DisplayedButIgnored;
        // if sharpRight not specified on Field, try to take a look on the children (Input)
        const sharpRight = this.props.isSharpRight ?? (this.props.children as any)?.props?.isSharpRight;
        const sharpLeft = this.props.isSharpLeft ?? (this.props.children as any)?.props?.isSharpLeft;
        const width = this.props.isReadOnly && !this.props.useWidthWhenReadOnly ? "auto" : this.props.width;

        return (
            <StyledField
                isAuditTrailIgnored={isAuditTrailIgnored}
                data-testid={TestIds.Field}
                data-name={this.props.name}
                // render isReadOnly into html, so that we can target it in css
                // to make different margins between readonly/editable fields
                data-isreadonly={!!this.props.isReadOnly}
                // not every child field has isDisabled as CSS attribute, this would help tests to target such fields
                data-isdisabled={!!this.props.isDisabled}

                // same reason as data-isreadonly
                data-textalign={this.props.textAlign}
                className={this.props.className}
                style={this.props.style}
                isReadOnly={this.props.isReadOnly}
                hasPadding={isDefined(this.props.hasPadding) ? this.props.hasPadding : true}
                isWithoutRightMargin={this.props.isWithoutRightMargin}
                $width={FieldsApplyingWidthToWholeField[type] ? width : null}
                textAlign={this.props.textAlign}
                isSharpRight={sharpRight}
                isSharpLeft={sharpLeft}>
                {(isAuditTrail || this.shouldShowDisableMessageOnClick()) &&
                    <FieldMouseCapture
                        onClick={this.shouldShowDisableMessageOnClick() ? this.handleCaptureClick : null}
                        onMouseEnter={this.onMouseEnter}
                        onMouseLeave={this.onMouseLeave}
                    />
                }
                {!this._hasOwnLabel() && this.props.labelStatus !== LabelStatus.Removed && <>
                    <div ref={this._refComparisonLabel}>
                        <Label
                            for={this.id}
                            description={this.props.description}
                            hotspotId={this.props.name ? `${this.props.name}-fieldLabel` : undefined}
                            auditTrailData={this.getAuditTrailDataForField(isAuditTrail)}
                            isSharpRight={sharpRight}
                            isSharpLeft={sharpLeft}
                            width={width}
                            maxWidth={this.props.isReadOnly && !this.props.useWidthWhenReadOnly ? this.props.width : "auto"}
                            isHidden={!this._showLabel()}
                            isLight={this.props.isLight && this.props.auditTrailData?.type !== AuditTrailFieldType.NoDifference}
                            isRequired={this._isRequired()}
                            isDisabled={this.props.isDisabled && !this.props.isReadOnly}
                            hasPadding={!this.props.isReadOnly}
                            tooltip={this.props.tooltip}
                            textAlign={this.props.textAlign}>
                            {this.props.label}
                        </Label>
                    </div>
                </>
                }
                {!this.state.showDisabledMessage
                    ? this.renderFieldContent()
                    : <PopperWrapper
                        // todo probably could be removed, but E2E tests would have to be changed
                        withoutPortal
                        placement={"bottom"}
                        reference={this.renderFieldContent}
                    >
                        {this.state.showDisabledMessage ? this.renderDisabledMessage() : null}
                    </PopperWrapper>}
            </StyledField>
        );
    }
}

export default Field;