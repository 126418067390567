import React, { memo, useContext, useEffect, useState } from "react";
import {
    getTileData,
    IDashboardTileComponentProps,
    IInfoTileDataCell,
    IInfoTileInfo,
    TDashboardInfoTileFormatter
} from "../../dashboard";
import { WithOData, withOData } from "@odata/withOData";
import {
    ColoredIconStyled,
    Fraction,
    InfoTileBody,
    Label,
    StyledInfoTile,
    StyledInfoTileCell,
    Unit,
    Value,
    ValueWrapper
} from "./InfoTile.styles";
import { getIcon } from "../../icon";
import { IconSize } from "../../../enums";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import DashboardTileHeader from "../../dashboard/DashboardTileHeader";
import { getInfoValue } from "../../smart/FieldInfo";
import NumberType from "../../../types/Number";
import { enhanceLinkWithDrilldownParams } from "../../drillDown/DrillDown.utils";
import { LinkStyled } from "@components/dashboard/Dashboard.styles";
import { logger } from "@utils/log";
import { isDefined } from "@utils/general";

interface IProps extends IDashboardTileComponentProps<IInfoTileInfo>, WithOData {
    isGrouped?: boolean;
}

export const infoTileCurrencyFormatter: TDashboardInfoTileFormatter = (value) => {
    const formatted = NumberType.format(value, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    const parser = NumberType.getParser();
    const [number, fraction] = formatted.split(parser._decimalChar);
    return (<>
        {number}<Fraction>{parser._decimalChar}{fraction}</Fraction>
    </>);
};

interface ITileCellProps extends IInfoTileDataCell {
    formatter: TDashboardInfoTileFormatter;
    inEditMode: boolean;
}

export const InfoTileCell: React.FC<ITileCellProps> = (props) => {
    const context = useContext(AppContext);
    const { value, label, iconName, unit, severity, formatter } = props;

    let content: React.ReactNode;
    if (iconName) {
        const Icon = getIcon(iconName);
        content = (
                <ColoredIconStyled $severity={severity}><Icon width={IconSize.L}
                                                              height={IconSize.L}/></ColoredIconStyled>
        );
    }

    const formattedValue = formatter ? formatter(value) : value;

    content = (<>
        <ValueWrapper>
            <Value $severity={severity}>{formattedValue} {unit && <Unit>{unit}</Unit>}</Value>
            {content}
        </ValueWrapper>
        <Label>{label}</Label>
    </>);

    if (props.link) {
        const to = getInfoValue(props, "link", { context });

        if (to) {
            content = (
                    <LinkStyled $isEdit={props.inEditMode}
                                to={enhanceLinkWithDrilldownParams(to, context)}>{content}</LinkStyled>
            );
        }
    }

    return (
            <StyledInfoTileCell $span={props.size ?? 1}>
                {content}
            </StyledInfoTileCell>
    );
};

const InfoTile: React.FC<IProps> = ({ inEditMode, info, oData, isGrouped }) => {
    const context = useContext(AppContext);
    const [data, setData] = useState<IInfoTileDataCell[]>(null);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [hasError, setError] = useState<boolean>(false);

    useEffect(() => {
        let mounted = true;
        try {
            getTileData(info.infoData, { context, oData })
                    .then((data) => mounted && setData(data))
                    .finally(() => mounted && setLoading(false));
        } catch (e) {
            if (mounted) {
                setError(true);
                setData(null);
            }
        }

        return () => {
            mounted = false;
        };
    }, [context, info.infoData, oData]);

    const max = data?.reduce((max, item) => max + (item.size ?? 1), 0);
    if (isDefined(max) && data.length > 1 && max !== info.size?.w) {
        logger.warn(`Possible dashboard tile misconfiguration (${info.title}) - width of single dashboard tiles should match the width of whole widget`);
    }

    const content = (<>
        <DashboardTileHeader tooltip={info.tooltip} small={isGrouped}>{info.title}</DashboardTileHeader>
        <InfoTileBody $columns={max}>
            {data?.map((item, idx) => (
                    <InfoTileCell key={idx} {...item} inEditMode={inEditMode}
                                  formatter={item.formatter ?? info.formatter}/>))}
        </InfoTileBody>
    </>);

    const showLoader = isLoading || hasError/*todo: may be different look for error state*/;
    const to = getInfoValue(info, "link", { context });

    return (
            <StyledInfoTile backgroundColor={info.backgroundColor}
                            inEditMode={inEditMode}
                            isLoading={showLoader}
                            hideBackground={isGrouped}
                            to={to}>
                {content}
            </StyledInfoTile>
    );
};

export default memo(withOData(InfoTile));