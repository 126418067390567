import React from "react";
import {
    AuditTrailLayer,
    Base,
    Body,
    Circle,
    InnerCircle,
    InnerLabel,
    InnerSpan,
    Label,
    StyledSwitch
} from "./Switch.styles";
import { KeyName } from "../../../keyName";
import { CheckIcon, CloseIcon, IProps as IIconProps } from "../../icon/Icon";
import { WithTranslation, withTranslation } from "react-i18next";
import TestIds from "../../../testIds";
import { IAuditTrailData } from "../../../model/Model";
import { getTabIndex } from "../../auditTrail/Utils";
import { IconSize, Status } from "../../../enums";
import { IValueInputComponentProps } from "../input";
import { ColoredIcon } from "../../icon";
import { InputStatusStraight } from "../input/Input.styles";
import { FIELD_CONTENT_PADDING } from "../field/Field.styles";
import { HOTSPOT_ID_ATTR } from "../../hotspots/Hotspots.utils";
import BusyIndicator from "../../busyIndicator";

import { BusyIndicatorSize, BusyIndicatorType } from "../../busyIndicator/BusyIndicator.utils";

export interface ISwitchIconSet {
    on: React.FunctionComponent<IIconProps>;
    off: React.FunctionComponent<IIconProps>;
}

export interface IProps extends IValueInputComponentProps, WithTranslation {
    checked?: boolean;
    // if switch should indicate its state by semantic color, like on/off green/red
    hasSemanticColor?: boolean;
    // light variant could be rendered on dark backgrounds, like in filter
    isLight?: boolean;
    label?: string;
    onChange?: (checked: boolean) => void;
    type?: SwitchType;
    isBusy?: boolean;
    iconSet?: ISwitchIconSet;
    hotspotId?: string;
    auditTrailData?: IAuditTrailData;
    name?: string;

    style?: React.CSSProperties;
    className?: string;
}

export enum SwitchType {
    Default = "Default",
    YesNo = "YesNo",
    Icons = "Icons",
    OnOff = "OnOff"
}

class Switch extends React.PureComponent<IProps> {
    static defaultProps = {
        isLight: false,
        type: SwitchType.Default,
        hasSemanticColor: false,
        checked: false
    };

    handleClick = (/*e: MouseEvent<HTMLElement>*/) => {
        this.select();
    };

    handleKeyDown = (e: React.KeyboardEvent) => {
        if ([KeyName.Space, KeyName.Enter].includes(e.key as KeyName)) {
            e.preventDefault();
            this.select();
        }
    };

    select = () => {
        if (!this.isDisabled() && !this.props.isBusy) {
            this.props.onChange?.(!this.props.checked);
        }
    };

    getIconWithProps = (Icon: React.FunctionComponent<IIconProps>) => {
        const size = IconSize.S;
        const style = { position: "relative", top: "50%", transform: "translateY(-50%)" } as React.CSSProperties;
        return <ColoredIcon><Icon style={style} width={size} height={size}/></ColoredIcon>;
    };

    getPositiveLabel = () => {
        switch (this.props.type) {
            case SwitchType.Icons:
                return this.getIconWithProps(this.props.iconSet?.on ?? CheckIcon);
            case SwitchType.YesNo:
                return this.props.t("Common:General.Yes");
            case SwitchType.OnOff:
                return this.props.t("Common:General.On");
            default:
                return null;
        }
    };

    getNegativeLabel = () => {
        switch (this.props.type) {
            case SwitchType.Icons:
                return this.getIconWithProps(this.props.iconSet?.off ?? CloseIcon);
            case SwitchType.YesNo:
                return this.props.t("Common:General.No");
            case SwitchType.OnOff:
                return this.props.t("Common:General.Off");
            default:
                return null;
        }
    };

    isDisabled = () => {
        // Switch in readOnly state is supposed to look the same as in disabled state
        return this.props.isDisabled || this.props.isReadOnly;
    };

    render() {
        const positiveLabel = this.getPositiveLabel(),
            negativeLabel = this.getNegativeLabel();
        const switchStyleProps = {
            _isChecked: this.props.checked,
            _isLight: this.props.isLight,
            _isSemantic: this.props.hasSemanticColor
        };
        return (
            <StyledSwitch onClick={this.handleClick} onKeyDown={this.handleKeyDown}
                          isDisabled={this.isDisabled()}
                          style={this.props.style}
                          className={this.props.className}
                          {...{ [HOTSPOT_ID_ATTR]: this.props.hotspotId }}
                          data-testid={TestIds.Switch}>
                {this.props.label &&
                    <Label auditTrailData={this.props.auditTrailData} isLight={this.props.isLight}
                           data-testid={TestIds.FieldLabel}>
                        <InnerSpan auditTrailData={this.props.auditTrailData}>{this.props.label}</InnerSpan>
                    </Label>}
                <Body role="switch"
                      isDisabled={this.isDisabled()}
                      _isLight={this.props.isLight}
                      tabIndex={this.isDisabled() ? null : getTabIndex(this.props)}
                      aria-checked={this.props.checked}
                      data-testid={TestIds.SwitchBody}
                      data-name={this.props.name}
                      aria-disabled={this.isDisabled() ? this.isDisabled() : null}
                >
                    <AuditTrailLayer
                        auditTrailData={this.props.auditTrailData}
                        data-testid={TestIds.AuditTrailLayer}
                        _isChecked={this.props.checked}/>
                    <Base {...switchStyleProps}
                          _hasIcon={this.props.type === SwitchType.Icons}
                          _isWithoutHover={this.props.isBusy}>
                        {positiveLabel &&
                            <InnerLabel {...switchStyleProps} _isPositive={true}>
                                {positiveLabel}
                            </InnerLabel>}
                        {negativeLabel &&
                            <InnerLabel {...switchStyleProps} _isPositive={false}>
                                {negativeLabel}
                            </InnerLabel>}
                        <Circle {...switchStyleProps}
                                data-testid={TestIds.SwitchCircle}
                                _isWithoutHover={this.props.isBusy}>
                            {!this.props.isBusy
                                ? <InnerCircle {...switchStyleProps} />
                                :
                                <BusyIndicator size={BusyIndicatorSize.XS} type={BusyIndicatorType.WithoutBackground}/>
                            }
                        </Circle>
                    </Base>
                </Body>
                {!this.props.isReadOnly && this.props.showChange &&
                    <InputStatusStraight left={`-${FIELD_CONTENT_PADDING}px`} status={Status.Warning}/>}
            </StyledSwitch>
        );
    }
}

export default withTranslation(["Common"])(Switch);