import React from "react";
import BindingContext from "../../../odata/BindingContext";
import FormGroup from "../../formGroup";
import { IFieldDef } from "../FieldInfo";
import SmartBulkChangeField from "./SmartBulkChangeField";
import { getTooltip } from "../smartField/SmartFieldUtils";
import SmartFormGroupBase, { ISmartFormGroupBaseProps } from "../smartFormGroup/SmartFormGroupBase";
import FieldsWrapper from "../../inputs/field/FieldsWrapper";

interface IProps extends ISmartFormGroupBaseProps {
}

export default class SmartBulkChangeFormGroup extends SmartFormGroupBase<IProps> {

    renderField = (rows: IFieldDef[], isCustom = false): React.ReactElement[] => {
        const array: React.ReactElement [] = [];

        const _addField = (field: IFieldDef, bc: BindingContext) => {
            array.push(
                <SmartBulkChangeField
                    key={bc.toString()}
                    bindingContext={bc}
                    storage={this.props.storage}
                    fieldProps={{
                        isLight: false
                    }}

                    onConfirm={this.handleConfirm}
                    onCancel={this.handleCancel}
                    onTemporalChange={this.handleTemporalChange}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                />
            );
        };

        for (let i = 0; i < rows.length; i++) {
            const path = rows[i].id;

            const field = this.props.storage.data.mergedDefinition?.[path]?.fieldDef || rows[i];
            const bc = this.props.bindingContext.navigate(field.id);

            _addField(field, bc);
        }

        return array;
    };


    // rowsTop -- groups.rows has nested array
    renderFields = (rowsTop: (IFieldDef[])[], isCustom: boolean, showCollapsedGroup = true): React.ReactElement[] => {
        const array: React.ReactElement[] = [];
        return (rowsTop || []).reduce((ret, rows, index) => {
            ret.push(...this.renderField(rows, isCustom));
            const lastField = this.getFieldDefinition(rows[rows.length - 1]);
            if (lastField?.collapsedRows) {
                ret.push(...this.renderFields(lastField.collapsedRows, false));
            }
            return ret;
        }, array);
    };


    render() {
        if (this.props.def.lineItems) {
            // skip group with lineItems
            return null;
        }

        return (
            <FormGroup
                id={this.props.def.id}
                title={this.props.title ?? this.props.def.title as string}
                isRemovable={false}
                showLine
                tooltip={getTooltip(this.props.def.tooltip, this.props.storage)}>

                {this.props.def.rows &&
                <FieldsWrapper isColumn>
                    {this.renderFields(this.props.def.rows, false)}
                </FieldsWrapper>
                }
                {/*this.props.def.lineItems && this.renderLineItems(this.props.def.lineItems)*/}
            </FormGroup>
        );
    }
}