import { asc } from "@utils/general";
import { logger } from "@utils/log";
import i18next from "i18next";
import { escapeRegExp } from "lodash";
import mime from "mime";
import Papa, { LocalFile, ParseResult } from "papaparse";
import React from "react";

import { FileAction, MimeType } from "../../enums";
import { TRecordString, TValue } from "../../global.types";
import NumberType from "../../types/Number";
import { ISelectItem } from "../inputs/select/BasicSelect";
import { ICellValueObject, TCellValue, TId } from "../table";
import TextEllipsis from "../textEllipsis/TextEllipsis";
import File from "./File";

export const FORBIDDEN_FILE_EXTENSIONS: string[] = ["", "exe"];
export const LAST_5_CHARS_OF_FILE_NAME_WITH_EXT = /.{1,5}((\([0-9]+\))?\.[^.]{1,4})?$/;

export function fileNameFormatter(value: TValue): ICellValueObject {
    return value ? {
        tooltip: `${value}`,
        value: (
            <TextEllipsis fixedPattern={LAST_5_CHARS_OF_FILE_NAME_WITH_EXT} text={`${value}`}/>
        )
    } : null;
}

export function strongTextFormatter(value: TValue): TCellValue {
    return {
        tooltip: `${value}`,
        value: (
            <strong>{value}</strong>
        )
    };
}

export const isFilePdf = (mime: MimeType): boolean => {
    return mime === MimeType.PDF;
};

export const isFileWord = (mime: MimeType): boolean => {
    const wordMimeTypes = [MimeType.DOC, MimeType.DOCX];

    return wordMimeTypes.includes(mime);
};

export const isFileIsDoc = (extOrName: string): boolean => {
    if (!extOrName) {
        return false;
    }
    const { prefix, extension } = splitFileName(extOrName);
    const ext = (extension || prefix).toLowerCase();
    return ["isdoc", "isdocx"].indexOf(ext) >= 0;
};

export const isFilePlainText = (mime: MimeType): boolean => {
    const plainTextMimeTypes = [MimeType.TEXT];

    return plainTextMimeTypes.includes(mime);
};

export const isFileExcel = (mime: MimeType): boolean => {
    const excelMimeTypes = [MimeType.XLS, MimeType.XLSX, MimeType.CSV];

    return excelMimeTypes.includes(mime);
};

export const isFileCsvOrGpc = (ext: string): boolean => {
    const types = ["csv", "gpc"];
    return types.includes(ext?.toLowerCase());
};

export const isFileCsv = (mime: MimeType): boolean => {
    const csvMimeTypes = [MimeType.CSV];

    return csvMimeTypes.includes(mime);
};

export const isFileImage = (mime: MimeType): boolean => {
    const imgMimeTypes = [
        MimeType.GIF, MimeType.ICO, MimeType.JPG, MimeType.PNG,
        MimeType.SVG, MimeType.TIFF, MimeType.WEBP
    ];

    return imgMimeTypes.includes(mime);
};

export const isFileXml = (mime: MimeType): boolean => {
    const xmlMimeTypes = [MimeType.XML, MimeType.TEXT_XML];

    return xmlMimeTypes.includes(mime);
};

export const fileNameToMime = (fileName: string): MimeType => {
    return mime.getType(fileName) as MimeType;
};

export const detectEncoding = async (file: Blob): Promise<string> => {
    const jschardet = await import("jschardet");
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = function (e) {
            const csvResult = (e.target.result as string).split(/\r|\n|\r\n/);
            resolve(jschardet.detect(csvResult.toString()).encoding);
        };
        reader.readAsBinaryString(file);
    });
};

export const csvToArray = (csv: LocalFile, encoding: string): Promise<ParseResult<TRecordString>> => {
    return new Promise(resolve => {
        const config = {
            complete: function (results: ParseResult<TRecordString>) {
                resolve(results);
            },
            header: false,
            skipEmptyLines: true,
            encoding
        };
        Papa.parse(csv, config);
    });
};


export const splitFileName = (fileName: string, isNotStrict?: boolean): { prefix: string; extension: string } => {
    let prefix;
    let extension = "";

    const lastDotIndex = (fileName ?? "").lastIndexOf(".");

    // treat names without extension as prefix
    // for files with extension only, treat them as prefix in non strict mode (used when showing file names and we don't want to show empty name for files without prefix)
    if (lastDotIndex < 0 || (lastDotIndex === 0 && isNotStrict)) {
        prefix = fileName;
    } else {
        prefix = fileName.slice(0, lastDotIndex);
        extension = fileName.slice(lastDotIndex + 1);
    }

    return { prefix, extension };
};

const getMaxFileIndex = (fileName: string, fileNames: string[]) => {
    const { prefix, extension } = splitFileName(fileName);
    // match "fileName (index)" or "(index)"
    const regex = new RegExp(`^${escapeRegExp(prefix.toLowerCase())}( ?\\((?<index>\\d+)\\))?$`);

    let maxIndex = null;
    let exactMatch = false;
    const matchedIndexes = [];

    for (const fName of fileNames) {
        if (fName.toLowerCase() === fileName.toLowerCase()) {
            exactMatch = true;
            continue;
        }

        const { prefix: fPrefix, extension: fExtension } = splitFileName(fName);
        const regexCheck = regex.exec(fPrefix.toLowerCase());

        if (regexCheck && fExtension.toLowerCase() === extension.toLowerCase()) {
            const foundIndex = parseInt(regexCheck.groups.index);

            matchedIndexes.push(foundIndex);
        }
    }

    if (exactMatch) {
        matchedIndexes.sort(asc);

        for (let i = 1; i <= (matchedIndexes[matchedIndexes.length - 1] ?? 0) + 1; i++) {
            if (!matchedIndexes.includes(i)) {
                maxIndex = i;
                break;
            }
        }
    }

    return maxIndex;
};

export const isValidFileName = (fileName: string) => {
    if (!fileName) {
        return false;
    }

    const { prefix, extension } = splitFileName(fileName);

    return !!prefix && !FORBIDDEN_FILE_EXTENSIONS.includes(extension);
};

/** Returns name without extension */
export const getFileNameOnly = (fileName: string, isNotStrict?: boolean) => {
    const { prefix } = splitFileName(fileName, isNotStrict);

    return prefix;
};

/** Returns only extension */
export const getFileNameExtension = (fileName: string) => {
    const { extension } = splitFileName(fileName);

    return extension;
};

/** Checks fileName against all fileNames and returns new fileName that is unique (e.g. test.txt => test_01.txt) */
export const getUniqueFileName = (fileName: string, fileNames: string[]) => {
    let { prefix, extension } = splitFileName(fileName);

    prefix = prefix.trim();

    let uniqueName = extension ? `${prefix}.${extension}` : prefix;
    const maxIndex = getMaxFileIndex(uniqueName, fileNames);

    if (maxIndex) {
        uniqueName = `${prefix} (${(maxIndex).toString()})${extension ? `.${extension}` : ""}`.trim();
    }

    return uniqueName;
};


/** Probabilistic check whether file item is a folder in browsers without webkitGetAsEntry */
export const isFileFolderOldBrowsers = (file: File) => {
    return !file.type && file.size % 4096 === 0;
};


export const retrieveFile = async (fileEntry: any): Promise<File> => {
    try {
        return new Promise((resolve, reject) => fileEntry.file(resolve, reject));
    } catch (err) {
        logger.error("retrieveFile error", err);
    }

    return null;
};

export const retrieveEntries = async (dirReader: any): Promise<any[]> => {
    try {
        return new Promise((resolve, reject) => dirReader.readEntries(resolve, reject));
    } catch (err) {
        logger.error("retrieveEntries error", err);
    }

    return null;
};

export const getAllFiles = async (item: any, path = ""): Promise<File[]> => {
    let files: File[] = [];

    if (item?.isFile) {
        files.push(await retrieveFile(item));
    } else if (item?.isDirectory) {
        const dirReader = item.createReader();
        const entries = await retrieveEntries(dirReader);

        for (let i = 0; i < entries.length; i++) {
            files = [...files, ...await getAllFiles(entries[i], path + item.name + "/")];
        }
    }

    return files;
};

export const getDroppedFiles = async (event: React.DragEvent | DragEvent): Promise<any> => {
    if (DataTransferItem.prototype.webkitGetAsEntry) {
        const files = await Promise.all([...event.dataTransfer.items]
            .filter(item => item.kind === "file")
            .map(async (item) => {
                return await getAllFiles(item.webkitGetAsEntry());
            }));

        return files.flatMap(file => file);
    } else {
        return [...event.dataTransfer.files].filter(file => !isFileFolderOldBrowsers(file));
    }
};

export const doesEventContainFiles = (event: React.DragEvent | DragEvent) => {
    // DnD events can contain other items than files, like parts of selected and dragged text
    return event.dataTransfer.types.includes("Files");
};

export const fileSizeFormatter = (val: number): string => {
    const { ceil, pow } = Math;
    const units = ["B", "kB", "MB", "GB", "TB"];
    const divider = pow(2, 10);
    let size = val;
    let idx = 0;
    while (idx < units.length && size > divider) {
        size /= divider;
        ++idx;
    }
    return `${NumberType.format(ceil(size))} ${units[idx]}`;
};

export const getFileContextMenuItems = (isReadOnly = false, hasFileContents = true, isDeleteDisabled = false): ISelectItem[] => {
    return [
        ...(hasFileContents ? [
            {
                id: FileAction.Info,
                label: i18next.t("Components:FileUploader.Info"),
                iconName: "Info"
            }
        ] : []),
        ...(!isReadOnly && hasFileContents ? [
            {
                id: FileAction.Rename,
                label: i18next.t("Components:FileUploader.Rename"),
                iconName: "Edit"
            }
        ] : []),
        ...(hasFileContents ? [
            {
                id: FileAction.Download,
                label: i18next.t("Components:FileUploader.Download"),
                iconName: "Download"
            }
        ] : []),
        ...(!isReadOnly ? [
            {
                id: FileAction.Delete,
                label: i18next.t("Components:FileUploader.Delete"),
                iconName: "Bin",
                isDisabled: isDeleteDisabled
            }
        ] : [])
    ];
};

export const getCustomFileContextMenuItems = (customItems: ISelectItem[] | ((fileId: TId) => ISelectItem[]), fileId: TId): ISelectItem[] => {
    if (typeof customItems === "function") {
        return customItems((fileId)) ?? [];
    }

    return customItems ?? [];
};

export const getFileIcon = (mimeType: MimeType, extension?: string): string => {
    switch (true) {
        case isFilePdf(mimeType):
            return "Pdf";
        case isFileWord(mimeType):
            return "Word";
        case isFileExcel(mimeType):
            return "Excel";
        case isFileImage(mimeType):
            return "Picture";
        case isFilePlainText(mimeType):
            return "Txt";
        case extension && isFileIsDoc(extension):
            return "Isdoc";
    }

    return "OtherDoc";
};

export const getFileIconByName = (fileName: string): string => {
    const extension = fileName.split(".").slice(-1)[0]?.toLowerCase();

    return getFileIcon(fileNameToMime(extension), extension);
};

export function getNativeEvent(event: React.DragEvent | DragEvent): DragEvent {
    return (event as React.DragEvent).nativeEvent ?? (event as DragEvent);
}

export function getFileNameFromResponseHeaders(headers: Headers): string {
    const contentDisposition = headers.get("Content-Disposition");
    return contentDisposition?.split(";").find(part => part.trim().startsWith("filename=")).split("filename=")[1]?.replaceAll("\"", "");
}

export function convertFileToBase64(fileChangedEvent: React.ChangeEvent<HTMLInputElement>, onConvert: (base64: string) => void) {
    //Read File
    const selectedFile = fileChangedEvent.target.files;
    //Check File is not Empty
    if (selectedFile.length > 0) {
        // Select the very first file from list
        const fileToLoad = selectedFile[0];
        // FileReader function for read the file.
        const fileReader = new FileReader();
        let base64: string;

        // Onload of file read the file content
        fileReader.onload = function (fileLoadedEvent) {
            base64 = fileLoadedEvent.target.result as string;
            onConvert(base64);
        };

        // Convert data to base64
        fileReader.readAsDataURL(fileToLoad);
    }
}