import styled from "styled-components/macro";
import { T_PLAIN_small, T_TITLE_6 } from "../../../global.style";

export const KeyboardShortcutTileGrid = styled.div`
    display: grid;
    grid-template-columns: max-content auto;
    row-gap: 15px;
    column-gap: 30px;

    margin-top: 25px;

    ${T_PLAIN_small};
`;

export const ItemGroupHeader = styled.h3`
    ${T_TITLE_6};
    color: ${props => props.theme.C_ACT_main};
    grid-column: 1 / span 2;
    margin-top: 15px;
    margin-bottom: -5px;
`;

export const ShortcutDescription = styled.div`
    position: relative;

    &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 2px;
        left: -8px;
        background-color: ${props => props.theme.C_ACT_hover_without_opacity};
    }
`;