import React, { useContext } from "react";
import ReactDOM from "react-dom";
import {
    BasicInputSizes,
    ConfigListItemBoundType,
    FastEntryInputSizes,
    GroupListDropType,
    IconSize,
    Status
} from "../../enums";
import {
    GroupSeparatorLine,
    ItemDescription,
    ItemInnerContentWrapper,
    ItemInnerWrapper,
    ItemSelectOpener,
    ItemSelector,
    ItemValue,
    ItemValueWrapper,
    RequiredAlert,
    SelectedItemLabel,
    StyledConfigurationItem,
    StyledGroupSeparator,
    SubItem
} from "./ConfigurationList.styles";
import { CaretIcon, CopyIcon, getIcon, MoreOptionsIcon, VisibleFilledIcon, VisibleIcon } from "../icon";
import { Draggable, DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { handleRefHandlers, isObjectEmpty, TabIndex } from "@utils/general";
import Tooltip from "../tooltip/Tooltip";
import { RequiredMark } from "../inputs/field/Label.styles";
import { IFieldComponentProps, ISelectItem } from "../inputs/select/BasicSelect";
import TestIds from "../../testIds";
import { Select } from "../inputs/select";
import {
    connectToContext,
    CustomDnDContext,
    IConfigList,
    IDropAnimationPos,
    IGroupListItemDef,
    shouldComponentUpdateIgnoreData
} from "./ConfigurationList";
import memoizeOne from "../../utils/memoizeOne";
import { WithTranslation, withTranslation } from "react-i18next";
import { IconButton } from "../button";
import CustomizationIcon from "./CustomizationIcon";

export interface IItemSelectedItemChange {
    itemId: any;
    selectedItemId: any;
}

export interface ISubItemVisibilityChange {
    itemId: string;
    subItemId: string;
    visible: boolean;
}

export interface IProps {
    /**
     * Unique id, has semantic value.
     * If it starts as an id of another item and has -copy- suffix, it is automatically treated as its clone.
     */
    id: string;
    index: number;
    value: string | number;
    icon?: string;
    /** Grey icon on the right side of item */
    rightIcon?: string;
    description?: string;
    width?: BasicInputSizes | FastEntryInputSizes;
    /** Shows required mark */
    isRequired?: boolean;
    /** Renders only as visual item, without Draggable wrapper. */
    isDisabled?: boolean;
    /** Disables drag, but item is still wrapped with Draggable.
     * Other draggable items interacts with the dragDisabled item and can be moved around it. */
    isDragDisabled?: boolean;
    /** Dragged items renders ghost item when drag starts, to show, that the item isn't being moved, but copied.*/
    isCopyOnly?: boolean;
    /** Item is rendered as group separator line. */
    isSeparator?: boolean;
    preventTransform?: boolean;
    isHighlighted?: boolean;
    level?: number;
    /** Prevents item from being dragged outside of its group or column. */
    boundTo?: ConfigListItemBoundType;
    /** List of group ids, that this Item can be dragged into */
    allowedGroups?: string[];

    /** Nested select component */
    items?: ISelectItem[];
    /** Render select as disabled */
    areItemsReadOnly?: boolean;
    /** Items shown as read only labels under the main item */
    subItems?: ISubItemProps[];
    /** This item will be shown as multiple items grouped together.
     * All the props of each item should be kept inside the combinedItems.
     * The main item is only placeholder for the rest and needs just unique id and value that can be used for sorting.*/
    combinedItems?: IGroupListItemDef[];
    selectedItemId?: any;
    onSelectedItemChange?: (args: IItemSelectedItemChange) => void;
    onSubItemVisibilityChange?: (args: ISubItemVisibilityChange) => void;

    dropAnimationPos: IDropAnimationPos;
    /** Whole config list definition, injected from CustomDnDContext. Used for advanced checks while performing DnD.*/
    data?: IConfigList;

    configListRef?: React.RefObject<HTMLDivElement>;
    provided?: DraggableProvided;
    snapshot?: DraggableStateSnapshot;
}

export interface ISubItemProps {
    id: string;
    value: string;
    showHideIcon?: boolean;
    isVisible?: boolean;
}

export interface IGroupSubItemProps {
    id: string;
    value: string;
    subItems?: ISubItemProps[];
}

interface IInnerWrapper {
    item: IGroupListItemDef;
    width: string;
    isHighlighted?: boolean;
    isDisabled?: boolean;
    icons?: any[];
    isDragging?: boolean;
    isRequired?: boolean;
    isCombined?: boolean;
    wrapperRef?: React.Ref<HTMLDivElement>;
    valueRefs?: React.Ref<HTMLDivElement>[];
}

class Item extends React.Component<IProps & WithTranslation> {
    static defaultProps = {
        width: BasicInputSizes.L
    };

    _valueRef = React.createRef<HTMLDivElement>();
    _wrapperRef = React.createRef<HTMLDivElement>();
    _itemSelectorRef = React.createRef<HTMLInputElement>();
    _itemSelectOpenerRef = React.createRef<HTMLInputElement>();
    elementStyles: CSSStyleDeclaration;

    shouldComponentUpdate(nextProps: IProps & WithTranslation) {
        return shouldComponentUpdateIgnoreData(this.props, nextProps);
    }

    getStyle = (style: any, snapshot: DraggableStateSnapshot, dropAnimationPos: IDropAnimationPos) => {
        if (snapshot.dropAnimation && dropAnimationPos) {
            const translate = `translate(${dropAnimationPos.x}px, ${dropAnimationPos.y}px)`;
            return {
                ...style,
                transform: translate
            };
        }

        return style;
    };

    getTooltipValue = (item: IGroupListItemDef) => {
        let tooltip = item.value;

        if (item.description) {
            tooltip += ` ${item.description}`;
        }

        return tooltip;
    };

    getValue = (item: IGroupListItemDef) => {
        if (item.icon) {
            const Icon = getIcon(item.icon);

            return <Icon title={item.value?.toString()}
                         width={IconSize.M} height={IconSize.M}
                         preventHover
                         color={"C_BTN_hover_light"}/>;
        }

        return item.value;
    };

    getRightIcon = () => {
        if (!this.props.rightIcon) {
            return null;
        }

        return (
                <CustomizationIcon key={1} iconName={this.props.rightIcon}/>
        );
    };

    getSubItemsIcon = (subItems: ISubItemProps[]) => {
        if (!subItems?.length) {
            return null;
        }

        return (
                <CustomizationIcon key={2} iconName={"DependentFields"} tooltipRows={subItems.map(item => item.value)}/>
        );
    };

    getHandleRef = memoizeOne((refs: React.Ref<any>[]) => {
        if (isObjectEmpty(refs)) {
            return null;
        }

        return (element: HTMLDivElement) => {
            handleRefHandlers(element, ...refs);
        };
    });

    renderCombinedItems = () => {
        return this.props.combinedItems.map(item => this.renderItem(item, true));
    };

    renderSubItem = (subItem: ISubItemProps) => {
        const VisibilityIcon = subItem.isVisible ? VisibleIcon : VisibleFilledIcon;

        return (
                <SubItem key={subItem.id}>{subItem.value}{subItem.showHideIcon &&
                        <IconButton isDecorative title={this.props.t("Common:General.View")}
                                    onClick={() => {
                                        this.props.onSubItemVisibilityChange?.({
                                            itemId: this.props.id,
                                            subItemId: subItem.id,
                                            visible: !subItem.isVisible
                                        });
                                    }}>
                            <VisibilityIcon width={IconSize.M} height={IconSize.M}/>
                        </IconButton>
                }</SubItem>
        );
    };

    checkItemBoundaries = (): ConfigListItemBoundType => {
        const shouldCheck = this.props.boundTo && this.props.snapshot.isDragging;

        if (!shouldCheck) {
            this.elementStyles = null;
            return null;
        }

        const itemGroup = Object.values(this.props.data.groups).find(group => group.itemIds.includes(this.props.id));
        const groupColumn = Object.values(this.props.data.columns).find(column => column.groupIds.includes(itemGroup.id));
        const itemRect = this._wrapperRef.current.parentElement.getBoundingClientRect();
        const itemVerticalMid = itemRect.x + itemRect.width / 2;
        const itemHorizontalMid = itemRect.y + itemRect.height / 2;

        let outOfBounds: ConfigListItemBoundType = null;

        if (this.props.boundTo === ConfigListItemBoundType.Column) {
            // we need to directly access configListRef because item can be render via portal, so we can't rely on parentElement structure
            const columnElement = this.props.configListRef.current.querySelector(`[data-rbd-droppable-id="${groupColumn.id}"]`);
            const columnRect = columnElement.getBoundingClientRect();

            if (!this.elementStyles) {
                // getComputedStyle is performance heavy operation => caching
                this.elementStyles = getComputedStyle(columnElement);
            }

            if (columnRect.x - parseFloat(this.elementStyles.marginLeft) + columnRect.width + parseFloat(this.elementStyles.marginRight) < itemVerticalMid) {
                outOfBounds = ConfigListItemBoundType.Column;
            }

        } else if (this.props.boundTo === ConfigListItemBoundType.Group) {
            const groupElement = this.props.configListRef.current.querySelector(`[data-rbd-draggable-id="${itemGroup.id}-${GroupListDropType.Group}"]`);
            const groupRect = groupElement.getBoundingClientRect();

            if (!this.elementStyles) {
                this.elementStyles = getComputedStyle(groupElement);
            }

            const leftMargin = parseFloat(this.elementStyles.marginLeft);
            const rightMargin = parseFloat(this.elementStyles.marginRight);
            const topMargin = parseFloat(this.elementStyles.marginTop);
            const bottomMargin = parseFloat(this.elementStyles.marginBottom);
            const isOutOfRightBounds = groupRect.x - leftMargin + groupRect.width + rightMargin < itemVerticalMid;
            const isOutOfLeftBounds = groupRect.x - leftMargin > itemVerticalMid;
            const isOutOfBottomBounds = groupRect.y - topMargin + groupRect.height + bottomMargin < itemHorizontalMid;
            const isOutOfTopBounds = groupRect.y - topMargin > itemHorizontalMid;

            if (isOutOfRightBounds || isOutOfLeftBounds || isOutOfBottomBounds || isOutOfTopBounds) {
                outOfBounds = ConfigListItemBoundType.Group;
            }
        }

        return outOfBounds;
    };

    renderItem = (item: IGroupListItemDef, isCombined = false) => {
        return (
                <React.Fragment key={item.id}>
                    <Tooltip
                            isHidden={this.props.snapshot.isDragging || !!this.props.snapshot.draggingOver}
                            content={this.getTooltipValue(item)}
                            onlyShowWhenChildrenOverflowing>
                        {(ref) =>
                                <>
                                    {this.renderInnerWrapper({
                                        item,
                                        wrapperRef: this._wrapperRef,
                                        valueRefs: [this._valueRef, ref],
                                        width: item.width ?? this.props.width,
                                        isHighlighted: item.isHighlighted,
                                        isCombined: isCombined,
                                        icons: [
                                            this.props.snapshot.isDragging && this.props.isCopyOnly &&
                                            <CopyIcon key={0} width={IconSize.M} height={IconSize.M}/>,
                                            this.getRightIcon(),
                                            this.getSubItemsIcon(item.subItems),
                                            !this.props.snapshot.isDragging && this.props.children
                                        ].filter(item => item),
                                        isRequired: item.isRequired,
                                        isDisabled: item.isDisabled,
                                        isDragging: this.props.snapshot.isDragging
                                    })}
                                </>}
                    </Tooltip>
                </React.Fragment>
        );
    };

    renderInnerWrapper = (args: IInnerWrapper) => {
        const outOfBounds = this.checkItemBoundaries();
        const isLastCombinedItem = this.props.combinedItems && this.props.combinedItems[this.props.combinedItems.length - 1].id === args.item.id;
        const shouldRenderAlert = !args.isCombined || isLastCombinedItem;
        return (
                <ItemInnerWrapper
                        ref={args.wrapperRef}
                        _width={args.width}
                        isHighlighted={args.isHighlighted}
                        isDisabled={args.isDisabled}
                        isDragging={args.isDragging}
                        isCombined={args.isCombined}
                        hasRightIcon={!!this.props.rightIcon}
                >
                    {outOfBounds && shouldRenderAlert
                            && <RequiredAlert
                                    title={this.props.t(`Components:ConfigurationList.${outOfBounds === ConfigListItemBoundType.Column ? "RequiredInColumn" : "RequiredInGroup"}`)}
                                    status={Status.Error} isSmall/>}
                    {args.isRequired &&
                            <RequiredMark style={{ top: "7px", left: "10px" }}
                                          data-testid={TestIds.RequiredMark}/>
                    }
                    <ItemValueWrapper ref={this.getHandleRef(args.valueRefs)}
                                      isDisabled={args.isDisabled}
                                      isDescription={!!args.item.description}>
                        <ItemValue
                                data-testid={TestIds.ConfigurationItemValue}>{this.getValue(args.item)}</ItemValue>
                        {args.item.description &&
                                <ItemDescription>{` ${args.item.description}`}</ItemDescription>
                        }
                        {args.icons && args.icons.length > 0 &&
                                <ItemInnerContentWrapper>
                                    {args.icons}
                                </ItemInnerContentWrapper>
                        }
                    </ItemValueWrapper>
                    {this.renderItemSelector()}
                </ItemInnerWrapper>
        );
    };

    handleItemRef = memoizeOne((openerRef: React.Ref<HTMLElement>) => {
        return (ref: HTMLDivElement) => {
            handleRefHandlers(ref, this._itemSelectorRef, openerRef);
        };
    });

    renderItemSelectorFieldComponent = (props: IFieldComponentProps) => {
        const isDisabled = this.props.areItemsReadOnly;

        const selectedItem = this.props.items?.find(item => item.id === this.props.selectedItemId);

        return (
                <ItemSelector
                        isDisabled={isDisabled}
                        ref={this.handleItemRef(props.openerRef)}
                        data-testid={TestIds.ConfigurationItemAggregationSelector}
                >
                    <SelectedItemLabel>
                        {selectedItem?.label}
                    </SelectedItemLabel>
                    <IconButton
                            title={this.props.t("Common:General.Open")}
                            isDecorative
                            isDisabled={isDisabled}
                            tabIndex={this.props.areItemsReadOnly ? TabIndex.Disabled : 0}
                            onClick={(e: React.MouseEvent) => {
                                if (this.props.areItemsReadOnly) {
                                    return;
                                }

                                this._itemSelectorRef.current.focus();
                                props.onClick(e);
                            }}
                            onKeyDown={props.onKeyDown}
                            onBlur={props.onBlur}
                            style={{
                                position: "relative",
                                top: "1px"
                            }}>
                        <CaretIcon width={IconSize.M} height={IconSize.M}/>
                    </IconButton>
                </ItemSelector>
        );
    };

    handleSelectChange = (args: any) => {
        this.props.onSelectedItemChange?.({
            itemId: this.props.id,
            selectedItemId: args.value
        });
    };

    renderItemSelector = () => {
        if (!this.props.items) {
            return null;
        }

        return (
                <>
                    <ItemSelectOpener ref={this._itemSelectOpenerRef}/>
                    <Select
                            onChange={this.handleSelectChange}
                            value={this.props.selectedItemId}
                            openOnClick={!this.props.areItemsReadOnly}
                            openerRef={this._itemSelectOpenerRef}
                            items={this.props.items}
                            fieldComponent={this.renderItemSelectorFieldComponent}
                    />
                </>
        );
    };

    renderCopyClone = (item: IGroupListItemDef) => {
        return (
                <StyledConfigurationItem>
                    {this.renderInnerWrapper({
                        item,
                        width: item.width ?? this.props.width,
                        isHighlighted: true,
                        isRequired: item.isRequired
                    })}
                </StyledConfigurationItem>
        );
    };

    render() {
        const usePortal = this.props.snapshot.isDragging;
        let Item = (
                <StyledConfigurationItem
                        ref={this.props.provided.innerRef}
                        {...this.props.provided.draggableProps}
                        {...this.props.provided.dragHandleProps}

                        level={this.props.snapshot.dropAnimation ? 0 : this.props.level}
                        isDragging={this.props.snapshot.isDragging}
                        preventTransform={this.props.preventTransform || this.props.isDisabled}
                        style={this.getStyle(this.props.provided.draggableProps.style, this.props.snapshot, this.props.dropAnimationPos)}
                        data-testid={TestIds.ConfigurationItem}
                >
                    {this.props.combinedItems ? this.renderCombinedItems() : this.renderItem(this.props)}
                </StyledConfigurationItem>
        );


        // cloning API has to be used, to prevent positioning error while dragging
        // because position: fixed doesn't work inside element with transform applied (and we use config list inside dialog with transform applied)
        // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/api/draggable.md#warning-position-fixed
        // !warning! may have impact on performance
        // custom createPortal instead of react-beautiful-dnd renderClone API is used,
        // so that highlighted item rendered using renderClone can be kept in original DOM position when dragging a copy
        if (usePortal) {
            const modalRoot = document.getElementById("modal-root");
            if (modalRoot) {
                Item = ReactDOM.createPortal(Item, modalRoot);
            }
        }

        return (
                <>
                    {Item}
                    {/*approach without clone API https://codesandbox.io/s/40p81qy7v0?file=/index.js:6474-6503*/}
                    {this.props.snapshot.isDragging && this.props.isCopyOnly && (this.props.combinedItems ? this.props.combinedItems.map(item => this.renderCopyClone(item)) : this.renderCopyClone(this.props))}
                </>
        );
    }
}

const GroupSeparatorItem: React.FunctionComponent<IProps> = (props) => {
    const usePortal = props.snapshot.isDragging;
    let Item = (
            <StyledGroupSeparator
                    ref={props.provided?.innerRef}
                    {...props.provided.draggableProps}
                    {...props.provided.dragHandleProps}
                    title={props.value.toString()}>
                <GroupSeparatorLine/>
                <MoreOptionsIcon
                        width={IconSize.M} height={IconSize.M}
                        preventHover/>
            </StyledGroupSeparator>
    );

    if (usePortal) {
        const modalRoot = document.getElementById("modal-root");
        if (modalRoot) {
            Item = ReactDOM.createPortal(Item, modalRoot);
        }
    }

    return Item;
};

const SelectItemPropsFromDnDContext = (props: IProps) => {
    const { dropAnimationPos, data, configListRef } = useContext(CustomDnDContext);

    return props.snapshot?.dropAnimation ? { dropAnimationPos, data, configListRef } : { data, configListRef };
};

const SelectSeparatorItemPropsFromDnDContext = (props: IProps) => {
    const { dropAnimationPos, data } = useContext(CustomDnDContext);

    return props.snapshot?.dropAnimation ? { dropAnimationPos, data } : { data };
};

export const WrappedItem = connectToContext(withTranslation(["Components"])(Item), SelectItemPropsFromDnDContext);
export const WrappedGroupedSeparatorItem = connectToContext(GroupSeparatorItem, SelectSeparatorItemPropsFromDnDContext);

const DraggableSeparatorItem = (props: IDraggableItemProps) => {
    return (
            <Draggable draggableId={`${props.id}-${GroupListDropType.Item}`} index={props.index}
                       disableInteractiveElementBlocking // enable d'n'd drag events even with html input inside
            >
                {(provided, snapshot) => {
                    return (
                            <WrappedGroupedSeparatorItem {...props} provided={provided} snapshot={snapshot}/>
                    );
                }}
            </Draggable>
    );
};

export { DraggableSeparatorItem as GroupSeparatorItem };

interface IDraggableItemProps extends Omit<IProps, "provided" | "snapshot" | "dropAnimationPos"> {
    children?: any;
}

const DraggableItem = (props: IDraggableItemProps) => {
    // draggableId has to be unique among ALL draggables (both Items and Groups in our case)
    // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/identifiers.md
    // append -item to differentiate between group with the same id
    return (
            <Draggable draggableId={`${props.id}-${GroupListDropType.Item}`} index={props.index}
                       isDragDisabled={props.isDragDisabled ?? props.isDisabled}
                       disableInteractiveElementBlocking // enable d'n'd drag events even with html input inside
            >
                {(provided, snapshot) => {
                    return (
                            <WrappedItem {...props} provided={provided} snapshot={snapshot}/>
                    );
                }}
            </Draggable>
    );
};

export default DraggableItem;