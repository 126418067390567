import styled from "styled-components/macro";
import { StyledLabel } from "../inputs/field/Label.styles";
import { T_TITLE_4_NORMAL } from "../../global.style";

interface ISettingsList {
    numColumns: number;
}

export const StyledSettingsList = styled.div.attrs((props: ISettingsList) => ({
    style: {
        gridTemplateColumns: `repeat(${props.numColumns}, max-content)`
    }
}))<ISettingsList>`
  width: 100%;
  margin-top: 20px;
  
  display: grid;
  grid-column-gap: 120px;
`;
export const StyledSettingItem = styled.div`
  display: flex;
`;

interface IItem {
    isCondensed: boolean;
}

export const SettingItemLabel = styled.div<IItem>`
  flex-grow: 1;
  vertical-align: top;
  padding-right: 19px;
  padding-bottom: ${props => props.isCondensed ? "17px" : "50px"};
  
  ${StyledLabel} {
    margin-bottom: 9px;
  }
`;
export const SettingItemButton = styled.div`
  vertical-align: top;
  position: relative;
  top: -11px;
`;
export const Description = styled.div`
  ${T_TITLE_4_NORMAL};
`;