import React from "react";
import * as deviceDetect from "react-device-detect";

import { KeyName } from "../../keyName";

export enum KeyboardShortcut {
    ALT_D = "ALT_D",
    ALT_N = "ALT_N",
    ALT_C = "ALT_C",
    ALT_R = "ALT_R",
    ALT_S = "ALT_S",
    ALT_X = "ALT_X",
    CTRL_Q = "CTRL_Q",
    ALT_UP = "ALT_UP",
    ALT_DOWN = "ALT_DOWN",
    ALT_SHIFT_UP = "ALT_SHIFT_UP",
    ALT_SHIT_DOWN = "ALT_SHIT_DOWN"
}

interface IKeyboardShortcut {
    id: KeyboardShortcut;
    char: string;
    // alt key on Windows, option key on Mac
    altKey?: boolean;
    ctrlKey?: boolean;
    shiftKey?: boolean;
}

/** If callback returns true, the event is considered as resolved and will not be propagated to another subscriber */
type TSubscriberCallback = (shortcut: KeyboardShortcut, event: KeyboardEvent) => boolean;

interface ISubscriber {
    callback: TSubscriberCallback,
    shortcuts: KeyboardShortcut[];
    /** This subscriber will always be called first, even if other subscribers has subscribed after this one */
    isPrioritized?: boolean;
}

// todo add support for multiple chars shortcuts
const shortcuts: Record<KeyboardShortcut, IKeyboardShortcut> = {
    [KeyboardShortcut.ALT_D]: {
        id: KeyboardShortcut.ALT_D,
        char: "d",
        altKey: true
    },
    [KeyboardShortcut.ALT_N]: {
        id: KeyboardShortcut.ALT_N,
        char: "n",
        altKey: true
    },
    [KeyboardShortcut.ALT_C]: {
        id: KeyboardShortcut.ALT_C,
        char: "c",
        altKey: true
    },
    [KeyboardShortcut.ALT_S]: {
        id: KeyboardShortcut.ALT_S,
        char: "s",
        altKey: true
    },
    [KeyboardShortcut.ALT_R]: {
        id: KeyboardShortcut.ALT_R,
        char: "r",
        altKey: true
    },
    [KeyboardShortcut.ALT_X]: {
        id: KeyboardShortcut.ALT_X,
        char: "x",
        altKey: true
    },
    [KeyboardShortcut.ALT_UP]: {
        id: KeyboardShortcut.ALT_UP,
        char: KeyName.ArrowUp,
        altKey: true
    },
    [KeyboardShortcut.ALT_DOWN]: {
        id: KeyboardShortcut.ALT_DOWN,
        char: KeyName.ArrowDown,
        altKey: true
    },
    [KeyboardShortcut.ALT_SHIFT_UP]: {
        id: KeyboardShortcut.ALT_SHIFT_UP,
        char: KeyName.ArrowUp,
        altKey: true,
        shiftKey: true
    },
    [KeyboardShortcut.ALT_SHIT_DOWN]: {
        id: KeyboardShortcut.ALT_SHIT_DOWN,
        char: KeyName.ArrowDown,
        altKey: true,
        shiftKey: true
    },
    [KeyboardShortcut.CTRL_Q]: {
        id: KeyboardShortcut.CTRL_Q,
        char: "q",
        ctrlKey: true
    }
};

class KeyboardShortcutsManager {
    #subscribers: ISubscriber[] = [];

    // we start with global handling, but subscribe could be provided DOM ref,
    // to only handle key presses from that DOM subtree
    public subscribe = (subscriber: ISubscriber): () => void => {
        this.#subscribers.push(subscriber);

        if (this.#subscribers.length === 1) {
            this.startListening();
        }

        return /*unsubscribe*/() => {
            const index = this.#subscribers.findIndex(s => s === subscriber);
            this.#subscribers.splice(index, 1);

            if (this.#subscribers.length === 0) {
                this.stopListening();
            }
        };
    };

    private startListening = (): void => {
        document.body.addEventListener("keydown", this.handleKeyDown);
    };

    private stopListening = (): void => {
        document.body.removeEventListener("keydown", this.handleKeyDown);
    };

    _notifySubscribers = (shortcut: KeyboardShortcut, event: KeyboardEvent, subscribers: ISubscriber[]): boolean => {
        // iterate from the back so that the later subscribers are notified first
        for (let i = subscribers.length - 1; i >= 0; i--) {
            const subscriber = subscribers[i];

            if (subscriber.shortcuts.includes(shortcut)) {
                const eventHandled = subscriber.callback(shortcut, event);

                if (eventHandled) {
                    return true;
                }
            }
        }

        return false;
    };

    private notifySubscribers = (shortcut: KeyboardShortcut, event: KeyboardEvent): void => {
        let notified = false;
        const prioritySubscribers = this.#subscribers.filter(sub => sub.isPrioritized);

        if (prioritySubscribers.length > 0) {
            notified = this._notifySubscribers(shortcut, event, prioritySubscribers);
        }

        if (!notified) {
            this._notifySubscribers(shortcut, event, this.#subscribers);
        }
    };

    public isEventShortcut = (event: KeyboardEvent | React.KeyboardEvent, shortcutId: KeyboardShortcut): boolean => {
        const shortcut = shortcuts[shortcutId];
        let condition: boolean = !!shortcut.ctrlKey === event.ctrlKey && !!shortcut.altKey === event.altKey && !!shortcut.shiftKey === event.shiftKey;

        // on Mac, alt by default works as altGr,
        // meaning for some keyboard layouts, different character is triggered than the on user pressed (like "<" or accent characters)
        // and the event.key returns "Dead" or some special value instead of value of the pressed key.
        // ==> use event.code which always returns value of the pressed key.
        // !warning! the returned key always corresponds to the key on the keyboard and doesn't care about the system keyboard layout;
        // meaning, if the user use QUERTZ layout, event.code will still return y when y is pressed on keyboard.
        // https://stackoverflow.com/questions/61255621/trying-to-prevent-a-dead-key-from-being-typed-from-a-mac-keyboard
        // https://apple.stackexchange.com/questions/10761/how-to-disable-composite-keys-on-mac-os-x
        if (shortcut.altKey && deviceDetect.isMacOs) {
            const keyValue = event.code?.toLowerCase().startsWith("key") ? `key${shortcut.char?.toLowerCase()}` : shortcut.char?.toLowerCase();

            condition = condition && keyValue === event.code?.toLowerCase();
        } else {
            condition = condition && shortcut.char.toLowerCase() === event.key.toLowerCase();
        }

        return condition;
    };

    private getShortcutFromEvent = (event: KeyboardEvent): KeyboardShortcut => {
        if (!event.key) {
            return null;
        }

        return Object.values(shortcuts).find(shortcut => this.isEventShortcut(event, shortcut.id))?.id;
    };

    private handleKeyDown = (event: KeyboardEvent): void => {
        if (event.repeat) {
            return;
        }

        const shortcut = this.getShortcutFromEvent(event);

        if (shortcut) {
            event.preventDefault();
            this.notifySubscribers(shortcut, event);
        }
    };
}

const keyboardShortcutsManager = new KeyboardShortcutsManager();

export default keyboardShortcutsManager;