import React from "react";
import { StyledSettingItem, SettingItemButton, SettingItemLabel, Description } from "./SettingsList.style";
import { Label } from "../inputs/field/Label";
import { useTranslation } from "react-i18next";
import { Button } from "../button/Button";

export interface ISettingItemProps {
    label: string;
    description?: string;
    isCondensed?: boolean;
    onClick: () => void;
}

export class SettingItem extends React.PureComponent<ISettingItemProps> {
    render() {
        return (
            <StyledSettingItem>
                <SettingItemLabel isCondensed={this.props.isCondensed}>
                    <Label hasPadding={false}>{this.props.label}</Label>
                    <Description>{this.props.description}</Description>
                </SettingItemLabel>
                <SettingItemButton>
                    <ChangeButton onClick={this.props.onClick}/>
                </SettingItemButton>
            </StyledSettingItem>
        );
    }
}

interface IChangeButtonProps {
    onClick: () => void;
    className?: string;
    style?: React.CSSProperties;
}

export const ChangeButton = React.memo(
    ({ onClick, className, style }: IChangeButtonProps) => {
        const { t } = useTranslation(["Common"]);
        return (
            <Button isTransparent
                    onClick={onClick}
                    className={className}
                    style={style}>
                {t("Common:General.Change")}
            </Button>
        );
    }
);