import React, { Component, PureComponent } from "react";
import { FormStorage } from "../../../views/formView/FormStorage";
import { IconSize } from "../../../enums";
import { getIcon } from "@components/icon";
import memoizeOne from "../../../utils/memoizeOne";
import { IUserEntity } from "@odata/GeneratedEntityTypes";
import {
    AgendasSpan,
    CompanyRolesListStyled,
    PermissionName,
    PermissionsHeader,
    PermissionsOverviewGrid
} from "./Users.styles";
import Tooltip from "../../../components/tooltip/Tooltip";
import Tokenizer from "../../../components/token/Tokenizer";
import { TRecordString } from "../../../global.types";
import TestIds from "../../../testIds";
import { IPermissionItemProps, IUsersFormCustomData } from "./Users.utils";

interface IProps {
    storage: FormStorage<IUserEntity, IUsersFormCustomData>;
}

class CompanyPermissionItem extends PureComponent<IPermissionItemProps> {
    getTooltipText = () => {
        const tokens = this.props.permission.companies.map((id) => ({
            id: id.toString(),
            title: this.props.allCompanies[id]
        }));

        return <Tokenizer isWrappable
                          maxWidth={"fit-content"}
                          tokens={tokens}/>;
    };

    render() {
        const Icon = getIcon(this.props.iconName);
        return <div data-testid={TestIds.PermissionComponent}>
            <Icon width={IconSize.S} height={IconSize.S}/>
            <PermissionName>
                <span data-testid={TestIds.Name}>{this.props.permission.Name}</span>
                <Tooltip content={this.getTooltipText()}>
                    {(ref) =>
                        <AgendasSpan ref={ref} data-testid={TestIds.AgendaSpan}>
                            {this.props.agendas}
                        </AgendasSpan>}
                </Tooltip>
            </PermissionName>
        </div>;
    }
}

class CompanyRolesList extends Component<IProps> {
    mapAllCompanies = memoizeOne((): TRecordString => {
        const result: TRecordString = {};
        this.props.storage.context.getData().companies?.forEach((c) => {
            result[c.Id] = c.Name;
        });
        return result;
    });

    render() {
        const allCompanies = this.mapAllCompanies();
        const permissions = this.props.storage.getCustomData().permissions ?? [];
        return <CompanyRolesListStyled>
            {!!permissions.length && <>
                <PermissionsHeader>{this.props.storage.t("Users:Permissions")}</PermissionsHeader>
                <PermissionsOverviewGrid data-testid={TestIds.PermissionsGrid}>
                    {permissions.map(permission => {
                        const iconName = permission.companies?.length ? "StatusYes" : "StatusNo";
                        const agendas = permission.companies?.length ? this.props.storage.t("Users:Agendas", { count: permission.companies.length }) : "";
                        return <CompanyPermissionItem
                            key={permission.Code}
                            permission={permission}
                            iconName={iconName}
                            allCompanies={allCompanies}
                            agendas={agendas}/>;
                    })}
                </PermissionsOverviewGrid>
            </>}
        </CompanyRolesListStyled>;
    }
}

export default CompanyRolesList;