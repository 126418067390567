import { ExpandQueryBuilder } from "@odata/OData";
import { getEnrichedProperties, parseNavigationTree, prepareQuery, queryFromTree } from "@odata/OData.utils";
import { WithOData, withOData } from "@odata/withOData";
import { isObjectEmpty } from "@utils/general";
import { logger } from "@utils/log";
import { isAbortException } from "@utils/oneFetch";
import { isEmpty } from "lodash";
import { WithTranslation, withTranslation } from "react-i18next";

import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { GroupStatus, RowType, Sort, TableBatch } from "../../../enums";
import BindingContext, { IEntity } from "../../../odata/BindingContext";
import LocalSettings from "../../../utils/LocalSettings";
import { AlertPosition } from "../../alert/Alert";
import { WithAlert, withAlert } from "../../alert/withAlert";
import { IRow, TId } from "../../table";
import {
    defaultODataTableProps,
    defaultODataTableState,
    ISmartODataTableProps,
    ISmartODataTableState,
    LOADING_ROW_ID,
    SmartODataTableBase
} from "./SmartODataTableBase";
import { IFetchDataArgs } from "./SmartTable";
import { getFormattedValueFromValues, getLoadingValues, getTableKey, updateRow } from "./SmartTable.utils";
import { ISmartLoadMoreItemsEvent } from "./SmartTableBase";

const PARENT_PROP = "Parent";

interface IProps extends ISmartODataTableProps, WithOData, WithAlert, WithTranslation {
    rootEntity?: string;
    childEntity?: string;
}

interface IState extends ISmartODataTableState {
}

class SmartHierarchyTable extends SmartODataTableBase<IProps, IState> {
    static defaultProps = defaultODataTableProps;
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    state = {
        ...defaultODataTableState,
        allGroupStatus: isObjectEmpty(LocalSettings.get(getTableKey(this.props.storage, this.props.tableId)).openedRowsIds) ? GroupStatus.Expanded : GroupStatus.Unknown
    };


    async fetchData(args?: IFetchDataArgs) {
        const navProperty = this.props.hierarchy;
        let rows: Record<string, IRow> = {};
        let rowsOrder: string[] = [];
        let rowCount: number;
        let response, filteredResponse;
        const promises = [];

        // Do not trigger request with invalid filterQuery
        if (this.props.filter?.isInvalid) {
            return;
        }

        this.setState({
            loaded: false,
            allGroupStatus: isObjectEmpty(LocalSettings.get(getTableKey(this.props.storage, this.props.tableId)).openedRowsIds) ? GroupStatus.Expanded : GroupStatus.Unknown
        });

        const settings = this.props.filter?.defaultFilter && { "": { filter: this.props.filter?.defaultFilter } };
        let query = prepareQuery({
            oData: this.props.oData,
            bindingContext: this.props.bindingContext,
            fieldDefs: this.state.columns,
            settings
        }).count();

        const sortColumns = this.getSort();
        let sortId: string, sortIsAsc: boolean;

        if (sortColumns?.length > 0) {
            for (const sortColumn of sortColumns) {
                const sortColumnInfo = this.state.columns.find((col) => col.id === sortColumn.id);
                sortId = sortColumnInfo?.bindingContext.getNavigationBindingContext(sortColumnInfo.fieldSettings?.displayName).getEntityPath();

                sortIsAsc = sortColumn.sort === Sort.Asc;

                if (sortId) {
                    query = query.orderBy(sortId, sortIsAsc);
                }
            }
        }

        try {
            if (!this.props.loadAll && !args?.loadAll) {
                // query() isn't enough anymore, we need navigate() to get request on ChartsOfAccounts(1)/Accounts
                // use entityset => binding context.getKey instead of filter if key is present query.get(
                response = await query.filter(this.props.filter?.query)
                    .expand(navProperty, (q: ExpandQueryBuilder) => {
                        q.count().top(0);
                    })
                    .fetchData<IEntity[]>(this.oneFetch.fetch);

                for (const entity of response.value) {
                    const row = this.createRowProperties(entity);
                    const id = row.id.toString();

                    rows[id] = row;
                    rowsOrder.push(id);
                }

                rowCount = response._metadata.count;
            } else {
                const keyPropertyName = this.props.bindingContext.getKeyPropertyName();
                const fnSelectKeyProperty = (q: ExpandQueryBuilder) => {
                    q.select(keyPropertyName);
                };
                const fnSelectKeyPropertyAndOrder = (sortId: string) => {
                    return (q: ExpandQueryBuilder) => {
                        q.count().select(keyPropertyName);
                        // we need to correctly sort no only the root items, but hierarchy as well
                        if (sortId) {
                            q.orderBy(sortId, sortIsAsc);
                        }
                    };
                };

                if (this.props.rootEntity) {
                    const fnExpandChild = (applyFilter?: boolean) => {
                        const bc = this.props.bindingContext.navigate(this.props.childEntity);
                        const enrichedProperties = getEnrichedProperties(this.state.childColumns.map(col => col.id), bc, this.state.childColumns);
                        const parsedTree = parseNavigationTree(enrichedProperties, bc);


                        return (q: ExpandQueryBuilder) => {
                            q.expand(PARENT_PROP, fnSelectKeyProperty);

                            for (const sortColumn of sortColumns) {
                                const sortColumnInfo = this.state.childColumns.find((col) => col.id === sortColumn.id);
                                const navSortId = sortColumnInfo?.bindingContext.getNavigationBindingContext(sortColumnInfo.fieldSettings?.displayName).getEntityPath();

                                if (navSortId) {
                                    q.expand(navProperty, fnSelectKeyPropertyAndOrder(navSortId));
                                    q.orderBy(navSortId, sortIsAsc);
                                }
                            }

                            if (applyFilter) {
                                q.filter(this.props.filter?.collectionQueries?.[this.props.childEntity]?.query);
                            }

                            const automaticQuery = queryFromTree({
                                bindingContext: bc,
                                fieldDefs: this.state.childColumns,
                                navigationTree: parsedTree,
                                settings: {}
                            });

                            automaticQuery(q);
                        };
                    };

                    promises.push(query.expand(this.props.childEntity, fnExpandChild()).fetchData(this.oneFetch.fetch));

                    if (this.props.filter?.query !== this.props.filter?.defaultFilter) {
                        // WARNING, filtering will only work for properties with same name on parent and child
                        promises.push(
                            query
                                .expand(this.props.childEntity, fnExpandChild(true))
                                .filter(this.props.filter?.query)
                                .fetchData()
                        );
                    }

                    [response, filteredResponse] = await Promise.all(promises);
                } else {
                    query = await query
                        .expand(navProperty, fnSelectKeyPropertyAndOrder(sortId))
                        .expand(PARENT_PROP, fnSelectKeyProperty);

                    // this could potentially be cached when only filter value changes, but could cause problems - e.g. data could be changed in the meantime..
                    promises.push(query.fetchData(this.oneFetch.fetch));

                    if (this.props.filter?.query !== this.props.filter?.defaultFilter) {
                        promises.push(query.filter(this.props.filter?.query).fetchData());
                    }

                    [response, filteredResponse] = await Promise.all(promises);
                }

                const tree = this.buildTreeFromAllItems(response?.value ?? [], filteredResponse?.value);

                rowCount = tree.rowCount;
                rows = tree.rows;
                rowsOrder = tree.rowsOrder;
            }

        } catch (error) {
            // OneFetch -> raises AbortError exception -> ignore
            if (isAbortException(error)) {
                return;
            }
            // todo global error handling
            logger.error("error in fetchData", error);
            this.setState({
                rows: {},
                rowsOrder: [],
                rowCount: 0,
                loaded: true
            });
            return;
        }

        const isClosed = (row: IRow): boolean => {
            return (row.rows?.length && !row.open) || row.rows?.some(isClosed);
        };

        const rowsArray = this.getRowsArray(rows, rowsOrder);
        const allGroupStatus = rowsArray.some((row: IRow) => !!row.rowCount) ? rowsArray.some(isClosed) ? GroupStatus.Collapsed : GroupStatus.Expanded : GroupStatus.Unknown;

        this.setState({
            allGroupStatus,
            rows,
            rowsOrder,
            rowCount: rowCount,
            loaded: true
        }, () => {
            this.props.onAfterTableLoad?.();
            this.props.rowAction?.onTableReloaded?.();
        });
    }

    async reloadRow(bindingContext: BindingContext) {
        const isRootEntity = !this.props.childEntity || bindingContext.getPath(true) !== this.props.childEntity;
        const columns = isRootEntity ? this.getTableState().columns : this.getTableState().childColumns;

        return await this.reloadRowInternal(bindingContext, columns, (newRowData: IEntity, oldRow) => {
            // SmartHierarchyTable uses rootEntity for child entities so that it can be used in value formatters
            // we have to add this custom row property as well
            if (!isRootEntity) {
                newRowData.rootEntity = oldRow.customData.parentEntity;
            }

            return {
                ...oldRow,
                values: this.getRowValues(newRowData, columns, isRootEntity ? this.props.bindingContext : this.props.bindingContext.navigate(this.props.childEntity))
            };
        });
    }

    buildTreeFromAllItems = (allItems: IEntity[], filteredItems?: IEntity[]) => {
        const keyPropertyName = this.props.bindingContext.getKeyPropertyName();
        const allItemsById: Record<string, IEntity> = {};
        const filteredItemsById: Record<string, IEntity> = {};
        const rootItems: IEntity[] = [];
        const processedRootItems = new Set<string>();
        const rows: Record<string, IRow> = {};

        const addRootItem = (item: IEntity) => {
            rootItems.push(item);
            processedRootItems.add(item[keyPropertyName]);
        };

        const processFilteredItem = (item: IEntity) => {
            filteredItemsById[item[keyPropertyName]] = item;

            if (filteredItemsById[item[PARENT_PROP][keyPropertyName]] || processedRootItems.has(item[keyPropertyName])) {
                return null;
            }

            let rootItem = item;

            while (!isObjectEmpty(rootItem[PARENT_PROP])) {
                rootItem = allItemsById[rootItem[PARENT_PROP][keyPropertyName]];
                filteredItemsById[rootItem[keyPropertyName]] = rootItem;
            }

            return rootItem;
        };

        if (this.props.rootEntity) {
            for (const item of allItems) {
                for (const child of item[this.props.childEntity]) {
                    child.rootEntity = item;
                    allItemsById[child[keyPropertyName]] = child;
                }
                if (!filteredItems) {
                    addRootItem(item);
                }
            }

            if (filteredItems) {
                for (const parentItem of filteredItems) {
                    rootItems.push(allItems.find(rootItem => rootItem[keyPropertyName] === parentItem[keyPropertyName]));

                    for (const child of parentItem[this.props.childEntity]) {
                        child.rootEntity = parentItem;

                        processFilteredItem(child);
                    }
                }
            }
        } else {
            for (const item of allItems) {
                allItemsById[item[keyPropertyName]] = item;

                if (!filteredItems) {
                    if (isObjectEmpty(item[PARENT_PROP])) {
                        addRootItem(item);
                    }
                }
            }

            if (filteredItems) {
                for (const item of filteredItems) {
                    const rootItem = processFilteredItem(item);

                    if (rootItem) {
                        addRootItem(rootItem);
                    }
                }
            }
        }

        const firstLevelRows = this.buildTreeFromRootItems(rootItems, filteredItems ? filteredItemsById : allItemsById, rows, !!this.props.rootEntity);

        return {
            rows,
            rowsOrder: firstLevelRows.map(row => row.id.toString()),
            rowCount: rootItems.length
        };
    };

    buildTreeFromRootItems = (rootItems: IEntity[], itemsById: Record<string, IEntity>, rows: Record<string, IRow>, isRootEntity = false, parent?: IRow, parentEntity?: IEntity) => {
        const keyPropertyName = this.props.bindingContext.getKeyPropertyName();
        const rowsTree = [];

        for (const rootItem of rootItems) {
            const row = this.createRowProperties(rootItem, parent, parentEntity, isRootEntity);
            const children = [];
            const hierarchy = isRootEntity ? this.props.childEntity : this.props.hierarchy;

            if (rootItem[hierarchy].length > 0) {
                for (const child of rootItem[hierarchy]) {
                    // items can be filtered out, check if present
                    if (itemsById[child[keyPropertyName]]) {
                        if (!isRootEntity || isEmpty(child[PARENT_PROP])) {
                            children.push(itemsById[child[keyPropertyName]]);
                        }
                    }
                }

                row.rowCount = children.length;
                row.rows = this.buildTreeFromRootItems(children, itemsById, rows, false, row, rootItem);
            }
            rows[row.id.toString()] = row;
            rowsTree.push(row);
        }

        return rowsTree;
    };

    createRowProperties = (row: IEntity, parent?: IRow, parentEntity?: IEntity, isRootEntity = false): IRow => {
        const navProperty = isRootEntity ? this.props.childEntity : this.props.hierarchy;
        const type = isRootEntity ? RowType.Group : RowType.Value;
        const bc = !isRootEntity && this.props.childEntity ? this.props.bindingContext.addKey(row.rootEntity?.Id)
            .navigate(`${this.props.childEntity}`) : this.props.bindingContext;
        const columns = isRootEntity || !this.props.childEntity ? this.state.columns : this.state.childColumns;
        const id = bc.addKey(row);

        return {
            id: id,
            dataId: id.getKey().toString(),
            type,
            rowCount: row[navProperty].count,
            drilldown: !this.props.hideDrilldown,
            open: LocalSettings.get(getTableKey(this.props.storage, this.props.tableId)).openedRowsIds?.[bc.addKey(row).toString()] ?? this.state.allGroupStatus === GroupStatus.Expanded,
            isLocked: !isObjectEmpty(row[this.props.lockProperty]),
            values: columns.reduce((values: IEntity, column) => {
                values[column.id] = () => {
                    return getFormattedValueFromValues({
                        column, values: row,
                        valuesBindingContext: bc
                    });
                };
                return values;
            }, {}),
            customData: { parent, parentEntity, entity: row }
        };
    };

    loadMoreGroupItems = async ({
                                    skip = 0,
                                    top = skip + TableBatch.InfiniteLoaderMinBatchSize,
                                    groupBindingContext
                                }: { skip: number, top: number, groupBindingContext: BindingContext }) => {
        const navProperty = this.props.hierarchy;
        let response;
        const rows: Record<string, IRow> = { ...this.state.rows };

        // loading state
        this.setState(state => {
            const newRows: Record<string, IRow> = {};
            const updatedRows = updateRow(state.rows, groupBindingContext, (group: IRow) => {
                const newGroup = { ...group };
                newGroup.rows = newGroup.rows || [];

                for (let i = skip; i < skip + top; i++) {
                    const newRow: IRow = {
                        id: `${group.id}-${i}-${LOADING_ROW_ID}`,
                        type: RowType.Value,
                        values: getLoadingValues(this.state.columns)
                    };

                    newGroup.rows[i] = newRow;
                    newRows[newRow.id.toString()] = newRow;
                }

                return newGroup;
            });

            return {
                rows: {
                    ...updatedRows,
                    ...newRows
                }
            };
        });

        // backend doesn't support too much nesting
        // http://localhost:4000/api/odata/ChartsOfAccounts(1)/Accounts?$filter=Id eq 1&$expand=Children($count=true;$expand=Children($count=true)) doesn't work
        // we have to use workaround for now, by filtering on parent
        // http://localhost:4000/api/odata/ChartsOfAccounts(1)/Accounts?$filter=Parent/Id eq 1&$expand=Children($count=true;$top=0)
        // TODO remove workaround once the problem is resolved on backend
        // response = await this.props.oData.fromPath(this.props.bindingContext.toString()).query().filter(`${groupBindingContext.getKeyPropertyName()} eq ${groupBindingContext.getKey()}`)
        //     .expand(navProperty, (q: ExpandQueryBuilder) => q.expand(navProperty, (r: any) => r.top(0).count())).fetchData();
        response = await this.props.oData.fromPath(this.props.bindingContext.toString()).query().filter(`Parent/${groupBindingContext.getKeyPropertyName()} eq ${groupBindingContext.getKey()}`)
            .expand(navProperty, (q: ExpandQueryBuilder) => q.top(0).count()).fetchData<IEntity[]>();

        const values = response.value;

        this.setState(state => {
            const newRows: Record<string, IRow> = {};

            const updatedRows = updateRow(state.rows, groupBindingContext, (group: IRow) => {
                const groupRows: IRow[] = [];

                for (const entity of values) {
                    const row = this.createRowProperties(entity);
                    const id = row.id.toString();

                    groupRows.push(row);
                    newRows[id] = row;
                }

                return {
                    ...group,
                    rows: groupRows
                };
            });

            return {
                rows: {
                    ...updatedRows,
                    ...newRows
                }
            };
        });
    };

    handleLoadMoreRows = ({ startIndex, stopIndex, group: groupBindingContext }: ISmartLoadMoreItemsEvent) => {
        const skip = startIndex;
        const top = stopIndex - startIndex + 1;

        this.loadMoreGroupItems({ skip, top, groupBindingContext: groupBindingContext });
    };

    handleRowActionClick(rowId: TId, row: IRow) {
        super.handleRowActionClick(rowId, row, true);
    }
}

export default withTranslation(["Error"])(withOData(withAlert({
    autoHide: true,
    position: AlertPosition.CenteredBottom
})(SmartHierarchyTable)));