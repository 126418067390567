import React from "react";
import BusyIndicator from "../components/busyIndicator";
import { RouteComponentProps, withRouter } from "react-router-dom";
import NumberType from "../types/Number";
import { ROUTE_HOME } from "../routes";
import { WithOData, withOData } from "@odata/withOData";
import {
    EntitySetName,
    IUserNotificationEntity,
    NotificationEntity,
    UserNotificationEntity
} from "@odata/GeneratedEntityTypes";
import { getNotificationUrl } from "./Notification.utils";
import { WithNotifications, withNotifications } from "./withNotifications";
import { isExternalLink } from "@components/clickable/Clickable.utils";

interface IProps extends WithNotifications, WithOData, RouteComponentProps<{ id: string }> {

}

class NotificationsRedirect extends React.PureComponent<IProps> {
    componentDidMount() {
        this.init();
    }

    init = async () => {
        const notificationId = NumberType.parse(this.props.match.params.id);

        if (notificationId && NumberType.isValid(notificationId)) {
            try {
                const res = await this.props.oData.getEntitySetWrapper(EntitySetName.Notifications)
                    .query(notificationId).expand(UserNotificationEntity.Notification, q => q.expand(NotificationEntity.NotificationType))
                    .fetchData<IUserNotificationEntity>();
                const notification = res.value;
                const url = getNotificationUrl(notification.Notification);

                if (url) {
                    await this.props.notifications.updateNotification({
                        Id: notificationId,
                        IsRead: true
                    });
                    this.props.notifications.refreshNotifications();

                    if (isExternalLink(url)) {
                        window.location.assign(url);
                    } else {
                        this.props.history.replace(url);
                    }
                    return;
                }
            } catch (e) {
            }
        }

        this.redirectToHome();
    };

    redirectToHome = () => {
        this.props.history.replace(ROUTE_HOME);
    };

    render() {
        return (
            <BusyIndicator/>
        );
    }
}


export default withNotifications(withOData(withRouter(NotificationsRedirect)));