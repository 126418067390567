import React, { useCallback, useContext, useEffect } from "react";
import { ModalBackground } from "./Dialog.styles";
import ReactDOM from "react-dom";
import { KeyName } from "../../keyName";
import { AppContext, ContextEvents } from "../../contexts/appContext/AppContext.types";

interface IProps {
    customRootId?: string;
}

export class Modal extends React.PureComponent<IProps> {
    render() {
        const modalRoot = document.getElementById(this.props.customRootId ?? "modal-root");

        if (modalRoot) {
            return ReactDOM.createPortal(
                this.props.children,
                modalRoot
            );
        } else {
            return this.props.children;
        }
    }
}

interface IProps {
    isCentered?: boolean;
    ignoreShellPadding?: boolean;
    customRootId?: string;
    closeOnClick?: boolean;
    isBlurred?: boolean;
    onClose?: () => void;
    onKeyDown?: (event: React.KeyboardEvent) => void;
}

const ModalBackdrop: React.FC<IProps> = (props) => {
    const { closeOnClick, onClose, onKeyDown, isCentered, isBlurred, children } = props;

    const context = useContext(AppContext);

    const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
        if (event.defaultPrevented) {
            // Do not trigger action if it's handled inside the dialog
            return;
        }
        if (event.key === KeyName.Escape) {
            event.preventDefault();
            onClose?.();
        }
        onKeyDown?.(event);
    }, [onClose, onKeyDown]);

    const handleBackdropClick = useCallback(() => {
        if (closeOnClick) {
            onClose?.();
        }
    }, [closeOnClick, onClose]);

    useEffect(() => {
        context.eventEmitter?.emit(ContextEvents.ModalToggled, true);
        return () => {
            context.eventEmitter?.emit(ContextEvents.ModalToggled, false);
        };
    }, [context.eventEmitter]);

    return (
        <Modal customRootId={props.customRootId}>
            <ModalBackground tabIndex={0}
                             _isEditableWindow={isBlurred}
                             centered={isCentered !== false}
                             ignoreShellPadding={props.ignoreShellPadding}
                             onKeyDown={handleKeyDown}
                             onClick={handleBackdropClick}>
                {children}
            </ModalBackground>
        </Modal>
    );
};

export default ModalBackdrop;
