import React from "react";
import { DefaultTheme } from "styled-components";
import { TFormatterFn } from "@components/smart/smartTable/SmartTable.utils";
import { TRecordAny, TValue } from "../../global.types";
import { IFormatOptions } from "@odata/OData.utils";
import { TCellValue } from "@components/table";
import { ColoredText } from "../../global.style";
import { IGetValueArgs } from "@components/smart/FieldInfo";
import i18next from "i18next";
import { ITabData } from "@components/tabs";
import { AssetItemTypeCode, AssetStatusCode, FiscalYearStatusCode } from "@odata/GeneratedEnums";
import customFetch, { getDefaultPostParams } from "../../utils/customFetch";
import { ASSET_API_URL } from "../../constants";
import { Status } from "../../enums";
import { IAlertProps } from "@components/alert/Alert";
import {
    AssetEntity,
    AssetItemEntity,
    DocumentItemEntity,
    EntitySetName,
    IAccountingDepreciationPolicyItemEntity,
    IAssetEntity
} from "@odata/GeneratedEntityTypes";
import { Dayjs } from "dayjs";
import { getFiscalDataCorrespondingToDateAccountingTransaction } from "../documents/Document.utils";
import { FormStorage, IFormStorageDefaultCustomData } from "../../views/formView/FormStorage";
import { SECONDARY_FILTERS_ALL } from "../../views/table/TableView.utils";
import { ValidationError } from "yup";
import DateType, { getUtcDayjs } from "../../types/Date";
import { parseResponse } from "@odata/ODataParser";
import BindingContext, { IEntity } from "../../odata/BindingContext";
import { getAlertFromError } from "../../views/formView/Form.utils";
import { isDefined } from "@utils/general";
import { getSortedFYs } from "../fiscalYear/FiscalYear.utils";
import { isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { OData } from "@odata/OData";
import { cloneDeep } from "lodash";
import { updateEntity } from "@odata/Data.utils";

export const getStatusColor = (StatusCode: AssetStatusCode): keyof DefaultTheme => {
    let color: keyof DefaultTheme;

    switch (StatusCode) {
        case AssetStatusCode.InUse:
            color = "C_SEM_text_good";
            break;
        case AssetStatusCode.Created:
            color = "C_SEM_text_bad";
            break;
        default:
            color = "C_TEXT_primary";
            break;
    }

    return color;
};

export const statusFormatter: TFormatterFn = (val: TValue, args?: IFormatOptions): TCellValue => {
    const title = val as string;
    const color: keyof DefaultTheme = getStatusColor(args.entity.Status?.Code);

    const colorWrapper = <ColoredText color={color}> {val} </ColoredText>;

    return { tooltip: title, value: colorWrapper };
};

export interface IAssetFormCustomData extends IFormStorageDefaultCustomData {
    isSimplified?: boolean;
}

/**
 * Simplified view to be used when creating single minor asset with
 * @param storage
 */
export const isFullForm = ({ storage }: IGetValueArgs): boolean => {
    const st = storage as FormStorage<unknown, IAssetFormCustomData>;

    return !st.getCustomData().isSimplified;
};

export const getAssetTabs = (): ITabData[] => [
    {
        id: SECONDARY_FILTERS_ALL,
        title: i18next.t("MinorAsset:Status.All")
    }, {
        id: AssetStatusCode.Created,
        title: i18next.t("MinorAsset:Status.CRE")
    }, {
        id: AssetStatusCode.InUse,
        title: i18next.t("MinorAsset:Status.USE")
    }, {
        id: AssetStatusCode.Disposed,
        title: i18next.t("MinorAsset:Status.DIS")
    }
];

export const getAsset = (args: IGetValueArgs, prop: "entity" | "origEntity" = "entity"): IAssetEntity => {
    const formStorage = args.storage as FormStorage;
    if (isDefined(args.data) && (prop === "entity" || !isDefined(formStorage.data[prop]))) {
        return args.data as IAssetEntity;
    }
    return formStorage.data[prop] as IAssetEntity;
};

export function getMaxTransactionDateOfItemType(args: IGetValueArgs, types?: AssetItemTypeCode[]): Dayjs {
    const asset = getAsset(args);
    let maxDateTransaction: Dayjs;
    (asset.Items || []).forEach(item => {
        if (types && !types.includes(item.ItemType?.Code as AssetItemTypeCode)) {
            return; // skip this item
        }
        if (!maxDateTransaction || maxDateTransaction.isBefore(item.DateOfTransaction)) {
            maxDateTransaction = getUtcDayjs(item.DateOfTransaction);
        }
    });
    return maxDateTransaction;
}

export function getInOpenFYValidation(value: TValue, args: IGetValueArgs): ValidationError | boolean {
    if (!isInOpenFY(value, args)) {
        const translation = i18next.t(`FixedAsset:Validation.${isCashBasisAccountingCompany(args.storage.context) ? "ActiveYear" : "ActiveFY"}`);
        return new ValidationError(translation, false, args.bindingContext.getPath(true));
    }
    return true;
}

export function getBeforeFirstFiscalYearValidation(value: TValue, args: IGetValueArgs): ValidationError | boolean {
    const sortedFYs = getSortedFYs(args.context ?? args.storage?.context);
    const firstFY = sortedFYs[0];
    if (getUtcDayjs(value as Date).isSameOrAfter(firstFY.DateStart, "date")) {
        // error
        const opts = {
            date: DateType.format(getUtcDayjs(firstFY.DateStart).subtract(1, "day"))
        };
        return new ValidationError(i18next.t("FixedAsset:Validation.OldAssetNotEnoughOld", opts), false, args.bindingContext.getPath(true));
    }
    return true;
}

export function getSameOrLaterItemOfTypeValidation(value: TValue, args: IGetValueArgs, types?: AssetItemTypeCode[]): ValidationError | boolean {
    const maxTransactionDate = getMaxTransactionDateOfItemType(args, types);
    if (maxTransactionDate && !maxTransactionDate.isSameOrBefore(value as Date, "day")) {
        // error
        const opts = {
            date: DateType.format(maxTransactionDate)
        };
        return new ValidationError(i18next.t("FixedAsset:Validation.LastAssetItem", opts), false, args.bindingContext.getPath(true));
    }
    return true;
}

export function getValidationResult(...args: (ValidationError | boolean)[]): ValidationError | boolean {
    return args.find(result => result !== true) ?? true;
}

export function fetchAccountingDepreciationPolicyItems(storage: FormStorage): Promise<IAccountingDepreciationPolicyItemEntity[]> {
    const bc = storage.data.bindingContext.navigate("AccountingDepreciationPolicy/Items");

    const query = storage.oData.fromPath(bc.getFullPath(false))
        .query()
        .select("Id", "DateDepreciation", "Order", "AccumulatedDepreciation", "CalculatedExpense", "EndValue")
        .orderBy("Order");

    return query.fetchData<IAccountingDepreciationPolicyItemEntity[]>()
        .then(result => result?.value);
}

export function getLastDepreciationBeforeDate(items: IAccountingDepreciationPolicyItemEntity[], date: Date | Dayjs): IAccountingDepreciationPolicyItemEntity {
    const limitDayjs = getUtcDayjs(date);
    let lastPolicy: IAccountingDepreciationPolicyItemEntity;
    items.forEach(item => {
        if ((!lastPolicy || getUtcDayjs(lastPolicy.DateDepreciation).isBefore(item.DateDepreciation, "day"))
            && limitDayjs.isSameOrAfter(item.DateDepreciation, "day")) {
            lastPolicy = item;
        }
    });
    return lastPolicy;
}

export function isInOpenFY(value: TValue, args: IGetValueArgs): boolean {
    const { fiscalYear } = getFiscalDataCorrespondingToDateAccountingTransaction(args.storage as FormStorage, value as Date);
    return [FiscalYearStatusCode.Active, FiscalYearStatusCode.NotUsed].includes(fiscalYear?.StatusCode as FiscalYearStatusCode);
}

export interface IReturnValue {
    alert: IAlertProps;
    data: IAssetEntity;
}

export async function fetchDataAndParseError(url: string, postData: TRecordAny): Promise<IEntity> {
    const response = await customFetch(url, {
        ...getDefaultPostParams(),
        body: JSON.stringify(postData)
    });

    const body = await parseResponse(response);
    if (!response.ok) {
        throw body;
    }
    return body;
}

export async function processAssetRequest(url: string, postData: TRecordAny, subTitle?: string): Promise<IReturnValue> {
    let alert: IAlertProps;
    let data: IAssetEntity;

    try {
        data = await fetchDataAndParseError(url, postData) as IAssetEntity;
        alert = {
            status: Status.Success,
            title: i18next.t("Common:Validation.SuccessTitle"),
            subTitle
        };
    } catch (e) {
        alert = getAlertFromError(e);
    }
    return { alert, data };
}

export function pairDocumentItemsWithAsset(entryIds: number[], AssetId: string | number): Promise<IReturnValue> {
    const data = {
        DocumentItemIds: entryIds,
        AssetId
    };

    return processAssetRequest(`${ASSET_API_URL}/${AssetItemTypeCode.Acquisition}`, data, i18next.t("FixedAsset:Unorganized.AssetPaired"));
}

/**
 * Load current asset items, remove the ones that has same DocumentItems as entryIds and save the asset
 * @param oData
 * @param entryIds
 * @param assetBc
 */
export async function unpairDocumentItemsFromAsset(oData: OData, entryIds: number[], assetBc: BindingContext): Promise<IReturnValue> {
    const assetId = assetBc.getKey();

    try {
        const result = await oData.getEntitySetWrapper(EntitySetName.Assets).query(assetId)
                .expand(AssetEntity.Items, (query) =>
                        query.select(AssetItemEntity.Id).expand(AssetItemEntity.DocumentItem, (subQuery) => subQuery.select(DocumentItemEntity.Id)))
                .fetchData<IAssetEntity>();

        const originalAsset = result.value;
        const newAsset = cloneDeep(originalAsset);
        newAsset.Items = newAsset.Items?.filter(item => !entryIds.includes(item.DocumentItem?.Id));

        const batch = oData.batch();
        batch.beginAtomicityGroup("updateAsset");

        updateEntity({
            entity: newAsset,
            originalEntity: originalAsset,
            bindingContext: assetBc,
            batch,
            changesOnly: true,
        });

        await batch.execute();
    } catch (e) {
        return {
            alert: getAlertFromError(e),
            data: null
        };
    }

    return {
        alert: {
            status: Status.Success,
            title: i18next.t("Common:Validation.SuccessTitle"),
            subTitle: i18next.t("FixedAsset:Unorganized.AssetUnpaired")
        },
        data: null
    };
}

interface IAddAssetItem {
    DocumentItemIds: number[];
    Name: string;
    Labels?: { LabelHierarchyId: number; LabelId: number }[];
    NumberOurs?: string;
    NumberRangeId?: number;
}

export async function createAssetFromEntries(entryIds: number[], Asset: Partial<IAssetEntity>): Promise<IReturnValue> {
    const data: IAddAssetItem = {
        DocumentItemIds: entryIds,
        Name: Asset.Name
    };

    data.Labels = Asset.Labels?.map(Label => ({
        LabelHierarchyId: Label.LabelHierarchy.Id,
        LabelId: Label.Label.Id
    })) ?? [];
    if (Asset.NumberRange?.Id) {
        data.NumberRangeId = Asset.NumberRange.Id;
    } else {
        data.NumberOurs = Asset.NumberOurs;
    }

    return processAssetRequest(`${ASSET_API_URL}/${AssetItemTypeCode.Acquisition}`, data, i18next.t("FixedAsset:Unorganized.AssetCreated"));
}