import React from "react";
import {
    ChangeButtonStyled,
    SettingListItem2Content,
    SettingListItem2LeftSide,
    SettingListItem2Wrapper,
    StyledSettingListItem2,
    StyledSettingsList2
} from "./SettingsList2.style";
import { Label } from "../inputs/field";
import TestIds from "../../testIds";

export interface ISettingItem2Props {
    id: string;
    label: string;
    content: React.ReactNode;
    isWithoutButton?: boolean;
    children?: ISettingItem2Props[];
}

interface IProps {
    items: ISettingItem2Props[];
    onChangeClick: (itemId: string) => void;
}


export default class SettingsList2 extends React.PureComponent<IProps> {
    getItemChildrenCount = (item: ISettingItem2Props): number => {
        if (!item.children || item.children.length === 0) {
            return 0;
        }

        return item.children.reduce((count, child) => {
            return count + this.getItemChildrenCount(child);
        }, item.children.length);
    };

    renderItems = (items: ISettingItem2Props[], level = 0): React.ReactNode => {
        return items.map(item => {
            return (
                <SettingListItem2Wrapper data-testid={TestIds.SettingsListItem}
                                         key={item.id}>
                    <StyledSettingListItem2 $level={level}
                                            $childrenCount={this.getItemChildrenCount(item)}>
                        <SettingListItem2LeftSide>
                            <Label hasPadding={false}>{item.label}</Label>
                            <SettingListItem2Content
                                    data-testid={TestIds.Content}
                                    isWithoutButton={item.isWithoutButton}>
                                {item.content}
                            </SettingListItem2Content>
                        </SettingListItem2LeftSide>
                        {!item.isWithoutButton && <ChangeButtonStyled onClick={() => {
                            this.props.onChangeClick?.(item.id);
                        }}/>}
                    </StyledSettingListItem2>
                    {item.children && this.renderItems(item.children, level + 1)}
                </SettingListItem2Wrapper>
            );
        });
    };

    render() {
        return (
            <StyledSettingsList2 data-testid={TestIds.SettingsList}>
                {this.renderItems(this.props.items)}
            </StyledSettingsList2>
        );
    }
}