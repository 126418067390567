import React from "react";
import { WithAuthContext, withAuthContext } from "../contexts/authContext/withAuthContext";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { isNonCustomerPortalRoute, ROUTE_LOGIN } from "../routes";
import { WithPermissionContext, withPermissionContext } from "../contexts/permissionContext/withPermissionContext";
import { NoPermission } from "../views/notFound";
import { CompanyPermissionCode, GeneralPermissionCode } from "@odata/GeneratedEnums";
import { getQueryParameters, getRedirectUriQueryParam } from "./Routes.utils";
import { PageViewMode, QueryParam } from "../enums";
import { SessionType } from "../contexts/authContext/Auth.utils";

interface IProps extends WithAuthContext, WithPermissionContext, RouteProps {
    id?: string;
    permission: CompanyPermissionCode | GeneralPermissionCode;
}

class PrivateRoute extends React.PureComponent<IProps> {
    render() {
        const { authContext, ...routeProps } = this.props;
        const permissionContext = this.props.permissionContext;

        if (authContext.sessionType === SessionType.Customer) {
            // todo: should we stay with the opt-out approach for customer login?
            if (isNonCustomerPortalRoute(routeProps.location?.pathname)) {
                routeProps.component = NoPermission;
                routeProps.render = null;
            }
        } else if (this.props.permission && !permissionContext.companyPermissions.has(this.props.permission as CompanyPermissionCode) &&
            !permissionContext.generalPermissions.has(this.props.permission as GeneralPermissionCode)) {

            // this is special case for intent navigation in edge case when you have permission for reports, but
            // not for invoices, then you should be able, to see invoice in view mode after using intent navigation, yet
            // you should not see invoices screen, these are just FE security through obscurity as on BE read permission
            // is available for all entities regardless on permissions
            const viewMode = getQueryParameters()?.[QueryParam.ViewMode];
            if (viewMode !== PageViewMode.FormReadOnly) {
                routeProps.component = NoPermission;
                routeProps.render = null;
            }
        }

        return authContext.isAuthenticated
            ? <Route {...routeProps}/>
            : <Redirect to={{
                pathname: ROUTE_LOGIN,
                    search: window.location?.pathname && window.location.pathname !== "/" ? `?${getRedirectUriQueryParam()}` : null
            }}/>;
    }
}

export default withPermissionContext(withAuthContext(PrivateRoute));