import TableView, { ITableViewBaseProps } from "../../views/table/TableView";
import { WithOData, withOData } from "@odata/withOData";
import { TableButtonsAction, TableButtonsActionType } from "../../views/table/TableToolbar";

class BackgroundJobsHistory extends TableView<ITableViewBaseProps & WithOData> {
    getToolbarButtons(): TableButtonsActionType[] {
        return [
            TableButtonsAction.Sorting,
            TableButtonsAction.Settings
        ];
    }
}

export default withOData(BackgroundJobsHistory);
