import React from "react";
import { IDefinition } from "./PageUtils";
import { ITableViewBaseProps } from "../views/table/TableView";
import { TSmartODataTableStorage } from "@components/smart/smartTable/SmartODataTableBase";
import { ISplitPageProps, SplitPageBase } from "./SplitPageBase";
import { DomManipulatorProvider } from "../contexts/domManipulator/DomManipulatorProvider";

export interface IProps extends Omit<ISplitPageProps<IDefinition>, "renderTable"> {
    getDefinitions?: (storage?: TSmartODataTableStorage) => IDefinition;
    onTableStorageLoad?: (storage: TSmartODataTableStorage) => Promise<void>;
    tableView: React.ComponentType<ITableViewBaseProps>;
}

class SplitPage extends React.Component<IProps> {
    renderTable = (args: any) => {
        const Table = this.props.tableView;

        return (
            <DomManipulatorProvider>
                <Table {...args}/>
            </DomManipulatorProvider>
        );
    };

    render() {
        return (
            <SplitPageBase {...this.props} renderTable={this.renderTable}/>
        );
    }
}

export { SplitPage };