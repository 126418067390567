import i18next from "i18next";
import { Sort, TextAlign } from "../../enums";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../PageUtils";
import { IFormDef } from "../../views/formView/Form";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { setDefByEntityType } from "../getDefByEntityType";
import {
    EntitySetName,
    EntityTypeName,
    FileMetadataEntity,
    ITicketEntity,
    TicketEntity,
    TicketMessageAttachmentEntity,
    TicketMessageEntity,
    UserCompanyRoleEntity,
    UserEntity
} from "@odata/GeneratedEntityTypes";
import { ISplitPageTableDef, ITableDefTabData } from "../../views/table/TableView.utils";
import { withDisplayName } from "@components/smart/GeneralFieldDefinition";
import TicketsFormView from "./TicketsFormView";
import BindingContext from "../../odata/BindingContext";
import { TicketStatusCode } from "@odata/GeneratedEnums";
import { getEnumNameSpaceName } from "@odata/GeneratedEnums.utils";
import { getTicketStatusColor, getTicketStatusDisplayValue, isSaved, ticketStatusFormatter, } from "./Tickets.utils";
import { TValue } from "../../global.types";
import { dateTimeFormatter, relativeTimeTableFormatter } from "@components/relativeTime/relativeTime";
import { IFormatOptions } from "@odata/OData.utils";
import { Model } from "../../model/Model";

const LastMessagePath = BindingContext.localContext("DateLastModified");


export const getDefinitions: IGetDefinition = (): IDefinition => {
    const filterBarDef: IFilterGroupDef[] = [{
        ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
        defaultFilters: [
            "Title",
            "TicketStatus"
        ],
        filterDefinition: {
            Title: {},
            ...withDisplayName("TicketStatus"),
        },
        isValueHelp: true
    }];

    const tabs: ITableDefTabData[] = [{
        id: null,
        title: i18next.t("Components:FilterBar.All")
    }, {
        id: TicketStatusCode.InProgress,
        title: getTicketStatusDisplayValue(TicketStatusCode.InProgress)
    }, {
        id: TicketStatusCode.Done,
        title: getTicketStatusDisplayValue(TicketStatusCode.Done)
    }];

    const table: ISplitPageTableDef = {
        id: "CustomerTicketsTable",
        filterBarDef,
        tabs,
        tabsSettings: {
            filterFieldName: TicketEntity.TicketStatusCode
        },
        columns: [
            "Title",
            "TicketStatus",
            "DateLastModified",
            "Number",
            LastMessagePath,
            "DateCreated"
        ],
        columnDefinition: {
            Title: {},
            TicketStatus: {
                textAlign: TextAlign.Center,
                formatter: ticketStatusFormatter
            },
            DateLastModified: {
                label: i18next.t("Tickets:TableView.LastModified"),
                formatter: dateTimeFormatter
            },
            Number: {},
            DateCreated: {
                label: i18next.t("Tickets:TableView.DateCreated"),
                formatter: relativeTimeTableFormatter
            },
            [LastMessagePath]: {
                label: i18next.t("Tickets:TableView.LastMessage"),
                textAlign: TextAlign.Right,
                fieldSettings: {
                    disableSort: true
                },
                formatter: (val: TValue, args: IFormatOptions) =>
                    relativeTimeTableFormatter((args.entity as ITicketEntity).DateLastModified, args)
            }
        },
        title: i18next.t("Tickets:Title"),
        initialSortBy: [{ id: "DateLastModified", sort: Sort.Desc }]
    };

    const summary: ISummaryItem[] = [{
        id: "Number",
        label: null
    }, {
        id: "TicketStatus/Code",
        label: i18next.t("Tickets:Status"),
        formatter: (val: TValue) => {
            return getTicketStatusDisplayValue(val as TicketStatusCode);
        },
        colorFormatter: (val: TValue) => {
            return getTicketStatusColor(val as TicketStatusCode);
        },
        isVisible: isSaved
    }];

    const userEntityAdditionalProps = [
        { id: UserEntity.Name },
        {
            id: UserEntity.CompanyRoles,
            additionalProperties: [
                { id: UserCompanyRoleEntity.Company },
                { id: UserCompanyRoleEntity.CompanyRole }
            ]
        }
    ];

    const form: IFormDef = {
        id: "CustomerTicketsForm",
        title: i18next.t("Tickets:TitleSingular"),
        summary,
        translationFiles: getDefinitions.translationFiles,
        formControl: TicketsFormView,
        getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t("Tickets:New"),
                        storage.data.entity?.Number && i18next.t("Tickets:BreadcrumbDetail", { number: storage.data.entity.Number })),
        fieldDefinition: {},
        additionalProperties: [
            // todo: load data using additional properties as we don't have usual form layout...
            //  (we have custom render of the "form")
            { id: TicketEntity.Title },
            {
                id: TicketEntity.CreatedBy,
                additionalProperties: userEntityAdditionalProps
            },
            {
                id: TicketEntity.Messages,
                additionalProperties: [
                    { id: TicketMessageEntity.DateCreated },
                    { id: TicketMessageEntity.IsReadByTicketAssignee },
                    { id: TicketMessageEntity.IsReadByTicketAuthor },
                    { id: TicketMessageEntity.Text },
                    {
                        id: TicketMessageEntity.CreatedBy,
                        additionalProperties: userEntityAdditionalProps
                    }, {
                        id: TicketMessageEntity.Attachments,
                        additionalProperties: [
                            { id: TicketMessageAttachmentEntity.Description },
                            {
                                id: TicketMessageAttachmentEntity.File,
                                additionalProperties: [
                                    { id: FileMetadataEntity.Id },
                                    { id: FileMetadataEntity.Name }
                                ]
                            }
                        ]
                    }
                ]
            },
        ]
    };

    return {
        entitySet: EntitySetName.Tickets,
        table,
        form
    };
};

getDefinitions.translationFiles = [
    "Tickets",
    "Error", "Components",
    getEnumNameSpaceName(EntityTypeName.TicketStatus)
];
setDefByEntityType(EntityTypeName.Ticket, getDefinitions);
