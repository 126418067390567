import customFetch from "../../utils/customFetch";
import { CacheCleaner } from "@utils/CacheCleaner";
import { CacheStrategy, QueryParam } from "../../enums";
import LocalSettings from "../../utils/LocalSettings";
import p13n from "../../utils/p13n";
import { CustomerPortal } from "@pages/home/CustomerPortal.utils";
import { THistoryLocation } from "@components/drillDown/DrillDown.utils";
import { isDefined } from "@utils/general";
import { isExternalLink } from "@components/clickable/Clickable.utils";
import { IAppContext } from "./AppContext.types";

export const personalizationKey = "FePersonalization";
// how much time should we wait before polling BE again on screens that waits for some kind of (email) confirmation
export const BePollingInterval = 5000;

export interface IPersonalizationSettings {
    currentCompany: number;
    previousCompany?: number;
    selectedInboxFolders?: string[];
    fileUploaderLayout?: string;
}

export class PersonalizationSettings {
    update<T extends IPersonalizationSettings = IPersonalizationSettings, K extends keyof T = keyof T>(name: K, data: T[K] = null): Promise<boolean> {
        if (CustomerPortal.isActive) {
            // do not persist setting when in customer portal
            return Promise.resolve(false);
        }
        return p13n.update<T, K>(personalizationKey, name, data);
    }

    get(name?: string, forceUpdate?: boolean): Promise<IPersonalizationSettings> {
        return p13n.get<IPersonalizationSettings>(personalizationKey, name, forceUpdate);
    }
}

export const personalizationSettings = new PersonalizationSettings();

export async function changeCompany(companyId: number, isCustomerPortal = true): Promise<void> {

    customFetch.setCompanyId(companyId);
    CacheCleaner.clear(CacheStrategy.Company);
    LocalSettings.clear();

    if (isCustomerPortal) {
        // store only in session for current tab, so user may use the customer portal in a tab,
        // while using Evala in another company
        CustomerPortal.isActive = true;
    }
}

/** Enhances given link with current CompanyId */
export const addCompanyIdToUrl = <T extends THistoryLocation>(link: T, contextOrCompanyId: IAppContext | number, returnAsRelativePath?: boolean): T => {
    const companyId = typeof contextOrCompanyId === "number" ? contextOrCompanyId : contextOrCompanyId?.getCompanyId();

    if (!link || !isDefined(companyId)) {
        return link;
    }

    if (typeof link === "string") {
        if (isExternalLink(link, window.location, true)) {
            return link;
        }

        const url = new URL(link, window.location.origin);

        if (!url.searchParams.get(QueryParam.CompanyId)) {
            url.searchParams.append(QueryParam.CompanyId, companyId.toString());
        }

        return `${url.pathname}${url.search}` as T;
    } else {
        const searchParams = new URLSearchParams(link.search);

        if (!searchParams.get(QueryParam.CompanyId)) {
            searchParams.append(QueryParam.CompanyId, companyId.toString());
        }

        link.search = searchParams.toString();

        return link;
    }
};