import { withTranslation, WithTranslation } from "react-i18next";
import React from "react";
import { Header, SeparatorStyledFormGroup, Title } from "./FormGroup.styles";
import TestIds from "../../testIds";
import { IconButton } from "../button";
import { CloseIcon } from "../icon";
import { IconSize } from "../../enums";
import { TooltipIconInfo } from "../tooltipIcon";

interface IGroupHeaderProps extends WithTranslation {
    title?: string;
    onClose?: () => void;
    isRemovable?: boolean;
    showLine?: boolean;
    tooltip?: React.ReactElement | string;
    className?: string;
    style?: React.CSSProperties;
}

class GroupHeader extends React.Component<IGroupHeaderProps> {
    render() {
        if (!this.props.title) {
            return null;
        }

        return (
            <Header data-testid={TestIds.FormGroupHeader}
                    className={this.props.className}
                    style={this.props.style}>
                {/*todo move title, tooltip and children to Separator*/}
                <Title data-testid={TestIds.Title}>{this.props.title.toUpperCase()}</Title>
                {this.props.tooltip && <TooltipIconInfo>{this.props.tooltip}</TooltipIconInfo>}
                {this.props.children}
                {this.props.showLine && <SeparatorStyledFormGroup/>}
                {this.props.isRemovable &&
                    <IconButton onClick={this.props.onClose}
                                isDecorative
                                title={this.props.t("Common:General.Remove")}>
                        <CloseIcon width={IconSize.S} height={IconSize.S}/>
                    </IconButton>
                }
            </Header>
        );
    }
}

const GroupHeaderBaseWithTranslation = withTranslation(["Common"])(GroupHeader);
export { GroupHeaderBaseWithTranslation as GroupHeader };
