import React from "react";
import { useTranslation } from "react-i18next";
import { IconButton } from "../../button";
import { IconSize } from "../../../enums";
import { CheckIcon, CloseIcon } from "../../icon";
import styled from "styled-components/macro";

export interface IFieldConfirmButtons {
    onCancel: () => void;
    onConfirm: () => void;
    onBlur?: (e: React.FocusEvent) => void;
    passRef?: React.Ref<HTMLDivElement>;
    className?: string;
}

const StyledFieldConfirmButtons = styled.div`
  display: flex;
  z-index: 1;
`;

export const FieldConfirmButtons = React.memo(
    ({ onCancel, onConfirm, onBlur, passRef, className }: React.PropsWithChildren<IFieldConfirmButtons>) => {
        const { t } = useTranslation(["Common"]);

        return (
            <StyledFieldConfirmButtons ref={passRef} className={className}>
                <IconButton title={t("Common:General.Cancel")} onClick={onCancel} onBlur={onBlur}
                            isDecorative>
                    <CloseIcon width={IconSize.S} height={IconSize.S}/>
                </IconButton>
                <IconButton title={t("Common:General.Save")} onClick={onConfirm} onBlur={onBlur}
                            isDecorative>
                    <CheckIcon width={IconSize.S} height={IconSize.S}/>
                </IconButton>
            </StyledFieldConfirmButtons>
        );
});