import { ChartType } from "@components/charts";
import { TChartValueFormatterFn } from "@components/charts/Charts.types";
import {
    DashboardTileType,
    IChartTileInfo,
    IDashboardDefinition,
    IGetTileDataArgs,
    ILinkTileInfo,
    ITableTileData,
    TTileDefinition
} from "@components/dashboard";
import { GrowthNegativeIcon, GrowthPositiveIcon, GrowthZeroIcon } from "@components/icon";
import {
    formatDateToDateString,
    getSlidingInterval,
    IDateInterval,
    IDayjsInterval
} from "@components/inputs/date/utils";
import { EMPTY_DASH } from "@components/readOnlyList/ReadOnlyList";
import { ifAll, IGetValueArgs, not } from "@components/smart/FieldInfo";
import { IReportRowDef } from "@components/smart/smartTable";
import { IColumn, IRow, TCellValue } from "@components/table";
import { IChartTileData } from "@components/tiles/chartTile/ChartTile";
import { infoTileCurrencyFormatter } from "@components/tiles/infoTile";
import { createPath } from "@odata/BindingContext";
import {
    BusinessPartnerEntity,
    DocumentBusinessPartnerEntity,
    DocumentEntity,
    EntitySetName,
    EntityTypeName,
    IDocumentEntity
} from "@odata/GeneratedEntityTypes";
import { CurrencyCode, DocumentTypeCode, ElectronicSubmissionTypeCode } from "@odata/GeneratedEnums";
import { getEnumDisplayValue } from "@odata/GeneratedEnums.utils";
import { OData } from "@odata/OData";
import { transformToODataString } from "@odata/OData.utils";
import { getCompanyCurrency, isAccountAssignmentCompanyValue, isVatRegisteredCompany } from "@utils/CompanyUtils";
import { forEachKey, ifPositive } from "@utils/general";
import i18next from "i18next";
import React from "react";

import {
    AGING_AP_API_URL,
    AGING_AR_API_URL,
    ASSET_DEPRECIATION_MONTH_API_URL,
    CBA_INCOME_EXPENSE_OVERVIEW_API_URL
} from "../../constants";
import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { IconSize, Status, TextAlign, ValueType } from "../../enums";
import { TRecordType } from "../../global.types";
import {
    ROUTE_ACCOUNTING_JOURNAL,
    ROUTE_AGING_AP,
    ROUTE_AGING_AR,
    ROUTE_ASSET_ANALYSIS,
    ROUTE_BALANCE_SHEET,
    ROUTE_CBA_ENTRY_LEDGER,
    ROUTE_CUSTOMER_PORTAL_PAYMENT,
    ROUTE_DOCUMENT_JOURNAL,
    ROUTE_GENERAL_LEDGER,
    ROUTE_INBOX_CUSTOMER_APPROVAL,
    ROUTE_INBOX_SEND_TO_ACCOUNTANT,
    ROUTE_INCOME_STATEMENT,
    ROUTE_PAYMENT_JOURNAL,
    ROUTE_TICKETS
} from "../../routes";
import { formatCurrency, getCurrencyScale } from "../../types/Currency";
import DateType, { getUtcDate, getUtcDayjs } from "../../types/Date";
import customFetch, { getDefaultPostParams } from "../../utils/customFetch";
import memoize from "../../utils/memoize";
import { getUnpaidDocumentsForPayment } from "../customerPortalPayment/CustomerPortalPaymentUtils";
import { getLastSubmissionByType } from "../electronicSubmission/ElectronicSubmission.utils";
import { getActiveFiscalYear, getOldestActiveFY } from "../fiscalYear/FiscalYear.utils";
import { getInboxForApprovalCount } from "../inbox/Inbox.utils";
import { AgingIssuedTypes, AgingReceivedTypes, AgingType } from "../reports/aging/AgingBase";
import { afterDueDateDefaultRange } from "../reports/customFilterComponents/AgingInterval";
import {
    CbaIncomeExpenseType,
    IReportData,
    IReportSettings,
    NumberAggregationFunction,
    TimeAggregationFunction
} from "../reports/Report.utils";
import { getUnfinishedTicketCount } from "../tickets/Tickets.utils";
import { PercentageWrapper, SemanticText } from "./Home.style";
import {
    getAssetAnalysisData,
    getChartLabels,
    getIncomeExpenseOverviewTileDef,
    getInfoDataTileCellValueWithSeverity,
    getJournalSummaryData,
    getWelcomeTileDef,
    isInCustomerPortal,
    timeAggregationToOpUnitType,
    VAT_ACCOUNT_PREFIX
} from "./Home.utils";

type TBasicLinkTileConfig = Partial<ILinkTileInfo> & Pick<ILinkTileInfo, "link">;

const _isVatPayer = (args: IGetValueArgs) => isVatRegisteredCompany(args.context);

export const linkConfigs: TRecordType<TBasicLinkTileConfig> = {
    Tickets: { link: ROUTE_TICKETS, count: getUnfinishedTicketCount },
    CustomerPortalPayment: { link: ROUTE_CUSTOMER_PORTAL_PAYMENT, count: getUnpaidDocumentsForPayment },
    Inbox: { link: ROUTE_INBOX_CUSTOMER_APPROVAL, count: getInboxForApprovalCount },
    SendToAccountant: { link: ROUTE_INBOX_SEND_TO_ACCOUNTANT },
    AccountingJournal: { link: ROUTE_ACCOUNTING_JOURNAL, isVisible: isAccountAssignmentCompanyValue },
    CbaEntryLedger: { link: ROUTE_CBA_ENTRY_LEDGER, isVisible: not(isAccountAssignmentCompanyValue) },
    BalanceSheet: { link: ROUTE_BALANCE_SHEET, isVisible: isAccountAssignmentCompanyValue },
    // not ready, hidden
    // CustomerPortalInvoicing: { link: ROUTE_CONNECT_WITH_IDOKLAD },
    GeneralLedger: { link: ROUTE_GENERAL_LEDGER, isVisible: isAccountAssignmentCompanyValue },
    IncomeStatement: { link: ROUTE_INCOME_STATEMENT, isVisible: isAccountAssignmentCompanyValue },
    DocumentJournal: { link: ROUTE_DOCUMENT_JOURNAL },
    PaymentJournal: { link: ROUTE_PAYMENT_JOURNAL }
};

export const ticketsWidgetId: keyof typeof linkConfigs = "Tickets";
export const customerPortalPaymentId: keyof typeof linkConfigs = "CustomerPortalPayment";

export const getLinkTileConfig = (id: string, cfg: TBasicLinkTileConfig): ILinkTileInfo => ({
    type: DashboardTileType.Link,
    title: i18next.t(`Home:CustomerPortalLink.${id}`),
    size: { w: 1, h: 1 },
    iconName: id,
    ...cfg
});


// todo: unify with ExchangeRateDiffScreen.tsx
function getBestBusinessPartnerTableDataCellValues(name: string, thisYearValue: number, lastYearValue: number, context: IAppContext): TRecordType<TCellValue> {
    const formattedValue = formatCurrency(thisYearValue, getCompanyCurrency(context));

    const diff = thisYearValue - lastYearValue;
    const severity = ifPositive<Status>(diff, Status.Success, Status.Error, Status.Warning);
    const Icon = ifPositive(diff, GrowthPositiveIcon, GrowthNegativeIcon, GrowthZeroIcon);
    const percentage = thisYearValue !== 0 && lastYearValue !== 0 ? Math.abs(Math.round(100 * thisYearValue / lastYearValue - 100)) : 100;

    return {
        Name: {
            tooltip: name,
            value: (<strong>{name}</strong>)
        },
        Value: formattedValue,
        Difference: {
            tooltip: `${percentage} %`,
            value: (
                <PercentageWrapper>
                    <Icon width={IconSize.XS} status={severity}/>
                    <SemanticText severity={severity}>{percentage}&nbsp;%</SemanticText>
                </PercentageWrapper>
            )
        }
    };
}

const REVENUES_ACCOUNT_PREFIX = "6";
const EXPENSES_ACCOUNT_PREFIX = "5";

function getCbaEntryLedgerParams(interval: IDateInterval, aggregationFn?: TimeAggregationFunction, onlyIncome = false): IReportSettings {
    const Groups = aggregationFn ? [{
        ColumnAlias: "CbaIncomeExpenseOverview_DocumentDate",
        AggregationFunction: aggregationFn
    }] : [];

    const Type = onlyIncome ? CbaIncomeExpenseType.Income : CbaIncomeExpenseType.All;

    return {
        DateRange: {
            DateStart: formatDateToDateString(interval.from),
            DateEnd: formatDateToDateString(interval.to)
        },
        IncludeUnrelatedToBusiness: false,
        Type,
        ReportHierarchy: {
            Aggregate: true,
            Groups,
            Columns: [],
            Aggregations: [{
                ColumnAlias: "CbaIncomeExpenseOverview_AmountTaxed",
                AggregationFunction: NumberAggregationFunction.Sum
            }]
        }
    };
}

const getCbaEntryLedgerSummaryData = memoize(async (interval: IDateInterval, aggregationFn: TimeAggregationFunction, onlyIncome = false): Promise<number[]> => {
    const aggregatedPoints = getChartLabels(interval, aggregationFn, "YYYY-MM-DD");

    const _extractAggregatedData = (rows: IReportRowDef[]): number[] => {
        return aggregatedPoints.map(formattedDate => {
            const groupedResult = rows?.find(row => row.Type === "MergedGroup" && row.Value[`CbaIncomeExpenseOverview_DocumentDate_${aggregationFn}`].toString().startsWith(formattedDate));
            return groupedResult ? groupedResult.Value[`CbaIncomeExpenseOverview_AmountTaxed_SUM`] as number : 0;
        });
    };

    let ret: number[] = [];

    try {
        const params = getCbaEntryLedgerParams(interval, aggregationFn, onlyIncome);

        ret = await customFetch(CBA_INCOME_EXPENSE_OVERVIEW_API_URL, {
            ...getDefaultPostParams(),
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then((data: IReportData) => _extractAggregatedData(data.Rows));

    } catch (e) {
        // todo: handleError
        throw e;
    }

    return ret;
}, (interval, aggregationFn) => [interval.from, interval.to, aggregationFn]);

async function getJournalDataForCurrentFYForInfoTile(account: string, trPrefix: string, {
    context
}: IGetTileDataArgs) {
    const currentFY = getActiveFiscalYear(context);
    const currentFYInterval = {
        from: getUtcDayjs(currentFY?.DateStart),
        to: getUtcDayjs(currentFY?.DateEnd)
    };
    const [value] = await getJournalSummaryData(account, currentFYInterval);
    return [{
        ...getInfoDataTileCellValueWithSeverity(value),
        label: i18next.t(`Home:${trPrefix}.Label`),
        unit: "Kč"
    }];
}

async function getRevenueGrowthChartTileData(): Promise<IChartTileData<ChartType.Line>> {
    const dataSetLabels = ["LastYear", "ThisYear"].map(key => i18next.t(`Home:RevenueGrowth.${key}`));
    const step = TimeAggregationFunction.Month;
    const { previous, current } = getSlidingInterval(getUtcDayjs().endOf(timeAggregationToOpUnitType(step)), "year");
    const labels = getChartLabels(current, step);

    const values = await Promise.all([previous, current].map(interval => getJournalSummaryData(REVENUES_ACCOUNT_PREFIX, interval, step)));

    return {
        type: ChartType.Line,
        chartProps: {
            labels,
            filled: true,
            dataSetLabels,
            colors: ["C_CHART_orange", "C_CHART_red"],
            values
        }
    };
}

async function getRevenueExpenseDiffChartTileData(): Promise<IChartTileData<ChartType.Line>> {
    const step = TimeAggregationFunction.Month;
    const { current } = getSlidingInterval(getUtcDayjs().endOf(timeAggregationToOpUnitType(step)), "year");
    const labels = getChartLabels(current, step);

    const [revenues, expenses] = await Promise.all([REVENUES_ACCOUNT_PREFIX, EXPENSES_ACCOUNT_PREFIX].map(account => getJournalSummaryData(account, current, step)));
    const values = revenues.map((revenue, idx) => revenue + expenses[idx]);

    return {
        type: ChartType.Line,
        chartProps: {
            labels,
            colors: ["C_CHART_green"],
            values: [values]
        }
    };
}

async function getCbaRevenueGrowthChartTileData(): Promise<IChartTileData<ChartType.Line>> {
    const dataSetLabels = ["LastYear", "ThisYear"].map(key => i18next.t(`Home:CbaRevenueGrowth.${key}`));
    const step = TimeAggregationFunction.Month;
    const { previous, current } = getSlidingInterval(getUtcDayjs().endOf(timeAggregationToOpUnitType(step)), "year");
    const labels = getChartLabels(current, step);

    const values = await Promise.all([previous, current].map(interval => getCbaEntryLedgerSummaryData(interval, step, true)));

    return {
        type: ChartType.Line,
        chartProps: {
            labels,
            filled: true,
            dataSetLabels,
            colors: ["C_CHART_orange", "C_CHART_red"],
            values
        }
    };
}

async function getCbaRevenueExpenseDiffChartTileData(): Promise<IChartTileData<ChartType.Line>> {
    const step = TimeAggregationFunction.Month;
    const { current } = getSlidingInterval(getUtcDayjs().endOf(timeAggregationToOpUnitType(step)), "year");
    const labels = getChartLabels(current, step);

    const values = await getCbaEntryLedgerSummaryData(current, step);

    return {
        type: ChartType.Line,
        chartProps: {
            labels,
            colors: ["C_CHART_green"],
            values: [values]
        }
    };
}

async function getBusinessPartnersTableData(oData: OData, context: IAppContext, interval: IDayjsInterval, types: DocumentTypeCode[] = [], businessPartnerIds: number[] = null): Promise<Pick<IDocumentEntity, "BusinessPartner" | "Amount">[]> {
    const bpPath = createPath(DocumentEntity.BusinessPartner, DocumentBusinessPartnerEntity.BusinessPartner, BusinessPartnerEntity.Id);
    const filters = [
        `${DocumentEntity.DateAccountingTransaction} ge ${transformToODataString(interval.from, ValueType.Date)}`,
        `${DocumentEntity.DateAccountingTransaction} le ${transformToODataString(interval.to, ValueType.Date)}`,
        `${bpPath} ne null`,
    ];
    const resultCount = 5;
    const isVatPayer = _isVatPayer({ context });

    if (types?.length) {
        const docTypesQuery = transformToODataString(types, ValueType.String);
        filters.push(`${DocumentEntity.DocumentTypeCode} in (${docTypesQuery})`);
    }

    businessPartnerIds = businessPartnerIds?.filter(item => !!item);
    if (businessPartnerIds?.length) {
        filters.push(`${bpPath} in (${transformToODataString(businessPartnerIds, ValueType.Number)})`);
    }

    const query = oData.getEntitySetWrapper(EntitySetName.Documents).query()
        .groupBy(
            createPath(DocumentEntity.BusinessPartner, DocumentBusinessPartnerEntity.BusinessPartner, BusinessPartnerEntity.Id),
            createPath(DocumentEntity.BusinessPartner, DocumentBusinessPartnerEntity.BusinessPartner, BusinessPartnerEntity.Name)
        )
        .aggregate(`${isVatPayer ? DocumentEntity.AmountNet : DocumentEntity.Amount} with sum as ${DocumentEntity.Amount}`)
        .orderBy(DocumentEntity.Amount, false)
        .top(resultCount)
        .filter(filters.join(" AND "));

    const data = await query.fetchData<IDocumentEntity[]>();

    return data.value.map(item => ({ ...item, ...item.BusinessPartner }));
}

export async function getBestBusinessPartnersTableData(key: "BestSuppliers" | "BestCustomers", {
    oData,
    context
}: IGetTileDataArgs): Promise<ITableTileData> {
    const columns: IColumn[] = [{
        id: "Name",
        label: i18next.t(`Home:${key}.Name`),
        textAlign: TextAlign.Left,
        width: 165
    }, {
        id: "Value",
        label: i18next.t(`Home:${key}.Value`),
        textAlign: TextAlign.Right
    }, {
        id: "Difference",
        label: i18next.t(`Home:${key}.Difference`),
        textAlign: TextAlign.Right,
        stretchContent: true
    }];
    const rows: IRow[] = [];

    const isExpense = key === "BestSuppliers";
    const docTypes = isExpense ? [DocumentTypeCode.InvoiceReceived, DocumentTypeCode.OtherLiability, DocumentTypeCode.CashReceiptReceived]
        : [DocumentTypeCode.InvoiceIssued, DocumentTypeCode.OtherReceivable, DocumentTypeCode.CashReceiptIssued];

    const { current, previous } = getSlidingInterval(getUtcDayjs(), "year");
    const currentData = await getBusinessPartnersTableData(oData, context, current, docTypes);

    if (currentData.length) {
        const BPIds = currentData.map(doc => doc.BusinessPartner.Id);
        const previousData = await getBusinessPartnersTableData(oData, context, previous, docTypes, BPIds);

        currentData.forEach(row => {
            const { Id, Name } = row.BusinessPartner;
            const Value = row.Amount ?? 0;
            const Difference = previousData.find(prevRow => prevRow.BusinessPartner.Id === Id)?.Amount ?? 0;

            rows.push({ id: Id, values: getBestBusinessPartnerTableDataCellValues(Name, Value, Difference, context) });
        });
    }

    return { columns, rows };
}

interface IAssetDepreciationSummary {
    CurrentMonthDepreciation: number;
    PreviousMonthDepreciation: number;
}

async function getAssetDepreciationData(): Promise<IAssetDepreciationSummary> {
    let data: IAssetDepreciationSummary;
    try {
        const response = await customFetch(ASSET_DEPRECIATION_MONTH_API_URL);
        data = await response.json();
    } catch (e) {
        // todo: handle error
    }
    return {
        CurrentMonthDepreciation: data?.CurrentMonthDepreciation ?? 0,
        PreviousMonthDepreciation: data?.PreviousMonthDepreciation ?? 0
    };
}

interface IAgingReportData {
    labels: string[];
    values: number[];
}

async function getAgingReportData(type: AgingType, toDate: Date): Promise<IAgingReportData> {
    const params: IReportSettings = {
        CalculateToDate: formatDateToDateString(toDate),
        CalculationDecisiveDate: "DateDue",
        AgingIntervals: afterDueDateDefaultRange,
        AgingIntervalUnit: "Day",
        Currency: "CZK",
        DocumentTypes: type === AgingType.AP ? AgingReceivedTypes : AgingIssuedTypes,
        Sort: [],
        ReportHierarchy: {
            Aggregate: true,
            Groups: [],
            Columns: [],
            Aggregations: [{
                ColumnAlias: "Document_TransactionAmountDue",
                AggregationFunction: NumberAggregationFunction.Sum
            }]
        }
    };
    if (type === AgingType.AP) {
        params["AgingAPReportType"] = "PayablsAfterDueDate";
    } else {
        params["AgingARReportType"] = "RcvblsAfterDueDate";
    }

    const labels: string[] = [];
    const values: number[] = [];

    try {
        const url = type === AgingType.AP ? AGING_AP_API_URL : AGING_AR_API_URL;
        const response = await customFetch(url, {
            ...getDefaultPostParams(),
            body: JSON.stringify(params)
        });

        const data = await response.json() as IReportData;
        const totalRow = data.Rows.find(row => row.Type === "Total");

        data.Columns.forEach(col => {
            if (col.AggregationFunction) {
                return;
            }
            labels.push(col.Label);
            values.push(totalRow.Value[col.ColumnAlias] as number);
        });

    } catch (e) {
        // todo: error state
    }

    return { labels, values };
}

async function getAgingChartData(type: AgingType, {
    context,
    oData
}: IGetTileDataArgs): Promise<IChartTileData<ChartType.Doughnut>> {
    const { labels, values } = await getAgingReportData(type, getUtcDate());

    return {
        type: ChartType.Doughnut,
        chartProps: {
            labels, values,
            colors: ["C_CHART_purple", "C_CHART_blue", "C_CHART_green", "C_CHART_yellow"]
        }
    };
}

export const currencyScaleFormatter: TChartValueFormatterFn = (axis, value, idx) => {
    if (axis === "y") {
        return getCurrencyScale(value as number, CurrencyCode.CzechKoruna);
    } else if (!axis) {
        // exact value
        return formatCurrency(value, CurrencyCode.CzechKoruna);
    }
    return `${value}`;
};

const getAgingChartTileInfo = (type: AgingType): IChartTileInfo => ({
    type: DashboardTileType.Chart,
    title: i18next.t(`Home:${type === AgingType.AP ? "AgingAP" : "AgingAR"}.Heading`),
    size: { w: 2, h: 2 },
    link: type === AgingType.AP ? ROUTE_AGING_AP : ROUTE_AGING_AR,
    chartData: getAgingChartData.bind(null, type),
    formatter: currencyScaleFormatter
});

/**
 * Dashboard configuration for customer - visible on customer portal and on agenda level
 */
export function getDashboardDefinition(): IDashboardDefinition {

    const linkTiles: TTileDefinition = {};
    forEachKey(linkConfigs, (id) => {
        linkTiles[id] = getLinkTileConfig(id, linkConfigs[id]);
    });

    const tileDefinition: TTileDefinition = {
        Welcome: getWelcomeTileDef(),
        ...linkTiles,
        IncomeExpenseOverview: getIncomeExpenseOverviewTileDef(),
        LastSubmission: {
            type: DashboardTileType.Info,
            title: i18next.t("Home:LastSubmission.Heading"),
            infoData: async ({ oData, context }) => {
                const lastSubmission = await getLastSubmissionByType(oData, ElectronicSubmissionTypeCode.VatStatement);
                return [{
                    value: lastSubmission?.DateSubmission ? DateType.format(lastSubmission?.DateSubmission) : EMPTY_DASH,
                    label: lastSubmission?.ElectronicSubmissionTypeCode ? getEnumDisplayValue(EntityTypeName.ElectronicSubmissionType, lastSubmission?.ElectronicSubmissionTypeCode)
                        : i18next.t("Home:LastSubmission.None")
                }];
            },
            isVisible: ifAll(_isVatPayer, isAccountAssignmentCompanyValue),
            size: { w: 2, h: 1 }
        },
        AssetAnalysis: {
            type: DashboardTileType.Info,
            title: i18next.t("Home:AssetAnalysis.Heading"),
            link: ROUTE_ASSET_ANALYSIS,
            infoData: async ({ context }) => {
                const fy = getActiveFiscalYear(context);
                const value = await getAssetAnalysisData(fy?.Id);
                return [{
                    value,
                    label: i18next.t("Home:AssetAnalysis.Label"),
                    unit: "Kč"
                }];
            },
            formatter: infoTileCurrencyFormatter,
            isVisible: not(isAccountAssignmentCompanyValue),
            size: { w: 2, h: 1 }
        },
        VatOverview: {
            type: DashboardTileType.Info,
            title: i18next.t("Home:VatOverview.Heading"),
            infoData: async ({ oData, context }) => {
                // When FY is closed, accounts are zeroed and there is new entry at the first day of new fiscalYear to
                // set initial account balance, so we can show only summary on accounts of opened fiscalYears till now
                const _fromFY = getOldestActiveFY(context);
                const interval: IDateInterval = {
                    from: _fromFY.DateStart,
                    to: getUtcDate()
                };
                const [value] = await getJournalSummaryData(VAT_ACCOUNT_PREFIX, interval);

                return [{
                    // we display green color for negative values so we invert the sign, the method use abs for value anyway so we can do that
                    ...getInfoDataTileCellValueWithSeverity(value * -1),
                    label: i18next.t(`Home:VatOverview.${ifPositive(value, "VatLiability", "VatReceivable", "NoVat")}`),
                    unit: "Kč"
                }];
            },
            formatter: infoTileCurrencyFormatter,
            isVisible: ifAll(_isVatPayer, isAccountAssignmentCompanyValue),
            size: { w: 2, h: 1 }
        },
        Expenses: {
            type: DashboardTileType.Info,
            title: i18next.t("Home:Expenses.Heading"),
            infoData: getJournalDataForCurrentFYForInfoTile.bind(null, EXPENSES_ACCOUNT_PREFIX, "Expenses"),
            formatter: infoTileCurrencyFormatter,
            isVisible: isAccountAssignmentCompanyValue,
            size: { w: 2, h: 1 }
        },
        Revenues: {
            type: DashboardTileType.Info,
            title: i18next.t("Home:Revenues.Heading"),
            infoData: getJournalDataForCurrentFYForInfoTile.bind(null, REVENUES_ACCOUNT_PREFIX, "Revenues"),
            formatter: infoTileCurrencyFormatter,
            isVisible: isAccountAssignmentCompanyValue,
            size: { w: 2, h: 1 }
        },
        // AverageMaturity: {
        //     type: DashboardTileType.Info,
        //     title: i18next.t("Home:AverageMaturity.Heading"),
        //     infoData: async ({ oData, context }) => {
        //         const revenues = 14,
        //             expenses = 7;
        //         return [{
        //             value: revenues,
        //             label: i18next.t("Home:AverageMaturity.Revenues"),
        //             unit: i18next.t("Home:AverageMaturity.day", { count: revenues })
        //         }, {
        //             value: expenses,
        //             label: i18next.t("Home:AverageMaturity.Expenses"),
        //             unit: i18next.t("Home:AverageMaturity.day", { count: expenses })
        //         }];
        //     },
        //     size: { w: 2, h: 1 }
        // },
        AssetDepreciation: {
            type: DashboardTileType.Info,
            title: i18next.t("Home:AssetDepreciation.Heading"),
            link: ROUTE_ASSET_ANALYSIS,
            isVisible: isAccountAssignmentCompanyValue,
            infoData: async ({ oData, context }) => {
                const { CurrentMonthDepreciation, PreviousMonthDepreciation } = await getAssetDepreciationData();
                return [{
                    value: CurrentMonthDepreciation,
                    label: i18next.t("Home:AssetDepreciation.ThisMonth"),
                    unit: "Kč"
                }, {
                    value: PreviousMonthDepreciation,
                    label: i18next.t("Home:AssetDepreciation.NextMonth"),
                    unit: "Kč"
                }];
            },
            formatter: infoTileCurrencyFormatter,
            size: { w: 4, h: 1 }
        },
        BestSuppliers: {
            type: DashboardTileType.Table,
            title: i18next.t("Home:BestSuppliers.Heading"),
            size: { w: 3, h: 2 },
            tooltip: i18next.t("Home:BestSuppliers.Tooltip"),
            tableData: getBestBusinessPartnersTableData.bind(null, "BestSuppliers")
        },
        BestCustomers: {
            type: DashboardTileType.Table,
            title: i18next.t("Home:BestCustomers.Heading"),
            size: { w: 3, h: 2 },
            tooltip: i18next.t("Home:BestCustomers.Tooltip"),
            tableData: getBestBusinessPartnersTableData.bind(null, "BestCustomers")
        },
        AgingAP: getAgingChartTileInfo(AgingType.AP),
        AgingAR: getAgingChartTileInfo(AgingType.AR),
        RevenueGrowth: {
            type: DashboardTileType.Chart,
            title: i18next.t("Home:RevenueGrowth.Heading"),
            size: { w: 4, h: 2 },
            chartData: getRevenueGrowthChartTileData,
            formatter: currencyScaleFormatter,
            isVisible: isAccountAssignmentCompanyValue
        },
        RevenueExpenseDiff: {
            type: DashboardTileType.Chart,
            title: i18next.t("Home:RevenueExpenseDiff.Heading"),
            size: { w: 4, h: 2 },
            chartData: getRevenueExpenseDiffChartTileData,
            formatter: currencyScaleFormatter,
            isVisible: isAccountAssignmentCompanyValue
        },
        CbaRevenueGrowth: {
            type: DashboardTileType.Chart,
            title: i18next.t("Home:CbaRevenueGrowth.Heading"),
            size: { w: 4, h: 2 },
            chartData: getCbaRevenueGrowthChartTileData,
            formatter: currencyScaleFormatter,
            isVisible: not(isAccountAssignmentCompanyValue)
        },
        CbaRevenueExpenseDiff: {
            type: DashboardTileType.Chart,
            title: i18next.t("Home:CbaRevenueExpenseDiff.Heading"),
            size: { w: 4, h: 2 },
            chartData: getCbaRevenueExpenseDiffChartTileData,
            formatter: currencyScaleFormatter,
            isVisible: not(isAccountAssignmentCompanyValue)
        }
    };

    const optionalTiles: (keyof TTileDefinition)[] = [];
    const defaultTiles = Object.keys(tileDefinition).filter(key => !optionalTiles.includes(key));

    return {
        groups: [
            {
                id: "all",
                title: i18next.t("Home:CustomerTitle"),
                tiles: defaultTiles,
                allTiles: [...defaultTiles, ...optionalTiles]
            }
        ],
        tileDefinition,
        isVisible: isInCustomerPortal
    };
}
