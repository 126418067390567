import React from "react";
import Dialog from "../../components/dialog";
import FileStrip from "../../components/fileStrip";
import {
    getInboxFolderFileStripeItem,
    getInboxTableDef,
    getPaneData,
    handleAttachFiles,
    InboxFilter
} from "./Inbox.utils";
import { OtherFolderWindowStyled } from "./Inbox.styles";
import { withOData, WithOData } from "@odata/withOData";
import { PropsWithTheme } from "../../theme";
import { withTheme } from "styled-components/macro";

import { AppContext, IAppContext } from "../../contexts/appContext/AppContext.types";
import { WithTranslation, withTranslation } from "react-i18next";
import { getDefinitions } from "./InboxDef";
import { TId } from "@components/table";
import { IAlertProps } from "@components/alert/Alert";
import { ISplitPageTableDef } from "../../views/table/TableView.utils";
import { InboxFileEntity } from "@odata/GeneratedEntityTypes";
import { transformToODataString } from "@odata/OData.utils";
import { ValueType } from "../../enums";
import InboxSplitPage from "./InboxSplitPage";
import { IDefinition } from "../PageUtils";
import { ISplitPagePaneData } from "../Page";
import memoize from "../../utils/memoize";
import { InboxEntityTypeCode } from "@odata/GeneratedEnums";


interface IProps extends WithOData, PropsWithTheme, WithTranslation {
    onAttachFiles?: (inboxFiles: TId[]) => Promise<boolean>;
    onClose?: () => void;
    // Inbox files to hide - they are temporarily attached to new entity without draft,
    // so they behave like they are already deleted from inbox, but actually the conversion is done on entity save
    hideInboxFileIds?: number[];
}

class OtherFolderWindow extends React.PureComponent<IProps> {
    static contextType = AppContext;

    definition: IDefinition;

    componentDidMount() {
        if (this.props.tReady) {
            this.init();
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (!prevProps.tReady && this.props.tReady) {
            this.init();
        }
    }

    init(): void {
        const context = this.context as IAppContext;
        const type = InboxFilter.Other;
        const definition = getDefinitions(context);
        const tableDef = getInboxTableDef(definition, type);
        const _origFilter = tableDef.filter as string;
        definition.table = {
            ...tableDef,
            preventStoreVariant: true,
            id: "otherFolderTable",
            filter: () => {
                const { hideInboxFileIds } = this.props;
                return hideInboxFileIds?.length ? `not(${InboxFileEntity.Id} in (${transformToODataString(hideInboxFileIds, ValueType.Number)})) AND ${_origFilter}`
                        : _origFilter;
            }
        };
        this.definition = definition;
        this.forceUpdate();
    }

    getPaneData = memoize((type: InboxFilter): ISplitPagePaneData<ISplitPageTableDef> =>
            getPaneData(this.definition, type));

    handleAttachFiles = (Ids: TId[]): Promise<IAlertProps> => {
        return handleAttachFiles("Inbox:TableView.CompleteSuccessOther", () => this.props.onAttachFiles?.(Ids));
    };

    handleClose = () => {
        this.props.onClose?.();
    };

    renderContent() {
        const otherFolder = getInboxFolderFileStripeItem(InboxEntityTypeCode.Other);
        const paneData = this.getPaneData(InboxFilter.Other);

        return (
                <OtherFolderWindowStyled>
                    <FileStrip items={[otherFolder]}
                               selectedId={otherFolder.id}/>

                    <InboxSplitPage t={this.props.t}
                                    onAttachFiles={this.handleAttachFiles}
                                    inEditableWindow
                                    selectedFolderId={otherFolder.id}
                                    supportedEntities={[InboxEntityTypeCode.Other]}
                                    paneData={paneData}
                                    hideInboxFileIds={this.props.hideInboxFileIds}
                    />
                </OtherFolderWindowStyled>
        );
    }

    render() {
        const isBusy = !this.definition;

        return (
                <Dialog
                        disableScroll
                        width="100%"
                        height="100%"
                        isEditableWindow
                        busy={isBusy}
                        onConfirm={null}
                        onClose={this.handleClose}>
                    {!isBusy && this.renderContent()}
                </Dialog>
        );
    }
}

export default withOData(withTheme(withTranslation("Inbox")(OtherFolderWindow)));