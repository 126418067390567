import React from "react";
import { FormStorage } from "../../../views/formView/FormStorage";
import { FormViewForExtend, IFormViewProps } from "../../../views/formView/FormView";
import { ISmartFieldBlur, ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { cloneDeep } from "lodash";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { canChangeNumberRange, loadAndStoreRanges } from "../../numberRange/NumberRange.utils";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";
import { BankTransactionTypeCode } from "@odata/GeneratedEnums";
import { loadExchangeRate, setLockBreadcrumbs } from "../../documents/Document.utils";
import { getCorrent4Digit } from "../bankTransactions/BankTransactions.utils";
import {
    calculateAndStoreTransactionCount,
    getBankAccountBalanceForDate,
    handleApplyBankStatementAttachment,
    onDateFromChange,
    recalculateBalance,
    refreshBankStatementNumberRange,
    refreshExchangesRates
} from "./BankStatements.utils";
import { ModelEvent } from "../../../model/Model";
import {
    BankStatementEntity,
    IBankStatementEntity,
    IBankTransactionEntity,
    IFileMetadataEntity
} from "@odata/GeneratedEntityTypes";
import { DEFAULT_SCALE_CURRENCY_VALUE } from "../../../types/Number";
import { roundToDecimalPlaces } from "@utils/general";
import {
    ISmartBankAccountFilterCustomData
} from "@components/smart/smartBankAccountFilter/SmartBankAccountFilter.utils";
import { APPLY_BANKFILE_IMPORT, ClosingBalancePath, OpeningBalancePath } from "./BankStatementsDef";
import { ActionType, addNewLineItem, ISmartFastEntriesActionEvent } from "@components/smart/smartFastEntryList";
import BindingContext from "../../../odata/BindingContext";
import { isValidDate } from "@components/inputs/date/utils";
import { WithAlert, withAlert } from "@components/alert/withAlert";
import { AlertPosition } from "@components/alert/Alert";
import BusyIndicator from "../../../components/busyIndicator";
import { getCompanyCurrency } from "@utils/CompanyUtils";
import { getUtcDate } from "../../../types/Date";

interface IProps extends IFormViewProps<IBankStatementEntity, ISmartBankAccountFilterCustomData>, WithAlert {
}

class BankStatementsFormView extends FormViewForExtend<IBankStatementEntity, IProps> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    shouldComponentUpdate(nextProps: Readonly<IProps>): boolean {
        return this.props.alert !== nextProps.alert;
    }

    shouldDisableSaveButton = (): boolean => {
        return this.props.storage.isDisabled || this.props.storage.data.entity[BankStatementEntity.Locks]?.length > 0;
    };

    onAfterLoad = async () => {
        const storage = this.props.storage;


        await loadAndStoreRanges(storage, true);

        if (storage.data.bindingContext.isNew()) {
            const accounts = (storage as FormStorage).context.getCompanyBankAccounts(true) || [];
            const defAcc = accounts.find(acc => acc.IsDefault);
            if (defAcc) {
                this.handleChange({
                    value: defAcc.Id,
                    bindingContext: storage.data.bindingContext.navigate("BankAccount"),
                    triggerAdditionalTasks: true,
                    additionalData: defAcc
                });
                refreshBankStatementNumberRange(this.props.storage, defAcc?.BankStatementNumberRangeDefinition?.Id);
            }
        } else {
            // call only for saved entities, otherwise handleChange ^^^ will trigger the call
            await this.getInitialBalance();
        }
        calculateAndStoreTransactionCount(storage);

        if (storage.data.bindingContext.isNew() && (!this.entity.Transactions || this.entity.Transactions.length === 0)) {
            addNewLineItem(storage, "Transactions");
            storage.data.entity.Transactions[0].DateBankTransaction = getUtcDate();
        }

        return super.onAfterLoad();
    };

    async getInitialBalance() {
        const { storage } = this.props;
        const { DateFrom, BankAccount, Id } = storage.data.entity;
        if (!DateFrom || !BankAccount?.Id) {
            return;
        }
        const balance = await getBankAccountBalanceForDate(storage.oData, storage.context, DateFrom, BankAccount.Id, Id);
        storage.setCustomData({ initialBalance: balance });
        storage.addActiveField(storage.data.bindingContext.navigate(OpeningBalancePath));
        storage.addActiveField(storage.data.bindingContext.navigate(ClosingBalancePath));
    }

    handleCustomFileAction = async ({
                                        file, action
                                    }: { file: IFileMetadataEntity, action: string, attachId: number }) => {
        if (action === APPLY_BANKFILE_IMPORT) {
            const response = await handleApplyBankStatementAttachment(this.props.storage, file);

            if (response !== true) {
                this.props.setAlert(response);
            }
            this.forceUpdate();
        }
    };

    componentDidMount(): void {
        super.componentDidMount();
        this.props.storage.emitter.on(ModelEvent.CustomFileAction, this.handleCustomFileAction);

        this.registerHeader();
    }

    componentWillUnmount() {
        this.props.storage.emitter.off(ModelEvent.CustomFileAction, this.handleCustomFileAction);
        super.componentWillUnmount();
    }

    componentDidUpdate(): void {
        this.registerHeader();
    }

    registerHeader = (): void => {
        if (this.props.storage.data.bindingContext) {
            const numberOursBc = this.props.storage.data.bindingContext.navigate("NumberOurs");
            this.props.storage.addCustomRef(this._refHeader.current, numberOursBc);
        }
    };

    handleDateBankBlur = async (args: ISmartFieldBlur) => {
        if (args.bindingContext.getPath() === "DateBankTransaction") {
            const currencyCode = this.entity.BankAccount?.TransactionCurrency?.Code;
            const date = this.props.storage.getValue(args.bindingContext);
            const rate = await loadExchangeRate(this.props.storage, currencyCode, date);
            if (rate) {
                const bc = args.bindingContext.getParent().navigate("ExchangeRatePerUnit");
                this.props.storage.clearAndSetValue(bc, rate);
                this.props.storage.refreshFields();
            }
        }
    };

    handleBlur = async (args: ISmartFieldBlur): Promise<void> => {
        this.handleDateBankBlur(args);
        await this.props.storage.handleBlur(args);
        this.props.storage.refreshFields();
    };

    setBreadcrumbs = (): void => {
        const { storage } = this.props;
        const title = storage.data.definition.getItemBreadCrumbText(storage);
        setLockBreadcrumbs({
            storage,
            title,
            onBeforeSave: this.props.onBeforeSave,
            onSaveFail: this.props.onSaveFail,
            onTableRefreshNeeded: this.props.onTableRefreshNeeded,
            onAfterSave: this.props.onAfterSave,
            context: this.context
        });
    };

    handleDateFromChange = (args: ISmartFieldChange) => {
        if (args.bindingContext.getPath() === "DateFrom" && args.triggerAdditionalTasks) {
            onDateFromChange(this.props.storage, args.value as Date);
        }
    };

    handleBankAccountChange = (e: ISmartFieldChange) => {
        const { storage } = this.props;
        if (e.bindingContext.getPath() === "BankAccount" && e.triggerAdditionalTasks) {
            if (canChangeNumberRange(storage)) {
                refreshBankStatementNumberRange(this.props.storage, e.additionalData?.BankStatementNumberRangeDefinition?.Id);
            }
            const oldCurrency = this.entity.BankAccount?.TransactionCurrency?.Code;
            const newCurrency = e.additionalData.TransactionCurrency?.Code;
            if (newCurrency !== oldCurrency) {
                for (const transaction of storage.data.entity.Transactions || []) {
                    transaction.TransactionCurrency = {
                        Code: newCurrency
                    };
                }

                refreshExchangesRates(storage, newCurrency);
            }

            const wasSet = this.entity.BankAccount?.Id;
            if (!wasSet) {
                storage.refresh();
            }
        }
    };

    handleNumberRange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath() === "NumberRange") {
            this.props.storage.data.entity.NumberRange = e.value ? e.additionalData : null;
        }
    };


    handleChange = (e: ISmartFieldChange) => {
        const { storage } = this.props;

        this.handleBankAccountChange(e);
        storage.handleChange(e);

        this.handleNumberRange(e);

        // after change !
        this.handleDateFromChange(e);

        const path = e.bindingContext.getPath();
        // initial balance has probably changed as we are changing BankAccount or Statement Date
        if (["BankAccount", "DateFrom"].includes(path) && e.triggerAdditionalTasks) {
            this.getInitialBalance()
                .then(() => storage.refreshFields(true));
        }
        if (path === "TransactionAmount") {
        }
        storage.refreshFields(e.triggerAdditionalTasks);
    };

    handleLineItemsChange = (args: ISmartFieldChange) => {
        this.props.storage.handleLineItemsChange(args);

        if (args.bindingContext.getPath() === "TransactionAmount") {
            recalculateBalance(this.props.storage);
        }

        const currentTranCount = this.props.storage.data.entity.Transactions?.length || 0;
        const calculatedCount = this.props.storage.getCustomData().transactionCount;

        if (currentTranCount !== calculatedCount) {
            calculateAndStoreTransactionCount(this.props.storage);
            this.props.storage.addActiveField(this.props.storage.data.bindingContext.navigate(BindingContext.localContext("TransactionCount")));
        }

        this.props.storage.refreshFields();
    };

    handleLineItemsAction = (args: ISmartFastEntriesActionEvent): void => {
        const storage = this.props.storage;

        if (ActionType.Add === args.actionType) {
            const items = storage.data.entity.Transactions || [];
            const lastDate = items.reverse().find((item: IBankTransactionEntity) => isValidDate(item.DateBankTransaction))?.DateBankTransaction
                || storage.data.entity.DateFrom;

            if (lastDate) {
                args.items[args.items.length - 1].DateBankTransaction = lastDate;
            }
        }

        storage.handleLineItemsAction(args);

        if ([ActionType.Remove, ActionType.Clone].includes(args.actionType)) {
            recalculateBalance(this.props.storage);
        }

        calculateAndStoreTransactionCount(this.props.storage);
        this.props.storage.refresh();
    };

    onBeforeSave = () => {
        this.props.storage.clearEmptyLineItems("Transactions");

        const entity = cloneDeep(this.props.storage.data.entity) as IBankStatementEntity;

        // for new items add currency
        const transactionCurrencyCode = entity.BankAccount?.TransactionCurrencyCode;
        for (let i = 0; i < (entity.Transactions || []).length; i++) {
            const transaction = entity.Transactions[i];
            if (!transaction.TransactionCurrencyCode) {
                transaction.TransactionCurrencyCode = transactionCurrencyCode;
            }

            if (!transaction.ExchangeRatePerUnit) {
                transaction.ExchangeRatePerUnit = 1;
            }

            if (!transaction.CurrencyCode) {
                transaction.CurrencyCode = getCompanyCurrency(this.props.storage.context);
            }

            if (!transaction.Company) {
                transaction.Company = { Id: this.context.getCompany().Id };
            }

            const minorUnit = this.entity.BankAccount?.Currency?.MinorUnit ?? DEFAULT_SCALE_CURRENCY_VALUE;
            const formattedAmount = roundToDecimalPlaces(minorUnit, transaction.TransactionAmount * transaction.ExchangeRatePerUnit);
            transaction.Amount = formattedAmount;
            transaction.BankTransactionTypeCode = transaction.TransactionAmount > 0 ? BankTransactionTypeCode.IncomingPayment : BankTransactionTypeCode.OutgoingPayment;

            if (!transaction.NumberOurs) {
                const newNumber = i + 1;
                transaction.NumberOurs = `${entity.NumberOurs}-${getCorrent4Digit(newNumber)}`;
            }
        }

        return entity;
    };

    render() {
        if (!this.isReady()) {
            return <BusyIndicator isDelayed/>;
        }

        return (<>
            {this.renderForm()}
            {this.props.alert}
        </>);
    }
}

export default withPermissionContext(withAlert({
    autoHide: true,
    position: AlertPosition.CenteredBottom
})(BankStatementsFormView));