import styled from "styled-components/macro";

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
`;

export const AddButtonWrapper = styled.div`
    display: flex;
    align-self: flex-start;   
`;

export const DefaultButtons = styled.div`
    display: flex;
`;