import React from "react";
import { CloseIcon, CopySimpleIcon, MoreIcon, ReorderSmallIcon } from "../icon";
import {
    CheckboxStyledFastEntry,
    IconWrapper,
    LastFieldWrapper,
    LineOrder,
    LineWrapper,
    LineWrapperComparisonBorder,
    RightIconButtonGroup,
    StyledFastEntry
} from "./FastEntry.styles";
import { IconSize, LabelStatus } from "../../enums";
import { ISharpBorders } from "../inputs/input/Input.styles";
import { WithTranslation, withTranslation } from "react-i18next";
import TestIds from "../../testIds";
import { IconButton } from "../button";
import { withTheme } from "styled-components/macro";
import { AuditTrailLineComparison, IAuditTrailData } from "../../model/Model";
import IconSelect from "../inputs/select/IconSelect";
import { ISelectionChangeArgs, ISelectItem } from "../inputs/select/BasicSelect";
import { ICheckboxChange } from "../inputs/checkbox";
import { PropsWithTheme } from "../../theme";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import KeyboardShortcutsManager, {
    KeyboardShortcut
} from "../../utils/keyboardShortcutsManager/KeyboardShortcutsManager";
import { TabIndex } from "@utils/general";
import memoizeOne from "../../utils/memoizeOne";

export interface IFastEntryChild {
    isSharpLeft: boolean;
    isSharpRight: boolean;
}

export enum FastEntryActionStatus {
    Default = "Default",
    Hidden = "Hidden"
}

interface IProps extends WithTranslation, PropsWithTheme {
    id: number;
    isDisabled?: boolean;
    auditTrailData?: IAuditTrailData;
    hideCloneAction?: boolean;
    hideRemoveAction?: boolean;
    disableCloneAction?: boolean;
    disableRemoveAction?: boolean;
    actionStatus?: FastEntryActionStatus;
    onClone?: (id: number) => void;
    onRemove: (id: number) => void;
    onFocusPreviousItem?: (id: number) => void;
    onFocusNextItem?: (id: number) => void;
    onReorderUp?: (id: number) => void;
    onReorderDown?: (id: number) => void;
    additionalActions?: ISelectItem[];
    onAction?: (id: number, action: string) => void;
    canReorder?: boolean;
    /** Renders checkbox */
    isSelectable?: boolean;
    isSelected?: boolean;
    onSelect?: (id: number, selected: boolean) => void;
    dragHandleProps?: DraggableProvidedDragHandleProps;
    customPreContent?: (id: number) => React.ReactNode;
    style?: React.CSSProperties;
    passRef?: React.Ref<HTMLDivElement>;
}

class FastEntry extends React.PureComponent<IProps> {
    static defaultProps: Partial<IProps> = {
        actionStatus: FastEntryActionStatus.Default
    };

    handleClone = () => {
        this.props.onClone?.(this.props.id);
    };

    handleRemove = () => {
        this.props.onRemove(this.props.id);
    };

    handleFocusPreviousItem = () => {
        this.props.onFocusPreviousItem?.(this.props.id);
    };

    handleFocusNextItem = () => {
        this.props.onFocusNextItem?.(this.props.id);
    };

    handleReorderUp = () => {
        this.props.onReorderUp?.(this.props.id);
    };

    handleReorderDown = () => {
        this.props.onReorderDown?.(this.props.id);
    };

    handleAdditionalAction = (e: ISelectionChangeArgs) => {
        this.props.onAction?.(this.props.id, e.value as string);
    };

    handleShortcut = (event: React.KeyboardEvent, callback: (...args: any[]) => any) => {
        event.stopPropagation();
        event.preventDefault();
        callback();
    };

    handleKeyDown = (event: React.KeyboardEvent): void => {
        switch (true) {
            case KeyboardShortcutsManager.isEventShortcut(event, KeyboardShortcut.ALT_X):
                if (!this.props.hideRemoveAction && !this.isRemoveDisabled) {
                    this.handleShortcut(event, this.handleRemove);
                }
                return;
            case KeyboardShortcutsManager.isEventShortcut(event, KeyboardShortcut.ALT_C):
                if (!this.props.hideCloneAction && !this.isCloneDisabled) {
                    this.handleShortcut(event, this.handleClone);
                }
                return;
            case KeyboardShortcutsManager.isEventShortcut(event, KeyboardShortcut.ALT_UP):
                this.handleShortcut(event, this.handleFocusPreviousItem);
                return;
            case KeyboardShortcutsManager.isEventShortcut(event, KeyboardShortcut.ALT_DOWN):
                this.handleShortcut(event, this.handleFocusNextItem);
                return;
            case KeyboardShortcutsManager.isEventShortcut(event, KeyboardShortcut.ALT_SHIFT_UP):
                if (this.canReorder) {
                    this.handleShortcut(event, this.handleReorderUp);
                }
                return;
            case KeyboardShortcutsManager.isEventShortcut(event, KeyboardShortcut.ALT_SHIT_DOWN):
                if (this.canReorder) {
                    this.handleShortcut(event, this.handleReorderDown);
                }
                return;
        }

    };

    isComparison = () => {
        return this.props.auditTrailData;
    };

    get canReorder() {
        return !this.props.isSelectable && this.props.canReorder;
    }

    get isRemoveDisabled() {
        return this.props.disableRemoveAction || this.props.isDisabled;
    }

    get isCloneDisabled() {
        return this.props.disableCloneAction || this.props.isDisabled;
    }

    handleCheckboxChange = (e: ICheckboxChange) => {
        this.props.onSelect(this.props.id, e.value);
    };

    renderCheckbox = (fieldHasLabel: boolean) => {
        return (
            <CheckboxStyledFastEntry checked={!!this.props.isSelected}
                                     onChange={this.handleCheckboxChange}
                                     isDisabled={!this.props.isSelectable}
                                     fieldHasLabel={fieldHasLabel}/>
        );
    };

    getCloneActionButtonsStyle = memoizeOne((): React.CSSProperties => {
        return { visibility: this.props.hideCloneAction ? "hidden" : "visible" };
    }, () => [this.props.hideCloneAction]);

    getRemoveActionButtonsStyle = memoizeOne((): React.CSSProperties => {
        return { visibility: this.props.hideRemoveAction ? "hidden" : "visible" };
    }, () => [this.props.hideRemoveAction]);

    render() {
        let labelHidden = false;

        let children: any[];

        if (this.props.children) {
            children = Array.isArray(this.props.children) ? this.props.children : [this.props.children];
        } else {
            children = [];
        }

        const fields = children.map((childFactory: (args: ISharpBorders) => React.ReactElement, i: number) => {
            const sharpLeft = i > 0;
            const sharpRight = i < children.length - 1;
            const field = (childFactory)({
                isSharpLeft: sharpLeft,
                isSharpRight: sharpRight
            });

            labelHidden = field?.props?.fieldProps?.labelStatus || field?.props?.labelStatus === LabelStatus.Removed;

            return field;
        });

        const isComparison = this.isComparison();
        // make action buttons unavailable for keyboard navigation, supposedly users are confused by it
        // https://solitea-cz.atlassian.net/browse/DEV-14304
        const actionButtonsTabIndex = TabIndex.Disabled;

        if (!isComparison && fields.length > 0) {
            fields[fields.length - 1] = (
                // LastFieldWrapper holds together last column and buttons so that they wrap together
                <LastFieldWrapper data-testid={TestIds.FastEntryListLastField} key={"lastFieldWrapper"}>
                    {fields[fields.length - 1]}
                    <RightIconButtonGroup data-testid={TestIds.ButtonGroup} fieldHasLabel={!labelHidden}>
                        {(!this.props.hideCloneAction || this.props.actionStatus === FastEntryActionStatus.Hidden) &&
                            <IconButton title={this.props.t("Common:General.Clone")}
                                        hotspotId={"fastEntryCopy"}
                                        onClick={this.handleClone}
                                        isDisabled={this.isCloneDisabled}
                                        tabIndex={actionButtonsTabIndex}
                                        style={this.getCloneActionButtonsStyle()}
                                        isDecorative
                                        isTransparent>
                                <CopySimpleIcon width={IconSize.S} height={IconSize.S}/>
                            </IconButton>
                        }
                        {(!this.props.hideRemoveAction || this.props.actionStatus === FastEntryActionStatus.Hidden) &&
                            <IconButton title={this.props.t("Common:General.Remove")}
                                        hotspotId={"fastEntryRemove"}
                                        onClick={this.handleRemove}
                                        isDisabled={this.isRemoveDisabled}
                                        tabIndex={actionButtonsTabIndex}
                                        style={this.getRemoveActionButtonsStyle()}
                                        isDecorative
                                        isTransparent>
                                <CloseIcon width={IconSize.S} height={IconSize.S}/>
                            </IconButton>
                        }
                        {this.props.additionalActions?.length &&
                            <IconSelect
                                hotspotId={"FastEntryItemActions"}
                                icon={<MoreIcon/>}
                                headerText={this.props.t("Common:Form.ItemActions")}
                                items={this.props.additionalActions}
                                onChange={this.handleAdditionalAction}
                                isDisabled={this.props.isDisabled}
                                useAutoselection={false}
                            />
                        }
                    </RightIconButtonGroup>
                </LastFieldWrapper>
            );
        }

        const lineData = this.props.auditTrailData?.lineData;

        return (
            <StyledFastEntry data-testid={TestIds.FastEntryItem}
                             data-fasteentry-id={this.props.id}
                             style={this.props.style}
                             onKeyDown={this.handleKeyDown}
            >
                {!isComparison && this.canReorder &&
                    <IconWrapper
                        fieldHasLabel={!labelHidden}
                        {...this.props.dragHandleProps}
                        tabIndex={actionButtonsTabIndex}
                    >
                        <ReorderSmallIcon width={IconSize.M} height={IconSize.M} isLightHover/>
                    </IconWrapper>
                }
                {this.props.isSelectable && this.renderCheckbox(!labelHidden)}
                {this.props.customPreContent?.(this.props.id)}
                <LineWrapper
                    _hideContent={lineData?.type === AuditTrailLineComparison.MissingRow}
                    passRef={this.props.passRef} fieldHasLabel={!labelHidden} testid={TestIds.FormGroupLine}>
                    {lineData &&
                        <LineOrder data-testid={TestIds.AuditTrailLineOrder}
                                   _isChangedOrder={lineData.orderChanged}>{lineData.order}</LineOrder>}
                    {(lineData?.type === AuditTrailLineComparison.AdditionalRow || lineData?.type === AuditTrailLineComparison.MissingRow) &&
                        <LineWrapperComparisonBorder data-testid={TestIds.ComparisonBorder}/>
                    }
                    {fields}
                </LineWrapper>
            </StyledFastEntry>
        );
    }
}

const FastEntryWithTranslation = withTranslation(["Common"], { withRef: true })(withTheme(FastEntry));

export { FastEntryWithTranslation as FastEntry };