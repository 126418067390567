import { deburr, escapeRegExp } from "lodash";

import { ValueType } from "../enums";
import NumberType from "../types/Number";
import { isNotDefined } from "./general";

/**
 * Convert first letter of the string to uppercase
 */
export const capitalize = (value: string): string => {
    if (!value) {
        return "";
    }

    return `${value?.[0].toUpperCase()}${value?.slice(1)}`;
};

/** According to spec, initials should always be just two letters (first name, last surname), even if user has multiple names. */
export const initials = (value: string): string => {
    if (!value) {
        return "";
    }

    const names = value.trim().split(" ");

    // First letter of first name and first letter of last name
    return `${names[0]?.[0]}${names[names.length - 1]?.[0]}`.toUpperCase();
};

export const isUpperCase = (char: string): boolean => char === char.toUpperCase();

export const startsWithAccentsInsensitive = (haystack: string, needle: string): boolean => {
    if (!haystack || !needle) {
        return false;
    }
    return [haystack.toLowerCase(), deburr(haystack).toLowerCase()].some(str => startsWith(str, needle.toLowerCase()));
};

export const startsWith = (haystack: string, needle: string): boolean => {
    if (!haystack || !needle) {
        return false;
    }
    const [h, n] = [haystack, needle].map(str => fixWhiteSpaceChar(str).toLowerCase());
    return h.startsWith(n);
};

export const endsWith = (haystack: string, needle: string): boolean => {
    if (!haystack || !needle) {
        return false;
    }
    const [h, n] = [haystack, needle].map(str => fixWhiteSpaceChar(str).toLowerCase());
    return h.endsWith(n);
};

/**
 * Replace hyphen character with minus character and vice versa when parsing
 * @param value
 * @param isParsing
 */
export const fixMinusSign = (value: string, isParsing = false): string => {
    const hyphenChar = "-";
    const minusChar = "−";

    return value.replace(isParsing ? minusChar : hyphenChar, isParsing ? hyphenChar : minusChar);
};

/**
 * Sometimes we need to ignore differences between space and hard space, e.g. during search,
 * so this method replaces any white character to simple space
 * @param value
 */
export const fixWhiteSpaceChar = (value: string): string => {
    if (typeof value === "string") {
        return value?.replaceAll(/\s+/g, " ");
    }
    return value;
};

export const removeWhiteSpace = (string: string): string => {
    return string?.replace(/\s/g, "");
};

export const trimLeadingZeroes = (s: string): string => {
    return s?.replace(/^0+/, "");
};

/**
 * Regular expression to search string from the beginning of any word. Zeroes at the beginning are ignored as
 * blank characters. It mixes [,.-/] to support different date and number formats.
 * @param str
 * @param searchType adds some tweaks to Number/Date type searches
 */
export const startOfWordRegExp = (str: string, searchType = ValueType.String): RegExp => {
    let optionalPrefix = "";
    str = escapeRegExp(str);
    if (searchType === ValueType.Number) {
        // ignore thousand separator when searching numbers and also remove possible white characters from search string
        str = removeWhiteSpace(str).replace(/([0-9])/g, `$1[${NumberType.getParser()._groupChar}]?`);
        // fix minus sign
        str = fixMinusSign(str);
        // For numbers we don't search from "non-word" characters, just from start, but let us skip all non-number characters (currency symbol, minus sign)
        return new RegExp(`((^[^0-9]*))(${str})`, "im");
    }
    if (searchType === ValueType.Date) {
        optionalPrefix = "0*";
    }
    return new RegExp(`((^|\\s|[\\/.,_-])${optionalPrefix})(${str})`, "im");
};

/**
 * Test if any word in haystack starts with needle, accent insensitive (only in haystack).
 * If needle contains diacritics, it should have exact match in the haystack
 * @param haystack
 * @param needle
 * @param searchType
 */
export const anyPartStartsWithAccentsInsensitive = (haystack: string, needle: string, searchType = ValueType.String): boolean => {
    return testAccentsInsensitiveWithRegex(haystack, startOfWordRegExp(needle, searchType));
};

/**
 * Tests string with regexp regardless of accent in str.
 * @param haystack
 * @param regex
 */
export const testAccentsInsensitiveWithRegex = (haystack: string, regex: RegExp): boolean => {
    if (isNotDefined(haystack) || isNotDefined(regex)) {
        return false;
    }

    return regex?.test(haystack) || regex?.test(deburr(haystack));
};

export const testWithRegex = (haystack: string, regex: RegExp): boolean => {
    if (isNotDefined(haystack) || isNotDefined(regex)) {
        return false;
    }

    return regex?.test(haystack);
};

/**
 * Get starts of words accent insensitive (only in haystack). If needle contains diacritics,
 * it should have exact match in the haystack
 * @param haystack
 * @param needle
 * @param searchType
 */
export const getStartsWithWordAccentsInsensitiveIndex = (haystack: string, needle: string, searchType = ValueType.String): {
    index: number,
    length: number
} => {
    if (!haystack || !needle) {
        return undefined;
    }

    const regex = startOfWordRegExp(needle, searchType);

    let res = regex.exec(haystack);
    if (!res) {
        res = regex.exec(deburr(haystack));
    }

    // We would like to return index of the first character of the searched string, so if the first match is
    // start of the string "^" the matched word starts at index 0, in other cases, we need to add length of
    // the first match
    return !res ? undefined : { index: res.index + res[1].length, length: res[3].length };
};

interface ISplitStringResults {
    prefix: string;
    root: string;
    suffix: string;
}

/**
 * Splits string to search result parts, so the needle could be highlighted
 * @param haystack
 * @param needle
 * @param searchType
 */
export function splitStringToSearchedParts(haystack: string, needle: string, searchType = ValueType.String): ISplitStringResults {
    const res = getStartsWithWordAccentsInsensitiveIndex(haystack, needle, searchType);
    if (res) {
        const prefix = haystack.substring(0, res.index);
        const root = haystack.substring(res.index, res.length + res.index);
        const suffix = haystack.substring(res.length + res.index);
        return { prefix, root, suffix };
    }
    return null;
}

export const includesAccentsInsensitive = (haystack: string, needle: string): boolean => {
    if (!haystack || !needle) {
        return false;
    }
    return [haystack.toLowerCase(), deburr(haystack).toLowerCase()].some(str => str.includes(needle.toLowerCase()));
};

export const indexOfAccentsInsensitive = (haystack: string, needle: string): number => {
    let foundIndex = -1;
    if (haystack && needle) {
        [haystack.toLowerCase(), deburr(haystack).toLowerCase()].forEach(str => {
            const index = str.indexOf(needle.toLowerCase());
            if (index !== -1) {
                foundIndex = index;
            }
        });
    }
    return foundIndex;
};

/**
 * Compares string for sorting, e.g. using array sort method. Returns same result as array sort
 * method is expecting (-1/0/1)
 * @param str1
 * @param str2
 */
export function compareString(str1: string, str2: string): number {
    return (str1 ?? "").localeCompare(str2 ?? "");
}
