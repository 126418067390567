import SmartFormGroup, { IFormGroupDef, TFormTable } from "../../components/smart/smartFormGroup/SmartFormGroup";
import { getInfoValue, isVisible } from "@components/smart/FieldInfo";
import BindingContext, { createBindingContext } from "../../odata/BindingContext";
import React from "react";
import memoizeOne from "../../utils/memoizeOne";
import { FormTableWrapper } from "../table/TableView.styles";
import { SmartTable } from "../../components/smart/smartTable";
import FormGroup from "../../components/formGroup";
import { IPureFormProps } from "./PureForm.types";
import { getFormTableFilterQuery } from "./Form.utils";
import SmartSimpleTable from "../../components/smart/smartSimpleTable";
import SmartFormTabs from "../../components/smart/smartFormGroup/SmartFormTabs";
import { getTooltip } from "@components/smart/smartField/SmartFieldUtils";
import { Separator } from "@components/separator/Separator";

export type { IPureFormProps };

export class PureForm extends React.Component<IPureFormProps> {
    renderTable = memoizeOne((groupDef: IFormGroupDef, tableDef: TFormTable, keyBindingContext: BindingContext, isInTab: boolean, key: string) => {
        const { storage } = this.props;
        /**
         * In case storage data binding context is new, we don't want to render the table at all,
         * because the filter won't be valid. If there will be a need to render the table in this case,
         * move the visibility condition from common "Tabs" group to all specific table groups, because the inner table
         * group may be re-rendered by resize handler of SmartFormTabs and it doesn't go through the visibility check
         * of the Tabs group. See https://solitea-cz.atlassian.net/browse/DEV-28150
         */
        const isNew = storage.data.bindingContext.isNew();
        // in case storage is busy, we don't want to render the table at all -> may be some action is in progress, so
        // we should not to fire additional requests if the properties might not be valid or incomplete.
        const isBusy = storage.isBusy();
        if (!tableDef.entitySet || isNew || isBusy) {
            return null;
        }
        const bindingContext = createBindingContext(tableDef.entitySet, storage.oData.getMetadata());
        const filterQuery = getFormTableFilterQuery(storage, tableDef, storage.data.bindingContext);
        const table = (
                <FormTableWrapper isInTab={isInTab}>
                    {tableDef.isSimplified ? (
                            <SmartSimpleTable key={key}
                                              bindingContext={bindingContext}
                                              columns={tableDef.columns}
                                              storage={storage}
                                              filter={filterQuery}
                                              initialSortBy={tableDef.initialSortBy}
                                              hasBigFont
                                              highlightRowsHover={false}
                                              showHeaderBorder={false}
                                              pageSize={50}/>
                    ) : (
                    <SmartTable key={key}
                                bindingContext={bindingContext}
                                initialSortBy={tableDef.initialSortBy}
                                columns={tableDef.columns}
                                storage={storage as any}
                                filter={filterQuery}
                                tableId={tableDef.id}
                                disableVirtualization
                                preventAutoReset={tableDef.preventAutoReset}
                                hideDrilldown={true}/>
                )}
            </FormTableWrapper>
        );

        return isInTab ? (
            <React.Fragment key={key}>
                {table}
            </React.Fragment>
        ) : (
            <FormGroup
                key={key}
                id={groupDef.id}
                title={getInfoValue(groupDef, "title", { storage })}
                tooltip={getTooltip(groupDef.tooltip, storage)}
                showLine={groupDef.showGroupDivider ?? true}
                isSmallBottomMargin={groupDef.isSmallBottomMargin}>
                {table}
            </FormGroup>
        );
    });

    getGroupTitle = (group: IFormGroupDef, index: number) => {
        if (typeof group.collectionItemTitle === "string") {
            return group.collectionItemTitle;
        }

        return group.collectionItemTitle?.(index, this.props.storage) ?? group.title;
    };

    renderStandardGroup = (group: IFormGroupDef, bindingContext: BindingContext, key: string, title?: string) => {
        const { storage } = this.props;
        const _handleRef = (ref: any) => {
            storage.addGroupRef(ref, group.id);
        };
        return (
            <SmartFormGroup
                key={key}
                ref={_handleRef}
                title={title}
                isSimple={this.props.isSimple}

                isInEdit={!storage.data.bindingContext?.isNew()}
                bindingContext={bindingContext}
                def={group}
                storage={storage}
                onExpand={this.props.onGroupExpand}
                onBlur={this.props.onBlur}
                onCancel={this.props.onCancel}
                onConfirm={this.props.onConfirm}
                onChange={this.props.onChange}
                onTemporalChange={this.props.onTemporalChange}
                onRemove={this.props.onRemove}
                onLineItemsChange={this.props.onLineItemChange}
                onLineItemsAction={this.props.onLineItemAction}
                onFieldStateChange={this.props.onFieldStateChange}
                onGroupAction={this.props.onGroupAction}
            />
        );
    };

    renderCollection = (group: IFormGroupDef, index: number) => {
        const collectionBc = this.props.storage.data.bindingContext.navigate(group.collection);
        const collection = this.props.storage.getValue(collectionBc) ?? [];

        const renderedCollection = collection.map((item: any, i: number) => {
            const updatedGroupDef = { ...group };
            updatedGroupDef.title = this.getGroupTitle(group, i);

            return this.renderStandardGroup(updatedGroupDef, collectionBc.addKey(item), `${index}-${i}`);
        });

        renderedCollection.push(<Separator key={"line"}/>);

        return renderedCollection;

    };

    renderTabs = (group: IFormGroupDef, index: number) => {
        const _handleRef = (ref: any) => {
            this.props.storage.addGroupRef(ref, group.id);
        };


        return (
            <SmartFormTabs ref={_handleRef}
                           key={group.id}
                           storage={this.props.storage}
                           group={group}
                           index={index}
                           renderGroup={this.renderGroup}
            />
        );
    };

    renderGroup = (group: IFormGroupDef, index: number, title?: string, isInTab?: boolean) => {
        const { tooltip, ...info } = group;
        if (!this.props.storage.data.fieldsInfo
            || !isVisible({ info, storage: this.props.storage, context: this.props.storage.context })
        ) {
            return null;
        }

        if (group.tabs) {
            return this.renderTabs(group, index);
        }

        if (group.table) {
            const key = this.props.storage.data.uuid ? `${group.table.id}-${this.props.storage.data.uuid}` : group.table.id;
            return this.renderTable(group, group.table, this.props.storage.data.bindingContext, isInTab, key);
        }

        if (group.collection) {
            return this.renderCollection(group, index);
        }

        return this.renderStandardGroup(group, this.props.storage.data.bindingContext, index.toString(), title);
    };

    render() {
        return (
            <div style={this.props.style} ref={this.props.passRef}>
                {this.props.storage.getVariant().map((item: IFormGroupDef, i: number) => {
                    return this.renderGroup(item, i);
                })}
            </div>
        );
    }
}