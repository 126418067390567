import React from "react";
import SmartFastEntryList, {
    addNewLineItem,
    ISmartFastEntriesActionEvent
} from "../../components/smart/smartFastEntryList";
import { FormStorage } from "../../views/formView/FormStorage";
import BindingContext, { IEntity } from "../../odata/BindingContext";
import { ISmartFieldBlur, ISmartFieldChange } from "@components/smart/smartField/SmartField";
import {
    IPaymentDocumentItemEntity,
    ISplitAccountAssignmentEntity,
    SplitAccountAssignmentEntity
} from "@odata/GeneratedEntityTypes";
import Dialog from "../../components/dialog/Dialog";
import ConfirmationButtons from "../../views/table/ConfirmationButtons";
import { IAccAssDialogCustomData } from "../accountAssignment/AccountAssignment.utils";
import { CurrencyCode, SelectionCode } from "@odata/GeneratedEnums";
import { SplitDialogHeader, SplitDialogValue } from "./bankTransactions/BankTransactions.styles";
import { formatCurrencyVariableDecimals } from "../../types/Currency";
import { cloneDeep } from "lodash";
import TestIds from "../../testIds";
import { roundToDecimalPlaces } from "../../utils/general";
import { getCompanyCurrency } from "../../utils/CompanyUtils";
import { updateSplitFromBe } from "./Pair.utils";
import { WithAlert, withAlert } from "../../components/alert/withAlert";
import { Status } from "../../enums";
import i18next from "i18next";

interface IProps extends WithAlert {
    storage: FormStorage;
    bindingContext: BindingContext;
}

const FIELDS = [{ id: SplitAccountAssignmentEntity.TransactionAmount }, { id: SplitAccountAssignmentEntity.DebitAccount }, { id: SplitAccountAssignmentEntity.CreditAccount },
    { id: SplitAccountAssignmentEntity.Description }];

class AccountSplitDialog extends React.Component<IProps> {
    // flag for change, in case user just hit "close" button, this prevents draft change to occur
    _hasChanged = false;
    origData: SplitAccountAssignmentEntity[] = [];

    sum: number;

    constructor(props: IProps) {
        super(props);

        this.origData = cloneDeep(props.storage.getValue(props.bindingContext));

        const row = props.storage.getValue(props.bindingContext.getParent());
        const isSplit = row?.AccountAssignmentSelection?.Selection?.Code === SelectionCode.Split;

        if (!isSplit) {
            row.SplitAccountAssignments = [];
        }

        if (!row.SplitAccountAssignments?.length) {
            const path = props.bindingContext.getNavigationPath();
            addNewLineItem(this.props.storage, path, path, FIELDS);
        }

        this.sum = this.getSumValue();
    }

    handleBlur = async (args: ISmartFieldBlur): Promise<void> => {
        await this.props.storage.handleBlur(args);
        if (args.bindingContext.getPath() === "TransactionAmount" && args.wasChanged) {
            this.sum = this.getSumValue();
            this.forceUpdate();
            return;
        }

        this.props.storage.refreshFields();
    };

    handleChange = (args: ISmartFieldChange): void => {
        this._hasChanged = true;
        this.props.storage.handleChange(args);
        this.props.storage.refreshFields();
    };

    handleAction = (e: ISmartFastEntriesActionEvent<IPaymentDocumentItemEntity>): void => {
        this._hasChanged = true;
        this.props.storage.handleLineItemsAction(e);

        this.sum = this.getSumValue();
        this.props.storage.refresh();
    };

    roundByCurrency = (sum: number) => {
        const maximumFractionDigits = this.props.storage.data.entity.TransactionCurrency?.MinorUnit ?? 2;
        return roundToDecimalPlaces(maximumFractionDigits, sum);
    };

    isValid = (): boolean => {
        const sum = this.getSumValue();
        const row = this.props.storage.getValue(this.props.bindingContext.getParent());

        return sum === 0 && row?.SplitAccountAssignments?.length > 0;
    };

    clearAllErrorsAndItems = (): void => {
        const bcs: BindingContext[] = this.getBindingContextsFromCollection(this.props.bindingContext, FIELDS.map(field => field.id));
        for (const bc of bcs) {
            this.props.storage.clearError(bc);
            // clear all items because we don't destroy first line
            // so in theory user can change fiscal year and items would
            // be inaccurate
            const info = this.props.storage.getInfo(bc);
            if (info) {
                delete info.fieldSettings.items;
            }
        }
    };

    getBindingContextsFromCollection = (rootBc: BindingContext, fields: string[]): BindingContext[] => {
        const data: IEntity[] = this.props.storage.getValue(rootBc) || [];
        const bcs: BindingContext[] = [];

        for (const item of data) {
            const lineBc = rootBc.addKey(item);
            for (const field of fields) {
                bcs.push(lineBc.navigate(field));
            }
        }

        return bcs;
    };

    validateFields = async (): Promise<boolean> => {
        const bcs: BindingContext[] = this.getBindingContextsFromCollection(this.props.bindingContext, FIELDS.map(field => field.id));
        let hasError = false;
        for (const bc of bcs) {
            hasError = !!(await this.props.storage.validateField(bc)) || hasError;
        }

        return hasError;
    };

    handleConfirmDialog = async (): Promise<void> => {
        const hasError = await this.validateFields();
        const { storage } = this.props;

        if (hasError) {
            storage.refreshFields();
            return;
        }

        const item = storage.getValue(this.props.bindingContext.getParent());
        item.AccountAssignmentSelection = {
            AccountAssignment: {
                Id: SelectionCode.Split
            },
            Selection: {
                Code: SelectionCode.Split
            }
        };

        const error = await updateSplitFromBe(storage, item);
        if (error) {
            this.props.setAlert({
                title: i18next.t("Banks:Transactions.SplitGainError"),
                status: Status.Error,
                detailData: error
            });
        } else {
            this.close();
        }
    };

    handleCancel = (): void => {
        this.clearAllErrorsAndItems();
        if (this._hasChanged) {
            this.props.storage.setValue(this.props.bindingContext, this.origData);
        }

        this.close();
    };

    close = (): void => {
        const storage = this.props.storage as FormStorage<unknown, IAccAssDialogCustomData>;
        storage.setCustomData({ isSplitDialogOpened: false });
        storage.refresh();
    };

    getSumValue = (): number => {
        const row = this.props.storage.getValue(this.props.bindingContext.getParent());
        const amount = row?.TransactionAmount;

        const sum = row.SplitAccountAssignments?.reduce((accumulator: number, object: ISplitAccountAssignmentEntity) => {
            return accumulator + (object.TransactionAmount || 0);
        }, 0) || 0;

        return amount - this.roundByCurrency(sum);
    };

    getFormattedSumValue = (): string => {
        return formatCurrencyVariableDecimals(this.sum, this.getTransactionCurrency());
    };

    getTransactionCurrency = (): CurrencyCode => {
        return this.props.storage.data.entity.TransactionCurrency?.Code ?? getCompanyCurrency(this.props.storage.context);
    };

    render() {
        const isValid = this.isValid();
        return (
            <Dialog
                title={this.props.storage.t("Document:AccountAssignment.Split")}
                isEditableWindow={false}
                footer={
                    <ConfirmationButtons
                        isDisabled={!isValid}
                        confirmText={this.props.storage.t("Common:General.Save")}
                        onConfirm={this.handleConfirmDialog}
                        onCancel={this.handleCancel}
                        useWrapper={false}/>
                }
                onConfirm={this.handleConfirmDialog}
                onClose={this.handleCancel}>
                <SplitDialogHeader>
                    <span data-testid={TestIds.SplitDialogTitle}>{this.props.storage.t("Banks:Form.SplitTitle")}:</span>
                    <SplitDialogValue value={this.sum}
                                      data-testid={TestIds.SplitDialogValue}>{this.getFormattedSumValue()}</SplitDialogValue>
                </SplitDialogHeader>
                {this.props.alert}
                <SmartFastEntryList
                    useLabelWrapping={true}
                    canAdd
                    isCollapsible={false}
                    storage={this.props.storage as FormStorage}
                    bindingContext={this.props.bindingContext}
                    style={{ marginTop: "10px" }}
                    columns={FIELDS}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    onAction={this.handleAction}
                    order={null}
                />
            </Dialog>
        );
    }
}

export default withAlert()(AccountSplitDialog);