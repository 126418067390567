import { DashboardTileType, IDashboardDefinition, TTileDefinition } from "@components/dashboard";
import { EMPTY_DASH } from "@components/readOnlyList/ReadOnlyList";
import { ifAll, IGetValueArgs, not } from "@components/smart/FieldInfo";
import { EntitySetName } from "@odata/GeneratedEntityTypes";
import { CompanyPermissionCode, ElectronicSubmissionTypeCode } from "@odata/GeneratedEnums";
import { getCompanyCurrency, isCashBasisAccountingCompany, isOnOrganizationLevel } from "@utils/CompanyUtils";
import { fetchUnfinishedDocumentsCounts, getUnfinishedDocumentsTableData } from "@utils/DraftUtils";
import i18next from "i18next";
import { capitalize } from "lodash";

import BusinessCardTile from "../../components/tiles/businessCardTile";
import TodoListTile from "../../components/tiles/todoList/TodoListTile";
import { VAT_ASSESSMENT_TURNOVER_LIMIT } from "../../constants";
import { Status } from "../../enums";
import { ROUTE_ELECTRONIC_SUBMISSION } from "../../routes";
import Currency from "../../types/Currency";
import { getUtcDayjs } from "../../types/Date";
import NumberType from "../../types/Number";
import { getSortedDocumentEntityTypeNamesWithPermission } from "../documents/Document.utils";
import { getLastSubmissionByType } from "../electronicSubmission/ElectronicSubmission.utils";
import {
    getVatSubmissionFrequencyFromPeriod,
    getVatSubmissionPeriodName
} from "../electronicSubmission/VatSubmission.utils";
import { currencyScaleFormatter, getLinkTileConfig, linkConfigs, ticketsWidgetId } from "./CustomerDashboardDef";
import {
    agendaIsVatRegistered,
    getAgendaWorkOverviewTileDef,
    getAgendaWorkOverviewTileDefCashBasisAccounting,
    getBankAccountsTileDef,
    getCashBoxTileDef,
    getCustomerSupportTileDef,
    getDemoTenantTileDef,
    getEstimatedAccrualsDef,
    getFutureExpensesAndRevenuesDef,
    getInboxTileDef,
    getIncomeExpenseOverviewTileDef,
    getKeyboardShortcutsTileDef,
    getNextElectronicSubmissionDate,
    getNotClearedDocumentsDef,
    getPaymentDocumentTileDef,
    getPurchaseTileDef,
    getTicketsInfoTileDef,
    getVatAssessmentTurnoverData,
    getVatOverviewChartTileData,
    getWelcomeTileDef,
    hasAnyCompanyPermission,
    isInCustomerPortal
} from "./Home.utils";

/**
 * Dashboard configuration for accountant with specific selected agenda
 */
export function getDashboardDefinition(): IDashboardDefinition {
    const isCba = (args: IGetValueArgs) => { // TODO: temporary, figure out how this should work on cba company
        return isCashBasisAccountingCompany(args.context);
    };

    const tileDefinition: TTileDefinition = {
        Welcome: getWelcomeTileDef(),
        Purchase: getPurchaseTileDef(),
        IncomeExpenseOverview: getIncomeExpenseOverviewTileDef(),
        VatOverview: {
            type: DashboardTileType.Chart,
            title: i18next.t("Home:VatOverviewChart.Heading"),
            size: { w: 4, h: 2 },
            chartData: getVatOverviewChartTileData,
            formatter: currencyScaleFormatter,
            isVisible: ifAll(agendaIsVatRegistered, not(isCba))
        },
        TodoList: {
            title: i18next.t("Home:TodoList.Heading"),
            type: DashboardTileType.Custom,
            component: TodoListTile,
            size: { w: 2, h: 2 }
        },
        BusinessCard: {
            title: i18next.t("Home:BusinessCard.Heading"),
            type: DashboardTileType.Custom,
            size: { w: 2, h: 1 },
            component: BusinessCardTile
        },
        VatAssessmentTurnover: {
            type: DashboardTileType.Info,
            title: i18next.t("Home:VatAssessmentTurnover.Heading"),
            infoData: async ({ oData, context }) => {
                const current = await getVatAssessmentTurnoverData(oData, isCashBasisAccountingCompany(context));
                return [
                    {
                        value: NumberType.format(current, { maximumFractionDigits: 0 }),
                        unit: "Kč",
                        severity: current > VAT_ASSESSMENT_TURNOVER_LIMIT ? Status.Error : Status.Success,
                        label: i18next.t("Home:VatAssessmentTurnover.From", { number: Currency.format(VAT_ASSESSMENT_TURNOVER_LIMIT, { currency: getCompanyCurrency(context) }) })
                    }
                ];
            },
            isVisible: not(agendaIsVatRegistered),
            size: { w: 2, h: 1 }
        },
        ValueAddedTaxDates: {
            type: DashboardTileType.Info,
            title: i18next.t("Home:ValueAddedTaxDates.Heading"),
            infoData: async ({ oData, context }) => {
                const [lastSubmission,] = await Promise.all([
                    getLastSubmissionByType(oData, ElectronicSubmissionTypeCode.VatStatement),
                    i18next.loadNamespaces("ElectronicSubmission")
                ]);
                const lastSubmissionStartDate = lastSubmission?.DatePeriodStart;
                const {
                    period,
                    submissionDate
                } = await getNextElectronicSubmissionDate(lastSubmissionStartDate, oData, context);
                const now = getUtcDayjs();
                const lastSubmissionPeriodName = lastSubmissionStartDate ? getVatSubmissionPeriodName(lastSubmissionStartDate, getVatSubmissionFrequencyFromPeriod(lastSubmission)) : EMPTY_DASH;
                return [
                    {
                        value: capitalize(getVatSubmissionPeriodName(period.from, getVatSubmissionFrequencyFromPeriod(period))),
                        severity: submissionDate && now.isAfter(submissionDate) ? Status.Error : null,
                        label: i18next.t("Home:ValueAddedTaxDates.Last", { date: lastSubmissionPeriodName })
                    }
                ];
            },
            isVisible: ifAll(agendaIsVatRegistered, hasAnyCompanyPermission(CompanyPermissionCode.VatStatement)),
            size: { w: 2, h: 1 },
            link: ROUTE_ELECTRONIC_SUBMISSION
        },
        UnfinishedDocuments: {
            type: DashboardTileType.Table,
            title: i18next.t("Home:UnfinishedDocuments.Heading"),
            size: { w: 4, h: 3 },
            tableData: async ({ context, oData }) => {
                const sortedDocEntityTypes = getSortedDocumentEntityTypeNamesWithPermission(context, false);

                const [counts] = await Promise.all([
                    fetchUnfinishedDocumentsCounts(oData, sortedDocEntityTypes),
                    i18next.loadNamespaces(["FiscalYearClose", "Document"])
                ]);
                return getUnfinishedDocumentsTableData(context, sortedDocEntityTypes, counts, {
                    addDocumentIcon: false,
                    hideStatusIcon: true,
                    showEmptyLines: true
                });
            }
        },
        BankTransactions: getPaymentDocumentTileDef(EntitySetName.BankTransactions),
        CashReceipts: getPaymentDocumentTileDef(EntitySetName.CashReceipts),
        BankAccounts: getBankAccountsTileDef(),
        CashBoxes: getCashBoxTileDef(),
        NotClearedDocuments: getNotClearedDocumentsDef(),
        KeyboardShortcuts: getKeyboardShortcutsTileDef(),
        AgendaWorkOverview: getAgendaWorkOverviewTileDef(false),
        AgendaWorkOverviewCashBasisAccounting: getAgendaWorkOverviewTileDefCashBasisAccounting(),
        FutureExpensesAndRevenues: getFutureExpensesAndRevenuesDef(),
        EstimatedAccruals: getEstimatedAccrualsDef(),
        TicketsOverview: getTicketsInfoTileDef(),
        Inbox: getInboxTileDef(),
        DemoTenant: getDemoTenantTileDef(),
        CustomerSupport: getCustomerSupportTileDef(),
        [ticketsWidgetId]: getLinkTileConfig(ticketsWidgetId, linkConfigs[ticketsWidgetId])
    };

    const optionalTiles: (keyof TTileDefinition)[] = ["FutureExpensesAndRevenues", "EstimatedAccruals", "TicketsOverview", "Inbox", "DemoTenant"];
    const defaultTiles = Object.keys(tileDefinition).filter(key => !optionalTiles.includes(key));

    return {
        tileDefinition,
        isVisible: ifAll(not(isOnOrganizationLevel), not(isInCustomerPortal)),
        groups: [{
            id: "all",
            title: i18next.t("Home:AgendaTitle"),
            tiles: defaultTiles,
            allTiles: [...defaultTiles, ...optionalTiles]
        }]
    };
}
