import React, { useCallback } from "react";
import {
    CellContentWrapper,
    ElectronicSubmissionCellStyled,
    LockIconWrapper,
    SelectIconWrapper,
    TitleWrapper
} from "./ElectronicSubmission.styles";
import { ColoredIcon, LockClosedIcon, LockOpenedIcon } from "@components/icon";
import { IconSize } from "../../enums";
import DateType from "../../types/Date";
import { CaretIconRight } from "./YearSwitcher.styles";
import { ElectronicSubmissionTypeCode } from "@odata/GeneratedEnums";

interface IFileProps {
    date: Date;
    displayedDate?: Date;
    type: ElectronicSubmissionTypeCode;
    size?: number;
    isLocked?: boolean;
    isSelected?: boolean;
    onSelect?: (type: ElectronicSubmissionTypeCode, date: Date) => void;
}

const ElectronicSubmissionTableCell: React.FC<IFileProps> = (props) => {
    const { isLocked, date, displayedDate, type, size, isSelected, onSelect } = props;

    const handleSelect = useCallback(() => {
        onSelect?.(type, date);
    }, [type, date, onSelect]);

    const Icon = isLocked ? LockClosedIcon : LockOpenedIcon;
    const rowSpan = size ?? 1;

    return (
        <ElectronicSubmissionCellStyled rowSpan={rowSpan} $isSelected={isSelected} >
            <CellContentWrapper onClick={handleSelect} rowSpan={rowSpan}>
                <LockIconWrapper>
                    <ColoredIcon><Icon width={IconSize.S} height={IconSize.S}/></ColoredIcon>
                </LockIconWrapper>
                <TitleWrapper>{DateType.format(displayedDate ?? date)}</TitleWrapper>
                <SelectIconWrapper $isSelected={isSelected}>
                    <CaretIconRight isLightHover width={IconSize.M} height={IconSize.M}/>
                </SelectIconWrapper>
            </CellContentWrapper>
        </ElectronicSubmissionCellStyled>
    );
};

export default ElectronicSubmissionTableCell;