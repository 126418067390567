import styled, { css } from "styled-components/macro";
import {
    getInverseOpacity,
    T_PLAIN_big_hig,
    T_PLAIN_EMP,
    T_PLAIN_small,
    T_PLAIN_small_hig,
    T_TITLE_4_NORMAL
} from "../../../global.style";
import { ButtonGroup, IconButton } from "../../../components/button";
import { transparentize } from "polished";

export const StyledContentWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;
`;

export const StyledExtension = styled.div`
    display: flex;
    position: relative;
    margin-right: 100px;
    margin-bottom: 20px;
    margin-top: 15px;
`;

export const ExtensionIconWrapper = styled.div`
    width: 155px;
    height: 155px;
    margin-right: 30px;

    svg {
        margin-top: -25px;
    }
`;

export const SubscriptionColumn = styled.div`
    display: flex;
    flex-direction: column;

    ${T_TITLE_4_NORMAL};
`;

export const InvoicingColumn = styled(SubscriptionColumn)`
    &:not(:last-child) {
        margin-right: 100px;
    }

    margin-bottom: 20px;
`;

export const ExtensionColumn = styled(SubscriptionColumn)`
    width: 220px;
`;

export const ExtensionContent = styled.div `
    height: 250px;
    margin-bottom: 14px;
`;

export const ExtensionButtonGroup = styled(ButtonGroup)`
    flex: 1 1 auto;
    align-items: flex-end;
    justify-content: flex-start;
    margin-bottom: 30px;
`;

export const ExtensionSeparatorLine = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: ${props => props.theme.C_BG_stripes};
    opacity: 0;
`;

export const StorageExtensionSizeWrapper = styled.div`
    display: flex;
    gap: 32px;
`;

export const EditRow = styled.div `
    display: flex;
`;

export const IconButtonEditRow = styled(IconButton) `
    margin-top: -2px;
`;

export const ThinnerText = styled.span<{
    isSmall?: boolean
}>`
    ${T_PLAIN_EMP};
    white-space: pre-wrap;

    ${props => props.isSmall && css
            `font-size: 18px;`
    }
`;

export const ThinText = styled.span`
    ${T_PLAIN_big_hig};
    font-weight: 300;
`;

export const HugeText = styled.span`
    font-size: 20px;
    font-weight: bold;
`;

export const CardWrapper = styled.div`
    display: flex;
`;

export const ButtonGroupCardOptions = styled(ButtonGroup)`
    margin: 7px 0;
`;

export const SeparatorLine = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.C_ACT_hover_without_opacity};
`;

export const BillingTableTooltipTable = styled.div`
    display: grid;
    grid-template-columns: max-content max-content;
    column-gap: 30px;
    row-gap: 15px;

    ${T_PLAIN_small};

    div:nth-child(1n) {
        justify-self: flex-start;
    }

    div:nth-child(2n) {
        justify-self: flex-end;
    }

    hr {
        all: unset;
        position: relative;
        grid-column: 1/-1;
        height: 3px;

        &:before {
            content: "";
            position: absolute;
            height: 1px;
            width: 100%;
            background-color: ${props => props.theme.C_BG_menu};
            top: 0;
            left: 0;
        }

        &:after {
            content: "";
            position: absolute;
            height: 1px;
            width: 100%;
            background-color: ${props => props.theme.C_BG_menu};
            bottom: 0;
            left: 0;
        }
    }

    div:last-child {
        grid-column: 1/-1;
        justify-self: flex-end;
        ${T_PLAIN_small_hig};
    }

`;

export const PlanTile = styled.div`
    background-color: ${props => transparentize(getInverseOpacity(props.theme.lightFieldGroupOpacity), props.theme.C_BG_filterbar)};
    border-radius: 30px;
    padding: 38px 38px 24px;
    margin-bottom: 38px;
    min-width: min-content;
`;

export const EvalaSignatureWrapper = styled.div`
    width: 135px;
    height: 44px;
    margin-bottom: 38px;
`;

export const PlanTileContent = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 60px;
`;

export const PlanTileContentColumn = styled.div`
    display: flex;
    flex-direction: column;
`;