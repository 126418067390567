import { getDrillDownFilters, getIntentNavParams } from "@components/drillDown/DrillDown.utils";
import { PairIcon } from "@components/icon";
import { ISelectItem } from "@components/inputs/select/BasicSelect";
import { PairingFastEntryList } from "@components/pairingFastEntryList";
import { IFieldDefFn, IGetValueArgs, TInfoValue } from "@components/smart/FieldInfo";
import {
    ExchangeRatePerUnit,
    getCommonFilterDefs,
    getCommonTableColumnsDefs,
    NoteFieldDef,
    withDisplayName
} from "@components/smart/GeneralFieldDefinition";
import {
    companyBankAccountFormatter,
    getFilterBarItemParsedValue,
    ISmartBankAccountFilterCustomData
} from "@components/smart/smartBankAccountFilter/SmartBankAccountFilter.utils";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { IGroupRenderFn } from "@components/smart/smartFormGroup/SmartFormGroup";
import { SmartSelect } from "@components/smart/smartSelect";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import { TSmartODataTableStorage } from "@components/smart/smartTable/SmartODataTableBase";
import { ICellValueObject } from "@components/table";
import {
    BankStatementEntity,
    BankStatementLockEntity,
    BankTransactionEntity,
    CashReceiptEntity,
    CompanyBankAccountEntity,
    CurrencyEntity,
    DocumentEntity,
    EntitySetName,
    EntityTypeName,
    IBankAccountEntity,
    IBankTransactionEntity,
    ICompanyBankAccountEntity,
    IPaymentDocumentDraftEntity,
    IPaymentDocumentItemEntity,
    PaymentDocumentDraftEntity,
    PaymentDocumentItemDraftEntity,
    PaymentDocumentItemEntity,
    UserEntity
} from "@odata/GeneratedEntityTypes";
import {
    BankTransactionTypeCode,
    CompanyPermissionCode,
    DocumentTypeCode,
    PaymentDocumentItemTypeCode,
    SelectionCode
} from "@odata/GeneratedEnums";
import { getEnumNameSpaceName } from "@odata/GeneratedEnums.utils";
import { ICreateFilterStringSettings, IFormatOptions } from "@odata/OData.utils";
import { DocumentBusinessPartnerAdditionalProperties } from "@pages/businessPartner/BusinessPartner.shared.utils";
import { generalBankAccountFormatter } from "@utils/BankUtils";
import { isAccountAssignmentCompany, isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { getFilterForDocumentDrafts } from "@utils/DraftUtils";
import { arrayInsert, isDefined, isObjectEmpty } from "@utils/general";
import i18next from "i18next";
import { isEmpty } from "lodash";
import React from "react";
import { DefaultTheme } from "styled-components";

import Field from "../../../components/inputs/field";
import SmartBankAccountFilter from "../../../components/smart/smartBankAccountFilter";
import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { BasicInputSizes, FieldType, IconSize, Sort, TextAlign, ValidatorType } from "../../../enums";
import { BoldText, ColoredText, SmallText } from "../../../global.style";
import { TValue } from "../../../global.types";
import { Model } from "../../../model/Model";
import { IFilterQuery, TableStorage } from "../../../model/TableStorage";
import BindingContext, { createPath, IEntity } from "../../../odata/BindingContext";
import { ROUTE_BANK_STATEMENTS } from "../../../routes";
import { formatCurrency } from "../../../types/Currency";
import DateType from "../../../types/Date";
import { currencyScaleFormatter } from "../../../types/Number";
import { IFormDef } from "../../../views/formView/Form";
import { FormStorage } from "../../../views/formView/FormStorage";
import { IChangedFilter, isDraftView, ISplitPageTableDef } from "../../../views/table/TableView.utils";
import { addAssetDef } from "../../asset/fixedAsset/FixedAsset.utils";
import {
    BankCoreBusinessPartnerAdditionalProperties,
    getBusinessPartnerFieldDef
} from "../../businessPartner/BusinessPartner.utils";
import { draftNumberOursFormatter, getCurrencyUnit } from "../../documents/Document.utils";
import {
    addBankAccountTableDefs,
    addBusinessPartnerTableDefs,
    draftInfoPath,
    getDraftInfoColumnDef
} from "../../documents/DocumentCommonDefs";
import { DOCUMENT_DATE_CHANGE_DEBOUNCE } from "../../documents/DocumentDef";
import { setDefByEntityType } from "../../getDefByEntityType";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../../PageUtils";
import { StatusCodeEnumTranslations } from "../../reports/CommonDefs";
import {
    getBankAccountFieldsDef,
    isBankAccountFieldVisible,
    SAVED_ACCOUNTS_PATH
} from "../bankAccounts/BankAccounts.utils";
import { getPairDef, PairAdditionalData } from "../Pair.utils";
import {
    getPaymentStatusSummaryItem,
    getPaymentStatusTabData,
    getPaymentStatusTableColumnDef,
    getPaymentTabsDef,
    isExchangeRateVisible,
    makeCbaDefAdjustments,
    PaymentStatusSummaryAdditionalData
} from "../Payments.utils";
import {
    bankTransactionTypeFormatter,
    createStatementFilterQuery,
    getAmountFilterDefs,
    IBankCustomData,
    paymentDocDateValidator
} from "./BankTransactions.utils";
import BankTransactionsFormView from "./BankTransactionsFormView";

const getFilter: TInfoValue<string | IFilterQuery> = (args: IGetValueArgs) => {
    // DoesWaitForDuplicateCheck doesn't work for drafts
    if (isDraftView(args.storage as TSmartODataTableStorage)) {
        return null;
    }

    return `${BankTransactionEntity.DoesWaitForDuplicateCheck} ne true`;
};

interface IBankFormatterArgs {
    amount: number;
    transactionAmount?: number;
    currencyCode: string;
    transactionCurrencyCode?: string;
    useColor?: boolean;
    showPositive?: boolean;
    isPositive?: boolean;
}

export const _bankFormatter = (args: IBankFormatterArgs): ICellValueObject => {
    const _convert = (val: number) => {
        return args.showPositive ? Math.abs(val) : val;
    };

    const hasDiffCurrency = args.transactionCurrencyCode !== args.currencyCode;
    const color: keyof DefaultTheme = args.useColor ? args.isPositive !== false && args.transactionAmount > 0 ? "C_SEM_text_good" : "C_TEXT_primary" : null;

    const formattedTransactionAmount = formatCurrency(_convert(args.transactionAmount) || 0, hasDiffCurrency ? args.transactionCurrencyCode : args.currencyCode);

    if (args.transactionCurrencyCode && hasDiffCurrency) {
        const formattedAmount = formatCurrency(_convert(args.amount) || 0, args.currencyCode);

        return {
            value: <b><ColoredText color={color}><SmallText>{formattedAmount} | </SmallText>
                <BoldText>{formattedTransactionAmount}</BoldText></ColoredText></b>,
            tooltip: `${formattedAmount} | ${formattedTransactionAmount}`
        };
    } else {
        return {
            tooltip: formattedTransactionAmount,
            value: <b><ColoredText color={color}> {formattedTransactionAmount} </ColoredText></b>
        };
    }
};

export const isPairFastEntryDisabled = (storage: FormStorage): boolean => {
    const isExVisible = isExchangeRateVisible(storage);

    const allIsSet =
        storage.data.entity?.DateBankTransaction &&
        storage.data.entity?.TransactionCurrency?.Code && storage.data.entity?.BankTransactionType &&
        (isDefined(storage.data.entity.TransactionAmount) && storage.data.entity.TransactionAmount !== 0)
        && (!isExVisible || isDefined(storage.data.entity.ExchangeRatePerUnit));

    return !allIsSet;
};

export const bankAmountDueFormatter = (value: TValue, args: IGetValueArgs): ICellValueObject => {
    const formattedTransactionAmount = formatCurrency(Math.abs(Number(value ?? 0)), args.item.TransactionCurrencyCode);

    return {
        tooltip: formattedTransactionAmount,
        value: <b>{formattedTransactionAmount}</b>
    };
};

export const bankAmountPaidFormatter = (value: TValue, args: IGetValueArgs): ICellValueObject => {
    const storage = args.storage as TSmartODataTableStorage<IBankCustomData>;
    const pairedDocuments = storage.getCustomData().pairedDocuments;
    const id = args.item.Id;
    const pairedLink = pairedDocuments?.[id];
    const amount = pairedLink?.Amount;
    const transactionAmount = pairedLink?.TransactionAmount;

    const rootStorage = storage.getCustomData().rootStorage as FormStorage<any>;
    const docCurrency = args.item.Currency?.Code;
    const transactionCurrency = rootStorage.data.entity.TransactionCurrency?.Code ?? rootStorage.data.entity.TransactionCurrencyCode;

    return _bankFormatter({
        amount,
        transactionAmount,
        transactionCurrencyCode: transactionCurrency,
        currencyCode: docCurrency,
        useColor: false
    });
};

export const positiveBankAmountFormatter = (value: TValue, args: IGetValueArgs): ICellValueObject => {
    return _bankFormatter({
        amount: args.item?.Amount,
        transactionAmount: args.item?.TransactionAmount,
        transactionCurrencyCode: args.item?.TransactionCurrencyCode,
        currencyCode: args.item?.CurrencyCode,
        useColor: true,
        showPositive: true
    });
};

export const bankAmountFormatter = (value: TValue, args: IGetValueArgs): ICellValueObject => {
    return _bankFormatter({
        amount: args.item?.Amount,
        transactionAmount: args.item?.TransactionAmount,
        transactionCurrencyCode: args.item?.TransactionCurrencyCode,
        currencyCode: args.item?.CurrencyCode,
        useColor: true
    });
};

export const CUSTOMIZE_BUTTON_ID = "CustomizeButtonId";

const hasItems = (args: IGetValueArgs) => {
    return args.storage.data.entity.Items?.length > 0;
};

export const getDefinitions: IGetDefinition = (context: IAppContext): IDefinition => {
    const isCashBasisAccounting = isCashBasisAccountingCompany(context);
    const hasAccountAssignment = isAccountAssignmentCompany(context);

    const filterBarDef: IFilterGroupDef[] = [{
        id: FilterBarGroup.Parameters,
        createQuery: false,
        icon: "SafeFilter",
        title: i18next.t("Banks:Accounts.BankAccounts"),
        isExpanded: false,
        allowCustomFilters: false,
        defaultFilters: ["BankStatement/BankAccount"],
        removePadding: true,
        clearOnVariantChange: true,
        customButtons: [{
            id: CUSTOMIZE_BUTTON_ID,
            title: i18next.t("Banks:CustomizationDialog.ButtonTitle"),
            iconName: "Settings"
        }],
        filterDefinition: {
            "BankStatement/BankAccount": {
                fieldSettings: {
                    displayName: "AccountNumber"
                },
                isCompanyDependent: true,
                formatter: companyBankAccountFormatter,
                filterName: "Id",
                type: FieldType.Custom,
                filter: {
                    buildFilter(item: IChangedFilter, settings: ICreateFilterStringSettings, storage: TableStorage): string | IFilterQuery {
                        const values = item?.value as [];

                        if (isDraftView(storage as TSmartODataTableStorage) || !item || isEmpty(values)) {
                            return "";
                        }

                        return `NOT(BankStatement/BankAccount/Id in (${values.join(", ")}))`;
                    },
                    customReadOnlyParsedValue: getFilterBarItemParsedValue
                },
                render: ({ props, storage }) => {
                    const hasDrilldownFilter = !!getDrillDownFilters()?.["BankStatement/BankAccount"];

                    return (
                        <SmartBankAccountFilter ref={storage.addRef}
                                                bindingContext={props.parentProps.bindingContext}
                                                onChange={props.parentProps.onChange}
                                                storage={storage as TSmartODataTableStorage<ISmartBankAccountFilterCustomData>}
                                                isDisabled={hasDrilldownFilter}/>
                    );
                }
            }
        }
    }, {
        ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
        isValueHelp: true,
        defaultFilters: ["BankTransactionType", "DateBankTransaction", "TransactionAmount", "PaymentStatus", "SymbolVariable", "BankStatement/NumberOurs"],
        filterDefinition: {
            PaymentInformation: {},
            BankTransactionType: {
                type: FieldType.ComboBox,
                fieldSettings: {
                    displayName: "Name"
                }
            },
            DateBankTransaction: {},
            SymbolSpecific: {},
            SymbolVariable: {},
            SymbolConstant: {},
            "BankStatement/NumberOurs": {
                label: i18next.t("Banks:Transactions.FromStatement")
            },
            RemittanceInformation: { isValueHelp: false },
            ...withDisplayName("PaymentStatus", {
                isDisabled: (args: IGetValueArgs) => {
                    // doesn't work for drafts
                    return isDraftView(args.storage as TSmartODataTableStorage);
                }
            }),
            "Items/LinkedDocument/NumberOurs": {
                label: i18next.t("Document:FormTab.PairedDocument"),
                isValueHelp: false,
                filter: {
                    onlyApplyCollectionFilterOnRoot: true
                }
            },
            ExchangeRatePerUnit: {},
            NumberOurs: {
                label: i18next.t("Banks:Transactions.NumberOurs")
            },
            BankInternalId:{},
            PaymentType: {},
            BankAccountDescription:{},
            ...getAmountFilterDefs(),
            ...getFilterForDocumentDrafts(true),
            ...getCommonFilterDefs()
        }
    }];

    const table: ISplitPageTableDef = {
        filterBarDef,
        ...getPaymentStatusTabData(),
        id: "BankTransactions",
        draftDef: {
            draftEntitySet: EntitySetName.PaymentDocumentDrafts,
            draftFilter: `${PaymentDocumentDraftEntity.PaymentDocument}/Id eq null AND ${PaymentDocumentDraftEntity.DocumentTypeCode} eq '${DocumentTypeCode.BankTransaction}'`,
            draftProperty: BankTransactionEntity.PaymentDocumentDraft,
            draftPropsBlacklist: [BankTransactionEntity.PaymentDocumentDraft, BankTransactionEntity.PaymentStatus, BankTransactionEntity.PaymentStatusCode,
                BankTransactionEntity.DoesWaitForDuplicateCheck, BankTransactionEntity.TransactionAmountDue]
        },
        initialSortBy: [{
            id: "DateBankTransaction",
            sort: Sort.Desc
        }],
        columns: [
            draftInfoPath,
            BankTransactionEntity.NumberOurs,
            BankTransactionEntity.BankTransactionType,
            BankTransactionEntity.DateBankTransaction,
            createPath(BankTransactionEntity.BankStatement, BankStatementEntity.BankAccount),
            BankTransactionEntity.TransactionAmount,
            BankTransactionEntity.PaymentStatus,
            BankTransactionEntity.SymbolVariable,
            BankTransactionEntity.BankStatement,
            BankTransactionEntity.BankAccount,
            BankTransactionEntity.RemittanceInformation,
            createPath(BankTransactionEntity.Items, PaymentDocumentItemEntity.LinkedDocument, DocumentEntity.NumberOurs),
            BankTransactionEntity.Note
        ],
        additionalProperties: [{
            id: createPath(BankTransactionEntity.Currency, CurrencyEntity.Name)
        }, {
            id: createPath(BankTransactionEntity.LastModifiedBy, UserEntity.Name)
        }, {
            id: createPath(BankTransactionEntity.BankStatement, BankStatementEntity.Locks, BankStatementLockEntity.Id)
        }, {
            id: BankTransactionEntity.DateLastModified
        }, {
            id: BankTransactionEntity.PaymentDocumentDraft
        }, {
            id: BankTransactionEntity.TransactionAmountDue
        }],
        columnDefinition: {
            ...getCommonTableColumnsDefs(),
            ...getPaymentStatusTableColumnDef(),
            ...getDraftInfoColumnDef(true),
            BankTransactionType: {
                textAlign: TextAlign.Center,
                formatter: bankTransactionTypeFormatter
            },
            "Items/LinkedDocument/NumberOurs": {
                label: i18next.t("Banks:Transactions.PairedDocumentTable"),
                textAlign: TextAlign.Left,
                additionalProperties: [
                    { id: "/Items/LinkedDocument/DocumentTypeCode" },
                    { id: "/Items/PaymentDocumentItemTypeCode" }
                ],
                formatter: (val: TValue, args?: IFormatOptions) => {
                    let value = "", title = "";
                    const items = args.entity?.Items?.filter((item: IPaymentDocumentItemEntity) => item.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.Payment);
                    for (const item of items || []) {
                        const doc = item.LinkedDocument?.NumberOurs;
                        if (doc) {
                            value += `${value ? `, ${doc}` : doc}`;
                            title += `${title ? `\n${doc}` : doc}`;
                        }
                    }

                    return {
                        tooltip: title,
                        value
                    };
                },
                fieldSettings: {
                    disableSort: true
                }
            },
            DateBankTransaction: {},
            ...withDisplayName("BankStatement", "NumberOurs", {
                label: i18next.t("Banks:Transactions.FromStatement")
            }),
            PaymentInformation: {},
            Note: {},
            "BankStatement/BankAccount": {
                label: i18next.t("Banks:Statements.AccountName"),
                fieldSettings: {
                    displayName: "Name"
                }
            },
            TransactionAmount: {
                label: i18next.t("Banks:Transactions.Converted"),
                additionalProperties: [{ id: "/CurrencyCode" }, { id: "/ExchangeRatePerUnit" }, { id: "/Amount" }],
                formatter: positiveBankAmountFormatter
            },
            Amount: {
                label: i18next.t("Banks:Transactions.Amount")
            },
            NumberOurs: {
                label: i18next.t("Banks:Transactions.NumberOurs"),
                formatter: draftNumberOursFormatter(EntitySetName.PaymentDocumentDrafts, BankTransactionEntity.PaymentDocumentDraft)
            },
            ExchangeRatePerUnit: {},
            SymbolSpecific: {},
            SymbolVariable: {},
            SymbolConstant: {},
            RemittanceInformation: {},
            BankInternalId:{},
            PaymentType: {},
            BankAccountDescription:{}
        },
        title: i18next.t("Banks:Transactions.Title"),
        filter: getFilter
    };

    const summary: ISummaryItem[] = [
        { id: "NumberOurs" },
        getPaymentStatusSummaryItem()
    ];

    const hasInactiveAccount = (storage: Model) => {
        return (storage.data.entity as IBankTransactionEntity).BankStatement?.BankAccount?.IsActive === false;
    };

    const form: IFormDef<IBankTransactionEntity, IPaymentDocumentDraftEntity> = {
        id: "bankTransactionForm",
        summary,
        permissions: [CompanyPermissionCode.Bank],
        draftDef: {
            draftProperty: BankTransactionEntity.PaymentDocumentDraft,
            draftEntitySet: EntitySetName.PaymentDocumentDrafts,
            navigationToItem: PaymentDocumentItemDraftEntity.PaymentDocumentItem,
            navigationFromDraft: PaymentDocumentDraftEntity.PaymentDocument,
            draftAdditionalProps: [{ id: "DateLastModified" }, { id: "Items/PaymentDocumentItem" }]
        },
        showChanges: true,
        translationFiles: getDefinitions.translationFiles,
        formControl: BankTransactionsFormView,
        isDeletable: true,
        getItemBreadCrumbText: (storage: Model) =>
            getItemBreadCrumbsText(storage, i18next.t("Banks:Transactions:NewFormTitle"), storage.data.entity?.NumberOurs),
        title: i18next.t("Banks:Transactions.FormTitle"),
        additionalProperties: [
            { id: createPath(CashReceiptEntity.CreatedBy, "Id") },
            { id: BankTransactionEntity.PaymentDocumentDraft },
            ...BankCoreBusinessPartnerAdditionalProperties,
            DocumentBusinessPartnerAdditionalProperties,
            DocumentBusinessPartnerAdditionalProperties,
            ...PairAdditionalData,
            ...PaymentStatusSummaryAdditionalData,
            { id: "BankAccount/AccountNumber" },
            { id: "TransactionCurrency/MinorUnit" },
            { id: "BankAccount/BankCode" },
            { id: "BankStatement/BankAccount/AccountNumber" },
            { id: "BankStatement/BankAccount/BankCode" },
            { id: "BankStatement/BankAccount/CountryCode" },
            { id: "BankStatement/BankAccount/IsActive" },
            { id: "NumberOurs" },
            { id: "TransactionAmount" },
            { id: "BankTransactionTypeCode" },
            { id: `${BankTransactionEntity.Locks}/CreatedBy` },
            { id: `${BankTransactionEntity.Locks}/Type` },
            { id: "IsUnrelatedToBusiness" }
        ],
        fieldDefinition: {
            BankStatement: {
                type: FieldType.ComboBox,
                label: i18next.t("Banks:Transactions.FromStatement"),
                isDisabled: (args) => {
                    return hasInactiveAccount(args.storage as FormStorage) || hasItems(args);
                },
                isRequired: true,
                filter: {
                    select: (args: IGetValueArgs) => {
                        const { storage } = args;

                        const filter = createStatementFilterQuery(storage.context, storage.data.bindingContext.navigate("BankStatement"));
                        const secondaryFilter = storage.data.bindingContext.isNew() ? "" : `OR Id eq ${storage.data.entity.BankStatement.Id}`;

                        // filter out any locked BankStatements
                        return `(${filter} AND (not(${BankTransactionEntity.Locks}/any())) ${secondaryFilter})`;
                    }
                },
                columns: [
                    { id: BankStatementEntity.NumberOurs },
                    { id: BankStatementEntity.DateFrom },
                    { id: createPath(BankStatementEntity.BankAccount, CompanyBankAccountEntity.TransactionCurrencyCode) },
                    { id: createPath(BankStatementEntity.BankAccount, CompanyBankAccountEntity.Name) }
                ],
                fieldSettings: {
                    displayName: "NumberOurs",
                    showTabularHeader: false,
                    preloadItems: true,
                    additionalProperties: [{ id: "BankAccount/TransactionCurrencyCode" },
                        { id: "BankAccount/Currency/MinorUnit" }, { id: "BankAccount/PaymentServiceID" },
                        { id: "Transactions" }, { id: "BankAccount/AccountNumber" }, { id: "BankAccount/BankCode" }, { id: "BankAccount/BalanceSheetAccountNumberSuffix" },
                        { id: "BankAccount/CountryCode" }, { id: "BankAccount/IBAN" }, { id: "BankAccount/SWIFT" }, { id: "BankAccount/AbaNumber" }, { id: "BankAccount/Name" }
                    ],
                    orderBy: {
                        propertyName: BankStatementEntity.DateFrom,
                        asc: false
                    },
                    localDependentFields: [{
                        from: { id: "BankAccount/TransactionCurrencyCode" },
                        to: { id: "TransactionCurrency/Code" }
                    }, {
                        from: { id: "BankAccount/Currency/MinorUnit" },
                        to: { id: "TransactionCurrency/MinorUnit" }
                    }, {
                        from: { id: "BankAccount" },
                        to: { id: "BankStatement/BankAccount" }
                    }],
                    querySettings: {
                        "BankStatement/Transactions": {
                            count: true,
                            top: 0
                        }
                    },
                    transformFetchedItems: (items, args: IGetValueArgs) =>
                        (items || []).map(item => ({
                            ...item,
                            link: getIntentNavParams({
                                route: `${ROUTE_BANK_STATEMENTS}/${item.id}`,
                                context: args.storage.context
                            })
                        }))
                },
                render: (args: IFieldDefFn) => {
                    const iconClick = (e: React.MouseEvent) => {
                        e.preventDefault();

                        const storage = args.storage as TSmartODataTableStorage<IBankCustomData>;
                        storage.setCustomData({
                            statementPairedDialogOpened: true
                        });
                        args.storage.refresh();
                    };

                    return (
                        <Field label={args.props.info.label}
                               name={"BankStatement"}
                               isReadOnly={args.props.isReadOnly}
                               isDisabled={args.props.isDisabled}
                               isRequired={args.props.isRequired}
                               auditTrailData={args.props.auditTrailData}>
                            <SmartSelect
                                fieldInfo={args.props.info}
                                iconHasDifferentAction={true}
                                bindingContext={args.props.info.bindingContext}
                                onIconActivate={iconClick}
                                inputIcon={<PairIcon width={IconSize.S}/>}
                                {...args.props}
                                auditTrailData={args.props.auditTrailData}
                                onChange={args.events.onSelectChange}
                            />
                        </Field>
                    );
                },
                width: BasicInputSizes.L,
                affectedFields: [{ id: "BankStatement/BankAccount/CurrencyCode" },
                    { id: "Items/TransactionAmount" },
                    { id: `${BindingContext.localContext("ItemsHeader")}` },
                    { id: "TransactionCurrency" },
                    { id: "TransactionAmount" },
                    { id: "BankStatement/BankAccount" }, { id: "ExchangeRatePerUnit" }]
            },
            TransactionAmount: {
                type: FieldType.NumberInput,
                isDisabled: hasItems,
                width: BasicInputSizes.S,
                label: i18next.t("Banks:Transactions.Amount"),
                affectedFields: [{
                    id: "Items/AccountAssignmentSelection/AccountAssignment"
                }, { id: `${BindingContext.localContext("ItemsHeader")}` }],
                formatter: (val: TValue, args: IGetValueArgs) => {
                    if (isDefined(val)) {
                        return currencyScaleFormatter(Math.abs(val as number), args.storage.data.entity.TransactionCurrency);
                    }

                    return "";
                },
                fieldSettings: {
                    unit: getCurrencyUnit
                },
                customizationData: {
                    dependents: [CashReceiptEntity.ExchangeRatePerUnit]
                }
            },
            TransactionCurrency: {
                label: i18next.t("Banks:Accounts.Currency"),
                isVisible: false,
                width: BasicInputSizes.M,
                isReadOnly: true,
                customizationData: {
                    useForCustomization: false
                }
            },
            DateBankTransaction: {
                isDisabled: hasItems,
                fieldSettings: {
                    debouncedWait: DOCUMENT_DATE_CHANGE_DEBOUNCE
                },
                validator: {
                    type: ValidatorType.Custom,
                    settings: {
                        customValidator: paymentDocDateValidator
                    }
                }
            },
            ...getBusinessPartnerFieldDef(false),
            ExchangeRatePerUnit: {
                ...ExchangeRatePerUnit,
                isDisabled: hasItems,
                width: BasicInputSizes.S,
                defaultValue: null,
                label: i18next.t("Document:Form.CurrencyRate"),
                isReadOnly: (args: IGetValueArgs) => {
                    return args.storage.data?.entity?.TransactionCurrency?.Code === "CZK";
                }
            },
            "BankStatement/BankAccount": {
                type: FieldType.ComboBox,
                columns: [
                    { id: "Name" },
                    { id: "AccountNumber" }
                ],
                fieldSettings: {
                    itemsForRender: (items: ISelectItem[], args: IGetValueArgs) => {
                        const _items: ISelectItem[] = [];
                        const account: ICompanyBankAccountEntity = (args.storage.data.entity?.BankStatement?.BankAccount) as IBankAccountEntity;

                        if (!isObjectEmpty(account)) {
                            const number = generalBankAccountFormatter(account.AccountNumber, {
                                storage: args.storage,
                                bindingContext: args.bindingContext,
                                item: account,
                                entity: account
                            });

                            _items.push({
                                id: account.Id,
                                label: account.Name,
                                additionalData: {
                                    ...account,
                                    AccountNumber: number
                                }
                            });
                        }

                        return _items;
                    },
                    displayName: "Name",
                    shouldDisplayAdditionalColumns: true,
                    additionalProperties: [
                        { id: "BankCode" },
                        { id: "CountryCode" }
                    ]
                },
                additionalProperties: [
                    { id: CompanyBankAccountEntity.Name },
                    { id: CompanyBankAccountEntity.PaymentServiceID },
                    { id: CompanyBankAccountEntity.BalanceSheetAccountNumberSuffix }
                ],
                isRequired: false,
                isReadOnly: true
            },
            Note: NoteFieldDef,
            ...getBankAccountFieldsDef({
                addPaymentMethod: false,
                type: DocumentTypeCode.BankTransaction,
                savedAccountLabel: i18next.t("Banks:Transactions.FromAccount"),
                alwaysShowSymbolVariable: true
            }),
            RemittanceInformation: {
                width: BasicInputSizes.XL,
                isVisible: isBankAccountFieldVisible
            },
            PaymentInformation: {
                width: BasicInputSizes.L
            },
            BankTransactionType: {
                type: FieldType.SegmentedButton,
                isDisabled: hasItems,
                affectedFields: [{ id: `${BindingContext.localContext("ItemsHeader")}` }],
                fieldSettings: {
                    items: [{
                        id: BankTransactionTypeCode.IncomingPayment,
                        title: i18next.t("Banks:Pairing.InPayment"),
                        iconName: "Incoming"
                    }, {
                        id: BankTransactionTypeCode.OutgoingPayment,
                        iconName: "Outgoing",
                        title: i18next.t("Banks:Pairing.OutPayment")
                    }]
                }
            },
            BankInternalId:{},
            PaymentType: {
                width: BasicInputSizes.L
            },
            BankAccountDescription:{
                width: BasicInputSizes.L
            },
            ...getPairDef(DocumentTypeCode.BankTransaction, hasAccountAssignment)
        },
        groups: [{
            id: "Note",
            title: i18next.t("Document:FormGroup.Note"),
            rows: [[{ id: "Note" }]]
        }, {
            id: "payment",
            title: i18next.t("Banks:Transactions.Payment"),
            rows: [[
                { id: "BankStatement" }, { id: "BankStatement/BankAccount" }
            ], [
                { id: "DateBankTransaction" },
                { id: "BankTransactionType" },
                { id: "TransactionAmount" },
                { id: "ExchangeRatePerUnit" }
            ], [
                { id: "SymbolVariable" },
                { id: "SymbolSpecific" },
                { id: "SymbolConstant" },
                { id: "RemittanceInformation" },
                { id: "PaymentInformation" }
            ]]
        }, {
            id: "Pair",
            title: i18next.t("Banks:Pairing.PairForm"),
            lineItems: {
                collection: "Items",
                order: "Id",
                columns: [{
                    id: "TransactionAmount"
                }, {
                    id: "LinkedDocument/NumberOurs"
                }],
                isReadOnly: (args: IGetValueArgs) => {
                    return args.data.IsUnrelatedToBusiness;
                },
                render: (args: IGroupRenderFn) => {
                    return <PairingFastEntryList
                        groupId="Pair"
                        hasAccountAssignment={hasAccountAssignment}
                        bindingContext={args.props.bindingContext}
                        storage={args.storage as FormStorage}
                        onChange={args.props.onChange}
                        isDisabled={isPairFastEntryDisabled.bind(null, args.storage as FormStorage)}
                        isReadOnly={args.props.isReadOnly}
                        onBlur={args.props.onBlur}/>;
                }
            }
        },
            getPaymentTabsDef(isCashBasisAccounting, context)
        ]
    };

    const savedAccount = form.fieldDefinition[SAVED_ACCOUNTS_PATH];
    form.fieldDefinition[SAVED_ACCOUNTS_PATH] = {
        ...savedAccount,
        columns: [{ id: "AccountNumber" }, { id: "BusinessPartner/Name" }]
    };

    const definition = {
        entitySet: EntitySetName.BankTransactions,
        table,
        form
    };

    if (isCashBasisAccounting) {
        addAssetDef(definition);
    }

    addBusinessPartnerTableDefs(definition, 1, false);
    addBankAccountTableDefs(definition, 1);

    const itemsGroup = form.groups.find(g => g.id === "Pair");

    if (hasAccountAssignment) {
        const filtersDef = filterBarDef.find((bar) => bar.id === FilterBarGroup.Filters);
        filtersDef.defaultFilters.push("Items/DateAccountingTransaction");
        filtersDef.filterDefinition = {
            ...filtersDef.filterDefinition,
            "Items/DateAccountingTransaction": {
                isValueHelp: false
            },
            "Items/AccountAssignmentSelection/AccountAssignment/ShortName": {
                isValueHelp: false,
                filter: {
                    onlyApplyCollectionFilterOnRoot: true
                }
            },
            "Items/AccountAssignmentSelection/AccountAssignment/Name": {
                isValueHelp: false,
                filter: {
                    onlyApplyCollectionFilterOnRoot: true
                }
            }
        };

        table.columns.push("Items/AccountAssignmentSelection/AccountAssignment/ShortName");

        table.columnDefinition = {
            ...table.columnDefinition,
            "Items/DateAccountingTransaction": {
                formatter: (value: TValue, args: IFormatOptions) => {
                    const formatted = (args.entity.Items || []).reduce((previousValue: string, item: IEntity) => {
                        return previousValue + (previousValue ? ", " : "") + DateType.format(item.DateAccountingTransaction);
                    }, "");
                    return {
                        tooltip: formatted,
                        value: formatted
                    };
                }
            },
            "Items/AccountAssignmentSelection/AccountAssignment/ShortName": {
                additionalProperties: [{ id: "/Items/PaymentDocumentItemType" }, { id: "/Items/Amount" },
                    { id: "/Items/AccountAssignmentSelection/Selection/Code" }, { id: "/Items/SplitAccountAssignments" }],
                label: i18next.t("Banks:Transactions.AccountAssignment"),
                formatter: (val: TValue, args?: IFormatOptions) => {
                    const entity: IBankTransactionEntity = args.entity;
                    const items = entity.Items?.filter(item => item.PaymentDocumentItemType?.Code === PaymentDocumentItemTypeCode.Payment
                        || item.PaymentDocumentItemType?.Code === PaymentDocumentItemTypeCode.Other);
                    const count = items?.length || 0;
                    const lbl = args.storage.t("Banks:Transactions.MoreAssignment") as string;

                    if (count === 1) {
                        const item = items[0];
                        const isSplit = item.AccountAssignmentSelection?.Selection?.Code === SelectionCode.Split;

                        if (isSplit && !item.SplitAccountAssignments?.length) {
                            // special case from BE, code is Split but split items are empty => we display empty field
                            return "";
                        }

                        const shortName = item.AccountAssignmentSelection?.AccountAssignment?.ShortName;
                        return isSplit ? lbl : (shortName ?? args.storage.t("Banks:Transactions.NoAssignment") as string);
                    }

                    return count > 1 ? lbl : "";
                },
                fieldSettings: {
                    disableSort: true
                }
            }
        };

        itemsGroup.lineItems.columns = arrayInsert(itemsGroup.lineItems.columns, { id: "Amount" }, 1);
        itemsGroup.lineItems.columns = arrayInsert(itemsGroup.lineItems.columns, { id: "DateAccountingTransaction" }, 2);
        itemsGroup.lineItems.columns = arrayInsert(itemsGroup.lineItems.columns, { id: "AccountAssignmentSelection/AccountAssignment" }, 3);
    }
    if (isCashBasisAccounting) {
        makeCbaDefAdjustments(form, itemsGroup);
    }

    return definition;
};

getDefinitions.translationFiles = ["Common", "Enums", "NumberRange", "Banks", "Document", "Error", "Categories", "FixedAsset", getEnumNameSpaceName(EntityTypeName.CbaCategoryTaxImpact), ...StatusCodeEnumTranslations];
setDefByEntityType(EntityTypeName.BankTransaction, getDefinitions);
