import {
    convertJobStatusToProgressStatus,
    ProgressStatus,
    TProgressStatus
} from "@components/backgroundJobs/BackgroundJobs.utils";
import { WithConfirmationDialog, withConfirmationDialog } from "@components/dialog/withConfirmationDialog";
import { TProgressBarDescFormatter } from "@components/progressBar";
import {
    getBackgroundJobTitle,
    getProgressFinishedWithWarningFormatter,
    ProgressBarColor,
    progressErrorFormatter,
    progressSuccessFormatter,
    progressWarningFormatter
} from "@components/progressBar/ProgressBar.utils";
import {
    BackgroundJobEntity,
    BackgroundJobTypeEntity,
    CompanyEntity,
    EntitySetName,
    EntityTypeName,
    FileMetadataEntity,
    IBackgroundJobEntity,
    UserEntity
} from "@odata/GeneratedEntityTypes";
import { BackgroundJobStatusCode, BackgroundJobTypeCode, WebSocketMessageTypeCode } from "@odata/GeneratedEnums";
import { getEnumNameSpaceName } from "@odata/GeneratedEnums.utils";
import { transformToODataString } from "@odata/OData.utils";
import { WithOData, withOData } from "@odata/withOData";
import { getCompanyLogoUrl } from "@utils/CompanyUtils";
import { TWebsocketMessage } from "@utils/websocketManager/Websocket.types";
import { isBackgroundJobWebsocketMessage } from "@utils/websocketManager/Websocket.utils";
import React from "react";
import ReactDOM from "react-dom";
import { WithTranslation, withTranslation } from "react-i18next";

import { IBackgroundJobsProps } from "../../components/backgroundJobs/BackgroundJobsPopup";
import { IProgressBannerProps } from "../../components/backgroundJobs/ProgressBanner";
import ProgressBannerWithBackgroundJobsPopup
    from "../../components/backgroundJobs/ProgressBannerWithBackgroundJobsPopup";
import { Status, ValueType } from "../../enums";
import i18n from "../../i18n";
import ConfirmationTransactionsDialog from "../../pages/banks/bankTransactions/ConfirmationTransactionsDialog";
import evalaLogo from "../../static/images/evalaLogo.png";
import TestIds from "../../testIds";
import memoizeOne from "../../utils/memoizeOne";
import WebsocketManager from "../../utils/websocketManager/WebsocketManager";
import { AppContext, IAppContext } from "../appContext/AppContext.types";
import { WithAuthContext, withAuthContext } from "../authContext/withAuthContext";
import { ProgressBannerWrapper } from "./BackgroundJobsContext.styles";
import {
    BackgroundJobActionType,
    CancelableBackgroundJobStatuses,
    cancelBackgroundJob,
    getBackgroundJobFromWebsocketMessage,
    IBackgroundJobsContext,
    IgnoredBackgroundJobStatuses,
    markSeenBackgroundJobs,
    rollbackBackgroundJob
} from "./BackgroundJobsContext.utils";

export const BackgroundJobsContext = React.createContext<IBackgroundJobsContext>(undefined);

interface IProps {

}

interface IState extends Pick<IBackgroundJobsContext, "backgroundJobs"> {
    isPopupOpen: boolean;
    // banner hiding transition
    isBannerClosing: boolean;
    // statement id for the duplicates check dialog. if set, dialog will open
    bankTransDuplicatesBackgroundJobId: number;
    closingBackgroundJobs: Record<number, boolean>;
}

class BackgroundJobsProvider extends React.PureComponent<IProps & WithConfirmationDialog & WithTranslation & WithOData & WithAuthContext, IState> {
    static contextType = AppContext;

    state: IState = {
        backgroundJobs: [],
        closingBackgroundJobs: {},
        isPopupOpen: false,
        isBannerClosing: false,
        bankTransDuplicatesBackgroundJobId: null
    };

    backgroundJobsPopupRef = React.createRef<HTMLDivElement>();
    _unsubscribeWebsocket: () => void;

    componentDidMount() {
        this.init();
    }

    componentWillUnmount() {
        this._unsubscribeWebsocket?.();
    }

    init = async (): Promise<void> => {
        const context = this.context as IAppContext;

        if (context.hasLimitedAccess()) {
            // most odata requests will fail for canceled tenant
            return;
        }

        const backgroundJobs = await this.props.oData.getEntitySetWrapper(EntitySetName.BackgroundJobs)
            .query()
            .expand(BackgroundJobEntity.Company,
                q => q.select(CompanyEntity.Name, CompanyEntity.Id).expand(CompanyEntity.Logo,
                    qq => qq.select(FileMetadataEntity.Id)))
            .filter(`${BackgroundJobEntity.IsSeen} eq false AND ${BackgroundJobEntity.Type}/${BackgroundJobTypeEntity.IsSystemJob} eq false AND not(${BackgroundJobEntity.StatusCode} in(${IgnoredBackgroundJobStatuses.map(status => transformToODataString(status, ValueType.String)).join(", ")})) AND ${BackgroundJobEntity.CreatedBy}/${UserEntity.Id} eq ${this.props.authContext.userId}`)
            .orderBy(BackgroundJobEntity.DateCreated, false)
            .fetchData<IBackgroundJobEntity[]>();

        this.setState({
            backgroundJobs: backgroundJobs.value
        });

        this._unsubscribeWebsocket = WebsocketManager.subscribe({
            callback: this.handleWebsocketMessage,
            types: [WebSocketMessageTypeCode.BackgroundJob]
        });
    };

    isEveryJobSuccess = (backgroundJobs: IBackgroundJobEntity[]): boolean => {
        return backgroundJobs.every(job => job.StatusCode === BackgroundJobStatusCode.Finished);
    };

    handleWebsocketMessage = async (message: TWebsocketMessage) => {
        if (!isBackgroundJobWebsocketMessage(message)) {
            return;
        }

        let isPopupOpen = this.state.isPopupOpen;
        let backgroundJobs = [...this.state.backgroundJobs];
        const backgroundJob: IBackgroundJobEntity = getBackgroundJobFromWebsocketMessage(message, (this.context as IAppContext).getData().companies);
        const index = backgroundJobs.findIndex(job => job.Id === backgroundJob.Id);

        // don't show system jobs in popup
        if (backgroundJob.Type?.IsSystemJob) {
            return;
        }

        const bjStatus = backgroundJob.StatusCode as BackgroundJobStatusCode;

        if (index >= 0) {
            // make job seen, if it finished and the popup is opened
            // if (this.state.isPopupOpen &&
            //     backgroundJob.StatusCode === BackgroundJobStatusCode.Finished
            //     && backgroundJobs[index].StatusCode !== BackgroundJobStatusCode.Finished) {
            //     markSeenBackgroundJobs(this.props.oData, [backgroundJob.Id]);
            //     backgroundJob.IsSeen = true;
            // }

            if (IgnoredBackgroundJobStatuses.includes(bjStatus)) {
                backgroundJobs = backgroundJobs.filter(bj => bj.Id !== backgroundJob.Id);
            } else {
                backgroundJobs[index] = backgroundJob;
            }
        } else {
            if (!IgnoredBackgroundJobStatuses.includes(bjStatus)) {
                backgroundJobs.unshift(backgroundJob);
                // open popup when new background job added
                isPopupOpen = true;
            }

        }

        this.setState({
            backgroundJobs,
            isPopupOpen,
            isBannerClosing: false
        });
    };

    closePopup = () => {
        // user can close popup while removing animation of background job is still running
        // => onCloseFinish won't be called, we have to remove the job here
        this.setState({
            isPopupOpen: false,
            // isBannerClosing: this.isEveryJobSuccess(this.state.backgroundJobs),
            backgroundJobs: this.state.backgroundJobs.filter(job => !this.state.closingBackgroundJobs[job.Id]),
            closingBackgroundJobs: {}
        });
    };

    handleBannerClick = (event: React.MouseEvent): void => {
        const isPopupOpen = !this.state.isPopupOpen;

        if (isPopupOpen) {
            // markSeenBackgroundJobs(this.props.oData,
            //     this.state.backgroundJobs
            //         .filter(job => job.StatusCode === BackgroundJobStatusCode.Finished && !job.IsSeen)
            //         .map(job => job.Id));

            this.setState({
                isPopupOpen,
                isBannerClosing: false
                // backgroundJobs: this.state.backgroundJobs.map(job => ({
                //     ...job,
                //     IsSeen: job.IsSeen ? job.IsSeen : job.StatusCode === BackgroundJobStatusCode.Finished
                // }))
            });
        } else {
            this.closePopup();
        }
    };

    handlePopupClose = (): void => {
        // ignore when confirmation dialog is opened,
        // user should be able to confirm dialog without triggering HideOnClickOutside
        if (this.props.confirmationDialog.isOpened) {
            return;
        }

        this.closePopup();
    };

    handleBannerCloseFinish = (): void => {
        this.setState({
            backgroundJobs: [],
            isBannerClosing: false,
            isPopupOpen: false
        });
    };

    focusPopupIfOpened = (): void => {
        // set focus inside the popup, so that HideOnClickOutside won't close it automatically
        this.backgroundJobsPopupRef.current?.focus();
    };

    getBannerProps = memoizeOne((): IProgressBannerProps => {
        const runningJobs = this.state.backgroundJobs.filter(job => job.StatusCode === BackgroundJobStatusCode.Running);
        let value = 0;
        // use 10 parts to show that nothing is currently being done (empty banner) when no job is running
        // 0 parts would be fully filled
        let parts = runningJobs.length > 0 ? 0 : 10;

        for (const job of runningJobs) {
            const parsedResult = this.parseBackgroundJobResult(job);

            value += parsedResult.Processed;
            parts += parsedResult.Total;
        }

        return {
            value,
            parts,
            count: runningJobs.length,
            onClick: this.handleBannerClick,
            status: this.state.backgroundJobs.length === 0 ? Status.Success
                : this.state.backgroundJobs.reduce((status: TProgressStatus, job: IBackgroundJobEntity) => {
                    if (!status) {
                        return convertJobStatusToProgressStatus(job.StatusCode as BackgroundJobStatusCode);
                    } else if (status === ProgressStatus.Progress || convertJobStatusToProgressStatus(job.StatusCode as BackgroundJobStatusCode) === status) {
                        return status;
                    } else {
                        return convertJobStatusToProgressStatus(job.StatusCode as BackgroundJobStatusCode) === ProgressStatus.Progress ? ProgressStatus.Progress : Status.Warning;
                    }
                }, null),
            isClosing: this.state.isBannerClosing,
            onCloseFinish: this.handleBannerCloseFinish
        };
    }, () => [this.state.backgroundJobs, this.state.isBannerClosing]);

    parseBackgroundJobResult = (backgroundJob: IBackgroundJobEntity): {
        Total: number,
        Processed: number,
        hideNumbers?: boolean
    } => {
        switch (backgroundJob.StatusCode) {
            case BackgroundJobStatusCode.NotStarted:
                return {
                    Total: 1,
                    Processed: 0
                };
            case BackgroundJobStatusCode.PendingUserInput:
            case BackgroundJobStatusCode.Running:
                const result = JSON.parse(backgroundJob.Result);

                return {
                    Total: result?.Total ?? 1,
                    // fake progress to prevent empty progress bar for running jobs without indication of progress
                    Processed: result?.Processed ?? 0.55,
                    hideNumbers: !result?.Total // if total is not set, don't show numbers as they are faked
                };
            default:
                // full progress for finished, error, canceled, etc.
                return {
                    Total: 0,
                    Processed: 0
                };
        }
    };

    onBackgroundJobAction = (backgroundJobId: number, actionId: BackgroundJobActionType): void => {
        if (actionId === BackgroundJobActionType.ConfirmDuplicates) {
            this.setState({
                bankTransDuplicatesBackgroundJobId: backgroundJobId,
                isPopupOpen: false
            });
        }
    };

    onBackgroundJobClose = async (backgroundJobId: number): Promise<void> => {
        const jobIndex = this.state.backgroundJobs.findIndex(job => job.Id === backgroundJobId);
        const job = this.state.backgroundJobs[jobIndex];
        const needsConfirmation = [BackgroundJobStatusCode.Running, BackgroundJobStatusCode.PendingUserInput].includes(job.StatusCode as BackgroundJobStatusCode);

        if (!needsConfirmation || await this.props.confirmationDialog.open({
            content: this.props.t("BackgroundJobs:Popup.CancelDialogText")
        })) {
            const backgroundJobs = [...this.state.backgroundJobs];
            const jobIndex = backgroundJobs.findIndex(job => job.Id === backgroundJobId);
            const job = backgroundJobs[jobIndex];

            if (!job.IsSeen) {
                markSeenBackgroundJobs(this.props.oData, [job.Id]).then(() => {
                    // wait for markSeen to finish, to prevent concurrency errors on BE
                    if (job.StatusCode === BackgroundJobStatusCode.PendingUserInput) {
                        rollbackBackgroundJob(this.props.oData, backgroundJobId);
                    } else if (CancelableBackgroundJobStatuses.includes(job.StatusCode as BackgroundJobStatusCode)) {
                        cancelBackgroundJob(this.props.oData, backgroundJobId);
                    }
                });
                backgroundJobs[jobIndex] = { ...job, IsSeen: true };
            }
            const isLast = this.state.backgroundJobs.length - 1 === jobIndex;

            this.setState({ backgroundJobs });

            // don't use transition (animation) when removing the last one
            if (isLast) {
                this.onBackgroundJobCloseFinish(backgroundJobId);
            } else {
                this.setState({
                    closingBackgroundJobs: {
                        ...this.state.closingBackgroundJobs,
                        [backgroundJobId]: true
                    }
                });
            }
        }

        this.focusPopupIfOpened();
    };

    onBackgroundJobCloseFinish = (backgroundJobId: number): void => {
        this.setState({
            backgroundJobs: this.state.backgroundJobs.filter(job => job.Id !== backgroundJobId),
            closingBackgroundJobs: {
                ...this.state.closingBackgroundJobs,
                [backgroundJobId]: false
            }
        });
    };

    getCustomRunningJobDescription = (job: IBackgroundJobEntity): () => string => {
        if (i18n.exists(`BackgroundJobs:ProgressDescription.${job.TypeCode}`)) {
            return () => this.props.t(`BackgroundJobs:ProgressDescription.${job.TypeCode}`);
        }
        return null;
    };

    getBackgroundJobDescriptionFormatter = (job: IBackgroundJobEntity): TProgressBarDescFormatter => {
        switch (job.StatusCode) {
            case BackgroundJobStatusCode.NotStarted:
                return () => {
                    return this.props.t("Components:ProgressBar.WaitingToStart");
                };
            case BackgroundJobStatusCode.Finished:
                return progressSuccessFormatter;
            case BackgroundJobStatusCode.PendingUserInput:
                return progressWarningFormatter;
            case BackgroundJobStatusCode.Error:
                return progressErrorFormatter;
            case BackgroundJobStatusCode.Running:
                return this.getCustomRunningJobDescription(job);
            case BackgroundJobStatusCode.Cancelling:
                return () => {
                    return this.props.t("Components:ProgressBar.Canceling");
                };
            case BackgroundJobStatusCode.Canceled:
                return () => {
                    return this.props.t("Components:ProgressBar.Canceled");
                };
            case BackgroundJobStatusCode.Finishing:
                return () => {
                    return this.props.t("Components:ProgressBar.Finishing");
                };
            case BackgroundJobStatusCode.FinishedWithWarning:
                return getProgressFinishedWithWarningFormatter(job);
            default:
                return null;
        }
    };

    getPopupProps = memoizeOne((): IBackgroundJobsProps => {
        return {
            backgroundJobs: this.state.backgroundJobs.map((job: IBackgroundJobEntity) => {
                const parsedResult = this.parseBackgroundJobResult(job);
                const company = job.Company;

                return {
                    id: job.Id,
                    title: getBackgroundJobTitle(job),
                    avatarSrc: company?.Logo ? getCompanyLogoUrl(company) : evalaLogo,
                    headerText: company?.Name ?? "Evala",
                    progress: {
                        parts: parsedResult.Total,
                        value: parsedResult.Processed,
                        hideNumbers: parsedResult?.hideNumbers,
                        isDescriptionInverseValue: true,
                        descriptionFormatter: this.getBackgroundJobDescriptionFormatter(job),
                        color: job.StatusCode === BackgroundJobStatusCode.Error ? ProgressBarColor.Gray : ProgressBarColor.Default
                    },
                    action: job.StatusCode === BackgroundJobStatusCode.PendingUserInput && job.TypeCode === BackgroundJobTypeCode.BankStatementImport ? BackgroundJobActionType.ConfirmDuplicates : null,
                    onActionClick: this.onBackgroundJobAction,
                    isClosing: this.state.closingBackgroundJobs[job.Id],
                    hideCloseButton: [BackgroundJobStatusCode.Cancelling, BackgroundJobStatusCode.Finishing].includes(job.StatusCode as BackgroundJobStatusCode),
                    onClose: this.onBackgroundJobClose,
                    onCloseFinish: this.onBackgroundJobCloseFinish
                };
            }),
            passRef: this.backgroundJobsPopupRef
        };
    }, () => [this.state.backgroundJobs, this.state.closingBackgroundJobs, this.props.tReady]);

    renderBanner = (): React.ReactElement => {
        if (this.state.backgroundJobs.length === 0 && !this.state.isBannerClosing) {
            return null;
        }

        const banner = (
            <ProgressBannerWrapper data-testid={TestIds.BackgroundJobs}>
                <ProgressBannerWithBackgroundJobsPopup bannerProps={this.getBannerProps()}
                                                       popupProps={this.getPopupProps()}
                                                       isOpen={this.state.isPopupOpen}
                                                       onClose={this.handlePopupClose}/>
            </ProgressBannerWrapper>
        );
        const modalRoot = document.getElementById("modal-root");

        if (modalRoot) {
            return ReactDOM.createPortal(
                banner,
                modalRoot
            );
        } else {
            return banner;
        }
    };

    handleBankTransDuplicatesDialogClose = () => {
        this.setState({
            bankTransDuplicatesBackgroundJobId: null
        });
    };

    renderBankTransDuplicatesDialog = (): React.ReactElement => {
        if (!this.state.bankTransDuplicatesBackgroundJobId) {
            return null;
        }

        return (
            <ConfirmationTransactionsDialog
                backgroundJob={this.state.backgroundJobs.find(job => job.Id === this.state.bankTransDuplicatesBackgroundJobId)}
                onClose={this.handleBankTransDuplicatesDialogClose}/>
        );
    };

    render() {
        return (
            <BackgroundJobsContext.Provider value={this.state}>
                {this.renderBanner()}
                {this.renderBankTransDuplicatesDialog()}
                {this.props.children}
            </BackgroundJobsContext.Provider>
        );
    }
}

export default withConfirmationDialog(withAuthContext(withTranslation(["BackgroundJobs", getEnumNameSpaceName(EntityTypeName.BackgroundJobType)])(withOData(BackgroundJobsProvider))));