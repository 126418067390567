import styled from "styled-components/macro";
import { ButtonGroup } from "@components/button";

export const StyledCompanyWizard = styled.div`
  display: flex;
  flex-direction: column;  
`;

export const WizardButtonGroup = styled(ButtonGroup)`
  // use margin instead of align-items, otherwise scrollbar doesn't show
  // https://stackoverflow.com/questions/43193923/align-items-flex-end-breaks-overflow-auto
  margin-left: auto;
`;

export const UsersWithPermissions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;