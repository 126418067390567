import React from "react";
import { ISmartSelectProps, SmartSelect } from "../smartSelect";
import { IconSize } from "../../../enums";
import { PairIcon } from "../../icon";
import BindingContext, { createBindingContext } from "../../../odata/BindingContext";
import SmartPairingSelectBase, { ISmartPairingSelectSharedProps } from "./SmartPairingSelectBase";

interface IProps extends ISmartSelectProps, ISmartPairingSelectSharedProps {

}

/** Combination of Select and Dialog to pick one document for pairing.
 * Hardcoded for documents, but could be changed to be used with anything. */
export default class SmartPairingSelect extends React.Component<IProps> {
    getSelectedItems = (): BindingContext[] => {
        let selectedBc;

        if (this.props.value) {
            selectedBc = createBindingContext(this.props.fieldInfo.fieldSettings.entitySet as string, this.props.storage.oData.metadata).addKey(this.props.value as (string | number));
        }
        return selectedBc ? [selectedBc] : [];
    };

    renderSelect = (onIconClick: (e: React.MouseEvent) => void): React.ReactElement => {
        const { onPair, dialogTableViewProps, getDefinition, ...selectProps } = this.props;

        return (
            <SmartSelect {...selectProps as any}
                         storage={this.props.storage}
                         bindingContext={this.props.bindingContext}
                         value={this.props.value}
                         onChange={this.props.onChange}
                         iconHasDifferentAction={true}
                         inputIcon={<PairIcon width={IconSize.S}/>}
                         onIconActivate={onIconClick}
                         fieldInfo={this.props.fieldInfo}
                         onKeyDown={this.props.onKeyDown}
            />
        );
    };

    render() {
        return (
            <SmartPairingSelectBase storage={this.props.storage}
                                    selectedItems={this.getSelectedItems()}
                                    onPair={this.props.onPair}
                                    dialogTableViewProps={this.props.dialogTableViewProps}
                                    getDefinition={this.props.getDefinition}
                                    renderSelect={this.renderSelect}
                                    fieldInfo={this.props.fieldInfo}
                                    bindingContext={this.props.bindingContext}
                                    isSingleSelect/>
        );
    }
}