import styled, { css } from "styled-components/macro";
import { LayoutStackOrder, LocalStackOrder, T_PLAIN_big } from "../../global.style";
import { IconButton } from "../button";
import { StyledView, VIEW_PADDING_VALUE } from "../../views/View.styles";
import { ShellBarWidth } from "../../views/main/Main.utils";
import {
    ItemIndex,
    LineNumbersWrapper,
    OrderBackground,
    StyledSortableList
} from "../sortableList/SortableList.styles";
import { StyledFastEntryList } from "../fastEntry/FastEntryList.styles";

interface IIsEditableWindow {
    _isEditableWindow?: boolean;
}

export const DIALOG_PADDING_VAL = "19px";
// 19px default padding - 12px padding on footer
export const DIALOG_BOTTOM_PADDING_VAL = "7px";
export const DIALOG_PADDING = `${DIALOG_PADDING_VAL} ${DIALOG_PADDING_VAL} ${DIALOG_BOTTOM_PADDING_VAL}`;
export const MIN_DIALOG_WIDTH = "278px";

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px;
    border-bottom: ${props => `1px solid ${props.theme.C_FORM_GROUP_HEADER_LINE}`}
`;

export const HeaderCustomContent = styled.div`
    position: absolute;
    left: 14px;
`;

export const EditableWindowCloseIcon = styled(IconButton)`
    position: absolute;
    top: -1px;
    left: -1px;
    z-index: ${LocalStackOrder.AboveOverlay};
    background: ${props => `linear-gradient(135deg, ${props.theme.C_ACT_der} 0%, ${props.theme.C_ACT_der} 50%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%)`};
    padding: 4px 28px 28px 4px;
    border-radius: 0 0 100% 0;
`;

export const DragIconWrapper = styled.div`
    display: none;
    position: absolute;
    right: 0;
    top: 0;

    cursor: pointer;
`;

export const Body = styled.div<IIsEditableWindow & {
    disableScroll: boolean;
    isConfirmation: boolean;
    withoutPadding: boolean;
}>`
    display: inline-block;
    max-width: ${props => props.isConfirmation ? "400px" : "100%"};
    max-height: 100%;
    height: 100%;
    padding: ${props => props.withoutPadding ? "0" : props.isConfirmation ? `39px ${DIALOG_PADDING_VAL} 27px` : DIALOG_PADDING};
    flex-grow: 1;
    overflow: ${props => props.disableScroll ? "initial" : "auto"};
    ${props => props.isConfirmation && css`
        text-align: center;
        ${T_PLAIN_big};
        // so that we can use "\\n" in translations
        white-space: pre-wrap;
    `};

    ${props => props._isEditableWindow && css`
        padding: 0;
        width: 100%;
        position: relative;
        z-index: ${LocalStackOrder.Base};
    `};

    ${props => !props._isEditableWindow && css`
        & ${StyledView} ${OrderBackground} { // readonly form in dialog
            width: ${DIALOG_PADDING_VAL};
            margin-left: ${DIALOG_PADDING_VAL}; // beat inline styles in smartfastentrylist which is not so smart to know he's in dialog
            & > ${ItemIndex} {
                left: -7px;
            }
        }

        & ${StyledView} ${StyledFastEntryList} {
            left: 0;

            &:has(${OrderBackground}) {
                margin-left: -${DIALOG_PADDING_VAL};
            }

            & > ${LineNumbersWrapper} ${OrderBackground} {
                width: ${DIALOG_PADDING_VAL};
                margin-left: 0;

                & > ${ItemIndex} {
                    left: -7px;
                }

                & + ${StyledSortableList} {
                    left: ${DIALOG_PADDING_VAL};
                    width: calc(100% - ${DIALOG_PADDING_VAL});
                }
            }
        }
    `}
`;

export const ModalBackground = styled.div<IIsEditableWindow & { centered: boolean; ignoreShellPadding?: boolean; }>`
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    height: 100%;
    width: ${props => props.ignoreShellPadding ? "100%" : `calc(100vw - ${ShellBarWidth})`};
    top: 0;
    left: ${props => props.ignoreShellPadding ? 0 : ShellBarWidth};

    z-index: ${props => props.ignoreShellPadding ? LayoutStackOrder.Tooltips : LayoutStackOrder.Modal};
    padding: ${VIEW_PADDING_VALUE}px;

    ${props => props._isEditableWindow && css`
        backdrop-filter: blur(4px); // todo: Firefox doesn't support this
        background-color: transparent;
        padding: 61px;
    `};

    ${props => props.centered && css`
        display: flex;
        align-items: center;
        justify-content: center;
    `}
`;

interface IStyledDialog extends IIsEditableWindow {
    _width: string;
    _height: string;
    _minWidth?: string;
    _minHeight?: string;
    _draggable?: boolean;
}


export const StyledDialog = styled.div<IStyledDialog>`
    max-width: 100%;
    max-height: 100%;
    min-width: ${props => props._minWidth ?? MIN_DIALOG_WIDTH};
    min-height: ${props => props._minHeight ?? "150px"};
    width: ${props => props._width ?? "auto"};
    height: ${props => props._height ?? "auto"};
    background-color: ${props => props.theme.C_BG_floorplan};
    display: inline-flex;
    flex-direction: column;
    border-radius: 3px;
    box-shadow: 0 0 12.7px 0.3px rgba(0, 0, 0, 0.15);
    outline: none;

    ${props => props._isEditableWindow && css`
        border: 3px solid ${props.theme.C_ACT_der};
        border-radius: 5px;
    `}

    ${props => props._draggable && css`
        &:hover ${DragIconWrapper} {
            display: block;
        }
    `}
`;

export const Title = styled.div`
    ${T_PLAIN_big};
`;

export const Footer = styled.div<{ justify?: string; }>`
    display: flex;
    justify-content: ${props => props.justify ?? "flex-end"};
    padding: 12px 19px;
    flex-shrink: 0;
    justify-self: flex-end;

    & > * {
        // get footer content over Resize icon
        z-index: ${LocalStackOrder.AboveContent};
    }
`;

export const DialogSeparatorLine = styled.div`
    position: relative;
    height: calc(100% - (${DIALOG_PADDING_VAL} + ${DIALOG_BOTTOM_PADDING_VAL}));
    top: ${DIALOG_PADDING_VAL};
    width: 2px;
    background-color: ${props => props.theme.C_FORM_GROUP_HEADER_LINE};
    flex: 0 0 auto;
`;