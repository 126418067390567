import { REST_API_URL } from "../constants";
import customFetch from "../utils/customFetch";
import { ROUTE_DEVTOOLS } from "../routes";
import { HTTPStatusCode } from "../enums";

export const isBackendInTestMode = async () => {
    const res = await customFetch(`${REST_API_URL}/TestAdmin/GetSystemNow`);

    if (res.status === HTTPStatusCode.NotFound) {
        return false;
    }

    return true;
};

export const isInDevtools = (): boolean => {
    return window?.location?.pathname?.toLowerCase?.()?.startsWith(ROUTE_DEVTOOLS.toLowerCase());
};