import { Status } from "../../enums";
import { BackgroundJobStatusCode } from "@odata/GeneratedEnums";

export enum ProgressStatus {
    Progress = "Progress"
}

export type TProgressStatus = Status | ProgressStatus;

export const convertJobStatusToProgressStatus = (jobStatus: BackgroundJobStatusCode): TProgressStatus => {
    switch (jobStatus) {
        case BackgroundJobStatusCode.Finished:
            return Status.Success;
        case BackgroundJobStatusCode.PendingUserInput:
        case BackgroundJobStatusCode.FinishedWithWarning:
            return Status.Warning;
        case BackgroundJobStatusCode.Error:
            return Status.Error;
        case BackgroundJobStatusCode.Running:
            return ProgressStatus.Progress;
        default:
            return null;
    }
};