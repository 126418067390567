import React, { useEffect } from "react";
import { DomManipulatorOrchestrator, DomManipulatorScope } from "./DomManipulatorOrchestrator";
import { DomManipulatorContext } from "./DomManipulatorProvider";

/** We want to unregister DomManipulator callbacks automatically before the wrapped component is unmounted
 * to prevent domUpdate calls on no longer existing DOM. By creating custom object that has the same API
 * as DomManipulator, we can call the unregister callback here, instead of having each component handle it by itself.*/
export interface IDomManipulatorLikeObject {
    createScope: () => DomManipulatorScope;
    registerCallback: <TData>(
            domRead: () => TData,
            domUpdate: (data: TData) => void,
            dependentRefs: React.RefObject<any>[]
    ) => () => void;
}

export interface WithDomManipulator {
    domManipulatorOrchestrator?: IDomManipulatorLikeObject;
}

export const withDomManipulator = <P extends WithDomManipulator>(
        Component: React.ComponentType<P>
): React.ComponentType<Omit<P, keyof WithDomManipulator>> => {
    const HOC = React.forwardRef<unknown, Omit<P, keyof WithDomManipulator>>((props, ref) => {
        let domManipulatorLikeObject: IDomManipulatorLikeObject | undefined;
        // keep multiple callbacks if needed, but just the last one could be enough
        let unregisterCallback: () => void;

        useEffect(() => {
            // Anything in here is fired on component mount.
            return () => {
                // Anything in here is fired on component unmount.
                unregisterCallback?.();
            };
        }, []);

        return (
                <DomManipulatorContext.Consumer>
                    {(orchestrator: DomManipulatorOrchestrator) => {
                        if (!domManipulatorLikeObject) {
                            domManipulatorLikeObject = {
                                createScope: orchestrator.createScope,
                                registerCallback: (...args) => {
                                    unregisterCallback = orchestrator.registerCallback(...args);
                                    return unregisterCallback;
                                }
                            };
                        }

                        return (
                                <Component
                                        domManipulatorOrchestrator={domManipulatorLikeObject}
                                        ref={ref}
                                        {...(props as P)}
                                />
                        );
                    }}
                </DomManipulatorContext.Consumer>
        );
    });

    // Assign a display name for better debugging
    HOC.displayName = `withDomManipulator(${Component.displayName || Component.name || "Component"})`;

    return HOC as unknown as React.ComponentType<Omit<P, keyof WithDomManipulator>>;
};
