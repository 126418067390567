import React from "react";
import { ISmartFieldProps } from "../smartField/SmartField";
import { ButtonSize } from "../../button/Button.utils";
import { BasicInputSizes, IconSize } from "../../../enums";
import ConditionalFilterDialog from "../../conditionalFilterDialog/ConditionalFilterDialog";
import {
    IComplexFilter,
    isComplexFilterArr,
    TFilterValue,
    ValueTypeToCondition
} from "../../conditionalFilterDialog/ConditionalFilterDialog.utils";
import { ValueHelpFilledIcon, ValueHelpIcon } from "../../icon";
import { getValueHelperChangeParams, IGetControlDataRetValue } from "../smartField/SmartFieldUtils";
import { TGetCustomValueInput } from "../../conditionalFilterDialog/ConditionalFilterRow";
import { TableStorage } from "../../../model/TableStorage";
import { IColumn, ISelectGroup, ISelectItem, TSelectItemId } from "../../inputs/select/BasicSelect";
import { getEnumEntities, getEnumName, isEnum } from "@odata/GeneratedEnums.utils";
import { createSelectItem } from "../smartValueHelper/ValueHelper.utils";
import { getCompanyUsedCurrencyCodes } from "@pages/companies/Company.utils";
import { CurrencyCode } from "@odata/GeneratedEnums";
import { MultiSelect } from "../../inputs/select/MultiSelect";
import { IconButtonStyled, SmartFieldStyled } from "./SmartFilterBar.styles";
import { getIndexedPathFromBindingContext, getUniqueContextsSuffixAsString, setBoundValue } from "@odata/Data.utils";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { logger } from "@utils/log";
import { IFieldInfo } from "@odata/FieldInfo.utils";
import { IEntity } from "@odata/BindingContext";

interface IProps extends Omit<ISmartFieldProps, "customRenderWrapper"> {
    hasConditionalDialog?: boolean;
}

interface IState {
    isDialogOpen: boolean;
}

class SmartFilterField extends React.Component<IProps> {
    static contextType = AppContext;

    state: IState = {
        isDialogOpen: false
    };

    get groupHasConditionalDialog(): boolean {
        return this.props.hasConditionalDialog;
    }

    get itemHasConditionalDialog(): boolean {
        const type = this.props.storage.getInfo(this.props.bindingContext).valueType;
        return ValueTypeToCondition[type]?.length > 0;
    }

    get fieldInfo(): IFieldInfo {
        return this.props.storage.getInfo(this.props.bindingContext);
    }

    isEnum = (): boolean => {
        return isEnum(this.fieldInfo);
    };

    isCurrencyEnum = (): boolean => {
        return this.isEnum() && this.getEnumName() === "Currency";
    };

    getEnumName = (): string => {
        return getEnumName(this.fieldInfo, this.props.storage.oData);
    };

    isOptional = (): boolean => {
        return this.props.bindingContext.getProperty()?.isNullable();
    };

    getCustomValueInput = (): TGetCustomValueInput => {
        if (!this.isEnum()) {
            return null;
        }
        const { bindingContext, storage } = this.props;
        // could be null in case we create enum items manually, e.g. for document status filter
        const enumName = this.getEnumName();

        const opts = {
            bindingContext, fieldInfo: this.fieldInfo,
            storage: storage as TableStorage,
            isEnum: true
        };

        let items: ISelectItem[] = (enumName ? getEnumEntities(this.getEnumName()) : [])
            .map((item: IEntity) => createSelectItem(item, opts, bindingContext));
        let isTabular = false;
        let columns: IColumn[];
        let groups: ISelectGroup[];

        if (enumName && this.isOptional()) {
            const emptyItem = createSelectItem(null, opts);
            items = [emptyItem, ...items];
        }

        if (this.isCurrencyEnum()) {
            const usedCurrencies = getCompanyUsedCurrencyCodes(this.context);
            items = items.reduce((ret, item) => {
                if (usedCurrencies.includes(item.id as CurrencyCode)) {
                    ret.push({
                        ...item,
                        tabularData: [item.id, item.label],
                        label: item.id,
                        description: item.label
                    });
                }
                return ret;
            }, []);
            isTabular = true;
            columns = [{ id: "Code" }, { id: "Name" }];
        }

        // generally enum with local context has to predefine its items, because there is no other way how to obtain them
        if (bindingContext.isLocal()) {
            groups = this.fieldInfo.fieldSettings.groups;
            items = items?.length > 0 ? items : this.fieldInfo.fieldSettings.initialItems;

            if (!items) {
                logger.warn(`SmartFilterField: local enum filter ${this.props.bindingContext.toString()} is missing initialItems definition`);
            }
        }

        // we don't need to pollute ConditionalFilterDialog with binding context,
        // instead we can create the SmartMultiSelect here
        return (row: IComplexFilter, handleChange: (value: IComplexFilter) => void) => {
            return (
                <MultiSelect
                    value={row.value as unknown as TSelectItemId[]}
                    isTabular={isTabular}
                    items={items}
                    groups={groups}
                    width={BasicInputSizes.L}
                    columns={columns}
                    onChange={(event) => {
                        handleChange({
                            ...row,
                            value: event.value as TFilterValue
                        });
                    }}/>
            );
        };
    };

    handleIconClick = (e: React.MouseEvent): void => {
        e.preventDefault();

        this.setState({
            isDialogOpen: true
        });
    };

    handleCancel = (): void => {
        this.setState({
            isDialogOpen: false
        });
    };

    handleConfirm = (values: IComplexFilter[]): void => {
        const { storage, bindingContext } = this.props;
        const info = storage.getInfo(bindingContext);
        this.props.onChange(getValueHelperChangeParams(bindingContext, info.type, {
            value: values,
            triggerAdditionalTasks: true
        }));

        this.setState({
            isDialogOpen: false
        });
    };


    getValidatorFn = () => {
        const { storage } = this.props;
        let { bindingContext } = this.props;
        const data = storage.data;
        let path: string;

        const fieldInfo = storage.getInfo(bindingContext);

        if (fieldInfo.fieldSettings?.displayName) {
            bindingContext = bindingContext.navigate(fieldInfo.fieldSettings?.displayName);
        }

        if (bindingContext.isAnyPartCollection()) {
            path = getIndexedPathFromBindingContext(bindingContext, data.entity, data.bindingContext);
        } else {
            path = getUniqueContextsSuffixAsString(bindingContext, data.bindingContext, ".");
        }

        return (value: TFilterValue) => {
            const entity = {};
            // store data in simulated entity to support validation
            setBoundValue({
                bindingContext,
                data: entity,
                newValue: value,
                dataBindingContext: data.bindingContext,
                preventCloning: true
            });

            storage._validationSchema.validateSyncAt(path, entity);
        };
    };

    renderSmartFieldWrapper = (field: React.ReactNode, fieldInfo: IFieldInfo, data: IGetControlDataRetValue): React.ReactNode => {
        const { storage, fieldProps } = this.props;
        const { additionalFieldData } = data.storageData;
        const type = fieldInfo.valueType;
        const _isEnum = isEnum(fieldInfo);

        const hasConditionalDialog = this.groupHasConditionalDialog && this.itemHasConditionalDialog;
        const conditionalValues: IComplexFilter[] = [];
        if (isComplexFilterArr(additionalFieldData?.parsedValue)) {
            conditionalValues.push(...additionalFieldData.parsedValue);
        }

        const Icon = conditionalValues.length ? ValueHelpFilledIcon : ValueHelpIcon;

        return (<>
            {field}

            {hasConditionalDialog &&
                <IconButtonStyled title={storage.t("Components:ValueHelper.OpenConditionalFilter")}
                                  onClick={this.handleIconClick}
                                  hotspotId={`${fieldInfo?.id}-openConditionalDialog`}
                                  isDisabled={fieldProps.isDisabled || data.props.isDisabled}
                                  size={ButtonSize.Default}
                                  isDecorative
                                  isLight>
                    <Icon width={IconSize.M}/>
                </IconButtonStyled>}

            {this.state.isDialogOpen &&
                <ConditionalFilterDialog name={fieldInfo.label}
                                         onCancel={this.handleCancel}
                                         onConfirm={this.handleConfirm}
                                         values={conditionalValues}
                                         type={type}
                                         isEnum={_isEnum}
                                         validator={this.getValidatorFn()}
                                         getCustomValueInput={this.getCustomValueInput()}
                />}
        </>);
    };

    render() {
        return (<SmartFieldStyled groupHasConditionalDialog={this.groupHasConditionalDialog}
                                  itemHasConditionalDialog={this.itemHasConditionalDialog}
                                  {...this.props}
                                  customRenderWrapper={this.renderSmartFieldWrapper}/>);
    }
}

export default SmartFilterField;