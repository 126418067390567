import styled, { css } from "styled-components/macro";
import { TextAlign } from "../../../enums";
import { FONT_FAMILY, getDisabledStyle } from "../../../global.style";
import { ellipsis, transparentize } from "polished";
import { themes } from "../../../theme";
import { FieldConfirmButtons } from "../field/FieldConfirmButtons";
import { Circle, ErrorText, StyledError } from "../formError/FormError.styles";
import { AuditTrailFieldType } from "../../smart/FieldInfo";
import { StyledStepper } from "../numericValueInputBase/Stepper.styles";
import { IconButton } from "../../button";

// 5px instead of 6px from spec, because otherwise its too small for two characters in time range picker
export const WRITE_LINE_INPUT_PADDING = 5;

interface IProps {
    _width?: string;
    _minWidth?: string;
    _maxWidth?: string;
    maxLength?: number;
    isLight?: boolean;
    ignoreTheme?: boolean;
    isExtending?: boolean;
    isDisabled?: boolean;
    textAlign?: TextAlign;
    paddingRight?: string;
    marginRight?: number;
    auditTrailType?: AuditTrailFieldType,
}

export const WriteLineWrapper = styled.div<IProps>`
    position: relative;
    display: flex;
    flex-direction: column;

    width: ${props => props._width ? props._width : "100%"};
    max-width: ${props => props._maxWidth ?? "inherit"};
    min-width: ${props => props._minWidth ?? "auto"};

    ${props => props.isExtending && css`
        flex: 1 1 auto;
    `};
`;

export const StyledWriteLine = styled.div<IProps>`
    position: relative;
    max-width: 100%;
    width: ${props => props.isExtending ? "fit-content" : "100%"};
    display: flex;
    align-items: center;
    ${props => getDisabledStyle(props.isDisabled)};

    ${StyledError} {
        bottom: 0;

        ${Circle} {
            transform: translate(-75%, -50%);
        }

        ${ErrorText} {
            padding-left: 3px;
        }
    }

    background: ${props => props.auditTrailType === AuditTrailFieldType.NoDifference || props.auditTrailType === AuditTrailFieldType.HoveredNoDifference ?
        props.theme.C_AUDIT_TRAIL_NO_DIFF : "none"};

    ${props => (props.auditTrailType === AuditTrailFieldType.Difference || props.auditTrailType === AuditTrailFieldType.HoveredDifference) && {
        border: `3px solid ${props.theme.C_AUDIT_TRAIL_DIFF}`
    }}
    ${WriteLineWrapper} {
        ${props => (props.auditTrailType === AuditTrailFieldType.NoDifference || props.auditTrailType === AuditTrailFieldType.HoveredNoDifference) && {
            opacity: 0,
            background: props.theme.C_AUDIT_TRAIL_NO_DIFF
        }}

        ${props => (props.auditTrailType === AuditTrailFieldType.HoveredNoDifference) && {
            opacity: props.theme.C_AUDIT_TRAIL_DIFF_VISIBLE_OPACITY
        }}
    }

    & + ${StyledStepper} {
        margin-left: 2px;
    }
`;

export const ValueWrapper = styled.div<IProps>`
    display: flex;
    padding: 0 ${`${WRITE_LINE_INPUT_PADDING}px`};
    ${FONT_FAMILY};
`;

export const WriteLineIcon = styled(IconButton)`
    position: absolute;
    right: 0;
    bottom: 7px;
    cursor: pointer;
`;

export const FloatingText = styled.span`
    position: absolute;
    color: ${props => props.theme.C_TEXT_primary};
    opacity: 0.5;
    pointer-events: none;
    font-size: 18px;
`;

export const ValueInput = styled.input.attrs(props => ({
    type: props.type,
    maxlength: props.maxLength
}))<IProps>`
    border: none;
    outline: none;
    background: transparent;
    box-sizing: border-box;
    min-width: ${props => props.marginRight ? `calc(100% - ${props.marginRight}px)` : "100%"};
    width: 100%;
    ${ellipsis()};
    padding: 0 ${props => props.marginRight ? `${props.marginRight}px` : 0} 0 0;
    text-align: ${props => props.textAlign ? props.textAlign : "center"};
    
    // inherit size from parent so that we can easily style write line in different use cases from its wrapper
    font-size: inherit;
    color: ${props => props.isLight ? props.theme.C_TEXT_dialogue : props.theme.C_TEXT_primary};

    /* Chrome, Safari, Edge, Opera */

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* background-color transition delays default chrome style so we can use transparent background for autofill */
    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px ${props => transparentize(0.5, props.theme.C_BTN_4L_trans_bg_selected)} inset !important;
        -webkit-transition: background-color 9999s ease-out;
        -webkit-text-fill-color: ${props => props.theme.C_TEXT_primary};
    }

    &::placeholder {
        color: ${props => props.isLight ? transparentize(0.5, props.theme.C_TEXT_dialogue) : transparentize(0.5, props.theme.C_TEXT_primary)};
        font-style: italic;
        letter-spacing: 0.14px;
    }

    /* Firefox */
    -moz-appearance: textfield;
`;

export const Line = styled.div<IProps>`
    margin-top: 2px;
    width: 100%;
    height: 2px;
    background-color: ${props => {
        const theme = props.ignoreTheme ? themes.light : props.theme;

        return props.isLight ? theme.C_ACT_hover_without_opacity : theme.C_ACT_main;
    }};
`;

export const Suffix = styled.div`
    pointer-events: none;
`;

export const WriteLineConfirmButtons = styled(FieldConfirmButtons)<IProps>`
    position: absolute;
    right: -5px;
    transform: translateX(100%);
`;