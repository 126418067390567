import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { withOData, WithOData } from "@odata/withOData";
import {
    createLockedVatStatementSubmission,
    createVatSubmissionDocuments,
    downloadSubmissionFile,
    ElectronicSubmissionNamespaces,
    getSubmissionFileUrl,
    getVatStatementPeriod,
    isSubmissionLocked,
    lockSubmission,
    removeVatSubmissionDocuments
} from "./ElectronicSubmission.utils";
import {
    EntitySetName,
    ICompanyVatReducedCoefficientEntity,
    IElectronicSubmissionEntity
} from "@odata/GeneratedEntityTypes";
import { BreadCrumbProvider } from "../../components/breadCrumb";
import View from "../../views/View";
import Header from "../../components/header";
import {
    ClickableStyled,
    CustomFormWrapper,
    LinksGrid,
    StyledHeading,
    StyledParagraph,
    StyledVatSubmissionDetail,
    SwitchStyled,
    TableWrapperStyled
} from "./VatSubmissionDetail.styles";
import { Button, ButtonGroup } from "../../components/button";
import { BasicInputSizes, IconSize, Status, TextAlign, ValueType } from "../../enums";
import ReadOnlyList from "../../components/readOnlyList/ReadOnlyList";
import { IReadOnlyListItem } from "@components/readOnlyList/ReadOnlyListItem";
import CurrencyType, { formatCurrency } from "../../types/Currency";
import { getCompanyCurrencyEntity } from "../companies/Company.utils";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { CaretIcon, DownloadIcon, EditSimpleIcon, ExpandIcon, LockClosedIcon, LockOpenedIcon } from "@components/icon";
import { SwitchType } from "@components/inputs/switch/Switch";
import { capitalize } from "@utils/string";
import BusyIndicator from "../../components/busyIndicator";
import { AlertAction, AlertPosition, IAlertProps } from "@components/alert/Alert";
import { getAlertFromError } from "../../views/formView/Form.utils";
import { CurrencyCode, DocumentTypeCode, VatStatementFrequencyCode } from "@odata/GeneratedEnums";
import {
    changeVatSubmissionClearingRatio,
    getLiabilityDocumentTableDef,
    getVatSubmissionClearingRatio,
    getVatSubmissionDocumentsData,
    getVatSubmissionPeriodName,
    IVatSubmissionCountArgs,
    TVatSubmissionDocumentsData,
    VatSubmissionType
} from "./VatSubmission.utils";
import { SmartTable } from "../../components/smart/smartTable";
import { createBindingContext } from "@odata/BindingContext";
import {
    ROUTE_VAT_CONTROL_STATEMENT_REPORT,
    ROUTE_VAT_STATEMENT_REPORT,
    ROUTE_VAT_VIES_STATEMENT_REPORT
} from "../../routes";
import { getDrillDownLink } from "@components/drillDown/DrillDown.utils";
import { IWithAlertSettings, WithAlert, withAlert } from "@components/alert/withAlert";
import TestIds from "../../testIds";
import customFetch from "../../utils/customFetch";
import Tabs from "../../components/tabs";
import FieldsWrapper from "../../components/inputs/field/FieldsWrapper";
import { IInputOnBlurEvent, IInputOnChangeEvent } from "@components/inputs/input";
import Field from "../../components/inputs/field";
import { BasicSelect, ISelectionChangeArgs } from "../../components/inputs/select/BasicSelect";
import { transformToODataString } from "@odata/OData.utils";
import { getCompanyCurrency, isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { WithPromisedComponent, withPromisedComponent } from "@components/dialog/withPromisedComponent";
import ClearingRatioDialog from "./ClearingRatioDialog";
import { DASH_CHARACTER, EMPTY_VALUE } from "../../constants";
import { isDefined, isNotDefined } from "@utils/general";
import { NumericInput } from "@components/inputs/numericInput/NumericInput";
import { ValidationMessage } from "../../model/Validator.types";
import { isVisible } from "@components/smart/FieldInfo";
import memoize from "../../utils/memoize";
import { getUtcDateBy } from "../../types/Date";
import { VatReportProps } from "../reports/VatReport.utils";
import { ODataError } from "@odata/Data.types";

interface IProps extends WithTranslation, WithOData, WithAlert, WithPromisedComponent {
    year: number;
    monthIndex: number;
    submission?: IElectronicSubmissionEntity;
    vatFrequency?: VatStatementFrequencyCode;
    onSubmissionChange?: (submission: IElectronicSubmissionEntity, isRemoved?: boolean) => boolean;
}

enum VatPaymentType {
    VatLiability = "VatLiability",
    VatReceivable = "VatReceivable"
}

interface IState {
    busy: boolean;
    documents?: TVatSubmissionDocumentsData;
    amount?: number;
    amountError?: string;
    type?: VatPaymentType;
    coefficient?: ICompanyVatReducedCoefficientEntity;
}

class VatSubmissionDetail extends Component<IProps, IState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    state: IState = {
        busy: true,
        coefficient: null
    };

    get subtitle() {
        const { year, monthIndex } = this.props;
        const d = getUtcDateBy(year, monthIndex);
        return capitalize(getVatSubmissionPeriodName(d, this.props.vatFrequency));
    }

    get vatSubmissionCountArgs() {
        const args: IVatSubmissionCountArgs = {};
        if (this.props.submission) {
            args.submission = this.props.submission;
        } else {
            const dateInPeriod = getUtcDateBy(this.props.year, this.props.monthIndex);
            const { from, to } = getVatStatementPeriod(dateInPeriod, this.props.vatFrequency);
            args.from = from.toDate();
            args.to = to.toDate();
        }
        return args;
    }

    get showClearingRatio(): boolean {
        return this.props.monthIndex === 11;
    }

    get useVatReducedDeduction(): boolean {
        return this.showClearingRatio && isDefined(this.state.coefficient);
    }

    componentDidMount() {
        this.setBreadcrumbs();
        this.loadDocumentCounts();

        if (this.props.submission?.Id) {
            this.setDefaultStateFromSubmission();

            if (isSubmissionLocked(this.props.submission) && this.showClearingRatio) {
                this.loadClearingRatio();
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        this.setBreadcrumbs();

        if (this.props.year !== prevProps.year || this.props.monthIndex !== prevProps.monthIndex || this.props.submission !== prevProps.submission) {
            this.loadDocumentCounts();
        }
        if (this.props.submission?.Id !== prevProps.submission?.Id || isSubmissionLocked(this.props.submission) !== isSubmissionLocked(prevProps.submission)) {
            this.setDefaultStateFromSubmission();
        }
        if (this.props.monthIndex !== prevProps.monthIndex) {
            if (this.props.submission?.Id && isSubmissionLocked(this.props.submission) && this.showClearingRatio) {
                this.loadClearingRatio();
            } else {
                this.setState({ coefficient: null });
            }
        }
    }

    async loadDocumentCounts() {
        this.setState({ busy: true });
        const documents = await getVatSubmissionDocumentsData(this.vatSubmissionCountArgs, this.context);
        this.setState({ busy: false, documents });
    }

    async loadClearingRatio() {
        const { year } = this.props;
        const coefficient = await getVatSubmissionClearingRatio(this.props.oData, this.context, year + 1);
        this.setState({ coefficient });
    }

    getSubmissionTypeLabel = (type: VatSubmissionType) => {
        return this.props.t(`ElectronicSubmission:VatSubmission.${type}`);
    };

    getSubmissionTypeLabelWithPeriod(type: VatSubmissionType) {
        return `${this.getSubmissionTypeLabel(type)} (${this.subtitle})`;
    }

    // sets default state for vat submission field
    setDefaultStateFromSubmission() {
        const { VatStatementDocument, VatClearingDocument } = this.props.submission ?? {};
        const hasDocument = !!(VatClearingDocument?.Id || VatStatementDocument?.Id);
        const [type, amount] = hasDocument ? this.getStatementVatDocumentData()
                : (isSubmissionLocked(this.props.submission) ? this.getVatData() : [null, 0]);
        this.setState({ type, amount });
    }

    validateAmount(amount: number): string {
        let validation = null;
        if (isNotDefined(amount)) {
            validation = ValidationMessage.Required;
        }
        if (isNaN(amount)) {
            validation = ValidationMessage.NotANumber;
        }
        const isCashBasisCompany = isCashBasisAccountingCompany(this.context);
        if (isCashBasisCompany ? amount <= 0 : amount < 0) {
            validation = isCashBasisCompany ? ValidationMessage.PositiveNumber : ValidationMessage.PositiveNumberOrZero;
        }
        return validation ? this.props.t(`Common:${validation}`) : null;
    }

    setBreadcrumbs = () => {
        if (this.props.tReady) {
            this.context.setViewBreadcrumbs({
                items: [{
                    key: "detail",
                    title: this.getSubmissionTypeLabelWithPeriod(VatSubmissionType.VatStatement)
                }],
                lockable: false
            });
        }
    };

    setAlert(key: string, status = Status.Success) {
        let alertProps: IAlertProps = null;
        if (key) {
            alertProps = {
                status,
                title: this.props.t(`Common:${status === Status.Success ? "Validation.SuccessTitle" : "Errors.ErrorHappened"}`),
                subTitle: this.props.t(key)
            };
            if (status === Status.Error) {
                alertProps.action = AlertAction.Close;
            }
        }
        this.props.setAlert(alertProps);
    }

    setAlertFromODataError(e: ODataError) {
        this.props.setAlert(getAlertFromError(e));
    }

    handleRemoveDocuments = async () => {
        const { submission, onSubmissionChange } = this.props;

        this.setState({ busy: true });

        try {
            const succ = await removeVatSubmissionDocuments(submission);
            if (succ) {
                onSubmissionChange?.({
                    ...submission,
                    VatStatementDocument: null,
                    VatClearingDocument: null
                });
            } else {
                this.setAlert("ElectronicSubmission:Tax.ErrorRemoveDocument", Status.Error);
            }
        } catch (e) {
            this.setAlertFromODataError(e);
        }

        this.setState({ busy: false });
    };

    handleGenerateDocument = async () => {
        const { submission, onSubmissionChange } = this.props;
        const { amount, type } = this.state;

        const error = this.validateAmount(amount);
        if (error) {
            this.setState({ amountError: error });
            return;
        }

        this.setState({ busy: true });

        try {
            const liability = (type === VatPaymentType.VatReceivable ? -1 : 1) * amount;
            const documents = await createVatSubmissionDocuments(submission, liability);
            if (documents?.length) {
                const VatClearingDocument = documents.find(doc => doc.DocumentTypeCode === DocumentTypeCode.InternalDocument);
                const VatStatementDocument = documents.find(doc => doc.Id !== VatClearingDocument?.Id);
                onSubmissionChange?.({
                    ...submission,
                    VatStatementDocument,
                    VatClearingDocument
                });
                this.setAlert(null);
                this.setDefaultStateFromSubmission();
            } else {
                this.setAlert("ElectronicSubmission:Tax.ErrorGenerateDocument", Status.Error);
            }
        } catch (e) {
            this.setAlertFromODataError(e);
        }

        this.setState({ busy: false });
    };

    handleLockSubmission = async (isLocked: boolean): Promise<void> => {
        const { submission, year, monthIndex } = this.props;

        this.setState({ busy: true });

        try {
            const updatedSubmission = await (submission
                ? lockSubmission(this.props.oData, submission, isLocked)
                : createLockedVatStatementSubmission(this.context, this.props.oData, getUtcDateBy(year, monthIndex), this.props.vatFrequency));

            if (updatedSubmission) {
                const willCloseDetail = this.props.onSubmissionChange?.(updatedSubmission, !updatedSubmission?.Id);
                if (willCloseDetail) {
                    // no further action, component will be unmounted
                    return;
                }
                if (this.showClearingRatio) {
                    this.loadClearingRatio();
                }
            }
        } catch (e) {
            this.setAlertFromODataError(e);
        }

        this.setState({ busy: false });
    };

    handleFileDownload = async (type: VatSubmissionType) => {
        try {
            await downloadSubmissionFile({
                context: this.context,
                type: type,
                submission: this.props.submission,
                generateType: "xml"
            });
            this.setAlert(`ElectronicSubmission:VatSubmission.${type}DownloadSuccess`);
        } catch (e) {
            this.setAlert("ElectronicSubmission:VatSubmission.DownloadError", Status.Error);
        }
    };

    handleTaxPortalRedirect = async (type: VatSubmissionType) => {
        const url = getSubmissionFileUrl({
            context: this.context,
            type,
            submission: this.props.submission,
            generateType: "epo"
        });
        const res = await customFetch(url);

        if (res.ok) {
            const redirectUrl = await res.text();

            // open in new tab
            window.open(redirectUrl, "_blank");
        } else {
            this.setAlert("ElectronicSubmission:VatSubmission.DownloadError", Status.Error);
        }
    };

    getDocumentCount(type: VatSubmissionType) {
        const { documents } = this.state;
        if (!documents) {
            return null;
        }

        const submission = this.props.submission;
        const args = this.vatSubmissionCountArgs;
        const wholeDateRangeFilter = {
            DateStart: submission?.DatePeriodStart ?? args?.from,
            DateEnd: submission?.DatePeriodEnd ?? args?.to
        };

        let route: string;
        switch (type) {
            case VatSubmissionType.VatStatement:
                route = ROUTE_VAT_STATEMENT_REPORT;
                break;
            case VatSubmissionType.VatControlStatement:
                route = ROUTE_VAT_CONTROL_STATEMENT_REPORT;
                break;
            case VatSubmissionType.VatVIESStatement:
                route = ROUTE_VAT_VIES_STATEMENT_REPORT;
                break;
        }

        const count = documents[type] ?? 0;
        const filters = {
            [VatReportProps.vatStatementPeriod]: wholeDateRangeFilter,
            [VatReportProps.unlockedElectronicSubmissionDocuments]: !isSubmissionLocked(submission)
        };

        return {
            label: this.props.t(`ElectronicSubmission:VatSubmission.${type}`),
            value: !count ? DASH_CHARACTER :
                getDrillDownLink(this.props.t("ElectronicSubmission:VatSubmission.Documents", { count }), {
                    route,
                    context: this.context,
                    filters
                })
        };
    }

    getDocumentCounts(): IReadOnlyListItem[] {
        return [
            this.getDocumentCount(VatSubmissionType.VatStatement),
            this.getDocumentCount(VatSubmissionType.VatControlStatement),
            this.getDocumentCount(VatSubmissionType.VatVIESStatement)
        ];
    }

    getVatData(): [VatPaymentType, number] {
        const { VatLiability } = this.props.submission;
        const { abs } = Math;
        const isLiability = VatLiability > 0;
        const type = isLiability ? VatPaymentType.VatLiability : VatPaymentType.VatReceivable;
        return [type, abs(VatLiability ?? 0)];
    }

    getStatementVatDocumentData(): [VatPaymentType, number, CurrencyCode] {
        const { VatStatementDocument } = this.props.submission;
        const { Amount, CurrencyCode: currency } = VatStatementDocument ?? {};
        const isLiability = VatStatementDocument.DocumentTypeCode === DocumentTypeCode.OtherLiability;
        const type = isLiability ? VatPaymentType.VatLiability : VatPaymentType.VatReceivable;
        return [type, (Amount ?? 0), (currency ?? CurrencyCode.CzechKoruna) as CurrencyCode];
    }

    renderLinks(type: VatSubmissionType) {
        return (
            <>
                {this.getSubmissionTypeLabel(type)}
                <ClickableStyled onClick={() => this.handleTaxPortalRedirect(type)} isLink>
                    {this.props.t("ElectronicSubmission:VatSubmission.MyTaxesLink")}
                    <ExpandIcon width={IconSize.XS} height={IconSize.XS}/>
                </ClickableStyled>
                <ClickableStyled onClick={() => this.handleFileDownload(type)} isLink>
                    {this.props.t("ElectronicSubmission:VatSubmission.DownloadXml")}
                    <DownloadIcon width={IconSize.XS} height={IconSize.XS}/>
                </ClickableStyled>
            </>
        );
    }

    renderTable = memoize((Ids: number[]) => {
        const tableDef = getLiabilityDocumentTableDef(this.context);
        const bindingContext = createBindingContext(EntitySetName.Documents, this.props.oData.metadata);
        const filterQuery = {
            query: `Id in (${transformToODataString(Ids, ValueType.Number)})`
        };

        return (
            <TableWrapperStyled>
                <Tabs data={[{ id: "Documents", title: this.props.t("ElectronicSubmission:Tax.Documents") }]}
                      selectedTabId={"Documents"}/>
                <SmartTable bindingContext={bindingContext}
                            initialSortBy={tableDef.initialSortBy}
                            columns={tableDef.columns.filter(col => isVisible({
                                info: col,
                                context: this.context
                            }))}
                            filter={filterQuery}
                            tableId={tableDef.id}
                            disableVirtualization
                            preventAutoReset={tableDef.preventAutoReset}
                            hideDrilldown
                            drilldown={null}/>
            </TableWrapperStyled>
        );
    }, (ids: number[]) => ids.join("-"));

    handleAmountBlur = (args: IInputOnBlurEvent) => {
        this.setState({ amountError: this.validateAmount(this.state.amount) });
    };

    handleAmountChange = (args: IInputOnChangeEvent<number>) => {
        this.setState({
            amount: args.value,
            amountError: null
        });
    };

    handleTypeChange = (args: ISelectionChangeArgs) => {
        if (args.triggerAdditionalTasks) {
            this.setState({ type: args.value as VatPaymentType });
        }
    };

    handleChangeRatio = async () => {
        const ratio = await this.props.promisedComponent.openComponent<number>((onFinish) => {
            return (
                <ClearingRatioDialog
                    initialRatio={this.state.coefficient?.VatReducedCoefficient}
                    onConfirm={onFinish}
                    onCancel={() => onFinish(null)}/>
            );
        });
        if (ratio) {
            const coefficient = {
                ...this.state.coefficient,
                VatReducedCoefficient: ratio
            };
            await changeVatSubmissionClearingRatio(this.props.oData, this.context, coefficient);
            this.setState({ coefficient });
            if (!coefficient?.Id) {
                this.loadClearingRatio();
            }
        }
    };

    vatPaymentTypeItems = memoize(() => {
        return [VatPaymentType.VatLiability, VatPaymentType.VatReceivable].map(id => ({
            id, label: this.props.t(`ElectronicSubmission:VatSubmission.${id}`)
        }));
    });

    renderFields(isDisabled: boolean) {
        const { amountError, amount, type } = this.state;

        const [calcType, calcAmount] = this.getVatData();
        const currency = getCompanyCurrencyEntity(this.context);
        const calcOptions = {
            amount: formatCurrency(calcAmount, currency.Code)
        };

        return (
            <CustomFormWrapper data-testid={TestIds.FormView}>
                <StyledParagraph
                    data-testid={TestIds.CalculatedTax}>{this.props.t(`ElectronicSubmission:Tax.CalculatedTax_${calcType}`, calcOptions)}</StyledParagraph>
                <FieldsWrapper style={{ width: "100%", maxWidth: "100%" }}>
                    <Field width={BasicInputSizes.S}
                           name="custom-type"
                           label={this.props.t("ElectronicSubmission:Tax.TaxType")}>
                        <BasicSelect
                            inputIcon={<CaretIcon width={IconSize.M}/>}
                            isDisabled={isDisabled}
                            openOnClick
                            value={type}
                            onChange={this.handleTypeChange}
                            items={this.vatPaymentTypeItems()}
                        />
                    </Field>
                    <Field
                        width={BasicInputSizes.M}
                        name="custom-amount"
                        label={this.props.t("ElectronicSubmission:Tax.Tax")}
                        isRequired>
                        <NumericInput width={BasicInputSizes.M}
                                      isDisabled={isDisabled}
                                      error={amountError ? { message: amountError } : null}
                                      value={amount}
                                      textAlign={TextAlign.Right}
                                      unit={CurrencyType.getCurrencyUnit(getCompanyCurrency(this.context))}
                                      onBlur={this.handleAmountBlur}
                                      parserArgs={{ maximumFractionDigits: currency.MinorUnit }}
                                      onChange={this.handleAmountChange}/>
                    </Field>
                </FieldsWrapper>
            </CustomFormWrapper>
        );
    }

    renderClearingRatio() {
        const { coefficient } = this.state;

        return (
            <>
                <StyledHeading>{this.props.t("ElectronicSubmission:VatSubmission.ClearingRatio")}</StyledHeading>
                <StyledParagraph data-testid={TestIds.CalculatedCoefficient}>{this.props.t("ElectronicSubmission:VatSubmission.ClearingInfo", { value: coefficient?.VatReducedCoefficient ?? EMPTY_VALUE })}</StyledParagraph>
                <ButtonGroup align={TextAlign.Right}>
                    <Button isTransparent onClick={this.handleChangeRatio} icon={<EditSimpleIcon/>}>
                        {this.props.t("ElectronicSubmission:VatSubmission.Change")}
                    </Button>
                </ButtonGroup>
            </>
        );
    }

    renderLockedSubmissionDetails() {
        const { VatStatementDocument, VatClearingDocument } = this.props.submission;
        const isCashBasisCompany = isCashBasisAccountingCompany(this.context);

        const isPeriodEmpty = !this.state.documents?.[VatSubmissionType.VatStatement];
        const hasDocument = !!(VatStatementDocument?.Id || VatClearingDocument?.Id);
        const canGenerateDocument = !isPeriodEmpty && !hasDocument && this.validateAmount(this.state.amount) === null;
        const documentIds = [VatStatementDocument?.Id, VatClearingDocument?.Id].filter(id => !!id);

        return (<>
            {this.useVatReducedDeduction && this.renderClearingRatio()}
            <StyledHeading>{this.props.t("ElectronicSubmission:VatSubmission.TaxPortalDocuments")}</StyledHeading>
            <StyledParagraph>{this.props.t("ElectronicSubmission:VatSubmission.TaxPortalDocumentsDescription")}</StyledParagraph>
            <LinksGrid data-testid={TestIds.LinksGrid}>
                {this.renderLinks(VatSubmissionType.VatStatement)}
                {this.renderLinks(VatSubmissionType.VatControlStatement)}
                {this.renderLinks(VatSubmissionType.VatVIESStatement)}
            </LinksGrid>
            <StyledHeading>{this.props.t("ElectronicSubmission:Tax.Title")}</StyledHeading>
            {this.renderFields(hasDocument || isPeriodEmpty)}
            <ButtonGroup align={TextAlign.Right}>
                <Button onClick={this.handleRemoveDocuments}
                        isTransparent
                        isDisabled={!hasDocument}>
                    {this.props.t(`ElectronicSubmission:Tax.${isCashBasisCompany ? "RemoveDocument" : "CancelPosting"}`)}
                </Button>
                <Button onClick={this.handleGenerateDocument}
                        isDisabled={!canGenerateDocument}>
                    {this.props.t(`ElectronicSubmission:Tax.${isCashBasisCompany ? "CreateDocument" : "Post"}`)}
                </Button>
            </ButtonGroup>
            {documentIds.length ? this.renderTable(documentIds) : null}
        </>);
    }

    renderSubmissionDetail() {
        const isLocked = isSubmissionLocked(this.props.submission);

        const iconSet = {
            on: LockClosedIcon,
            off: LockOpenedIcon
        };

        return (
            <>
                <StyledHeading>
                    {this.props.t("ElectronicSubmission:VatSubmission.Label")}
                    <SwitchStyled checked={isLocked}
                                  hotspotId={"ElectronicSubmissionVatSubmission"}
                                  onChange={this.handleLockSubmission}
                                  type={SwitchType.Icons}
                                  iconSet={iconSet}/>
                </StyledHeading>
                <StyledParagraph>{this.props.t("ElectronicSubmission:VatSubmission.Description")}</StyledParagraph>
                <ReadOnlyList data={this.getDocumentCounts()} withoutPadding/>

                {isLocked && this.renderLockedSubmissionDetails()}
            </>
        );
    }

    render() {
        if (!this.props.tReady) {
            return (<BusyIndicator/>);
        }

        return (<>
            <BreadCrumbProvider/>
            <View hotspotContextId={"vatSubmissionsDetail"}>
                <StyledVatSubmissionDetail>
                    <Header title={this.props.t("ElectronicSubmission:VatSubmission.Title")}
                            subtitle={this.subtitle}/>
                    {this.renderSubmissionDetail()}
                </StyledVatSubmissionDetail>
            </View>
            {this.props.alert}
            {this.state.busy && <BusyIndicator isDelayed/>}
        </>);
    }
}

const alertProps: IWithAlertSettings = {
    autoHide: true,
    position: AlertPosition.CenteredBottom
};

export default withAlert(alertProps)(withPromisedComponent(withTranslation(ElectronicSubmissionNamespaces)(withOData(VatSubmissionDetail))));