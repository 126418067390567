import React from "react";
import { Button, ButtonGroup } from "../../components/button";
import { Direction, QueryParam, TextAlign } from "../../enums";
import { LogoutButton } from "../userSetting/LogoutButton";
import View from "../../views/View";
import {
    AccountDisabledDescription,
    AccountDisabledMainText,
    ContentWrapper,
    DaysRemainingText,
    HorizontalButtonGroupInVerticalStyled,
    WillBeRemovedText
} from "./GoodbyeScreen.styles";
import { withTranslation, WithTranslation } from "react-i18next";
import { ButtonSize } from "@components/button/Button.utils";
import DashboardBackground, { BackgroundType } from "../../components/navigation/DashboardBackground";
import { ROUTE_HOME } from "../../routes";
import {
    EntitySetName,
    IInvoiceEntity,
    InvoiceEntity,
    ISubscriptionEntity,
    ITenantEntity,
    SubscriptionEntity
} from "@odata/GeneratedEntityTypes";
import { WithOData, withOData } from "@odata/withOData";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BusyIndicator from "../../components/busyIndicator";
import { AppContext, IAppContext } from "../../contexts/appContext/AppContext.types";
import {
    cancelSubscription,
    CREATE_MISSING_PAYMENT_URL,
    IPaymentState,
    isNotYetPayedForSubscription,
    restoreSubscription
} from "../admin/subscriptions/Subscriptions.utils";
import customFetch, { getDefaultPostParams } from "../../utils/customFetch";
import { withBusyIndicator, WithBusyIndicator } from "@components/busyIndicator/withBusyIndicator";
import { createSingleFileExportOfAllData } from "../dataExport/DataExport.utils";
import { PaymentMethodCode, SubscriptionStatusCode } from "@odata/GeneratedEnums";
import { getUtcDayjs } from "../../types/Date";
import { isUserOwner } from "../admin/users/Users.utils";
import PurchaseWizardDialog from "../../components/purchaseWizardDialog/PurchaseWizardDialog";
import { WithPromisedComponent, withPromisedComponent } from "@components/dialog/withPromisedComponent";
import { FILES_API_URL } from "../../constants";
import { logger } from "@utils/log";
import { WithAlert, withAlert } from "@components/alert/withAlert";
import { AlertPosition } from "@components/alert/Alert";
import { parseResponse } from "@odata/ODataParser";
import { getAlertFromError } from "../../views/formView/Form.utils";
import { saveFileFromUrl } from "@utils/FileStorage";
import { getQueryParameters } from "../../routes/Routes.utils";

import { ODataError } from "@odata/Data.types";

interface IProps {

}

interface IExtendedProps extends IProps, WithAlert, WithTranslation, WithOData, RouteComponentProps, WithBusyIndicator, WithPromisedComponent {

}

enum GoodbyeScreenType {
    Default = "Default",
    AwaitsRenewal = "AwaitsRenewal"
}

interface IState {
    screen: GoodbyeScreenType;
    invoices: IInvoiceEntity[];
}

export class GoodbyeScreen extends React.PureComponent<IExtendedProps, IState> {
    static contextType = AppContext;

    constructor(props: IExtendedProps, context: IAppContext) {
        super(props, context);

        const screen = context.getData().subscription.SubscriptionStatusCode === SubscriptionStatusCode.AwaitsRenewal
            ? GoodbyeScreenType.AwaitsRenewal
            : GoodbyeScreenType.Default;

        this.state = {
            screen,
            invoices: []
        };
    }

    componentDidMount() {
        const context = this.context as IAppContext;

        if (!context.hasLimitedAccess()) {
            this.props.history.replace(ROUTE_HOME);
            return;
        }

        if (getQueryParameters()?.[QueryParam.PurchaseAfterTrial]) {
            this.purchaseAfterTrial();
        }

        this.fetchInvoices();
    }

    purchaseAfterTrial = async (): Promise<void> => {
        if (!isNotYetPayedForSubscription(this.subscription)) {
            return;
        }

        await this.handleRestoreAccountClick();
        await this.handlePaymentClick();
    };

    fetchInvoices = async (): Promise<void> => {
        const res = await this.props.oData.getEntitySetWrapper(EntitySetName.Subscriptions)
            .query()
            .select(SubscriptionEntity.Id)
            .expand(SubscriptionEntity.Invoices, (q) => {
                q.orderBy(InvoiceEntity.DateStart);
                q.filter(`${InvoiceEntity.DateOfPayment} eq null and ${InvoiceEntity.FileMetadataId} ne null`);
            })
            .fetchData<ISubscriptionEntity[]>();

        if (!res.value) {
            return;
        }

        const invoices = res.value[0]?.Invoices ?? [];

        this.setState({
            invoices
        });
    };

    get tenant(): ITenantEntity {
        const { tenant } = (this.context as IAppContext).getData();

        return tenant;
    }

    get subscription(): ISubscriptionEntity {
        const { subscription } = (this.context as IAppContext).getData();

        return subscription;
    }

    get remainingDays(): number {
        if (!this.tenant?.DateDataDeletion) {
            return null;
        }

        return getUtcDayjs(this.tenant?.DateDataDeletion).diff(getUtcDayjs(), "day");
    }

    get unpaidInvoices(): IInvoiceEntity[] {
        const invoicesLength = this.state?.invoices?.length;

        if (!invoicesLength) {
            return [];
        }

        return this.state.invoices.filter(invoice => !invoice.DateOfPayment && invoice.FileMetadataId);
    }

    setErrorAlert = async (res: Response): Promise<void> => {
        const error: ODataError = await parseResponse(res);

        logger.error(`GoodbyeScreen request failed: ${error._message}`);
        this.props.setAlert({
            ...getAlertFromError(error),
            isSmall: false,
            position: AlertPosition.CenteredBottom
        });
    };

    handleRestoreAccountClick = async (): Promise<void> => {
        const context = this.context as IAppContext;

        this.props.setBusy(true);

        const res = await restoreSubscription();

        if (!res.ok) {
            this.setErrorAlert(res);
            this.props.setBusy(false);
            return;
        } else {
            this.props.setAlert(null);
        }

        await context.updateTenantAndSubscription();


        if (this.subscription.SubscriptionStatusCode === SubscriptionStatusCode.Active) {
            // just hardcore reload the whole page, just to be sure that everything will be reloaded correctly
            // otherwise, we should call context.refreshAppData, PermissionContext.refreshUserPermissions and maybe something else also
            window.location.reload();
            return;
        }

        this.props.setBusy(false);

        this.setState({
            screen: GoodbyeScreenType.AwaitsRenewal
        });
    };

    handlePaymentClick = async (): Promise<void> => {
        const trialEnded = isNotYetPayedForSubscription(this.subscription);

        // user has not yet paid at all => show purchase wizard
        if (trialEnded) {
            await this.props.promisedComponent.openComponent<boolean>((onFinish) => {
                return (
                    <PurchaseWizardDialog onClose={onFinish}/>
                );
            });
        } else {
            this.props.setBusy(true);
            // run one time payment
            const res = await customFetch(CREATE_MISSING_PAYMENT_URL, {
                ...getDefaultPostParams()
            });

            if (res.ok) {
                const values = await res.json() as IPaymentState;

                window.location.assign(values.Url);
            } else {
                this.setErrorAlert(res);
            }
        }
    };

    cancelRestoring = async (): Promise<void> => {
        this.props.setBusy(true);

        const res = await cancelSubscription();

        if (!res.ok) {
            this.setErrorAlert(res);
            this.props.setBusy(false);
            return;
        } else {
            this.props.setAlert(null);
        }

        this.props.setBusy(false);

        this.setState({
            screen: GoodbyeScreenType.Default
        });
    };

    handleDownloadUnpaidInvoices = (): void => {
        for (const unpaidInvoice of this.unpaidInvoices) {
            saveFileFromUrl(`${FILES_API_URL}/${unpaidInvoice.FileMetadataId}`);
        }
    };

    handleDownloadData = async (): Promise<void> => {
        createSingleFileExportOfAllData();
    };

    render() {
        if (!this.subscription) {
            return <BusyIndicator/>;
        }

        const context = this.context as IAppContext;
        const isOwner = !!isUserOwner(context.getData()?.userSettings);
        const remainingDays = this.remainingDays;
        const trialEnded = isNotYetPayedForSubscription(this.subscription);
        const unpaidInvoices = this.unpaidInvoices;
        let transKey: string;

        switch (true) {
            case this.subscription.SubscriptionStatusCode === SubscriptionStatusCode.Cancelled:
                transKey = "TenantCancelled";
                break;
            case trialEnded:
                transKey = "TrialEnded";
                break;
            case this.state.screen === GoodbyeScreenType.AwaitsRenewal:
                transKey = "Restoring";
                break;
            case this.subscription.PaymentMethodCode === PaymentMethodCode.WireTransfer:
                transKey = "Wire";
                break;
            case this.subscription.PaymentMethodCode === PaymentMethodCode.Card:
                transKey = "Card";
                break;
        }

        return (
            <>
                <View hotspotContextId={"goodbyeScreen"}>
                    {this.props.alert}
                    <DashboardBackground type={BackgroundType.GoodbyeScreen}/>
                    <ButtonGroup align={TextAlign.Right}>
                        <LogoutButton/>
                    </ButtonGroup>
                    <ContentWrapper>
                        <AccountDisabledMainText>
                            {this.props.t(`Goodbye:${transKey}.MainText`)}
                        </AccountDisabledMainText>
                        <AccountDisabledDescription>
                            {this.props.t("Goodbye:RemainingUntilRemoved")}
                        </AccountDisabledDescription>
                        <DaysRemainingText isVisible={!!remainingDays}>
                            {remainingDays} {this.props.t("Common:Time.Days", { count: remainingDays })}
                        </DaysRemainingText>
                        <WillBeRemovedText>
                            {this.props.t("Goodbye:WillBeRemovedAfterThat")}
                        </WillBeRemovedText>
                        <ButtonGroup direction={Direction.Vertical}>
                            {isOwner && this.state.screen === GoodbyeScreenType.Default &&
                                <Button size={ButtonSize.Big}
                                        onClick={this.handleRestoreAccountClick}>
                                    {this.props.t(`Goodbye:${transKey}.ButtonText`)}
                                </Button>
                            }
                            {isOwner && this.state.screen === GoodbyeScreenType.AwaitsRenewal &&
                                <Button size={ButtonSize.Big}
                                        onClick={this.handlePaymentClick}>
                                    {this.props.t(`Goodbye:${transKey}.PaymentText`)}
                                </Button>
                            }
                            <HorizontalButtonGroupInVerticalStyled direction={Direction.Horizontal}>
                                {unpaidInvoices?.length > 0 &&
                                    <Button isTransparent
                                            onClick={this.handleDownloadUnpaidInvoices}>
                                        {this.props.t("Goodbye:DownloadLatestInvoice")}
                                    </Button>
                                }
                                {this.state.screen === GoodbyeScreenType.AwaitsRenewal &&
                                    <Button isTransparent
                                            onClick={this.cancelRestoring}>
                                        {this.props.t("Goodbye:Restoring.CancelRestoring")}
                                    </Button>
                                }
                                {/*hidden, not ready*/}
                                {/*<Button isTransparent*/}
                                {/*        onClick={this.handleDownloadData}>*/}
                                {/*    {this.props.t("Goodbye:DownloadData")}*/}
                                {/*</Button>*/}
                            </HorizontalButtonGroupInVerticalStyled>
                        </ButtonGroup>
                    </ContentWrapper>
                </View>
                {this.props.busyIndicator}
            </>
        );
    }
}

export default withAlert()((withPromisedComponent(withBusyIndicator({ passBusyIndicator: true })(withTranslation(["Goodbye", "Common"])(withRouter(withOData(GoodbyeScreen)))))));