const CUSTOMER_PORTAL_KEY = "CustomerPortal";

interface ICustomerPortalData {
    isActive?: boolean;
    currentCompany?: number;
}

export class CustomerPortalSettings {
    data: ICustomerPortalData = {};

    constructor() {
        try {
            const data = JSON.parse(sessionStorage.getItem(CUSTOMER_PORTAL_KEY)) as ICustomerPortalData;
            this.data = data ?? {};
        } catch (e) {
            /* fall silently */
        }
    }

    __save(): void {
        try {
            sessionStorage.setItem(CUSTOMER_PORTAL_KEY, JSON.stringify(this.data));
        } catch (e) { /* fall silently */
        }
    }

    get isActive(): boolean {
        return this.data.isActive;
    }

    set isActive(value: boolean) {
        this.data.isActive = value;
        this.__save();
    }
}

export const CustomerPortal = new CustomerPortalSettings();
