import styled, { css } from "styled-components/macro";
import { T_HEADER_bold, T_HEADER_small, T_PLAIN_small } from "../../global.style";
import Clickable from "../../components/clickable";

const ROW_HEIGHT = 53;
const HEADER_SPACING = 28;

export const Page = styled.div`
    padding-top: 45px;
    height: 100%;
`;

export const Body = styled.div`
    padding: 0 38px 38px 38px;
`;

export const Content = styled.div`
    padding: 30px 0;
`;

export const Title = styled.div``;

export const HeaderTile = styled.div`
    width: 260px;
`;

export const IconWrapper = styled.div`
    height: 143px;
    width: 143px;
    margin: 0 auto;
`;

export const TileTitle = styled.div`
    ${T_HEADER_small};
    font-weight: normal;
`;

export const ElCol = styled.th`
    vertical-align: bottom;
    padding-bottom: ${HEADER_SPACING}px;
`;

export const HeaderCol = styled.th`
    width: 276px;
    padding-bottom: ${HEADER_SPACING}px;
`;

export const TableStyled = styled.table`
    border-collapse: separate;
    border-spacing: 4px;
`;

interface IIsSelected {
    $isSelected?: boolean;
}

export const SelectIconWrapper = styled.div<IIsSelected>`
    display: flex;
    justify-content: center;
    width: 38px;
    visibility: ${props => props.$isSelected ? "visible" : "hidden"};
    margin-left: auto;
`;

export const ElectronicSubmissionCellStyled = styled.td<IIsSelected>`
    box-shadow: ${props => props.$isSelected ? "0 0 18px 0 rgba(0, 0, 0, 0.15)" : "0 0 5px 0 rgba(0, 0, 0, 0.05)"};
    background-color: ${props => props.theme.C_BG_row_field};
    padding: 0;
    border-radius: 3px;

    ${props => props.$isSelected && css`
        position: relative;
        z-index: 1;
    `}
`;

export const CellContentWrapper = styled(Clickable)<{ rowSpan: number }>`
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${props => props.theme.C_TEXT_primary};
    min-height: ${props => props.rowSpan * ROW_HEIGHT}px;

    &:hover {
        color: ${props => props.theme.C_TEXT_primary};
        background-color: ${props => props.theme.C_ACT_hover};

        ${SelectIconWrapper} {
            visibility: visible;
            color: ${props => props.theme.C_ACT_der};
        }
    }
`;

export const LockIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 45px;
`;

export const TitleWrapper = styled.div`
    ${T_PLAIN_small};
`;

export const RowHeaderCell = styled.th`
    ${T_HEADER_bold};
    text-align: right;
    height: ${ROW_HEIGHT}px;
    padding-right: 50px;
    vertical-align: middle;
`;
