import React from "react";
import { InnerTextWrapper, StyledText } from "./Text.styles";
import { handleRefHandlers, isDefined } from "@utils/general";
import Tooltip from "../tooltip/Tooltip";
import TestIds from "../../testIds";
import memoizeOne from "../../utils/memoizeOne";
import { TextAlign } from "../../enums";
import { DASH_CHARACTER } from "../../constants";

interface IProps {
    isBig?: boolean;
    width?: string;
    maxRows?: number;
    isLight?: boolean;
    /** Removes default padding and self align */
    isWithoutDefaultFormatting?: boolean;
    textAlign?: TextAlign;
    passRef?: React.Ref<HTMLDivElement>;
    className?: string;
    style?: React.CSSProperties;
    isDisabled?: boolean;
    isSynchronized?: boolean;
}

export default class Text extends React.PureComponent<IProps> {
    getHandleRef = memoizeOne((ref?: React.Ref<any>) => {
        return (element: HTMLDivElement) => {
            handleRefHandlers(element, ref, this.props.passRef);
        };
    });

    render() {
        return (
            <Tooltip content={this.props.children}
                     onlyShowWhenChildrenOverflowing>
                {(ref) =>
                    <StyledText ref={this.getHandleRef(ref)}
                                className={this.props.className}
                                isBig={this.props.isBig}
                                maxRows={this.props.maxRows}
                                style={this.props.style}
                                _textAlign={this.props.textAlign}
                                isLight={this.props.isLight}
                                isDisabled={this.props.isDisabled}
                                isWithoutDefaultFormatting={this.props.isWithoutDefaultFormatting}
                                _width={this.props.width}
                                data-testid={TestIds.Text}>
                        <InnerTextWrapper>
                            {isDefined(this.props.children) && this.props.children !== "" ? this.props.children : DASH_CHARACTER}
                        </InnerTextWrapper>
                    </StyledText>
                }
            </Tooltip>
        );
    }
}